import React, { FC, useMemo } from 'react'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
import useMarketSegmentsQuery from '../../Product/hooks/useMarketSegmentsQuery'
// Components
import Dropdown, {
  DropdownOption,
  DropdownValue
} from '../../../components/common/Dropdown'
import { ViewStyle } from 'react-native'

interface MarketSegmentSelectorProps {
  name?: string
  placeholder?: string
  dropdownStyle?: ViewStyle
  value: DropdownValue
  onSelect: (dropdownValue: any) => void
  externalLoading?: boolean
}

const MarketSegmentSelector: FC<MarketSegmentSelectorProps> = ({
  name = 'marketSegmentSelector',
  externalLoading = false,
  dropdownStyle = {},
  ...rest
}) => {
  // Hooks
  const { t } = useTranslation()
  const { marketSegments, loading } = useMarketSegmentsQuery()
  // Render
  const marketSegmentsOptions: DropdownOption[] = useMemo(
    () =>
      marketSegments?.length
        ? marketSegments?.map?.((marketSegment: any) => ({
            value: marketSegment.id,
            label: marketSegment.name
          }))
        : [],

    [marketSegments]
  )
  const isLoading = !!externalLoading || loading
  const dpStyle: ViewStyle = {
    minHeight: 0,
    marginBottom: 0,
    ...dropdownStyle
  }
  return (
    <Dropdown
      isMulti
      isFilter
      isClearable
      style={dpStyle}
      isLoading={isLoading}
      closeMenuOnSelect={false}
      options={marketSegmentsOptions}
      controlStyle={{ minHeight: 0 }}
      menuPortalTarget={document?.body}
      name={name || 'marketSegmentSelector'}
      placeholder={t('platformManagement:marketSegment:selectorPlaceholder')}
      {...rest}
    />
  )
}

export default MarketSegmentSelector
