import React, { useContext, useEffect, useState } from 'react'

import useTranslation from '../../../../../../hooks/useTranslation'
import { Platform } from 'react-native'
import Sentry from '../../../../../../utils/sentry'
import useToast from '../../../../../../hooks/useToast'
import { useTheme } from 'styled-components/native'
import { TableContext } from '../../../../hooks/TableProvider'
import useCurrentUser from '../../../../../../hooks/useCurrentUser'
import { ButtonFlex, StyledButtonUI } from '../../SharedStyledComponents'
import ModalDialog from '../../../common/ModalDialog'
import { getCsvData } from '../../../constants'
import { ButtonUIProps } from '../../types'
import useHasPermission from '../../../../../../hooks/useHasPermission'
import { CURATION_PERMISSIONS } from '../../../../../../constants/roles'
import Link from '../../../../../Link'
import useGenerateCsvMutation from '../../../../hooks/useGenerateCsvMutation'
import { getExtraParams, tableNameCSVTypeMap } from '../../../../utils'
import { CSVTypeEnum, TableNameEnum } from '../../../../types'

const ButtonUI = (props: ButtonUIProps): any => {
  const { t } = useTranslation()
  const { colors, sizes } = useTheme()
  const [buttonColors, setButtonColors] = useState({
    background: '#FFFFFF',
    text: '#D9D9D9'
  })

  const {
    name,
    openDialog,
    setOpenDialog,
    isProcessing,
    setIsProcessing,
    isDisabled,
    handleGenerateCSV
  } = props

  const shouldShowModal = [
    TableNameEnum.Submission,
    TableNameEnum.PreCuration,
    TableNameEnum.Cohort,
    TableNameEnum.Presentation
  ].includes(name)

  useEffect(() => {
    if (!isDisabled) {
      setButtonColors({
        background: colors.background,
        text: colors.darkIcon
      })
    }
  }, [isDisabled])

  return (
    <StyledButtonUI
      testID={'exportCSVbtn'}
      disabled={isDisabled}
      title={t('common:table:exportCSV')}
      onPress={() => {
        if (shouldShowModal) {
          setOpenDialog(!openDialog)
        } else {
          // JC This is just a dummy loader for the button on the link
          setIsProcessing(true)
          handleGenerateCSV()
          setTimeout(() => {
            setIsProcessing(false)
          }, 3000)
        }
      }}
      buttonStyle={{
        width: sizes[6] + sizes[4],
        paddingVertical: 10,
        backgroundColor: buttonColors.background
      }}
      titleStyle={{
        color: buttonColors.text
      }}
      isProcessing={isProcessing}
    />
  )
}

const Button = (): any => {
  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()
  const [isProcessing, setIsProcessing] = useState(false)
  const [link, setLink] = useState<any>(null)
  const {
    config,
    queryFilters,
    data,
    error,
    selectedItemsLength,
    selectedItemsKeys,
    selectAllElements
  } = useContext(TableContext)
  const { name, csvTypesMap = [], cohortId, filters } = config
  const [generateCsv] = useGenerateCsvMutation()

  const [openDialog, setOpenDialog] = useState(false)
  const isCurator = useHasPermission(CURATION_PERMISSIONS)

  const [isDisabled, setIsDisabled] = useState(true)

  const { currentUserId } = useCurrentUser()

  const csvType = tableNameCSVTypeMap[name]

  useEffect(() => {
    if (isCurator && !error && selectedItemsLength > 0 && !isProcessing) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [isCurator, error, selectedItemsLength, isProcessing])

  const URL = window.URL || window.webkitURL
  const cfsName = data ? data[0]?.callForSubmission?.name || '' : ''

  const downloadFile = (url, type) => {
    const fileName = `Innovation-${cfsName}-${name}-${type}.csv`
    const aElement = document.createElement('a')
    document.body.appendChild(aElement)
    aElement.href = url
    aElement.download = fileName
    aElement.click()
    URL.revokeObjectURL(url)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadCsvTypes = async types => {
    const csvTypes = Object.entries(types)
      .filter(type => type[1])
      .map(type => type[0])
    setIsProcessing(true)
    await Promise.all(
      csvTypes.map(async type => {
        try {
          const csvType = csvTypesMap.filter(
            csvTypeMap => csvTypeMap.name === type
          )[0]
          const url = buildURI({
            ...config,
            ...(csvType.customCsv
              ? {
                  getCustomCsvColumns: csvType?.getCustomCsvColumns,
                  getCustomCsvData: csvType?.getCustomCsvData,
                  getCustomCsvBody: csvType?.getCustomCsvBody
                }
              : {}),
            customCsv: csvType.customCsv,
            excludedColumns: csvType?.excludedColumns
          })
          if (url) {
            downloadFile(url, type)
          }
        } catch (error) {
          Sentry.captureException(
            new Error(
              `Error while creating CSV Batch for type: ${type}, CFS: ${cfsName}`
            )
          )
        }
      })
    )
    setIsProcessing(false)
    setOpenDialog(false)
  }

  const buildURI = config => {
    try {
      const filteredData = data?.filter(item =>
        selectedItemsKeys.includes(item.id)
      )
      const csv = getCsvData({
        config: config,
        data: filteredData,
        t,
        currentUserId
      })
      const type = 'text/csv'

      const blob = new Blob(['', csv], { type })
      const dataURI = `data:${type};charset=utf-8,''}${csv}`

      return typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob)
    } catch (e) {
      setToastErrorMessage(t('error:csvError'))
      setIsProcessing(false)
    }
  }

  const handleGenerateCSV = async (type: CSVTypeEnum) => {
    try {
      setIsProcessing(true)
      let variables = {}
      variables = {
        csvType: type,
        groupId: cohortId,
        advisorId: currentUserId
      }

      if (selectAllElements && data) {
        variables['callForSubmissionId'] = data[0]?.callForSubmissionId
      } else {
        variables['ids'] = selectedItemsKeys
      }

      const extraParams = getExtraParams({
        name,
        filters,
        queryFilters
      })

      variables = { ...variables, extraParams }

      // Generate and get CSV Body
      const { data: mutationData, errors } = await generateCsv({
        variables
      })
      if (errors) {
        setToastErrorMessage(t('error:csvError'))
        setIsProcessing(false)
      } else {
        const csv = mutationData?.getInnovationSubmissionsCSV
        const type = 'text/csv'

        const blob = new Blob(['', csv], { type })
        const dataURI = `data:${type};charset=utf-8,''}${csv}`

        const csvLink =
          typeof URL.createObjectURL === 'undefined'
            ? dataURI
            : URL.createObjectURL(blob)

        setIsProcessing(false)
        return csvLink
      }
    } catch (e) {
      setToastErrorMessage(t('error:csvError'))
      setIsProcessing(false)
    }
  }

  const newDownloadCsvTypes = async types => {
    const csvTypes = Object.entries(types)
      .filter(type => type[1])
      .map(type => type[0])
    setIsProcessing(true)
    await Promise.all(
      csvTypes.map(async type => {
        try {
          const url = await handleGenerateCSV(type as CSVTypeEnum)
          if (url) {
            downloadFile(url, type)
          }
        } catch (error) {
          Sentry.captureException(
            new Error(
              `Error while creating CSV Batch for type: ${type}, CFS: ${cfsName}`
            )
          )
        }
      })
    )
    setIsProcessing(false)
    setOpenDialog(false)
  }

  if (Platform.OS !== 'web') {
    return null
  }

  if (!data?.length) return null

  return (
    <Link
      to={link}
      title={t('common:table:exportCSV')}
      target="_self"
      download={`Innovation-${cfsName}-${name}.csv`}
      style={{
        textDecoration: 'none'
      }}
    >
      <ButtonFlex flexDirection="row">
        <ButtonUI
          isDisabled={isDisabled}
          name={name}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          setLink={setLink}
          handleGenerateCSV={async () => {
            const link = await handleGenerateCSV(csvType)
            if (link) {
              downloadFile(link, csvType)
            }
          }}
          setIsProcessing={setIsProcessing}
          isProcessing={isProcessing}
        />
      </ButtonFlex>
      <ModalDialog
        title={t('common:table:exportCSV')}
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        handleSubmit={newDownloadCsvTypes}
        csvTypesMap={csvTypesMap}
        isProcessing={isProcessing}
      />
    </Link>
  )
}

export default Button
