import React, { useState } from 'react'
import {
  CollapsibleContainerTitle,
  CollapsibleContainerHeaderView,
  CollapsibleContainerView,
  CollapsibleContainerSubtitle,
  CollapsibleTitle,
  CollapsibleIcon,
  CollapsibleContainerContentView
} from './SharedStyledComponents'

interface CollapsibleContainerProps {
  children?: any
  title: string
  subtitle?: string
  isOpen?: boolean
  testID?: string
}

const CollapsibleContainer = ({
  children,
  title,
  subtitle,
  isOpen = false,
  testID
}: CollapsibleContainerProps) => {
  const [isCollapsed, setIsCollaped] = useState(isOpen)
  return (
    <CollapsibleContainerView testID={testID}>
      <CollapsibleContainerHeaderView>
        <CollapsibleContainerTitle>
          <CollapsibleTitle>{title}</CollapsibleTitle>
          <CollapsibleIcon
            width={15}
            name="chevron-right"
            color="black"
            onPress={() => setIsCollaped(!isCollapsed)}
            isCollapsed={isCollapsed}
          />
        </CollapsibleContainerTitle>
        <CollapsibleContainerSubtitle>{subtitle}</CollapsibleContainerSubtitle>
      </CollapsibleContainerHeaderView>
      {isCollapsed && (
        <CollapsibleContainerContentView>
          {children}
        </CollapsibleContainerContentView>
      )}
    </CollapsibleContainerView>
  )
}

export default CollapsibleContainer
