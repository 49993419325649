import { atomFamily } from 'recoil'

export interface DownloadProps {
  isVisible: boolean
  isLoading: boolean
  attempts?: number | undefined
  downloadLink?: string
  handleSubscription?: () => any
  handleGetLink?: (val: any) => any
  getLinkDataKey?: string
  getLinkValuesKey?: string
  fileName?: string
}

const currentDownloadStatusAtom = atomFamily<DownloadProps, string>({
  key: 'downloadStatus',
  default: {
    isVisible: false,
    isLoading: false,
    attempts: undefined,
    downloadLink: undefined,
    handleSubscription: () => {},
    handleGetLink: () => {},
    getLinkDataKey: undefined,
    getLinkValuesKey: undefined,
    fileName: undefined
  } as DownloadProps
})

export default currentDownloadStatusAtom
