import gql from 'graphql-tag'

import { callForSubmissionQuery } from './useCallForSubmissionQuery'

import useTranslation from '../../../hooks/useTranslation'
import useMutation from '../../../hooks/useMutation'

export const upsertCallForSubmissionAdvisorsMutation = gql`
  mutation upsertCallForSubmissionAdvisors(
    $callForSubmissionId: String
    $toAddAdvisorsIds: [String]
    $toRemoveAdvisorsIds: [String]
  ) {
    upsertCallForSubmissionAdvisors(
      callForSubmissionId: $callForSubmissionId
      toAddAdvisorsIds: $toAddAdvisorsIds
      toRemoveAdvisorsIds: $toRemoveAdvisorsIds
    ) {
      id
      advisors
      advisorsUsers {
        person {
          id
        }
      }
    }
  }
`

const useUpsertCallForSubmissionAdvisorsMutation = (
  callForSubmissionId: string,
  refetchOnMutationIsCompleted = true
) => {
  const { t } = useTranslation()

  const [upsertCallForSubmissionAdvisors, { loading }] = useMutation(
    upsertCallForSubmissionAdvisorsMutation,
    {
      ...(refetchOnMutationIsCompleted
        ? {
            refetchQueries: [
              {
                query: callForSubmissionQuery,
                variables: {
                  id: callForSubmissionId,
                  withSubmissionReviews: false,
                  withSubmissionReviewsAverages: false,
                  withInnovationSubmissions: true
                }
              }
            ]
          }
        : {}),
      errorMessage: t('error:save:default')
    }
  )

  return {
    upsertCallForSubmissionAdvisors,
    loading
  }
}

export default useUpsertCallForSubmissionAdvisorsMutation
