import React, { useContext, useMemo } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import Card from '../common/Card'
import { useTheme } from 'styled-components/native'
import { DashboardContext } from '../hooks/DashboardProvider'
import { AnimatedEffect } from '../../../../components/common/AnimatedComponent/types'
import { StepProps } from './types'
import { InnovationSubmissionStatusEnum } from '../../../../types'
import useTranslation from '../../../../hooks/useTranslation'

const stepsMap = ['submission', 'precurate', 'advising', 'cohort']

const Circle = ({
  size,
  right,
  opacity
}: {
  size: number
  right: number
  opacity: number
}) => {
  const { colors } = useTheme()
  return (
    <View
      style={{
        position: 'absolute',
        height: size,
        width: size,
        right: -right,
        top: -right - 5,
        borderRadius: size,
        opacity: opacity,
        backgroundColor: colors.appBackground
      }}
    />
  )
}

const Step = ({
  step,
  opacity,
  color,
  total,
  average,
  handleClick
}: StepProps) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { step: stepName, status } = step
  const stepStatus = Object.keys(status)
  const count = status[stepStatus[0]] ?? 0 // Change to all status
  const stepValue = total - count

  let metric: number | undefined
  let metricValidation: boolean
  let captionKey: any = step.step

  switch (step.step) {
    case 'Total Submissions':
      metric = average ? Math.round((total / average) * 100 - 100) : undefined
      captionKey = 'totalSubmissions'
      metricValidation = metric ? metric <= 1 : false
      metric = metric ? Math.abs(metric) : undefined
      break

    default:
      metric = count
      metricValidation = metric >= 0
      break
  }

  const zoomEffect: AnimatedEffect = {
    initialValue: 1,
    mouseEvent: 'Hover',
    styleProperty: value => ({
      transform: [{ scaleX: value }, { scaleY: value }]
    }),
    timeIn: 200,
    timeOut: 150,
    toValue: 1.08
  }

  return (
    <TouchableOpacity style={{ opacity }} onPress={() => handleClick(stepName)}>
      <Card
        effects={[zoomEffect]}
        clickOpacity
        height={140}
        width="250px"
        styles={{ backgroundColor: color }}
      >
        {/* Metrics Container */}
        <View
          style={{
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          {typeof metric !== 'undefined' ? (
            <>
              <View
                style={{
                  backgroundColor: colors.appBackground,
                  position: 'absolute',
                  borderRadius: 10,
                  width: 100,
                  height: 45,
                  top: -5,
                  left: -5,
                  opacity: 0.2
                }}
              />
              <Text
                style={{
                  position: 'absolute',
                  width: 90,
                  top: 0,
                  left: 0,
                  color: 'white',
                  fontWeight: '500',
                  fontSize: 13,
                  textAlign: 'center'
                }}
              >
                {t(
                  `dashboard:components:stepSummary:${captionKey}:${
                    metricValidation ? 'under' : 'over'
                  }`,
                  { metric }
                )}
              </Text>
            </>
          ) : null}
          <Text
            style={{
              position: 'absolute',
              width: 135,
              top: 7,
              right: -30,
              color: 'white',
              fontSize: '45px',
              opacity: 1,
              textTransform: 'uppercase',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {stepValue}
          </Text>
          <Text
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              color: 'white',
              fontSize: '20px',
              opacity: 0.9,
              fontWeight: '800',
              textTransform: 'uppercase'
            }}
          >
            {stepName}
          </Text>
          <Circle size={135} right={30} opacity={0.2} />
          <Circle size={195} right={60} opacity={0.15} />
          <Circle size={250} right={90} opacity={0.1} />
        </View>
      </Card>
    </TouchableOpacity>
  )
}

const StepsSummary = () => {
  const { colors } = useTheme()
  const {
    callForSubmission: { average, steps },
    handleFilters,
    filters
  } = useContext(DashboardContext)
  const selectedOpacity = 0.4

  const currentStatusFilter = useMemo(
    () =>
      filters.find(filter => filter.filterType === 'Status')?.value ??
      undefined,
    [filters]
  )

  const handleClick = (step: string) => {
    handleFilters({ filterType: 'Status', value: step })
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        maxWidth: '100%',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
      }}
    >
      {/* Total Submissions */}
      <Step
        key={`totalSubmissionsStepId`}
        step={{
          step: 'Total Submissions',
          status: { [InnovationSubmissionStatusEnum.inProgress]: 0 },
          closeAt: '',
          openAt: ''
        }}
        opacity={currentStatusFilter ? selectedOpacity : 1}
        handleClick={handleClick}
        color={colors.saved}
        average={average}
        total={steps.reduce((acc, { status }) => {
          const keys = Object.keys(status)
          let count = 0
          for (let indexKey = 0; indexKey < keys.length; indexKey++) {
            const key = keys[indexKey]
            count = count + status[key]
          }
          return acc + count
        }, 0)}
      />
      {/* Call For Submission - Submissions Summary */}
      {steps
        .filter(step => stepsMap.includes(step.step.toLowerCase()))
        .map((step, index) => (
          <Step
            handleClick={handleClick}
            key={index}
            step={step}
            color={colors.pillColor}
            opacity={
              currentStatusFilter
                ? currentStatusFilter === step.step
                  ? 1
                  : selectedOpacity
                : 1
            }
            total={steps
              .filter((item, i) => item && i >= index)
              .reduce((acc, { status }) => {
                const keys = Object.keys(status)
                let count = 0
                for (let indexKey = 0; indexKey < keys.length; indexKey++) {
                  const key = keys[indexKey]
                  count = count + status[key]
                }
                return acc + count
              }, 0)}
          />
        ))}
    </View>
  )
}

export default StepsSummary
