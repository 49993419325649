import React, { useEffect, useState } from 'react'
import TextInput from '../../../../../ui-library/TextInput'
import { DebouncedTextInputProps } from './types'
import { shouldMemoizeComponent } from '../../../constants'

const DebouncedTextInput = (props: DebouncedTextInputProps): any => {
  const { onChangeText, initialValue, ...rest } = props
  const [query, setQuery] = useState(initialValue || '')

  useEffect(() => {
    const handler = setTimeout(() => {
      onChangeText(query)
    }, 300)

    return () => clearTimeout(handler)
  }, [query])

  return <TextInput value={query} onChangeText={setQuery} {...rest} />
}

// Adding memoized function to avoid reRender on theme changes
// Only when any specific prop changes. (e.g. styles Object, onChangeText: Function)
export default React.memo(DebouncedTextInput, shouldMemoizeComponent)
