import React from 'react'
import { View } from 'react-native'
import { useField } from 'formik'
import { pick } from 'ramda'

import Rating, { RatingProps } from '../../ui-library/Rating'
import useTranslation from '../../hooks/useTranslation'

import {
  ErrorContainer,
  ErrorText
} from '../../screens/authentication/components/SharedStyledComponents'

import { JSONStyles, Question } from './types'
import { parseJson } from '../../utils/parseJson'
import useQuestionDependency from './hooks/useQuestionDependency'

type RatingStyles = Pick<
  JSONStyles,
  'containerStyles' | 'errorContainerStyles' | 'labelStyles' | 'styles'
>

interface RatingDataProps {
  key: string
  name: string
  label?: string
  helperText?: string
  questionWeight?: number
  ratingCount?: number
  styles?: RatingStyles
  formSubmissionId: string
  questionDependencyIds?: string[]
  questionId: string
}

interface DynamicRatingProps extends RatingProps {
  data: RatingDataProps
  isReadOnly: boolean
}

const DynamicRating = (props: DynamicRatingProps) => {
  const { t } = useTranslation()
  const { data, isReadOnly = false } = props
  const {
    key,
    styles,
    label,
    helperText,
    ratingCount,
    name,
    questionId,
    questionDependencyIds = []
  } = data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField({
    name
  })
  const { visible } = useQuestionDependency(
    questionDependencyIds,
    questionId,
    field?.value
  )

  const { value } = meta
  const { setValue } = helpers

  const handleChange = newRating => {
    if (!isReadOnly) {
      setValue(newRating, false)
    }
  }

  const showError = !isReadOnly && !!meta.error && meta.touched
  const containerStyle = {
    width: '100%',
    // @ts-ignore
    ...(styles?.containerStyles || {})
  }

  return (
    visible && (
      <View style={containerStyle}>
        <Rating
          key={key}
          label={label}
          helperText={helperText}
          isReadOnly={isReadOnly}
          count={value}
          ratingCount={ratingCount}
          onFinishRating={handleChange}
        />
        {showError ? (
          <ErrorContainer style={styles?.errorContainerStyles}>
            <ErrorText>{t(meta.error, { length })}</ErrorText>
          </ErrorContainer>
        ) : null}
      </View>
    )
  )
}

export const transformRatingData = (
  question: Question,
  formSubmissionId: string
): RatingDataProps => {
  const styles = parseJson(question?.style)
  const configData = parseJson(question?.configData || {})
  const questionWeight = configData?.questionWeight || styles?.questionWeight
  const helperText = configData?.helperText || styles?.helperText
  const ratingCount = configData?.ratingCount || undefined

  return {
    key: `questions-${question?.id}`,
    name: `questions.${question?.id}`,
    label: question?.questionText,
    helperText,
    questionWeight,
    ratingCount,
    styles: pick(['containerStyles', 'labelStyles', 'styles'], styles || {}),
    formSubmissionId,
    questionId: question?.id,
    questionDependencyIds: question?.questionDependencies?.map(qd => qd?.id)
  }
}

export default DynamicRating
