import gql from 'graphql-tag'

const InnovationSubmissionAdviseFieldsFragment = advisorId => {
  const QueryString = `
fragment InnovationSubmissionFieldsFragment on InnovationSubmission {
  id
  callForSubmissionId
  callForSubmission {
    id
    name
  }
  pdfCloudinaryId
  company {
    name
  }
  submissionInfo {
    categoryLabel
    name
  }
  advisorSubmissionReview(advisorId: "${advisorId}") {
    hasAdvised
    favorite
    academy
  }
}
`
  return gql`
    ${QueryString}
  `
}

export default InnovationSubmissionAdviseFieldsFragment
