import gql from 'graphql-tag'

const AnswerFieldsFragment = gql`
  fragment AnswerFieldsFragment on Answer {
    id
    value
    answerEntityId
    answerEntityType
    formSubmissionId
    questionId
  }
`

export default AnswerFieldsFragment
