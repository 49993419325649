import gql from 'graphql-tag'

const DirectoryCompanyProfileFragment = gql`
  fragment CompanyProfileFragment on CompanyProfile {
    id
    url
    companyInfo: company {
      name
      id
      createdAt
    }
    directoryInfo(withDirectoryTypesenseData: $withDirectoryTypesenseData) {
      teamAdminEmails
      delegationNames
      categoryNames
      teamAdminIds
      teamAdmins {
        id
        email
      }
      delegationIds
      delegations {
        id
        name
      }
      categories {
        id
        name
      }
      categoryIds
      totalCallsForSubmission
    }
  }
`

export default DirectoryCompanyProfileFragment
