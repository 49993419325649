import styled from 'styled-components/native'
import { View } from 'react-native'

export default styled(View)`
  flex: ${({ isFullScreenWeb }) => (isFullScreenWeb ? 1 : '1 1 auto')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 70px;
  padding: 0px 16px;
  border-bottom-color: ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
`
