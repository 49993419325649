import { atomFamily } from 'recoil'
import { MarketSegmentsMetaData } from '../types'

export const marketSegmentsMetaDataAtomFamily = atomFamily<
  MarketSegmentsMetaData | null,
  string
>({
  key: 'marketSegmentsMetaDataState',
  default: null
})
