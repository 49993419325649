import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'
import { UseTableRowOptions } from '../types'

const useTableRow = ({ config }: UseTableRowOptions<any>) => {
  let { updateMutation, removeMutation, refetchQueries } = config

  // possibly empty, userMutation errors out if it does not get something it can parse and these being hooks makes conditional statements difficult
  updateMutation =
    updateMutation ||
    gql`
      mutation {
        none
      }
    `
  removeMutation =
    removeMutation ||
    gql`
      mutation {
        none
      }
    `

  const [updateItem, { loading: updateLoading }]: any[] = useMutation(
    updateMutation,
    {
      refetchQueries,
      skip: !updateMutation
    }
  )

  const [removeItem, { loading: removeLoading }]: any[] = useMutation(
    removeMutation,
    {
      refetchQueries,
      skip: !removeMutation
    }
  )

  const remove = variables => removeItem({ variables })

  const update = variables => updateItem({ variables })

  return {
    remove,
    loading: removeLoading || updateLoading,
    update
  }
}

export default useTableRow
