import { useEffect } from 'react'
import gql from 'graphql-tag'

import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import useCurrentQuestion from '../../../hooks/useCurrentQuestion'
import { QuestionState } from '../../../recoil/currentQuestionAtom'
import sentry from '../../../utils/sentry'

export const questionQuery = gql`
  query question($id: String!, $formSubmissionId: String) {
    question(id: $id) {
      id
      questionText
      type
      style
      dataEntityType
      answer(formSubmissionId: $formSubmissionId) {
        id
        questionId
        answerEntityId
        answerEntityType
        formSubmissionId
        answerFileAttachmentsMap {
          id
          name
          answerId
          fileAttachmentId
          fileAttachment {
            id
            fileName
            cloudinaryId
            moderationStatus
          }
          createdAt
          origin {
            id
            source
          }
        }
      }
      dynamicAnswers(formSubmissionId: $formSubmissionId) {
        id
        questionId
        answerEntityId
        answerEntityType
        value
        formSubmissionId
        answerFileAttachmentsMap {
          id
          name
          answerId
          fileAttachmentId
          fileAttachment {
            id
            fileName
            cloudinaryId
            moderationStatus
          }
          createdAt
          origin {
            id
            source
          }
        }
      }
      __typename
    }
  }
`

const useQuestionQuery = (id: string, formSubmissionId: string) => {
  const { t } = useTranslation()
  const {
    currentQuestion,
    answers,
    currentAttachments,
    setCurrentQuestion
  } = useCurrentQuestion(id)

  const { data, loading, refetch } = useQuery(questionQuery, {
    variables: {
      id: id,
      formSubmissionId: formSubmissionId
    },
    skip: !id,
    errorMessage: t('error:product:get')
  })

  useEffect(() => {
    if (data && data?.question) {
      const answer = data.question?.answer
      const dynamicAnswers = data.question?.dynamicAnswers ?? []
      const filteredDyanmicAnswers = dynamicAnswers?.filter(
        (dynamicAnswer: any) =>
          dynamicAnswer.formSubmissionId === formSubmissionId
      )

      if (
        (answer && answer?.formSubmissionId !== formSubmissionId) ||
        dynamicAnswers?.length !== filteredDyanmicAnswers.length
      ) {
        sentry.captureException(
          new Error(
            `Innovation: formSubmissionId mismatch ${formSubmissionId} does not match the returned answer ${answer?.formSubmissionId}`
          )
        )
      }

      const questionState = {
        currentQuestion: data?.question,
        currentAttachments:
          answer?.formSubmissionId === formSubmissionId
            ? answer?.answerFileAttachmentsMap
            : null,
        answers: filteredDyanmicAnswers
      } as QuestionState

      setCurrentQuestion(questionState)
    }
  }, [data])

  return {
    question: currentQuestion,
    answers,
    currentAttachments,
    loading,
    refetchQuestion: refetch
  }
}

export default useQuestionQuery
