import React, { FC } from 'react'

import { View, StyleSheet } from 'react-native'

import Svg, { G, Circle } from 'react-native-svg'
import Background from './Background'

interface DonutChartProps {
  color: string
  contrastColor: string
  percentage?: number
  radius?: number
}

const RADIUS = 50
const STROKE_WIDTH = 19

const DonutChart: FC<DonutChartProps> = ({
  children,
  percentage = 0,
  radius = RADIUS,
  color,
  contrastColor
}) => {
  const halfCircle = radius + STROKE_WIDTH
  const circleCircumference = 2 * Math.PI * radius

  const strokeDashoffset =
    circleCircumference - (circleCircumference * percentage) / 100

  return (
    <View style={styles.container}>
      <View style={styles.background}>
        <Background />
      </View>
      <View style={styles.graphWrapper}>
        <Svg
          height={radius * 2}
          width={radius * 2}
          viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
        >
          {/* Using transform with rotate for react-native-web support */}
          <G transform={`rotate(-90, ${halfCircle}, ${halfCircle})`}>
            <Circle
              cx="50%"
              cy="50%"
              r={radius}
              fill="transparent"
              stroke={contrastColor}
              strokeWidth={STROKE_WIDTH}
            />
            <Circle
              cx="50%"
              cy="50%"
              r={radius}
              stroke={color}
              fill="transparent"
              strokeLinecap="round"
              strokeWidth={STROKE_WIDTH}
              strokeDasharray={circleCircumference}
              strokeDashoffset={strokeDashoffset}
            />
          </G>
        </Svg>
        <View style={styles.content}>{children}</View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  graphWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 15
  },
  content: {
    position: 'absolute',
    textAlign: 'center'
  },
  background: {
    position: 'absolute',
    right: 0
  }
})

export default DonutChart
