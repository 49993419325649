import { useSetRecoilState, useRecoilValue } from 'recoil'
// Atoms
import presentationsPersistSelectAtom, {
  PresentationsPersistSelectProps
} from '../recoil/presentationsPersistSelectAtom'
// Interfaces
interface SetPresentationsInput {
  submissionId: string
  groupId: string
  flag: string
  value: boolean
}
interface PresentationsPersistSelectResult {
  setPresentations: (input: SetPresentationsInput) => void
}

export const usePresentationsPersistSelect = (): PresentationsPersistSelectResult => {
  const setState = useSetRecoilState<PresentationsPersistSelectProps>(
    presentationsPersistSelectAtom
  )

  // Functions
  const setPresentations = ({
    submissionId,
    groupId,
    flag,
    value
  }: SetPresentationsInput) => {
    setState(currentPresentations => ({
      ...currentPresentations,
      [submissionId]: {
        ...currentPresentations?.[submissionId],
        [groupId]: {
          ...currentPresentations?.[submissionId]?.[groupId],
          [flag]: value
        }
      }
    }))
  }

  return { setPresentations }
}

export const useGetPersistedPresentationsSelection = (args: {
  submissionId: string
  groupId: string
  flag: string
}): boolean | null => {
  const presentationsState = useRecoilValue<PresentationsPersistSelectProps>(
    presentationsPersistSelectAtom
  )

  return presentationsState?.[args?.submissionId]?.[args?.groupId]?.[
    args.flag
  ] !== undefined
    ? presentationsState[args?.submissionId][args.groupId][args.flag]
    : null
}
