import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import {
  Cell,
  HeaderComponentsContainer,
  RowContainer
} from '../SharedStyledComponents'
import { TableContext } from '../../../hooks/TableProvider'
import HeaderCell from './HeaderCell'
import { getCellWidth } from '../../../utils'
import { HeaderProps } from '../types'
import SelectAllElements from './SelectAllElements'

const Header = (props: HeaderProps): any => {
  const {
    config: { headerContainerStyle, enableSelectAllElements = true, isSticky },
    fixedColumns,
    dynamicColumns,
    leftTableWidth,
    rigthTableWidth,
    selectedAll,
    data,
    totalElements
  } = useContext(TableContext)
  const { leftTable, headerRef } = props

  const columns = leftTable ? fixedColumns : dynamicColumns

  const showSelectAllElementsText =
    selectedAll && data && data.length < totalElements

  const getColumnCellWidth = (width: Number | undefined) =>
    getCellWidth(
      width?.valueOf() ?? 0,
      leftTable,
      leftTableWidth,
      rigthTableWidth
    )

  const HeaderRow = (
    <HeaderComponentsContainer testID={'tableHeader'}>
      <RowContainer
        leftTable={leftTable}
        isSticky={isSticky}
        marginLeft={headerContainerStyle?.marginLeft}
        marginRight={headerContainerStyle?.marginRight}
        marginBottom={headerContainerStyle?.marginBottom}
      >
        {columns.map((col, i) => {
          const {
            sort,
            header,
            width,
            headerStyle = {},
            isSelectable = false,
            subtitle = [],
            cellStyle,
            sortContainerStyle = {}
          } = col
          return (
            <Cell
              width={getColumnCellWidth(width)}
              key={`${header}_${i}`}
              hasSubtitle
              cellStyle={cellStyle}
            >
              <HeaderCell
                sort={sort}
                headerStyle={headerStyle}
                isSelectable={isSelectable}
                header={header}
                subtitle={subtitle}
                sortContainerStyle={sortContainerStyle}
              />
            </Cell>
          )
        })}
      </RowContainer>
      {showSelectAllElementsText ? (
        <SelectAllElements
          leftTable={leftTable}
          enableSelectAllElements={enableSelectAllElements}
        />
      ) : null}
    </HeaderComponentsContainer>
  )

  if (headerRef && headerRef.current)
    return ReactDOM.createPortal(HeaderRow, headerRef.current)

  return HeaderRow
}

export default Header
