import React, { useCallback, useMemo } from 'react'

import { pick } from 'ramda'
import { useField } from 'formik'
import { useRecoilValue } from 'recoil'
// Components
import CheckBox, { CheckBoxInputProps } from '../../ui-library/CheckBox'
// Hooks
import useQuestionDependency from './hooks/useQuestionDependency'
// Recoil
import { formQuestionsConditionsAtomFamily } from '../../recoil/formQuestionsConditionsAtomFamily'
// Types
import { JSONStyles, Question } from './types'
import { CallForSubmission } from '../../types'

export type CheckInputStyles = Pick<
  JSONStyles,
  | 'checkedContainerStyles'
  | 'containerStyles'
  | 'errorContainerStyles'
  | 'labelStyles'
  | 'styles'
>

export interface CheckboxInputDataProps {
  key: string
  name: string
  label: string
  styles?: CheckInputStyles
  isHidden?: boolean
  children?: JSX.Element | JSX.Element[]
  checkedIconName?: string
  formSubmissionId: string
  questionDependencyIds?: string[]
  questionId: string
  academyElegible?: boolean
}

interface DynamicCheckboxInputDataProps extends CheckBoxInputProps {
  data: CheckboxInputDataProps
  isReadOnly?: boolean
}

const DynamicCheckBox = (props: DynamicCheckboxInputDataProps) => {
  const { data } = props
  const {
    key,
    name,
    styles,
    isHidden,
    children,
    checkedIconName,
    formSubmissionId,
    questionId,
    questionDependencyIds = [],
    academyElegible = false
  } = data

  const formQuestionCondition = useRecoilValue(
    formQuestionsConditionsAtomFamily(formSubmissionId)
  )

  const questionCondition = useMemo(() => {
    return formQuestionCondition?.[questionId] || null
  }, [questionId, formQuestionCondition])

  const label = useMemo(() => {
    return `${data.label} ${questionCondition?.customMsg ?? ''}`
  }, [data.label, questionCondition?.customMsg])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField({
    name: name,
    type: 'checkbox'
  })
  const { visible } = useQuestionDependency(
    questionDependencyIds,
    questionId,
    field?.value
  )

  const isReadOnly = useMemo(() => {
    const { isReadOnly = false } = props
    const isDisabled = questionCondition?.isDisabled() ?? false
    if (isReadOnly) {
      return isReadOnly
    }
    if (isDisabled) {
      // If is disabled we allow only to uncheck it if it's current value is true
      return field.value ? false : isDisabled
    }
    return isReadOnly
  }, [props.isReadOnly, questionCondition])

  const handleChange = useCallback(() => {
    if (!isReadOnly) {
      const newValue = !field.value
      helpers.setValue(!newValue ? null : newValue, false)

      questionCondition?.onChange &&
        questionCondition?.onChange({
          initialValue: meta.initialValue,
          newValue: !newValue ? null : newValue
        })
    }
  }, [field.value, isReadOnly])

  const renderChildrenComponent = () => {
    if (children && field.checked) {
      return children
    }
    return null
  }
  if (checkedIconName === 'academy' && !academyElegible) {
    return null
  }
  return (
    visible && (
      <CheckBox
        key={key}
        label={label}
        checked={!!field.checked}
        onPress={handleChange}
        renderChildrenComponent={renderChildrenComponent}
        isReadOnly={isReadOnly}
        isHidden={isHidden}
        flexContainerStyles={
          field.checked ? styles?.checkedContainerStyles : {}
        }
        containerStyles={styles?.containerStyles}
        checkedIconName={checkedIconName}
      />
    )
  )
}

export const transformCheckBoxInputData = (
  question: Question,
  formSubmissionId: string,
  callForSubmission?: CallForSubmission
): CheckboxInputDataProps => {
  return {
    key: `questions-${question?.id}`,
    name: `questions.${question?.id}`,
    label: question?.questionText,
    styles: pick(
      ['containerStyles', 'labelStyles', 'styles'],
      question?.style || {}
    ),
    checkedIconName: question?.style?.checkedIconName,
    formSubmissionId,
    questionId: question?.id,
    questionDependencyIds: question?.questionDependencies?.map(qd => qd?.id),
    academyElegible: callForSubmission?.academyElegible ?? false
  }
}

export default DynamicCheckBox
