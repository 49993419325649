import {
  useQuery as RAuseQuery,
  useLazyQuery as RAuseLazyQuery
} from '@apollo/client'
import useToast from './useToast'
import Sentry from '../utils/sentry'

const useQuery = <QueryResultType = any>(query, options: any = {}) => {
  const { setToastErrorMessage } = useToast()
  const {
    onError,
    errorMessage,
    onCompleted,
    skipErrorThrow,
    ...queryOptions
  } = options

  const result = RAuseQuery(query, {
    ...queryOptions,
    onError: error => {
      if (onError) {
        onError(error)
      }
      Sentry.captureException(error)

      if (errorMessage && !skipErrorThrow?.(error)) {
        const errorObj = {
          error,
          errorMessage
        }
        setToastErrorMessage(errorMessage)
        throw errorObj
      }
    },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(data)
      }
    }
  })

  return {
    ...result,
    data: result.data as QueryResultType | null
  }
}

export default useQuery

const useLazyQuery = (query, options: any = {}) => {
  const { onError, errorMessage, onCompleted, ...queryOptions } = options

  const result = RAuseLazyQuery(query, {
    ...queryOptions,
    onError: error => {
      if (onError) {
        onError(error)
      }
      //   Sentry.captureException(error)
      if (errorMessage) {
        const errorObj = {
          error,
          errorMessage
        }
        throw errorObj
      }
    },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(data)
      }
    }
  })

  return result
}

export { useLazyQuery }
