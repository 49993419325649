import React, { useState } from 'react'
import { path, split } from 'ramda'

import TextInput from '../../../ui-library/TextInput'
import useTranslation from '../../../hooks/useTranslation'
import Theme from '../../../constants/Theme'

const commonStyles = {
  textInputs: {
    width: '',
    margin: 0,
    borderWidth: 0,
    backgroundColor: Theme.colors.tableCellBackground,
    borderRadius: 0,
    color: Theme.colors.disabled
  },
  containerStyles: { width: '', padding: 0, margin: 0 },
  dropDownStyles: {
    marginBottom: 0,
    width: 'auto',
    minWidth: '100%',
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: Theme.colors.tableCellBackground
  }
}

const StandardInput = ({ item, itemMap, handlers, isEditable, label }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(path(split('.', itemMap), item))

  return (
    <TextInput
      value={value}
      onChangeText={text => setValue(text)}
      label={label && t(label)}
      editable={isEditable}
      onBlur={() =>
        isEditable && handlers && handlers.update({ ...item, [itemMap]: value })
      }
      selectTextOnFocus={isEditable}
      style={commonStyles.textInputs}
      containerStyles={commonStyles.containerStyles}
      tabIndex={-1}
    />
  )
}

export default StandardInput
