import { SmallRow } from '../../../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../../../components/DynamicTable/components/Table/SharedStyledComponents'
import { TableConfig } from '../../../../components/Table'
import { commonFixedColumns } from '../../../Curation/constants'
import { TableNameEnum } from '../../../../components/DynamicTable/types'
import { EngineEnum, newSelectItemColumn } from '../../constants'
import CompanyProfileFragment from '../../../../graphql/CompanyProfileFragment'
import {
  categoryColumn,
  companyNameColumn,
  delegationColumn
} from '../../../InnovatorDirectory/constants'

const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}

export const TEST_SETTINGS_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: 'TestSettingsTable',
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

export const getTestSubmissionTableConfig = () => {
  const table = Object.assign(
    {},
    TEST_SETTINGS_TABLE_CONFIG
  ) as TableConfig<any>
  table.queryDynamicName = 'directoryProfileSearchInnovationEngine'
  table.name = TableNameEnum.TestSettingsCreateSubmissions

  const columns = [
    newSelectItemColumn,
    companyNameColumn,
    delegationColumn,
    categoryColumn
  ]

  table.columns = columns.map(column => ({
    ...column,
    fixed: !![...commonFixedColumns, 'delegationColumn'].filter(
      fixedColumn => fixedColumn === column?.id
    ).length
  }))

  table.enableFilters = true
  table.skipCache = true

  table.isSticky = true
  table.engine = EngineEnum.InnovationInnovatorDirectoryEngine
  table.filters = {}
  table.customQueryFields = CompanyProfileFragment
  table.showTotalElements = true
  table.enableSelectAllElements = false

  return table
}
