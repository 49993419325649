import { useCallback, useState } from 'react'
// Hooks/Utils
import useToast from '../../../hooks/useToast'
import useSetCompanyLogo from './useSetCompanyLogo'
import useDeleteCompanyLogo from './useDeleteCompanyLogo'
import useTranslation from '../../../hooks/useTranslation'
import uploadImageToCloudinary from '../../../utils/uploadImageToCloudinary'
// Types
import { Company } from '../../../types'
import { MediaI, CustomCrop } from '../components/ImageViewer'
import { ApolloError } from '@apollo/client'
import { TFunction, TFunctionResult } from 'i18next'

interface CompanyLogoEditResult {
  // Loading indicators
  isDeleting: boolean
  isUploading: boolean
  isUploadCompleted: boolean
  setCompanyLogoLoading: boolean
  cloudinaryProgress: number
  // Events
  editCompanyLogo: (
    image: MediaI,
    crop?: CustomCrop,
    onComplete?: () => void
  ) => void
  uploadCompanyLogo: (
    image: MediaI,
    crop?: CustomCrop,
    onComplete?: () => void
  ) => void
  removeCompanyLogo: (onComplete?: () => void) => void
}

const DEFAULT_ERR_TIMEOUT = 5000

const formatCropping = cropping => {
  if (!cropping) return
  return JSON.stringify({
    ...cropping,
    shape: 'rect',
    unit: cropping.unit || 'px'
  })
}

const errorMsgHandler = (
  error: ApolloError | any,
  t: TFunction,
  type: 'upload' | 'delete' = 'upload'
): TFunctionResult | string => {
  if (
    error instanceof ApolloError &&
    (error.graphQLErrors || error.networkError)
  ) {
    return t(`directoryProfile:companyLogoUploader:errMsg:${type}:server`)
  }
  return t(`directoryProfile:companyLogoUploader:errMsg:${type}:unknownError`)
}

const useCompanyLogoEdit = (company: Company): CompanyLogoEditResult => {
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadCompleted, setIsUploadCompleted] = useState(false)
  const [cloudinaryProgress, setCloudinaryProgress] = useState(0)

  const companyId = company?.id

  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()

  // Mutations
  const { setCompanyLogo, loading: setCompanyLogoLoading } = useSetCompanyLogo()
  const {
    deleteCompanyLogo,
    loading: deleteCompanyLogoLoading
  } = useDeleteCompanyLogo(companyId)

  const uploadCompanyLogo = useCallback(
    async (image: MediaI, crop?: CustomCrop, onComplete?: () => void) => {
      setIsUploading(true)
      setIsUploadCompleted(false)
      setCloudinaryProgress(0)
      try {
        const signedUploadRequestVariables = { resourceType: 'image' }

        const result: any = await uploadImageToCloudinary(
          image.file,
          signedUploadRequestVariables as any,
          progress => {
            setCloudinaryProgress(progress)
          }
        )

        await setCompanyLogo({
          companyId,
          cropping: formatCropping(crop),
          cloudinaryImage: {
            cloudinaryId: result.public_id
          }
        })
        setIsUploadCompleted(true)
        await delay(1000)
        onComplete?.()
      } catch (uploadCompanyLogoError) {
        console.error({ uploadCompanyLogoError })
        setToastErrorMessage(
          errorMsgHandler(uploadCompanyLogoError, t),
          DEFAULT_ERR_TIMEOUT
        )
        return
      } finally {
        setIsUploading(false)
      }
    },
    [companyId]
  )

  const editCompanyLogo = useCallback(
    async (image: MediaI, crop?: CustomCrop, onComplete?: () => void) => {
      setIsUploading(true)
      setIsUploadCompleted(false)
      setCloudinaryProgress(75)
      try {
        if (!image?.cloudinaryId) throw new Error('No image provided')

        await setCompanyLogo({
          companyId,
          cropping: formatCropping(crop),
          cloudinaryImage: {
            cloudinaryId: image.cloudinaryId
          }
        })
        setIsUploadCompleted(true)
        await delay(1000)
        onComplete?.()
      } catch (editCompanyLogoError) {
        console.error({ editCompanyLogoError })
        setToastErrorMessage(
          errorMsgHandler(editCompanyLogoError, t),
          DEFAULT_ERR_TIMEOUT
        )
        return
      } finally {
        setIsUploading(false)
      }
    },
    []
  )

  const removeCompanyLogo = useCallback(async () => {
    try {
      await deleteCompanyLogo({ companyId })
    } catch (removeCompanyLogoError) {
      console.error({ removeCompanyLogoError })
      setToastErrorMessage(
        errorMsgHandler(removeCompanyLogoError, t, 'delete'),
        DEFAULT_ERR_TIMEOUT
      )
    }
  }, [companyId])

  return {
    isDeleting: deleteCompanyLogoLoading,
    isUploading,
    isUploadCompleted,
    setCompanyLogoLoading,
    cloudinaryProgress,
    editCompanyLogo,
    uploadCompanyLogo,
    removeCompanyLogo
  }
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default useCompanyLogoEdit
