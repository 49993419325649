import React, { useEffect } from 'react'
import {
  getFocusedRouteNameFromRoute,
  useRoute
} from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'
import useCallForSubmissionQuery from './hooks/useCallForSubmissionQuery'
import useNewCallForSubmissionQuery from './hooks/useNewCallForSubmissionQuery'

import {
  ScreenContainerWithMenuHeader,
  ShadowContainerWithTabs
} from '../../components/layout/ScreenContainer'
import TopTabBar from '../../components/layout/TopTabBar'
import TopTabBarMobile from '../../components/layout/TopTabBarMobile'
import CurationTabsHeader from './components/CurationTabsHeader'

import Submissions from './Submissions'
import PreCuration from './Precuration'
import Advising from './Advising'
import Cohort from './Cohort'
import Presentations from './Presentations'

import { RoleEnum } from '../../types'
import useHistoryRoutes from '../../hooks/useHistoryRoutes'
import SkeletonContainer from '../../components/skeletonLoadings/SkeletonContainer'
import StaffHomeSkeleton from '../HomeScreen/StaffHomeSkeleton'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'

const Tabs = createMaterialTopTabNavigator()

const prefix = 'Curation'

const CurationTabs = () => {
  const { trackWithProperties } = useMixpanel()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)

  const route: any = useRoute()
  const id = route?.params?.id
  const { history, pushToHistory } = useHistoryRoutes()
  const { callForSubmission, loading } = useCallForSubmissionQuery(
    isInnovationNewDynamicTable ? false : id,
    false,
    false,
    true
  )
  const {
    callForSubmission: newCallForSubmission,
    loading: loadingNewCFS
  } = useNewCallForSubmissionQuery(id, !isInnovationNewDynamicTable)
  const currentRouteName = getFocusedRouteNameFromRoute(route)

  // During deepLink in any of the curation tabs we don't have anything in the history
  // to go back to after going into the details page. So we push the current tab
  useEffect(() => {
    if (!history.length) {
      pushToHistory({
        screen: currentRouteName
      })
    }
  }, [history, pushToHistory, currentRouteName])

  const isSmallScreen = useIsSmallScreen()
  const tabScreens = [
    {
      name: 'Submissions',
      Component: Submissions,
      options: {
        title: `Submissions`,
        webTitle: 'Submissions',
        disabled: false
      }
    },
    {
      name: 'PreCuration',
      Component: PreCuration,
      options: {
        title: `PreCuration`,
        webTitle: 'PreCuration',
        disabled: false
      }
    },
    {
      name: 'Advising',
      Component: Advising,
      options: {
        title: `Advising`,
        webTitle: 'Advising',
        disabled: false
      }
    },
    {
      name: 'Cohort',
      Component: Cohort,
      options: {
        title: `Cohort`,
        webTitle: 'Cohort',
        disabled: false
      }
    },
    {
      name: 'Presentations',
      Component: Presentations,
      options: {
        title: `Presentations`,
        webTitle: 'Presentations',
        disabled: false
      }
    }
  ]

  const cfs = isInnovationNewDynamicTable
    ? newCallForSubmission
    : callForSubmission
  // Bug relating to browser resize and tabs not centering - https://github.com/react-navigation/react-navigation/issues/8837
  return (
    <ScreenContainerWithMenuHeader screenCategory="curation">
      <ShadowContainerWithTabs>
        <SkeletonContainer
          isLoading={isInnovationNewDynamicTable ? loadingNewCFS : loading}
          Skeleton={StaffHomeSkeleton}
        >
          <CurationTabsHeader callForSubmission={cfs} />

          <Tabs.Navigator
            swipeEnabled={false}
            tabBar={props =>
              !isSmallScreen ? (
                <TopTabBar {...props} />
              ) : (
                <TopTabBarMobile {...props} />
              )
            }
          >
            {tabScreens.map(({ name, Component, options }) => (
              <Tabs.Screen
                key={`${prefix}${name}`}
                name={`${prefix}${name}`}
                children={() =>
                  `${prefix}${name}` === currentRouteName ? (
                    <Component
                      id={id}
                      role={RoleEnum.InnovationStaff}
                      currentCFSStep={cfs?.currentStep?.name}
                      delegations={cfs?.delegations}
                      reviewFormQuestions={cfs?.reviewFormQuestions}
                      callForSubmission={cfs}
                      canEditAll={cfs?.canEditSubmissions}
                    />
                  ) : null
                }
                options={options}
                listeners={{
                  tabPress: () => {
                    trackWithProperties(EVENT_MAP.click.tab, {
                      tabName: name
                    })
                  }
                }}
              />
            ))}
          </Tabs.Navigator>
        </SkeletonContainer>
      </ShadowContainerWithTabs>
    </ScreenContainerWithMenuHeader>
  )
}

export default CurationTabs
