import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import CurationTabs from './CurationTabs'
import SubmissionDetails from './SubmissionDetails'
import CurationAdvisorDetail from './CurationAdvisorDetail'
import { CurationNavigatorParamList } from '../../types/rootStackParamsList'

const CurationStack = createStackNavigator<CurationNavigatorParamList>()

const CurationNavigator = () => (
  <CurationStack.Navigator
    screenOptions={{ headerShown: false }}
    initialRouteName="CurationTabs"
  >
    <CurationStack.Screen name="CurationTabs" component={CurationTabs} />
    <CurationStack.Screen
      name="CurationSubmissionDetails"
      component={SubmissionDetails}
    />
    <CurationStack.Screen
      name="CurationAdvisorDetail"
      component={CurationAdvisorDetail}
    />
  </CurationStack.Navigator>
)

export default CurationNavigator
