import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import useTranslation from '../../../hooks/useTranslation'
import { RoleEnum } from '../../../types'
import { useUnclaimableDomainsQuery } from '../../authentication/queries/useRegistrationDataQuery'

const useGetInviteValidationSchema = () => {
  const { t } = useTranslation()
  const {
    data,
    loading: loadingRegistrationData
  } = useUnclaimableDomainsQuery()

  const baseSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name Required'),
    lastName: Yup.string().required('Last Name Required'),
    jobTitle: Yup.string().required('Title Required'),
    role: Yup.mixed<RoleEnum>()
      .oneOf(Object.values(RoleEnum))
      .required(t('validation:error:required')),
    innovatorCommsPlatformAdmin: Yup.boolean().notRequired(),
    innovatorCommsCFS: Yup.boolean().notRequired(),
    innovatorCommsCFSSelectionAndPresentation: Yup.boolean().notRequired(),
    innovatorCommsInvoicing: Yup.boolean().notRequired()
  })
  const baseEmailSchema = Yup.string()
    .email('Invalid email')
    .required('Required')

  const [schema, setSchema] = useState(
    baseSchema.shape({
      email: baseEmailSchema
    })
  )

  useEffect(() => {
    if ((data?.unclaimableEmailDomains?.length || 0) > 0) {
      const prohibitsDomains = [
        ...(data?.unclaimableEmailDomains?.map(d => d.domain) || []),
        ...(data?.blacklistedDomains?.map(d => d.domain) || [])
      ]

      Yup.addMethod(Yup.string, 'domainCheck', function (errorMessage) {
        return this.test(`domainCheck`, errorMessage, function (value) {
          const { path, createError } = this

          const hasProhibitDomain = prohibitsDomains?.find(domain =>
            value?.toLowerCase().includes(domain)
          )
          if (hasProhibitDomain) {
            return createError({ path, message: errorMessage })
          }
          return true
        })
      })

      // @ts-ignore
      const domainCheckEmail = Yup.string().domainCheck(
        t('error:auth:invalidEmailDomain')
      )

      const emailSchema = baseEmailSchema.concat(domainCheckEmail)

      setSchema(
        baseSchema.shape({
          email: emailSchema
        })
      )
    }
  }, [data?.unclaimableEmailDomains])

  return {
    schema,
    loading: loadingRegistrationData
  }
}

export default useGetInviteValidationSchema
