import { useSubscription } from '@apollo/client'
import callForSubmissionUpdatedSubscription from '../subscriptions/callForSubmissionUpdatedSubscription'
import { callsForSubmissionQuery } from './useCallsForSubmissionQuery'
import { RoleEnum } from '../../../types'

const onCallForSubmissionUpdated = (client, subscriptionData, query, role) => {
  const options = { query, variables: { role } }

  const cachedQuery = client.readQuery({
    ...options
  })
  const updatedCallForSubmission =
    subscriptionData.data.callForSubmissionUpdated

  const updatedCallsForSubmission = (cachedQuery?.callsForSubmission || []).map(
    callForSubmission => {
      if (callForSubmission.id !== updatedCallForSubmission.id) {
        return callForSubmission
      }
      return updatedCallForSubmission
    }
  )

  client.writeQuery({
    ...options,
    data: {
      ...cachedQuery,
      callsForSubmission: updatedCallsForSubmission
    }
  })
}

const useUpdatedCallForSubmissionSubscription = (role: RoleEnum) => {
  useSubscription(callForSubmissionUpdatedSubscription, {
    variables: {
      role
    },
    onData: ({ client, data }) => {
      onCallForSubmissionUpdated(client, data, callsForSubmissionQuery, role)
    }
  })
}

export default useUpdatedCallForSubmissionSubscription
