import { useMemo, useState, useCallback, useEffect } from 'react'

import gql from 'graphql-tag'
// Hooks
import useQuery from '../../../hooks/useQuery'
import useTranslation from '../../../hooks/useTranslation'
import useGetOptionEntityIds, { MetadataProps } from './useGetOptionEntityIds'
// Components
import { QuestionOptionsArgs, Option } from '../DropDown'

const questionOptionsQuery = gql`
  query getQuestionOptions($args: OptionsArgsInput) {
    getQuestionOptions(args: $args) {
      id
      name
      value
      type
    }
  }
`

const useDropdownOptions = (
  { optionsEntity, optionsValues, subType }: QuestionOptionsArgs,
  metaData: MetadataProps | null,
  withDeleted = false
) => {
  const { t } = useTranslation()
  const [options, setOptions]: [Option[], any] = useState([])

  const [optionsEntityIds] = useGetOptionEntityIds(metaData)

  const variables = useMemo(
    () =>
      optionsEntity && optionsEntityIds?.length
        ? {
            args: {
              optionsEntity,
              optionsEntityIds,
              withDeleted
            }
          }
        : null,
    [optionsEntity, optionsEntityIds]
  )

  const getOptions = useCallback(unformattedOptions => {
    let dropDownList: Option[] = (unformattedOptions || []).map(
      ({ name, value }) => ({
        label: name,
        value
      })
    )
    dropDownList = [...dropDownList]
    setOptions(dropDownList)
  }, [])

  const { loading } = useQuery(questionOptionsQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: subType !== 'dbLookup' || !optionsEntity || !variables,
    errorMessage: t('error:form:getOptions'),
    onCompleted: data => {
      getOptions(data?.getQuestionOptions)
    }
  })

  useEffect(() => {
    if (Array.isArray(optionsValues) && optionsValues?.length) {
      getOptions(optionsValues)
    }
  }, [optionsValues])

  return {
    options,
    loading
  }
}

export default useDropdownOptions
