import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { CompanyProfile } from '../../../types'

const setCompanyLogoMutation = gql`
  mutation setCompanyLogo(
    $companyId: String!
    $imageId: String
    $cloudinaryImage: CloudinaryUploadObject
    $cropping: String
  ) {
    setCompanyLogo(
      companyId: $companyId
      imageId: $imageId
      cloudinaryImage: $cloudinaryImage
      cropping: $cropping
    ) {
      id
      logo {
        id
        cloudinaryId
      }
      logoCropping
    }
  }
`

interface SetCompanyLogoVariables {
  companyId: string
  imageId?: string
  cropping?: string
  cloudinaryImage: {
    cloudinaryId: string
  }
}

interface SetCompanyLogoResult {
  setCompanyLogo: (
    variables: SetCompanyLogoVariables
  ) => Promise<CompanyProfile | null>
  loading: boolean
}

const useSetCompanyLogo = (): SetCompanyLogoResult => {
  const [setLogo, { loading }] = useMutation(setCompanyLogoMutation)

  const setCompanyLogo = async (variables: SetCompanyLogoVariables) => {
    const { data } = await setLogo({ variables })
    return data
  }

  return { setCompanyLogo, loading }
}

export default useSetCompanyLogo
