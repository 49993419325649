/**
 * This is to avoid warning errors when passing props that the React-Native Element that are not
 * supported by the component. This props are only specific for the styled-component
 */
export const propsToFilter = [
  'label',
  'labelStyles',
  'renderRightComponent',
  'containerStyles',
  'hasError',
  'wrapperStyles',
  'isReadOnly',
  'helperText',
  'withHelperText',
  'isDisabled',
  'asPrintable',
  'dateIconColor'
]
