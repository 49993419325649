import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'

export const deleteNonOnboardedInnovatorMutation = gql`
  mutation deleteNonOnboardedInnovator($id: String!) {
    deleteNonOnboardedInnovator(id: $id)
  }
`

const useDeleteNonOnboardedInnovator = () => {
  const [deleteNonOnboarded, { loading }]: any[] = useMutation(
    deleteNonOnboardedInnovatorMutation
  )

  const cancelRegistration = async userId => {
    const { data } = await deleteNonOnboarded({
      variables: {
        id: userId
      }
    })

    return data
  }

  return {
    cancelRegistration,
    loading
  }
}

export default useDeleteNonOnboardedInnovator
