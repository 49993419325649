import React, { useContext } from 'react'
import { TableContext } from '../../hooks/TableProvider'
import SearchBox from './SearchBox'
import Buttons from './Buttons'
import { SearchBarContainer, Container } from './SharedStyledComponents'
import Filters from './Filters'

const SearchBar = (): any => {
  const {
    config: { isAlternativeView }
  } = useContext(TableContext)

  return (
    <Container isAlternativeView={isAlternativeView}>
      <SearchBarContainer isAlternativeView={isAlternativeView}>
        <SearchBox />
        <Buttons />
      </SearchBarContainer>
      <Filters />
    </Container>
  )
}

export default SearchBar
