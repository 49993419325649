import gql from 'graphql-tag'

const innovationSubmissionCreatedSubscription = gql`
  subscription innovationSubmissionCreated(
    $statuses: [InnovationSubmissionStatusEnum]
  ) {
    innovationSubmissionCreated(statuses: $statuses) {
      id
      callForSubmissionId
      status
      updatedAt
      callForSubmission {
        id
        name
        delegations {
          id
          name
        }
        marketSegments {
          id
          name
        }
      }
    }
  }
`

export default innovationSubmissionCreatedSubscription
