import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const EyeIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg
        width={size}
        height={size}
        x="0px"
        y="0px"
        viewBox={`0 0 128 128`}
        {...rest}
      >
        <Path
          d="M64 21C35.4 21 10.9 38.9 1 64c9.9 25.1 34.4 43 63 43s53.1-17.8 63-43c-9.9-25.1-34.4-43-63-43zm0 71.6c-15.8 0-28.6-12.8-28.6-28.6S48.2 35.4 64 35.4 92.6 48.2 92.6 64 79.8 92.6 64 92.6zm0-45.8c-9.5 0-17.2 7.7-17.2 17.2S54.5 81.2 64 81.2 81.2 73.5 81.2 64 73.5 46.8 64 46.8z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default EyeIcon
