import gql from 'graphql-tag'
// Hook
import useMutation from '../../../../../hooks/useMutation'

export const upsertSubmissionReviewForTestingByAdvisorMutation = gql`
  mutation upsertSubmissionReviewForTestingByAdvisor(
    $ids: [String!]
    $advisorId: String!
    $hasAdvised: Boolean
  ) {
    upsertSubmissionReviewForTestingByAdvisor(
      ids: $ids
      advisorId: $advisorId
      hasAdvised: $hasAdvised
    ) {
      id
      advisorSubmissionReview(advisorId: $advisorId) {
        hasAdvised
        favorite
        academy
      }
    }
  }
`

const useUpsertSubmissionReviewForTesting = (options = {}) => {
  const [upsertSubmissionReviewForTesting, { loading }]: any[] = useMutation(
    upsertSubmissionReviewForTestingByAdvisorMutation
  )

  const updateSubmissionReview = async (
    submissionIds: string[],
    advisorId: string,
    hasAdvised = false
  ) => {
    const results = await upsertSubmissionReviewForTesting({
      variables: {
        ids: submissionIds,
        advisorId,
        hasAdvised
      },
      ...options
    })

    return results
  }

  return [updateSubmissionReview, loading]
}

export default useUpsertSubmissionReviewForTesting
