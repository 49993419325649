import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import { Divider as RNDivider } from 'react-native-elements'

import { Flex } from '../../../../components/FlexBox'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

type Orientation = 'vertical' | 'horizontal'

export interface DividerProps {
  orientation?: Orientation
  width?: number
}

const Divider = ({
  orientation = 'vertical',
  width = 1,
  length = '70%',
  padding = '5%'
}) => {
  const defaultLength = '100%'
  const isVertical = orientation === 'vertical'

  const containerWidth = isVertical ? padding : length
  const containerHeight = isVertical ? length : padding
  const dividerLength = isVertical ? defaultLength : width
  const dividerWidth = isVertical ? width : defaultLength

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={containerWidth}
      height={containerHeight}
    >
      <RNDivider
        style={{
          height: dividerLength,
          width: dividerWidth,
          flexDirection: isVertical ? 'column' : 'row'
        }}
      >
        <LinearGradient
          style={{ flex: 1 }}
          colors={['transparent', 'white']}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0.5 }}
        />
        <LinearGradient
          style={{ flex: 1 }}
          colors={['white', 'transparent']}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0.5 }}
        />
      </RNDivider>
    </Flex>
  )
}

export default function () {
  const isSmallScreen = useIsSmallScreen()

  return (
    <Divider
      orientation={isSmallScreen ? 'horizontal' : 'vertical'}
      length={isSmallScreen ? '100%' : '70%'}
      padding={isSmallScreen ? '40px' : '8%'}
    />
  )
}
