import { atom, RecoilState } from 'recoil'

export interface CurrentQueryTable {
  currentTable: string
}

const currentQueryTable: RecoilState<CurrentQueryTable> = atom({
  key: 'currentQueryTable',
  default: {
    currentTable: ''
  } as CurrentQueryTable
})

export default currentQueryTable
