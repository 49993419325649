import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { MarketSegment } from '../../../types'

const marketSegments = gql`
  query marketSegments {
    marketSegments {
      id
      name
      innovationCategories {
        id
        name
        sort
      }
    }
  }
`

interface MarketSegmentObject {
  marketSegments: MarketSegment[]
  loading: boolean
}

const useMarketSegmentsQuery = (): MarketSegmentObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(marketSegments, {
    errorMessage: t('error:general:getMarketSegments')
  })

  return {
    marketSegments: data?.marketSegments,
    loading
  }
}

export default useMarketSegmentsQuery
