import { View } from 'react-native'
import styled from 'styled-components/native'
import W50Logo from '../../icon/W50Logo'
import React from 'react'

import useIsInBreakpoint from '../../../hooks/useIsInBreakpoint'
import { Breakpoint } from '../../../types/breakpoint'

export const LogoContainer = styled(View)`
  padding: ${props => props.theme.space[3] + props.theme.space[1]}px;
  padding-bottom: ${props => props.theme.space[4]}px;
`

const Logo = () => {
  const isSmallScreen = useIsInBreakpoint(
    Breakpoint.Tablet,
    Breakpoint.Desktop,
    Breakpoint.Phone
  )
  return isSmallScreen ? null : (
    <LogoContainer>
      <W50Logo width={127} height={50} />
    </LogoContainer>
  )
}

export default Logo
