import gql from 'graphql-tag'

import useMutation from '../../../hooks/useMutation'

export const removeInnovatorProductMutation = gql`
  mutation removeInnovatorProduct($id: String!) {
    removeInnovatorProduct(id: $id)
  }
`

const useRemoveInnovatorProduct = () => {
  const [removeInnovationProduct, { loading }]: any[] = useMutation(
    removeInnovatorProductMutation,
    {
      refetchQueries: ['company'],
      awaitRefetchQueries: true
    }
  )

  const removeProduct = async (id: string) => {
    const result = await removeInnovationProduct({
      variables: {
        id
      }
    })

    return result
  }

  return { removeProduct, loading }
}

export default useRemoveInnovatorProduct
