import { useMemo } from 'react'

import moment from 'moment-timezone'

// Hooks/Services/utils
import capitalize from '../../../utils/capitalize'
import useTranslation from '../../../hooks/useTranslation'
// Types
import {
  StepDateRange,
  CallForSubmission,
  CallForSubmissionStepEnum
} from '../../../types'

export enum CFSStepTimeStatus {
  upcoming = 'upcoming',
  open = 'open',
  finished = 'finished'
}

interface CFSStepDatesRangeDiffResult {
  cfsTitleStep: string | null
  cfsSubtitleStep: string | null
  cfsStepTimeStatus: CFSStepTimeStatus | null
}

const useCFSStepDatesRangeDiff = (
  cfs?: CallForSubmission
): CFSStepDatesRangeDiffResult => {
  const { t } = useTranslation()

  const formatStepDate = date =>
    date
      ? moment.tz(date, 'America/New_York').format('MM/DD/YY')
      : t('common:notAvailable')

  const getCFSCurrentTimeStatus = (
    steps: StepDateRange[]
  ): CFSStepTimeStatus => {
    // Handling upcoming step
    const submissionStep = steps.find(
      step => step.curationStep === CallForSubmissionStepEnum.submission
    )
    if (
      submissionStep &&
      submissionStep?.openAt &&
      moment().isBefore(moment.tz(submissionStep.openAt, 'America/New_York'))
    ) {
      return CFSStepTimeStatus.upcoming
    }

    // Handling finished step
    const eventStep = steps.find(
      step => step.curationStep === CallForSubmissionStepEnum.event
    )
    if (
      eventStep &&
      moment().isAfter(moment.tz(eventStep?.closeAt, 'America/New_York'))
    ) {
      return CFSStepTimeStatus.finished
    }

    return CFSStepTimeStatus.open
  }

  const cfsStepTimeStatus: CFSStepTimeStatus | null = useMemo(() => {
    if (cfs && cfs?.stepDateRanges?.length) {
      return getCFSCurrentTimeStatus(cfs.stepDateRanges)
    }
    return null
  }, [cfs])

  const [cfsTitleStep, cfsSubtitleStep]: string[] = useMemo(() => {
    let subtitle
    if (cfsStepTimeStatus) {
      if (cfsStepTimeStatus === CFSStepTimeStatus.open) {
        if (cfs?.currentStep?.name && cfs?.currentStep?.closeAt) {
          // In case the current step is not null
          const { name, closeAt } = cfs.currentStep
          const currentStepName = t(`callsForSubmission:step:name:${name}`)

          subtitle = `${capitalize(currentStepName)} ${
            moment().isAfter(closeAt)
              ? t(`callsForSubmission:status:${CFSStepTimeStatus.finished}`)
              : `(${formatStepDate(
                  cfs?.currentStep?.openAt
                )} - ${formatStepDate(cfs?.currentStep?.closeAt)})`
          }`
        } else if (cfs?.stepDateRanges?.length) {
          // In the case that the CFS is open and the currentStep is null
          const stepBetweenCurrentTime = cfs.stepDateRanges.findIndex(step =>
            moment().isBefore(step.openAt)
          )
          const lastStep =
            cfs.stepDateRanges?.[stepBetweenCurrentTime - 1] ||
            cfs.stepDateRanges[cfs.stepDateRanges.length - 1]

          subtitle = `${capitalize(lastStep.curationStep)} ${t(
            `callsForSubmission:status:${CFSStepTimeStatus.finished}`
          )}`
        }
      } else {
        subtitle = `${t(`callsForSubmission:status:${cfsStepTimeStatus}`)}`
      }
    }
    return [`${cfs?.name} » ${subtitle || ''}`, subtitle]
  }, [cfsStepTimeStatus, cfs?.stepDateRanges, cfs?.currentStep])

  return { cfsStepTimeStatus, cfsTitleStep, cfsSubtitleStep }
}

export default useCFSStepDatesRangeDiff
