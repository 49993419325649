import React from 'react'
import { View } from 'react-native'

import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

import { Container, OverlayContainer } from '../SharedStyledComponents'
import InnovationBg from '../sharedComponents/InnovationBg'
import { LoginLogo } from '../../../../components/icon/W50Logo'
import FooterLinks from '../sharedComponents/FooterLinks'

interface RowLayoutProps {
  children: any
  minHeight?: number
}

const RowLayout = ({ children, minHeight }: RowLayoutProps) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <OverlayContainer>
      <InnovationBg>
        <LoginLogo position={'absolute'} />
        <Container minHeight={minHeight}>
          <View
            style={{
              maxWidth: '1366px',
              width: isSmallScreen ? '100%' : 570,
              paddingLeft: isSmallScreen ? 16 : 0,
              paddingRight: isSmallScreen ? 16 : 0
            }}
          >
            {children}
          </View>
        </Container>
        <FooterLinks
          style={{
            alignSelf: 'center',
            marginTop: 0
          }}
        />
      </InnovationBg>
    </OverlayContainer>
  )
}

export default RowLayout
