import React, { FC } from 'react'

import styled from 'styled-components/native'
// Components
import AvgRatings from './AvgRatings'
import StaffFeedback from './StaffFeedback'
// Types
import {
  CallForSubmissionStepEnum,
  InnovationSubmission
} from '../../../../../../types'
// Styled Components
const Container = styled.View`
  flex-direction: column;
`

interface StaffReviewProps {
  submission: InnovationSubmission
}

const StaffReview: FC<StaffReviewProps> = ({ submission }) => {
  const isCFSInPreCurate =
    submission?.callForSubmission?.currentStep?.name ===
    CallForSubmissionStepEnum.preCurate

  const isFeedbackEnabled = isCFSInPreCurate

  if (!submission) {
    return null
  }

  return (
    <Container>
      <StaffFeedback submission={submission} isDisabled={!isFeedbackEnabled} />
      {!isCFSInPreCurate ? <AvgRatings submission={submission} /> : null}
    </Container>
  )
}

export default StaffReview
