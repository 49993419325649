import gql from 'graphql-tag'

const QuestionGroupEntityMapFieldsFragment = gql`
  fragment QuestionGroupEntityMapFieldsFragment on QuestionGroupEntityMap {
    id
    questionGroupId
    entityId
    entityType
    sequence
    __typename
  }
`

export default QuestionGroupEntityMapFieldsFragment
