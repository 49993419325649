import { intersection } from 'ramda'
import useCurrentUser from './useCurrentUser'
import { RoleEnum } from '../types'

/**
 * Compare a set of permissions with the current user's roles.
 * Permissions are the roles that can use or access something.
 * @param permissions
 */
const useHasPermission = (permissions: RoleEnum[]): boolean => {
  const { roles } = useCurrentUser()

  return !!intersection(permissions, roles).length
}

export default useHasPermission
