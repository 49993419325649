import React, { useContext, useState, useReducer, useMemo } from 'react'
import {
  FilterContainer,
  FilterTitleContainer
} from '../SharedStyledComponents'
import { TableContext } from '../../../hooks/TableProvider'
import FilterComponent from './FilterComponent'
import { statusFilter } from '../constants'
import useTranslation from '../../../../../hooks/useTranslation'
import { CallForSubmissionStatusEnum } from '../../../../../types'
import {
  statusMap,
  statusMapAdvise
} from '../../../../../screens/CallForSubmission/constants'
import { DropdownValue } from '../../../../common/Dropdown'
import { StyledLabel } from '../../../../../ui-library/TextInput/Label'

const customSelectionReducer = (state: any, action: any) => {
  if (action.type === 'set') {
    return { ...state, ...action.payload }
  }
  return state
}

const Filters = () => {
  const { t } = useTranslation()
  const {
    hasFilters,
    config: { isAlternativeView, showStatusFilter },
    filteredColumns,
    setQueryFilters,
    isAdviseTable,
    queryFilters
  } = useContext(TableContext)
  const [status, setStatus] = useState<CallForSubmissionStatusEnum | undefined>(
    CallForSubmissionStatusEnum.open
  )
  // State
  const [customSelectedOptions, customSelectionDispatch] = useReducer(
    customSelectionReducer,
    {}
  )

  const statusFilterObject = statusFilter(t, !isAdviseTable)

  const handleChangeStatus = (valueFilter: any) => {
    const value = valueFilter[statusFilterObject.key]
    if (value !== undefined) {
      setStatus(value)
      setQueryFilters({
        ...(isAdviseTable ? statusMapAdvise[value] : statusMap[value])
      })
    } else {
      setStatus(undefined)
      setQueryFilters({
        open_at: undefined,
        close_at: undefined
      })
    }
  }

  const setCustomSelectedOptions = (value: any) => {
    customSelectionDispatch({
      type: 'set',
      payload: value
    })
  }

  const currentOptionSelection = useMemo(
    () => ({ status, ...customSelectedOptions }),
    [status, customSelectedOptions]
  )

  const columnsWithFiltersCount = filteredColumns.filter(
    c => !!c.filter && c.filter.type === 'filter'
  ).length

  return (
    <FilterContainer
      hasFilters={hasFilters}
      isAlternativeView={isAlternativeView}
    >
      {!!columnsWithFiltersCount && (
        <FilterTitleContainer>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
        </FilterTitleContainer>
      )}
      {showStatusFilter ? (
        <FilterComponent
          key={`dynamicTable-statusFilter`}
          header={t('callsForSubmission:filter:status')}
          filter={statusFilterObject as any}
          value={status as DropdownValue}
          handleChangeFilters={handleChangeStatus}
        />
      ) : null}
      {filteredColumns.map((col, index) => {
        const customHandler = col.filter?.handleChange ?? undefined
        const filterKey = col.filter?.key || ''
        function filterHandler(selection) {
          if (customHandler) {
            setCustomSelectedOptions({
              [filterKey]: selection[filterKey] ?? undefined
            })
            // invoke custom filter handler to set query filters based on selection
            customHandler(selection, setQueryFilters)
          }
        }
        const disabledFilter = col.filter?.disabledFilterBySelection?.(
          currentOptionSelection
        )
        // use current state saved value OR ask column to the value based on the current filters in the context
        let value =
          customSelectedOptions[filterKey] ??
          (col.filter?.getValue ? col.filter.getValue(queryFilters) : undefined)
        return col.filter ? (
          col.filter.type === 'filter' ? (
            <FilterComponent
              key={`dynamicTable-filter-${index}`}
              filter={col.filter}
              header={t(col.header as string)}
              isDisabled={disabledFilter ?? false}
              handleChangeFilters={customHandler ? filterHandler : undefined}
              value={disabledFilter ? '' : value}
            />
          ) : null
        ) : null
      })}
    </FilterContainer>
  )
}

export default Filters
