import React from 'react'
import TextInput from '../../../../ui-library/TextInput'
import { ErrorText } from '../../../authentication/components/SharedStyledComponents'
import useTranslation from '../../../../hooks/useTranslation'
import { useField } from 'formik'
import { View } from 'react-native'

const InputField = ({
  fieldName,
  isReadOnly,
  type
}: {
  fieldName: string
  isReadOnly: boolean
  type: string
}) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField({ name: fieldName })

  return (
    <View style={{ marginTop: 5 }}>
      <TextInput
        isReadOnly={isReadOnly}
        name={field.name}
        isAutoSizable
        multiline
        value={field.value}
        onBlur={() => {
          helpers.setTouched(true)
        }}
        onChangeText={value => {
          helpers.setValue(value)
        }}
        label={t(
          `platformManagement:forms:menuItems:${type}:fields:${field.name}`
        )}
      />
      {meta.error && meta.touched ? <ErrorText>{meta.error}</ErrorText> : null}
    </View>
  )
}

export default InputField
