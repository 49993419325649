import gql from 'graphql-tag'

const callForSubmissionUpdatedSubscription = gql`
  subscription callForSubmissionUpdated(
    $statuses: [CallForSubmissionStatusEnum]
    $step: CallForSubmissionStepEnum
  ) {
    callForSubmissionUpdated(statuses: $statuses, step: $step) {
      id
      name
      status
      academyElegible
      stepDateRanges {
        curationStep
        closeAt
        openAt
      }
      currentStep {
        name
        openAt
        closeAt
      }
      delegations {
        id
        name
      }
      marketSegments {
        id
        name
      }
      innovationSubmissions {
        id
      }
    }
  }
`

export default callForSubmissionUpdatedSubscription
