import React from 'react'
import FooterLinks from '../sharedComponents/FooterLinks'

import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

import { Flex } from '../../../../components/FlexBox'
import W50Logo from '../../../../components/icon/W50Logo'

import { BaseContainer, StyledScrollView } from '../SharedStyledComponents'

const ColumnLayout = ({ children }) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <BaseContainer>
      <StyledScrollView
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center'
        }}
      >
        <W50Logo
          width={isSmallScreen ? 124 : 144}
          height={isSmallScreen ? 47 : 67}
          style={{
            marginBottom: 20,
            marginLeft: isSmallScreen ? 10 : 40,
            alignSelf: 'flex-start'
          }}
        />
        <Flex
          flex={1}
          flexDirection={isSmallScreen ? 'column' : 'row'}
          flexBasis="auto"
          flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
          justifyContent="center"
          alignItems="center"
          maxWidth="1366px"
        >
          {children}

          <FooterLinks />
        </Flex>
      </StyledScrollView>
    </BaseContainer>
  )
}

export default ColumnLayout
