class Simulator {
  simulatorId: string | null

  set id(id: string | null) {
    this.simulatorId = id
  }

  get id(): string | null {
    return this.simulatorId
  }
}

export default new Simulator()
