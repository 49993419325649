import { atom, RecoilState } from 'recoil'

const lastAttemptedEmailAtom: RecoilState<any> = atom({
  key: 'lastAttemptedEmailAtom',
  default: {
    email: null
  }
})

export default lastAttemptedEmailAtom
