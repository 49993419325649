import React, { FC, useCallback, useState } from 'react'
import { useTheme } from 'styled-components/native'
import RemovePopup from '../../common/Dialog/RemovePopup'
import { Text } from '../../common/Text'
import CloseIcon from '../../icon/CloseIcon'
import useMutation from '../../../hooks/useMutation'
import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import useTranslation from '../../../hooks/useTranslation'
import DateService from '../../../services/dateService'
import { AnswerFileAttachmentMap, DateTime } from '../../../types'
import {
  CardContainer,
  CardContentVew,
  CardRemoveButton,
  CardTitleContent,
  CardLabelContent,
  FileCardContainer,
  CardEditFormButton,
  CardView,
  CardPreviewIconContent,
  EditFormIcon
} from './SharedStyledComponents'
import { deleteAnswerFileAttachmentMapMutation } from './constants'
import { Container } from '../../common/SharedStyledComponents'
import EditForm from './Form'
import { Answer } from '../../../types/form'
import PreviewFile from './PreviewFile'
import useAnswerSubscription from '../../../screens/Product/hooks/useAnswerSubscription'

interface DynamicFilesBlockProps {
  answer: Answer
  attachments: AnswerFileAttachmentMap[]
  onRemoveFile?: (answerFileAttachmentMapId: string) => Promise<any>
  isReadOnly: boolean
}

const DynamicFilesBlock: FC<DynamicFilesBlockProps> = ({
  answer,
  attachments = [],
  onRemoveFile,
  isReadOnly
}) => {
  const { t } = useTranslation()
  const { sizes, colors, space, fontWeights } = useTheme()
  const { trackWithProperties } = useMixpanel()

  const [popupVisibility, setPopupVisibility] = useState({
    isVisible: false,
    index: -1
  })

  const [removeItem, { loading: removeItemLoading }]: any[] = useMutation(
    deleteAnswerFileAttachmentMapMutation,
    {
      refetchQueries: ['question']
    }
  )

  const onPopupToggle = useCallback(
    index => () => {
      setPopupVisibility(({ isVisible }) => ({
        index,
        isVisible: !isVisible
      }))
    },
    [setPopupVisibility]
  )

  const onRemoveItem = useCallback(
    answerFileAttachmentMapId => async () => {
      setPopupVisibility(({ isVisible }) => ({
        index: -1,
        isVisible: !isVisible
      }))

      if (onRemoveFile) {
        onRemoveFile?.(answerFileAttachmentMapId)
      } else {
        await removeItem({ variables: { id: answerFileAttachmentMapId } })
      }

      trackWithProperties(EVENT_MAP.click.button, {
        category: 'customers',
        action: 'delete'
      })
    },
    [setPopupVisibility]
  )

  const CardTitlesContent = props => {
    const { file, handleShowForm, isReadOnly } = props
    const { name, createdAt } = file

    return (
      <CardTitleContent style={{ justifyContent: 'space-between' }}>
        <CardLabelContent>
          <Text
            styles={{
              width: 'fit-content',
              maxHeight: space[4] + space[2],
              marginRight: sizes[1],
              color: colors.cta.secondary,
              fontWeight: `${fontWeights.bold}`,
              overflow: 'auto'
            }}
          >
            {name}
          </Text>
          {!isReadOnly ? (
            <CardEditFormButton onPress={handleShowForm}>
              <EditFormIcon />
            </CardEditFormButton>
          ) : null}
        </CardLabelContent>
        <CardPreviewIconContent>
          <PreviewFile file={file} />
        </CardPreviewIconContent>
        <CardLabelContent style={{ justifyContent: 'flex-end', width: '20%' }}>
          <Text
            styles={{
              color: colors.subTitleDescription
            }}
          >
            {DateService.getFormat(createdAt as DateTime)}
          </Text>
        </CardLabelContent>
      </CardTitleContent>
    )
  }

  const CardContent = props => {
    const { file, i } = props
    const { id } = file
    const [showForm, setShowForm] = useState(false)

    const handleShowForm = () => {
      setShowForm(!showForm)
    }

    return (
      <CardView
        style={{
          height: space[3] + space[5]
        }}
      >
        <CardContentVew
          style={{
            height: space[4] + space[2],
            maxWidth: '100%'
          }}
          key={`file-attachment-${id}`}
        >
          <CardTitlesContent
            handleShowForm={handleShowForm}
            file={file}
            isReadOnly={isReadOnly}
          />
          {!isReadOnly ? (
            <CardRemoveButton
              disabled={removeItemLoading}
              onPress={onPopupToggle(i)}
            >
              <CloseIcon
                color={colors.darkIcon}
                style={{ width: space[3], height: space[3] }}
              />
            </CardRemoveButton>
          ) : null}
        </CardContentVew>
        {showForm ? (
          <EditForm
            file={file}
            answer={answer}
            handleShowForm={handleShowForm}
          />
        ) : null}
      </CardView>
    )
  }

  const CardRemoveDialog = props => {
    const { file, i } = props
    const { name, id } = file

    return (
      <RemovePopup
        title={`${t('product:general:delete')} ${name}`}
        onPrimaryClick={onRemoveItem(id)}
        onSecondaryClick={onPopupToggle(i)}
        styles={{
          position: 'absolute',
          bottom: -space[3],
          right: space[4],
          zIndex: 999
        }}
      />
    )
  }

  const FileContainer = props => {
    const { file, i } = props
    useAnswerSubscription(
      answer.id,
      file.id,
      answer.questionId,
      answer.formSubmissionId,
      isReadOnly
    )
    return (
      <Container
        key={`file-${file.id}`}
        flexDirection="column"
        flexWrap="wrap"
        minWidth="100%"
        backgroundColor={colors.formCard}
        borderColor={colors.formCard}
        style={{
          marginBottom: 0,
          justifyContent: 'center'
        }}
      >
        <CardContainer>
          <CardContent file={file} i={i} />
          {popupVisibility.isVisible && popupVisibility.index === i ? (
            <CardRemoveDialog file={file} i={i} />
          ) : null}
        </CardContainer>
      </Container>
    )
  }

  return (
    <FileCardContainer>
      {attachments.map((file, i) => (
        <FileContainer key={`file-container-${file.id}`} file={file} i={i} />
      ))}
    </FileCardContainer>
  )
}

export default DynamicFilesBlock
