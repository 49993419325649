import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'

const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const LoadingScreen = ({ style = {} }) => (
  <Container style={style}>
    <ActivityIndicator size="large" color="#006890" />
  </Container>
)

export default LoadingScreen
