import gql from 'graphql-tag'
import useMutation from '../../../../../hooks/useMutation'

export const createMockSubmissionsMutation = gql`
  mutation createMockSubmissions(
    $id: String!
    $companyProfileIds: [String!]!
    $onlyRequiredQuestion: Boolean
    $anyValidation: Boolean
  ) {
    createMockSubmissions(
      id: $id
      companyProfileIds: $companyProfileIds
      onlyRequiredQuestion: $onlyRequiredQuestion
      anyValidation: $anyValidation
    ) {
      id
    }
  }
`

const useCreateMockSubmissionsMutation = () => {
  const [createMockSubmissions, { loading }] = useMutation(
    createMockSubmissionsMutation
  )

  return {
    createMockSubmissions,
    loading
  }
}

export default useCreateMockSubmissionsMutation
