import React, { FC } from 'react'
import styled from 'styled-components/native'
import Modal from '../../../../components/common/Modal'
import TouchableIcon from '../../../../components/icon/TouchableIcon'
import { H3 } from '../../../../components/common/Text'

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

const Title = styled(H3).attrs(props => ({
  styles: {
    color: props.theme.colors.primary
  },
  h3Style: {
    fontSize: '24px',
    fontWeight: 600
  }
}))`
  margin-bottom: 10px;
`

interface ReviewModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  children?: JSX.Element | JSX.Element[]
}

const ReviewModal: FC<ReviewModalProps> = ({
  title,
  isOpen,
  onClose,
  children
}) => {
  return (
    <Modal close={onClose} open={isOpen}>
      <Title>{title}</Title>
      <CloseIcon onPress={onClose} />
      {children}
    </Modal>
  )
}

export default ReviewModal
