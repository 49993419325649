import { useState, useCallback } from 'react'
import { FileDropzoneFile } from '../components/FileDropzone/FileDropzone'
import uploadToCloudinary from '../utils/uploadToCloudinary'

type FileUploadError = 'noFileSelected' | 'maxSizeExceeded' | null
type FileUploadSuccess = 'fileUploaded' | null

type UseFileUploaderResponse = [
  (file: FileDropzoneFile) => Promise<void>,
  Number | null,
  FileUploadError,
  FileUploadSuccess
]

const UPLOAD_OPTIONS = {
  moderation: 'perception_point',
  resourceType: 'raw'
}

const MAX_FILE_SIZE_MB = 40
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1000000

const useFileUploader = (handleUpload): UseFileUploaderResponse => {
  const [progress, setProgress] = useState<Number | null>(null)
  const [error, setError] = useState<FileUploadError>(null)
  const [success, setSuccess] = useState<FileUploadSuccess>(null)

  const uploadFile = useCallback(
    async (file: FileDropzoneFile) => {
      if (!file) {
        setError('noFileSelected')
        setProgress(null)
        setSuccess(null)

        return
      }

      setError(null)
      setProgress(null)
      setSuccess(null)

      try {
        if ((file?.size ?? 0) >= MAX_FILE_SIZE_BYTES) {
          setError('maxSizeExceeded')
          setProgress(null)
          setSuccess(null)
        } else {
          const uploadedFile = await uploadToCloudinary(
            file,
            'innovation',
            setProgress,
            UPLOAD_OPTIONS
          )

          handleUpload(uploadedFile)
          setProgress(null)
          setSuccess('fileUploaded')
        }
      } catch (error) {
        setError(error)
        setProgress(null)
        setSuccess(null)
      }
    },
    [handleUpload]
  )

  return [uploadFile, progress, error, success]
}

export default useFileUploader
