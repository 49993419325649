import { useRecoilState } from 'recoil'
import currentQueryTableAtom from '../recoil/currentQueryTableAtom'

const useCurrentQueryTable = () => {
  const [currentQueryTable, _setCurrentQueryTable] = useRecoilState(
    currentQueryTableAtom
  )

  const setCurrentQueryTable = queryDynamicName => {
    _setCurrentQueryTable({ currentTable: queryDynamicName })
  }

  return {
    setCurrentQueryTable: queryDynamicName => {
      setCurrentQueryTable(queryDynamicName)
    },
    currentTable: currentQueryTable.currentTable
  }
}

export default useCurrentQueryTable
