import React from 'react'
import styled from 'styled-components/native'
import { ViewStyle } from 'react-native'

const Container = styled.View`
  width: 100%;
  ${({ theme: { colors, sizes }, colorLight }) => `
    overflow: hidden;
    height: ${sizes[3]}px;
    background-color: ${colorLight || colors.lightGrey};
  `}
`

interface ContainerStyle extends ViewStyle {
  animation?: string
  '-webkit-mask'?: string
}
interface SkeletonProps {
  style?: ViewStyle
  children?: any
  colorLight?: string
}
const Skeleton: React.FC<SkeletonProps> = ({ style, children, colorLight }) => {
  // CSS integration on web platform
  const containerStyle: ContainerStyle = {
    animation: 'shimmeringEffect 2s infinite',
    WebkitMask:
      'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%'
  }

  return (
    <Container style={[containerStyle, style]} colorLight={colorLight}>
      {children}
    </Container>
  )
}

export default Skeleton
