import React, { useContext, useMemo, useState } from 'react'
import Chart from './Chart'
import Legend from './Legend'
import { PieChartProps } from './types'
import { View } from 'react-native'
import { DashboardContext } from '../../hooks/DashboardProvider'

const PieChart = (props: PieChartProps) => {
  const { values, type } = props
  const [index, setIndex] = useState<number | undefined>(undefined)
  const [isHover, setIsHover] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const { handleFilters } = useContext(DashboardContext)

  const handleClick = (value: string) => {
    handleFilters({
      filterType: type,
      value
    })
  }

  const opacity = useMemo(() => {
    if (isClicked) return 0.2
    if (isHover) return 0.5
    return 0.4
  }, [isClicked, isHover])

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View style={{ width: '45%' }}>
        <Chart
          values={values}
          index={index}
          setIndex={setIndex}
          setIsHover={setIsHover}
          setIsClicked={setIsClicked}
          opacity={opacity}
        />
      </View>
      <View
        style={{
          width: '55%'
        }}
      >
        <Legend
          values={values}
          index={index}
          setIndex={setIndex}
          setIsHover={setIsHover}
          setIsClicked={setIsClicked}
          opacity={opacity}
          handleClick={handleClick}
        />
      </View>
    </View>
  )
}

export default PieChart
