import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useMutation from '../../../hooks/useMutation'

export const updateInnovationSubmissionGroupInviteMutation = gql`
  mutation updateInnovationSubmissionGroupInvite(
    $id: String!
    $groupId: String!
    $value: Boolean!
  ) {
    updateInnovationSubmissionGroupInvite(
      id: $id
      groupId: $groupId
      value: $value
    ) {
      groupId
    }
  }
`

const useUpdateInnovationSubmissionGroupInviteMutation = () => {
  const { t } = useTranslation()
  const [updateInnovationSubmissionGroupInvite, { loading }] = useMutation(
    updateInnovationSubmissionGroupInviteMutation,
    {
      errorMessage: t('error:save:default')
    }
  )

  return {
    updateInnovationSubmissionGroupInvite,
    loading
  }
}

export default useUpdateInnovationSubmissionGroupInviteMutation
