import React, { useCallback, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { pick } from 'ramda'
import Button from '../../ui-library/Button'
import { Flex } from '../../components/FlexBox'

import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import useToast from '../../hooks/useToast'
import useTranslation from '../../hooks/useTranslation'
import useCurrentUser from '../../hooks/useCurrentUser'
import { useLazyQuery } from '../../hooks/useQuery'
import useSchemaWithTranslation from '../../hooks/useSchemaWithTranslation'

import useRegistrationToCompanyMutation from './mutations/useRegistrationToCompanyMutation'
import Firebase from '../../Firebase'
import {
  getAuth as getFBAuth,
  updatePassword as updateFBPassword
} from 'firebase/auth'
import useRegistrationDataQuery from './queries/useRegistrationDataQuery'
import useUpdateInnovationOnboarded from './mutations/useUpdateInnovationOnboarded'
import PersonInfoForm from './components/PersonInfoForm'
import PasswordMatchForm from './components/PasswordMatchForm'
import { INPUT_MAX_LENGTH_SM } from './constants'
import { H3 } from '../../components/common/Text'
import { Column, Layout } from './RegistrationScreen/Layout'
import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'
import { Company } from '../../types'
import { ActivityIndicator } from 'react-native'
import currentUserQuery from '../../AppRoot/controllers/currentUserQuery'
import { RegisterCheckSubStateProps } from '../../AppCoordinator/RegisterCheck'

const initialValues = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  password: '',
  confirmPassword: ''
}

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list?.map(mapper)).size
  })
})

const getRegistrationSchema = t => {
  return Yup.object().shape({
    firstName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
    lastName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
    jobTitle: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
    email: Yup.string().required('Required'),
    password: Yup.string()
      .min(6, t('error:auth:tooShort'))
      .required(t('error:auth:required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null as any], t('error:auth:match'))
      .required(t('error:auth:againRequired'))
  })
}

const RegistrationUserToCompany = ({
  send,
  payload
}: RegisterCheckSubStateProps) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)
  const { data, loading } = useRegistrationDataQuery()
  const isSmallScreen = useIsSmallScreen()
  const registrationSchema = useSchemaWithTranslation(getRegistrationSchema)
  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()
  const {
    currentPersonId,
    setCurrentUser,
    setCompany,
    currentUserId
  } = useCurrentUser()
  const { trackWithProperties } = useMixpanel()
  const [updateCurentUser] = useLazyQuery(currentUserQuery, {
    fetchPolicy: 'no-cache',
    onError: () => {
      setToastErrorMessage(t('error:general:currentUser'))
    },
    onCompleted: (data: any) => {
      if (data?.currentUser) {
        setCurrentUser(data?.currentUser)
      }
      send('REGISTERED')
    }
  })

  const commonMutationProps = {
    onError: () => {
      setToastErrorMessage(t('error:auth:failedRegister'))
      setFetchLoading(false)
    }
  }

  const updateOnboarding = useUpdateInnovationOnboarded({
    variables: {
      personId: currentPersonId
    },
    onCompleted: () => {
      updateCurentUser()
    },
    ...commonMutationProps
  })

  const [register, registerLoading] = useRegistrationToCompanyMutation({
    onCompleted: () => {
      trackWithProperties(EVENT_MAP.auth.registered, {
        personId: currentPersonId
      })
      updateOnboarding()
    },
    ...commonMutationProps
  })

  const updatePassword = async password => {
    const currentUserFB = getFBAuth(Firebase).currentUser
    if (currentUserFB) {
      try {
        await updateFBPassword(currentUserFB, password)
      } catch (error) {
        setToastErrorMessage(t('error:auth:changePassword'))
        setFetchLoading(false)
      }
    }
  }

  const submitRegistration = useCallback(
    async (values: any) => {
      setFetchLoading(true)
      const variables = {
        ...pick(['firstName', 'lastName', 'jobTitle', 'email'], values),
        personId: currentPersonId,
        originId: data?.origins?.find(o => o.source === 'innovation')?.id,
        termsId: data?.terms?.id,
        privacyId: data?.privacy?.id,
        cookieId: data?.cookie?.id,
        userId: currentUserId,
        companyId: payload.companyId
      }
      await updatePassword(values.password)
      const { data: registerData } = await register({ variables })
      setCompany(registerData?.employment?.company as Company)
    },
    [data?.origins, currentPersonId]
  )

  if (loading) {
    return (
      <Layout height={670}>
        <ActivityIndicator size="large" />
      </Layout>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitRegistration}
      validationSchema={registrationSchema}
    >
      {(formikProps: FormikProps<typeof initialValues>) => {
        const { isValidating, isSubmitting, dirty, isValid } = formikProps
        const disableButton: boolean =
          isValidating || isSubmitting || !dirty || !isValid || fetchLoading
        return (
          <Layout height={780}>
            <Column
              padding={isSmallScreen ? '40px 24px 0 15px' : '0 0 500px 0'}
              alignSelf={'flex-start'}
            >
              <H3
                styles={{
                  textAlign: 'center',
                  fontWeight: '300',
                  marginBottom: isSmallScreen ? 10 : 30
                }}
                h3Style={{ fontSize: isSmallScreen ? '29px' : '36px' }}
              >
                {t('auth:register:header')}
              </H3>
              <PersonInfoForm {...formikProps} />
              <PasswordMatchForm {...formikProps} />
              <Flex width="100%" marginTop="30px">
                <Button
                  testID={'submitUserToCompany'}
                  title={t('auth:buttons:submit')}
                  onPress={() => {
                    formikProps
                      .validateForm()
                      .then(() => formikProps.submitForm())
                  }}
                  isProcessing={registerLoading}
                  disabled={disableButton}
                  buttonStyle={{
                    alignSelf: 'center'
                  }}
                />
              </Flex>
            </Column>
          </Layout>
        )
      }}
    </Formik>
  )
}

export default RegistrationUserToCompany
