import useBreakpoint from './useBreakpoint'
import { Breakpoint } from '../types/breakpoint'

const useIsInBreakpoint = (...breakpoints: Breakpoint[]) => {
  const currentBreakpoint = useBreakpoint()

  return breakpoints.indexOf(currentBreakpoint) > -1
}

export default useIsInBreakpoint
