import { useSubscription } from '@apollo/client'
import { innovationSubmissionCloudinaryIdUpdated } from '../screens/Submission/subscriptions/innovationSubmissionCloudinaryIdUpdatedSubscription'
import { submissionQuery } from '../screens/Submission/hooks/useSubmissionQuery'
import { innovatorProductBySubmissionIdQuery } from './useInnovatorProductBySubmissionId'

const onSubmissionPDFUpdated = (
  client,
  subscriptionData,
  query,
  submissionId
) => {
  const options = {
    query,
    variables: { id: submissionId, withSubmissionReviewsAverages: true }
  }
  const cachedQuery = client.readQuery({
    ...options
  })

  const {
    pdfCloudinaryId,
    id
  } = subscriptionData.data.innovationSubmissionCloudinaryIdUpdated

  const updateInnovationSubmission = {
    ...(cachedQuery?.innovationSubmission || {})
  }
  updateInnovationSubmission.pdfCloudinaryId = pdfCloudinaryId

  if (id === submissionId) {
    client.writeQuery({
      ...options,
      data: {
        ...cachedQuery,
        innovationSubmission: updateInnovationSubmission
      }
    })
  }
}

const onProductUpdated = (client, subscriptionData, query, submissionId) => {
  const options = {
    query,
    variables: { submissionId: submissionId }
  }
  const cachedQuery = client.readQuery({
    ...options
  })

  const {
    pdfCloudinaryId,
    id
  } = subscriptionData.data.innovationSubmissionCloudinaryIdUpdated

  const updatedProduct = {
    ...(cachedQuery?.innovatorProductBySubmissionId || {})
  }
  updatedProduct.hasLatestPDF = !!pdfCloudinaryId

  if (id === submissionId) {
    client.writeQuery({
      ...options,
      data: {
        ...cachedQuery,
        innovatorProductBySubmissionId: updatedProduct
      }
    })
  }
}

interface UseSubmissionPdfSubscriptionProps {
  submissionId: string
  pdfCloudinaryId?: string
}

const useSubmissionPdfSubscription = ({
  submissionId,
  pdfCloudinaryId
}: UseSubmissionPdfSubscriptionProps) => {
  useSubscription(innovationSubmissionCloudinaryIdUpdated, {
    variables: { id: submissionId, pdfCloudinaryId },
    skip: !submissionId,
    shouldResubscribe: true,
    onData: ({ client, data }: any) => {
      onSubmissionPDFUpdated(client, data, submissionQuery, submissionId)
      onProductUpdated(
        client,
        data,
        innovatorProductBySubmissionIdQuery,
        submissionId
      )
    }
  })
}

export default useSubmissionPdfSubscription
