import {
  CallForSubmission,
  CallForSubmissionStepEnum,
  InnovationSubmission,
  StepDateRange
} from '../types'

const getCFSSubmissionStep = (cfs: CallForSubmission): StepDateRange | null => {
  if (!cfs || cfs.stepDateRanges) {
    return null
  }
  // @ts-ignore
  return cfs.stepDateRanges?.find(
    s => s.curationStep === CallForSubmissionStepEnum.submission
  )
}

export const filterByCFSSubmissionCloseAt = submissions => {
  submissions.sort((a, b) => {
    if (a.callForSubmission && b.callForSubmission) {
      const aSubmissionStep = getCFSSubmissionStep(a.callForSubmission)
      const bSubmissionStep = getCFSSubmissionStep(b.callForSubmission)

      if (aSubmissionStep && bSubmissionStep) {
        return (
          // @ts-ignore
          new Date(aSubmissionStep.closeAt) - new Date(bSubmissionStep.closeAt)
        )
      }
    }
    return -1
  })

  return submissions
}

export const filterBySubmitted = (submissions: InnovationSubmission[] = []) => {
  return filterByCFSSubmissionCloseAt(
    submissions
      ?.filter(s => !!s.callForSubmissionId)
      .filter(s => s.hasBeenSubmitted)
  )
}
