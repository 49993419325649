import React from 'react'
import { View } from 'react-native'
import Svg, { G, Circle } from 'react-native-svg'
import { ChartProps } from './types'
import { Text } from '../../../../../components/common/Text'

const Chart = (props: ChartProps) => {
  const { values, opacity, index: selectedIndex } = props
  const radius = 70
  const circleCircumference = 2 * Math.PI * radius

  const total = values.reduce((acc, { value }) => acc + value, 0)
  const percentages = values.map(({ value }) => value / total)
  const strokeDashoffsets = percentages.map(
    percentage => circleCircumference - circleCircumference * percentage
  )
  let sumAngles = 0
  const angles = percentages.map(percentage => {
    const indexPercentage = percentage * 360 + sumAngles
    sumAngles = indexPercentage
    return indexPercentage
  })

  const totalSubmissions =
    typeof selectedIndex !== 'undefined' && values[selectedIndex]
      ? values[selectedIndex].value
      : undefined

  const totalSubmissionsPercentage =
    typeof selectedIndex !== 'undefined' && percentages[selectedIndex]
      ? Math.round(percentages[selectedIndex] * 100)
      : undefined

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Svg height="100%" width="100%" viewBox="0 0 180 180">
        <G rotation={-90} originX="90" originY="90">
          {total === 0 ? (
            <Circle
              cx="50%"
              cy="50%"
              r={radius}
              stroke="#F1F6F9"
              fill="transparent"
              strokeWidth="40"
            />
          ) : (
            strokeDashoffsets.map((strokeDashoffset, index) => (
              <Circle
                cx={index === selectedIndex ? '52%' : '50%'}
                cy={index === selectedIndex ? '52%' : '50%'}
                r={radius}
                stroke={values[index].color}
                fill="transparent"
                strokeWidth="30"
                strokeDasharray={circleCircumference}
                strokeDashoffset={strokeDashoffset}
                rotation={index === 0 ? 0 : angles[index - 1]}
                originX="90"
                originY="90"
                opacity={index === selectedIndex ? opacity : 1}
              />
            ))
          )}
        </G>
      </Svg>
      {typeof selectedIndex !== 'undefined' ? (
        <>
          {totalSubmissionsPercentage ? (
            <Text
              style={{
                position: 'absolute',
                textAlign: 'center',
                fontWeight: '700',
                fontSize: 24
              }}
            >
              {`${totalSubmissionsPercentage}%`}
            </Text>
          ) : null}
          {totalSubmissions ? (
            <Text
              style={{
                position: 'absolute',
                textAlign: 'center',
                fontWeight: '300',
                marginTop: 45,
                fontSize: 10
              }}
            >
              {`${totalSubmissions} Submissions`}
            </Text>
          ) : null}
        </>
      ) : null}
    </View>
  )
}

export default Chart
