import gql from 'graphql-tag'

const formAnswerSubscription = gql`
  subscription formAnswerUpdatedBy($innovationSubmissionId: String!) {
    formAnswerUpdatedBy(innovationSubmissionId: $innovationSubmissionId) {
      id
      person {
        firstName
        lastName
      }
    }
  }
`

export default formAnswerSubscription
