import { useMemo } from 'react'
import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { User } from '../../../types'

export const invitationUserQuery = gql`
  query getInvitationUserByUserId($id: String!) {
    getInvitationUserByUserId(id: $id) {
      id
      person {
        emailAddresses {
          email
        }
      }
    }
  }
`

interface UseInvitationUserQuery {
  user?: User
  loading: boolean
}

interface UseInvitationUserQueryProps {
  id?: string
}

const useInvitationUserQuery = ({
  id
}: UseInvitationUserQueryProps): UseInvitationUserQuery => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(invitationUserQuery, {
    variables: {
      id: id
    },
    skip: !id,
    errorMessage: t('error:product:get')
  })

  const currentInvitationUser = useMemo(() => {
    if (data && data?.getInvitationUserByUserId) {
      const currentInvitationUser: User = data?.getInvitationUserByUserId
      return currentInvitationUser
    }
  }, [data])

  return {
    user: currentInvitationUser,
    loading
  }
}

export default useInvitationUserQuery
