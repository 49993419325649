import styled from 'styled-components/native'
import { Flex } from '../FlexBox'

export const Container = styled(Flex).attrs(
  ({
    theme: { colors, space, radii, borderWidths },
    styles = {},
    ...props
  }) => ({
    borderColor: props.borderColor ? props.borderColor : colors.inputBorder,
    borderWidth: borderWidths[1],
    borderRadius: radii[4],
    borderStyle: 'solid',
    p: props.padding ? props.padding : `${space[3]}px`,
    my: props.my ? props.my : `${space[3]}px`,
    w: '100%',
    ...styles
  })
)``
