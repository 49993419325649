import { useMemo } from 'react'
import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { User } from '../../../types'
import useCurrentUser from '../../../hooks/useCurrentUser'
import CompanyUsersFragment from '../../../graphql/CompanyUsersFragment'

export const companyUsersQuery = gql`
  query getInnovationUsersByCompanyId($id: String!) {
    getInnovationUsersByCompanyId(id: $id) {
      ...CompanyUsersFragment
    }
  }
  ${CompanyUsersFragment}
`

interface UseCompanyUsersQuery {
  users?: User[]
  pendingUsers?: User[]
  loading: boolean
}

interface CurrentCompanyUsers {
  users?: User[]
  pendingUsers?: User[]
}

interface UseCompanyUsersQueryProps {
  id?: string
}

const filterActiveCompanyUsers = (user: User): Boolean =>
  Boolean(user && (user?.innovationOnboardedAt || user?.lastLogin))

const useCompanyUsersQuery = (
  { id }: UseCompanyUsersQueryProps,
  options = {}
): UseCompanyUsersQuery => {
  const { currentUserId } = useCurrentUser()
  const { t } = useTranslation()

  const { data, loading } = useQuery(companyUsersQuery, {
    variables: {
      id: id
    },
    skip: !id,
    errorMessage: t('error:product:get'),
    ...options
  })

  const currentCompanyUsers = useMemo(() => {
    if (data && data?.getInnovationUsersByCompanyId && currentUserId) {
      let results: User[] = [...data?.getInnovationUsersByCompanyId]
      results.sort((el, el2) => {
        if (el2.id === currentUserId) return 1
        return -1
      })
      const currentCompanyUsers: CurrentCompanyUsers = {
        users: results?.filter(user => filterActiveCompanyUsers(user)),
        pendingUsers: results?.filter(user => !filterActiveCompanyUsers(user))
      }

      return currentCompanyUsers
    }
  }, [data, currentUserId])

  return {
    users: currentCompanyUsers?.users,
    pendingUsers: currentCompanyUsers?.pendingUsers,
    loading
  }
}

export default useCompanyUsersQuery
