import { useState } from 'react'
import { useSubscription } from '@apollo/client'
import formAnswerSubscription from '../subscriptions/formAnswerSubscription'
import { User } from '../../../types'

interface UseFormAnswerSubscriptionProps {
  innovationSubmissionId: string
}

const useFormAnswerSubscription = ({
  innovationSubmissionId
}: UseFormAnswerSubscriptionProps) => {
  const [[hasChanged, submissionUpdatedBy], setHasChanged] = useState([
    false,
    ''
  ])
  useSubscription(formAnswerSubscription, {
    variables: {
      innovationSubmissionId
    },
    onData: ({ data: subscriptionData }) => {
      const formAnswerUpdatedBy: User =
        subscriptionData.data.formAnswerUpdatedBy
      const {
        person: { firstName, lastName }
      } = formAnswerUpdatedBy
      const updatedBy = `${firstName} ${lastName}`
      setHasChanged([true, updatedBy])
    }
  })
  return { hasChanged, submissionUpdatedBy, setHasChanged }
}

export default useFormAnswerSubscription
