import { atomFamily, CallbackInterface } from 'recoil'
import { JSONObject } from '../types/form'
import { DocumentNode } from 'graphql'

export interface SearchQueryInfo {
  queryName: string
  variables: JSONObject
  query?: DocumentNode
  data: any[]
}

const DEFAULT_SEARCH_QUERY: SearchQueryInfo = {
  queryName: '',
  variables: {},
  query: undefined,
  data: []
}

export const searchQueryByName = atomFamily<SearchQueryInfo, string>({
  key: 'searchQueries',
  default: (name: string) => ({
    ...DEFAULT_SEARCH_QUERY,
    name
  })
})

export const setSearchQueryByName = ({ set }: CallbackInterface) => (
  name: string,
  searchQueryInfo: SearchQueryInfo
) => {
  set(searchQueryByName(name), searchQueryInfo)
}

export const addDataToSearchQueryByName = ({ set }: CallbackInterface) => (
  name: string,
  data: object
) => {
  set(searchQueryByName(name), oldValue => {
    return {
      ...oldValue,
      data: {
        ...oldValue?.data,
        ...data,
        searchInnovationEngine: {
          ...oldValue?.data?.['searchInnovationEngine'],
          ...data?.['searchInnovationEngine'],
          hits: [
            ...oldValue?.data?.['searchInnovationEngine']?.['hits'],
            ...data?.['searchInnovationEngine']?.['hits']
          ]
        }
      }
    }
  })
}
