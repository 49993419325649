import React from 'react'
import styled from 'styled-components/native'

import { Text } from '../../components/common/Text'
import Tooltip from './Tooltip'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import {
  PrintableLabel,
  TooltipContent
} from '../common/PrintableStyledComponents'

export const StyledLabel = styled(Text)`
  ${({ theme, styles }) => `
    color: ${styles?.color ? styles.color : theme.colors.inputLabels};
    margin-left: ${styles?.marginLeft ? styles?.marginLeft : '7px'};
    margin-bottom: 3px;
    position: relative;
`}
`

interface LabelProps {
  label?: string
  helperText?: string
  withHelperText?: boolean
  labelStyles?: any
  asPrintable?: boolean
}

const Label = ({
  label,
  helperText,
  withHelperText = false,
  labelStyles,
  asPrintable = false
}: LabelProps) => {
  const isSmallScreen = useIsSmallScreen()

  if (asPrintable) {
    return (
      <PrintableLabel>
        {label}
        {'\n'}
        {withHelperText && helperText ? (
          <TooltipContent>{helperText}</TooltipContent>
        ) : null}
      </PrintableLabel>
    )
  }

  if (!isSmallScreen && withHelperText && helperText) {
    return (
      <Tooltip text={helperText}>
        <StyledLabel styles={labelStyles}>{label}</StyledLabel>
      </Tooltip>
    )
  } else if (label) {
    return <StyledLabel styles={labelStyles}>{label}</StyledLabel>
  }

  return null
}

export default Label
