import { useMemo } from 'react'

// Types
import { FormSubmissionMetaData, MarketSegmentsMetaData } from '../types'

export interface MetadataProps {
  cfsMetaData?: FormSubmissionMetaData | null
  marketSegmentsMetaData?: MarketSegmentsMetaData | null
}

type OptionsEntityIdsType = string[] | null

const useGetOptionEntityIds = (
  metaData: MetadataProps | null
): [OptionsEntityIdsType] => {
  const optionsEntityIds: string[] | null = useMemo(() => {
    if (metaData?.cfsMetaData || metaData?.marketSegmentsMetaData) {
      const { cfsMetaData, marketSegmentsMetaData } = metaData

      if (marketSegmentsMetaData?.marketSegments?.length) {
        return marketSegmentsMetaData.marketSegments.map(mks => mks.id)
      }
      return cfsMetaData?.callForSubmission?.marketSegments?.length
        ? cfsMetaData.callForSubmission.marketSegments.map(mks => mks.id)
        : null
    }
    return null
  }, [metaData])

  return [optionsEntityIds]
}

export default useGetOptionEntityIds
