import React, { useEffect, useState } from 'react'

// Hooks
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
import useTranslation from '../../../../../hooks/useTranslation'
// Components
//
import Modal from './components/Modal'
//
import { TableContainer } from '../../../../../components/StaticForm'
import { DynamicTable, useTable } from '../../../../../components/Table'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../../../../components/DynamicTable'
// Skeleton
import TableSkeleton from '../../../../HomeScreen/TableSkeleton'
import SkeletonContainer from '../../../../../components/skeletonLoadings/SkeletonContainer'
import { View } from 'react-native'
import { CreateIcon } from '../../../constants'
import { FormValues } from '../../types'
import TextInput from '../../../../../ui-library/TextInput'
import useDebounce from '../../../../../hooks/useDebounce'
import { getFormsTableConfig } from './constants'
import useFormsQuery from '../../../../Product/hooks/useFormsQuery'
import { Form } from '../../../../../types'

const Forms = () => {
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentForm, setCurrentForm] = useState<FormValues | undefined>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { forms, loading: fetchLoading } = useFormsQuery()

  const [search, setSearch] = useState<string | undefined>(undefined)

  const debouncedSearch = useDebounce(search, 300)

  const [data, setData] = useState<Form[]>(forms)

  useEffect(() => {
    if (fetchLoading && !isLoading) {
      setIsLoading(true)
    }
  }, [fetchLoading, isLoading])

  useEffect(() => {
    if (!fetchLoading && forms.length) {
      let newData: Form[] = forms ?? []

      if (debouncedSearch != null) {
        newData = newData.filter(question => {
          const allTerms = [
            ...Object.keys(question),
            ...Object.values(question).map(
              value => String(JSON.stringify(value)) ?? String(value)
            )
          ]
          const includeTerm = allTerms.find(term =>
            term.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
          if (includeTerm) {
            return true
          } else {
            return false
          }
        })
      }
      setData(newData)
      setIsLoading(false)
    }
  }, [forms, fetchLoading, debouncedSearch])

  const handleEditForm = (form: FormValues) => {
    setCurrentForm(form)
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const handleCreateForm = () => {
    setCurrentForm(undefined)
    setIsOpen(true)
  }

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps = useTable({
    data,
    config: getFormsTableConfig({
      handleEditForm
    })
  })

  return (
    <View
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <View
        style={{
          width: '100%',
          height: 50,
          marginTop: 10,
          paddingHorizontal: 10,
          flexDirection: 'row'
        }}
      >
        {/* Filters */}
        <View
          style={{
            marginHorizontal: 10,
            height: '90%',
            width: '80%',
            flexDirection: 'row'
          }}
        >
          <TextInput
            style={{
              minHeight: 0,
              alignSelf: 'center'
            }}
            containerStyles={{
              width: 250,
              height: 50
            }}
            placeholder="Search..."
            value={search}
            onChangeText={setSearch}
          />
        </View>
        <CreateIcon
          style={{ alignSelf: 'flex-end' }}
          disabled={false}
          isLoading={false}
          title={t('platformManagement:forms:menuItems:forms:addNew')}
          onPress={handleCreateForm}
        />
      </View>

      {/* Table */}
      <View
        style={{
          flex: 1,
          paddingHorizontal: 10
        }}
      >
        <SkeletonContainer isLoading={isLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t('platformManagement:categories:noCategories')}
            />
          </TableContainer>
        </SkeletonContainer>
      </View>
      <Modal isOpen={isOpen} onClose={onClose} form={currentForm} />
    </View>
  )
}

export default Forms
