import React, { useCallback, useContext } from 'react'
import { SortContainer } from '../SharedStyledComponents'
import CheckBox from '../../../../../ui-library/CheckBox'
import { TableContext } from '../../../hooks/TableProvider'
import SortColumn from './SortColumn'
import Column from './Column'
import { HeaderCellProps } from '../types'

const HeaderCell = (props: HeaderCellProps): any => {
  const { handleSelectItems, selectedAll } = useContext(TableContext)
  const { isSelectable, sort, headerStyle, header, subtitle } = props

  const handleOnPress = useCallback(() => {
    handleSelectItems && handleSelectItems(undefined, !selectedAll)
  }, [selectedAll])

  return (
    <SortContainer style={props?.sortContainerStyle || {}}>
      {isSelectable && (
        <CheckBox
          isSelectable
          checked={selectedAll ?? false}
          onPress={handleOnPress}
        />
      )}
      {sort ? (
        <SortColumn sort={sort} headerStyle={headerStyle} header={header} />
      ) : (
        <Column headerStyle={headerStyle} header={header} subtitle={subtitle} />
      )}
    </SortContainer>
  )
}

export default HeaderCell
