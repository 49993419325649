import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const deleteFormQuestionMutation = gql`
  mutation deleteFormQuestion($questionId: String!, $formId: String!) {
    deleteFormQuestion(questionId: $questionId, formId: $formId)
  }
`

const useDeleteFormQuestion = (options = {}) => {
  const [
    deleteFormQuestion,
    { loading: loadingDeleteFormQuestion }
  ]: any[] = useMutation(deleteFormQuestionMutation, options)

  return [deleteFormQuestion, loadingDeleteFormQuestion]
}

export default useDeleteFormQuestion
