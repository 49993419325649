import React, { FC } from 'react'
import { useTheme } from 'styled-components/native'
import { TouchableOpacity } from 'react-native'
import useLinkToScreen from '../../../../hooks/useLinkToScreen'
import ArrowLeftIcon from '../../../../components/icon/ArrowLeftIcon'
import { Flex } from '../../../../components/FlexBox'
import { Title } from '../index'
import { CallForSubmission } from '../../../../types'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import useCFSStepDatesRangeDiff from '../../hooks/useCFSStepDatesRangeDiff'

interface HeaderProps {
  callForSubmission: CallForSubmission
  advisorName: string
}

const Header: FC<HeaderProps> = ({ callForSubmission, advisorName }) => {
  const { colors, space } = useTheme()
  const isSmallScreen = useIsSmallScreen()
  const linkToScreen = useLinkToScreen()
  const { cfsTitleStep } = useCFSStepDatesRangeDiff(callForSubmission)

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Flex width="100%" flexDirection="row" alignItems="center">
        <TouchableOpacity
          onPress={() => {
            let routeName = 'CurationAdvising'
            let params = {
              id: callForSubmission.id
            }

            linkToScreen(routeName, params)
          }}
          style={{
            marginRight: space[3] + space[1] // 20
          }}
        >
          <ArrowLeftIcon color={colors.lightGrey} />
        </TouchableOpacity>
        <Title isSmallScreen={isSmallScreen}>{cfsTitleStep}</Title>
        <Title style={{ marginLeft: 'auto' }} />
        <Title style={{}}>{advisorName}</Title>
      </Flex>
    </Flex>
  )
}

export default Header
