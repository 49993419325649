import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import QuestionValidationMapFieldsFragment from '../../../../../../graphql/QuestionValidationMapFieldsFragment'

export const upsertQuestionValidationMapMutation = gql`
  mutation upsertQuestionValidationMap(
    $id: String
    $questionId: String!
    $validation: ValidationInput
    $validationId: String
    $validationType: ValidationTypeEnum
    $phase: ValidationPhaseEnum!
    $errorMessage: String
    $value: JSON
  ) {
    upsertQuestionValidationMap(
      id: $id
      questionId: $questionId
      validation: $validation
      validationId: $validationId
      validationType: $validationType
      phase: $phase
      errorMessage: $errorMessage
      value: $value
    ) {
      ...QuestionValidationMapFieldsFragment
    }
  }
  ${QuestionValidationMapFieldsFragment}
`

const useUpsertQuestionValidationMap = (options = {}) => {
  const [
    upsertQuestionValidationMap,
    { loading: loadingUpsertQuestionValidationMap }
  ]: any[] = useMutation(upsertQuestionValidationMapMutation, options)

  return [upsertQuestionValidationMap, loadingUpsertQuestionValidationMap]
}

export default useUpsertQuestionValidationMap
