import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { TouchableOpacity, View } from 'react-native'
import Card from '../common/Card'
import { DashboardContext } from '../hooks/DashboardProvider'
import { Text } from '../../../../components/common/Text'
import TextInput from '../../../../ui-library/TextInput'
import useDebounce from '../../../../hooks/useDebounce'
import FiltersModal from './FiltersModal'
import { FilterObject } from '../types'

interface FilterBadgeProps {
  filter: FilterObject
  handlePress: (value: FilterObject) => void
  showRemoveIcon?: boolean
}

export const FilterBadge = (props: FilterBadgeProps) => {
  const { filter, handlePress, showRemoveIcon = true } = props
  const { colors } = useTheme()
  const filterStyles = {
    width: 'fit-content',
    height: 25,
    marginHorizontal: 2,
    paddingHorizontal: 7,
    paddingVertical: 3,
    marginBottom: 5,
    borderColor: colors.tints.grays.g100,
    borderWidth: 1,
    borderRadius: 12,
    backgroundColor: 'white'
  }
  return (
    <Card
      styles={filterStyles}
      hoverOpacity
      clickOpacity
      effects={[
        {
          initialValue: 1,
          mouseEvent: 'Hover',
          styleProperty: value => ({
            transform: [{ scaleX: value }, { scaleY: value }]
          }),
          timeIn: 200,
          timeOut: 150,
          toValue: 1.08
        },
        {
          initialValue: 0,
          mouseEvent: 'Hover',
          styleProperty: value => ({ opacity: value }),
          interpolateValues: ['60%', '100%'],
          timeIn: 200,
          timeOut: 150,
          toValue: 1
        }
      ]}
    >
      <TouchableOpacity
        style={{ flexDirection: 'row' }}
        onPress={() => handlePress(filter)}
      >
        <Text
          style={{
            fontSize: 12
          }}
        >
          {filter.value}
        </Text>
        {showRemoveIcon ? (
          <Text
            style={{
              marginLeft: 5,
              fontSize: 12,
              fontWeight: 'bold'
            }}
          >
            {`x`}
          </Text>
        ) : null}
      </TouchableOpacity>
    </Card>
  )
}
const FiltersBar = () => {
  const { colors } = useTheme()
  const [search, setSearch] = useState<string | undefined>(undefined)
  const debouncedSearch = useDebounce(search, 300)
  const { filters, handleFilters } = useContext(DashboardContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (typeof debouncedSearch !== 'undefined') {
      handleFilters({ filterType: 'SearchText', value: debouncedSearch })
    }
  }, [debouncedSearch])

  return (
    <Card
      styles={{ flexDirection: 'row', alignItems: 'center' }}
      width={'90%'}
      height={60}
    >
      <TouchableOpacity
        style={{
          marginRight: 10,
          borderWidth: 1,
          borderColor: colors.tints.grays.g200,
          borderRadius: 12,
          height: 40,
          paddingHorizontal: 5,
          justifyContent: 'center'
        }}
        onPress={() => setIsOpen(true)}
      >
        <Text
          style={{
            fontSize: 11
          }}
        >
          {`+ Filters`}
        </Text>
      </TouchableOpacity>
      <TextInput
        style={{
          width: '300px',
          marginTop: 15,
          marginRight: 20,
          alignSelf: 'center'
        }}
        placeholder="Search..."
        value={search}
        onChangeText={setSearch}
      />
      <View
        style={{
          flexDirection: 'row',
          overflow: 'scroll',
          flex: 1
        }}
      >
        {filters
          .filter(filter => filter.filterType !== 'SearchText')
          .map(filter => (
            <FilterBadge
              filter={filter}
              handlePress={filter => handleFilters(filter)}
            />
          ))}
      </View>
      <FiltersModal openDialog={isOpen} triggerClose={() => setIsOpen(false)} />
    </Card>
  )
}

export default FiltersBar
