import { useMemo, useState, useCallback, useEffect } from 'react'

import gql from 'graphql-tag'
// Hooks
import useQuery from '../../../hooks/useQuery'
import useTranslation from '../../../hooks/useTranslation'
import useGetOptionEntityIds, { MetadataProps } from './useGetOptionEntityIds'
// Components
import { QuestionOptionsArgs, Option } from '../DropDown'

const questionOptionsQuery = gql`
  query getQuestionOptions($args: OptionsArgsInput) {
    getQuestionOptions(args: $args) {
      id
      name
      value
      type
    }
  }
`

const useMultiSelectDropdownOptions = (
  { optionsEntity, optionsValues, subType }: QuestionOptionsArgs,
  metaData: MetadataProps | null
) => {
  const { t } = useTranslation()
  const [options, setOptions]: [Option[], any] = useState([])

  const [optionsEntityIds] = useGetOptionEntityIds(metaData)

  const variables = useMemo(() => {
    return {
      args: {
        optionsEntity,
        optionsEntityIds
      }
    }
  }, [optionsEntity, optionsEntityIds])

  const getOptions = useCallback(unformattedOptions => {
    let dropDownList: Option[] = (unformattedOptions || []).map(
      ({ name, value }) => ({
        label: name,
        value
      })
    )
    dropDownList = [...dropDownList]
    setOptions(dropDownList)
  }, [])

  const { loading } = useQuery(questionOptionsQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: subType !== 'dbLookup' || !optionsEntity,
    errorMessage: t('error:form:getOptions'),
    onCompleted: data => {
      getOptions(data?.getQuestionOptions)
    }
  })

  useEffect(() => {
    if (Array.isArray(optionsValues)) {
      getOptions(optionsValues)
    }
  }, [optionsValues])

  return {
    options,
    loading
  }
}

export default useMultiSelectDropdownOptions
