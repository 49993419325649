import React, { FC } from 'react'

import { useTheme } from 'styled-components/native'

import { Container } from '../SharedStyledComponents'

import Button from '../../../ui-library/Button'
import useTranslation from '../../../hooks/useTranslation'
import { Text } from '../../common/Text'

interface RemovePopupProps {
  title: string
  testID?: string
  cancelButtonTestId?: string
  deleteButtonTestId?: string
  onPrimaryClick: () => any
  onSecondaryClick: () => any
  styles?: any
}

const RemovePopup: FC<RemovePopupProps> = ({
  styles = {},
  title,
  testID,
  cancelButtonTestId,
  deleteButtonTestId,
  onPrimaryClick,
  onSecondaryClick
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { colors, space } = theme
  const { btnStyles, btnContainerStyles } = getStyles(theme)

  return (
    <Container
      testID={testID}
      flexDirection="column"
      flexWrap="wrap"
      borderColor={colors.inputBorder}
      backgroundColor="white"
      textAlign="center"
      styles={styles}
    >
      <Text styles={{ fontSize: space[3], maxWidth: space[7] }}>{title}</Text>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          title={t('product:general:cancel')}
          testID={cancelButtonTestId}
          type="outline"
          buttonStyle={{
            ...btnStyles,
            borderColor: colors.buttonVariant
          }}
          titleStyle={{
            color: colors.buttonVariant
          }}
          containerStyle={{
            ...btnContainerStyles,
            marginRight: space[3]
          }}
          onPress={onSecondaryClick}
        />
        <Button
          title={t('product:general:delete')}
          testID={deleteButtonTestId}
          buttonStyle={{
            ...btnStyles,
            backgroundColor: colors.dangerButton
          }}
          containerStyle={btnContainerStyles}
          onPress={onPrimaryClick}
        />
      </div>
    </Container>
  )
}

const getStyles = theme => ({
  btnContainerStyles: { height: theme.space[4], marginTop: theme.space[3] },
  btnStyles: {
    width: 'fit-content',
    paddingBottom: theme.space[3],
    paddingTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[2],
    paddingRight: theme.space[4] - theme.space[2],
    height: theme.space[4]
  }
})

export default RemovePopup
