import React from 'react'

import {
  ScreenContainerWithMenuHeader,
  StyledViewContainerAutoHeight
} from '../../../../components/layout/ScreenContainer'
import { Container, Title } from '../../components/SharedComponents'
import useTranslation from '../../../../hooks/useTranslation'
import CallForSubmissionForm from './CallForSubmissionForm'

const CallForSubmissionFormScreen = () => {
  const { t } = useTranslation()

  return (
    <ScreenContainerWithMenuHeader screenCategory="callForSubmission">
      <StyledViewContainerAutoHeight>
        <Title text={t('callsForSubmission:form:title')} />
        <Container>
          <CallForSubmissionForm />
        </Container>
      </StyledViewContainerAutoHeight>
    </ScreenContainerWithMenuHeader>
  )
}

export default CallForSubmissionFormScreen
