export enum EmployeeCountRangeValuesEnum {
  XS = '1-10',
  SM = '11-50',
  MD = '51-100',
  LG = '101-500',
  XL = '501-1000',
  XXL = '1001-5000',
  XXXL = '5001-10000',
  XXXXL = '10,000+'
}

export enum EmployeeCountRangeEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  XXXL = 'xxxl',
  XXXXL = 'xxxxl'
}
