import React, { useEffect } from 'react'
import { FormikProps } from 'formik'
import useTranslation from '../../../hooks/useTranslation'
import TextInput from '../../../ui-library/TextInput'
import { Flex } from '../../../components/FlexBox'
import { Count } from './SharedStyledComponents'
import useCurrentUser from '../../../hooks/useCurrentUser'
import { INPUT_MAX_LENGTH_SM } from '../constants'
import changeText from '../../../utils/changeFormikText'
import { FormHeader } from './sharedComponents/PageHeader'

interface PersonInfoFormProps extends FormikProps<any> {}

const PersonInfoForm = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
  touched
}: PersonInfoFormProps) => {
  const { t } = useTranslation()
  const { firebaseEmail, currentUser } = useCurrentUser()

  useEffect(() => {
    if (firebaseEmail) {
      changeText('email', firebaseEmail, setFieldValue)
      setFieldValue('companyURL', firebaseEmail.split('@')[1])
      setFieldValue('companyEmailDomains', [firebaseEmail.split('@')[1]])
    }
  }, [firebaseEmail])

  useEffect(() => {
    if (currentUser) {
      changeText('firstName', currentUser?.person?.firstName, setFieldValue)
      changeText('lastName', currentUser?.person.lastName, setFieldValue)
    }
  }, [currentUser])

  return (
    <Flex flexDirection="column" maxWidth="100%" width="100%">
      <FormHeader title={t('auth:register:personalInfo')} />
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
      >
        <TextInput
          value={values.firstName}
          onChangeText={value => changeText('firstName', value, setFieldValue)}
          onBlur={handleBlur('firstName')}
          autoCapitalize="none"
          label={t('auth:forms:labels:firstName')}
          autoFocus
          hasError={!!errors.firstName && !!touched.firstName}
          style={{ width: '100%' }}
          containerStyles={{ flexBasis: '35%' }}
          maxLength={INPUT_MAX_LENGTH_SM}
        />
        <TextInput
          value={values.lastName}
          onChangeText={value => changeText('lastName', value, setFieldValue)}
          onBlur={handleBlur('lastName')}
          autoCapitalize="none"
          label={t('auth:forms:labels:lastName')}
          hasError={!!errors.lastName && !!touched.lastName}
          style={{ width: '100%' }}
          maxLength={INPUT_MAX_LENGTH_SM}
          containerStyles={{ flexBasis: '64%', paddingLeft: '10px' }}
        />
      </Flex>
      <TextInput
        value={values.jobTitle}
        onChangeText={value => changeText('jobTitle', value, setFieldValue)}
        onBlur={handleBlur('jobTitle')}
        autoCapitalize="none"
        label={t('auth:forms:labels:jobTitle')}
        hasError={!!errors.jobTitle && !!touched.jobTitle}
        style={{ minWidth: '100%' }}
        maxLength={INPUT_MAX_LENGTH_SM}
        renderRightComponent={() =>
          values.jobTitle.length >= INPUT_MAX_LENGTH_SM / 2 ? (
            <Count>
              {INPUT_MAX_LENGTH_SM - Number(values.jobTitle.length)}
            </Count>
          ) : null
        }
      />
      <TextInput
        value={values.email}
        onChangeText={value => changeText('email', value, setFieldValue)}
        hasError={!!errors.email && !!touched.email}
        autoCapitalize="none"
        label={t('auth:forms:labels:email')}
        editable={false}
        style={{ minWidth: '100%', borderWidth: 0, paddingTop: 0 }}
      />
    </Flex>
  )
}

export default PersonInfoForm
