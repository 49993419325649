import { useSubscription } from '@apollo/client'
import innovationSubmissionCreatedSubscription from '../subscriptions/innovationSubmissionCreatedSubscription'
import { submissionsQuery } from './useSubmissionsQuery'
import { InnovationSubmissionStatusEnum } from '../../../types'

const onInnovationSubmissionCreated = (
  client,
  subscriptionData,
  query,
  statuses
) => {
  let options = { query }
  if (statuses?.length) {
    options['variables'] = { statuses }
  }

  const cachedQuery = client.readQuery({
    ...options
  })
  const newInnovationSubmission =
    subscriptionData.data.innovationSubmissionCreated
  const existing = cachedQuery?.innovationSubmissions || []

  // filter out submissions whose product does not have a name
  const updatedSubmissions = newInnovationSubmission?.submissionInfo?.name
    ? [newInnovationSubmission, ...existing]
    : existing

  client.writeQuery({
    ...options,
    data: {
      ...cachedQuery,
      innovationSubmissions: updatedSubmissions
    }
  })
}

const useCreatedInnovationSubmissionSubscription = (
  statuses?: [InnovationSubmissionStatusEnum]
) => {
  let variables
  if (statuses) {
    variables = { statuses }
  }
  useSubscription(innovationSubmissionCreatedSubscription, {
    variables,
    onData: ({ client, data }) => {
      onInnovationSubmissionCreated(client, data, submissionsQuery, statuses)
    }
  })
}

export default useCreatedInnovationSubmissionSubscription
