import React, { FC, useCallback } from 'react'
import { ActivityIndicator } from 'react-native'
import Checkbox from '../../../ui-library/CheckBox'

import useSelectSubmissionForCohortMutation from '../hooks/useSelectSubmissionForCohortMutation'

import { Group, InnovationSubmission } from '../../../types'

export interface CohortCheckBoxProps {
  submission: InnovationSubmission
  group: Group
  isDisabled?: boolean
  checkboxColor?: string
  isSelected?: boolean
  isDeleted?: boolean
  onPressToggle?: () => void
}

const CohortCheckBox: FC<CohortCheckBoxProps> = ({
  group,
  submission,
  isDisabled = false,
  checkboxColor = undefined,
  isSelected = false,
  isDeleted = false,
  onPressToggle
}) => {
  const {
    upsertSubmissionGroupMap,
    loading: isLoading
  } = useSelectSubmissionForCohortMutation()

  const onToggle = useCallback(async () => {
    await upsertSubmissionGroupMap({
      variables: {
        group,
        submissionId: submission.id,
        isDeleted
      }
    })
  }, [isDeleted])

  return isLoading ? (
    <ActivityIndicator style={{ alignItems: 'baseline' }} size="small" />
  ) : (
    <Checkbox
      checked={isSelected}
      onPress={onPressToggle || onToggle}
      isReadOnly={isDisabled}
      flexContainerStyles={{
        paddingLeft: 0,
        marginBottom: 0
      }}
      checkedIconName=""
      checkedColor={checkboxColor}
      uncheckedColor={checkboxColor}
    />
  )
}

export default CohortCheckBox
