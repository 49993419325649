import React from 'react'

import useAppCoordinator from './useAppCoordinator'
import useCurrentUserLoader from '../hooks/useCurrentUserLoader'
import LoadingCheck from './LoadingCheck'
import LoginCheck from './LoginCheck'
import AppListener from './AppListener'
import ResetPasswordCheck from './ResetPasswordCheck'
import RegisterCheck from './RegisterCheck'
import VerifyRegistrationCheck from './VerifyRegistration'
import TermsCheck from './TermsCheck'
import UnclaimableDomain from '../screens/authentication/UnclaimableDomain'

const AppCoordinator = () => {
  const {
    state: { value, event },
    send
  } = useAppCoordinator()
  const { firebaseInitialized, firebaseToken } = useCurrentUserLoader()

  const currentState = typeof value === 'string' ? value : Object.keys(value)[0]

  const commonProps = {
    send,
    event,
    payload: event.payload,
    state: value,
    firebaseInitialized,
    firebaseToken
  }

  switch (currentState) {
    case 'loadingCheck':
      return <LoadingCheck {...commonProps} />
    case 'loginCheck':
      return <LoginCheck {...commonProps} />
    case 'verifyRegistrationCheck':
      return <VerifyRegistrationCheck {...commonProps} />
    case 'registerCheck':
      return <RegisterCheck {...commonProps} />
    case 'app':
      return <AppListener {...commonProps} />
    case 'resettingPassword':
      return <ResetPasswordCheck {...commonProps} />
    case 'verifyTermsCheck':
      return <TermsCheck {...commonProps} />
    case 'unclaimableDomain':
      return <UnclaimableDomain {...commonProps} />
    default:
      return null
  }
}

export default AppCoordinator
