import React, { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/native'
import ScreenContainer from '../../components/layout/ScreenContainer'

import { useRoute } from '@react-navigation/native'
import useCompanyQuery from '../../hooks/useCompanyQuery'
import { SafeAreaView, ScrollView, View } from 'react-native'

import { Flex } from '../../components/FlexBox'

import useCompanyUsersQuery from '../Settings/hooks/useCompanyUsersQuery'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import SkeletonContainer from '../../components/skeletonLoadings/SkeletonContainer'
import ProfileCompanySummary from './components/ProfileCompanySummary'
import CompanyProductList from './components/CompanyProductList'
import ProductOverview from './components/ProductOverview'
import CompanySummarySkeleton from './components/skeletons/CompanySummarySkeleton'
import { BackArrow } from './components/SharedComponents'
import CompanySubmissionList from './components/CompanySubmissionList'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import InnovatorWelcome from './components/InnovatorWelcome'
import useLastSubmittedSubmission from '../../hooks/useLastSubmittedSubmission'
import useDelegationsQuery from '../Product/hooks/useDelegationsQuery'
import ProductUpdateOrCreateModal from './components/ProductUpdateOrCreateModal'
import { MarketSegment, InnovatorProduct } from '../../types'
import useInnovationAccess from '../../hooks/useInnovationAccess'
import useFeatureFlag from '../../hooks/useFeatureFlag'

const StyledFlex = styled(Flex).attrs({
  w: '100%',
  flex: 1,
  flexDirection: 'column'
})``

const InnovatorDirectoryProfile = () => {
  const { space, colors } = useTheme()
  const { currentUser } = useCurrentUser()
  const linkToScreen = useLinkToScreen()
  const { curate, innovatorTeam: isInnovator } = useInnovationAccess()
  const route: any = useRoute()
  const companyId = route?.params?.companyId
  const { delegations, loading: loadingDelegations } = useDelegationsQuery()
  const { lastSubmittedSubmission } = useLastSubmittedSubmission()
  const innovationInnovatorDirectoryEnabled = useFeatureFlag(
    `innovationInnovatorDirectory`
  )

  const [openProductDialog, setOpenProductDialog] = useState(
    !!lastSubmittedSubmission?.submissionId
  )
  useEffect(() => {
    if (lastSubmittedSubmission?.submissionId) {
      setTimeout(() => {
        setOpenProductDialog(true)
      }, 700)
    }
  }, [lastSubmittedSubmission?.submissionId])

  // JC: Since we're using the same routing for all roles, when an innovator
  // deep links to /innovator-directory with different companyId, we redirect them to their own profile
  useEffect(() => {
    if (
      isInnovator &&
      currentUser?.person?.innovationCompany?.id !== route?.params?.companyId
    ) {
      linkToScreen('InnovatorDirectoryProfile', {
        companyId: currentUser?.person?.innovationCompany?.id
      })
    }
  }, [isInnovator, currentUser])

  const { company, loading } = useCompanyQuery(companyId)
  const { users, pendingUsers, loading: loadingUsers } = useCompanyUsersQuery({
    id: companyId
  })
  const isSmallScreen = useIsSmallScreen()
  const [productSelectedId, setProductSelectedId] = useState<string | null>(
    null
  )
  const [submissionSelectedId, setSubmissionSelectedId] = useState<
    string | null
  >(null)

  const submissionId = useMemo(() => {
    if (productSelectedId) {
      return company.products?.find(p => p.id === productSelectedId)
        ?.submissionId
    }
    if (submissionSelectedId) {
      return submissionSelectedId
    }
    return null
  }, [productSelectedId, submissionSelectedId])

  const formId = useMemo(() => {
    if (productSelectedId) {
      return company.products?.find(p => p.id === productSelectedId)?.formId
    }
    return null
  }, [productSelectedId, submissionSelectedId])

  const marketSegment: MarketSegment | null = useMemo(() => {
    if (productSelectedId && company?.products?.length) {
      return (
        company.products.find(p => p.id === productSelectedId)?.delegation
          ?.marketSegment || null
      )
    }
    return null
  }, [productSelectedId, company])

  const selectedProduct = company?.products?.find(
    p => p.id === productSelectedId
  )

  const canEdit = useMemo(() => selectedProduct && !!selectedProduct?.canEdit, [
    selectedProduct
  ])

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: colors.appBackground
      }}
    >
      <ScreenContainer
        header="menu-header"
        style={{
          paddingHorizontal: isSmallScreen ? 0 : `${space[4]}px`,
          paddingVertical: isSmallScreen ? 0 : `${space[4]}px`
        }}
      >
        {curate ? <BackArrow routeName="InnovatorDirectoryList" /> : null}
        <StyledFlex>
          <Flex
            flexDirection={isSmallScreen ? 'column' : 'row'}
            width="100%"
            justifyContent="center"
            flexBasis="100%"
            margin="0 auto"
          >
            <Flex
              style={{
                width: '35%',
                marginTop: space[3]
              }}
            >
              <ScrollView
                style={{
                  width: '100%',
                  maxWidth: 1000
                }}
                contentContainerStyle={{
                  flex: 1
                }}
              >
                <SkeletonContainer
                  isLoading={
                    !company || loadingUsers || loading || loadingDelegations
                  }
                  Skeleton={CompanySummarySkeleton}
                >
                  <ProfileCompanySummary
                    company={company}
                    users={users}
                    pendingUsers={pendingUsers}
                  />
                  <CompanyProductList
                    company={company}
                    productSelectedId={productSelectedId}
                    openProductDialog={setOpenProductDialog}
                    onProductSelected={setProductSelectedId}
                  />
                  {curate ? (
                    <CompanySubmissionList
                      companyId={companyId}
                      productSelectedId={submissionSelectedId}
                      onProductSelected={v => {
                        setProductSelectedId(null)
                        setSubmissionSelectedId(v)
                      }}
                    />
                  ) : null}
                </SkeletonContainer>
              </ScrollView>
            </Flex>
            <ScrollView
              style={{
                width: '100%',
                maxWidth: 1000,
                marginLeft: space[4],
                ...(isSmallScreen ? { borderRadius: 0 } : {})
              }}
              contentContainerStyle={{
                flex: 1
              }}
            >
              {productSelectedId || submissionSelectedId ? (
                <ProductOverview
                  submissionId={submissionId}
                  formId={formId}
                  onProductSelected={setProductSelectedId}
                  isInnovatorProduct={!!productSelectedId}
                  canDelete={selectedProduct?.canDelete}
                  canEdit={canEdit}
                  canPublish={
                    selectedProduct?.canPublish &&
                    innovationInnovatorDirectoryEnabled
                  }
                  isPublished={selectedProduct?.publishedLog?.isPublished}
                  productId={productSelectedId}
                  marketSegment={marketSegment}
                />
              ) : isInnovator ? (
                <InnovatorWelcome
                  openProductDialog={setOpenProductDialog}
                  canAddInnovatorProducts={company?.canAddInnovatorProducts}
                />
              ) : null}
            </ScrollView>
          </Flex>
        </StyledFlex>
      </ScreenContainer>
      {openProductDialog ? (
        <ProductUpdateOrCreateModal
          testID={'newProductModal'}
          company={company}
          delegations={delegations}
          products={company?.products}
          isOpen={openProductDialog}
          onClose={(product: InnovatorProduct) => {
            if (product) {
              setProductSelectedId && setProductSelectedId(product.id)
            }
            setOpenProductDialog(false)
          }}
          setProductSelectedId={setProductSelectedId}
        />
      ) : null}
      <View
        style={{
          width: '100%',
          backgroundColor: '#E1EDF9',
          height: '114px',
          position: 'absolute',
          top: '20%'
        }}
      />
    </SafeAreaView>
  )
}

export default InnovatorDirectoryProfile
