import React, { useEffect, useState } from 'react'

// Hooks
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Modal from './components/Modal'
import { TableContainer } from '../../../../../components/StaticForm'
import { DynamicTable, useTable } from '../../../../../components/Table'
import { getQuestionsManagementTableConfig } from './constants'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../../../../components/DynamicTable'
// Skeleton
import TableSkeleton from '../../../../HomeScreen/TableSkeleton'
import SkeletonContainer from '../../../../../components/skeletonLoadings/SkeletonContainer'
import useGetQuestions from './hooks/useGetQuestions'
import { View } from 'react-native'
import { CreateIcon } from '../../../constants'
import { QuestionValues } from '../../types'
import Dropdown, {
  DropdownOption
} from '../../../../../components/common/Dropdown'
import {
  Question,
  QuestionTypeEnum
} from '../../../../../components/DynamicForm/types'
import TextInput from '../../../../../ui-library/TextInput'
import useDebounce from '../../../../../hooks/useDebounce'
import useFormsQuery from '../../../../Product/hooks/useFormsQuery'
import DeleteModal from './components/DeleteModal'

const Questions = () => {
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentQuestion, setCurrentQuestion] = useState<
    QuestionValues | undefined
  >()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)

  const [
    currentQuestionType,
    setCurrentQuestionType
  ] = useState<QuestionTypeEnum>(QuestionTypeEnum.TextInput)

  const { forms = [], loading: fetchLoadingForms } = useFormsQuery()
  const [currentForm, setCurrentForm] = useState<string | null>()

  const formsOptions: DropdownOption[] = forms?.map(form => ({
    label: form.name,
    value: form.id
  }))

  const { loading: fetchLoading, questions } = useGetQuestions(
    false,
    undefined,
    currentForm
  )

  useEffect(() => {
    if (!fetchLoadingForms && forms.length && !currentForm) {
      setCurrentForm(forms[0].id)
    }
  }, [fetchLoadingForms, forms, currentForm])

  const [search, setSearch] = useState<string | undefined>(undefined)
  const debouncedSearch = useDebounce(search, 300)

  const [data, setData] = useState<Question[]>([])

  useEffect(() => {
    if (fetchLoading && !isLoading) {
      setIsLoading(true)
    }
  }, [fetchLoading, isLoading])

  useEffect(() => {
    if (!fetchLoading && questions.length) {
      let newData: Question[] = questions ?? []
      if (currentQuestionType) {
        newData = newData.filter(
          question => question.type === currentQuestionType
        )
      }
      if (debouncedSearch != null) {
        newData = newData.filter(question => {
          const allTerms = [
            ...Object.keys(question),
            ...Object.values(question).map(
              value => String(JSON.stringify(value)) ?? String(value)
            )
          ]
          const includeTerm = allTerms.find(term =>
            term.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
          if (includeTerm) {
            return true
          } else {
            return false
          }
        })
      }
      setData(newData)
      setIsLoading(false)
    }
  }, [questions, fetchLoading, debouncedSearch, currentQuestionType])

  const handleEditQuestion = (question: QuestionValues) => {
    setCurrentQuestion(question)
    setIsOpen(true)
  }

  const handleDeleteQuestion = (question: QuestionValues) => {
    // Remove Question
    setCurrentQuestion(question)
    setIsOpenDeleteDialog(true)
  }

  const onClose = () => {
    setCurrentQuestion(undefined)
    setIsOpen(false)
  }

  const handleCreateQuestion = () => {
    setCurrentQuestion(undefined)
    setIsOpen(true)
  }

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps = useTable({
    data,
    config: getQuestionsManagementTableConfig({
      handleEditQuestion,
      handleDeleteQuestion
    })
  })

  const questionTypes = Object.keys(QuestionTypeEnum)
  const questionTypesValues = Object.values(QuestionTypeEnum)
  const typeOptions: DropdownOption[] = questionTypes.map((typeKey, index) => ({
    label: typeKey,
    value: questionTypesValues[index]
  }))

  return (
    <View style={{ width: '100%', height: '100%' }}>
      <View
        style={{
          width: '100%',
          height: 50,
          marginTop: 10,
          paddingHorizontal: 10,
          flexDirection: 'row'
        }}
      >
        {/* Filters */}
        <View
          style={{
            marginHorizontal: 10,
            height: '90%',
            width: '80%',
            flexDirection: 'row'
          }}
        >
          <Dropdown
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentQuestionType}
            containerStyle={{ width: 200, height: 50, marginRight: 10 }}
            isLoading={fetchLoading}
            options={typeOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => setCurrentQuestionType(value)}
            name={'questionTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
          <Dropdown
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentForm ?? null}
            containerStyle={{ width: 350, height: 50, marginRight: 10 }}
            isLoading={fetchLoadingForms}
            options={formsOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => setCurrentForm(value)}
            name={'formTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
          <TextInput
            style={{
              minHeight: 0,
              alignSelf: 'center'
            }}
            containerStyles={{
              width: 250,
              height: 50
            }}
            placeholder="Search..."
            value={search}
            onChangeText={setSearch}
          />
        </View>
        <CreateIcon
          style={{ alignSelf: 'flex-end' }}
          disabled={false}
          isLoading={false}
          title={t('platformManagement:forms:menuItems:questions:addNew')}
          onPress={handleCreateQuestion}
        />
      </View>

      {/* Table */}
      <View
        style={{
          flex: 1,
          paddingHorizontal: 10
        }}
      >
        <SkeletonContainer isLoading={isLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t('platformManagement:categories:noCategories')}
            />
          </TableContainer>
        </SkeletonContainer>
      </View>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        question={currentQuestion}
        isParent={false}
        formType={forms.find(form => form.id === currentForm)?.type}
        questionType={currentQuestionType ?? undefined}
      />
      {currentForm ? (
        <DeleteModal
          isOpen={isOpenDeleteDialog}
          question={currentQuestion}
          onClose={() => {
            setCurrentQuestion(undefined)
            setIsOpenDeleteDialog(false)
          }}
          formId={currentForm}
        />
      ) : null}
    </View>
  )
}

export default Questions
