import gql from 'graphql-tag'

const InnovationSubmissionCohortFieldsFragment = gql`
  fragment InnovationSubmissionFieldsFragment on InnovationSubmission {
    id
    callForSubmissionId
    pdfCloudinaryId
    innovationSubmissionStatus: status
    step
    hasBeenFastPassed
    callForSubmission {
      name
      currentStep {
        name
      }
    }
    submissionInfo {
      name
      categoryLabel
    }
    # TODO: AG: Create a API Function that returns the values
    # instead of getting all the answers.
    # advisorsReviews and submissionScores
    advisorsReviews {
      advisorId
      submissionId
      answers {
        id
        questionId
        value
      }
    }
    submissionAverage {
      avgScore
      stdDev
      totalFav
      totalAcademy
      wass
      submissionScores {
        submissionId
        question {
          id
          questionText
        }
        value
      }
    }
    submissionVotes {
      id
      company {
        id
        name
      }
      vote
      favorite
      reason
    }
    cohorts {
      group {
        id
        name
        shortName
      }
      deletedById
      isConfirmed
      hasPresentedBefore
    }
    company {
      name
    }
  }
`

export default InnovationSubmissionCohortFieldsFragment
