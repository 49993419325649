import styled from 'styled-components/native'

import { StyledButtonUI } from '../../components/DynamicTable/components/SearchBar/SharedStyledComponents'
import { H4 } from '../../components/common/Text'

export const ModalContainer = styled.View`
  flex-direction: column;
  ${({ theme: { space } }) => `
    padding-vertical: ${space[1]}px;
  `}
`

export const Title = styled(H4).attrs(({ theme: { fontWeights } }) => ({
  h4Style: {
    fontWeight: fontWeights.medium
  }
}))``

export const CancelButton = styled(StyledButtonUI).attrs(
  ({ theme: { sizes } }) => ({
    type: 'outline',
    buttonStyle: {
      width: sizes[6]
    }
  })
)`
  ${({ theme: { sizes } }) => `
    margin-right: ${sizes[2]}px;
  `}
`
export const SaveButton = styled(StyledButtonUI).attrs(
  ({ theme: { sizes, colors } }) => ({
    buttonStyle: {
      width: sizes[6],
      backgroundColor: colors.buttonBackground
    }
  })
)`
  ${({ theme: { sizes } }) => `
    margin-right: ${sizes[2]}px;
  `}
`

export const DeleteButton = styled(StyledButtonUI).attrs(
  ({ theme: { sizes, colors } }) => ({
    buttonStyle: {
      width: sizes[6],
      backgroundColor: colors.delete
    }
  })
)`
  ${({ theme: { sizes } }) => `
    margin-right: ${sizes[2]}px;
  `}
`

export const Footer = styled.View`
  flex-direction: row;
  ${({ theme: { space } }) => `
    margin-top: ${space[3]}px;
  `}
`
