import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface LogoProps {
  color: string
  size?: number
  style?: ViewStyle
}

const Logo = React.memo(
  ({ color = '#CFD2D3', size = 36, style }: LogoProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox="0 0 128 128">
        <Path
          d="M15.19,60.2C19.53,55.87,26,53,34.59,53c14.65,0,27,9.1,27,26.59,0,19.12-15.46,33.63-37,33.63C7.19,113.24,0,106.59,0,99.39a7.39,7.39,0,0,1,.49-3,7.24,7.24,0,0,1,1.67-2.55,7.38,7.38,0,0,1,5.57-2.16c11.12,0,7.45,16.15,19,16.15,6.91,0,14.51-6.91,14.51-25.64,0-12.61-4.61-20.34-13.84-20.34-7.86,0-11.52,4.07-11.52,8.54,0,1.5.13,2.82.13,4.08,0,2.3-1.49,3.66-4.34,3.66C9.23,78,7.6,76.71,7.6,74.28c0-2.82.14-6.5.27-9.62L10,15.43h4.49c3,3,8.82,6.57,20.64,6.57,10,0,15.6-2.41,18.65-6.57h3.85C54.13,32.24,42.74,38,26.06,38c-4.34,0-7.73-.42-9.9-1.37ZM96.55,14.8C116.68,14.8,128,28.2,128,44.69S116.68,74.58,96.55,74.58,65.11,61.14,65.11,44.64,76.44,14.76,96.55,14.76Zm0,54.61c8.62,0,11.58-7.71,11.58-24.72s-3-24.78-11.58-24.78S85,27.63,85,44.64s3,24.72,11.56,24.72Zm21.23,16.21H75.34v7.13h42.44Z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default Logo
