import React, { useState } from 'react'
import useDebounce from '../../../hooks/useDebounce'
import { AutoCompleteDropdownProps } from './constants'
import { AutoCompleteDropdownContainer } from './SharedStyledComponents'
import Dropdown from '../../common/Dropdown'

// Disabled filter so the API can perform all the work
const filterNone = () => true

const AutoCompleteDropdown = ({
  options,
  onSelectValue,
  value,
  placeholder,
  disableFiltering = false,
  loading = false,
  isMulti = false,
  styles
}: AutoCompleteDropdownProps) => {
  const [queryString, setQueryString] = useState('')
  const debouncedQueryString = useDebounce(queryString, 400)

  return (
    <AutoCompleteDropdownContainer>
      <Dropdown
        isFilter
        name={placeholder}
        options={options}
        value={value}
        onInputChange={value => setQueryString(value)}
        placeholder={placeholder}
        hasError={false}
        onBlur={() => {}}
        onSelect={onSelectValue}
        style={styles}
        isClearable
        isMulti={isMulti}
        filterOption={disableFiltering ? filterNone : undefined}
        noOptionsMessage={
          debouncedQueryString
            ? loading
              ? 'Loading...'
              : 'No options found'
            : `Search ${placeholder}...`
        }
        menuPortalTarget={document?.body}
      />
    </AutoCompleteDropdownContainer>
  )
}

export default AutoCompleteDropdown
