import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'
import { Group } from '../../../types'
import { NEW_DYNAMIC_TABLE_FF } from '../../../components/DynamicTable/constants'
import useFeatureFlag from '../../../hooks/useFeatureFlag'

export const assignSubmissionToCohortVotingMutation = gql`
  mutation upsertSubmissionGroupMap(
    $groupId: String!
    $submissionId: String!
    $isDeleted: Boolean
  ) {
    assignSubmissionToCohortVoting(
      groupId: $groupId
      submissionId: $submissionId
      isDeleted: $isDeleted
    ) {
      id
      cohorts {
        group {
          id
          name
        }
        isConfirmed
        hasPresentedBefore
        deletedById
      }
    }
  }
`

const useUpsertSubmissionGroupMapMutation = () => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const [assignSubmissionToCohortVoting, { loading }] = useMutation(
    assignSubmissionToCohortVotingMutation
  )

  // JC: Since we are using the query searchInnovationEngine in all the tables,
  // we created a dynamic query name to target specific tables to be able to save the query variables
  // and e able to target it while updating the cache

  // FPO: New mutation returns innovation submission, with cohorts field.
  // Cache entries for submission should be handled automatically by apollo client
  // and there shouldn't be need for doing this manually ~ 2023/07/30

  const upsertSubmissionGroupMap = async (data: {
    variables: {
      group: Group
      submissionId: string
      isDeleted: boolean
    }
  }) => {
    const { group, submissionId, isDeleted } = data.variables

    const { data: upsertData, errors } = await assignSubmissionToCohortVoting({
      variables: {
        submissionId,
        isDeleted,
        groupId: group.id
      }
    })

    return isInnovationNewDynamicTable
      ? { data: upsertData, errors: !!errors }
      : upsertData?.assignSubmissionToCohortVoting
  }

  return {
    upsertSubmissionGroupMap,
    loading
  }
}

export default useUpsertSubmissionGroupMapMutation
