import { Route } from '@react-navigation/native'
import { atom } from 'recoil'

// Types
export interface UnsavedAdvisorChangesAtomData {
  unsavedValues?: any | null
  formRouteState?: Route<any> | null
  shouldInitForm?: boolean
}

export const unsavedAdvisorChangesAtom = atom<UnsavedAdvisorChangesAtomData>({
  key: 'unsavedAdvisorChangesDataState',
  default: {
    unsavedValues: null,
    formRouteState: null,
    shouldInitForm: false
  }
})

export const hasUnsavedChangesAtom = atom<boolean>({
  key: 'hasUnsavedAdvisorChangesBoolState',
  default: false
})
