import React, { useState, useRef, useEffect, useContext } from 'react'
import { View, ViewProps } from 'react-native'
import { createPortal } from 'react-dom'
import styled, { ThemeContext } from 'styled-components/native'

import { Text } from '../../components/common/Text'
import { Flex } from '../../components/FlexBox'
import Icon, { IconProps } from '../../components/icon'

const TOOLTIP_WIDTH = 280

export const Info = styled(View)`
  margin-left: 10px;
  margin-bottom: 2px;
`

export const TooltipText = styled(Text)`
  position: absolute;
  width: ${TOOLTIP_WIDTH}px;
  
  background: ${({ theme }) => theme.colors.cta.secondary};
  padding: 4px 4px;
  border-radius: 7px;
  color: white;
  z-index: 999;
}
`

interface TooltipProps {
  text: string
  children: JSX.Element | JSX.Element[]
  iconProps?: IconProps
}

interface ViewElement extends ViewProps {
  getBoundingClientRect?: Function
}

const Tooltip = ({
  text,
  children,
  iconProps = {
    name: 'info'
  }
}: TooltipProps) => {
  const theme: any = useContext(ThemeContext)
  const labelRef = useRef<ViewElement>(null)
  const [hover, setHover] = useState(false)
  const [styles, setStyles] = useState({})

  const onMouseEnter = () => {
    if (labelRef.current) {
      // @ts-ignore
      labelRef.current.measure((_, __, labelWidth) => {
        // check if overflows window
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight
        const bounds = labelRef.current?.getBoundingClientRect?.()
        let left = 0
        let bottom = 0
        if (bounds) {
          left = bounds['x'] + labelWidth + 20
          bottom = windowHeight - bounds['y']
        }
        // if tooltip overflows window, move it to the left
        if (left + TOOLTIP_WIDTH > windowWidth) {
          const overflow = left + TOOLTIP_WIDTH - windowWidth
          left -= overflow + 10
        }
        setStyles({
          left: `${left}px`,
          bottom: `${bottom}px`
        })
      })
      // show tooltip after 10 miliseconds
      setTimeout(() => {
        setHover(true)
      }, 10)
    }
  }

  const onMouseLeave = () => {
    setHover(false)
  }

  // hide tooltip when any scroll is detected
  useEffect(() => {
    document.addEventListener('scroll', onMouseLeave, true)
    return () => {
      document.removeEventListener('scroll', onMouseLeave)
    }
  }, [])

  return (
    <Flex
      flexDirection="row"
      w="100%"
      alignItems="center"
      position="relative"
      onMouseEnter={() => onMouseLeave()}
    >
      <View
        ref={labelRef as any}
        style={{
          flexShrink: 1
        }}
      >
        {children}
      </View>
      <Info
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
      >
        <Icon
          width={11}
          height={11}
          color={theme.colors.primaryPalette.black}
          {...iconProps}
          name={iconProps.name || 'info'}
        />
        {hover
          ? createPortal(
              <TooltipText style={styles}>{text}</TooltipText>,
              document.body
            )
          : null}
      </Info>
    </Flex>
  )
}

export default Tooltip
