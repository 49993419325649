import { Flex } from '../../FlexBox'
import { Text } from '../../common/Text'
import styled from 'styled-components/native'

export const InputContainer = styled(Flex).attrs(() => ({
  flexWrap: 'wrap',
  w: '100%'
}))``

export const Title = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    color: ${colors.text2};
    fontSize: ${fontSizes[6]}px;
    fontWeight: ${fontWeights.light}px;
    margin-bottom: ${space[2]}px;
`}
`

export const TableContainer = styled(Flex).attrs(
  ({ height, theme: { space, isSmallScreen } }) => ({
    flexDirection: 'row',
    marginTop: `${isSmallScreen ? space[4] : space[3]}px`,
    w: '100%',
    height: height || '90%'
  })
)``
