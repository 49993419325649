import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import QuestionFieldsFragment from '../../../../../../graphql/QuestionFieldsFragment'

export const upsertParentQuestionMutation = gql`
  mutation upsertParentQuestion($input: QuestionInput) {
    upsertParentQuestion(input: $input) {
      ...QuestionFieldsFragment
    }
  }
  ${QuestionFieldsFragment}
`

const useUpsertParentQuestion = (options = {}) => {
  const [
    upsertQuestion,
    { loading: loadingUpsertQuestion }
  ]: any[] = useMutation(upsertParentQuestionMutation, options)

  return [upsertQuestion, loadingUpsertQuestion]
}

export default useUpsertParentQuestion
