import { format } from 'date-fns'
import { timezone } from 'expo-localization'

export const localTimezone = timezone

export function displayDate(date) {
  return format(date, 'MMMM dd, yyyy')
}

export function csvDisplayDate(date) {
  return format(date, 'MMMM dd yyyy')
}

export function displayTime(date) {
  return format(date, `h:mm a`)
}
