import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const registrationMutation = gql`
  mutation registration(
    $firstName: String!
    $lastName: String!
    $jobTitle: String!
    $personId: String!
    $email: String!
    $companyName: String!
    $originId: String!
    $profile: CompanyProfileInput!
    $termsId: String!
    $privacyId: String!
    $cookieId: String!
    $userId: String!
  ) {
    # Mutations execute sequentially, add email and update person first
    email: addEmailAddressToPerson(id: $personId, email: $email) {
      id
    }

    person: upsertPerson(
      id: $personId
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
    ) {
      id
      firstName
      lastName
      jobTitle
    }

    company: registerCompany(
      name: $companyName
      personId: $personId
      jobTitle: $jobTitle
      originId: $originId
      profile: $profile
      userId: $userId
    ) {
      id
      name
      companyProfile {
        id
        url
        yearFounded
        funding
        employeeCount
        country {
          id
          name
        }
        domains {
          id
          name
        }
      }
    }

    termsAgreement: createLegalAgreement(legalDocId: $termsId, location: web) {
      id
    }

    privacyAgreement: createLegalAgreement(
      legalDocId: $privacyId
      location: web
    ) {
      id
    }

    cookieAgreement: createLegalAgreement(
      legalDocId: $cookieId
      location: web
    ) {
      id
    }
  }
`

const useRegistrationMutation = (options = {}) => {
  const [register, { loading }]: any[] = useMutation(
    registrationMutation,
    options
  )

  return [register, loading]
}

export default useRegistrationMutation
