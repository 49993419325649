import { useSubscription } from '@apollo/client'
import {
  onAnswerFileAttachmentUpdated,
  answerFileAttachmentMapUpdated
} from '../subscriptions/subscribeToAnswerFileAttachmentMapUpdate'
import { questionQuery } from './useQuestionQuery'

const useAnswerSubscription = (
  answerId: string | undefined,
  answerFileAttachmentMapId: string,
  questionId: string | undefined,
  formSubmissionId: string | undefined,
  isReadOnly: boolean
) => {
  useSubscription(answerFileAttachmentMapUpdated, {
    variables: { answerId, answerFileAttachmentMapId },
    skip: !answerId || isReadOnly,
    onData: ({ client, data }) =>
      onAnswerFileAttachmentUpdated(
        client,
        data,
        questionQuery,
        questionId,
        formSubmissionId
      )
  })
}

export default useAnswerSubscription
