import React from 'react'
import { ThemeProvider as NativeThemeProvider } from 'styled-components/native'

// eslint-disable-next-line no-restricted-imports
import { ThemeProvider as WebThemeProvider } from 'styled-components'

import useAppCoordinator from '../../AppCoordinator/useAppCoordinator'

import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import lightTheme, { dark as darkTheme } from '../../constants/Theme'

const AUTHENTICATION_SCREENS = [
  'loadingCheck',
  'loginCheck',
  'verifyRegistrationCheck',
  'registerCheck',
  'resettingPassword',
  'legal',
  'verifyTermsCheck',
  'joinTeam'
]
const isAuthenticationState = stateValue => {
  const currentState =
    typeof stateValue === 'string' ? stateValue : Object.keys(stateValue)[0]

  return AUTHENTICATION_SCREENS.includes(currentState)
}

const ThemeProvider = ({ children }: any) => {
  const {
    state: { value }
  } = useAppCoordinator()
  const isAuthenticationScreen = isAuthenticationState(value)
  const isSmallScreen = useIsSmallScreen()

  const activeTheme = isAuthenticationScreen ? darkTheme : lightTheme
  const extendedTheme = {
    ...activeTheme,
    ...{ isSmallScreen: isSmallScreen }
  }
  return (
    <NativeThemeProvider theme={extendedTheme}>
      <WebThemeProvider theme={extendedTheme}>{children}</WebThemeProvider>
    </NativeThemeProvider>
  )
}

export default ThemeProvider
