import React from 'react'

import { TableConfig, CellRendererProps } from './'
import StandardInput from './components/StandardInput'
import DeleteIcon from './components/DeleteIcon'
import Preview from '../../screens/Product/components/PitchDeckPreview'
import { AppSearchFiltersInput } from './types'

export const getDynamicTableConfig = ({
  name,
  questions,
  removeMutation,
  updateMutation,
  refetchQueries,
  hasPreview = false
}): TableConfig<any> => {
  const config: TableConfig<any> = {
    name,
    refetchQueries,
    removeMutation,
    updateMutation,
    columns: []
  }

  config.columns = questions.map(question => ({
    header: question.questionText,
    width: (hasPreview ? 90 : 95) / questions.length,
    emptyMessage: question?.emptyMessage ?? 'No Data Added',
    isEditable: question?.isEditable ?? false,
    itemMap: question.id,
    Cell: ({
      item,
      itemMap,
      handlers,
      isEditable,
      label
    }: CellRendererProps<any>) => {
      return (
        <StandardInput
          item={item}
          itemMap={itemMap}
          handlers={handlers}
          isEditable={isEditable}
          label={label}
        />
      )
    }
  }))

  hasPreview &&
    config.columns.unshift({
      header: null,
      width: 5,
      Cell: ({ item }: CellRendererProps<any>) => <Preview item={item} />
    })

  config.columns.push({
    header: null,
    width: 5,
    Cell: ({ item, handlers, isReadOnly }: CellRendererProps<any>) => {
      return isReadOnly ? null : (
        <DeleteIcon item={item} handlers={handlers} category="customers" />
      )
    }
  })

  return config
}

/**
 * Combines top level filters input
 * @param filtersOne: AppSearchFiltersInput
 * @param filtersTwo: AppSearchFiltersInput
 * @returns AppSearchFiltersInput
 */
export const mergeFilters = (
  filtersOne: AppSearchFiltersInput,
  filtersTwo: AppSearchFiltersInput
): AppSearchFiltersInput => {
  return {
    all: [...(filtersOne?.all || []), ...(filtersTwo?.all || [])],
    any: [...(filtersOne?.any || []), ...(filtersTwo?.any || [])],
    none: [...(filtersOne?.none || []), ...(filtersTwo?.none || [])]
  }
}
