import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, TextInput, Text as RNText } from 'react-native'
import { path } from 'ramda'
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell
} from 'react-native-confirmation-code-field'
import useAppCoordinator from '../../../AppCoordinator/useAppCoordinator'
import Firebase from '../../../Firebase'
import { getAuth as getFBAuth, signInWithCustomToken } from 'firebase/auth'
import { CodeComponentProps } from '../constants'

import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useThemeColor from '../../../hooks/useThemeColor'
import useLastAttemptedEmail from '../../../hooks/useLastAttemptedEmail'
import useTranslation from '../../../hooks/useTranslation'
import { CodeContainer } from '../../authentication/AuthCodeSentScreen'
import {
  AuthenticationErrorContainer,
  ErrorText
} from '../../authentication/components/SharedStyledComponents'
import CallToActionBtns from '../../authentication/components/sharedComponents/CallToActionBtns'
import useVerifyAuthCode from '../../authentication/mutations/useVerifyAuthCode'
import useSendAuthCode from '../../authentication/mutations/useSendAuthCode'
import useFinishInnovatorRegistration from '../../authentication/mutations/useFinishInnovatorRegistration'
import client from '../../../API'

const CELL_COUNT = 6

const CodeComponent = ({ currentUser }: CodeComponentProps) => {
  const { send }: any = useAppCoordinator()
  const { trackWithProperties, track } = useMixpanel()
  const isSmallScreen = useIsSmallScreen()
  const inputBorderColor = useThemeColor('inputText')
  const { t } = useTranslation()
  const [newCodeDisabled, setNewCodeDisabled] = useState(false)

  const {
    lastAttemptedEmail,
    updateLastAttemptedEmail
  } = useLastAttemptedEmail()

  const [authCodeError, setAuthCodeError] = useState()
  const [authCode, setAuthCode] = useState('')
  const desktopCodeFieldRef = useRef<TextInput>()

  const codeFieldRef = useBlurOnFulfill({
    value: authCode,
    cellCount: CELL_COUNT
  })
  const textContentType = 'oneTimeCode'
  const isDesktopWeb = Platform.OS === 'web'
  const keyboardType = Platform.OS === 'web' ? 'default' : 'number-pad'

  const activeRef = isDesktopWeb ? desktopCodeFieldRef : codeFieldRef

  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: authCode,
    setValue: setAuthCode
  })
  const finishInnovatorRegistration = useFinishInnovatorRegistration({})

  const loginWithToken = async (token: any) => {
    try {
      await signInWithCustomToken(getFBAuth(Firebase), token)
      track(EVENT_MAP.auth.loginWithCode)

      await finishInnovatorRegistration()

      send('INVITATION_CODE_VERIFIED')
    } catch (error: any) {
      await getFBAuth(Firebase).signOut()
      await client.clearStore()

      send('NOT_CLAIMABLE', {
        errorMessage: error.message
      })
    }
  }

  const [verifyCode] = useVerifyAuthCode({
    onCompleted: results => {
      loginWithToken(results.verifyAuthenticationCode)
    },
    onError: error => {
      if (!error || !error.message) {
        return
      }
      if (error.message === 'Incorrect Authentication Code') {
        setAuthCodeError(t('error:auth:badCode'))
      } else if (error.message === 'No Authentication Code For This User') {
        setAuthCodeError(t('error:auth:codeExpired'))
      } else {
        // going to leave the below console just in case we need a new message
        console.warn(`Code verify error: "${error.message}" `, error)
        setAuthCodeError(t('error:auth:other'))
      }
    }
  })

  const verifyAuthCode = async () => {
    const emailForCode =
      path(['person', 'emailAddresses', '0', 'email'], currentUser) ||
      lastAttemptedEmail
    if ((!emailForCode && !currentUser) || !authCode) return
    await verifyCode({
      variables: { email: emailForCode, code: authCode }
    })
  }

  const clearInput = () => {
    activeRef?.current?.clear()
    setAuthCode('')
  }

  const handleVerifyCode = () => {
    setAuthCodeError(undefined)
    verifyAuthCode()
    clearInput()
  }

  const isInt = val => {
    return val && Number.isInteger(Number(val))
  }

  const isValidCode = () => {
    const hasValue = !!authCode
    const isSixDigits = hasValue && (authCode || '').length === 6
    const isInteger = isInt(Number(authCode))
    return hasValue && isSixDigits && isInteger
  }

  const sendCode = useSendAuthCode({
    errorMessage: t('error:auth:sendAuthCode')
  })

  const sendAuthCode = async () => {
    if (!lastAttemptedEmail) return
    trackWithProperties(EVENT_MAP.auth.authCodeRequest, {
      destinationEmail: lastAttemptedEmail
    })
    await sendCode({ variables: { email: lastAttemptedEmail } })
  }

  const sendNewCode = () => {
    sendAuthCode()
    setNewCodeDisabled(true)
    setTimeout(() => {
      setNewCodeDisabled(false)
    }, 5000)
  }

  const onSubmitEditing = () =>
    isDesktopWeb && isValidCode() && handleVerifyCode

  useEffect(() => {
    let email = lastAttemptedEmail

    if (!lastAttemptedEmail && currentUser.person?.emailAddresses?.length) {
      email = currentUser.person?.emailAddresses[0].email
      updateLastAttemptedEmail(email)
    }
    sendCode({ variables: { email } })
  }, [])

  const renderCell = ({ index, symbol, isFocused }) => (
    <RNText
      key={index}
      style={[
        styles.defaultCell,
        isSmallScreen ? styles.smallCellContent : styles.cellContent,
        { borderColor: inputBorderColor, color: inputBorderColor },
        index === 0 ? { marginLeft: 0 } : {},
        isFocused && styles.focusCell
      ]}
      onLayout={getCellOnLayoutHandler(index)}
    >
      {symbol || (isFocused ? <Cursor /> : null)}
    </RNText>
  )

  return (
    <>
      <CodeContainer>
        <CodeField
          {...props}
          ref={activeRef as any}
          onSubmitEditing={onSubmitEditing}
          value={authCode}
          onChangeText={setAuthCode}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFieldRoot}
          keyboardType={keyboardType}
          textContentType={textContentType}
          renderCell={renderCell}
        />
      </CodeContainer>
      <AuthenticationErrorContainer>
        <ErrorText>{authCodeError || ''}</ErrorText>
      </AuthenticationErrorContainer>

      <CallToActionBtns
        primaryBtn={{
          title: t('joinTeam:buttons:register'),
          onPress: handleVerifyCode,
          disabled: !isValidCode()
        }}
        secondaryBtn={{
          title: newCodeDisabled
            ? t('joinTeam:authCode:codeSent')
            : t('joinTeam:authCode:newCode'),
          onPress: newCodeDisabled ? () => {} : sendNewCode,
          disabled: false
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  codeFieldRoot: { marginTop: 5 },
  defaultCell: {
    borderWidth: 1,
    marginLeft: 10,
    textAlign: 'center',
    borderRadius: 3,
    borderColor: 'white'
  },
  cellContent: {
    borderColor: 'white',
    width: 60,
    height: 70,
    lineHeight: 65,
    fontSize: 40
  },
  smallCellContent: {
    borderColor: 'white',
    width: 50,
    height: 60,
    lineHeight: 55,
    fontSize: 28
  },
  focusCell: {
    borderColor: 'rgba(255, 255, 255, .5)'
  }
})

export default CodeComponent
