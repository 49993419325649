import React, { useEffect, useState } from 'react'
import { DynamicTable, useTable } from '../../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../../components/DynamicTable'
import { useRoute } from '@react-navigation/native'
import styled from 'styled-components/native'
import withNavigationPermissions from '../../../navigation/withNavigationPermissions'
import { ScreenContainerWithMenuHeader } from '../../../components/layout/ScreenContainer'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useAdvisorQuery from '../../CallForSubmission/hooks/useAdvisorQuery'
import useCallForSubmissionQuery from '../hooks/useCallForSubmissionQuery'
import useNewCallForSubmissionQuery from '../../Curation/hooks/useNewCallForSubmissionQuery'
import { Text } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'
import { StyledFlex } from '../SubmissionDetails/components/SharedComponents'
import { Container } from '../../CallForSubmission/components/SharedComponents'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import Skeleton from './Skeleton'

import Header from './components/Header'
import { TableContainer } from '../../../components/StaticForm'
import { getAdvisorsProgressTableConfig } from '../constants'
import useTranslation from '../../../hooks/useTranslation'
import { EngineEnum } from '../../CallForSubmission/constants'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../../components/DynamicTable/constants'
import { InnovationAccessKey } from '../../../types'

export const Title = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights }, isSmallScreen }) => `
    color: ${colors.text2};
    fontSize: ${isSmallScreen ? fontSizes[4] : fontSizes[6]}px;
    fontWeight: ${fontWeights.regular};
`}
`

const CurationAdvisorDetailScreen = () => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const route: any = useRoute()
  const callForSubmissionId = route?.params?.id
  const advisorId = route?.params?.advisorId
  const { callForSubmission, loading: loadingCFS } = useCallForSubmissionQuery(
    isInnovationNewDynamicTable ? false : callForSubmissionId
  )
  const {
    callForSubmission: newCallForSubmission,
    loading: loadingNewCFS
  } = useNewCallForSubmissionQuery(
    callForSubmissionId,
    !isInnovationNewDynamicTable
  )
  const { advisorInfo, loading: loadingAdvisor } = useAdvisorQuery(
    callForSubmissionId,
    advisorId
  )
  const [favoriteQuestionId, setFavoriteQuestionId] = useState('')
  const [academyQuestionId, setAcademyQuestionId] = useState('')

  const newLoading = isInnovationNewDynamicTable ? loadingNewCFS : loadingCFS

  const cfs = isInnovationNewDynamicTable
    ? newCallForSubmission
    : callForSubmission

  const tableProps = useTable({
    config: getAdvisorsProgressTableConfig({
      cfsId: callForSubmissionId,
      engine: EngineEnum.InnovationSubmission,
      advisorId,
      favoriteQuestionId,
      submissionIdsToReview: (advisorInfo?.submissionsToReview ?? []).map(
        sr => sr.submissionId
      ),
      academyQuestionId,
      isInnovationNewDynamicTable
    })
  })

  useEffect(() => {
    if (callForSubmission) {
      setFavoriteQuestionId(
        callForSubmission.reviewFormQuestions?.find(rfq =>
          rfq.questionText.toLowerCase().includes('favorite')
        )?.id || ''
      )
      setAcademyQuestionId(
        callForSubmission.reviewFormQuestions?.find(rfq =>
          rfq.questionText.toLowerCase().includes('academy')
        )?.id || ''
      )
    }
  }, [callForSubmission])

  const isLoading = newLoading || loadingAdvisor

  let advisorName = `${advisorInfo?.userInfo?.person?.firstName} ${advisorInfo?.userInfo?.person?.lastName}`

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  return (
    <ScreenContainerWithMenuHeader screenCategory="submissions">
      <StyledFlex>
        <SkeletonContainer isLoading={isLoading} Skeleton={Skeleton}>
          <Flex
            flexDirection={isSmallScreen ? 'column' : 'row'}
            flexWrap="wrap"
            w="100%"
            p={16}
            marginLeft={10}
            marginRight={10}
            style={{
              borderBottomWidth: 1,
              borderColor: '#d8d8d8'
            }}
          >
            <Header callForSubmission={cfs} advisorName={advisorName} />
          </Flex>
          <Container>
            <TableContainer height="100%">
              <TableComponent
                {...tableProps}
                emptyMessage={t('curation:submissions:noSubmissions')}
              />
            </TableContainer>
          </Container>
        </SkeletonContainer>
      </StyledFlex>
    </ScreenContainerWithMenuHeader>
  )
}

const CurationAdvisorDetail = withNavigationPermissions(
  CurationAdvisorDetailScreen,
  [InnovationAccessKey.CURATE]
)

export default CurationAdvisorDetail
