import { useSetRecoilState } from 'recoil'
import toastMessageAtom, { MessageStatus } from '../recoil/toastMessageAtom'

const DEFAULT_TIMEOUT = 3000

const useToast = () => {
  const _setToastMessage = useSetRecoilState(toastMessageAtom)

  const setToastMessage = (message, status, tmt = DEFAULT_TIMEOUT) => {
    _setToastMessage({
      message,
      status
    })
    setTimeout(
      () =>
        _setToastMessage({
          message: '',
          status: status
        }),
      tmt
    )
  }

  return {
    setToastMessage: (msg, tmt = DEFAULT_TIMEOUT) => {
      setToastMessage(msg, MessageStatus.Success, tmt)
    },
    setToastErrorMessage: (msg, tmt = DEFAULT_TIMEOUT) => {
      setToastMessage(msg, MessageStatus.Error, tmt)
    },
    setToastWarningMessage: (msg, tmt = DEFAULT_TIMEOUT) => {
      setToastMessage(msg, MessageStatus.Warning, tmt)
    }
  }
}

export default useToast
