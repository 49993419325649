import React from 'react'
import useTranslation from '../../hooks/useTranslation'
import {
  ScreenContainerWithMenuHeader,
  StyledFixedHeightViewContainer
} from '../../components/layout/ScreenContainer'
import useSubmissionsQuery from './hooks/useSubmissionsQuery'
import { TableContainer } from '../../components/StaticForm'
import SkeletonContainer from '../../components/skeletonLoadings/SkeletonContainer'
import TableSkeleton from '../HomeScreen/TableSkeleton'
import { SUBMISSIONS_TABLE_CONFIG } from './constants'
import { DynamicTable, useTable } from '../../components/Table'
import {
  Container,
  Title
} from '../CallForSubmission/components/SharedComponents'
import { filterByCFSSubmissionCloseAt } from '../../utils/submission'

const filterProducts = submissions =>
  submissions?.filter(s => !!s.callForSubmissionId) || submissions

const SubmissionsScreen = () => {
  const { t } = useTranslation()
  const { submissions, loading } = useSubmissionsQuery()

  const tableProps = useTable({
    config: SUBMISSIONS_TABLE_CONFIG,
    data: submissions
      ? filterByCFSSubmissionCloseAt([...filterProducts(submissions)])
      : filterProducts(submissions)
  })

  return (
    <ScreenContainerWithMenuHeader screenCategory="submission">
      <StyledFixedHeightViewContainer testID={'mySubContainer'}>
        <Title text={t('submissions:title')} />
        <SkeletonContainer isLoading={loading} Skeleton={TableSkeleton}>
          <Container>
            <TableContainer height="100%">
              <DynamicTable {...tableProps} />
            </TableContainer>
          </Container>
        </SkeletonContainer>
      </StyledFixedHeightViewContainer>
    </ScreenContainerWithMenuHeader>
  )
}

export default SubmissionsScreen
