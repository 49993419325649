import React from 'react'
import { View } from 'react-native'

const Dependencies = () => {
  return (
    <View
      style={{
        marginTop: 5,
        width: '100%',
        height: 400,
        flexDirection: 'row'
      }}
    />
  )
}

export default Dependencies
