import React from 'react'
import Svg, { G, Circle } from 'react-native-svg'
import theme from '../../../../constants/Theme'

interface TimelineIconProps {
  iconColor?: string
}

const Icon = (props: TimelineIconProps) => {
  const { iconColor = theme.colors.tints.grays.g300 } = props
  const radius = 30

  return (
    <Svg viewBox="0 0 180 180">
      <G rotation={-90} originX="90" originY="90">
        <Circle
          cx="50%"
          cy="50%"
          r={radius}
          stroke={iconColor}
          fill="transparent"
          strokeWidth="15"
          strokeDashoffset={100}
        />
      </G>
    </Svg>
  )
}

export default Icon
