import { View, KeyboardAvoidingView, ScrollView } from 'react-native'
import styled from 'styled-components/native'

import { Text } from '../../../components/common/Text'

export const Container = styled(KeyboardAvoidingView)`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: ${({ minHeight }) => minHeight || ''}px;
`

export const ErrorContainer = styled(View)`
  padding: 0 10px 0px 10px;
  margin-bottom: 15px;
  min-height: 17px;
  max-width: 253px;
  min-width: 100%;
`
/**
 * This is primarily used for the login/authentication screens
 */
export const AuthenticationErrorContainer = styled(ErrorContainer)`
  margin-bottom: 0;
  align-items: center;
  ${({ theme: { space } }) => `
    padding-vertical: ${space[8]}px;
  `}
`
export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.danger};
`

export const SuccessText = styled(Text)`
  color: ${({ theme }) => theme.colors.success};
`

/**
 * This is primarily used for state machine screen that
 * overlay the main home screen after the user logs in.
 */
export const OverlayContainer = styled(View)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
`

export const Count = styled(Text)`
  position: relative;
  height: 0;
  bottom: 60%;
  text-align: right;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.inputBorderError};
`

export const BaseContainer = styled(KeyboardAvoidingView)`
  width: 100%;
  flex: 1;
  min-width: 375px;
`
export const StyledScrollView = styled(ScrollView)`
  width: 100%;
  height: 100%;
  padding-top: ${({ paddingTop }) => paddingTop || '40px'};
  flex: 1;
`
