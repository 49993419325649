import React, { FC, useMemo } from 'react'
import styled from 'styled-components/native'
// Hooks
import useTranslation from '../../../../hooks/useTranslation'
// Components
import {
  SelectAllElementsLinkContainer,
  SelectAllElementsText
} from '../../../DynamicTable/components/Table/SharedStyledComponents'

// Styled component
const Container = styled.TouchableOpacity`
  height: auto;
  width: 100%;
  flex-direction: column;
  background-color: transparent;
  ${({ theme: { space } }) => `margin-bottom: ${space[3]}px`};
`

interface Props {
  leftTable: boolean
  selectAll: boolean
  selectCurrent: boolean
  enableSelectAllElements: boolean
  totalElements: number
  currentSelection: number
  handleSelectAll?: (selectAllValue: boolean) => void
}

const DynamicTableSelectAllElements: FC<Props> = ({
  leftTable = false,
  selectAll = false,
  selectCurrent = false,
  totalElements = 0,
  currentSelection = 0,
  handleSelectAll
}) => {
  const { t } = useTranslation()
  const showSelectAllElementsText: boolean = useMemo(
    () =>
      Boolean(
        (selectCurrent || selectAll) &&
          totalElements &&
          totalElements > 0 &&
          leftTable
      ) ?? false,
    [selectCurrent, selectAll, totalElements, leftTable]
  )

  return (
    <Container
      onPress={() => {
        handleSelectAll?.(!selectAll)
      }}
    >
      {showSelectAllElementsText ? (
        <>
          <SelectAllElementsLinkContainer>
            <SelectAllElementsText>
              {t(
                selectAll
                  ? 'common:table:selection:allSelection'
                  : 'common:table:selection:currentSelection',
                {
                  totalElements,
                  currentSelection
                }
              )}
            </SelectAllElementsText>
          </SelectAllElementsLinkContainer>
          <SelectAllElementsLinkContainer>
            <SelectAllElementsText isHighlighted>
              {t(
                selectAll
                  ? 'common:table:selectAll:label5'
                  : 'common:table:selection:askSelectAll',
                {
                  totalElements
                }
              )}
            </SelectAllElementsText>
          </SelectAllElementsLinkContainer>
        </>
      ) : null}
    </Container>
  )
}

export default DynamicTableSelectAllElements
