import { SetterOrUpdater, useRecoilState } from 'recoil'
import currentDownloadStatusAtom, {
  DownloadProps
} from '../recoil/currentDownloadStatusAtom'
import { pick } from 'ramda'

const useDownloadStatus = (id: string) => {
  const [downloadStatus, setCurrentDownloadStatus] = useRecoilState(
    currentDownloadStatusAtom(id)
  ) as [DownloadProps, SetterOrUpdater<DownloadProps>]

  const setAttempts = value => {
    setCurrentDownloadStatus({ ...downloadStatus, attempts: value })
  }

  return {
    ...pick(
      [
        'attempts',
        'isVisible',
        'isLoading',
        'downloadLink',
        'handleSubscription',
        'handleGetLink',
        'getLinkDataKey',
        'getLinkValuesKey',
        'fileName'
      ],
      downloadStatus || {}
    ),
    setAttempts,
    setCurrentDownloadStatus
  }
}

export default useDownloadStatus
