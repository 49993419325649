import { useMemo, useEffect } from 'react'
import { useRecoilValue, useRecoilCallback } from 'recoil'
import { filter } from 'ramda'

import {
  getQuestionDependencyManagers,
  QuestionDependencyManagerState,
  setQuestionDependencyManagerState
} from '../recoil/questionDependencyAtomFamily'

const useQuestionDependency = (
  questionDependencyIds: string[],
  questionId: string,
  answer: any
) => {
  const questionDependencyManagers = useRecoilValue(
    getQuestionDependencyManagers(questionDependencyIds)
  )

  const setManager = useRecoilCallback(setQuestionDependencyManagerState)
  /**
   * isParent -> This question has children
   * isChild -> This question has parents
   */
  const [isParent, isChild, parents, children] = useMemo(() => {
    /**
     * Parents of this question are always represented by
     * the current questionId === question2Id
     */
    const parents = filter(
      qdm => qdm?.question2Id === questionId,
      questionDependencyManagers
    )

    /**
     * Children of this question are always represented by
     * the current questionId === question2Id
     */
    const children = filter(
      qdm => qdm?.question1Id === questionId,
      questionDependencyManagers
    )

    return [
      !!children.length,
      !!parents.length,
      parents as QuestionDependencyManagerState[],
      children as QuestionDependencyManagerState[]
    ]
  }, [questionDependencyManagers, questionId])

  useEffect(() => {
    children.forEach(child => {
      const requiredAnswer = child?.value?.entityAnswer === answer
      const entityAnswerNotSelected =
        child?.value?.entityAnswerNotSelected ?? false
      if (
        (entityAnswerNotSelected && !requiredAnswer && answer) ||
        (!entityAnswerNotSelected && requiredAnswer)
      ) {
        setManager({ ...child, visible: true })
      } else {
        if (child.visible) {
          setManager({ ...child, visible: false })
        }
      }
    })
  }, [answer])

  const isVisible = useMemo(() => {
    /**
     * If question is a parent and not a child
     * it is alway visible
     */
    if (isParent && !isChild) {
      return true
    }

    /**
     * If question is a child or a parent and a child
     * check the parents state. Check all
     * parents for visibilty. All parents must have
     * visible === true to show child
     */
    return parents.reduce((_, current) => current.visible, true)
  }, [isChild, isParent, children, parents])

  return {
    visible: isVisible
  }
}

export default useQuestionDependency
