import gql from 'graphql-tag'
// Hooks
import { useLazyQuery } from './useQuery'
import useTranslation from './useTranslation'

export const innovatorProductBySubmissionIdQuery = gql`
  query getInnovationSubmissionsByIds($submissionIds: [String]) {
    getInnovationSubmissionsByIds(submissionIds: $submissionIds) {
      id
      pdfCloudinaryId
      __typename
    }
  }
`

interface InnovatorProductProps {
  getSubmissionByIds: (options: any) => any
}

const useGetInnovationSubmissionsByIds = (): InnovatorProductProps => {
  const { t } = useTranslation()

  const [getSubmissionByIds] = useLazyQuery(
    innovatorProductBySubmissionIdQuery,
    { errorMessage: t('innovatorDirectory:details:queryError') }
  )

  return {
    getSubmissionByIds
  }
}

export default useGetInnovationSubmissionsByIds
