import React, { useState, useCallback, FC } from 'react'

import { Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

// Hooks
import useToast from '../../../../../hooks/useToast'
import useTranslation from '../../../../../hooks/useTranslation'
import useIsSmallScreen from '../../../../../hooks/useIsSmallScreen'
import useUpsertSubmissionReviewForTesting from '../hooks/useUpsertSubmissionReviewForTesting'
// Components
import Button from '../../../../../ui-library/Button'
import { Flex } from '../../../../../components/FlexBox'
import Modal from '../../../../../components/common/Modal'
import Dropdown from '../../../../../components/common/Dropdown'
import { StyledLabel } from '../../../../../ui-library/TextInput/Label'
import TouchableIcon from '../../../../../components/icon/TouchableIcon'
import { Title } from '../../../../InnovatorDirectory/components/SharedComponents'
import {
  AdvisorStatusFilterValueEnum,
  advisorStatusFilterOptions
} from '../../../../../components/DynamicTable/components/SearchBar/constants'

interface AdvisingDialogProps {
  openDialog: boolean
  triggerClose: () => void
  setIsSubmitting: (value: boolean) => void
  setSelectedElements: (value: any) => void
  selectedElements: string[]
  advisorId: string
}

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`
const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[3]}px;
  `}
`

const containerStyle = {
  flexBasis: '48%'
}
const buttonStyle = {
  width: '100%'
}

const AdvisingDialog: FC<AdvisingDialogProps> = ({
  advisorId,
  openDialog,
  selectedElements,
  triggerClose,
  setIsSubmitting,
  setSelectedElements
}) => {
  const { setToastErrorMessage, setToastMessage } = useToast()
  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()
  const titleStyle = isSmallScreen ? { fontSize: 15 } : {}
  const { t } = useTranslation()
  const [
    updateInnovationSubmissionStatus
  ] = useUpsertSubmissionReviewForTesting({
    onError: e => {
      console.error(e)
      setIsLoading(false)
      setToastErrorMessage(t('settings:testing:errorUpdatingSubmissions'))
    },
    onCompleted: () => {
      setIsSubmitting(false)
      setDropdownValue(null)
      setSelectedElements([])
      onClose()
      setToastMessage(t('settings:testing:allSubmissionsUpdated'))
    }
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [
    dropdownValue,
    setDropdownValue
  ] = useState<AdvisorStatusFilterValueEnum | null>(null)

  const onClose = useCallback(() => {
    triggerClose()
    setIsLoading(false)
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true)
    await updateInnovationSubmissionStatus(
      selectedElements,
      advisorId,
      dropdownValue === AdvisorStatusFilterValueEnum.advised
    )
  }

  return (
    <Modal
      close={onClose}
      open={openDialog}
      styles={{
        minWidth: '350px',
        maxWidth: '450px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={onClose} />

      <Flex padding={space[3]}>
        <Title>{t('settings:testing:updateSubmissions')}</Title>
        <View
          style={{
            marginTop: space[3]
          }}
        >
          {selectedElements?.length ? (
            <Description>
              {t('settings:testing:updateSubmissionsDescription', {
                count: selectedElements.length
              })}
            </Description>
          ) : null}
          <View
            style={{
              marginVertical: space[3]
            }}
          >
            <StyledLabel>{t('curation:advisors:table:cfsStatus')}</StyledLabel>
            <Dropdown
              name="advisingSubmissionStatusDropdown"
              value={dropdownValue as any}
              options={advisorStatusFilterOptions(t)}
              onSelect={toStatus => setDropdownValue(toStatus)}
              menuPortalTarget={document?.body}
            />
          </View>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              marginTop: space[3]
            }}
          >
            <Button
              title={t('callsForSubmission:dialog:cancelBtn')}
              type="outline"
              onPress={onClose}
              containerStyle={containerStyle}
              buttonStyle={buttonStyle}
              titleStyle={titleStyle}
            />
            <Button
              title={t('common:buttons:confirm')}
              type="solid"
              onPress={handleSubmit}
              containerStyle={containerStyle}
              buttonStyle={buttonStyle}
              titleStyle={titleStyle}
              disabled={isLoading}
              isProcessing={isLoading}
            />
          </Flex>
        </View>
      </Flex>
    </Modal>
  )
}

export default AdvisingDialog
