import { useEffect, useMemo, useRef } from 'react'

import { useRoute } from '@react-navigation/native'
// Hooks
import useAppCoordinator from '../../../../AppCoordinator/useAppCoordinator'
// Types
import { ALLOWED_LOGIN_ROUTES } from '../../../../types/form'
// Constants
const DEFAULT_QUERY_PARAM = 'loginRoute'

export const isRouteSearchParam = (
  loginRoute: ALLOWED_LOGIN_ROUTES
): boolean => {
  const searchParams = new URLSearchParams(document.location.search)
  const searchParam = searchParams.get(DEFAULT_QUERY_PARAM)
  return Boolean(searchParam && searchParam === loginRoute)
}

const useRedirectLoginByQueryParams = () => {
  const route = useRoute<any>()
  const attempts = useRef<number>(0)
  const { send, state }: any = useAppCoordinator()

  const searchParam = useMemo(() => route?.params?.[DEFAULT_QUERY_PARAM], [
    route?.params?.[DEFAULT_QUERY_PARAM]
  ])

  useEffect(() => {
    if (
      searchParam &&
      attempts.current === 0 &&
      state?.value?.loginCheck === 'login' &&
      Object.values(ALLOWED_LOGIN_ROUTES).includes(searchParam)
    ) {
      send(searchParam)
      ++attempts.current
    }
  }, [searchParam, state.value.loginCheck])
}

export default useRedirectLoginByQueryParams
