import React from 'react'
import styled from 'styled-components/native'
// Components/Types
import { BorderCard } from './components'
import Layout from '../../../../constants/Layout'
import { Col } from '../../../../components/skeletonLoadings/components'
import Skeleton from '../../../../components/skeletonLoadings/Skeleton'
const { window } = Layout
// Styled Components
const Container = styled(Col)`
  width: 100%;
  height: auto;
  ${({ theme: { space } }) => `
    padding-top: ${space[1]}px;
  `}
`
const OuterBorderCard = styled(BorderCard)`
  justify-content: space-evenly;
  ${({ theme: { space }, height }) => `
    padding-top: ${space[4]}px;
    margin-bottom: ${space[4]}px;
    padding-horizontal: ${space[4] + space[3]}px;
    height: ${height || window.height * 0.7}px;
  `}
`
const InnerBorderCard = styled(BorderCard).attrs(
  ({ theme: { sizes, space } }) => ({
    badgeLeftPosition: 0,
    badgeWidth: '25%',
    badgeHeight: sizes[3],
    badgeTopPosition: -(space[3] + space[2] + space[1])
  })
)(({ height }) => ({ height: height || '15%' }))

const InnerSkeleton = styled(Skeleton as any)`
  width: 100%;
  height: 50%;
  margin-bottom: auto;
  ${({ theme: { radii } }) => `
    border-radius: ${radii[3] * radii[1]}px;
  `}
`

const FormSkeleton = React.memo(() => (
  <Container>
    <OuterBorderCard>
      <InnerBorderCard height="12%" />
      <InnerBorderCard />
      <InnerBorderCard />
      <InnerBorderCard />
      <InnerBorderCard />
    </OuterBorderCard>
    <OuterBorderCard height={window.height * 0.25}>
      <InnerSkeleton />
    </OuterBorderCard>
  </Container>
))

export default FormSkeleton
