import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { View, Text } from 'react-native'
import AnimatedComponent from '../../../../../components/common/AnimatedComponent'

const Chart = ({ values, formatLabel }) => {
  const { colors } = useTheme()
  const dataValues = values.map(value => value.value)
  const dataLabels = values.map(value => value.title)
  const chartHeight = 220
  const maxWith = 40
  const maxValue = Math.max(...dataValues)

  const legendWidth = 130

  const [isHovered, setIsHovered] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [legendValue, setLegendValue] = useState(0)
  const [legendValue2, setLegend2Value] = useState(``)

  const getLabelText = (index: number, value: string) => {
    return `${index === 0 ? `<` : index === dataValues.length - 1 ? `>` : ''}${
      formatLabel ? formatLabel(value) : value
    }`
  }
  const handleMouseEnter = (index: number) => {
    let positionX: number = 0
    let positionY: number = 0
    let leftItems = dataValues.length - index
    if (leftItems <= 3) {
      positionX = (index - 3) * (100 / (dataValues.length ?? 1))
    } else {
      positionX = (index + 1) * (100 / (dataValues.length ?? 1))
    }
    setPosition({
      x: positionX,
      y: positionY
    })
    setLegendValue(dataValues[index])
    setLegend2Value(getLabelText(index, dataLabels[index]))
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setLegendValue(0)
    setLegend2Value(``)
    setIsHovered(false)
  }

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      {isHovered && (
        <View
          style={[
            {
              position: 'absolute',
              width: legendWidth,
              padding: 10,
              backgroundColor: 'lightgreen',
              borderRadius: 8,
              zIndex: 2
            },
            { top: position.y, left: `${position.x}%` }
          ]}
        >
          <Text style={{ fontWeight: 'bold' }}>{`${legendValue2}`}</Text>
          <Text>{`${legendValue} Submissions`}</Text>
        </View>
      )}

      <View
        style={{
          height: '90%',
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {dataValues.map((value, index) => (
            <View
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{
                width: `${100 / (dataValues.length ?? 1)}%`,
                maxWidth: `${maxWith}px`
              }}
            >
              <AnimatedComponent
                clickOpacity
                hoverColor={colors.tints.grays.g100}
                effects={[
                  {
                    initialValue: 0,
                    toValue: 1,
                    mouseEvent: 'Init',
                    styleProperty: propertyValue => ({
                      height: propertyValue
                    }),
                    timeIn: 800,
                    timeOut: 500,
                    interpolateValues: ['0%', '100%']
                  },
                  {
                    initialValue: chartHeight,
                    toValue: 0,
                    mouseEvent: 'Init',
                    styleProperty: propertyValue => ({
                      top: propertyValue
                    }),
                    timeIn: 800,
                    timeOut: 500
                  }
                ]}
                style={{ overflow: 'hidden' }}
              >
                <View
                  style={{
                    top: chartHeight - (value / maxValue) * chartHeight,
                    backgroundColor: 'blue',
                    height: (value / maxValue) * chartHeight
                  }}
                />
              </AnimatedComponent>
            </View>
          ))}
        </View>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        {dataLabels.map((value, index) => (
          <View
            style={{
              width: `${100 / (dataValues.length ?? 1)}%`,
              maxWidth: `${maxWith + 1}px`
            }}
          >
            <Text
              style={{
                fontSize: 10,
                textAlign: 'center'
              }}
              key={index}
            >
              {getLabelText(index, value)}
            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Chart
