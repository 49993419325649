import React from 'react'
import { FilterComponentProps } from '../../types'
import { textInputStyle } from '../../../../components/SearchBar/constants'
import { DropdownOption } from '../../../../../common/Dropdown'
import useFilterOptions from '../../../../hooks/useFilterOptions'
import AutoCompleteDropdown from '../../../../components/common/AutoCompleteDropdown'

const FilterHook = (props: FilterComponentProps) => {
  const { filter, handleChangeFilters, header, value } = props
  const {
    data = [] as DropdownOption[],
    loading = true as Boolean
  } = useFilterOptions(filter?.isHook && filter)

  return filter && !loading ? (
    <AutoCompleteDropdown
      options={data}
      placeholder={header}
      isMulti={filter.isMulti}
      value={value}
      onSelectValue={handleChangeFilters}
      styles={{ ...textInputStyle, ...(filter.styles ?? {}) }}
    />
  ) : null
}

export default FilterHook
