import gql from 'graphql-tag'

const CompanyProfileFragment = gql`
  fragment CompanyProfileFragment on CompanyProfile {
    id
    url
    companyInfo: company {
      name
      id
      createdAt
      products {
        id
        submissionId
        submission {
          id
          pdfCloudinaryId
          submissionInfo {
            name
          }
        }
      }
    }
    directoryInfo {
      teamAdminIds
      teamAdmins {
        id
        email
      }
      delegationIds
      delegations {
        id
        name
      }
      categories {
        id
        name
      }
      categoryIds
      totalCallsForSubmission
    }
  }
`

export default CompanyProfileFragment
