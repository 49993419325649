import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const CloseIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg
        width={size}
        height={size}
        x="0px"
        y="0px"
        viewBox={`0 0 128 128`}
        {...rest}
      >
        <Path
          d="M99.36 14.5L64 49.86 28.64 14.5a4 4 0 00-5.65 0L14.5 23a4 4 0 000 5.65L49.86 64 14.5 99.36a4 4 0 000 5.65l8.5 8.49a4 4 0 005.65 0L64 78.14l35.36 35.36a4 4 0 005.65 0l8.49-8.5a4 4 0 000-5.65L78.14 64l35.36-35.36a4 4 0 000-5.65L105 14.5a4 4 0 00-5.64 0z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default CloseIcon
