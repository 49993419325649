import { useState } from 'react'

import * as Yup from 'yup'
import { isEmpty } from 'ramda'
import { FormikProps, useFormik } from 'formik'
// Hooks
import useToast from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'
import useUpsertInnovationCategory from '../../../hooks/useUpsertInnovationCategory'
import useDeleteInnovationCategory from '../../../hooks/useDeleteInnovationCategory'
// Types
import {
  InnovationCategory,
  OriginSourceEnum,
  UpsertInnovationCategoryInput
} from '../../../types'
export interface CategoryMngModalProps {
  isOpen: boolean
  isLoading: boolean
  modalState: CategoryMngModalState
  onDeleteCategory: (catId?: string) => void
  onClose: (val?: any) => void
  formik: FormikProps<CategoryMngValues>
}
export enum CategoryMngModalState {
  save,
  update,
  closed
}
interface CategoryMngControllerResult {
  modalProps: CategoryMngModalProps
  onAddNewCategory: () => void
  onEditCategory: (category: InnovationCategory) => void
}
interface CategoryMngValues {
  id?: string | null
  name: string
  isActive: boolean
  submissionsCount: number
  marketSegmentIds?: string[]
}

// Yup validation
const getAddCategoryValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    name: Yup.string().required(),
    isActive: Yup.string().required(),
    marketSegmentIds: Yup.array().of(Yup.string())
  })

const defaultValues = {
  id: null,
  name: '',
  isActive: true,
  submissionsCount: 0,
  marketSegmentIds: []
}

const useCategoryMngController = (): CategoryMngControllerResult => {
  // Modal states
  const [modalState, setModalState] = useState<CategoryMngModalState>(
    CategoryMngModalState.closed
  )

  // Hooks
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()

  // Mutations
  const [
    upsertInnovationCategory,
    loadingUpsertInnovationCategory
  ] = useUpsertInnovationCategory({
    onError: () => {
      setToastErrorMessage(t('platformManagement:categories:errorSave'))
    },
    onCompleted: ({ upsertInnovationCategory }) => {
      if (upsertInnovationCategory && !isEmpty(upsertInnovationCategory)) {
        setToastMessage(t('platformManagement:categories:successSaved'))
        onClose()
      } else {
        setToastErrorMessage(t('platformManagement:categories:errorSave'))
      }
    },
    errorMessage: t('platformManagement:categories:errorSave')
  })
  const [
    deleteInnovationCategory,
    loadingDeleteInnovationCategory
  ] = useDeleteInnovationCategory({
    onError: error => {
      console.log(error)
      setToastErrorMessage(t('platformManagement:categories:errorDelete'))
    },
    onCompleted: ({ deleteInnovationCategory }) => {
      if (deleteInnovationCategory) {
        setToastMessage(t('platformManagement:categories:successDeleted'))
        onClose()
      } else {
        setToastErrorMessage(t('platformManagement:categories:errorDelete'))
      }
    },
    errorMessage: t('platformManagement:categories:errorDelete')
  })

  // Formik
  const formik = useFormik<CategoryMngValues>({
    initialValues: defaultValues,
    validationSchema: getAddCategoryValidationSchema(),
    onSubmit: async values => {
      const input: UpsertInnovationCategoryInput = {
        name: values?.name,
        isActive: values?.isActive,
        marketSegmentIds: values?.marketSegmentIds,
        originSource: OriginSourceEnum.Innovation
      }
      if (values?.id) input.id = values.id

      upsertInnovationCategory({
        variables: { input },
        refetchQueries: ['innovationCategories']
      })
    }
  })

  // Modal Events
  const onClose = () => {
    setModalState(CategoryMngModalState.closed)
    formik.resetForm({ values: defaultValues })
  }

  // Category events
  const onAddNewCategory = () => {
    formik.resetForm()
    setModalState(CategoryMngModalState.save)
  }
  const onEditCategory = category => {
    if (category?.name) {
      formik.resetForm({
        values: {
          id: category?.id,
          name: category.name,
          submissionsCount: category.submissionsCount,
          isActive: category.isActive,
          marketSegmentIds: category?.marketSegments?.length
            ? category.marketSegments?.map(ms => ms.id)
            : []
        }
      })
      setModalState(CategoryMngModalState.update)
    }
  }
  const onDeleteCategory = (catId?: string) => {
    deleteInnovationCategory({
      variables: { id: catId },
      refetchQueries: ['innovationCategories']
    })
  }

  const mutationLoading =
    formik.isSubmitting ||
    loadingUpsertInnovationCategory ||
    loadingDeleteInnovationCategory

  const isOpen = modalState !== CategoryMngModalState.closed

  return {
    onEditCategory,
    onAddNewCategory,
    modalProps: {
      isOpen,
      formik,
      modalState,
      isLoading: mutationLoading,
      onClose,
      onDeleteCategory
    }
  }
}

export default useCategoryMngController
