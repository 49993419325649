import React, { FC, useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled, { useTheme } from 'styled-components/native'
import { InnovationSubmission } from '../../../types'
import Button from '../../../ui-library/Button'
import Icon from '../../../components/icon'
import { Flex } from '../../../components/FlexBox'
import useTranslation from '../../../hooks/useTranslation'
import useCreateSubmissionPdfMutation from '../../Submission/hooks/useCreateSubmissionPdfMutation'
import idToCloudinaryUrl from '../../../utils/idToCloudinaryUrl'
import * as Linking from 'expo-linking'
import { Platform } from 'react-native'
import useSubmissionPdfSubscription from '../../../hooks/useSubmissionPdfSubscription'
import useInnovatorProductBySubmissionId from '../../../hooks/useInnovatorProductBySubmissionId'
import ProductDeletionModal from './ProductDeletionModal'
import generatingPDFAtom from '../../../recoil/generatingPDFAtom'

const ActionContainer = styled(Flex).attrs({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-end'
})`
  margin-bottom: ${props => props.theme.space[4]}px;
`

interface ActionButtonsProps {
  submission: InnovationSubmission
  isInnovatorProduct: boolean
  productId?: string | null
  onProductSelected?: (v) => void
  canDelete?: boolean
}

const ActionButtons: FC<ActionButtonsProps> = ({
  submission,
  isInnovatorProduct,
  canDelete,
  productId,
  onProductSelected
}) => {
  const { t } = useTranslation()
  const { colors, space } = useTheme()
  const [creatingPDF, setCreatingPDF] = useRecoilState(generatingPDFAtom)
  const { createSubmissionPDF, loading } = useCreateSubmissionPdfMutation()
  const [openDeleteProductDialog, setDeleteProductDialog] = useState(false)
  const {
    product,
    loading: loadingProduct
  } = useInnovatorProductBySubmissionId(
    isInnovatorProduct ? submission.id : null
  )

  const submissionPdfUrl = useMemo(() => {
    return submission?.pdfCloudinaryId
      ? idToCloudinaryUrl(submission?.pdfCloudinaryId, {
          resource_type: 'image'
        })
      : null
  }, [submission?.pdfCloudinaryId])

  const isPDFDownloadable = useMemo(() => {
    return isInnovatorProduct ? !!product?.hasLatestPDF : !!submissionPdfUrl
  }, [product, submissionPdfUrl])

  useEffect(() => {
    if (isPDFDownloadable) {
      setCreatingPDF(false)
    }
  }, [isPDFDownloadable])

  useSubmissionPdfSubscription({
    submissionId: submission.id,
    pdfCloudinaryId: submission?.pdfCloudinaryId
  })

  const handleCreateSubmissionPDF = async () => {
    const pdfResult = await createSubmissionPDF(submission.id)
    setCreatingPDF(true)

    if (pdfResult?.errors) {
      console.error(pdfResult.errors)
    }
  }

  const pdfButtonStyles = useMemo(
    () => ({
      standard: {
        buttonStyle: {
          borderColor: colors.primaryPalette.black,
          backgroundColor: colors.appBackground
        },
        titleStyle: {
          color: colors.primaryPalette.black,
          textTransform: 'capitalize'
        },
        iconStyle: {
          color: colors.primaryPalette.black
        }
      },
      hover: {
        iconStyle: {
          color: colors.primaryPalette.white
        },
        buttonStyle: {
          backgroundColor: colors.primaryPalette.black,
          borderColor: colors.primaryPalette.black
        },
        titleStyle: {
          color: colors.primaryPalette.white
        }
      }
    }),
    [colors]
  )

  const isGeneratingPDF = useMemo(
    () => (isPDFDownloadable ? false : loading || creatingPDF),
    [isPDFDownloadable, loading, creatingPDF]
  )

  if (loadingProduct) {
    return null
  }
  return (
    <ActionContainer style={{ width: 'fit-content' }}>
      <Flex flexDirection="column" alignItems="end">
        <Flex flexDirection="row">
          {canDelete ? (
            <Button
              icon={<Icon name="trash" width={14} height={14} />}
              title={t('innovatorDirectory:actions:deleteProduct')}
              type="outline"
              buttonStyle={{
                borderWidth: 1,
                marginRight: 10,
                borderColor: colors.dangerButton
              }}
              titleStyle={{
                textTransform: 'capitalize',
                color: colors.dangerButton
              }}
              iconStyle={{
                color: colors.dangerButton
              }}
              hoverStyles={{
                iconStyle: {
                  color: colors.primaryPalette.white
                },
                buttonStyle: {
                  backgroundColor: colors.dangerButton,
                  borderColor: colors.dangerButton
                },
                titleStyle: {
                  color: colors.primaryPalette.white
                }
              }}
              onPress={async () => {
                setDeleteProductDialog(true)
              }}
            />
          ) : null}
          <Button
            icon={
              <Icon
                name={isPDFDownloadable ? 'download' : 'arrow-path'}
                width={14}
                height={14}
                color="black"
              />
            }
            title={
              isPDFDownloadable
                ? t('innovatorDirectory:actions:pdf')
                : creatingPDF
                ? t('curation:submissionDetails:generatingPDF')
                : product
                ? t('curation:submissionDetails:regeneratePDF')
                : t('curation:submissionDetails:createPDF')
            }
            type="outline"
            buttonStyle={
              isGeneratingPDF
                ? { padding: space[1], ...pdfButtonStyles.hover.buttonStyle }
                : pdfButtonStyles.standard.buttonStyle
            }
            titleStyle={
              isGeneratingPDF
                ? pdfButtonStyles.hover.titleStyle
                : pdfButtonStyles.standard.titleStyle
            }
            iconStyle={
              isGeneratingPDF
                ? pdfButtonStyles.hover.iconStyle
                : pdfButtonStyles.standard.iconStyle
            }
            hoverStyles={pdfButtonStyles.hover}
            onPress={async () => {
              if (isPDFDownloadable && submissionPdfUrl) {
                if (Platform.OS === 'web') {
                  window.open(submissionPdfUrl, '_blank')
                } else {
                  await Linking.openURL(submissionPdfUrl)
                }
              } else {
                await handleCreateSubmissionPDF()
              }
            }}
            loading={isGeneratingPDF}
          />
        </Flex>
      </Flex>
      {!!productId && openDeleteProductDialog ? (
        <ProductDeletionModal
          productId={productId}
          title={t('directoryProfile:products:productDeletion:title')}
          isOpen={openDeleteProductDialog}
          onClose={() => {
            setDeleteProductDialog(false)
            onProductSelected && onProductSelected(null)
          }}
          onCancel={() => {
            setDeleteProductDialog(false)
          }}
        />
      ) : null}
    </ActionContainer>
  )
}

export default ActionButtons
