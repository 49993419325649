import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'

export const createInnovationSubmissionsBatchPdfMutation = gql`
  mutation createInnovationSubmissionsBatchPdf($ids: [String!]) {
    createInnovationSubmissionsBatchPdf(ids: $ids) {
      cloudinaryLink
      cloudinaryUrls
    }
  }
`

const useCreateSubmissionsBatchPdfMutation = () => {
  const [createSubmissionsBatchPDFMutation, { loading, error }] = useMutation(
    createInnovationSubmissionsBatchPdfMutation
  )

  const createSubmissionsBatchPDF = async submissionIds => {
    const { data } = await createSubmissionsBatchPDFMutation({
      variables: {
        ids: submissionIds
      }
    })

    return data
  }

  return {
    createSubmissionsBatchPDF,
    loading,
    error
  }
}

export default useCreateSubmissionsBatchPdfMutation
