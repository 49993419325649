import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'ramda'

import InputField from '../../../InputField'
import { FormikProvider, useFormik } from 'formik'
import {
  FormBlockQuestionGroupModalFormProps,
  FormField,
  QuestionGroupEntityMapValues
} from '../../../../types'
import useToast from '../../../../../../../hooks/useToast'
import useTranslation from '../../../../../../../hooks/useTranslation'
import { CancelButton, Footer, SaveButton } from '../../../../../constants'
import { EntityTypeEnum } from '../../../../../../../components/DynamicForm/types'
import { JSONValidation } from '../../../../../utils'
import SwitchField from '../../../SwitchField'
import useUpsertQuestionGroupEntityMapAndEntity from '../../../Forms/hooks/useUpsertQuestionGroupEntityMap'

const questionFields: FormField[] = [
  {
    field: 'dynamic',
    Component: SwitchField,
    isParent: false
  },
  {
    field: 'style',
    Component: InputField,
    isJSON: true,
    isParent: false
  },
  {
    field: 'configData',
    Component: InputField,
    isJSON: true,
    isParent: false
  }
]

// Yup validation
const getAddQuestionGroupValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    dynamic: Yup.boolean().default(false),
    style: JSONValidation,
    configData: JSONValidation
  })

const Form = (props: FormBlockQuestionGroupModalFormProps) => {
  const { question, questions, onClose, isEdit, questionGroupId } = props
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()

  // Mutations
  const [
    upsertQuestionGroupEntityMap,
    isLoading
  ] = useUpsertQuestionGroupEntityMapAndEntity({
    onError: e => console.error(e),
    onCompleted: ({ upsertQuestionGroupEntityMapAndEntity }) => {
      if (
        upsertQuestionGroupEntityMapAndEntity &&
        !isEmpty(upsertQuestionGroupEntityMapAndEntity)
      ) {
        setToastMessage(
          t(
            'platformManagement:forms:menuItems:formBlockQuestions:successSaved'
          )
        )
        onClose()
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:formBlockQuestions:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:formBlockQuestions:errorSave')
  })

  const isNew = useMemo(() => !question?.id, [question?.id])

  let initialValues = {
    id: isNew ? undefined : question?.id,
    sequence: isNew ? questions?.length ?? 0 : question?.sequence,
    entityId: isNew ? undefined : question?.entityId,
    entityType: EntityTypeEnum.QuestionGroup,
    style:
      !isNew && question?.entity?.style
        ? JSON.stringify(question?.entity?.style)
        : '{}',
    configData:
      !isNew && question?.entity?.configData
        ? JSON.stringify(question?.entity?.configData)
        : '{}',
    dynamic: question?.entity?.['dynamic'] ?? false
  }

  // Formik
  const form = useFormik<QuestionGroupEntityMapValues>({
    initialValues: { ...initialValues },
    validationSchema: getAddQuestionGroupValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      const input = {
        id: isNew ? undefined : question?.id,
        questionGroupId,
        entityType: EntityTypeEnum.QuestionGroup,
        sequence: parseInt(`${values.sequence}`),
        dynamic: values?.['dynamic'],
        configData: values?.['configData'],
        style: values?.['style'],
        parentId: values.entityId
      }

      upsertQuestionGroupEntityMap({
        variables: {
          ...input
        },
        refetchQueries: ['getQuestionGroupEntityMaps']
      })
      onClose()
    }
  })

  const validateAndSubmit = () => {
    form.validateForm().then(() => form.submitForm())
  }

  const enableSubmit = useMemo(() => {
    return form.isValid && form.dirty
  }, [form])

  return (
    <FormikProvider value={form}>
      {questionFields
        .filter(formField => (isNew ? !formField.hideOnNew : true))
        .map(formField => {
          const { Component, field, isParent } = formField
          const componentProps = {
            type: 'formBlockQuestions',
            key: field,
            fieldName: field,
            isReadOnly: (isEdit && isParent) || isLoading
          }

          return <Component {...componentProps} />
        })}
      <Footer>
        <CancelButton
          isProcessing={isLoading}
          onPress={onClose}
          title={t('common:buttons:cancel')}
        />
        <SaveButton
          disabled={!enableSubmit}
          onPress={validateAndSubmit}
          isProcessing={isLoading}
          title={t(`common:buttons:${isEdit ? 'update' : 'save'}`)}
        />
      </Footer>
    </FormikProvider>
  )
}

export default Form
