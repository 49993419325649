import { useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'
import { simulationAtom } from '../../recoil/simulationAtom'
import Simulator from '../../lib/Simulator'
import { CommonActions } from '@react-navigation/native'
import navigationRef from '../../navigation/navigationRef'

const RESET_LOADER = CommonActions.reset({
  index: 0,
  routes: [
    {
      name: 'SimulationLoading'
    }
  ]
})

const SimulatorController = () => {
  let [simulationState] = useRecoilState(simulationAtom)
  const prevSimId = useRef(simulationState.simulatedId)

  useEffect(() => {
    Simulator.id = simulationState.simulatedId ?? null
    if (simulationState.simulatedId === prevSimId.current) {
      return
    }
    prevSimId.current = simulationState.simulatedId

    if (navigationRef.current) {
      navigationRef.current.dispatch(RESET_LOADER)
    }
  }, [simulationState.simulatedId])

  return null
}

export default SimulatorController
