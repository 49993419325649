import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const deleteInnovationCategoryQuery = gql`
  mutation deleteInnovationCategory($id: String) {
    deleteInnovationCategory(id: $id)
  }
`

const useDeleteInnovationCategory = (options = {}) => {
  const [
    deleteInnovationCategory,
    { loading: loadingDeleteInnovationCategory }
  ]: any[] = useMutation(deleteInnovationCategoryQuery, options)

  return [deleteInnovationCategory, loadingDeleteInnovationCategory]
}

export default useDeleteInnovationCategory
