import React, { useCallback, useState } from 'react'
import { View, ViewStyle } from 'react-native'
// Hooks
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Dropdown, {
  DropdownValue
} from '../../../../../components/common/Dropdown'
import SubmissionsDialog from './SubmissionsDialog'
import Button from '../../../../../ui-library/Button'
import { Flex } from '../../../../../components/FlexBox'
import { useTable } from '../../../../../components/Table'
import { DynamicTable } from '../../../../../components/DynamicTable'
import { TableContainer } from '../../../../../components/StaticForm'
import { StyledLabel } from '../../../../../ui-library/TextInput/Label'
import { getSubmissionTableConfig } from '../../../../Curation/constants'
import {
  Container,
  SettingsContainer
} from '../../../components/SharedComponents'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
// Types
import {
  CallForSubmission,
  InnovationSubmissionStatusEnum,
  RoleEnum
} from '../../../../../types'
import { EngineEnum } from '../../../constants'
import { Option } from '../../../../../components/DynamicForm/DropDown'

interface SubmissionsTestSettings {
  callForSubmission: CallForSubmission
}

const SubmissionsTestSettings = (props: SubmissionsTestSettings) => {
  const { t } = useTranslation()
  const { callForSubmission } = props
  const { roles } = useCurrentUser()
  const [selectedElements, setSelectedElements] = useState<string[]>([])
  const [openDialog, setOpenDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [statusFilteredBy, setStatusFilterBy] = useState<DropdownValue | null>(
    'true'
  )

  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)

  const statusOptions: Option[] = [
    {
      label: t('callsForSubmission:innovatorSubmissionStatus:incomplete'),
      value: 'false'
    },
    {
      label: t('callsForSubmission:innovatorSubmissionStatus:submitted'),
      value: 'true'
    }
  ]

  const onSelectedItemsKeysChange = (keys: string[]) => {
    setSelectedElements(keys)
  }

  const closeDialog = useCallback(() => {
    setIsSubmitting(false)
    setOpenDialog(false)
  }, [])

  const tableProps = useTable({
    config: getSubmissionTableConfig({
      cfsId: callForSubmission.id,
      role: roles.includes(RoleEnum.Admin)
        ? RoleEnum.InnovationStaff
        : RoleEnum.InnovatorTeamMember,
      currentCFSStep: callForSubmission.currentStep.name,
      engine: EngineEnum.InnovationSubmission,
      isInnovationNewDynamicTable,
      statusFilteredBy: (statusFilteredBy as string) ?? undefined,
      isTestingModule: true
    }) as any
  })

  const statusStyle: ViewStyle = {
    minHeight: 0,
    marginLeft: 32,
    marginBottom: 0
  }

  const controlStyle: ViewStyle = { minHeight: 0 }

  return (
    <SettingsContainer>
      <Container padding={`0`} style={{ paddingBottom: 30 }}>
        <Flex flexDirection="row" alignItems="center" marginBottom={16}>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
          <Dropdown
            name={'statusFilter'}
            value={statusFilteredBy}
            placeholder={t('curation:submissions:table:status')}
            options={statusOptions}
            onSelect={setStatusFilterBy}
            isFilter
            style={statusStyle}
            controlStyle={controlStyle}
            menuPortalTarget={document?.body}
          />
        </Flex>
        <TableContainer height="100%">
          <DynamicTable
            {...(tableProps as any)}
            onSelectedItemsKeysChange={onSelectedItemsKeysChange}
            emptyMessage={t('innovatorDirectory:table:noInnovators')}
          />
        </TableContainer>
      </Container>
      <View style={{ height: '5%' }}>
        <Button
          title={t('settings:testing:updateSubmissions')}
          onPress={() => setOpenDialog(!openDialog)}
          disabled={
            !selectedElements || selectedElements.length < 1 || isSubmitting
          }
          isProcessing={isSubmitting}
        />
      </View>
      <SubmissionsDialog
        openDialog={openDialog}
        triggerClose={closeDialog}
        selectedElements={selectedElements}
        currentFilter={
          statusFilteredBy === 'true'
            ? InnovationSubmissionStatusEnum.submitted
            : InnovationSubmissionStatusEnum.inProgress
        }
      />
    </SettingsContainer>
  )
}

export default SubmissionsTestSettings
