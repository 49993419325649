import React from 'react'

// Components
import Dialog from '../../common/Dialog'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
// Types
import { ButtonConfig } from '../../DynamicForm/layout'

interface UnsavedChangesDialog {
  title?: string
  description?: string
  showDialog?: boolean
  setShowDialog?: (bool: boolean) => void
  discardChangesCallback?: () => void
  saveChangesCallback?: () => void
  actions?: ButtonConfig[]
}

export const UnsavedChangesDialog = ({
  title,
  description,
  showDialog = false,
  setShowDialog,
  discardChangesCallback = () => {},
  saveChangesCallback = () => {},
  actions
}: UnsavedChangesDialog) => {
  const { t } = useTranslation()

  return (
    <Dialog
      title={title || t('common:dialogs:unsavedChanges:title')}
      description={
        description || t('common:dialogs:unsavedChanges:description')
      }
      isOpen={showDialog}
      onClose={() => {
        setShowDialog?.(false)
      }}
      actions={
        actions?.length
          ? actions
          : [
              {
                title: t('common:dialogs:unsavedChanges:discardBtn'),
                type: 'outline',
                onPress: discardChangesCallback
              },
              {
                title: t('common:dialogs:unsavedChanges:saveBtn'),
                onPress: saveChangesCallback
              }
            ]
      }
    />
  )
}
