import gql from 'graphql-tag'

const callForSubmissionCreatedSubscription = gql`
  subscription callForSubmissionCreated(
    $statuses: [CallForSubmissionStatusEnum]
    $step: CallForSubmissionStepEnum
  ) {
    callForSubmissionCreated(statuses: $statuses, step: $step) {
      id
      name
      status
      academyElegible
      stepDateRanges {
        curationStep
        closeAt
        openAt
      }
      delegations {
        id
        name
      }
      marketSegments {
        id
        name
      }
      innovationSubmissions {
        id
      }
    }
  }
`

export default callForSubmissionCreatedSubscription
