import gql from 'graphql-tag'
import InnovatorProductFragment from './InnovatorProductFragment'

const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    canUpdateCompanyLogo
    companyProfile {
      id
      url
      logo {
        id
        cloudinaryId
      }
      logoCropping
      yearFounded
      funding
      employeeCount
      delegationInterest {
        id
        name
        description
      }
      domains {
        name
        id
      }
      country {
        id
        name
      }
      directoryInfo {
        teamAdminIds
        teamAdmins {
          id
          email
        }
        delegationIds
        delegations {
          id
          name
        }
        categories {
          id
          name
        }
        categoryIds
        totalCallsForSubmission
      }
    }
    innovatorMembers {
      user {
        roles
      }
      emailAddresses {
        email
      }
    }
    products {
      ...InnovatorProductFragment
      canEdit
      canDelete
      canPublish
    }
  }
  ${InnovatorProductFragment}
`

export default CompanyFragment
