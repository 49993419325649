import React from 'react'
import { useTheme } from 'styled-components/native'
import TouchableIcon from '../../icon/TouchableIcon'

import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'

const DeleteIcon = ({ item, handlers, category }) => {
  const { colors } = useTheme()
  const { trackWithProperties } = useMixpanel()

  return (
    <TouchableIcon
      name="delete"
      onPress={() => {
        handlers?.remove({ id: item.id })
        trackWithProperties(EVENT_MAP.click.button, {
          category,
          action: 'delete'
        })
      }}
      color={colors.lightGrey}
      style={{ margin: '1px' }}
    />
  )
}

export default DeleteIcon
