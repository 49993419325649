import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AppSearchTableProps } from '../types'
import FlatListComponents from './Components'
import useSearchQuery from '../../../hooks/useSearchQuery'
import { useIsFocused } from '@react-navigation/native'
import Button from '../../../../../ui-library/Button'
import theme from '../../../../../constants/Theme'
import useTranslation from '../../../../../hooks/useTranslation'
import { useRecoilCallback } from 'recoil'
import {
  OutsideFetchMoreResultsProps,
  setFetchMoreResultsByName
} from '../../../../../recoil/fetchMoreResultsAtom'
import { addDataToSearchQueryByName } from '../../../../../recoil/searchQueryAtom'

const AppSearchTable = (props: AppSearchTableProps) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)
  const setFetchMoreResults = useRecoilCallback(setFetchMoreResultsByName)
  const addDataToQueryByName = useRecoilCallback(addDataToSearchQueryByName)

  const {
    keyExtractor,
    leftTableWidth,
    name,
    pageSize,
    rigthTableWidth,
    queryFilters,
    searchFilters,
    sortBy,
    sortDir,
    engine,
    queryConditions,
    queryDynamicName,
    hitKey,
    EmptyComponent,
    emptyMessage,
    loading: configLoading,
    customQueryFields,
    handleSetData,
    handleTotalElements,
    skipCache,
    skipFetchData = false,
    fetchingDataOnFocus = true,
    fetchPolicy,
    isSticky,
    headerRef
  } = props

  const { data, loading, fetchMore } = useSearchQuery({
    customQueryFields,
    enableFilters: true,
    engine,
    pageSize,
    queryFilters,
    searchFilters,
    sortBy,
    sortDir,
    isLoading: configLoading,
    isFocused,
    queryConditions,
    queryDynamicName,
    skipCache,
    skipFetchData,
    fetchingDataOnFocus,
    fetchPolicy
  })

  const items = useMemo(
    () =>
      data
        ? hitKey
          ? data?.searchInnovationEngine?.hits?.map(hit => hit[hitKey])
          : data?.searchInnovationEngine?.hits
        : [],
    [data, hitKey]
  )

  useEffect(() => {
    if (items?.length) {
      handleSetData(items)
    }
  }, [items])

  const totalElements = useMemo(
    () => (data ? data?.searchInnovationEngine?.total : 0),
    [data]
  )

  useEffect(() => {
    if (totalElements || totalElements === 0) {
      handleTotalElements(totalElements)
    }
  }, [totalElements])

  const showFetchMoreElementsButton = !loading && totalElements > items.length

  const updateQuery = (prev, { fetchMoreResult }) => {
    const newHits = fetchMoreResult?.searchInnovationEngine?.hits || []
    setLoadingFetchMore(false)
    if (newHits.length === 0) return prev
    let newResults = {
      ...prev,
      searchInnovationEngine: {
        ...(prev?.searchInnovationEngine || {}),
        hits: [...(prev?.searchInnovationEngine?.hits || []), ...newHits]
      }
    }
    return newResults
  }

  const fetchMoreResults = useCallback(() => {
    if (
      !items?.length ||
      loading ||
      !isFocused ||
      loadingFetchMore ||
      totalElements <= items.length
    )
      return

    setLoadingFetchMore(true)

    const offset = Math.ceil(items?.length / pageSize) + 1

    fetchMore &&
      fetchMore({
        variables: {
          offset,
          size: pageSize,
          sort: [{ sortBy, sortDir }],
          engine
        },
        updateQuery: updateQuery
      })
  }, [
    items,
    loading,
    isFocused,
    loadingFetchMore,
    pageSize,
    sortBy,
    sortBy,
    totalElements
  ])

  const outsideFetchMoreResults = useCallback(
    async (props: OutsideFetchMoreResultsProps) => {
      if ((loading || loadingFetchMore) && queryDynamicName) return

      const { variables } = props

      setLoadingFetchMore(true)

      if (fetchMore) {
        const result = await fetchMore({ variables, updateQuery: updateQuery })
        if (!result.error && queryDynamicName) {
          addDataToQueryByName(queryDynamicName, result.data ?? {})
        }
      }
    },
    [loading, loadingFetchMore]
  )

  if (setFetchMoreResults && queryDynamicName) {
    setFetchMoreResults(queryDynamicName, {
      fetchMoreResults: outsideFetchMoreResults
    })
  }

  return (
    <>
      <FlatListComponents
        leftTableWidth={leftTableWidth}
        rigthTableWidth={rigthTableWidth}
        name={name}
        data={items}
        keyExtractor={keyExtractor}
        pageSize={pageSize}
        EmptyComponent={EmptyComponent}
        emptyMessage={emptyMessage}
        loading={loading || loadingFetchMore}
        isSticky={isSticky}
        headerRef={headerRef}
      />
      {showFetchMoreElementsButton && !loadingFetchMore ? (
        <Button
          style={{
            alignSelf: 'center'
          }}
          titleStyle={{
            textTransform: 'none',
            fontStyle: 'italic',
            color: theme.colors.primary
          }}
          buttonStyle={{
            borderColor: theme.colors.primary,
            backgroundColor: 'transparent'
          }}
          title={t('common:table:loadMoreItems')}
          isProcessing={loading}
          onPress={fetchMoreResults}
        />
      ) : null}
    </>
  )
}

export default AppSearchTable
