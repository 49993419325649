import React from 'react'
import { shouldMemoizeComponent } from '../../../constants'
import RowWrapper from './RowWrapper'
import { ItemProps } from '../types'

const Item = React.memo((props: ItemProps): any => {
  const { item, index, leftTable } = props

  return <RowWrapper item={item} index={index} leftTable={leftTable} />
}, shouldMemoizeComponent)
// Added custom memoize function to check function and object props
// e.g fetchMoreResults: Function

export default Item
