import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'

export const updateInnovationSubmissionStatusMutation = gql`
  mutation updateInnovationSubmissionStatus(
    $id: String!
    $status: InnovationSubmissionStatusEnum!
    $returnToSubmission: Boolean
    $deleteSubmission: Boolean
    $isTestingSetting: Boolean
  ) {
    updateInnovationSubmissionStatus(
      id: $id
      status: $status
      returnToSubmission: $returnToSubmission
      deleteSubmission: $deleteSubmission
      isTestingSetting: $isTestingSetting
    ) {
      id
      status
      step
      hasBeenFastPassed
      hasBeenSubmitted
    }
  }
`

const useUpdateSubmissionStatusMutation = (options = {}) => {
  const [updateInnovationSubmissionStatus, { loading }]: any[] = useMutation(
    updateInnovationSubmissionStatusMutation
  )

  const updateSubmissionStatus = async (
    submissionId,
    status,
    returnToSubmission?,
    deleteSubmission?,
    isTestingSetting?
  ) => {
    const results = await updateInnovationSubmissionStatus({
      variables: {
        id: submissionId,
        status,
        returnToSubmission,
        deleteSubmission,
        isTestingSetting
      },
      ...options
    })

    return results
  }

  return [updateSubmissionStatus, loading]
}

export default useUpdateSubmissionStatusMutation
