import React, { useEffect } from 'react'
import LoadingScreen from './LoadingScreen'
import Firebase from '../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import useVerifyRegistration from './queries/useVerifyRegistration'
import useTranslation from '../hooks/useTranslation'
import { ADVISORS_PERMISSIONS, STAFF_PERMISSIONS } from '../constants/roles'
import { intersection } from 'ramda'

const VerifyRegistrationCheck = ({ send, event }: any) => {
  const { t } = useTranslation()
  const { loading, error, data } = useVerifyRegistration({
    skip: !getFBAuth(Firebase).currentUser,
    fetchPolicy: 'no-cache',
    errorMessage: t('error:auth:failedVerifyRegistration')
  })
  const verified =
    data?.currentUser?.person?.innovationCompany?.companyProfile?.id
  const onboarded = data?.currentUser?.innovationOnboardedAt
  const isAdvisor = data?.currentUser?.roles
    ? !!intersection(data?.currentUser?.roles, [
        ...ADVISORS_PERMISSIONS,
        ...STAFF_PERMISSIONS
      ]).length
    : false

  useEffect(() => {
    const handleError = () => {
      if (error) {
        send('ERROR')
      }
    }
    if (!loading) {
      handleError()
    }
  }, [loading, error])

  useEffect(() => {
    const handleVerified = () => {
      if (verified || onboarded || isAdvisor) {
        send('VERIFIED', event)
      } else {
        send('UNVERIFIED', event)
      }
    }
    if (!loading) {
      handleVerified()
    }
  }, [loading, verified, onboarded, event, isAdvisor])

  if (loading) {
    return <LoadingScreen />
  }

  return null
}

export default VerifyRegistrationCheck
