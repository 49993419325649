import React, { useEffect, useMemo, useState } from 'react'
import useTranslation from '../../../../hooks/useTranslation'
import { DynamicTableSearchBarProps, SearchComponentProps } from './constants'
import { useTheme } from 'styled-components/native'
import {
  ButtonContainer,
  DynamicSearchBarContainer,
  HeaderButtonsContainer,
  SearchComponentContainer
} from './SharedStyledComponents'
import DebouncedTextInput from '../DebouncedTextInput'
import { Flex } from '../../../FlexBox'
import CsvDownloadButton from './CsvDownloadButton'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import Button from '../../../../ui-library/Button'
import useHasPermission from '../../../../hooks/useHasPermission'
import { CURATION_PERMISSIONS } from '../../../../constants/roles'
import useDownloadStatus from '../../../../hooks/useDownloadStatus'
import useDownloadFiles from '../../../../hooks/useDownloadFiles'
import { DownloadProps } from '../../../../recoil/currentDownloadStatusAtom'
import useCreateSubmissionsBatchPdfMutation from '../../../../screens/Submission/hooks/useCreateSubmissionsBatchPdfMutation'
import useCreateSubmissionsPdfMutation from '../../../../screens/Submission/hooks/useCreateSubmissionPdfMutation'
import { InnovationSubmission } from '../../../../types'
import useUpdatedInnovationSubmissionCloudinaryIdSubscription from '../../../../screens/Submission/hooks/useUpdatedInnovationSubmissionCloudinaryIdSubscription'

const SearchComponent = ({
  handleChangeSearch,
  initialValue,
  isAlternativeView
}: SearchComponentProps) => {
  const { t } = useTranslation()
  const { fontSizes, colors, sizes } = useTheme()

  const searchInputStyles = {
    marginTop: sizes[2],
    borderRadius: sizes[1],
    paddingLeft: sizes[2],
    marginLeft: isAlternativeView ? sizes[2] : sizes[3] + sizes[2],
    width: isAlternativeView ? '85%' : '70%',
    fontSize: fontSizes[3],
    backgroundColor: colors.background
  }

  return (
    <DebouncedTextInput
      initialValue={initialValue}
      placeholder={t('common:search')}
      onChangeText={value => handleChangeSearch({ value })}
      style={searchInputStyles}
    />
  )
}

function DynamicTableSearchBar<T>({
  enableFilters,
  handleChangeSearch,
  searchFilters,
  initialValue,
  data,
  config,
  error,
  selectedItems = {},
  isAlternativeView,
  fetchData,
  selectAll,
  selectCurrent
}: DynamicTableSearchBarProps<T>) {
  const { t } = useTranslation()
  const {
    createSubmissionsBatchPDF,
    loading: loadingCreateSubmissionsBatchPDF
  } = useCreateSubmissionsBatchPdfMutation()
  const {
    createSubmissionPDF,
    loading: loadingCreateSubmissionPDF
  } = useCreateSubmissionsPdfMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [isPDFDisabled, setIsPDFDisabled] = useState(true)
  const [isProductPDFDisabled, setIsProductPDFDisabled] = useState(true)
  const [isDisabled, setIsDisabled] = useState(true)
  const { currentUserId } = useCurrentUser()
  const { colors, sizes } = useTheme()
  const isCurator = useHasPermission(CURATION_PERMISSIONS)
  const {
    showSearchField = true,
    showCsvButton = true,
    showPDFButton = true,
    showProductPDFButton = false
  } = config
  const {
    setCurrentDownloadStatus,
    isLoading: isLoadingDownloadStatus
  } = useDownloadStatus(currentUserId ? (currentUserId as string) : '')

  const { setCurrentDownloadFiles } = useDownloadFiles(
    currentUserId ? (currentUserId as string) : ''
  )

  const selectedItemsKeys = useMemo(
    () => Object.keys(selectedItems).filter(item => selectedItems[item]),
    [selectedItems]
  )

  const handleCreateSubmissionPDF = async () => {
    setIsLoading(true)
    const selectedItemsKeys = Object.keys(selectedItems).filter(
      item => selectedItems[item]
    )
    setCurrentDownloadFiles({
      isLoadingFiles: true,
      isRefreshingFiles: false,
      files:
        data
          ?.filter(item => selectedItemsKeys.includes(item?.id))
          ?.map(item => ({
            id: item?.id,
            label: item?.submissionInfo?.name,
            cloudinaryId: item?.pdfCloudinaryId,
            isDownloadable: true,
            isLoading: !item?.pdfCloudinaryId,
            subscriptionKeys: {
              innovationSubmissionId: item?.id,
              pdfCloudinaryId: item?.pdfCloudinaryId
            }
          })) ?? []
    })

    const cfnName = data?.length ? data[0].callForSubmission?.name : ''

    setCurrentDownloadStatus({
      attempts: 0,
      isVisible: true,
      isLoading: true,
      downloadLink: undefined,
      handleSubscription: useUpdatedInnovationSubmissionCloudinaryIdSubscription,
      getLinkDataKey: 'createInnovationSubmissionsBatchPdf',
      getLinkValuesKey: 'pdfCloudinaryId',
      handleGetLink: createSubmissionsBatchPDF,
      fileName: `${cfnName}-innovationSubmissions`
    } as DownloadProps)

    for (let index = 0; index < selectedItemsKeys.length; index++) {
      const submissionId = selectedItemsKeys[index]
      const submissionIndex =
        data?.findIndex(item => item?.id === submissionId) ?? -1
      const submission =
        ((data && data[submissionIndex]) as InnovationSubmission) ?? undefined

      let pdfCreated = true
      if (!submission?.pdfCloudinaryId) {
        const { createInnovationSubmissionPdf } = await createSubmissionPDF(
          submissionId,
          false
        )
        pdfCreated = createInnovationSubmissionPdf
      }
      if (!pdfCreated) {
        console.error('Error creating PDF for Submission: ' + submission.id)
      }
    }
    setIsLoading(false)
  }

  const handleCreateProductPDF = async () => {
    setIsLoading(true)
    const selectedItemsKeys = Object.keys(selectedItems).filter(
      item => selectedItems[item]
    )

    const filesToDownload =
      data
        ?.filter(item => selectedItemsKeys.includes(item?.id))
        // ?.filter(item => item.companyInfo.products?.length > 0)
        ?.map(item => {
          if (item.companyInfo.products?.length > 0) {
            const productSubmission = item?.companyInfo.products[0]?.submission

            return {
              id: productSubmission?.id,
              label: item?.companyInfo.name,
              cloudinaryId: productSubmission?.pdfCloudinaryId,
              isLoading: !productSubmission?.pdfCloudinaryId,
              isDownloadable: true,
              subscriptionKeys: {
                innovationSubmissionId: productSubmission?.id,
                pdfCloudinaryId: productSubmission?.pdfCloudinaryId
              }
            }
          } else {
            // For innovators with no products created
            return {
              id: null,
              label: `${item?.companyInfo.name} - ${t(
                'common:download:noProducts'
              )}`,
              cloudinaryId: null,
              isLoading: false,
              isDownloadable: false,
              subscriptionKeys: {
                innovationSubmissionId: null,
                pdfCloudinaryId: null
              }
            }
          }
        }) ?? []

    setCurrentDownloadFiles({
      isLoadingFiles: true,
      files: filesToDownload,
      isRefreshingFiles: false
    })

    setCurrentDownloadStatus({
      isVisible: true,
      isLoading: true,
      downloadLink: undefined,
      handleSubscription: useUpdatedInnovationSubmissionCloudinaryIdSubscription,
      getLinkDataKey: 'createInnovationSubmissionsBatchPdf',
      getLinkValuesKey: 'pdfCloudinaryId',
      handleGetLink: createSubmissionsBatchPDF,
      fileName: 'innovatorDirectory'
    } as DownloadProps)

    for (let index = 0; index < selectedItemsKeys.length; index++) {
      let pdfCreated = true
      const companyId = selectedItemsKeys[index]
      const companyIndex = data?.findIndex(item => item?.id === companyId) ?? -1
      const companyProfile = (data && data[companyIndex]) ?? undefined
      const companyProduct =
        companyProfile?.companyInfo?.products?.length > 0
          ? companyProfile?.companyInfo?.products[0]
          : null
      if (companyProduct) {
        if (!companyProduct?.submission?.pdfCloudinaryId) {
          const { createInnovationSubmissionPdf } = await createSubmissionPDF(
            companyProduct?.submissionId,
            false
          )
          pdfCreated = createInnovationSubmissionPdf
        }
        if (!pdfCreated) {
          console.error(
            `Error creating Product PDF for Company: ${companyId} with product: ${companyProduct?.id}`
          )
        }
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (
      loadingCreateSubmissionsBatchPDF ||
      loadingCreateSubmissionPDF ||
      isLoading ||
      isLoadingDownloadStatus ||
      !(selectedItemsKeys.length > 0)
    ) {
      setIsPDFDisabled(true)
    } else {
      setIsPDFDisabled(false)
    }
  }, [
    loadingCreateSubmissionsBatchPDF,
    loadingCreateSubmissionPDF,
    isLoading,
    isLoadingDownloadStatus,
    selectedItemsKeys
  ])

  useEffect(() => {
    if (showProductPDFButton) {
      if (
        loadingCreateSubmissionsBatchPDF ||
        loadingCreateSubmissionPDF ||
        isLoading ||
        isLoadingDownloadStatus ||
        !(selectedItemsKeys.length > 0)
      ) {
        setIsProductPDFDisabled(true)
      } else {
        setIsProductPDFDisabled(false)
      }
    }
  }, [
    showProductPDFButton,
    loadingCreateSubmissionsBatchPDF,
    loadingCreateSubmissionPDF,
    isLoading,
    isLoadingDownloadStatus,
    selectedItemsKeys
  ])

  useEffect(() => {
    if (config && isCurator && !error && selectedItemsKeys.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [config, isCurator, error, selectedItemsKeys])

  if (!enableFilters) {
    return <></>
  }

  return (
    <DynamicSearchBarContainer isAlternativeView={isAlternativeView}>
      <SearchComponentContainer isAlternativeView={isAlternativeView}>
        {showSearchField && (
          <SearchComponent
            searchFilters={searchFilters}
            handleChangeSearch={handleChangeSearch}
            initialValue={initialValue}
            isAlternativeView={isAlternativeView}
          />
        )}
      </SearchComponentContainer>
      <HeaderButtonsContainer>
        {showCsvButton && (
          <ButtonContainer>
            <Flex width="100%" alignItems="center">
              <CsvDownloadButton
                currentUserId={currentUserId}
                data={
                  data &&
                  data?.filter(
                    el => el?.id && selectedItemsKeys.includes(el?.id)
                  )
                }
                config={config}
                isDisabled={isDisabled}
                fetchData={fetchData}
                selectAll={selectAll}
                selectCurrent={selectCurrent}
              />
            </Flex>
          </ButtonContainer>
        )}
        {showPDFButton && (
          <ButtonContainer left>
            <Flex width="100%" alignItems="center">
              <Button
                disabled={isPDFDisabled}
                title={t('curation:submissionDetails:createPDF')}
                onPress={handleCreateSubmissionPDF}
                buttonStyle={{
                  width: sizes[6] + sizes[4],
                  paddingVertical: 10,
                  backgroundColor: colors.background
                }}
                titleStyle={{
                  color: colors.darkIcon
                }}
              />
            </Flex>
          </ButtonContainer>
        )}

        {showProductPDFButton && (
          <ButtonContainer left>
            <Flex width="100%" alignItems="center">
              <Button
                disabled={isProductPDFDisabled}
                title={t('curation:submissionDetails:createPDF')}
                onPress={handleCreateProductPDF}
                buttonStyle={{
                  width: sizes[6] + sizes[4],
                  paddingVertical: 10,
                  backgroundColor: colors.background
                }}
                titleStyle={{
                  color: colors.darkIcon
                }}
              />
            </Flex>
          </ButtonContainer>
        )}
      </HeaderButtonsContainer>
    </DynamicSearchBarContainer>
  )
}

export default DynamicTableSearchBar
