import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const upsertAnswersMutation = gql`
  mutation upsertAnswers(
    $answers: [AnswerInput!]!
    $dynamic: Boolean
    $originSource: OriginSourceEnum
  ) {
    upsertAnswers(
      answers: $answers
      dynamic: $dynamic
      originSource: $originSource
    ) {
      id
      questionId
      value
      formSubmissionId
      answerEntityId
      answerEntityType
    }
  }
`

const useUpsertAnswersMutation = (options = {}) => {
  const [upsertAnswers, { loading: loadingUpsertAnswer }]: any[] = useMutation(
    upsertAnswersMutation,
    options
  )

  return [upsertAnswers, loadingUpsertAnswer]
}

export default useUpsertAnswersMutation
