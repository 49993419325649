import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const signupUserMutation = gql`
  mutation signup(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $innovationEnabled: Boolean
  ) {
    signup(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      innovationEnabled: $innovationEnabled
    ) {
      id
      role
      person {
        id
        firstName
        lastName
      }
    }
  }
`

const useSignupUser = (options = {}) => {
  const [signupUser, { loading }]: any[] = useMutation(
    signupUserMutation,
    options
  )

  return [signupUser, loading]
}

export default useSignupUser
