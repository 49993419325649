import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { Form } from '../../../types'
import FormFieldsFragment from '../../../graphql/FormFieldsFragment'

const forms = gql`
  query forms {
    forms {
      ...FormFieldsFragment
    }
  }
  ${FormFieldsFragment}
`

interface FormObject {
  forms: Form[]
  loading: boolean
}

const useFormsQuery = (options = {}): FormObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(forms, {
    options,
    errorMessage: t('error:general:getForms')
  })

  return {
    forms: data?.forms,
    loading
  }
}

export default useFormsQuery
