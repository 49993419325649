import gql from 'graphql-tag'
import { clone } from 'ramda'
import { useMutation } from '@apollo/client'
// Hooks
import { companyQuery } from '../../../hooks/useCompanyQuery'
// Types
import { CompanyProfile } from '../../../types'

const deleteCompanyLogoMutation = gql`
  mutation deleteCompanyLogo($companyId: String!) {
    deleteCompanyLogo(companyId: $companyId)
  }
`
interface DeleteCompanyLogoVariables {
  companyId: string
}
interface DeleteCompanyLogoResult {
  deleteCompanyLogo: (
    variables: DeleteCompanyLogoVariables
  ) => Promise<CompanyProfile | null>
  loading: boolean
}

const useDeleteCompanyLogo = (companyId: string): DeleteCompanyLogoResult => {
  const [deleteLogo, { loading }] = useMutation(deleteCompanyLogoMutation)

  const deleteCompanyLogo = async (variables: DeleteCompanyLogoVariables) => {
    const { data } = await deleteLogo({
      variables,
      update: (cache, { data }) => {
        try {
          const { deleteCompanyLogo } = data

          if (deleteCompanyLogo) {
            let companyQueryData: any = clone(
              cache.readQuery({
                query: companyQuery,
                variables: { id: companyId }
              })
            )

            if (companyQueryData && companyQueryData?.company?.companyProfile) {
              companyQueryData.company.companyProfile['logo'] = null
              cache.writeQuery({
                query: companyQuery,
                variables: companyQuery,
                data: { ...companyQueryData }
              })
            }
          }
        } catch (error) {
          console.log({ deleteCompanyLogoQueryUpdateError: error })
        }
      }
    })
    return data
  }

  return { deleteCompanyLogo, loading }
}

export default useDeleteCompanyLogo
