import gql from 'graphql-tag'
import { Company } from '../types'
import useTranslation from './useTranslation'
import useQuery from './useQuery'
import CompanyFragment from '../graphql/CompanyFragment'

export const companyQuery = gql`
  query company($id: String!) {
    company(id: $id) {
      ...CompanyFragment
      canEdit
      alumniMembership {
        id
      }
      canAddInnovatorProducts
    }
  }
  ${CompanyFragment}
`

interface CompanyQueryProp {
  company: Company
  loading: boolean
}

const useCompanyQuery = (companyId, options = {}): CompanyQueryProp => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(companyQuery, {
    variables: {
      id: companyId
    },
    skip: !companyId,
    errorMessage: t('innovatorDirectory:details:queryError'),
    ...options
  })

  return {
    company: data?.company as Company,
    loading
  }
}

export default useCompanyQuery
