const useFilterOptions = filter => {
  const { hookOptions } = filter
  const { useHookQuery, hookParams, hookKey, labelKey, valueKey } = hookOptions
  const data = useHookQuery(hookParams)

  if (data[hookKey] && !data['loading']) {
    return data[hookKey]?.map(element => ({
      label: element[labelKey],
      value: element[valueKey]
    }))
  }

  return []
}

export default useFilterOptions
