import React, { useCallback } from 'react'
import { useTheme } from 'styled-components/native'
import * as Yup from 'yup'
import { isEmpty } from 'ramda'
import useTranslation from '../../../hooks/useTranslation'

import { InputContainer } from '../groups/SharedStyledComponents'
import { FormikErrors, FormikProvider, FormikValues, useFormik } from 'formik'
import { useMutation } from '@apollo/client'
import upsertAnswerFileAttachmentMapMutation from '../../../screens/Product/mutations/upsertAnswerFileAttachmentMapMutation'
import TextInput from '../TextInput'
import Button from '../../../ui-library/Button'
import { AnswerFileAttachmentMap } from '../../../types'
import { MAX_LENGT_TEXT_INPUT } from './constants'
import { EditFormContainer, FieldContainer } from './SharedStyledComponents'
import { Answer, QuestionTypeEnum } from '../../../types/form'

interface EditFormProps {
  answer: Answer
  file: AnswerFileAttachmentMap
  handleShowForm: () => void
}

const EditForm = ({ answer, file, handleShowForm }: EditFormProps) => {
  const { formSubmissionId, questionId } = answer
  const { id } = file
  const { t } = useTranslation()
  const { space } = useTheme()
  const [upsertAnswerFileAttachment] = useMutation(
    upsertAnswerFileAttachmentMapMutation,
    {
      refetchQueries: ['question']
    }
  )

  const data = {
    key: `${id}-name`,
    name: `${id}.name`,
    label: '',
    length: MAX_LENGT_TEXT_INPUT,
    isMultiline: false,
    numberOfLines: 1,
    placeholder: t('product:pitch:form:deckName'),
    type: QuestionTypeEnum.TextInput,
    renderCounter: false,
    styles: {
      styles: {
        backgroundColor: 'white'
      }
    }
  }

  const form = useFormik({
    initialValues: { [id]: { name: '' } },
    validateOnBlur: true,
    validateOnChange: false,
    validate: async (values: FormikValues) => {
      const errors: FormikErrors<FormikValues> = {}
      const schema = Yup.object().shape({
        [id]: Yup.object().shape({
          name: Yup.string().required()
        })
      })
      try {
        schema.validateSync(values, { abortEarly: false })
      } catch (error) {
        error?.inner?.forEach(error => {
          errors[id] = {
            [error?.path?.split('.')[1]]: 'forms:Required'
          }
        })
      }
      return isEmpty(errors[id]) ? {} : errors
    },
    onSubmit: async (values, { setSubmitting, setStatus, resetForm }) => {
      try {
        await onSaveForm(values)
        resetForm({})
        setStatus({ success: true })
        handleShowForm()
      } catch (error) {
        setStatus({ success: false })
        setSubmitting(false)
      }
    }
  })

  const onSaveForm = async (values: FormikValues) => {
    const answerFileAttachmentMapValues = values[id]
    const { name } = answerFileAttachmentMapValues

    const variables = { id, name, questionId, formSubmissionId }
    await upsertAnswerFileAttachment({ variables })
  }

  const handleSave = useCallback(async () => {
    form.setTouched({ [id]: { name: true } })
    const errors = await form.validateForm()
    if (isEmpty(errors)) {
      await form.submitForm()
    }
  }, [form])

  return (
    <EditFormContainer>
      <FormikProvider value={form}>
        <InputContainer style={{ flexDirection: 'row' }}>
          <FieldContainer>
            <TextInput data={data} isReadOnly={false} key={`dynamic-${id}`} />
          </FieldContainer>
          <Button
            title={t('dropZone:saveButton')}
            buttonStyle={{
              width: 'fit-content',
              paddingLeft: space[4] - space[1],
              paddingRight: space[4] - space[1]
            }}
            containerStyle={{
              marginLeft: space[4]
            }}
            onPress={handleSave}
          />
        </InputContainer>
      </FormikProvider>
    </EditFormContainer>
  )
}

export default EditForm
