import React, { FC, useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/native'

import { InnovationSubmission } from '../../../types'
import { Flex } from '../../../components/FlexBox'
import Button from '../../../ui-library/Button'
import useTranslation from '../../../hooks/useTranslation'
import { H3, Text } from '../../../components/common/Text'
import DateService from '../../../services/dateService'
import idToCloudinaryUrl from '../../../utils/idToCloudinaryUrl'
import Link from '../../../components/Link'
// JC: Commented for now until the new specifications are completed
// import DonutChart from '../../InnovatorDirectory/components/DonutChart'
// import SubmissionPrefillStepsSummary from './SubmissionPrefillStepsSummary'
import useCreateSubmissionPdfMutation from '../hooks/useCreateSubmissionPdfMutation'
import useSubmissionPdfSubscription from '../../../hooks/useSubmissionPdfSubscription'
import Icon from '../../../components/icon'

// JC: Commented for now until the new specifications are completed
// const MAX_SCORE = 5

const SummaryContainer = styled(Flex)`
  ${({ theme }) => `
    margin-bottom: ${theme.space[4]}px;
  `}
`
const Container = styled(Flex)`
  ${({ theme }) => `
    background: ${theme.colors.formCard};
    border-top-right-radius: ${theme.radii[3]}px;
    border-top-left-radius: ${theme.radii[3]}px;
    padding: ${theme.space[4]}px;
  `}
`

export const Title = styled(H3).attrs(props => ({
  h3Style: {
    fontWeight: `${props.theme.fontWeights.bold}`,
    fontSize: `${props.theme.fontSizes[4]}px`,
    marginBottom: props.theme.space[1]
  }
}))``

export const SecondaryText = styled(Text)`
  ${({ theme: { colors, space } }) => `
  color: ${colors.tints.grays.g400};
  margin-bottom: ${space[2]}px;
  `}
`
// JC: Commented for now until the new specifications are completed
// const DonutContainer = styled.View`
//   max-width: 320px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `
//
// const LightLabel = styled.Text`
//   ${({ theme: { colors, fontWeights, fontSizes } }) => `
//     color: ${colors.tints.grays.g400};
//     font-weight: ${fontWeights.regular};
//     font-size: ${fontSizes[3]}px;
//   `}
// `
//
// const BoldLabel = styled.Text`
//   ${({ theme: { sizes, fontWeights, fontSizes } }) => `
//     font-weight: ${fontWeights.bold};
//     margin-top: ${sizes[1]}px;
//     font-size: ${fontSizes[6]}px;
//   `}
// `

interface PrefillSummaryProps {
  submission?: InnovationSubmission
}

const SubmissionPrefillSummary: FC<PrefillSummaryProps> = ({ submission }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { space, colors } = theme
  const { createSubmissionPDF, loading } = useCreateSubmissionPdfMutation()
  const [creatingPDF, setCreatingPDF] = useState(false)

  const submissionPdfUrl = useMemo(() => {
    return submission?.pdfCloudinaryId
      ? idToCloudinaryUrl(submission?.pdfCloudinaryId, {
          resource_type: 'image'
        })
      : null
  }, [submission?.pdfCloudinaryId])

  useEffect(() => {
    if (submissionPdfUrl) {
      setCreatingPDF(false)
    }
  }, [submissionPdfUrl])

  const isGeneratingPDF = useMemo(
    () => (submissionPdfUrl ? false : loading || creatingPDF),
    [submissionPdfUrl, loading, creatingPDF]
  )

  useSubmissionPdfSubscription({
    submissionId: submission?.id,
    pdfCloudinaryId: submission?.pdfCloudinaryId
  })

  const handleCreateSubmissionPDF = async () => {
    const pdfResult = await createSubmissionPDF(submission?.id)
    setCreatingPDF(true)

    if (pdfResult?.errors) {
      console.error(pdfResult.errors)
    }
  }

  const pdfButtonStyles = useMemo(
    () => ({
      standard: {
        buttonStyle: {
          borderColor: colors.cta.secondary,
          backgroundColor: 'transparent'
        },
        titleStyle: {
          color: colors.cta.secondary,
          textTransform: 'capitalize'
        },
        iconStyle: {
          color: colors.cta.secondary
        }
      },
      hover: {
        iconStyle: {
          color: colors.primaryPalette.white
        },
        buttonStyle: {
          backgroundColor: colors.cta.secondary,
          borderColor: colors.cta.secondary
        },
        titleStyle: {
          color: colors.primaryPalette.white
        }
      }
    }),
    [colors]
  )
  // JC: Commented for now until the new specifications are completed
  // const scorePercentage = useMemo(() => {
  //   let avg = 0
  //   if (submission?.submissionAverage?.wass) {
  //     avg = submission?.submissionAverage?.wass
  //   }
  //   return (avg * 100) / MAX_SCORE
  // }, [submission?.submissionAverage?.wass])

  if (!submission || !!submission.legacyOriginId) {
    return null
  }
  const { btnContainerStyles, btnStyles } = getStyles(theme)
  const callForSubmission = submission?.callForSubmission
  const marketSegment =
    callForSubmission &&
    callForSubmission.marketSegments &&
    callForSubmission.marketSegments.length > 0
      ? callForSubmission?.marketSegments[0]?.name
      : ''
  const title = submission?.submissionInfo?.name || ''
  const submittedAt = DateService.getFormat(
    new Date(submission?.submittedAt),
    'MMMM DD, yyyy'
  )

  return (
    <SummaryContainer>
      <Container>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            style={{
              maxWidth: '50%',
              minHeight: 120
            }}
          >
            <Flex
              style={{
                marginBottom: space[2]
              }}
            >
              <Title>{title}</Title>
              {callForSubmission ? (
                <SecondaryText>{callForSubmission?.name || ''}</SecondaryText>
              ) : null}
            </Flex>
            <SecondaryText>
              {t('submissions:printed:submitted')}: {submittedAt || ''}
            </SecondaryText>
            {marketSegment ? (
              <SecondaryText>{marketSegment || ''}</SecondaryText>
            ) : null}
            {submissionPdfUrl ? (
              <Link
                style={{
                  textDecoration: 'none'
                }}
                to={submissionPdfUrl}
                title={t('common:labels:preview')}
                target="_blank"
              >
                <Button
                  title={t('submissions:prefill:preview')}
                  type="outline"
                  buttonStyle={{
                    ...btnStyles
                  }}
                  containerStyle={{
                    ...btnContainerStyles,
                    marginRight: space[3]
                  }}
                  onPress={() => {}}
                />
              </Link>
            ) : (
              <Button
                title={
                  creatingPDF
                    ? t('curation:submissionDetails:generatingPDF')
                    : t('curation:submissionDetails:createPDF')
                }
                type="outline"
                icon={
                  <Icon
                    name={submissionPdfUrl ? 'download' : 'arrow-path'}
                    width={14}
                    height={14}
                    color="black"
                  />
                }
                buttonStyle={
                  isGeneratingPDF
                    ? {
                        padding: space[1],
                        ...pdfButtonStyles.hover.buttonStyle
                      }
                    : pdfButtonStyles.standard.buttonStyle
                }
                titleStyle={
                  isGeneratingPDF
                    ? pdfButtonStyles.hover.titleStyle
                    : pdfButtonStyles.standard.titleStyle
                }
                iconStyle={
                  isGeneratingPDF
                    ? pdfButtonStyles.hover.iconStyle
                    : pdfButtonStyles.standard.iconStyle
                }
                hoverStyles={pdfButtonStyles.hover}
                onPress={async () => {
                  await handleCreateSubmissionPDF()
                }}
                loading={isGeneratingPDF}
              />
            )}
          </Flex>
          {/* JC: Commented for now until the new specifications are completed */}
          {/* <DonutContainer> */}
          {/*  <DonutChart */}
          {/*    color={colors.primaryPalette.accent} */}
          {/*    contrastColor={colors.tints.blues.b400} */}
          {/*    percentage={scorePercentage} */}
          {/*    radius={60} */}
          {/*  > */}
          {/*    <LightLabel> */}
          {/*      {t('innovatorDirectory:donutChart:advisor')} */}
          {/*    </LightLabel> */}
          {/*    <BoldLabel> */}
          {/*      {submission?.hasBeenFastPassed || */}
          {/*      !submission?.submissionAverage?.wass */}
          {/*        ? t('innovatorDirectory:donutChart:noValue') */}
          {/*        : submission?.submissionAverage?.wass.toFixed(2)} */}
          {/*    </BoldLabel> */}
          {/*  </DonutChart> */}
          {/* </DonutContainer> */}
        </Flex>
      </Container>
      {/* JC: Commented for now until the new specifications are completed */}
      {/* <SubmissionPrefillStepsSummary submission={submission} /> */}
    </SummaryContainer>
  )
}

const getStyles = theme => ({
  btnContainerStyles: { height: theme.space[4], marginTop: theme.space[3] },
  btnStyles: {
    width: 'fit-content',
    paddingBottom: theme.space[3],
    paddingTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[2],
    paddingRight: theme.space[4] - theme.space[2],
    height: theme.space[4],
    paddingHorizontal: theme.space[4]
  }
})

export default SubmissionPrefillSummary
