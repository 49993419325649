import { useRecoilValue } from 'recoil'

import appHeaderAtom from '../../recoil/appHeaderAtom'

/**
 * Just moving this out into its own component helps organize the renders a
 * little better, putting this into its own tree on the flamegraph in the
 * profiler.
 */
const AppHeaderContainer = () => {
  const appHeader = useRecoilValue(appHeaderAtom)

  return appHeader
}

export default AppHeaderContainer
