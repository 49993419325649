import React, { useEffect, useState } from 'react'

import LoadingScreen from './LoadingScreen'
import Firebase from '../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import useTranslation from '../hooks/useTranslation'
import NewTermsScreen from '../screens/authentication/NewTermsScreen'
import useLegalStatus from './queries/useLegalStatus'

const TermsCheck = ({ send }: any) => {
  const { t } = useTranslation()
  const { loading, error, data } = useLegalStatus({
    skip: !getFBAuth(Firebase).currentUser,
    fetchPolicy: 'no-cache',
    errorMessage: t('error:auth:failedVerifyRegistration')
  })
  const termsVerified = data?.isLegalUpToDate
  const [showScreen, setShowScreen] = useState(false)

  useEffect(() => {
    const handleTermsCheck = () => {
      if (error || !termsVerified) {
        setShowScreen(true)
      } else {
        setShowScreen(false)
        send('TERMS_VERIFIED')
      }
    }
    if (!loading) {
      handleTermsCheck()
    }
  }, [loading, error, termsVerified, send])

  if (loading) {
    return <LoadingScreen />
  }

  return showScreen ? <NewTermsScreen send={send} /> : null
}

export default TermsCheck
