import React from 'react'
import { ScreenContainerWithMenuHeader } from '../../../components/layout/ScreenContainer'
import DashboardComponents from './Components'
import { DashboardProvider } from './hooks/DashboardProvider'
import useInnovationDashboardQuery from './hooks/useDashboardQuery'
import { useRoute } from '@react-navigation/native'

const Dashboard = () => {
  const { params }: any = useRoute()
  const cfsId = params?.cfsId
  const { data, loading } = useInnovationDashboardQuery(cfsId)

  return (
    <ScreenContainerWithMenuHeader screenCategory="dashboard">
      <DashboardProvider props={{ loading, data }}>
        <DashboardComponents />
      </DashboardProvider>
    </ScreenContainerWithMenuHeader>
  )
}

export default Dashboard
