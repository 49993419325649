import { useRecoilState, SetterOrUpdater } from 'recoil'
import HistoryRoutesAtom, { History } from '../recoil/historyRoutesAtom'

/*
 * This hook provides all history routes as a TabNavigator does not preserve the history correctly.
 * */
const useHistoryRoutes = () => {
  const [historyAtom, setHistory] = useRecoilState(HistoryRoutesAtom) as [
    History,
    SetterOrUpdater<History>
  ]

  const pushToHistory = newRoute => {
    const newHistory = [...historyAtom.history, newRoute]
    setHistory({
      history: newHistory
    })
  }

  const getPreviousRoute = () => {
    const { history } = historyAtom

    if (history.length >= 2) {
      return history[history.length - 2]
    }

    return null
  }

  return {
    history: historyAtom.history,
    pushToHistory,
    getPreviousRoute
  }
}

export default useHistoryRoutes
