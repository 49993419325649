import React, { useEffect, useMemo, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Text } from '../../../../../components/common/Text'
import { TableItem, TableItemProps, TableProps } from './types'
import AnimatedComponent from '../../../../../components/common/AnimatedComponent'
import { CompanyObject, SubmissionsObject } from '../../types'

const TableRow = (props: TableItemProps) => {
  const { item, colWidths } = props
  const { colors } = useTheme()

  return (
    <AnimatedComponent
      clickOpacity
      style={{
        flexDirection: 'row',
        height: 35,
        overflow: 'hidden',
        borderRadius: 25,
        paddingHorizontal: 10,
        justifyContent: 'center'
      }}
      hoverColor={colors.tableRowHover}
    >
      <>
        {item.map(({ value, color, handleClick }, index) => (
          <Text
            styles={{
              paddingVertical: 5,
              width: `${colWidths[index]}%`,
              textAlign: 'center',
              alignSelf: 'center',
              marginHorizontal: 5,
              borderBottomColor: colors.tints.grays.g100,
              borderBottomWidth: '1px',
              color: color ? 'white' : colors.tints.grays.g300,
              overflow: 'scroll',
              whiteSpace: 'nowrap',
              backgroundColor: color,
              borderRadius: color ? 25 : undefined
            }}
            onPress={() => handleClick && handleClick()}
          >
            {value
              ? String(value).trim() === ''
                ? '-'
                : String(value).trim()
              : '-'}
          </Text>
        ))}
      </>
    </AnimatedComponent>
  )
}

const Table = (props: TableProps) => {
  const { colors } = useTheme()
  const { columns, data: initialData } = props

  const totalWidth = columns.reduce((acc, { width }) => acc + width, 0)
  const [data, setData] = useState<(SubmissionsObject | CompanyObject)[]>([])
  const colWidths = columns.map(({ width }) =>
    totalWidth < 100 ? (width / totalWidth) * 100 : width
  )
  const [[sortBy, sortDir], updateSort] = useState([0, 'asc'])
  const sortIcon = useMemo(() => (sortDir === 'asc' ? '▲ ' : '▼ '), [sortDir])

  const handleOnSort = (index: number) => {
    let newSortDir
    if (index === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      newSortDir = 'asc'
    }
    updateSort([index, newSortDir])
  }

  useEffect(() => {
    const sortColumn = columns[sortBy]
    let newFilteredAndSortedData: (SubmissionsObject | CompanyObject)[] = []
    if (initialData.length) {
      newFilteredAndSortedData = [...initialData]
      if (sortColumn.columnType === 'Number') {
        newFilteredAndSortedData = newFilteredAndSortedData.sort((a, b) =>
          sortDir === 'asc'
            ? a[sortColumn.key] - b[sortColumn.key]
            : b[sortColumn.key] - a[sortColumn.key]
        )
      } else {
        newFilteredAndSortedData = newFilteredAndSortedData.sort((a, b) => {
          const aLowerCase = String(a[sortColumn.key]).toLowerCase()
          const bLowerCase = String(b[sortColumn.key]).toLowerCase()
          return aLowerCase > bLowerCase
            ? sortDir === 'asc'
              ? 1
              : -1
            : bLowerCase > aLowerCase
            ? sortDir === 'asc'
              ? -1
              : 1
            : 0
        })
      }
    }
    setData(newFilteredAndSortedData)
  }, [initialData, sortBy, sortDir])

  const tableData = useMemo(() => {
    const tmpTableDataResult: TableItem[][] = []
    let tmpFilteredData = data
    for (let indexItem = 0; indexItem < tmpFilteredData.length; indexItem++) {
      const currentItem = tmpFilteredData[indexItem]
      const currentItemValues: TableItem[] = []
      for (let indexColumn = 0; indexColumn < columns.length; indexColumn++) {
        const currentColumn = columns[indexColumn]
        const colValue = currentColumn.getData(currentItem)
        const handleClick = currentColumn.handleClick ?? ((() => {}) as any)
        currentItemValues.push({
          value: colValue,
          color: currentColumn.color
            ? currentColumn.color
            : currentColumn.getColor
            ? currentColumn.getColor(currentItem)
            : undefined,
          handleClick: () => handleClick(currentItem.id)
        })
      }
      tmpTableDataResult.push(currentItemValues)
    }
    return tmpTableDataResult
  }, [data, columns])

  return (
    <View
      style={{
        flex: 1
      }}
    >
      {/* Table Header */}
      <View
        style={{
          flexDirection: 'row',
          height: 25,
          paddingHorizontal: 10
        }}
      >
        {colWidths.map((width, index) => (
          <TouchableOpacity
            style={{
              height: '90%',
              width: `${width}%`,
              borderBottomColor: colors.tints.grays.g100,
              borderBottomWidth: 1
            }}
            onPress={() => handleOnSort(index)}
          >
            <Text
              styles={{
                textAlign: 'center',
                alignSelf: 'center',
                color: colors.tints.grays.g300,
                fontWeight: 'bold'
              }}
            >
              {`${index === sortBy ? sortIcon : ''}${columns[index].name}`}
            </Text>
          </TouchableOpacity>
        ))}
      </View>

      {/* Table Rows */}
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          overflowY: 'scroll',
          paddingBottom: 10
        }}
      >
        {tableData.map(item => (
          <TableRow item={item} colWidths={colWidths} />
        ))}
      </View>
      <View
        style={{ width: 'fit-content', alignSelf: 'flex-end', marginTop: 10 }}
      >
        <Text
          styles={{ color: colors.tints.grays.g200, fontStyle: 'italic' }}
        >{`Total: ${tableData.length}`}</Text>
      </View>
    </View>
  )
}

export default Table
