import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import SubmissionsScreen from './SubmissionsScreen'
import SubmissionPrefillScreen from './SubmissionPrefillScreen'
import SubmissionForm from './components/SubmissionForm'

const SubmissionStack = createStackNavigator()
const SubmissionNavigator = () => (
  <SubmissionStack.Navigator
    screenOptions={{ headerShown: false }}
    initialRouteName="SubmissionList"
  >
    <SubmissionStack.Screen
      name="SubmissionList"
      component={SubmissionsScreen}
    />
    <SubmissionStack.Screen
      name="SubmissionPrefill"
      component={SubmissionPrefillScreen}
    />
    <SubmissionStack.Screen name="SavedSubmission" component={SubmissionForm} />
  </SubmissionStack.Navigator>
)

export default SubmissionNavigator
