import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import FormFieldsFragment from '../../../../../../graphql/FormFieldsFragment'

export const upsertFormMutation = gql`
  mutation upsertForm($input: FormInput) {
    upsertForm(input: $input) {
      ...FormFieldsFragment
    }
  }
  ${FormFieldsFragment}
`

const useUpsertForm = (options = {}) => {
  const [upsertForm, { loading: loadingUpsertForm }]: any[] = useMutation(
    upsertFormMutation,
    options
  )

  return [upsertForm, loadingUpsertForm]
}

export default useUpsertForm
