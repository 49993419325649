import React, { FC } from 'react'

import { ScreenContainerWithTabs } from '../../../../components/layout/ScreenContainer'
import { CallForSubmission, InnovationAccessKey } from '../../../../types'
import withNavigationPermissions from '../../../../navigation/withNavigationPermissions'
import { GeneralSettingsSkeleton } from '../components/Skeleton'
import SkeletonContainer from '../../../../components/skeletonLoadings/SkeletonContainer'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import TopTabBar from '../../../../components/layout/TopTabBar'
import TopTabBarMobile from '../../../../components/layout/TopTabBarMobile'
import useMixpanel, { EVENT_MAP } from '../../../../hooks/useMixpanel'
import CreateSubmissions from './CreateSubmissions'
import SubmissionsTestSettings from './Submissions'
import PreCurateTestSettings from './PreCurate'
import AdvisingTestSettings from './Advising'

const Tabs = createMaterialTopTabNavigator()

interface TestingSettingsProps {
  callForSubmission: CallForSubmission
  isLoading: boolean
}

interface TabSettings {
  name: string
  Component: React.FC
  options: {
    title: string
    webTitle: string
    disabled: boolean
  }
}

const TestingSettings: FC<TestingSettingsProps> = ({
  isLoading,
  callForSubmission
}) => {
  const { trackWithProperties } = useMixpanel()
  const isSmallScreen = useIsSmallScreen()

  const tabScreens: TabSettings[] = [
    {
      name: 'CreateSubmissionsTestSettings',
      Component: () => <CreateSubmissions cfsId={callForSubmission.id} />,
      options: {
        title: `Create Submissions`,
        webTitle: 'Create Submissions Configuration',
        disabled: false
      }
    },
    {
      name: 'SubmissionsTestSettings',
      Component: () => (
        <SubmissionsTestSettings callForSubmission={callForSubmission} />
      ),
      options: {
        disabled: false,
        title: 'Submissions',
        webTitle: 'Submissions Step'
      }
    },
    {
      name: 'PreCurateTestSettings',
      Component: () => (
        <PreCurateTestSettings callForSubmission={callForSubmission} />
      ),
      options: {
        disabled: false,
        title: 'PreCuration',
        webTitle: 'PreCuration Step'
      }
    },
    {
      name: 'AdvisingTestSettings',
      Component: () => (
        <AdvisingTestSettings callForSubmission={callForSubmission} />
      ),
      options: {
        disabled: false,
        title: 'Advising',
        webTitle: 'Advising Step'
      }
    }
  ]

  return (
    <ScreenContainerWithTabs>
      <SkeletonContainer
        isLoading={isLoading || !callForSubmission}
        Skeleton={GeneralSettingsSkeleton}
      >
        <Tabs.Navigator
          swipeEnabled={false}
          tabBar={props =>
            !isSmallScreen ? (
              <TopTabBar {...props} />
            ) : (
              <TopTabBarMobile {...props} />
            )
          }
        >
          {tabScreens.map(({ name, Component, options }) => (
            <Tabs.Screen
              key={name}
              name={name}
              children={props => <Component {...props} />}
              options={options}
              listeners={{
                tabPress: () => {
                  trackWithProperties(EVENT_MAP.click.tab, {
                    tabName: name
                  })
                }
              }}
            />
          ))}
        </Tabs.Navigator>
      </SkeletonContainer>
    </ScreenContainerWithTabs>
  )
}

const AdvisorSettingsWithPermissions = withNavigationPermissions(
  TestingSettings,
  [InnovationAccessKey.CFS_TESTING]
)

export default AdvisorSettingsWithPermissions
