import React, { FC } from 'react'

import { View, StyleSheet } from 'react-native'

import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg'

interface BackgroundProps {
  width?: number
  height?: number
}

const Background: FC<BackgroundProps> = ({ width = 261, height = 165 }) => {
  return (
    <View style={styles.container}>
      <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <Path
          d={`M0 165C53.1271 165 66.9222 142.513 93.6324 92.2839C118.288 46.1419 144.998 0 227.182 0H261V165H0Z`}
          fill={'url(#paint0_linear_5888_11488)'}
        />
        <Defs>
          <LinearGradient
            id="paint0_linear_5888_11488"
            x1="130.5"
            y1="0"
            x2="130.5"
            y2="165"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#F1F5FC" />
            <Stop offset="1" stopColor="#F1F5FC" stopOpacity="0.3" />
          </LinearGradient>
        </Defs>
      </Svg>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default Background
