import React, { useState, FC, useMemo, useEffect } from 'react'
import { prop, uniqBy } from 'ramda'

import withNavigationPermissions from '../../navigation/withNavigationPermissions'

import { ScreenContainerWithTabs } from '../../components/layout/ScreenContainer'
import { DynamicTable, useTable } from '../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'

import { Flex } from '../../components/FlexBox'
import { Container } from '../CallForSubmission/components/SharedComponents'
import { TableContainer } from '../../components/StaticForm'
import Dropdown, { DropdownValue } from '../../components/common/Dropdown'
import { Option } from '../../components/DynamicForm/DropDown'

import { getPresentationsSubmissionTableConfig } from './constants'
import { StyledLabel } from '../../ui-library/TextInput/Label'
import useTranslation from '../../hooks/useTranslation'
import { EngineEnum } from '../CallForSubmission/constants'
import { SubmissionsProps } from './Precuration'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'
import { InnovationAccessKey } from '../../types'
import useUpdateInnovationSubmissionGroupInviteMutation from './hooks/useUpdateInnovationSubmissionGroupInviteMutation'
import useUpdateInnovationSubmissionGroupSpotlightMutation from './hooks/useUpdateInnovationSubmissionGroupSpotlightMutation'
import useCategoriesByMarketSegmentQuery from '../CallForSubmission/hooks/useCategoriesByMarketSegmentQuery'
import { ViewStyle } from 'react-native'

const PresentationsScreen: FC<SubmissionsProps> = ({
  id,
  role,
  callForSubmission,
  currentCFSStep,
  delegations
}) => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { t } = useTranslation()

  const cohortList = useMemo(() => {
    return uniqBy(
      prop('value'),
      delegations.reduce((groups, delegation) => {
        return [
          ...groups,
          ...delegation.groups.map(({ id, shortName }) => ({
            label: shortName,
            value: id
          }))
        ]
      }, [])
    )
  }, [delegations])

  const {
    loading: loadingCategories,
    innovationCategories = []
  } = useCategoriesByMarketSegmentQuery({
    marketSegmentId: callForSubmission?.marketSegments?.length
      ? callForSubmission?.marketSegments[0].id
      : undefined
  })

  const [
    categoryFilteredBy,
    setCategoryFilterBy
  ] = useState<DropdownValue | null>(null)
  const [statusFilteredBy, setStatusFilterBy] = useState<DropdownValue | null>(
    null
  )

  const [categoryOptions, setCategoryOptions] = useState<Option[]>([])
  const statusOptions: Option[] = [
    {
      label: t('curation:submissions:table:invite'),
      value: 'invite'
    },
    {
      label: t('curation:submissions:table:spotlight'),
      value: 'spotlight'
    }
  ]

  const [filterBy, setFilterBy] = useState<string>(
    cohortList ? cohortList[0]?.value : ''
  )

  useEffect(() => {
    if (!loadingCategories && innovationCategories.length) {
      setCategoryOptions(
        innovationCategories.map(ic => ({ label: ic.name, value: ic.id }))
      )
    }
  }, [loadingCategories, innovationCategories])

  const {
    updateInnovationSubmissionGroupInvite
  } = useUpdateInnovationSubmissionGroupInviteMutation()
  const {
    updateInnovationSubmissionGroupSpotlight
  } = useUpdateInnovationSubmissionGroupSpotlightMutation()

  const handleUpdateFlagSelection = async ({ flag, variables }) => {
    const mutation =
      flag === 'isInvite'
        ? updateInnovationSubmissionGroupInvite
        : updateInnovationSubmissionGroupSpotlight
    const { errors } = await mutation({ variables })
    return !!errors
  }

  const tableProps = useTable({
    config: getPresentationsSubmissionTableConfig({
      cfsId: id,
      currentCFSStep,
      cohortId: filterBy,
      cohortLabel: cohortList.find(c => c.value === filterBy)?.label || '-',
      role,
      engine: EngineEnum.InnovationSubmission,
      categoryFilteredBy: (categoryFilteredBy?.length
        ? categoryFilteredBy
        : []) as string[],
      statusFilteredBy: (statusFilteredBy as string) ?? undefined,
      isInnovationNewDynamicTable,
      handleUpdateFlagSelection
    })
  })

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const cohortStyle: ViewStyle = { width: 100, marginBottom: 0, marginLeft: 16 }
  const categoryStyle: ViewStyle = {
    minHeight: 0,
    marginLeft: 26,
    marginBottom: 0,
    maxWidth: '20vw'
  }
  const statusStyle: ViewStyle = {
    minHeight: 0,
    marginLeft: 32,
    marginBottom: 0
  }
  const controlStyle: ViewStyle = { minHeight: 0 }

  return (
    <ScreenContainerWithTabs>
      <Container>
        <Flex flexDirection="row" alignItems="center">
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
          <Dropdown
            value={filterBy}
            placeholder={t('callsForSubmission:step:name:cohort')}
            onSelect={setFilterBy}
            options={cohortList}
            isFilter
            style={cohortStyle}
            menuPortalTarget={document?.body}
          />
          <Dropdown
            name="categoryFilter"
            value={categoryFilteredBy}
            placeholder={t('curation:submissions:table:category')}
            options={categoryOptions}
            onSelect={setCategoryFilterBy}
            isMulti
            isFilter
            isClearable
            style={categoryStyle}
            controlStyle={controlStyle}
            menuPortalTarget={document?.body}
          />
          <Dropdown
            name={'statusFilter'}
            value={statusFilteredBy}
            placeholder={t('curation:submissions:table:status')}
            options={statusOptions}
            onSelect={setStatusFilterBy}
            isFilter
            isClearable
            style={statusStyle}
            controlStyle={controlStyle}
            menuPortalTarget={document?.body}
          />
        </Flex>
        <TableContainer height="92%">
          <TableComponent
            {...tableProps}
            emptyMessage={
              filterBy?.length
                ? t('curation:presentations:noSubmissions')
                : t('curation:presentations:selectCohortFilter')
            }
          />
        </TableContainer>
      </Container>
    </ScreenContainerWithTabs>
  )
}

const PresentationsPage = withNavigationPermissions(PresentationsScreen, [
  InnovationAccessKey.CURATE
])

export default PresentationsPage
