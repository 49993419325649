import React from 'react'
import { useTheme } from 'styled-components/native'
import { Text } from '../../../../components/common/Text'
import AnimatedComponent from '../../../../components/common/AnimatedComponent'
import { AnimatedEffect } from '../../../../components/common/AnimatedComponent/types'
import { DEFAULT_COMPONENT_HEIGHT } from '../components/constants'

interface CardProps {
  children?: any
  height?: number
  width?: string
  minWidth?: string
  title?: string
  styles?: object
  additionalTitleStyles?: object
  effects?: AnimatedEffect[]
  clickOpacity?: boolean
  hoverOpacity?: boolean
}

const Card = (props: CardProps) => {
  const { colors } = useTheme()
  const {
    children,
    height = DEFAULT_COMPONENT_HEIGHT,
    width,
    minWidth,
    title,
    styles,
    additionalTitleStyles,
    effects,
    clickOpacity,
    hoverOpacity
  } = props

  return (
    <AnimatedComponent
      effects={effects}
      clickOpacity={clickOpacity}
      hoverOpacity={hoverOpacity}
      style={{
        borderRadius: '10px',
        overflow: 'hidden',
        borderColor: colors.tints.grays.g100,
        borderWidth: '1px',
        margin: '5px',
        padding: '15px',
        height: height,
        width: width,
        minWidth: minWidth,
        shadowColor: colors.tints.grays.g500,
        shadowOffset: {
          width: -5,
          height: 5
        },
        shadowOpacity: 0.1,
        shadowRadius: '10px',
        ...styles
      }}
    >
      {title ? (
        <Text
          styles={{
            marginBottom: '10px',
            paddingBottom: '5px',
            fontSize: '20px',
            color: colors.tints.grays.g300,
            borderBottomColor: colors.tints.grays.g100,
            borderBottomWidth: 0.5,
            ...additionalTitleStyles
          }}
        >
          {title}
        </Text>
      ) : null}
      {children}
    </AnimatedComponent>
  )
}

export default Card
