import React from 'react'
import Constants from 'expo-constants'
import { View } from 'react-native'
import { Text } from 'react-native-elements'
import styled from 'styled-components/native'

// Components
import CloudinaryAvatar from '../../CloudinaryAvatar'
// Hooks
import useCurrentUser from '../../../hooks/useCurrentUser'

const ENV = Constants?.manifest?.extra?.BUILD_ENV
const VERSION = Constants?.manifest?.version

const OuterContainer = styled(View)`
  ${({ theme: { space } }) => `
    flex-direction: column;
    margin-top: auto;
    width: ${space[5] + space[7]}px;
    padding: 0 ${space[4]}px;
    
`}
`

const Container = styled(View)`
  ${({ theme: { space, radii, colors } }) => `
    flex-direction: row;
    width: 100%;
    padding: ${space[3]}px;
    align-items: center;
    background: ${colors.darkBackground}98;
    border-radius: ${radii[3]}px;
`}
`

const NameContainer = styled(View)``

const HeaderText = styled(Text)`
  ${({ theme: { colors, fontSizes, space, fontWeights } }) => `
        font-weight: ${fontWeights.bold};
        margin-left: ${space[3]}px;
        font-size: ${fontSizes[4]}px;
        color: ${colors.menuItem};
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        max-width: 110px;
    `}
`
const SubText = styled(HeaderText)`
  ${({ theme: { fontWeights } }) => `
        font-weight: ${fontWeights.light};
        font-size: 13px;
    `}
`

const Spacer = styled(View)`
  ${({ theme: { space } }) => `
  height: ${space[2]}px;
  `}
`

const Header = ({ showPhoto = true }) => {
  // Hooks
  const { currentUser } = useCurrentUser()

  if (!currentUser) return null
  const { roles } = currentUser
  const { firstName, lastName, photo, companies } = currentUser.person

  return (
    <OuterContainer>
      <Container>
        {showPhoto && photo && (
          <CloudinaryAvatar publicId={photo.cloudinaryId} size={48} />
        )}
        <NameContainer>
          <HeaderText>{`${firstName} ${lastName}`}</HeaderText>
          {companies && companies.length ? (
            <SubText>{`${companies[0].name}`}</SubText>
          ) : null}
          {ENV && ENV !== 'production' && (
            <>
              <Spacer />
              <HeaderText>{'Roles'}</HeaderText>
              {roles.map(role => (
                <SubText key={role}>{role}</SubText>
              ))}
              <Spacer />
              <HeaderText>{'App Version'}</HeaderText>
              <SubText key={VERSION}>{VERSION}</SubText>
            </>
          )}
        </NameContainer>
      </Container>
    </OuterContainer>
  )
}

export default Header
