import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'

export const deleteCallForSubmissionMutation = gql`
  mutation deleteCallForSubmission($id: String!) {
    deleteCallForSubmission(id: $id)
  }
`

const useDeleteCallForSubmissionMutation = (options = {}) => {
  const [deleteCallForSubmissionM, { loading }]: any[] = useMutation(
    deleteCallForSubmissionMutation
  )

  const deleteCallForSubmission = async callForSubmissionId => {
    const results = await deleteCallForSubmissionM({
      variables: {
        id: callForSubmissionId
      },
      ...options
    })

    return results
  }

  return [deleteCallForSubmission, loading]
}

export default useDeleteCallForSubmissionMutation
