import gql from 'graphql-tag'

import useMutation from '../../../hooks/useMutation'

export const createInnovatorProductMutation = gql`
  mutation createInnovatorProduct(
    $companyId: String!
    $submissionId: String
    $productName: String
    $delegationId: String
  ) {
    createInnovatorProduct(
      companyId: $companyId
      submissionId: $submissionId
      productName: $productName
      delegationId: $delegationId
    ) {
      id
      formId
      submissionId
    }
  }
`

const useCreateInnovatorProduct = companyId => {
  const [upsertInnovationProduct, { loading }]: any[] = useMutation(
    createInnovatorProductMutation,
    {
      refetchQueries: ['company'],
      awaitRefetchQueries: true
    }
  )

  const createInnovatorProduct = async (
    submissionId?: string,
    productName?: string,
    delegationId?: string | null
  ) => {
    const results = await upsertInnovationProduct({
      variables: {
        companyId,
        submissionId,
        productName,
        delegationId
      }
    })

    return results
  }

  return { createInnovatorProduct, loading }
}

export default useCreateInnovatorProduct
