import React, { useEffect, useRef, useMemo } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import EyeIcon from '../../../components/icon/EyeIcon'
import AlertIcon from '../../../components/icon/AlertIcon'
import Link from '../../../components/Link'
import idToCloudinaryUrl from '../../../utils/idToCloudinaryUrl'
import useTranslation from '../../../hooks/useTranslation'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

const PitchDeckPreview = ({ item }) => {
  const viewRef = useRef(null)
  const { t } = useTranslation()
  const { colors } = useTheme()
  const attachment = item?.attachment ?? item?.fileAttachment
  const status = useMemo(() => attachment?.moderationStatus, [item])
  const isSmallScreen = useIsSmallScreen()

  useEffect(() => {
    if (status === 'rejected') {
      viewRef?.current?.setNativeProps({ title: t('common:labels:malware') })
    }

    if (status === 'pending') {
      viewRef?.current?.setNativeProps({ title: t('common:labels:pending') })
    }
  }, [viewRef?.current])

  if (status === 'approved') {
    return (
      <Link
        to={idToCloudinaryUrl(attachment?.cloudinaryId, {
          resource_type: 'raw'
        })}
        title={t('common:labels:preview')}
        target="_blank"
      >
        <EyeIcon color={colors.lightGrey} />
      </Link>
    )
  }

  return (
    <View ref={viewRef}>
      {status === 'rejected' ? (
        <AlertIcon color={colors.danger} />
      ) : (
        <ActivityIndicator
          size="small"
          color={colors.primary}
          animating
          style={isSmallScreen ? { alignItems: 'flex-start' } : {}}
        />
      )}
    </View>
  )
}

export default PitchDeckPreview
