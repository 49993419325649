import gql from 'graphql-tag'

import { callForSubmissionQuery } from './useCallForSubmissionQuery'

import useTranslation from '../../../hooks/useTranslation'
import useMutation from '../../../hooks/useMutation'

export const upsertCallForSubmissionAdvisorSettingsMutation = gql`
  mutation upsertCallForSubmissionAdvisorSettings(
    $id: String
    $advisorMaxFav: Int
    $questionsWeights: [QuestionWeightInput]
  ) {
    upsertCallForSubmissionAdvisorSettings(
      id: $id
      advisorMaxFav: $advisorMaxFav
      questionsWeights: $questionsWeights
    ) {
      id
      advisorMaxFav
      reviewFormQuestions {
        id
        questionText
        type
        configData
        optionsValues
        style
        systemLabel
        weight
      }
    }
  }
`

const useUpsertCallForSubmissionAdvisorSettingsMutation = (cfsId: string) => {
  const { t } = useTranslation()

  const [upsertCallForSubmissionAdvisorSettings, { loading }] = useMutation(
    upsertCallForSubmissionAdvisorSettingsMutation,
    {
      refetchQueries: [
        {
          query: callForSubmissionQuery,
          variables: {
            id: cfsId,
            withSubmissionReviews: false,
            withSubmissionReviewsAverages: false,
            withInnovationSubmissions: true
          }
        }
      ],
      errorMessage: t('error:save:default')
    }
  )

  return {
    upsertCallForSubmissionAdvisorSettings,
    loading
  }
}

export default useUpsertCallForSubmissionAdvisorSettingsMutation
