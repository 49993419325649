import { useCallback, useEffect } from 'react'

import { FormikProps } from 'formik'
import {
  useFocusEffect,
  useNavigation,
  useRoute
} from '@react-navigation/native'
// Hooks
import useUnsavedAdvisorChanges from './useUnsavedAdvisorChanges'

const usePreventUnsavedChanges = (
  formik: FormikProps<any>,
  isReadOnly: boolean,
  loadingFormData: boolean
) => {
  const navigation = useNavigation()
  const route = useRoute()

  const {
    unsavedValues,
    shouldInitForm,
    hasUnsavedChanges,
    resetState,
    setHasUnsaved,
    setUnsavedAdvisorState
  } = useUnsavedAdvisorChanges()

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (
          !formik.dirty ||
          formik.isSubmitting ||
          isReadOnly ||
          hasUnsavedChanges
        )
          return
        setHasUnsaved(formik.dirty)
      }
    }, [
      navigation,
      formik.dirty,
      formik.isSubmitting,
      isReadOnly,
      hasUnsavedChanges
    ])
  )

  useEffect(() => {
    if (formik.dirty && !isReadOnly) {
      setUnsavedAdvisorState({
        unsavedValues: formik.values,
        formRouteState: route
      })
    }
  }, [formik.dirty, formik.values, isReadOnly, route])

  useEffect(() => {
    if (!isReadOnly && hasUnsavedChanges && !loadingFormData) {
      setTimeout(() => {
        resetState()
        if (shouldInitForm) {
          formik.setValues(unsavedValues)
        }
      }, 10)
    }
  }, [isReadOnly, hasUnsavedChanges, loadingFormData, shouldInitForm])
}

export default usePreventUnsavedChanges
