import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import AdviseSubmissions from './AdviseSubmissions'
import SubmissionDetails from '../Curation/SubmissionDetails'
import { AdviseNavigatorParamList } from '../../types/rootStackParamsList'

const AdviseStack = createStackNavigator<AdviseNavigatorParamList>()
const SubmissionNavigator = () => {
  return (
    <AdviseStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="AdviseSubmissions"
    >
      <AdviseStack.Screen
        name="AdviseSubmissions"
        component={AdviseSubmissions}
      />
      <AdviseStack.Screen
        name="AdviseSubmissionDetails"
        component={SubmissionDetails}
      />
    </AdviseStack.Navigator>
  )
}

export default SubmissionNavigator
