import React from 'react'

import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { RecoilRoot } from 'recoil'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider as ElementsProvider } from 'react-native-elements'
import { PopupMenuProvider } from './PopupMenuProvider'
import { ApolloProvider } from '@apollo/client'

import i18n from '../../i18n'
import elementsTheme from '../../themes/elements/theme'
import ThemeProvider from './ThemeProvider'
import API from '../../API'
import CurrentUserController from '../controllers/CurrentUserController'
import FeatureFlagController from '../../featureFlag/FeatureFlagController'
import SimulatorController from '../controllers/SimulatorController'

import AppCoordinator from '../../AppCoordinator'
import { AppStateProvider } from './AppStateProvider'
import GlobalStyles from './GlobalStyles'
import CurrentCompanyController from '../controllers/CurrentCompanyController'

const Providers = ({ children }: any) => {
  return (
    <RecoilRoot>
      <GlobalStyles />
      <ThemeProvider>
        <PopupMenuProvider>
          <ElementsProvider theme={elementsTheme}>
            <I18nextProvider i18n={i18n}>
              <SafeAreaProvider>
                <ApolloProvider client={API}>
                  <AppStateProvider>
                    <AppCoordinator />
                    <CurrentUserController />
                    <SimulatorController />
                    <FeatureFlagController />
                    <CurrentCompanyController />
                    {children}
                  </AppStateProvider>
                </ApolloProvider>
                <StatusBar />
              </SafeAreaProvider>
            </I18nextProvider>
          </ElementsProvider>
        </PopupMenuProvider>
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default Providers
