import React from 'react'

import { useTheme } from 'styled-components/native'
// Types
import {
  TableConfig,
  CellRendererProps,
  ColumnDefinition
} from '../../../../../components/Table'
import { TableNameEnum } from '../../../../../components/DynamicTable/types'
// Hooks
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Theme from '../../../../../constants/Theme'
import { Flex } from '../../../../../components/FlexBox'
import TouchableIcon, {
  TouchableIconProps
} from '../../../../../components/icon/TouchableIcon'
import { SmallRow } from '../../../../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../../../../components/Table/components/DynamicTable/SharedStyledComponents'
import { QuestionValues } from '../../types'
import { Content } from '../../../SharedStyledComponents'
// Types

// Styles
const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[2]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'center',
  textAlignVertical: 'center',
  width: '100%'
}
const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}
const cellTextStyle = { textAlign: 'center' }

// Columns
const questionNameColumn: ColumnDefinition<any> = {
  id: 'questionNameColumn',
  header: 'platformManagement:forms:menuItems:questions:questionText',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 20,
  emptyMessage: '',
  fixed: true,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item?.questionText || ''}
        style={{ textAlign: 'left', marginLeft: 50 }}
      />
    )
  }
}

const questionTypeColumn: ColumnDefinition<any> = {
  id: 'questionTypeColumn',
  header: 'platformManagement:forms:menuItems:questions:questionType',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 5,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item?.type || ''}
        style={cellTextStyle}
      />
    )
  }
}

const questionHelperTextColumn: ColumnDefinition<any> = {
  id: 'questionHelperTextColumn',
  header: 'platformManagement:forms:menuItems:questions:questionHelperText',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 30,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item?.configData?.helperText || ''}
        style={cellTextStyle}
      />
    )
  }
}

const questionEditColumn = ({ handleEditQuestion }) => ({
  id: 'questionEditColumn',
  header: 'platformManagement:forms:menuItems:questions:edit',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 5,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors, sizes } = useTheme()
    const commonProps: TouchableIconProps = {
      name: 'pencil',
      width: 22,
      height: 22,
      color: colors.darkGradientBackground,
      style: { alignSelf: 'center', marginHorizontal: sizes[1] }
    }

    return (
      <Flex flexDirection="row" justifyContent="center">
        <TouchableIcon
          {...commonProps}
          onPress={() => {
            handleEditQuestion(item)
          }}
        />
      </Flex>
    )
  }
})

const questionDeleteColumn = ({ handleDeleteQuestion }) => ({
  id: 'questionDeleteColumn',
  header: 'platformManagement:forms:menuItems:questions:delete',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 5,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors, sizes } = useTheme()
    const commonProps: TouchableIconProps = {
      name: 'delete',
      width: 22,
      height: 22,
      color: colors.darkGradientBackground,
      style: { alignSelf: 'center', marginHorizontal: sizes[1] }
    }

    return (
      <Flex flexDirection="row" justifyContent="center">
        <TouchableIcon
          {...commonProps}
          onPress={() => {
            handleDeleteQuestion(item)
          }}
        />
      </Flex>
    )
  }
})

export const QUESTIONS_MNG_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: TableNameEnum.QuestionsManagementTable,
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

interface getQuestionsManagementTableArgs {
  handleEditQuestion: (question: QuestionValues) => void
  handleDeleteQuestion: (question: QuestionValues) => void
}

export const getQuestionsManagementTableConfig = ({
  handleEditQuestion,
  handleDeleteQuestion
}: getQuestionsManagementTableArgs) => {
  const table = Object.assign(
    {},
    QUESTIONS_MNG_TABLE_CONFIG
  ) as TableConfig<any>

  let columns = [
    questionNameColumn,
    questionTypeColumn,
    questionHelperTextColumn,
    questionEditColumn({
      handleEditQuestion
    }),
    questionDeleteColumn({
      handleDeleteQuestion
    })
  ]
  table.columns = columns
  table.showTotalElements = true

  return table
}
