import { atomFamily, selectorFamily, CallbackInterface } from 'recoil'
import { QuestionDependency } from '../types'
import { reject, isNil } from 'ramda'

const removeNil = reject(isNil)

export const questionDependencyAtomFamily = atomFamily<
  QuestionDependency | null,
  string
>({
  key: 'questionDependencyState',
  default: null
})

export const getQuestionDependencies = selectorFamily<
  QuestionDependency[],
  string[]
>({
  key: 'getQuestionDependenciesSelector',
  get: (ids: string[]) => ({ get }) => {
    return removeNil(ids.map(id => get(questionDependencyAtomFamily(id))))
  }
})

export const setQuestionDependencyCallback = ({ set }: CallbackInterface) => (
  questionDependencies: QuestionDependency[]
) => {
  for (const questionDependency of questionDependencies) {
    set(questionDependencyAtomFamily(questionDependency.id), questionDependency)
    set(
      questionDependencyManagerAtom(questionDependency.id),
      initQuestionDependencyManager(questionDependency)
    )
  }
}

/**
 * Visible represents the visibility of the child or question2
 * of a questionDependency
 * A parent that is also a child, like
 * questionTwo in questionOne -> questionTwo -> questionThree,
 * will look to it's parent to determine visibilty.
 */
export interface QuestionDependencyManagerState extends QuestionDependency {
  visible: boolean
  answer: any
}

/**
 * Question Dependency Manager Atom family
 * Key => questionDependency.id
 */
export const questionDependencyManagerAtom = atomFamily<
  QuestionDependencyManagerState | null,
  string
>({
  key: 'questionDependencyManagerState',
  default: null
})

/**
 * Get QuestionDependencyManagers from a list of
 * QuestionDependency id's
 */
export const getQuestionDependencyManagers = selectorFamily<
  QuestionDependencyManagerState[],
  string[]
>({
  key: 'getQuestionDependencyManagersSelector',
  get: (ids: string[]) => ({ get }) => {
    return ids.map(id => get(questionDependencyManagerAtom(id)))
  }
})

/**
 * Setter function to be used with useRecoilCallback
 */
export const setQuestionDependencyManagerState = ({
  set
}: CallbackInterface) => (state: QuestionDependencyManagerState) => {
  set(questionDependencyManagerAtom(state.id), state)
}

const initQuestionDependencyManager = (
  qd: QuestionDependency
): QuestionDependencyManagerState => {
  return {
    ...qd,
    answer: null,
    visible: !qd?.value?.shouldHide
  }
}
