import React, { useContext, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/native'
import useTranslation from '../../../../hooks/useTranslation'
import { DashboardContext } from '../hooks/DashboardProvider'
import { groupAndCreateCategories } from '../common/utils'
import Card from '../common/Card'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { Text } from '../../../../components/common/Text'
import { TopQuestionsEnum } from './types'
import BarChart from './BarChart'

interface FilterBadgeProps {
  topQuestion: TopQuestionsEnum
  currentTopQuestion: TopQuestionsEnum
  setTopQuestion: (value: TopQuestionsEnum) => void
  setLoading: (value: boolean) => void
}

const FilterBadge = (props: FilterBadgeProps) => {
  const { topQuestion, setTopQuestion, setLoading, currentTopQuestion } = props
  const { colors } = useTheme()
  const filterStyles = {
    fontSize: 10,
    marginHorizontal: 2,
    paddingHorizontal: 7,
    paddingVertical: 3,
    marginBottom: 5,
    borderColor: colors.tints.grays.g100,
    borderWidth: 1,
    borderRadius: 10
  }
  return (
    <TouchableOpacity
      onPress={() => {
        if (topQuestion !== currentTopQuestion) {
          setLoading(true)
          setTopQuestion(currentTopQuestion)
        }
      }}
    >
      <Text
        style={{
          ...filterStyles,
          backgroundColor:
            topQuestion === currentTopQuestion
              ? colors.tints.grays.g100
              : undefined
        }}
      >
        {currentTopQuestion}
      </Text>
    </TouchableOpacity>
  )
}

const Distribution = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { funding, userBase } = useContext(DashboardContext)
  const [question, setQuestion] = useState<TopQuestionsEnum>(
    TopQuestionsEnum.Funding
  )

  const questionData = useMemo(() => {
    let data: any[] = []
    switch (question) {
      case TopQuestionsEnum.Funding:
        data = funding
        break
      case TopQuestionsEnum.UserBase:
        data = userBase
        break
      default:
        return []
    }
    const filteredData = data.length ? groupAndCreateCategories(data, 9) : []
    return filteredData
  }, [question, funding, userBase])

  const chart = useMemo(() => {
    setLoading(false)
    return questionData.map(({ count, name }) => ({
      value: count,
      title: name
    }))
  }, [questionData, loading])

  const baseNumberByType = {
    [TopQuestionsEnum.Funding]: 1000000,
    [TopQuestionsEnum.UserBase]: 1000000
  }

  const baseUnitByType = {
    [TopQuestionsEnum.Funding]: 'M',
    [TopQuestionsEnum.UserBase]: 'M'
  }
  const basePrefixByType = {
    [TopQuestionsEnum.Funding]: '$',
    [TopQuestionsEnum.UserBase]: ''
  }

  const formatLabel = (label: string) => {
    try {
      const labelNumber = parseInt(
        (parseInt(label) / baseNumberByType[question]).toFixed(0)
      )
      if (isNaN(labelNumber)) {
        return `-`
      }
      return `${basePrefixByType[question]}${labelNumber}${baseUnitByType[question]}`
    } catch (error) {
      return `-`
    }
  }

  return (
    <Card
      minWidth={'450px'}
      styles={{ flex: 1 }}
      title={t('dashboard:components:distribution:title')}
    >
      <View style={{ flexDirection: 'row', width: '100%', height: '10%' }}>
        {[TopQuestionsEnum.Funding, TopQuestionsEnum.UserBase].map(
          currentTopQuestion => (
            <FilterBadge
              currentTopQuestion={currentTopQuestion}
              setTopQuestion={setQuestion}
              topQuestion={question}
              setLoading={setLoading}
            />
          )
        )}
      </View>
      {loading && chart.length ? (
        <ActivityIndicator size={'small'} />
      ) : (
        <BarChart formatLabel={formatLabel} type={question} values={chart} />
      )}
    </Card>
  )
}

export default Distribution
