import React from 'react'
import styled, { useTheme } from 'styled-components/native'
// Hooks
import useTranslation from '../../../../../../hooks/useTranslation'
// Components
import Modal from '../../../../../../components/common/Modal'
// Types
import { ModalContainer, Title } from '../../../../constants'
import { DeleteQuestionModalProps } from '../../../types'
import { View } from 'react-native'
import { ModalCloseIcon } from '../../../../../../components/DynamicTable/components/common/ModalDialog/SharedStyledComponents'
import { Flex } from '../../../../../../components/FlexBox'
import Button from '../../../../../../ui-library/Button'
import { Text } from '../../../../../../components/common/Text'
import useDeleteFormQuestion from '../hooks/deleteFormQuestion'
import useToast from '../../../../../../hooks/useToast'
import { isEmpty } from 'ramda'

const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[3]}px;
  `}
`

const containerStyle = {
  flexBasis: '48%'
}
const buttonStyle = {
  width: '100%'
}

const DeleteModal = (props: DeleteQuestionModalProps) => {
  const { isOpen, onClose, question, formId } = props
  // Hooks
  const { t } = useTranslation()
  const { space, colors } = useTheme()
  const { setToastErrorMessage, setToastMessage } = useToast()

  // Mutations
  const [deleteFormQuestion, isLoading] = useDeleteFormQuestion({
    onError: e => {
      console.error(e)
      setToastErrorMessage(
        e?.message ??
          t('platformManagement:forms:menuItems:questions:errorSave')
      )
      onClose()
    },
    onCompleted: ({ deleteFormQuestion }) => {
      if (deleteFormQuestion && !isEmpty(deleteFormQuestion)) {
        setToastMessage(
          t('platformManagement:forms:menuItems:questions:successSaved')
        )
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:questions:errorSave')
        )
      }
      onClose()
    },
    errorMessage: t('platformManagement:questions:errorSave')
  })

  const handleDeleteQuestion = async () => {
    await deleteFormQuestion({
      variables: {
        questionId: question?.id,
        formId: formId
      },
      refetchQueries: ['getQuestions']
    })
  }

  return (
    <Modal
      close={onClose}
      open={isOpen}
      styles={{ minWidth: '350px', maxWidth: '450px', padding: '15px 24px' }}
    >
      <ModalCloseIcon onPress={onClose} />
      <Flex padding={space[3]}>
        <Title>
          {t(`platformManagement:forms:menuItems:questions:deleteQuestion`)}
        </Title>

        <ModalContainer style={{ marginTop: 10 }}>
          <View style={{ width: '100%', flexDirection: 'column' }}>
            <Description>
              {t(
                'platformManagement:forms:menuItems:questions:deleteQuestionDescription'
              )}
            </Description>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              style={{
                marginTop: space[3]
              }}
            >
              <Button
                title={t('callsForSubmission:dialog:cancelBtn')}
                type="outline"
                onPress={onClose}
                containerStyle={containerStyle}
                buttonStyle={buttonStyle}
              />
              <Button
                title={t('product:general:delete')}
                type="solid"
                onPress={handleDeleteQuestion}
                containerStyle={containerStyle}
                buttonStyle={{ ...buttonStyle, backgroundColor: colors.danger }}
                isProcessing={isLoading}
              />
            </Flex>
          </View>
        </ModalContainer>
      </Flex>
    </Modal>
  )
}

export default DeleteModal
