import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const verifyAuthCodeMutation = gql`
  mutation verifyAuthenticationCode($email: String!, $code: String!) {
    verifyAuthenticationCode(email: $email, code: $code)
  }
`

interface MutationProps {
  onCompleted?: (results: any) => void
  onError?: (error: any) => void
}

const useVerifyAuthCode = (options: MutationProps) => {
  const [verifyCode, { loading }]: any[] = useMutation(
    verifyAuthCodeMutation,
    options
  )

  return [verifyCode, loading]
}

export default useVerifyAuthCode
