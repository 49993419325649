import React, { FC, useEffect, useState } from 'react'

import withNavigationPermissions from '../../navigation/withNavigationPermissions'

import { ScreenContainerWithTabs } from '../../components/layout/ScreenContainer'
import { DynamicTable, useTable } from '../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'

import { Container } from '../CallForSubmission/components/SharedComponents'
import { TableContainer } from '../../components/StaticForm'

import { getSubmissionTableConfig } from './constants'

import useTranslation from '../../hooks/useTranslation'
import { EngineEnum } from '../CallForSubmission/constants'
import { SubmissionsProps } from './Precuration'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'
import { InnovationAccessKey } from '../../types'
import { Flex } from '../../components/FlexBox'
import { StyledLabel } from '../../ui-library/TextInput/Label'
import Dropdown, { DropdownValue } from '../../components/common/Dropdown'
import useCategoriesByMarketSegmentQuery from '../CallForSubmission/hooks/useCategoriesByMarketSegmentQuery'
import { Option } from '../../components/DynamicForm/DropDown'
import { ViewStyle } from 'react-native'

const SubmissionsScreen: FC<SubmissionsProps> = ({
  id,
  role,
  currentCFSStep,
  callForSubmission,
  canEditAll
}) => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { t } = useTranslation()
  const {
    loading: loadingCategories,
    innovationCategories = []
  } = useCategoriesByMarketSegmentQuery({
    marketSegmentId: callForSubmission?.marketSegments?.length
      ? callForSubmission?.marketSegments[0].id
      : undefined
  })
  const [
    categoryFilteredBy,
    setCategoryFilterBy
  ] = useState<DropdownValue | null>(null)
  const [statusFilteredBy, setStatusFilterBy] = useState<DropdownValue | null>(
    null
  )
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([])
  const statusOptions: Option[] = [
    {
      label: t('callsForSubmission:innovatorSubmissionStatus:incomplete'),
      value: 'false'
    },
    {
      label: t('callsForSubmission:innovatorSubmissionStatus:submitted'),
      value: 'true'
    }
  ]

  useEffect(() => {
    if (!loadingCategories && innovationCategories.length) {
      setCategoryOptions(
        innovationCategories.map(ic => ({ label: ic.name, value: ic.id }))
      )
    }
  }, [loadingCategories, innovationCategories])

  const tableProps = useTable({
    config: getSubmissionTableConfig({
      cfsId: id,
      role,
      currentCFSStep,
      engine: EngineEnum.InnovationSubmission,
      isInnovationNewDynamicTable,
      categoryFilteredBy: (categoryFilteredBy?.length
        ? categoryFilteredBy
        : []) as string[],
      statusFilteredBy: (statusFilteredBy as string) ?? undefined,
      canEditAll
    })
  })

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const categoryStyle: ViewStyle = {
    minHeight: 0,
    marginLeft: 16,
    marginBottom: 0,
    maxWidth: '25vw'
  }
  const statusStyle: ViewStyle = {
    minHeight: 0,
    marginLeft: 32,
    marginBottom: 0
  }
  const controlStyle: ViewStyle = { minHeight: 0 }

  return (
    <ScreenContainerWithTabs>
      <Container>
        <Flex flexDirection="row" alignItems="center" marginBottom={16}>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
          <Dropdown
            testID={'categoryFilter'}
            name="categoryFilter"
            value={categoryFilteredBy}
            placeholder={t('curation:submissions:table:category')}
            options={categoryOptions}
            onSelect={setCategoryFilterBy}
            isMulti
            isFilter
            isClearable
            style={categoryStyle}
            controlStyle={controlStyle}
            menuPortalTarget={document?.body}
          />
          <Dropdown
            testID={'statusFilter'}
            name={'statusFilter'}
            value={statusFilteredBy}
            placeholder={t('curation:submissions:table:status')}
            options={statusOptions}
            onSelect={setStatusFilterBy}
            isFilter
            isClearable
            style={statusStyle}
            controlStyle={controlStyle}
            menuPortalTarget={document?.body}
          />
        </Flex>
        <TableContainer height="100%">
          <TableComponent
            {...tableProps}
            emptyMessage={t('curation:submissions:noSubmissions')}
          />
        </TableContainer>
      </Container>
    </ScreenContainerWithTabs>
  )
}

const Submissions = withNavigationPermissions(SubmissionsScreen, [
  InnovationAccessKey.CURATE,
  InnovationAccessKey.ADVISE
])

export default Submissions
