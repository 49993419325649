import React, { FC, useMemo, useState } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { useFormik } from 'formik'

import useTranslation from '../../../hooks/useTranslation'
import useInviteTeamMemberMutation from '../hooks/useInviteTeamMemberMutation'

import Modal from '../../../components/common/Modal'
import TouchableIcon from '../../../components/icon/TouchableIcon'

import { Title } from './SharedStyledComponents'
import { Company, RoleEnum, TEAM_MEMBER_ROLES, User } from '../../../types'
import TextInput from '../../../ui-library/TextInput'
import Dropdown from '../../../components/common/Dropdown'
import Button from '../../../ui-library/Button'
import { INNOVATOR_TEAM_PERMISSIONS } from '../../../constants/roles'
import theme from '../../../constants/Theme'
import Sentry from '../../../utils/sentry'
import {
  DEFAULT_TOAST_MESSAGE,
  Message,
  MessageStatus
} from '../../../recoil/toastMessageAtom'
import useUpsertInnovationUserMutation from '../hooks/useUpsertUserMutation'
import useGetInviteValidationSchema from '../hooks/useGetInviteValidationSchema'
import CheckBox from '../../../ui-library/CheckBox'
import ModalToast from './ModalToast'
import useCurrentUser, { CurrentUserHook } from '../../../hooks/useCurrentUser'

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

const CommsTitle = styled(Text)`
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: ${theme.sizes[2]};
`

const buttonPadding = {
  vertical: theme.space[1] + theme.space[2],
  horizontal: theme.space[2] + theme.space[3]
}
const formFieldStyles = { maxWidth: theme.breakpoints.phone, width: '100%' }
const formFieldContainerStyles = { marginBottom: '2%' }

const btnContainerStyles = {
  height: theme.space[4],
  marginTop: theme.space[1] + theme.space[3]
}
const btnStyles = {
  width: 'fit-content',
  paddingTop: buttonPadding.vertical,
  paddingBottom: buttonPadding.vertical,
  paddingLeft: buttonPadding.horizontal,
  paddingRight: buttonPadding.horizontal,
  height: theme.space[4]
}

interface InviteDialogProps {
  user?: User
  company: Company
  title: string
  isOpen: boolean
  onClose: () => void
  children?: JSX.Element | JSX.Element[]
  isPending?: boolean
  testID?: string
}

const commsPreferences = {
  [RoleEnum.InnovatorTeamMember]: ['innovatorCommsCFS'],
  [RoleEnum.InnovatorTeamAdmin]: [
    'innovatorCommsPlatformAdmin',
    'innovatorCommsCFS',
    'innovatorCommsCFSSelectionAndPresentation',
    'innovatorCommsInvoicing'
  ]
}

const ALL_PREFERENCES = [
  ...new Set([
    ...commsPreferences[RoleEnum.InnovatorTeamAdmin],
    ...commsPreferences[RoleEnum.InnovatorTeamMember]
  ])
]

const InviteDialog: FC<InviteDialogProps> = ({
  title,
  isOpen,
  onClose,
  company,
  user,
  isPending = false,
  testID
}) => {
  const { t } = useTranslation()
  const { colors, space, breakpoints } = useTheme()
  const {
    schema,
    loading: loadingRegistrationData
  } = useGetInviteValidationSchema()

  const {
    isAdmin,
    isInnovatorTeamAdmin,
    isInnovationStaff,
    isInnovationAdmin,
    currentUserId
  }: CurrentUserHook = useCurrentUser()

  const [
    inviteTeamMember,
    loadingInviteMember,
    resendInvite,
    loadingResend
  ] = useInviteTeamMemberMutation(company?.id)
  const [
    upsertInnovationUser,
    loadingUpsertInnovationUser
  ] = useUpsertInnovationUserMutation()

  const [toastMessage, setToastMessage] = useState<Message | null>(null)

  const handleClose = () => {
    setToastMessage(DEFAULT_TOAST_MESSAGE)
    onClose && onClose()
  }

  const roleOptions = useMemo(
    () =>
      INNOVATOR_TEAM_PERMISSIONS.map(val => ({
        label: t(`settings:members:roles:${val}`),
        value: val
      })),
    []
  )

  const initialValues = {
    firstName: user?.person.firstName ?? '',
    lastName: user?.person.lastName ?? '',
    jobTitle: user?.person.jobTitle ?? '',
    email:
      (user?.person.emailAddresses && user?.person.emailAddresses[0].email) ??
      '',
    role:
      (user?.roles &&
        user?.roles.find(role => TEAM_MEMBER_ROLES.includes(role))) ??
      '',
    innovatorCommsPlatformAdmin:
      user?.settings?.innovatorCommsPlatformAdmin ?? false,
    innovatorCommsCFS: user?.settings?.innovatorCommsCFS ?? false,
    innovatorCommsCFSSelectionAndPresentation:
      user?.settings?.innovatorCommsCFSSelectionAndPresentation ?? false,
    innovatorCommsInvoicing: user?.settings?.innovatorCommsInvoicing ?? false
  }
  const {
    resetForm,
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
    dirty
  } = useFormik({
    onSubmit: async (values, { resetForm }) => {
      let toastMsg = t('settings:members:form:memberInvited')
      // @ts-ignore
      try {
        let results

        if (user) {
          if (isPending) {
            results = await resendInvite({ userId: user.id })
          } else {
            toastMsg = t('settings:members:form:memberUpdated')
            results = await upsertInnovationUser({
              id: user.id,
              role: values.role,
              firstName: values.firstName,
              lastName: values.lastName,
              jobTitle: values.jobTitle,
              innovatorCommsPlatformAdmin: values.innovatorCommsPlatformAdmin,
              innovatorCommsCFS: values.innovatorCommsCFS,
              innovatorCommsCFSSelectionAndPresentation:
                values.innovatorCommsCFSSelectionAndPresentation,
              innovatorCommsInvoicing: values.innovatorCommsInvoicing
            })
          }
        } else {
          results = await inviteTeamMember(values)
        }

        if (results.data) {
          setToastMessage({ message: toastMsg, status: MessageStatus.Success })
          // @ts-ignore
          resetForm(initialValues)
        }
      } catch (error: any) {
        console.error(error)
        Sentry.captureException(error)
        let message = t('error:defaultMutation')

        if (error?.message?.includes('User already exists')) {
          message = t('settings:members:form:error')
        } else if (error?.message?.includes('is not claimable')) {
          message = t('settings:members:form:emailDomainNotClaimable')
        }

        setToastMessage({ message, status: MessageStatus.Error })
      }
    },
    validationSchema: schema,
    initialValues,
    enableReinitialize: true
  })

  const btnLabel = user
    ? isPending
      ? t('settings:members:form:resendInvite')
      : t('settings:members:form:update')
    : t('settings:members:form:invite')

  // Enable edition by permissions
  const basicInfoEditable: boolean =
    !isPending &&
    (isAdmin ||
      isInnovatorTeamAdmin ||
      isInnovationStaff ||
      isInnovationAdmin ||
      currentUserId === user?.id)

  const roleEditable: boolean =
    currentUserId !== user?.id &&
    !isPending &&
    (isAdmin || isInnovatorTeamAdmin || isInnovationStaff || isInnovationAdmin)

  return (
    <Modal
      close={handleClose}
      open={isOpen}
      styles={{
        minWidth: '550px',
        padding: '15px 24px'
      }}
      testID={testID}
    >
      <Title testID="edit_member_modal_title">{title}</Title>
      <CloseIcon testID="edit_member_modal_close_btn" onPress={handleClose} />

      {loadingInviteMember ||
      loadingResend ||
      loadingUpsertInnovationUser ||
      loadingRegistrationData ? (
        <View>
          <ActivityIndicator size="large" />
        </View>
      ) : (
        <form style={{ marginTop: space[3], maxWidth: breakpoints.tablet }}>
          {toastMessage?.message ? (
            <ModalToast
              testID="edit_member_toast_message"
              message={toastMessage}
            />
          ) : null}
          <TextInput
            containerStyles={formFieldContainerStyles}
            errorMessage={errors.email}
            label={t('settings:members:form:email')}
            style={formFieldStyles}
            name="email"
            testID="edit_member_email_input"
            value={values.email}
            onChange={handleChange}
            isReadOnly={!!user}
          />

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              maxWidth: 640
            }}
          >
            <TextInput
              errorMessage={errors.firstName}
              label={t('settings:members:form:firstName')}
              name="firstName"
              testID="edit_member_first_name_input"
              value={values.firstName}
              onChange={handleChange}
              containerStyles={{ width: '49%' }}
              style={{ width: '100% ' }}
              isReadOnly={!basicInfoEditable}
            />
            <TextInput
              errorMessage={errors.lastName}
              label={t('settings:members:form:lastName')}
              name="lastName"
              testID="edit_member_last_name_input"
              value={values.lastName}
              onChange={handleChange}
              containerStyles={{ width: '49%' }}
              style={{ width: '100% ' }}
              isReadOnly={!basicInfoEditable}
            />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              maxWidth: 640
            }}
          >
            <TextInput
              errorMessage={errors.jobTitle}
              label={t('settings:members:form:jobTitle')}
              name="jobTitle"
              testID="edit_member_job_title_input"
              value={values.jobTitle}
              onChange={handleChange}
              containerStyles={{ width: '49%' }}
              style={{ width: '100% ' }}
              isReadOnly={!basicInfoEditable}
            />
            <Dropdown
              label={t('settings:members:form:role')}
              name="role"
              testID="edit_member_role_dropdown"
              placeholder={t('settings:members:form:select')}
              containerStyle={{ width: '49%' }}
              style={formFieldStyles}
              value={values.role}
              options={roleOptions}
              onChange={(name, val) => {
                if (!user) {
                  // first reset values before applying the new ones based on Role
                  ALL_PREFERENCES.forEach(field => setFieldValue(field, false))

                  commsPreferences[val].forEach(field =>
                    setFieldValue(field, true)
                  )
                } else {
                  // JC: if original role was member and passes to admin we set the platformAdmin checkbox to true
                  // if it was an admin and we change it to be a member we uncheck the platformAdmin checkbox
                  setFieldValue(
                    'innovatorCommsPlatformAdmin',
                    val === RoleEnum.InnovatorTeamAdmin
                  )
                }
                setFieldValue(name, val)
              }}
              isReadOnly={!roleEditable}
              menuPortalTarget={document?.body}
            />
          </View>

          <View style={{ marginTop: space[3] }}>
            <CommsTitle>
              {t('settings:members:form:preferences:title')}
            </CommsTitle>
            {ALL_PREFERENCES.map((preference, i) => (
              <CheckBox
                key={`commsPreferences-${preference}-${i}`}
                testID={`edit_member_${preference}_checkbox`}
                label={t(`settings:members:form:preferences:${preference}`)}
                checked={values[preference]}
                onPress={() => setFieldValue(preference, !values[preference])}
                isReadOnly={
                  isPending ||
                  (values.role === RoleEnum.InnovatorTeamMember &&
                    preference === 'innovatorCommsPlatformAdmin')
                }
              />
            ))}
          </View>

          <div
            style={{
              display: 'flex',
              marginTop: space[3],
              justifyContent: 'flex-end'
            }}
          >
            <Button
              title={t('settings:members:form:cancel')}
              type="outline"
              buttonStyle={{
                ...btnStyles,
                borderColor: colors.buttonVariant
              }}
              titleStyle={{
                color: colors.buttonVariant
              }}
              containerStyle={{
                ...btnContainerStyles,
                marginRight: space[3]
              }}
              onPress={() => {
                resetForm()
                handleClose()
              }}
            />
            <Button
              title={btnLabel}
              buttonStyle={btnStyles}
              containerStyle={btnContainerStyles}
              onPress={() => handleSubmit()}
              disabled={isPending ? !isValid : !dirty || !isValid}
            />
          </div>
        </form>
      )}
    </Modal>
  )
}

export default InviteDialog
