import React, { useImperativeHandle, forwardRef, useState } from 'react'
import useTranslation from '../../../hooks/useTranslation'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

import TableHeaderCell from './TableHeaderCell'
import {
  TableRowContainer,
  TableCell,
  TableContainer,
  TableFlatList,
  TableText
} from './SharedStyledComponents'
import TableRow from './TableRow'
import TableRowEmpty from './TableRowEmpty'
import { TableProps } from '../types'
import { H4 } from '../../common/Text'
import { Flex } from '../../FlexBox'
import useSearchQuery from '../hooks/useSearchQuery'
import { getInitialSortState } from './constants'
import SkeletonContainer from '../../skeletonLoadings/SkeletonContainer'
import TableSkeleton from '../../../screens/HomeScreen/TableSkeleton'

const getInitialFilters = columns => {
  return columns
    ?.filter(({ filter }) => filter && filter.defaultValue !== undefined)
    ?.reduce(
      (acc, { filter: { key, defaultValue } }) => ({
        ...acc,
        [key]: defaultValue
      }),
      {}
    )
}

function TableComponent<T>(
  {
    config,
    keyExtractor = defaultKeyExtractor,
    data,
    loading,
    error,
    refetch,
    isReadOnly,
    emptyMessage,
    EmptyComponent
  }: TableProps<T>,
  ref
) {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  let {
    name,
    headerContainerStyle,
    RowWrapper,
    columns,
    enableFilters = false,
    hitKey,
    engine,
    pageSize = 10,
    filters,
    queryConditions
  } = config
  useImperativeHandle(ref, () => ({ refetch }))

  const initialSortState = getInitialSortState(columns) || ['', 'asc']
  const [[sortBy, sortDir], updateSort] = useState(initialSortState)
  const [queryFilters, setQueryFilters] = useState({})
  const [searchFilters, setSearchFilters] = useState({
    ...getInitialFilters(columns)
  })

  const handleOnSort = newSortBy => {
    let newSortDir
    if (newSortBy === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      newSortDir = 'asc'
    }
    updateSort([newSortBy, newSortDir])
  }

  const {
    data: searchData,
    loading: searchLoading,
    fetchMore
  } = useSearchQuery({
    enableFilters,
    pageSize,
    sortBy,
    sortDir,
    filters,
    queryFilters,
    searchFilters,
    engine,
    queryConditions
  })

  const items = searchData
    ? hitKey
      ? searchData?.searchInnovationEngine?.hits?.map(hit => hit[hitKey])
      : searchData?.searchInnovationEngine?.hits
    : []

  const fetchMoreResults = () => {
    if ((enableFilters && (!items?.length || searchLoading)) || !enableFilters)
      return

    const offset = Math.ceil(items?.length / pageSize) + 1

    fetchMore({
      variables: {
        offset,
        size: pageSize,
        sort: [{ sortBy, sortDir }],
        engine
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newHits = fetchMoreResult?.searchInnovationEngine?.hits || []
        if (newHits.length === 0) return prev
        return {
          ...prev,
          searchInnovationEngine: {
            ...(prev?.searchInnovationEngine || {}),
            hits: [...(prev?.searchInnovationEngine?.hits || []), ...newHits]
          }
        }
      }
    })
  }

  if (!enableFilters && !data?.length && emptyMessage) {
    return (
      <Flex
        flexDirection="row"
        justifyContent="center"
        marginTop={20}
        width="100%"
      >
        <H4>{emptyMessage}</H4>
      </Flex>
    )
  }

  if (enableFilters && EmptyComponent && !searchLoading && !items?.length) {
    return <EmptyComponent />
  }

  return (
    <TableContainer>
      {error && <TableText>{t('error:errorLoadingItems')}</TableText>}
      <TableFlatList
        key={name}
        data={enableFilters ? items : data}
        keyExtractor={keyExtractor}
        ListEmptyComponent={
          <SkeletonContainer
            Skeleton={TableSkeleton}
            skeletonProps={{ numberOfRows: 3, showHeader: false }}
            isLoading={loading || searchLoading}
          >
            <TableRowEmpty config={config} />
          </SkeletonContainer>
        }
        ListHeaderComponent={
          !isSmallScreen && (
            <TableRowContainer
              marginLeft={headerContainerStyle?.marginLeft}
              marginRight={headerContainerStyle?.marginRight}
              marginBottom={headerContainerStyle?.marginBottom}
            >
              {columns.map(
                (
                  { sort, filter, header, width, headerStyle = {}, ...rest },
                  i
                ) => {
                  return (
                    <TableCell width={width} key={`${header}_${i}`}>
                      <TableHeaderCell
                        enableFilters={enableFilters}
                        handleChangeSort={handleOnSort}
                        orderBy={sortBy}
                        orderDir={sortDir}
                        filter={filter}
                        sort={sort}
                        header={header as string}
                        headerStyle={headerStyle}
                        handleChangeSearchFilters={value =>
                          filter?.key &&
                          setSearchFilters({
                            ...searchFilters,
                            [filter.key]: value
                          })
                        }
                        handleChangeFilters={value =>
                          filter?.key &&
                          setQueryFilters({
                            ...queryFilters,
                            [filter.key]: value
                          })
                        }
                        queryFilter={queryFilters}
                        {...rest}
                      />
                    </TableCell>
                  )
                }
              )}
            </TableRowContainer>
          )
        }
        renderItem={({ item, index }) => {
          if (RowWrapper) {
            return (
              <RowWrapper item={item}>
                <TableRow
                  item={item}
                  index={index}
                  config={config}
                  isReadOnly={isReadOnly}
                />
              </RowWrapper>
            )
          }
          return (
            <TableRow
              item={item}
              index={index}
              config={config}
              isReadOnly={isReadOnly}
            />
          )
        }}
        onEndReached={fetchMoreResults}
      />
    </TableContainer>
  )
}

const defaultKeyExtractor = (item: any, i: any) => `${item?.id}_${i}`

// @ts-ignore
export default forwardRef(TableComponent)
