import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { AdvisorInfo } from '../../../types'

export const cfsAdvisorQuery = gql`
  query advisorInfo($cfsId: String!, $advisorId: String!) {
    advisorInfo(cfsId: $cfsId, advisorId: $advisorId, filterIncomplete: true) {
      id
      userInfo {
        person {
          firstName
          lastName
        }
      }
      totalAdvised
      submissionsToReview {
        submissionId
      }
    }
  }
`

interface CFSObject {
  advisorInfo: AdvisorInfo
  loading: boolean
}

const useAdvisorQuery = (cfsId, advisorId, skip?: boolean): CFSObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(cfsAdvisorQuery, {
    variables: {
      cfsId,
      advisorId
    },
    skip: !cfsId || !advisorId || skip,
    errorMessage: t('error:advisor:info'),
    fetchPolicy: 'cache-and-network'
  })

  return {
    advisorInfo: data?.advisorInfo,
    loading
  }
}

export default useAdvisorQuery
