import React, { useContext } from 'react'
import { TableContext } from '../../../hooks/TableProvider'
import Row from './Row'
import { RowWrapperProps } from '../types'

const RowWrapper = (props: RowWrapperProps): any => {
  const {
    config: { RowWrapper: ItemRowWrapper }
  } = useContext(TableContext)

  const { item, index, leftTable } = props

  return ItemRowWrapper ? (
    <ItemRowWrapper item={item}>
      <Row index={index} item={item} leftTable={leftTable} />
    </ItemRowWrapper>
  ) : (
    <Row index={index} item={item} leftTable={leftTable} />
  )
}

export default RowWrapper
