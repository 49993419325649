import { Flex } from '../FlexBox'
import styled from 'styled-components/native'

export default styled(Flex).attrs(
  ({ theme: { colors, space, radii, borderWidths } }) => ({
    borderColor: colors.inputBorder,
    borderWidth: borderWidths[1],
    borderRadius: radii[1],
    borderStyle: 'solid',
    p: `${space[3]}px`,
    my: `${space[3]}px`,
    w: '100%'
  })
)``
