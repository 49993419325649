import gql from 'graphql-tag'
// Hooks
import useMutation from '../../../hooks/useMutation'

export const upsertInnovatorProduct = gql`
  mutation upsertInnovatorProduct(
    $submissionId: String!
    $hasLatestPDF: Boolean!
  ) {
    upsertInnovatorProduct(
      submissionId: $submissionId
      hasLatestPDF: $hasLatestPDF
    ) {
      id
      submissionId
      hasLatestPDF
      updatedAt
      submission {
        updatedAt
        submissionInfo {
          name
          category
          categoryLabel
        }
      }
    }
  }
`

interface UpsertInnovatorProductProps {
  companyId?: string
}

const useUpsertInnovatorProduct = () => {
  const [updateInnovationProduct, { loading, error }] = useMutation(
    upsertInnovatorProduct
  )

  const updateInnovatorProduct = async (
    submissionId,
    hasLatestPDF,
    refetchQueries: string[] = []
  ) => {
    const { data } = await updateInnovationProduct({
      variables: {
        submissionId,
        hasLatestPDF
      },
      refetchQueries
    })

    return data
  }

  return {
    updateInnovatorProduct,
    loading,
    error
  }
}

export default useUpsertInnovatorProduct
