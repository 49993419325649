import { useReducer } from 'react'

// Types
interface FilterState {
  [key: string]: any | any[]
  none: any[]
}

interface QueryFilterResult {
  filters: FilterState | any
  // Actions
  setFilters: (
    queryFilters: FilterState | any,
    actionType?: FilterActionsEnum
  ) => void
}

export enum FilterActionsEnum {
  SET_ROOT = 'SET_ROOT',
  SET_NONE = 'SET_NONE',
  CLEAR = 'CLEAR',
  default = 'DEFAULT'
}

// Utils
const removeNoneObject = (keyValuePayload, state: any): any => {
  if (state?.none?.length > 1) {
    const noneObjectIndex =
      keyValuePayload?.length && state?.none?.length
        ? state.none.findIndex((obj: any) => {
            const [key, value] = keyValuePayload
            const [[keyStateObj, valueStateObj]] = Object.entries(obj)

            return (
              keyStateObj === key && String(valueStateObj) === String(value)
            )
          })
        : []
    if (noneObjectIndex !== null) {
      const filteredNone = state?.none?.filter(
        (_, index: number) => index !== noneObjectIndex
      )
      state.none = filteredNone
    }
  } else {
    state.none = []
  }
}

// Reducer
const reducer = (
  state: FilterState | any,
  action: { type: FilterActionsEnum; payload: any }
) => {
  switch (action.type) {
    case FilterActionsEnum.SET_ROOT:
      if (action?.payload) {
        let newState = { ...state, ...action.payload }

        const [keyValuePayload] = Object.entries(action.payload)

        removeNoneObject(keyValuePayload, newState)

        return { ...newState }
      }
      return state
    case FilterActionsEnum.SET_NONE:
      if (action?.payload) {
        let newState = { ...state }
        const [keyValuePayload] = Object.entries(action.payload)
        if (keyValuePayload && keyValuePayload?.[0]) {
          if (newState?.[keyValuePayload[0]]) {
            newState[keyValuePayload[0]] = undefined
          }

          removeNoneObject(keyValuePayload, newState)

          newState.none = [
            ...(state?.none || []),
            { [keyValuePayload[0]]: keyValuePayload[1] }
          ]
        }
        return { ...newState }
      }
      return state
    case FilterActionsEnum.CLEAR:
      if (action?.payload) {
        let newState = { ...state }
        const [keyValuePayload] = Object.entries(action.payload)
        if (keyValuePayload && keyValuePayload?.[0]) {
          if (newState?.[keyValuePayload[0]]) {
            newState[keyValuePayload[0]] = undefined
          }

          removeNoneObject(keyValuePayload, newState)
        }
        return { ...newState }
      }
      return state
    case FilterActionsEnum.default:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const useFilter = (defaultState: FilterState | any): QueryFilterResult => {
  // State
  const [filters, dispatch] = useReducer(
    reducer,
    defaultState,
    () => defaultState
  )

  // Actions

  const setFilters = (
    filter: FilterState | any,
    actionType = FilterActionsEnum.default
  ) => {
    dispatch({ type: actionType, payload: filter })
  }

  return {
    filters,
    // Actions
    setFilters
  }
}

export default useFilter
