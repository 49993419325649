import gql from 'graphql-tag'

const QuestionFieldsFragment = gql`
  fragment QuestionFieldsFragment on Question {
    id
    __typename
    parentId
    type
    subType
    questionText
    style
    configData
    dataEntityId
    dataEntityType
    optionsEntity
    optionsValues
    systemLabel
    questionValidationMaps {
      id
      questionId
      validation {
        id
        type
        questionDependencyId
      }
      errorMessage
      phase
      value
    }
    questionDependencies {
      id
      question1Id
      question2Id
      value
    }
  }
`

export default QuestionFieldsFragment
