import React, { FC, useEffect } from 'react'
import { useTheme } from 'styled-components/native'
import { SubmissionProps } from '../../../../recoil/lastSubmittedSubmissionAtom'
import {
  CallForSubmissionStepEnum,
  Company,
  Delegation
} from '../../../../types'
import useTranslation from '../../../../hooks/useTranslation'
import useCreateInnovatorProduct from '../../hooks/useCreateInnovatorProduct'
import useSubmissionsQuery from '../../../Submission/hooks/useSubmissionsQuery'
import { MessageStatus } from '../../../../recoil/toastMessageAtom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Flex } from '../../../../components/FlexBox'
import { View } from 'react-native'
import Dropdown, { GroupedOption } from '../../../../components/common/Dropdown'
// import SubmissionPrefillSummary from '../../../Submission/components/SubmissionPrefillSummary'
import TextInput from '../../../../ui-library/TextInput'
import Button from '../../../../ui-library/Button'
import {
  Description,
  getStyles,
  InputContainer
} from './SharedStyledComponents'
import { getProductLabel } from '../../../../utils/products'

const getSubmittedSubmissions = submissions => {
  return submissions
    .filter(
      ({ hasBeenSubmitted, submittedAt, step }) =>
        (hasBeenSubmitted && submittedAt) ||
        step !== CallForSubmissionStepEnum.submission
    )
    .sort((a, b) => {
      const x = (new Date(a.submittedAt) as any) as number
      const y = (new Date(b.submittedAt) as any) as number

      return y - x
    })
}

interface CreateProductProps {
  lastSubmittedSubmission: SubmissionProps
  company?: Company
  delegations: Delegation[]
  onClose: (val?) => void
  setToastMessage: (val?) => void
}

const CreateProduct: FC<CreateProductProps> = ({
  lastSubmittedSubmission,
  onClose,
  company,
  delegations,
  setToastMessage
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { space, colors } = theme
  const { btnContainerStyles, btnStyles } = getStyles(theme)
  const {
    createInnovatorProduct,
    loading: isCreatingProduct
  } = useCreateInnovatorProduct(company?.id)

  const {
    submissions = [],
    loading: isSubmissionLoading
  } = useSubmissionsQuery()

  let delegationOptions = [
    ...delegations
      .map(delegation => ({
        value: delegation.id,
        label: `${delegation.description} (${delegation.name})`
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  ]

  const defaultOption = {
    label: t('submissions:prefill:newProduct'),
    value: 'new'
  }

  const productOptions = [
    defaultOption,
    ...(company?.products?.map(product => ({
      value: product?.submissionId,
      label: getProductLabel(product)
    })) || [{}])
  ]

  const submittedSubmissions = getSubmittedSubmissions(submissions || [])

  const submissionOpts = [
    ...submittedSubmissions.map(({ id, callForSubmission, submissionInfo }) => {
      return {
        value: id,
        label: `${callForSubmission?.name} - ${submissionInfo?.name}`
      }
    })
  ]

  const submissionOptions = [
    {
      label: t('submissions:prefill:prefillGroups:products'),
      options: productOptions
    },
    {
      label: t('submissions:prefill:prefillGroups:pastSubmissions'),
      options: submissionOpts
    }
  ] as GroupedOption[]

  const {
    resetForm,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
    dirty
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async values => {
      const isPrefill = values.submissionId !== defaultOption.value

      try {
        const { data, errors } = await createInnovatorProduct(
          isPrefill ? values.submissionId : undefined,
          isPrefill ? undefined : values.productName,
          values.delegationId
        )
        if (data.createInnovatorProduct) {
          resetForm()
          onClose(data.createInnovatorProduct)
        } else if (errors) {
          setToastMessage({
            message: t('error:defaultMutation'),
            status: MessageStatus.Error
          })
        }
      } catch (e) {
        setToastMessage({
          message: t('error:defaultMutation'),
          status: MessageStatus.Error
        })
      }
    },
    validationSchema: Yup.object().shape({
      submissionId: Yup.string().notRequired(),
      productName: Yup.string().when(['submissionId'], {
        is: submissionId => !submissionId,
        then: Yup.string().required()
      }),
      delegationId: Yup.string().required()
    }),
    initialValues: {
      submissionId: defaultOption.value,
      productName: '',
      delegationId: null
    }
  })

  useEffect(() => {
    if (lastSubmittedSubmission?.submissionId) {
      setFieldValue('submissionId', lastSubmittedSubmission?.submissionId)
      setFieldValue('delegationId', lastSubmittedSubmission?.delegationId)
    }
  }, [lastSubmittedSubmission])

  if (isSubmissionLoading) {
    return null
  }

  // const getCurrentSubmission = () =>
  //   submittedSubmissions.find(s => s.id === values.submissionId)

  return (
    <Flex>
      <View>
        <View
          style={{
            marginBottom: space[2]
          }}
        >
          <Description>
            {t('directoryProfile:products:prefillProduct:selectDelegation')}
          </Description>
          <InputContainer testID={'delegationSelect'}>
            <Dropdown
              style={{ width: 'max-content' }}
              name="delegationId"
              value={values.delegationId}
              options={delegationOptions}
              onChange={setFieldValue}
              containerStyle={{ width: '100%' }}
              menuPortalTarget={document?.body}
            />
          </InputContainer>
        </View>

        {values.delegationId ? (
          <View>
            {submittedSubmissions.length > 0 ? (
              <View
                style={{
                  marginBottom: space[2]
                }}
              >
                <Description>
                  {t(
                    'directoryProfile:products:prefillProduct:submissionSelection'
                  )}
                </Description>
                <Description>
                  {t(
                    'directoryProfile:products:prefillProduct:submissionSelectionWithNewProduct'
                  )}
                </Description>
              </View>
            ) : (
              <Description>
                {t('directoryProfile:products:prefillProduct:brandNewProduct')}
              </Description>
            )}

            <InputContainer testID={'newExistingSelect'}>
              <Dropdown
                isGroupedOptions
                name="submissionId"
                value={values.submissionId}
                options={submissionOptions}
                onChange={setFieldValue}
                containerStyle={{ width: '100%' }}
                menuPortalTarget={document?.body}
              />
            </InputContainer>
            {/* JC: Until we have not clarified which are the steps we want to show, hide the Prefill summary */}
            {/* {values.submissionId !== defaultOption.value && ( */}
            {/*  <SubmissionPrefillSummary submission={getCurrentSubmission()} /> */}
            {/* )} */}
            {values.submissionId === defaultOption.value && (
              <InputContainer testID={'productName'}>
                <TextInput
                  name="productName"
                  value={values.productName}
                  onChange={handleChange}
                  label={t(
                    'directoryProfile:products:prefillProduct:productName'
                  )}
                />
              </InputContainer>
            )}
          </View>
        ) : null}

        <div style={{ display: 'flex' }}>
          <Button
            title={t('product:general:cancel')}
            type="outline"
            buttonStyle={{
              ...btnStyles,
              borderColor: colors.buttonVariant
            }}
            titleStyle={{
              color: colors.buttonVariant
            }}
            containerStyle={{
              ...btnContainerStyles,
              marginRight: space[3]
            }}
            onPress={onClose}
          />
          <Button
            title={t('common:buttons:continue')}
            buttonStyle={btnStyles}
            containerStyle={btnContainerStyles}
            onPress={() => handleSubmit()}
            disabled={!dirty || !isValid}
            loading={isCreatingProduct}
          />
        </div>
      </View>
    </Flex>
  )
}

export default CreateProduct
