import React from 'react'
import { useTheme } from 'styled-components/native'
import { Text } from '../../components/common/Text'
import Icon from '../../components/icon'
import { FileProps } from '../../recoil/currentDownloadFilesAtom'
import {
  DownloadStatusActivityIndicator,
  FileStatusContainer,
  FileTextContainer
} from './styledComponents'

interface FileStatusProps {
  file: FileProps
  handleSubscription?: (value: any) => {}
}

const FileStatus = ({ file, handleSubscription }: FileStatusProps) => {
  const { label, isLoading, error, subscriptionKeys, isDownloadable } = file
  const { space } = useTheme()

  handleSubscription && handleSubscription({ ...subscriptionKeys })

  return (
    <FileStatusContainer>
      <FileTextContainer>
        <Text
          styles={{
            fontSize: space[1] + space[2],
            color: '#000000'
          }}
        >
          {label}
        </Text>
      </FileTextContainer>
      {isLoading ? (
        <DownloadStatusActivityIndicator style={{ width: '20%' }} />
      ) : (
        <Icon
          name={error || !isDownloadable ? 'errorIcon' : 'fileReady'}
          color={error || !isDownloadable ? 'red' : '#00C389'}
          style={{ marginRight: '15px', width: '20%' }}
        />
      )}
    </FileStatusContainer>
  )
}

export default FileStatus
