import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'ramda'

import InputField from '../../../InputField'
import { FormikProvider, useFormik } from 'formik'
import {
  FormField,
  QuestionModalFormProps,
  QuestionValues
} from '../../../../types'
import {
  QuestionSubTypeEnum,
  QuestionTypeEnum
} from '../../../../../../../types/form'
import useUpsertParentQuestion from '../../hooks/useUpsertParentQuestion'
import useToast from '../../../../../../../hooks/useToast'
import useTranslation from '../../../../../../../hooks/useTranslation'
import { CancelButton, Footer, SaveButton } from '../../../../../constants'
import { JSONValidation } from '../../../../../utils'
import { DropdownOption } from '../../../../../../../components/common/Dropdown'
import DropdownField from '../../../DropdownField'

enum DataEntityTypeEnum {
  'Submission Review' = 'Submission Review',
  'All Funding' = 'All Funding',
  'All Customers' = 'All Customers',
  'Innovator feedback' = 'Innovator feedback',
  'All Competitors' = 'All Competitors',
  'All Certifications' = 'All Certifications',
  'All Products' = 'All Products'
}

const dataEntityTypes = Object.keys(DataEntityTypeEnum)
const dataEntityTypesValues = Object.values(DataEntityTypeEnum)
const dataEntityTypeOptions: DropdownOption[] = dataEntityTypes.map(
  (typeKey, index) => ({
    label: typeKey,
    value: dataEntityTypesValues[index]
  })
)

const questionTypes = Object.keys(QuestionTypeEnum)
const questionTypesValues = Object.values(QuestionTypeEnum)
const questionTypeOptions: DropdownOption[] = questionTypes.map(
  (typeKey, index) => ({
    label: typeKey,
    value: questionTypesValues[index]
  })
)

const questionSubTypes = Object.keys(QuestionSubTypeEnum)
const questionSubTypesValues = Object.values(QuestionSubTypeEnum)
const questionSubTypeOptions: DropdownOption[] = questionSubTypes.map(
  (typeKey, index) => ({
    label: typeKey,
    value: questionSubTypesValues[index]
  })
)

const questionFields: FormField[] = [
  {
    field: 'dataEntityType',
    Component: DropdownField,
    isParent: false,
    options: dataEntityTypeOptions
  },
  {
    field: 'questionText',
    Component: InputField,
    isParent: false
  },
  {
    field: 'systemLabel',
    Component: InputField,
    isParent: true,
    shouldShow: values => {
      if (values.id) {
        return false
      }
      return true
    }
  },
  {
    field: 'type',
    Component: DropdownField,
    isParent: false,
    options: questionTypeOptions
  },
  {
    field: 'subType',
    Component: DropdownField,
    isParent: false,
    options: questionSubTypeOptions,
    shouldShow: values => {
      if (values.type === QuestionTypeEnum.MultiSelect) {
        return true
      }
      return false
    }
  },
  {
    field: 'optionsEntity',
    Component: InputField,
    isParent: false,
    shouldShow: values => {
      if (
        values.type === QuestionTypeEnum.MultiSelect &&
        values.subType === QuestionSubTypeEnum.DbLookup
      ) {
        return true
      }
      return false
    }
  },
  {
    field: 'optionsValues',
    Component: InputField,
    isParent: false,
    isJSON: true,
    shouldShow: values => {
      if (
        values.type === QuestionTypeEnum.MultiSelect &&
        [
          QuestionSubTypeEnum.OptionValues,
          QuestionSubTypeEnum.CheckboxGroup
        ].includes(values.subType)
      ) {
        return true
      }
      return false
    }
  },
  {
    field: 'style',
    Component: InputField,
    isParent: false,
    isJSON: true
  },
  {
    field: 'configData',
    Component: InputField,
    isParent: false,
    isJSON: true
  }
]

// Yup validation
const getAddQuestionValidationSchema = (isNew: boolean) =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    dataEntityType: Yup.mixed<DataEntityTypeEnum>()
      .oneOf(Object.values(DataEntityTypeEnum))
      .required(),
    type: Yup.mixed<QuestionTypeEnum>()
      .oneOf(Object.values(QuestionTypeEnum))
      .required(),
    subType: Yup.mixed<QuestionSubTypeEnum>()
      .oneOf(Object.values(QuestionSubTypeEnum))
      .notRequired()
      .nullable(),
    questionText: Yup.string().required(),
    optionsEntity: Yup.string().notRequired().nullable(),
    optionsValues: JSONValidation,
    style: JSONValidation,
    configData: JSONValidation,
    systemLabel: isNew
      ? Yup.string().required()
      : Yup.string().notRequired().nullable()
  })

const Form = (props: QuestionModalFormProps) => {
  const { onClose, question, isEdit } = props
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()

  const currentQuestion = useMemo(() => question, [question])

  // Mutations
  const [upsertParentQuestion, isLoading] = useUpsertParentQuestion({
    onError: e => console.error(e),
    onCompleted: ({ upsertParentQuestion }) => {
      if (upsertParentQuestion && !isEmpty(upsertParentQuestion)) {
        setToastMessage(
          t('platformManagement:forms:menuItems:questions:successSaved')
        )
        onClose()
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:questions:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:questions:errorSave')
  })

  const isNew = useMemo(() => !question?.id, [question?.id])

  const questionKeys = currentQuestion
    ? Object.keys(currentQuestion)
    : undefined

  let initialValues = {
    dataEntityType: DataEntityTypeEnum['All Products'],
    type: QuestionTypeEnum.TextInput,
    configData: '{}' as any,
    style: '{}' as any
  }

  if (currentQuestion && questionKeys) {
    for (
      let indexQuestionKey = 0;
      indexQuestionKey < questionKeys.length;
      indexQuestionKey++
    ) {
      const questionKey = questionKeys[indexQuestionKey]
      if (
        ['id', ...questionFields.map(qField => qField.field)].includes(
          questionKey
        )
      ) {
        const currentValue = currentQuestion[questionKey]
        if (typeof currentValue === 'object' && currentValue != null) {
          initialValues[questionKey] = JSON.stringify(currentValue)
        } else {
          initialValues[questionKey] = currentValue ?? undefined
        }
      }
    }
  }

  // Formik
  const form = useFormik<QuestionValues>({
    initialValues: { ...initialValues },
    validationSchema: getAddQuestionValidationSchema(isNew),
    enableReinitialize: true,
    onSubmit: async values => {
      const input = {
        ...(isNew ? { parent: { ...values } } : { ...values }),
        id: isNew ? undefined : values.id
      }

      upsertParentQuestion({
        variables: { input },
        refetchQueries: ['getQuestions']
      })
      onClose()
    }
  })

  const validateAndSubmit = () => {
    form.validateForm().then(() => form.submitForm())
  }

  const enableSubmit = useMemo(() => {
    return form.isValid && form.dirty
  }, [form])

  return (
    <FormikProvider value={form}>
      {questionFields
        .filter(formField => (isNew ? !formField.hideOnNew : true))
        .map(formField => {
          const { Component, field, options, isParent, shouldShow } = formField
          const componentProps = {
            type: 'questions',
            key: field,
            fieldName: field,
            isReadOnly: isEdit && isParent,
            options
          }

          if (shouldShow && !shouldShow(form.values)) return null

          return <Component {...componentProps} />
        })}
      <Footer>
        <CancelButton
          isProcessing={isLoading}
          onPress={onClose}
          title={t('common:buttons:cancel')}
        />
        <SaveButton
          disabled={!enableSubmit}
          onPress={validateAndSubmit}
          isProcessing={isLoading}
          title={t(`common:buttons:${isEdit ? 'update' : 'save'}`)}
        />
      </Footer>
    </FormikProvider>
  )
}

export default Form
