import React from 'react'
import ChangePasswordScreen from '../screens/authentication/ChangePasswordScreen'
import VerifyEmailScreen from '../screens/authentication/VerifyEmailScreen'
import AuthCodeSentScreen from '../screens/authentication/AuthCodeSentScreen'

export interface LoginCheckSubStateProps {
  send: (state: string) => void
  firebaseToken: string
  firebaseInitialized: boolean
  state: any
}

const LoginCheck = ({
  send,
  state,
  firebaseToken,
  firebaseInitialized
}: any) => {
  const substate = state.loginCheck

  const props: LoginCheckSubStateProps = {
    send,
    state,
    firebaseToken,
    firebaseInitialized
  }

  switch (substate) {
    case 'verifyEmail':
      return <VerifyEmailScreen {...props} />
    case 'authCodeCheck':
      return <AuthCodeSentScreen {...props} />
    case 'passwordReset':
      return <ChangePasswordScreen {...props} />
    default:
      return null
  }
}

export default LoginCheck
