import React from 'react'
import styled from 'styled-components/native'
// Components/Types
import {
  Col,
  RowSkeleton
} from '../../../../components/skeletonLoadings/components'
import { SkeletonTemplateProps } from '../../../../components/skeletonLoadings/types'
import { BorderCard } from '../../../Submission/components/skeletons/components'
import { FlexRow } from '../../../Curation/SubmissionDetails/components/SharedComponents'

// Styled Components
const Container = styled(Col)`
  height: auto;
  min-width: 250px;
  width: 100%;
`
const Column = styled(Col)`
  ${({ theme: { space, sizes } }) => `
    min-height: ${sizes[4]}px;
    padding-horizontal: ${space[3]}px;
  `}
`
const OuterBorderCard = styled(BorderCard)`
  height: auto;
  justify-content: space-between;
  ${({ theme: { space, colors } }) => `
    margin-bottom: 0;
    padding-vertical: ${space[4]}px;
    padding-horizontal: ${space[4]}px;
    background: ${colors.appBackground}
  `}
`
const SkeletonText = styled(RowSkeleton)`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[2]}px;
  `}
`

const SkeletonButton = styled(RowSkeleton)`
  width: 45%;
  margin-left: auto;
  ${({ theme: { sizes } }) => `
    height: ${sizes[4]}px;
  `}
`

export const SkeletonTitle = styled(RowSkeleton)`
  ${({ theme: { spaces, sizes } }) => `
  padding-vertical: ${spaces[4]}px;
    margin-bottom: ${sizes[2]}px;
  `}
`
interface CompanySummarySkeleton extends SkeletonTemplateProps {}

export const ListSkeleton = React.memo(
  ({ theme: { sizes } }: CompanySummarySkeleton) => (
    <Column marginTop={sizes[4]}>
      <SkeletonText width="35%" height={sizes[4]} />

      <SkeletonText width="100%" height={sizes[5]} />
      <SkeletonText width="100%" height={sizes[5]} />
      <SkeletonText width="100%" height={sizes[5]} />
      <SkeletonText width="100%" height={sizes[5]} />
      <SkeletonText width="100%" height={sizes[5]} />
    </Column>
  )
)

const CompanySummarySkeleton = React.memo(
  ({ theme: { sizes, radii } }: CompanySummarySkeleton) => (
    <>
      <Container>
        <OuterBorderCard showBadge={false}>
          <Column>
            <SkeletonText
              width="65%"
              height={sizes[4]}
              borderRadius={radii[3]}
            />
            {/* <SkeletonTitle width="50%" /> */}
            <SkeletonText width="30%" height={sizes[1]} />
            <SkeletonText width="45%" height={sizes[2]} />
          </Column>

          <Column marginTop={sizes[3]}>
            <SkeletonText width="100%" height={sizes[5]} />
          </Column>
          <Column marginTop={sizes[3]}>
            <SkeletonText width="25%" height={sizes[3]} />

            <SkeletonText width="100%" height={sizes[5]} />
          </Column>

          <FlexRow>
            <SkeletonButton />
            <SkeletonButton />
          </FlexRow>
        </OuterBorderCard>
      </Container>
      <Column marginTop={sizes[4]}>
        <SkeletonText width="35%" height={sizes[4]} />

        <SkeletonText width="100%" height={sizes[5]} />
      </Column>
    </>
  )
)

export default CompanySummarySkeleton
