import { useCallback } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { TeamMemberRolesEnum } from '../../../types'

export const upsertInnovationUserMutation = gql`
  mutation upsertInnovationUserMutation(
    $id: String!
    $role: RoleEnum
    $remove: Boolean
    $innovatorCommsPlatformAdmin: Boolean
    $innovatorCommsCFS: Boolean
    $innovatorCommsCFSSelectionAndPresentation: Boolean
    $innovatorCommsInvoicing: Boolean
    $firstName: String
    $lastName: String
    $jobTitle: String
  ) {
    upsertInnovationUserMutation(
      id: $id
      role: $role
      remove: $remove
      innovatorCommsPlatformAdmin: $innovatorCommsPlatformAdmin
      innovatorCommsCFS: $innovatorCommsCFS
      innovatorCommsCFSSelectionAndPresentation: $innovatorCommsCFSSelectionAndPresentation
      innovatorCommsInvoicing: $innovatorCommsInvoicing
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
    )
  }
`

interface UpsertInnovationUserMutationProps {
  id: string
  role?: TeamMemberRolesEnum
  remove?: boolean
  innovatorCommsPlatformAdmin?: boolean
  innovatorCommsCFS?: boolean
  innovatorCommsCFSSelectionAndPresentation?: boolean
  innovatorCommsInvoicing?: boolean
  // Profile info data
  firstName?: string
  lastName?: string
  jobTitle?: string
}

const useUpsertInnovationUserMutation = () => {
  const [
    upsertInnovationUser,
    { loading: loadingUpsertInnovationUser }
  ]: any[] = useMutation(upsertInnovationUserMutation, {
    refetchQueries: ['getInnovationUsersByCompanyId']
  })

  const upsertInnovator = useCallback(
    ({ id, role, remove, ...rest }: UpsertInnovationUserMutationProps) => {
      return upsertInnovationUser({ variables: { id, role, remove, ...rest } })
    },
    [upsertInnovationUser]
  )

  return [upsertInnovator, loadingUpsertInnovationUser]
}

export default useUpsertInnovationUserMutation
