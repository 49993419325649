import React, { FC } from 'react'
import { ActivityIndicator } from 'react-native'
import Checkbox from '../../../ui-library/CheckBox'

export interface CohortCheckBoxProps {
  isSelected: boolean
  onToggle: (isDeleted: boolean, isSelected: boolean) => Promise<void>
  isDisabled: boolean
  checkboxColor?: string
  isCutCheckbox?: boolean
  isLoading: boolean
}

const CohortCheckBox: FC<CohortCheckBoxProps> = ({
  isSelected,
  onToggle,
  isDisabled,
  checkboxColor,
  isCutCheckbox,
  isLoading
}) => {
  return isLoading ? (
    <ActivityIndicator style={{ alignItems: 'baseline' }} size="small" />
  ) : (
    <Checkbox
      checked={isSelected}
      onPress={() => onToggle(isCutCheckbox ?? false, isSelected)}
      isReadOnly={isDisabled}
      flexContainerStyles={{
        paddingLeft: 0,
        marginBottom: 0
      }}
      checkedIconName=""
      checkedColor={checkboxColor}
      uncheckedColor={checkboxColor}
    />
  )
}

export default CohortCheckBox
