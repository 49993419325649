import {
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  View
} from 'react-native'
import styled from 'styled-components/native'
import { H1, Text } from '../../components/common/Text'

export const FileTextContainer = styled(View)`
  width: 90%;
  justify-content: center;
  padding-left: 30px;
`

export const FilesContainer = styled(ScrollView)`
  width: 98%;
  max-height: ${({ theme }) => theme.sizes[8]}px;
  margin-top: ${({ theme }) => theme.sizes[1]}px;
  margin-bottom: ${({ theme }) => theme.sizes[2]}px;
  padding: 0 ${({ theme }) => theme.space[2]}px;
`

export const FileStatusContainer = styled(View)`
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: ${({ theme }) => theme.sizes[3]}px;
  height: ${({ theme }) => theme.sizes[4] - theme.sizes[1]}px;
  margin-bottom: ${({ theme }) => theme.sizes[1]}px;
  background-color: rgba(229, 229, 229, 0.54);
  border-radius: 12px;
`

export const DownloadStatusContainer = styled(View)`
  max-height: ${({ theme }) => theme.sizes[7]}px;
  min-height: ${({ theme }) => theme.sizes[5] + theme.sizes[1]}px;
  width: ${({ theme }) => theme.sizes[7] + theme.sizes[5]}px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: ${({ theme }) => theme.sizes[1] + theme.sizes[2]}px;
  margin-bottom: ${({ theme }) => theme.sizes[3]}px;
  margin-right: ${({ theme }) => theme.sizes[3]}px;
  box-shadow: #0000001a 0px 0px 20px;
  align-items: center;
  background-color: #ffffff;
`

export const DownloadStatusCloseContainer = styled(View)`
  width: 100%;
  margin-top: ${({ theme }) => theme.sizes[2]}px;
  padding-right: ${({ theme }) => theme.sizes[2]}px;
  align-items: flex-end;
`

export const DownloadStatusTouchableOpacity = styled(TouchableOpacity)``

export const DownloadStatusContentView = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50%;
  justify-content: center;
  padding-horizontal: ${({ theme }) => theme.sizes[4]}px;
  margin-bottom: ${({ theme }) => theme.sizes[2]}px;
`

export const DownloadStatusActivityIndicator = styled(ActivityIndicator)`
  margin-right: ${({ theme }) => theme.sizes[3]}px;
`

export const Title = styled(H1).attrs(
  ({ theme: { space }, isSmallScreen }) => ({
    styles: {
      marginBottom: space[3],
      marginTop: isSmallScreen ? space[2] : 0,
      textAlign: isSmallScreen ? 'center' : 'left'
    },
    h1Style: {
      ...(isSmallScreen ? { fontSize: '24px' } : { fontSize: '32px' })
    }
  })
)``

export const P = styled(Text)`
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes[4]}px;
  line-height: 20px;
  margin-bottom: 15px;
`
