import { Flex } from '../FlexBox'
import styled from 'styled-components/native'

const TableContainer = styled(Flex).attrs(({ height }) => ({
  flexDirection: 'row',
  w: '100%',
  height: height || '50vh'
}))``

export default TableContainer
