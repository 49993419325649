import React, { FC, useMemo, useState } from 'react'
import styled from 'styled-components/native'
// Hooks
import useToast from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'
import useCompanyLogoEdit from '../hooks/useCompanyLogoEdit'
// Components
import CompanyLogo from './CompanyLogo'
import Dialog from '../../../components/common/Dialog'
import { Title } from './common/SharedStyledComponents'
import ImageViewer, { MediaI, CustomCrop } from './ImageViewer'
// Types
import { Company } from '../../../types'
import {
  IMAGE_MAX_FILE_SIZE_BYTES,
  IMAGE_MAX_FILE_SIZE_MB,
  acceptSignatures
} from './types'

// Styled components
const Container = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const PicturesContainer = styled.View`
  ${({ theme: { sizes } }) => `
    margin-top: ${sizes[3]}px;
  `}
`
// Interface/Types
interface CompanyLogoUploaderProps {
  company: Company
}

const CompanyLogoUploader: FC<CompanyLogoUploaderProps> = ({ company }) => {
  const { t } = useTranslation()
  const {
    isDeleting,
    isUploading,
    isUploadCompleted,
    cloudinaryProgress,
    uploadCompanyLogo,
    removeCompanyLogo
  } = useCompanyLogoEdit(company)
  const {
    setToastMessage,
    setToastWarningMessage,
    setToastErrorMessage
  } = useToast()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [imageToView, setImageToView] = useState<MediaI | null>(null)

  // Handlers
  const onLoad = async file => {
    try {
      const media: MediaI = {
        file,
        uri: URL.createObjectURL(file)
      }

      if ((file?.size ?? 0) >= IMAGE_MAX_FILE_SIZE_BYTES) {
        setToastWarningMessage(
          t('directoryProfile:companyLogoUploader:exceedFileSize', {
            size: IMAGE_MAX_FILE_SIZE_MB
          })
        )
        return
      }

      setImageToView(media)
    } catch (onUploadImageError) {
      setToastErrorMessage(
        onUploadImageError ||
          t('directoryProfile:companyLogoUploader:unknownLoadImageError')
      )
    }
  }

  const onRemoveConfirm = () => {
    setOpenDialog(true)
  }

  const onRemove = () => {
    setOpenDialog(false)
    removeCompanyLogo()
  }

  const onRemoveCancel = () => {
    setOpenDialog(false)
  }

  const onCancel = () => {
    setImageToView(null)
  }
  const onUpload = async (crop?: CustomCrop) => {
    if (!imageToView) return

    const onComplete = () => {
      setToastMessage(t('directoryProfile:companyLogoUploader:uploadSuccess'))
    }

    await uploadCompanyLogo(imageToView, crop, onComplete)

    setImageToView(null)
  }

  const onFilesRejected = fileRejections => {
    if (fileRejections.length === 0) return
    setToastWarningMessage(
      t('directoryProfile:companyLogoUploader:invalidImage', {
        types: acceptSignatures.join(', ')
      })
    )
  }

  const companyLogo = useMemo(
    () =>
      company?.companyProfile?.logo
        ? ({
            ...company?.companyProfile?.logo,
            logoCropping: company?.companyProfile?.logoCropping || null
          } as any)
        : undefined,
    [company?.companyProfile]
  )

  if (!company?.canUpdateCompanyLogo) return null

  return (
    <Container>
      <Title>{t('directoryProfile:companyLogoUploader:title')}</Title>
      <PicturesContainer>
        <CompanyLogo
          onLoad={onLoad}
          onRemove={onRemoveConfirm}
          logo={companyLogo}
          onDropRejected={onFilesRejected}
          loading={isDeleting || isUploading}
          canUpload={!isDeleting && !isUploading}
          canRemove={!!companyLogo && !isDeleting && !isUploading}
        />
      </PicturesContainer>
      {imageToView ? (
        <ImageViewer
          media={imageToView}
          onSave={onUpload}
          onCancel={onCancel}
          isUploading={isUploading}
          isUploadCompleted={isUploadCompleted}
          cloudinaryProgress={cloudinaryProgress}
        />
      ) : null}
      {companyLogo ? (
        <Dialog
          title={t(
            'directoryProfile:companyLogoUploader:deleteCompanyLogoTitle'
          )}
          description={t(
            'directoryProfile:companyLogoUploader:deleteCompanyLogoDesc'
          )}
          isOpen={openDialog}
          onClose={onRemoveCancel}
          actions={[
            {
              title: t('common:dialogs:unsavedChanges:discardBtn'),
              type: 'outline',
              onPress: onRemoveCancel
            },
            {
              title: t('common:buttons:delete'),
              onPress: onRemove
            }
          ]}
        />
      ) : null}
    </Container>
  )
}

export default CompanyLogoUploader
