import { useDimensions } from '@react-native-community/hooks'
import { Breakpoint } from '../types/breakpoint'
import theme from '../constants/Theme'

const useBreakpoint = () => {
  const { breakpoints } = theme
  const { width } = useDimensions().window

  if (width < breakpoints.phone) {
    return Breakpoint.Phone
  }

  if (width < breakpoints.tablet) {
    return Breakpoint.Tablet
  }

  if (width < breakpoints.desktop) {
    return Breakpoint.Desktop
  }

  return Breakpoint.Full
}

export default useBreakpoint
