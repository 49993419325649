import React from 'react'
import { useTheme } from 'styled-components/native'
import { Container } from '../../common/SharedStyledComponents'

const Block = ({ children, style }) => {
  const { space } = useTheme()

  const containerStyle = {
    paddingVertical: `${space[4]}px`,
    paddingHorizontal: '50px',
    ...(style?.containerStyles || {})
  }
  return (
    <Container
      flexDirection="row"
      flexWrap="wrap"
      flexShrink={1}
      justifyContent="space-between"
      minWidth="100%"
      style={containerStyle}
      my={`0px`}
    >
      {children}
    </Container>
  )
}

export default Block
