import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'

export const updateBulkInnovationSubmissionStatusMutation = gql`
  mutation updateBulkInnovationSubmissionStatus(
    $ids: [String!]
    $status: InnovationSubmissionStatusEnum!
    $returnToSubmission: Boolean
    $deleteSubmission: Boolean
    $isTestingSetting: Boolean
    $generatePDF: Boolean
  ) {
    updateBulkInnovationSubmissionStatus(
      ids: $ids
      status: $status
      returnToSubmission: $returnToSubmission
      deleteSubmission: $deleteSubmission
      isTestingSetting: $isTestingSetting
      generatePDF: $generatePDF
    ) {
      id
      status
      step
      hasBeenFastPassed
      __typename
    }
  }
`

const useUpdateBulkSubmissionStatusMutation = (
  options = {},
  generatePDF = false
) => {
  const [
    updateBulkInnovationSubmissionStatus,
    { loading }
  ]: any[] = useMutation(updateBulkInnovationSubmissionStatusMutation)

  const updateBulkSubmissionStatus = async (
    submissionIds,
    status,
    returnToSubmission?,
    deleteSubmission?,
    isTestingSetting?
  ) => {
    const results = await updateBulkInnovationSubmissionStatus({
      variables: {
        ids: submissionIds,
        status,
        returnToSubmission,
        deleteSubmission,
        isTestingSetting,
        generatePDF
      },
      ...options
    })

    return results
  }

  return [updateBulkSubmissionStatus, loading]
}

export default useUpdateBulkSubmissionStatusMutation
