import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { InnovationSubmission } from '../../../types'
import useUpdatedInnovationSubmissionSubscription from './useUpdatedInnovationSubmissionSubscription'
import useCreatedInnovationSubmissionSubscription from './useCreatedInnovationSubmissionSubscription'

export const submissionsQuery = gql`
  query innovationSubmissions(
    $statuses: [InnovationSubmissionStatusEnum]
    $callForSubmissionId: String
  ) {
    innovationSubmissions(
      statuses: $statuses
      callForSubmissionId: $callForSubmissionId
    ) {
      legacyOriginId
      id
      callForSubmissionId
      pdfCloudinaryId
      step
      status
      updatedAt
      submittedAt
      hasBeenSubmitted
      innovationProduct {
        id
        name
        company {
          name
        }
      }
      submissionAverage {
        avgScore
        wass
      }
      submissionInfo {
        name
        category
      }
      parentInnovationProduct {
        id
        name
      }
      callForSubmission {
        id
        name
        delegations {
          id
          name
        }
        marketSegments {
          id
          name
        }
        status
        currentStep {
          status
          name
          openAt
          closeAt
        }
        stepDateRanges {
          closeAt
          openAt
          curationStep
        }
      }
    }
  }
`

interface SubmissionObject {
  submissions: InnovationSubmission[]
  loading: boolean
}

const useSubmissionsQuery = (skip?: boolean): SubmissionObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(submissionsQuery, {
    errorMessage: t('error:submissions:getSubmissions'),
    skip
  })

  // TODO: If we need an instance of this request that filters by status, we are setup to handle it.
  // We would just need to provide them to this hook via props.
  // We didn't need it yet, so I didn't build it in.

  useUpdatedInnovationSubmissionSubscription()
  useCreatedInnovationSubmissionSubscription()

  return {
    submissions: data?.innovationSubmissions as InnovationSubmission[],
    loading
  }
}

export default useSubmissionsQuery
