// Hooks
import useFeatureFlag from './useFeatureFlag'
import useInnovationAccess from './useInnovationAccess'

const useIsFormManagementEnabled = () => {
  const innovationAccess = useInnovationAccess()
  const isFormManagementEnabled = useFeatureFlag('innovationFormBuilderV2')
  return isFormManagementEnabled && innovationAccess?.platformManagement
}

export default useIsFormManagementEnabled
