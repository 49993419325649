import React from 'react'
import useCallsForSubmissionQuery from './hooks/useCallsForSubmissionQuery'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import theme from '../../constants/Theme'
import { TableContainer } from '../../components/StaticForm'
import { EngineEnum, getCFSTableConfig, TablesEnum } from './constants'
import { useTable, DynamicTable } from '../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'

import { H3 } from '../../components/common/Text'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import { RoleEnum } from '../../types'
import Innovator from '../HomeScreen/Innovator'
import useAppCoordinator from '../../AppCoordinator/useAppCoordinator'
// Skeletons
import SkeletonContainer from '../../components/skeletonLoadings/SkeletonContainer'
import useCurrentUser from '../../hooks/useCurrentUser'
import useTranslation from '../../hooks/useTranslation'
import { View } from 'react-native'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'

const DELAY = 1000
const NEW_DELAY = 500

interface Props {
  EmptyComponent?: any
  title?: string
  role: RoleEnum
  showTitleOnEmpty?: boolean
  engine?: EngineEnum | undefined
  isInnovator?: boolean
  Skeleton?: any
  skeletonProps?: any
  dataFilter?: (CallForSubmission) => boolean
  configCallback?: (
    RoleEnum,
    EngineEnum,
    isCountdownClockEnabled,
    canEditSettings?,
    advisorId?,
    tableId?,
    isInnovationNewDynamicTable?,
    isInnovationAnalyticsSuiteEnabled?
  ) => any
  tableId?: TablesEnum
  canEditSettings?: boolean
}

const CallForSubmissionTable = ({
  title,
  role,
  engine,
  isInnovator,
  Skeleton,
  skeletonProps,
  dataFilter = () => true,
  configCallback = getCFSTableConfig,
  tableId,
  canEditSettings
}: Props) => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { currentUserId } = useCurrentUser()
  const isSmallScreen = useIsSmallScreen()
  const { t } = useTranslation()
  const {
    state: { value }
  } = useAppCoordinator()
  const isCountdownClockEnabled = useFeatureFlag(
    'innovationSubmissionCountdownClock'
  )
  const currentState = typeof value === 'string' ? value : Object.keys(value)[0]
  const roleValidation = isInnovationNewDynamicTable
    ? [
        RoleEnum.Admin,
        RoleEnum.InnovationStaff,
        RoleEnum.InnovationAdvisor
      ].includes(role)
    : false
  const { callsForSubmission, loading } = useCallsForSubmissionQuery(
    role,
    currentState !== 'app' || roleValidation
  )

  const tableProps = useTable({
    config: configCallback(
      role,
      engine,
      isCountdownClockEnabled,
      canEditSettings,
      currentUserId as string,
      tableId,
      isInnovationNewDynamicTable
    ),
    data: callsForSubmission?.filter(dataFilter)
  })

  const onPropsDataChanged = (_, propsData, handleSetData) => {
    handleSetData(propsData)
  }

  const shouldRendeTable = !loading && currentState === 'app'

  const emptyMessage =
    role === RoleEnum.InnovationAdvisor
      ? t('homeScreen:cfs:roles:innovationAdvisor:noCfs')
      : t('homeScreen:cfs:noCfs')

  return (
    <SkeletonContainer
      isLoading={loading && Skeleton}
      Skeleton={Skeleton}
      skeletonProps={skeletonProps}
      delay={
        currentState === 'app' && !isInnovator
          ? isInnovationNewDynamicTable
            ? NEW_DELAY
            : DELAY
          : undefined
      }
    >
      {isInnovator && <Innovator />}
      {title ? (
        isInnovator ? (
          <View
            style={{
              borderTopWidth: 1,
              borderColor: '#d9d9d9',
              marginTop: theme.space[4],
              paddingTop: theme.space[4]
            }}
          >
            <H3
              styles={{
                marginBottom: theme.space[3],
                fontWeight: 'bold',
                color: theme.colors.tints.grays.g500
              }}
              h3Style={{
                fontSize: theme.fontSizes[7],
                fontWeight: 'bold'
              }}
            >
              {title}
            </H3>
          </View>
        ) : (
          <H3
            styles={{
              marginBottom: 0
            }}
            h3Style={{
              ...(isSmallScreen ? { fontSize: '24px' } : {})
            }}
          >
            {title}
          </H3>
        )
      ) : null}
      {isInnovationNewDynamicTable ? (
        shouldRendeTable ? (
          <TableContainer testID={'openCFSContainer'} height="100%">
            <NewDynamicTable
              {...(tableProps as any)}
              emptyMessage={emptyMessage}
              onPropsDataChanged={onPropsDataChanged}
            />
          </TableContainer>
        ) : null
      ) : (
        <TableContainer height="100%">
          <DynamicTable {...tableProps} emptyMessage={emptyMessage} />
        </TableContainer>
      )}
    </SkeletonContainer>
  )
}

export default CallForSubmissionTable
