import { View } from 'react-native'
import styled from 'styled-components/native'
import { Text } from '../../../../components/common/Text'
import TouchableIcon from '../../../../components/icon/TouchableIcon'

export const TableContainerView = styled(View)`
  background-color: white;
  padding: 10px;
  border-radius: 12px;
`

export const CollapsibleContainerView = styled(View)`
  padding: ${({ theme }) => theme.space[3]}px;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  border-radius: 12px;
  background-color: rgb(242, 242, 242);
`

export const CollapsibleContainerHeaderView = styled(View)``

export const CollapsibleContainerTitle = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: ${({ theme }) => theme.sizes[4]}px;
`

export const CollapsibleIcon = styled(TouchableIcon)`
  width: ${({ theme }) => theme.sizes[4]}px;
  align-items: center;
  ${({ isCollapsed }) =>
    isCollapsed ? `transform: rotate(-90deg);` : `transform: rotate(90deg);`};
`

export const CollapsibleTitle = styled(Text)`
  ${({ theme: { colors, space, fontWeights, fontSizes } }) => `
    color: ${colors.text1};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes[5]}px;
    margin-bottom: ${space[1]}px;
    width: 85%;
    height: fit-content;
  `}
`

export const CollapsibleContainerSubtitle = styled(Text)`
  ${({ theme: { colors, space, fontSizes } }) => `
    color: ${colors.text2};
    fontSize: ${fontSizes[3]}px;
    margin-bottom: ${space[1]}px;
  `}
`

export const CollapsibleContainerContentView = styled(View)`
  margin-top: ${({ theme }) => theme.space[3]}px;
`

export const Title = styled.Text`
  font-size: 18px;
  font-weight: bold;
`
