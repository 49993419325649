import React from 'react'
import Content from '../content'
import { Flex } from '../../../components/FlexBox'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

const Innovator = () => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <Flex
      flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
      flexBasis="auto"
      flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
      justifyContent="center"
      alignItems="center"
    >
      <Content />
    </Flex>
  )
}

export default Innovator
