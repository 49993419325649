import theme from '../../constants/Theme'
import { appSearchColumnMap } from '../../screens/CallForSubmission/constants'
import { AppSearchFiltersInput } from '../Table'
import { tableFiltersMap } from './constants'
import {
  CSVExtraParamTypeEnum,
  CSVExtraParamsObject,
  CSVTypeEnum,
  ColumnDefinition,
  GetExtraParamsProps,
  SortState,
  SortableColumnDefinition,
  TableNameEnum
} from './types'

function getDefaultSortColumn<T>(
  columns: (ColumnDefinition<T> | SortableColumnDefinition<T>)[]
): SortableColumnDefinition<T> | undefined {
  // columns marked as sortDefault
  const sortDefaultCols = columns.filter(
    c => c['sort'] && c['sort']['isDefault']
  )
  if (sortDefaultCols.length === 1)
    return sortDefaultCols[0] as SortableColumnDefinition<T>
  if (sortDefaultCols.length > 1) {
    throw new Error(
      `Cannot specify more than one sortDefault column, found ${sortDefaultCols.length}`
    )
  }
  // none marked as sortDefault, take first sortable column
  const sortableCols = columns.filter(c => c['sort'])
  if (sortableCols.length > 1)
    return sortableCols[0] as SortableColumnDefinition<T>
}

export function getInitialSortState<T>(
  columns: ColumnDefinition<T>[]
): SortState | undefined {
  const defaultSortColumn = getDefaultSortColumn(columns)
  return defaultSortColumn
    ? [
        // @ts-ignore
        defaultSortColumn.sort.key,
        // @ts-ignore
        defaultSortColumn.sort.defaultDir || 'asc'
      ]
    : undefined
}

export const getInitialFilters = columns => {
  return columns
    ?.filter(({ filter }) => filter && filter.defaultValue !== undefined)
    ?.reduce(
      (acc, { filter: { key, defaultValue } }) => ({
        ...acc,
        [key]: defaultValue
      }),
      {}
    )
}

export const getCellWidth = (
  width: number,
  leftTable: boolean,
  leftTableWidth: number,
  rigthTableWidth: number
) => {
  const tableWidthV = leftTable
    ? leftTableWidth
    : rigthTableWidth + (rigthTableWidth > 100 ? theme.space[2] : 0)
  return (width / tableWidthV) * 100
}

/**
 * Combines top level filters input
 * @param filtersOne: AppSearchFiltersInput
 * @param filtersTwo: AppSearchFiltersInput
 * @returns AppSearchFiltersInput
 */
export const mergeFilters = (
  filtersOne: AppSearchFiltersInput,
  filtersTwo: AppSearchFiltersInput
): AppSearchFiltersInput => {
  return {
    all: [...(filtersOne?.all || []), ...(filtersTwo?.all || [])],
    any: [...(filtersOne?.any || []), ...(filtersTwo?.any || [])],
    none: [...(filtersOne?.none || []), ...(filtersTwo?.none || [])]
  }
}

export const tableNameCSVTypeMap = {
  [TableNameEnum.Submission]: CSVTypeEnum.Submission,
  [TableNameEnum.PreCuration]: CSVTypeEnum.PreCurate,
  [TableNameEnum.Presentation]: CSVTypeEnum.Presentation,
  [TableNameEnum.Advising]: CSVTypeEnum.Advising,
  [TableNameEnum.Cohort]: CSVTypeEnum.CohortSelectionTable
}

export const getExtraParams = (
  props: GetExtraParamsProps
): CSVExtraParamsObject[] => {
  const { filters, queryFilters, name } = props

  let currentFilters: {
    [key: string]: string[]
  }[] = filters?.['all'] ?? []

  const isAdvisingTable = [
    TableNameEnum.Advising,
    TableNameEnum.AdvisingSubmissionSelection
  ].includes(name)
  const additionalFilters = tableFiltersMap[name]

  // ===================================
  // Adiving Filters
  // (AG) Note: It is necessary to evaluate the Advising table filters individually
  //       since the filters come from queryFilter and in another structure.
  // (AG) TODO: Move all the table filters to the config.filters variable.
  let advisingFilters: CSVExtraParamsObject[] = []
  if (isAdvisingTable) {
    const filtersNone = (queryFilters?.['none'] ?? []) as any[]
    const advisedPendingValue = filtersNone.filter(
      filter =>
        Object.keys(filter).includes('advisors_who_advised') &&
        filter['advisors_who_advised']
    )
    const advisedFilter = !!queryFilters?.['advisors_who_advised']
    const pendingFilter = advisedPendingValue.length > 0

    // Advised Filter
    if (advisedFilter) {
      advisingFilters.push({
        id: 'advisingAdvisedStatus',
        type: CSVExtraParamTypeEnum.Status,
        values: [queryFilters?.['advisors_who_advised']],
        appSearchId: appSearchColumnMap['advisorsWhoAdvised']
      })
    }

    // Pending Filter
    if (pendingFilter) {
      advisingFilters.push({
        id: 'advisingPendingStatus',
        type: CSVExtraParamTypeEnum.Status,
        values: [advisedPendingValue?.[0]?.['advisors_who_advised']],
        appSearchId: appSearchColumnMap['advisorsWhoAdvised']
      })
    }

    // Category Filter
    if ((queryFilters?.['category'] ?? []).length) {
      currentFilters.push({
        category: queryFilters?.['category']
      })
    }
  }
  // ===================================

  let extraParams: CSVExtraParamsObject[] = []

  let allFilters = currentFilters.filter(el => Object.keys(el).length) as {
    [key: string]: string[]
  }[]

  const currentAdditionalFilters = allFilters.filter(filter =>
    additionalFilters
      .map(aFilter => aFilter.appSearchId)
      .includes(Object.keys(filter)[0])
  )

  for (let index = 0; index < currentAdditionalFilters.length; index++) {
    const currentAdditionalFilter = currentAdditionalFilters[index]
    const appSearchId = Object.keys(currentAdditionalFilter)[0]
    const extraFilters = additionalFilters.filter(
      aFilter => aFilter.appSearchId === appSearchId
    )
    extraFilters.map(extraFilter => {
      if (currentAdditionalFilter[appSearchId]) {
        extraParams.push({
          appSearchId,
          id: extraFilter.id,
          type: extraFilter.type,
          values: currentAdditionalFilter[appSearchId]
        })
      }
    })
  }

  extraParams = [...extraParams, ...advisingFilters]

  return extraParams
}
