import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
// Components
import { SkeletonTemplateProps } from '../../components/skeletonLoadings/types'
import { Row, RowSkeleton } from '../../components/skeletonLoadings/components'
import TableSkeleton from './TableSkeleton'

interface StaffHomeSkeletonProps extends SkeletonTemplateProps {
  isAdvisor?: boolean
}

// Style
const Container = styled(View)`
  ${({ theme: { space } }) => `
    padding: ${space[3] - 1}px;
    margin-bottom: ${space[0]}px;
  `}
`

const StaffHomeSkeleton: React.FC<StaffHomeSkeletonProps> = ({
  theme,
  isAdvisor
}) => {
  if (isAdvisor) {
    return (
      <Container>
        <RowSkeleton width="15%" height={theme.sizes[3]} />
        <Row justifyContent="flex-start" marginTop={20} marginBottom={10}>
          <RowSkeleton
            marginRight={126}
            width="10%"
            height={theme.sizes[2] - 2}
          />
          <RowSkeleton
            marginRight={60}
            width="17%"
            height={theme.sizes[2] - 2}
          />
          <RowSkeleton
            marginRight={38}
            width="14%"
            height={theme.sizes[2] - 2}
          />
          <RowSkeleton
            marginRight={87}
            width="14%"
            height={theme.sizes[2] - 2}
          />
          <RowSkeleton
            marginRight={110}
            width="5%"
            height={theme.sizes[2] - 2}
          />
        </Row>
        <Row marginBottom={theme.sizes[2]}>
          <RowSkeleton
            width="21%"
            height={theme.sizes[4] + theme.sizes[2]}
            borderRadius={10}
          />
          <RowSkeleton
            width="21%"
            height={theme.sizes[4] + theme.sizes[2]}
            borderRadius={10}
          />
          <RowSkeleton
            width="16%"
            height={theme.sizes[4] + theme.sizes[2]}
            borderRadius={10}
          />
          <RowSkeleton
            width="21%"
            height={theme.sizes[4] + theme.sizes[2]}
            borderRadius={10}
          />
          <RowSkeleton
            width="11%"
            height={theme.sizes[4] + theme.sizes[2]}
            borderRadius={10}
          />
        </Row>
      </Container>
    )
  }
  return (
    <Container>
      <RowSkeleton marginBottom={40} width="15%" height={theme.sizes[3]} />
      <Row justifyContent="flex-start">
        <RowSkeleton
          marginBottom="initial"
          marginRight={55}
          alignSelf="center"
          width="10%"
          height={theme.sizes[2]}
        />
        <RowSkeleton width="30%" height={theme.sizes[4]} borderRadius={10} />
      </Row>
      <Row justifyContent="flex-start" marginTop={30} marginBottom={10}>
        <RowSkeleton marginRight={55} width="10%" height={theme.sizes[2] - 2} />
        <RowSkeleton marginRight={14} width="14%" height={theme.sizes[2] - 2} />
        <RowSkeleton marginRight={70} width="8%" height={theme.sizes[2] - 2} />
        <RowSkeleton
          marginRight={110}
          width="10%"
          height={theme.sizes[2] - 2}
        />
        <RowSkeleton marginRight={110} width="5%" height={theme.sizes[2] - 2} />
        <RowSkeleton width="10%" height={theme.sizes[2] - 2} />
      </Row>
      <Row justifyContent="flex-start" marginBottom={20}>
        <RowSkeleton
          marginRight={15}
          width="14%"
          height={theme.sizes[4] + theme.sizes[2]}
          borderRadius={10}
        />
        <RowSkeleton
          marginRight={15}
          width="14%"
          height={theme.sizes[4] + theme.sizes[2]}
          borderRadius={10}
        />
        <RowSkeleton
          width="10%"
          height={theme.sizes[4] + theme.sizes[2]}
          borderRadius={10}
        />
      </Row>
      <TableSkeleton />
    </Container>
  )
}

export default StaffHomeSkeleton
