import React, { useEffect, useMemo, useState } from 'react'

// Hooks
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Modal from './components/Modal'
import { TableContainer } from '../../../../../components/StaticForm'
import { DynamicTable, useTable } from '../../../../../components/Table'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../../../../components/DynamicTable'
// Skeleton
import TableSkeleton from '../../../../HomeScreen/TableSkeleton'
import SkeletonContainer from '../../../../../components/skeletonLoadings/SkeletonContainer'
import { View } from 'react-native'
import { CreateIcon } from '../../../constants'
import { FormValues, QuestionGroupEntityMapValues } from '../../types'
import Dropdown, {
  DropdownOption
} from '../../../../../components/common/Dropdown'
import { EntityTypeEnum } from '../../../../../components/DynamicForm/types'
import TextInput from '../../../../../ui-library/TextInput'
import useDebounce from '../../../../../hooks/useDebounce'
import { getQuestionGroupEntityMapsTableConfig } from './constants'
import useFormsQuery from '../../../../Product/hooks/useFormsQuery'
import useGetQuestionGroupEntityMaps from '../Forms/hooks/useGetQuestionGroupEntityMaps'

const getBlockLabel = (
  block: QuestionGroupEntityMapValues,
  identation: number
): string => {
  const configData = JSON.parse(
    JSON.stringify(block?.entity?.configData) ?? '{}'
  )

  const label = `${'>>'.repeat(identation)} ${block.sequence} ${
    configData?.title ||
    configData?.blockLabel ||
    configData?.questionGroupTitle ||
    ''
  }`

  return label
}

const getSubBlockOptions = (
  blocks: QuestionGroupEntityMapValues[],
  allGroups: QuestionGroupEntityMapValues[],
  identation: number = 0
): DropdownOption[] => {
  let result: DropdownOption[] = []
  for (let indexBlock = 0; indexBlock < blocks.length; indexBlock++) {
    const formBlock = blocks[indexBlock]
    // Add Initial Group
    result.push({
      value: formBlock.id,
      label: getBlockLabel(formBlock, identation)
    })

    // Get all subBlocks
    const formBlockSubBlocks = allGroups.filter(
      group =>
        group.entityType === EntityTypeEnum.QuestionGroup &&
        group.questionGroupId === formBlock.entityId
    )

    let subBlocks: DropdownOption[] = []

    if (formBlockSubBlocks.length > 0) {
      subBlocks = getSubBlockOptions(
        formBlockSubBlocks,
        allGroups,
        identation + 1
      )
    }
    result.push(...subBlocks)
  }

  return result
}

const FormBlockQuestionsAndGroups = () => {
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentQuestion, setCurrentQuestion] = useState<
    QuestionGroupEntityMapValues | undefined
  >()
  const [currentFormBlock, setCurrentFormBlock] = useState<
    QuestionGroupEntityMapValues | undefined
  >()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { forms, loading: fetchLoadingForms } = useFormsQuery()

  const [currentForm, setCurrentForm] = useState<FormValues | undefined>()

  useEffect(() => {
    if (!fetchLoadingForms && forms.length && !currentForm) {
      setCurrentForm(forms[0])
    }
  }, [fetchLoadingForms, forms, currentForm])

  const {
    loading: fetchLoading,
    questionGroupEntityMaps = []
  } = useGetQuestionGroupEntityMaps(
    false,
    currentForm?.id,
    undefined,
    undefined,
    { skip: fetchLoadingForms || !currentForm }
  )

  useEffect(() => {
    if (!fetchLoading && questionGroupEntityMaps.length) {
      setCurrentFormBlock(questionGroupEntityMaps[0])
    }
  }, [fetchLoading, questionGroupEntityMaps])

  const questionGroups = useMemo(() => {
    if (
      !fetchLoading &&
      questionGroupEntityMaps &&
      questionGroupEntityMaps.length
    ) {
      let result = questionGroupEntityMaps ?? []
      if (currentFormBlock) {
        result = result.filter(
          item => item.questionGroupId === currentFormBlock.entityId
        )
      }
      return (result ?? []) as QuestionGroupEntityMapValues[]
    }
    return []
  }, [fetchLoading, currentFormBlock, questionGroupEntityMaps])

  const [search, setSearch] = useState<string | undefined>(undefined)

  const debouncedSearch = useDebounce(search, 300)

  const [data, setData] = useState<QuestionGroupEntityMapValues[]>(
    questionGroups
  )

  useEffect(() => {
    if ((fetchLoading || fetchLoadingForms) && !isLoading) {
      setIsLoading(true)
    }
  }, [fetchLoading, fetchLoadingForms, isLoading])

  useEffect(() => {
    if (!fetchLoading) {
      let newData: QuestionGroupEntityMapValues[] = [...questionGroups] ?? []

      if (debouncedSearch != null) {
        newData = newData.filter(question => {
          const allTerms = [
            ...Object.keys(question),
            ...Object.values(question).map(
              value => String(JSON.stringify(value)) ?? String(value)
            )
          ]
          const includeTerm = allTerms.find(term =>
            term.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
          if (includeTerm) {
            return true
          } else {
            return false
          }
        })
      }
      newData = newData.sort((a, b) => (a?.sequence ?? 0) - (b?.sequence ?? 0))
      setData(newData)
      setIsLoading(false)
    }
  }, [questionGroups, fetchLoading, debouncedSearch])

  const handleEditQuestion = (question: QuestionGroupEntityMapValues) => {
    setCurrentQuestion(question)
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const handleCreateQuestion = () => {
    setCurrentQuestion(undefined)
    setIsOpen(true)
  }

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps = useTable({
    data,
    config: getQuestionGroupEntityMapsTableConfig({
      handleEditQuestion
    })
  })

  const formsOptions: DropdownOption[] = forms?.map(form => ({
    label: form.name,
    value: form.id
  }))

  const formBlocksOptions = getSubBlockOptions(
    questionGroupEntityMaps.filter(
      block => block.questionGroupId === currentForm?.questionGroupId
    ),
    questionGroupEntityMaps
  )

  return (
    <View
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <View
        style={{
          width: '100%',
          height: 50,
          marginTop: 10,
          paddingHorizontal: 10,
          flexDirection: 'row'
        }}
      >
        {/* Filters */}
        <View
          style={{
            marginHorizontal: 10,
            height: '90%',
            width: '80%',
            flexDirection: 'row'
          }}
        >
          <TextInput
            style={{
              minHeight: 0,
              alignSelf: 'center'
            }}
            containerStyles={{
              width: 200,
              height: 50,
              marginRight: 10
            }}
            placeholder="Search..."
            value={search}
            onChangeText={setSearch}
          />
          <Dropdown
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentForm?.id ?? null}
            containerStyle={{ width: 250, height: 50, marginRight: 10 }}
            isLoading={fetchLoading}
            isDisabled={fetchLoading}
            options={formsOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => {
              const form = forms.find(form => form.id === value)
              setCurrentForm(form)
            }}
            name={'formTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
          <Dropdown
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentFormBlock?.id ?? null}
            containerStyle={{ width: 300, height: 50, marginRight: 10 }}
            isLoading={fetchLoading}
            isDisabled={fetchLoading}
            options={formBlocksOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => {
              const questionGroup = questionGroupEntityMaps.find(
                qg => qg.id === value
              )
              setCurrentFormBlock(questionGroup)
            }}
            name={'formTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
        </View>
        <CreateIcon
          style={{ alignSelf: 'flex-end' }}
          disabled={!currentForm?.id}
          isLoading={false}
          title={t(
            'platformManagement:forms:menuItems:formBlockQuestions:addNew'
          )}
          onPress={handleCreateQuestion}
        />
      </View>

      {/* Table */}
      <View
        style={{
          flex: 1,
          paddingHorizontal: 10
        }}
      >
        <SkeletonContainer isLoading={isLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t('platformManagement:categories:noCategories')}
            />
          </TableContainer>
        </SkeletonContainer>
      </View>
      {currentForm && currentFormBlock?.entityId ? (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          question={currentQuestion}
          form={currentForm}
          questions={questionGroups}
          questionGroupId={currentFormBlock?.entityId}
        />
      ) : null}
    </View>
  )
}

export default FormBlockQuestionsAndGroups
