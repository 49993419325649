import React, { useCallback } from 'react'
import * as Linking from 'expo-linking'
import { TouchableOpacity, Platform } from 'react-native'

const Link = ({ to, children, ...rest }) => {
  const onPress = useCallback(() => {
    Linking.openURL(to)
  }, [to])

  if (Platform.OS === 'web') {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    )
  } else {
    return (
      <TouchableOpacity onPress={onPress} {...rest}>
        {children}
      </TouchableOpacity>
    )
  }
}

export default Link
