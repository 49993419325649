import { useIsFocused } from '@react-navigation/native'

import React, { useEffect, useMemo, useState } from 'react'
import {
  MultiSelectDropDown,
  MultiSelectDropDownProps
} from '../../../../components/DynamicForm/MultiSelect'
import { DynamicTable, useTable } from '../../../../components/Table'
import useTranslation from '../../../../hooks/useTranslation'
import { CallForSubmission, Person } from '../../../../types'
import { getAdvisorsTableConfig } from '../../../Curation/constants'
import { UsersSelectorView } from '../../components/SharedComponents'

interface AdvisorUsersSelectorProps {
  callForSubmission: CallForSubmission
  handleSelectionChange: (value: boolean) => void
  setAdvisorsData: (values: any) => void
  loadingCallForSubmissionAdvisors: boolean
}

const getEmailAddress = (person: Person, t: any) => {
  if (person?.emailAddresses && person?.emailAddresses.length > 0) {
    return person?.emailAddresses[0].email
  }
  return t('curation:advisors:table:noEmail')
}

const AdvisorUsersSelector = ({
  callForSubmission,
  handleSelectionChange,
  setAdvisorsData,
  loadingCallForSubmissionAdvisors
}: AdvisorUsersSelectorProps) => {
  const [advisors, setAdvisors] = useState<any[]>([])
  const { t } = useTranslation()

  const isFocused = useIsFocused()

  const currentAdvisors = useMemo(
    () =>
      callForSubmission?.advisorsUsers?.map(adv => ({
        callForSubmissionId: callForSubmission.id,
        id: `${adv?.id}`,
        value: `${adv?.id}`,
        roles: adv?.roles,
        person: adv?.person,
        cfsSubmissionReviews: adv?.cfsSubmissionReviews,
        submissionsCount: adv?.submissionsCount,
        label: `${adv?.person.firstName} ${
          adv?.person.lastName
        } (${getEmailAddress(adv?.person, t)})`,
        fullName: `${adv?.person?.firstName} ${adv?.person?.lastName}`,
        custom: { employerName: adv?.person?.employerName || '' }
      })),
    [callForSubmission?.advisorsUsers, isFocused]
  )

  const tableProps = useTable({
    config: getAdvisorsTableConfig()
  })

  const handleSelectUser = (ids: any[], userList?: any[]) => {
    const isDeletion = ids?.length < (advisors ? advisors?.length : -1)
    let newAdvisors: any[]

    if (isDeletion) {
      newAdvisors = advisors?.filter(adv => ids?.includes(adv.value))
    } else {
      const toAdd = ids?.filter(
        id => advisors?.findIndex(adv => adv.value === id) === -1
      )
      newAdvisors = [
        ...advisors,
        ...(userList
          ? userList
              .filter(user => toAdd.includes(user.value))
              .map(user => ({ ...user, isUnSaved: true }))
          : [])
      ]
    }
    const hasChanged =
      newAdvisors.length !== currentAdvisors?.length ||
      newAdvisors.filter(
        newAdv =>
          currentAdvisors?.findIndex(
            currAdv => currAdv.value === newAdv.value
          ) === -1
      ).length > 0
    if (hasChanged) {
      const toRemove =
        currentAdvisors
          ?.filter(
            adv => !newAdvisors?.map(nAdv => nAdv.value).includes(adv.value)
          )
          ?.map(tr => tr.value as string) ?? []
      const toAdd =
        newAdvisors
          ?.filter(
            nAdv =>
              currentAdvisors?.findIndex(adv => adv.value === nAdv.value) === -1
          )
          .map(ta => ta.value as string) ?? []
      setAdvisorsData([toAdd, toRemove])
    }

    handleSelectionChange(hasChanged)
    setAdvisors(newAdvisors)
  }

  useEffect(() => {
    if (currentAdvisors && currentAdvisors?.length > 0) {
      setAdvisors(currentAdvisors)
    }
  }, [currentAdvisors])

  const multiSelectProps: MultiSelectDropDownProps = {
    isUserSelector: true,
    label: t('curation:general:advisors'),
    name: 'userSelectorMultiSelect',
    multiSelectValues: advisors,
    styles: {},
    isDisabled: loadingCallForSubmissionAdvisors,
    initialValues: advisors,
    handleSelect: handleSelectUser,
    callForSubmissionId: callForSubmission.id
  }

  return (
    <UsersSelectorView>
      <MultiSelectDropDown {...multiSelectProps} />
      <DynamicTable
        {...tableProps}
        data={advisors}
        emptyMessage={t('curation:general:noAdvisors')}
      />
    </UsersSelectorView>
  )
}

export default AdvisorUsersSelector
