import React from 'react'
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import Icon, { IconProps } from './index'

export interface TouchableIconProps extends IconProps {
  onPress?: any
  withFeedback?: boolean
  disabled?: boolean
  testID?: string
}

const TouchableIcon = ({
  name,
  testID,
  style = {},
  onPress = () => {},
  withFeedback = true,
  disabled = false,
  ...rest
}: TouchableIconProps) => {
  return withFeedback ? (
    <TouchableOpacity
      testID={testID ?? undefined}
      style={style}
      onPress={onPress}
      disabled={disabled}
    >
      <Icon name={name} {...rest} />
    </TouchableOpacity>
  ) : (
    <TouchableWithoutFeedback
      testID={testID ?? undefined}
      style={style}
      onPress={onPress}
    >
      <Icon name={name} {...rest} />
    </TouchableWithoutFeedback>
  )
}

export default TouchableIcon
