import React, { useCallback, useEffect, useRef } from 'react'
import { Text, TouchableOpacity, View, Animated, ViewStyle } from 'react-native'
import { useRecoilState } from 'recoil'
import toastMessageAtom, { MessageStatus } from '../recoil/toastMessageAtom'
import styled from 'styled-components/native'

const ToastSlider = styled(Animated.View)`
  width: 100%;
  position: absolute;
  top: 100%;
  overflow: hidden;
  box-shadow: 0px 6px 5px rgb(0 0 0 / 17%);
  z-index: 999;
`
const ToastContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
`
const ToastText = styled(Text)`
  color: #fff;
  font-size: 18px;
`

interface ToastAnchorProps {
  style?: ViewStyle
}

export default function ToastAnchor({ style = {} }: ToastAnchorProps) {
  let [{ message, status }, setToastMessage] = useRecoilState(toastMessageAtom)

  const hasMessage = !!message

  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 66,
      duration: 200,
      useNativeDriver: true
    }).start()
  }, [fadeAnim])

  const fadeOut = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true
    }).start()
  }, [fadeAnim])

  useEffect(() => {
    if (hasMessage) {
      fadeIn()
    } else {
      fadeOut()
    }
  }, [hasMessage])

  return (
    <ToastSlider
      style={[
        {
          height: fadeAnim,
          backgroundColor:
            status === MessageStatus.Error
              ? '#ED435C'
              : status === MessageStatus.Warning
              ? '#F9C80E'
              : '#85adc3'
        },
        style
      ]}
    >
      {hasMessage && (
        <ToastContainer>
          <ToastText>{message}</ToastText>
          <TouchableOpacity
            onPress={() =>
              setToastMessage({
                message: '',
                status: MessageStatus.Success
              })
            }
          >
            <ToastText>×</ToastText>
          </TouchableOpacity>
        </ToastContainer>
      )}
    </ToastSlider>
  )
}
