import React from 'react'
import { View } from 'react-native'

import theme from '../../constants/Theme'
import {
  ScreenContainerWithMenuHeader,
  StyledScrollViewContainer
} from '../../components/layout/ScreenContainer'
import CallsForSubmission from './CallsForSubmission'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

const HomeScreen = () => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <ScreenContainerWithMenuHeader screenCategory="home">
      <StyledScrollViewContainer>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: theme.space[2],
            paddingTop: isSmallScreen ? theme.space[3] : theme.space[2]
          }}
        >
          <CallsForSubmission />
        </View>
      </StyledScrollViewContainer>
    </ScreenContainerWithMenuHeader>
  )
}

export default HomeScreen
