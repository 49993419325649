import { atomFamily, CallbackInterface } from 'recoil'
import { QuestionGroup } from '../types'

export const questionGroupAtomFamily = atomFamily<QuestionGroup | null, string>(
  {
    key: 'questionGroupState',
    default: null
  }
)

export const setQuestionGroupCallback = ({ set }: CallbackInterface) => (
  groups: QuestionGroup[]
) => {
  for (const group of groups) {
    set(questionGroupAtomFamily(group.id), group)
  }
}
