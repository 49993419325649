import React, { useContext } from 'react'
import { DEFAULT_PAGE_SIZE, TableContext } from '../../../hooks/TableProvider'
import AppSearchTable from './AppSearch'
import FlatListComponents from './Components'

const FlatListTable = (props: any): any => {
  const {
    leftTableWidth,
    rigthTableWidth,
    config: {
      name,
      pageSize = DEFAULT_PAGE_SIZE,
      enableFilters,
      engine,
      queryConditions,
      queryDynamicName,
      hitKey,
      customQueryFields,
      skipCache,
      skipFetchData,
      fetchingDataOnFocus = true,
      fetchPolicy,
      isSticky
    },
    data,
    keyExtractor,
    loading,
    sortBy,
    sortDir,
    queryFilters,
    searchFiltersValue,
    emptyMessage,
    EmptyComponent,
    handleSetData,
    handleTotalElements
  } = useContext(TableContext)

  const { headerRef } = props

  // If enableFiltets = true => Render Dynamic Table with App Seach Query
  return enableFilters ? (
    <AppSearchTable
      keyExtractor={keyExtractor}
      leftTableWidth={leftTableWidth}
      name={name}
      pageSize={pageSize}
      rigthTableWidth={rigthTableWidth}
      sortBy={sortBy}
      sortDir={sortDir}
      queryFilters={queryFilters}
      searchFilters={searchFiltersValue}
      engine={engine}
      queryConditions={queryConditions}
      queryDynamicName={queryDynamicName}
      hitKey={hitKey}
      emptyMessage={emptyMessage}
      EmptyComponent={EmptyComponent}
      loading={loading}
      customQueryFields={customQueryFields}
      handleSetData={handleSetData}
      handleTotalElements={handleTotalElements}
      skipCache={skipCache}
      skipFetchData={skipFetchData}
      fetchingDataOnFocus={fetchingDataOnFocus}
      isSticky={isSticky}
      fetchPolicy={fetchPolicy}
      headerRef={headerRef}
    />
  ) : (
    <FlatListComponents
      leftTableWidth={leftTableWidth}
      rigthTableWidth={rigthTableWidth}
      sortBy={sortBy}
      sortDir={sortDir}
      name={name}
      isSticky={isSticky}
      data={data ?? []}
      keyExtractor={keyExtractor}
      pageSize={pageSize}
      emptyMessage={emptyMessage}
      EmptyComponent={EmptyComponent}
      loading={loading}
      headerRef={headerRef}
    />
  )
}

export default FlatListTable
