import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import InnovatorDirectoryList from './InnovatorDirectoryList'
import InnovatorDirectoryDetails from './InnovatorDirectoryDetails'
import InnovatorDirectoryProfile from './InnovatorDirectoryProfile'
import TeamMembers from '../Settings/TeamMembers'
import { InnovatorDirectoryNavigatorParamList } from '../../types/rootStackParamsList'

const InnovatorDirectoryStack = createStackNavigator<InnovatorDirectoryNavigatorParamList>()

const InnovatorDirectoryNavigator = () => {
  return (
    <InnovatorDirectoryStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="InnovatorDirectoryList"
    >
      <InnovatorDirectoryStack.Screen
        name="InnovatorDirectoryList"
        component={InnovatorDirectoryList}
      />
      <InnovatorDirectoryStack.Screen
        name="InnovatorDirectoryProfile"
        component={InnovatorDirectoryProfile}
      />
      <InnovatorDirectoryStack.Screen
        name="InnovatorDirectoryDetails"
        component={InnovatorDirectoryDetails}
      />
      <InnovatorDirectoryStack.Screen
        name="InnovatorDirectoryMembers"
        component={TeamMembers}
      />
    </InnovatorDirectoryStack.Navigator>
  )
}

export default InnovatorDirectoryNavigator
