import React from 'react'

import CallForSubmissionTable from '../CallForSubmission/CallForSubmissionTable'
import { Flex } from '../../components/FlexBox'
import StaffHomeSkeleton from './StaffHomeSkeleton'
import SubmitterHomeSkeleton from './SubmitterHomeSkeleton'

import useTranslation from '../../hooks/useTranslation'
import useHasPermission from '../../hooks/useHasPermission'
import { INNOVATOR_TEAM_PERMISSIONS } from '../../constants/roles'
import EmptyCFSPrompt from './EmptyCFSPrompt'
import {
  CallForSubmission,
  CallForSubmissionStepEnum,
  CallForSubmissionStepStatusEnum,
  RoleEnum
} from '../../types'
import {
  EngineEnum,
  getCFSTableConfig,
  getInnovatorHomePageCFSTableConfig,
  TablesEnum
} from '../CallForSubmission/constants'
import AdvisorHomePageTitle from './Advisor/content'
import useInnovationAccess from '../../hooks/useInnovationAccess'

const CallsForSubmission = () => {
  const { t } = useTranslation()
  const isInnovator = useHasPermission(INNOVATOR_TEAM_PERMISSIONS)
  const emptyComponent = EmptyCFSPrompt

  const {
    callForSubmissionSettings: canEditSettings,
    curate: canCurate,
    advise: canAdvise
  } = useInnovationAccess()

  const isOnlyAdvisor = canAdvise && !canCurate
  const tables = [
    {
      id: TablesEnum.CurrentOpenCalls,
      role: RoleEnum.InnovatorTeamAdmin,
      title: t('homeScreen:currentOpenCalls'),
      emptyComponent,
      shouldShow: isInnovator,
      showTitleOnEmpty: false
    },
    {
      id: TablesEnum.Curate,
      role: RoleEnum.InnovationStaff,
      title: t('curation:sideBar:curate'),
      emptyComponent,
      shouldShow: canCurate,
      showTitleOnEmpty: canCurate
    },
    {
      id: TablesEnum.Advise,
      role: RoleEnum.InnovationAdvisor,
      title: t('curation:sideBar:advise'),
      emptyComponent,
      shouldShow: canAdvise,
      showTitleOnEmpty: canAdvise
    }
  ]

  const showCurrentCFSFilter = role => (cfs: CallForSubmission) => {
    if (
      role === RoleEnum.InnovatorTeamAdmin ||
      role === RoleEnum.InnovatorTeamMember
    ) {
      return cfs?.currentStep?.name === CallForSubmissionStepEnum.submission
    } else if (role === RoleEnum.InnovationAdvisor) {
      return (
        cfs?.currentStep?.name === CallForSubmissionStepEnum.advising &&
        cfs?.currentStep?.status === CallForSubmissionStepStatusEnum.open
      )
    }
    return true
  }

  return (
    <Flex
      marginTop={canAdvise ? 10 : isInnovator ? 16 : 20}
      width={'100%'}
      height={isInnovator ? '600px' : '350px'}
    >
      {isOnlyAdvisor && <AdvisorHomePageTitle />}
      {tables.map((table, index) =>
        table.shouldShow ? (
          <CallForSubmissionTable
            tableId={table.id}
            Skeleton={isInnovator ? SubmitterHomeSkeleton : StaffHomeSkeleton}
            skeletonProps={
              table.role === RoleEnum.InnovationAdvisor
                ? { isAdvisor: canAdvise }
                : {}
            }
            isInnovator={isInnovator}
            engine={EngineEnum.InnovationCallForSubmission}
            key={`cfsHomeTable-${index}`}
            role={table.role}
            EmptyComponent={table.emptyComponent}
            title={table.title}
            showTitleOnEmpty={table.showTitleOnEmpty}
            dataFilter={showCurrentCFSFilter(table.role)}
            canEditSettings={canEditSettings}
            configCallback={
              isInnovator
                ? getInnovatorHomePageCFSTableConfig
                : getCFSTableConfig
            }
          />
        ) : null
      )}
    </Flex>
  )
}

export default CallsForSubmission
