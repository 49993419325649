import React, { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { Title } from './SettingsStyledComponents'
import { CallForSubmission } from '../../../../types'
import useHistoryRoutes from '../../../../hooks/useHistoryRoutes'
import { Flex } from '../../../../components/FlexBox'
import ArrowLeftIcon from '../../../../components/icon/ArrowLeftIcon'
import useLinkToScreen from '../../../../hooks/useLinkToScreen'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import useCFSStepDatesRangeDiff from '../../../Curation/hooks/useCFSStepDatesRangeDiff'

const Container = styled(View)`
  ${({ theme }) => `
    width: 100%;
    padding: ${theme.space[4]}px ${theme.space[4]}px ${theme.space[4]}px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${theme.colors.tabBorder}
    
`}
`

interface HeaderNavProps {
  callForSubmission: CallForSubmission
  goBack?: () => void
  extraContent?: string
}

// JC: We don't want to use the internal Settings tabs navigation history
const ROUTES_TO_EXCLUDE = [
  'GeneralSettings',
  'AdvisingSettings',
  'CFSSettings',
  'TestingSettings',
  'CreateSubmissionsTestSettings',
  'SubmissionsTestSettings',
  'PreCurateTestSettings',
  'AdvisingTestSettings'
]

const getLastRoute = history => {
  const filteredHistory = history.filter(
    h => !ROUTES_TO_EXCLUDE.includes(h?.screen)
  )

  return filteredHistory.length >= 2
    ? filteredHistory[filteredHistory.length - 2]
    : null
}

const HeaderNav: FC<HeaderNavProps> = ({
  callForSubmission,
  extraContent,
  goBack
}) => {
  const linkToScreen = useLinkToScreen()
  const { colors, space } = useTheme()
  const isSmallScreen = useIsSmallScreen()
  const { history } = useHistoryRoutes()

  const { cfsTitleStep } = useCFSStepDatesRangeDiff(callForSubmission)

  return (
    <Container>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <TouchableOpacity
          onPress={() => {
            if (goBack) {
              goBack()
            } else {
              let routeName = 'CurationSubmissions'
              let params = {
                id: callForSubmission?.id
              }

              const lastRoute = getLastRoute(history)

              if (lastRoute && lastRoute?.screen) {
                routeName = lastRoute.screen
                params = lastRoute?.params || params
              }

              linkToScreen(routeName, params)
            }
          }}
          style={{
            marginRight: space[3] + space[1] // 20
          }}
        >
          <ArrowLeftIcon color={colors.lightGrey} />
        </TouchableOpacity>
        <Title isSmallScreen={isSmallScreen}>
          {cfsTitleStep}
          {extraContent ? `» ${extraContent}` : ''}
        </Title>
      </Flex>
    </Container>
  )
}

export default HeaderNav
