import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { Delegation } from '../../../types'

const delegations = gql`
  query delegations {
    delegations {
      id
      name
      description
      forms {
        id
        type
        name
      }
    }
  }
`

interface DelegationObject {
  delegations: Delegation[]
  loading: boolean
}

const useDelegationsQuery = (): DelegationObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(delegations, {
    errorMessage: t('error:general:getDelegations')
  })

  return {
    delegations: data?.delegations,
    loading
  }
}

export default useDelegationsQuery
