import React from 'react'
import styled from 'styled-components/native'
import useTranslation from '../../../hooks/useTranslation'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

import { Flex } from '../../../components/FlexBox'
import Link from '../../../components/Link'
import theme from '../../../constants/Theme'
import { H1, Text } from '../../../components/common/Text'

const P = styled(Text)`
  color: ${props => props.theme.colors.text};
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
  text-align: justify;
`

const ContactText = styled(P)`
  margin-bottom: 0;
`

const Title = styled(H1).attrs(
  ({ theme: { space, fontSizes, colors }, isSmallScreen }) => ({
    styles: {
      marginBottom: space[3],
      marginTop: isSmallScreen ? space[2] : 0,
      textAlign: isSmallScreen ? 'center' : 'left',
      color: colors.primary
    },
    h1Style: {
      fontSize: fontSizes[7],
      fontWeight: 'bold'
    }
  })
)``

const Content = () => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()

  return (
    <Flex
      flexDirection="column"
      flexBasis={isSmallScreen ? 'auto' : 'fit-content'}
      justifyContent="flex-start"
      alignItems={isSmallScreen ? 'center' : 'flex-start'}
      marginBottom={isSmallScreen ? 0 : 20}
    >
      <Title isSmallScreen={isSmallScreen}>
        {t('homeScreen:advisor:title')}
      </Title>
      <P>{t('homeScreen:advisor:description:paragraph1')}</P>

      <P>{t('homeScreen:advisor:description:paragraph2')}</P>

      <P>
        {t('homeScreen:advisor:description:paragraph3')}
        <ContactText>
          <P>{t('homeScreen:advisor:description:teamMembers:ehir:name')}</P>
          <Link
            to={`mailto:${t(
              'homeScreen:advisor:description:teamMembers:ehir:email'
            )}`}
            title={t('homeScreen:advisor:description:teamMembers:ehir:email')}
            target="_blank"
            style={{
              color: theme.colors.primary,
              textDecoration: 'none'
            }}
          >
            {`(${t('homeScreen:advisor:description:teamMembers:ehir:email')})`}
          </Link>
          <P>{` or `}</P>
          <P>{t('homeScreen:advisor:description:teamMembers:santos:name')}</P>
          <Link
            to={`mailto:${t(
              'homeScreen:advisor:description:teamMembers:santos:email'
            )}`}
            title={t('homeScreen:advisor:description:teamMembers:santos:email')}
            target="_blank"
            style={{
              color: theme.colors.primary,
              textDecoration: 'none'
            }}
          >
            {`(${t(
              'homeScreen:advisor:description:teamMembers:santos:email'
            )})`}
          </Link>
          <P>{t('homeScreen:advisor:description:teamMembers:end')}</P>
        </ContactText>
      </P>
      <P>{t('homeScreen:advisor:description:lastParagraph')}</P>
    </Flex>
  )
}

export default Content
