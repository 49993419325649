import { useSubscription } from '@apollo/client'
import innovationSubmissionUpdatedSubscription from '../subscriptions/innovationSubmissionUpdatedSubscription'
import { submissionsQuery } from './useSubmissionsQuery'
import { InnovationSubmissionStatusEnum } from '../../../types'

const onInnovationSubmissionUpdated = (
  client,
  subscriptionData,
  query,
  statuses
) => {
  let options = { query }
  if (statuses?.length) {
    options['variables'] = { statuses }
  }

  const cachedQuery = client.readQuery({
    ...options
  })
  const updatedInnovationSubmission =
    subscriptionData.data.innovationSubmissionUpdated

  const updatedInnovationSubmissions = (
    cachedQuery?.innovationSubmissions || []
  ).map(submission => {
    if (submission.id !== updatedInnovationSubmission.id) {
      return submission
    }
    return updatedInnovationSubmission
  })

  client.writeQuery({
    ...options,
    data: {
      ...cachedQuery,
      innovationSubmissions: updatedInnovationSubmissions
    }
  })
}

const useUpdatedInnovationSubmissionSubscription = (
  statuses?: [InnovationSubmissionStatusEnum]
) => {
  let variables
  if (statuses) {
    variables = { statuses }
  }
  useSubscription(innovationSubmissionUpdatedSubscription, {
    variables,
    onData: ({ client, data }) => {
      onInnovationSubmissionUpdated(client, data, submissionsQuery, statuses)
    }
  })
}

export default useUpdatedInnovationSubmissionSubscription
