import useCurrentUser from './useCurrentUser'
import { RoleEnum } from '../types'

const useGetFirstRole = (): RoleEnum => {
  const { roles } = useCurrentUser()

  return roles?.length ? roles[0] : RoleEnum.InnovatorTeamAdmin
}

export default useGetFirstRole
