import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const upsertInnovationCategoryMutation = gql`
  mutation upsertInnovationCategory($input: InnovationCategoryInput!) {
    upsertInnovationCategory(input: $input) {
      id
      name
      marketSegments {
        id
        name
      }
    }
  }
`

const useUpsertInnovationCategory = (options = {}) => {
  const [
    upsertInnovationCategory,
    { loading: loadingUpsertInnovationCategory }
  ]: any[] = useMutation(upsertInnovationCategoryMutation, options)

  return [upsertInnovationCategory, loadingUpsertInnovationCategory]
}

export default useUpsertInnovationCategory
