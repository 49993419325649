export const DASHBOARD_BREAKPOINT = 1400

export const reviewQuestionsSystemLabelMap = {
  marketFit: 'submission-review-2-market-fit',
  ii: 'submission-review-2-innovative',
  fundamentals: 'submission-review-2-fundamentals',
  traction: 'submission-review-2-traction',
  submission: 'submission-review-2-submission',
  directFeedback: 'submission-review-2-internal-feedback',
  internalFeedback: 'submission-review-2-direct-feedback',
  academy: 'submission-review-2-academy',
  favorites: 'submission-review-2-favorite'
}
