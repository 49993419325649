import React, { FC, useMemo, useState } from 'react'
import { ViewStyle } from 'react-native'
import withNavigationPermissions from '../../navigation/withNavigationPermissions'

import { ScreenContainerWithTabs } from '../../components/layout/ScreenContainer'
import { DynamicTable, useTable } from '../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'

import { Container } from '../CallForSubmission/components/SharedComponents'
import { TableContainer } from '../../components/StaticForm'
import { Flex } from '../../components/FlexBox'
import Dropdown, { DropdownValue } from '../../components/common/Dropdown'

import { getPreCurationTableConfig } from './constants'

import {
  CallForSubmission,
  CallForSubmissionStepEnum,
  InnovationAccessKey,
  RoleEnum
} from '../../types'

import useTranslation from '../../hooks/useTranslation'
import { EngineEnum } from '../CallForSubmission/constants'
import { Question } from '../../types/form'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'
import { StyledLabel } from '../../ui-library/TextInput/Label'
import useCategoriesByMarketSegmentQuery from '../CallForSubmission/hooks/useCategoriesByMarketSegmentQuery'

export interface SubmissionsProps {
  id: string
  role: RoleEnum
  currentCFSStep: CallForSubmissionStepEnum | null
  delegations: any[]
  reviewFormQuestions?: Question[]
  advisorsUsers?: any[]
  callForSubmission?: CallForSubmission
  canEditAll?: boolean
}

const PreCurationScreen: FC<SubmissionsProps> = ({
  id,
  role,
  canEditAll,
  currentCFSStep,
  callForSubmission
}) => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { t } = useTranslation()
  const { innovationCategories, loading } = useCategoriesByMarketSegmentQuery({
    marketSegmentId: callForSubmission?.marketSegments?.[0]?.id
  })

  const categoryList = useMemo(
    () =>
      !loading
        ? innovationCategories?.map(category => ({
            label: category.name,
            value: category.id
          }))
        : [],
    [loading, innovationCategories]
  )

  const statusFilterOptions = [
    {
      label: 'Fast Pass',
      value: 'Fast Pass'
    },
    {
      label: 'Pass',
      value: 'Pass'
    },
    {
      label: 'Cut',
      value: 'Cut'
    },
    {
      label: 'Flagged',
      value: 'Flagged'
    }
  ]

  const [
    categoryFilteredBy,
    setCategoryFilterBy
  ] = useState<DropdownValue | null>(null)
  const [statusFilteredBy, setStatusFilterBy] = useState<string>()

  const tableProps = useTable({
    config: getPreCurationTableConfig({
      role,
      currentCFSStep,
      engine: EngineEnum.InnovationSubmission,
      cfsId: id,
      canEditAll,
      isInnovationNewDynamicTable,
      passOrCutText: statusFilteredBy,
      categoryFilteredBy: (categoryFilteredBy?.length
        ? categoryFilteredBy
        : []) as string[]
    }) as any
  })

  const categoryStyle: ViewStyle = {
    minHeight: 0,
    marginLeft: 16,
    marginBottom: 0,
    maxWidth: '25vw'
  }

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  return (
    <ScreenContainerWithTabs>
      <Container>
        <Flex flexDirection="row" alignItems="center" marginBottom={16}>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
          <Dropdown
            testID={'categoryFilter'}
            value={categoryFilteredBy}
            options={categoryList}
            onSelect={setCategoryFilterBy}
            isFilter
            isClearable
            isMulti
            style={categoryStyle}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            name={t('innovatorDirectory:table:category')}
            placeholder={t('innovatorDirectory:table:category')}
          />
          <Dropdown
            testID={'statusFilter'}
            name={t('curation:advisors:cfsStatus')}
            value={statusFilteredBy as any}
            placeholder={'Status'}
            options={statusFilterOptions}
            onSelect={setStatusFilterBy}
            isFilter
            isClearable
            style={{ marginBottom: 0, marginLeft: 32, minHeight: 0 }}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
          />
        </Flex>
        <TableContainer height="100%">
          <TableComponent
            {...(tableProps as any)}
            emptyMessage={t('curation:submissions:noSubmissions')}
          />
        </TableContainer>
      </Container>
    </ScreenContainerWithTabs>
  )
}

const PreCuration = withNavigationPermissions(PreCurationScreen, [
  InnovationAccessKey.CURATE
])

export default PreCuration
