import { Text } from '../common/Text'
import styled from 'styled-components/native'

export default styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    color: ${colors.text2};
    fontSize: ${fontSizes[6]}px;
    fontWeight: ${fontWeights.light}px;
    margin-bottom: ${space[2]}px;
`}
`
