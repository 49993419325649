import useTranslation from './useTranslation'

declare function SchemaWithTranslation(t: (key: string) => string)

const useSchemaWithTranslation = (schema: typeof SchemaWithTranslation) => {
  const { t } = useTranslation()

  return schema(t)
}

export default useSchemaWithTranslation
