import React, { useMemo, useState } from 'react'
// Hooks
import useTranslation from '../../../../../../hooks/useTranslation'
// Components
import Modal from '../../../../../../components/common/Modal'
// Types
import { ModalContainer, Title } from '../../../../constants'
import {
  ModalTabProps,
  ModalTabsEnum,
  QuestionModalProps
} from '../../../types'
import { View } from 'react-native'
import { ModalCloseIcon } from '../../../../../../components/DynamicTable/components/common/ModalDialog/SharedStyledComponents'
import Form from './Form'
import Validations from './Validations'
import Dependencies from './Dependencies'
import { ModalTab } from '../../../../SharedStyledComponents'

const QuestionModal = (props: QuestionModalProps) => {
  const { isOpen, onClose, question, isParent, formType, questionType } = props
  const [activeOption, setActiveOption] = useState<ModalTabsEnum>(
    ModalTabsEnum.General
  )
  // Hooks
  const { t } = useTranslation()

  const modalTabs: ModalTabProps[] = [
    {
      tabKey: ModalTabsEnum.General,
      label: 'platformManagement:forms:menuItems:questions:general',
      component: props => <Form {...props} />
    }
  ]

  const isEdit = !!question

  if (isEdit) {
    modalTabs.push(
      {
        tabKey: ModalTabsEnum.Validations,
        label: 'platformManagement:forms:menuItems:questions:validations',
        component: props => <Validations {...props} />
      },
      {
        tabKey: ModalTabsEnum.Dependencies,
        label: 'platformManagement:forms:menuItems:questions:dependencies',
        component: props => <Dependencies {...props} />
      }
    )
  }

  const CurrentComponent = useMemo(
    () => modalTabs.find(opt => opt.tabKey === activeOption)?.component,

    [question, activeOption]
  )

  const componentProps = {
    onClose,
    isParent,
    question,
    isEdit,
    formType,
    questionType
  }
  return (
    <Modal close={onClose} open={isOpen} styles={{ width: 700 }}>
      <View
        style={{
          width: '100%',
          height: 30,
          flexDirection: 'row'
        }}
      >
        <Title>
          {t(
            `platformManagement:forms:menuItems:questions:${
              isEdit ? 'editQuestion' : 'addNew'
            }`
          )}
        </Title>
        <ModalCloseIcon onPress={onClose} />
      </View>
      <ModalContainer style={{ marginTop: 10 }}>
        <View style={{ width: '100%', height: 35, flexDirection: 'row' }}>
          {modalTabs.map(tab => (
            <ModalTab
              key={tab.tabKey}
              label={tab.label}
              tabKey={tab.tabKey}
              isActive={activeOption === tab.tabKey}
              setActiveOption={setActiveOption}
            />
          ))}
        </View>
        {CurrentComponent ? <CurrentComponent {...componentProps} /> : null}
      </ModalContainer>
    </Modal>
  )
}

export default QuestionModal
