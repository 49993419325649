import gql from 'graphql-tag'

const InnovationSubmissionCohortFieldsFragment = gql`
  fragment InnovationSubmissionFieldsFragment on InnovationSubmission {
    id
    pdfCloudinaryId
    company {
      name
    }
    callForSubmissionId
    callForSubmission {
      name
    }
    submissionInfo {
      categoryLabel
      name
    }
    submissionAverage {
      wass
    }
    cohorts {
      group {
        id
        name
        shortName
      }
      deletedById
      isConfirmed
      isInvite
      isSpotlight
    }
    submissionVotes {
      company {
        id
        name
      }
      vote
      favorite
      reason
    }
  }
`

export default InnovationSubmissionCohortFieldsFragment
