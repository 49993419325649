import { useCallback, useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import {
  areFormsInSubmissionPhaseSelector,
  areFormsValidatingSelector,
  haveAllFormsPassedValidationSelector,
  productFormsAtom,
  setAllFormsPhase
} from '../recoil/productFormsAtom'

import { ValidationPhaseEnum } from '../types/form'

/**
 * When implemented, check is run whenever validation status changes on whether
 * a submission is in progress and whether validation has passed. If so, then
 * the `openDialog` Recoil atom is set to `true`, which shows the submission
 * confirmation dialog.
 */
const useSubmitDialogState = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const productTabNames = useRecoilValue(productFormsAtom)
  const setAllFormsPhaseCallback = useRecoilCallback(setAllFormsPhase)

  const areFormsValidating = useRecoilValue(areFormsValidatingSelector)
  const areFormsInSubmission = useRecoilValue(areFormsInSubmissionPhaseSelector)
  const haveAllFormsPassedValidation = useRecoilValue(
    haveAllFormsPassedValidationSelector
  )
  const closeDialog = useCallback(() => {
    setOpenDialog(false)
  }, [])

  useEffect(() => {
    if (areFormsInSubmission && !areFormsValidating) {
      // wsrast: This phase is being set here because the phase controls the
      // visibility of the submission confirmation dialog box. Leaving this as
      // "submit" will immediately reopen the dialog when you press the cancel
      // button.
      setAllFormsPhaseCallback(productTabNames, ValidationPhaseEnum.Save)

      if (haveAllFormsPassedValidation) {
        if (!openDialog) {
          setOpenDialog(true)
        }
      }
    }
  }, [
    areFormsInSubmission,
    areFormsValidating,
    openDialog,
    haveAllFormsPassedValidation
  ])

  return {
    openDialog,
    closeDialog
  }
}

export default useSubmitDialogState
