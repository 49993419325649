import React, { FC, useEffect, useState } from 'react'
import { useIsFocused, useRoute } from '@react-navigation/native'
import { Flex } from '../../components/FlexBox'
import {
  Container,
  Title
} from '../CallForSubmission/components/SharedComponents'
import ScreenContainer, {
  ScreenContainerWithMenuHeader
} from '../../components/layout/ScreenContainer'
import useCallForSubmissionQuery from '../Curation/hooks/useCallForSubmissionQuery'
import useNewCallForSubmissionQuery from '../Curation/hooks/useNewCallForSubmissionQuery'
import { RoleEnum } from '../../types'
import { DynamicTable, useTable } from '../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'
import { getAdvisingTableConfig } from '../Curation/constants'
import { EngineEnum } from '../CallForSubmission/constants'
import { TableContainer } from '../../components/StaticForm'
import { UnsavedChangesDialog } from '../../components/layout/sharedComponents'
import useAdvisorQuery from '../CallForSubmission/hooks/useAdvisorQuery'
// Hooks
import useTranslation from '../../hooks/useTranslation'
import useCurrentUser from '../../hooks/useCurrentUser'
import useCFSStepDatesRangeDiff from '../Curation/hooks/useCFSStepDatesRangeDiff'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'
import useUnsavedAdvisorChanges from '../../hooks/unsavedAdvisorChanges/useUnsavedAdvisorChanges'
import useLinkToScreen from '../../hooks/useLinkToScreen'

const AdviseSubmissions: FC = () => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { t } = useTranslation()
  const route: any = useRoute()
  const linkToScreen = useLinkToScreen()
  const id = route?.params?.id
  const { currentUser } = useCurrentUser()
  const { callForSubmission, loading } = useCallForSubmissionQuery(
    isInnovationNewDynamicTable ? false : id,
    false,
    false,
    true
  )
  const {
    callForSubmission: newCallForSubmission,
    loading: loadingNewCFS
  } = useNewCallForSubmissionQuery(id, !isInnovationNewDynamicTable)
  const isFocus = useIsFocused()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const { currentStep } = callForSubmission || {}

  const { advisorInfo, loading: loadingAdvisor } = useAdvisorQuery(
    id,
    currentUser?.id,
    !isFocus
  )

  const newLoading = isInnovationNewDynamicTable ? loadingNewCFS : loading

  const cfs = isInnovationNewDynamicTable
    ? newCallForSubmission
    : callForSubmission

  const { cfsTitleStep } = useCFSStepDatesRangeDiff(cfs)

  const tableProps = useTable({
    // @ts-ignore
    config: getAdvisingTableConfig({
      t,
      role: RoleEnum.InnovationAdvisor,
      currentCFSStep: currentStep?.name,
      engine: EngineEnum.InnovationSubmission,
      cfsId: id,
      submissionIdsToReview: (advisorInfo?.submissionsToReview ?? []).map(
        sr => sr.submissionId
      ),
      totalAdvised: advisorInfo?.totalAdvised,
      advisorId: currentUser?.id,
      isInnovationNewDynamicTable
    })
  })

  // Unsaved advisor changes
  const {
    resetState,
    setUnsavedAdvisorState,
    hasUnsavedChanges,
    formRouteState,
    unsavedValues
  } = useUnsavedAdvisorChanges()

  // Dialog events/effects
  const onCloseDialog = () => {
    resetState()
    setShowDialog(false)
  }
  const onSubmitDialog = () => {
    setShowDialog(false)
    setUnsavedAdvisorState({
      formRouteState,
      unsavedValues,
      shouldInitForm: true
    })
    linkToScreen(formRouteState?.name, { ...formRouteState?.params })
  }

  useEffect(() => {
    if (isFocus && hasUnsavedChanges && formRouteState) {
      setShowDialog(true)
    } else if (isFocus && showDialog && !hasUnsavedChanges && !formRouteState) {
      setShowDialog(false)
    }
  }, [showDialog, isFocus, hasUnsavedChanges, formRouteState])

  if (newLoading || !cfs || loadingAdvisor) {
    return null
  }

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  return (
    <ScreenContainerWithMenuHeader screenCategory="curation">
      <Flex justifyContent="left">
        <Title text={cfsTitleStep ?? ''} />
      </Flex>
      <ScreenContainer header="menu-header" style={{ padding: 0 }}>
        <Container>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t('curation:submissions:noSubmissions')}
            />
          </TableContainer>
        </Container>
      </ScreenContainer>
      <UnsavedChangesDialog
        description={t('common:dialogs:unsavedAdvisorChanges:description')}
        showDialog={showDialog}
        setShowDialog={onCloseDialog}
        discardChangesCallback={onCloseDialog}
        saveChangesCallback={() => {}}
        actions={[
          {
            title: t('common:dialogs:unsavedAdvisorChanges:discardBtn'),
            type: 'outline',
            onPress: onCloseDialog
          },
          {
            title: t('common:dialogs:unsavedAdvisorChanges:saveBtn'),
            onPress: onSubmitDialog
          }
        ]}
      />
    </ScreenContainerWithMenuHeader>
  )
}

export default AdviseSubmissions
