import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled, { ThemeContext } from 'styled-components/native'

import { useField } from 'formik'
import { useRecoilValue } from 'recoil'
import { find, pick, isEmpty } from 'ramda'

import {
  Answer,
  Question,
  QuestionDependency,
  ValidationTypeEnum
} from './types'

import Checkbox from '../../ui-library/CheckBox'
import { View, ViewStyle } from 'react-native'
import {
  ErrorContainer,
  ErrorText
} from '../../screens/authentication/components/SharedStyledComponents'
import useTranslation from '../../hooks/useTranslation'
import useMultiSelectDropdownOptions from './hooks/useMultiSelectDropdownOptions'
import { Option, QuestionOptionsArgs } from './DropDown'
import { Flex } from '../FlexBox'
import Dropdown from '../common/Dropdown'
import Icon from '../icon'
import { StyledLabel } from '../../ui-library/TextInput/Label'
import useQuestionQuery from '../../screens/Product/hooks/useQuestionQuery'
import useMultiselectMenuOpen from '../../hooks/useMultiselectMenuOpen'
import useUpsertAnswersMutation from '../../hooks/useUpsertAnswersMutation'
import useDeleteAnswersMutation from '../../hooks/useDeleteAnswersMutation'
import useQuestionDependency from './hooks/useQuestionDependency'
import useSearchPeopleQuery from '../../hooks/useSearchPeopleQuery'
import useDebounce from '../../hooks/useDebounce'
// Types
import { OriginSourceEnum } from '../../types'
import { QuestionSubTypeEnum } from '../../types/form'
// Recoil
import { formSubmissionMetaDataAtomFamily } from './recoil/formSubmissionMetaDataAtomFamily'
import { marketSegmentsMetaDataAtomFamily } from './recoil/marketSegmentsMetaDataAtomFamily'
import Tooltip from '../../ui-library/TextInput/Tooltip'
export interface MultiSelectOption {
  label: string
  value: string
}

export interface MultiSelectInputDataProps {
  isIndependent?: boolean
  name: string
  styles?: any
  isReadOnly?: boolean
  isHidden?: boolean
  minLength?: number
  options: MultiSelectOption[]
  questionOptions: QuestionOptionsArgs
  formSubmissionId: string
  question: Question
  questionDependencyIds?: QuestionDependency[]
  label: string
}

export interface DynamicMultiSelectInputDataProps {
  data: MultiSelectInputDataProps
  isReadOnly?: boolean
  menuIsOpen?: boolean | null
  setMenuIsOpen?: (boolean) => void
  submitOnBlur?: boolean
  callbackAfterAction?: () => void
}

const MultiSelectView = styled(View)`
  width: fit-content;
  ${({ menuIsOpen }) => `
    z-index: ${menuIsOpen ? '999999' : '0'};
  `}
`
const MultiSelectDropDownContainer = styled(Flex)`
  ${({ menuIsOpen }) => `
    z-index: ${menuIsOpen ? '999999' : '0'};
  `}
`

interface MultiSelectDropDownStyle extends ViewStyle {
  labelStyles?: any
}
export interface MultiSelectDropDownProps {
  isUserSelector?: boolean
  styles: MultiSelectDropDownStyle
  label: string
  name: string
  multiSelectOptions?: Option[]
  MAX_AMOUNT?: number
  multiSelectValues?: any
  helperText?: string
  helperTextProps?: any
  handleSelect: (ids: any[], array?: any[]) => any
  onInputChange?: any
  setSearchTerms?: any
  isLoading?: boolean
  isDisabled?: boolean
  initialValues?: any[]
  callForSubmissionId?: string
  menuIsOpen?: boolean | null
  setMenuIsOpen?: (boolean) => void | null
  menuPortalTarget?: HTMLElement | null
}

export const MultiSelectDropDown = (props: MultiSelectDropDownProps) => {
  const {
    isUserSelector = false,
    MAX_AMOUNT,
    handleSelect,
    label,
    helperText,
    helperTextProps = {},
    multiSelectOptions,
    multiSelectValues,
    name,
    styles = {},
    initialValues = [],
    callForSubmissionId,
    isDisabled,
    menuIsOpen = null,
    setMenuIsOpen = null,
    menuPortalTarget = null,
    ...rest
  } = props
  const [open, setOpen] = useState(false)
  const [userSearchTerm, setUserSearchTerm] = useState(null)
  const debouncedUsersTerm = useDebounce(userSearchTerm, 300)
  const { data: userList } = useSearchPeopleQuery({
    query: isUserSelector ? debouncedUsersTerm : null,
    cfsId: callForSubmissionId
  })
  const theme: any = useContext(ThemeContext)

  const onInputChange = (input: any) => {
    if (setMenuIsOpen) {
      setMenuIsOpen?.(input)
    } else {
      setOpen(input)
    }
  }

  const isVisible = !isDisabled || (isDisabled && multiSelectValues?.length > 0)

  return isVisible ? (
    <MultiSelectDropDownContainer
      menuIsOpen={menuIsOpen || open}
      flexDirection="column"
      style={styles}
    >
      {helperText ? (
        <Tooltip text={helperText} {...helperTextProps}>
          <StyledLabel styles={styles?.labelStyles}>{label}</StyledLabel>
        </Tooltip>
      ) : (
        <StyledLabel styles={styles?.labelStyles}>{label}</StyledLabel>
      )}
      <Dropdown
        menuPortalTarget={menuPortalTarget}
        name={name}
        options={[
          ...initialValues,
          ...(isUserSelector ? userList : multiSelectOptions)
        ]}
        maxAmount={MAX_AMOUNT}
        placeholder={''}
        isMulti
        closeMenuOnSelect={false}
        dropdownIcon={
          <Icon
            name="addSecondaryCategories"
            width={41}
            height={22}
            color={theme.colors.dropDownIndicatorColor}
          />
        }
        style={{
          backgroundColor: 'transparent',
          ...styles
        }}
        value={multiSelectValues.map(answer => answer.value)}
        onSelect={
          isUserSelector
            ? ids => {
                handleSelect(ids, userList)
              }
            : handleSelect
        }
        onInputChange={isUserSelector ? setUserSearchTerm : undefined}
        isDisabled={isDisabled}
        menuIsOpen={menuIsOpen || open}
        onMenuOpen={() => onInputChange(true)}
        onMenuClose={() => onInputChange(false)}
        {...rest}
      />
    </MultiSelectDropDownContainer>
  ) : null
}

const DynamicDropdownMultiSelect = (
  props: DynamicMultiSelectInputDataProps
) => {
  const {
    data,
    isReadOnly,
    submitOnBlur = true,
    menuIsOpen,
    callbackAfterAction = () => {}
  } = props
  const {
    name,
    formSubmissionId,
    question,
    questionOptions,
    questionDependencyIds = [],
    label,
    styles
  } = data
  const { t } = useTranslation()
  const {
    answers,
    refetchQuestion,
    loading: questionLoading
  } = useQuestionQuery(question.id, formSubmissionId)
  const { setMenuOpened } = useMultiselectMenuOpen()
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)
  const [multiSelectValues, setMultiSelectValues] = useState<Answer[]>([])
  const [initMultiSelectValues, setInitMultiSelectValues] = useState<Answer[]>(
    []
  )
  const [field] = useField(name)

  const questionDependency = questionDependencyIds[0]

  const [parentField] = useField(`questions.${questionDependency?.question1Id}`)

  const { visible } = useQuestionDependency(
    questionDependencyIds?.map(qd => qd?.id),
    question.id,
    field?.value
  )

  const [upsertAnswers, loadingUpsertAnswer] = useUpsertAnswersMutation({
    onError: e => console.error(e),
    onCompleted: ({ upsertAnswers }) => {
      if (!upsertAnswers || isEmpty(upsertAnswers)) {
        console.warn(
          `No upsertAnswers returned from upsertAnswers mutation: `,
          upsertAnswers
        )
      }
    },
    errorMessage: t('error:form:answerQuestions')
  })

  const [deleteAnswer, loadingDeleteAnswers] = useDeleteAnswersMutation()

  useEffect(() => {
    if (answers) {
      setMultiSelectValues(answers as Answer[])
      setInitMultiSelectValues(answers as Answer[])
    }
  }, [answers])

  const cfsMetaData = useRecoilValue(
    formSubmissionMetaDataAtomFamily(formSubmissionId)
  )
  const marketSegmentsMetaData = useRecoilValue(
    marketSegmentsMetaDataAtomFamily(formSubmissionId)
  )
  const { options } = useMultiSelectDropdownOptions(questionOptions, {
    cfsMetaData,
    marketSegmentsMetaData
  })

  const multiSelectOptions = useMemo(
    () =>
      options
        .filter(opt => opt.value !== parentField.value)
        .filter(
          opt =>
            opt.value &&
            !questionDependency?.value?.removedValues?.includes(opt.value)
        ),
    [parentField, options, questionDependency?.value?.removedValues]
  )
  const questionValidationMax = question?.questionValidationMaps?.filter(
    qvm => qvm.validation.type === ValidationTypeEnum.max
  )[0]
  const MAX_AMOUNT = questionValidationMax?.value?.length || undefined

  const removeAllAnswers = async () => {
    setMultiSelectValues([])
    await deleteAnswer({
      variables: {
        ids: initMultiSelectValues.map(answer => answer.id)
      }
    })
    refetchQuestion()
  }

  const fetchChanges = async (ids: string[]) => {
    let thereAreChanges: boolean = false
    const newItems = ids.filter(
      el => initMultiSelectValues.map(msv => msv.value).indexOf(el) === -1
    )
    const removed = initMultiSelectValues.filter(
      ({ value }) => ids.indexOf(value) === -1
    )

    if (removed.length) {
      thereAreChanges = true
      setFetchLoading(true)
      await deleteAnswer({
        variables: {
          ids: removed.map(answer => answer.id)
        }
      })
    }

    if (newItems.length) {
      thereAreChanges = true
      setFetchLoading(true)
      const newAnswers: Answer[] = []
      for (let index = 0; index < newItems.length; index++) {
        const toAdd = newItems[index]
        const newAnswer: Answer = {
          questionId: question.id,
          formSubmissionId,
          value: toAdd
        }
        newAnswers.push(newAnswer)
      }
      await upsertAnswers({
        variables: {
          answers: newAnswers,
          dynamic: true,
          originSource: OriginSourceEnum.Innovation
        }
      })
    }
    if (thereAreChanges) {
      refetchQuestion()
      callbackAfterAction()
    }
    setFetchLoading(false)
  }

  const handleSelect = (ids: string[]) => {
    if (submitOnBlur) {
      if (ids?.length) {
        const newValues = ids.map(
          id =>
            ({
              value: id,
              questionId: question.id,
              formSubmissionId
            } as Answer)
        )
        setMultiSelectValues(newValues)
      } else {
        removeAllAnswers()
      }
    } else {
      fetchChanges(ids)
    }
  }

  const setMenuIsOpen = (input: boolean) => {
    if (!input && submitOnBlur && !fetchLoading) {
      fetchChanges(
        multiSelectValues?.length
          ? multiSelectValues?.map(answer => answer.value)
          : []
      )
    }
    setMenuOpened(input)
    props?.setMenuIsOpen?.(input)
  }

  const isLoading: boolean =
    fetchLoading ||
    questionLoading ||
    loadingUpsertAnswer ||
    loadingDeleteAnswers

  return visible ? (
    <MultiSelectDropDown
      name={name}
      label={label}
      MAX_AMOUNT={MAX_AMOUNT}
      isLoading={isLoading}
      handleSelect={handleSelect}
      multiSelectOptions={multiSelectOptions}
      multiSelectValues={multiSelectValues}
      styles={styles}
      isDisabled={isReadOnly}
      menuIsOpen={menuIsOpen || null}
      setMenuIsOpen={setMenuIsOpen}
    />
  ) : null
}

const DynamicCheckBoxMultiSelect = (
  props: DynamicMultiSelectInputDataProps
) => {
  const { data, isReadOnly } = props
  const { name, label, styles, options, isHidden, minLength, question } = data
  const { t } = useTranslation()
  const [field, meta, helpers] = useField({
    name,
    type: 'multiSelect'
  })

  const configData = question.configData
  const showLabel = configData?.showLabel ?? false

  const selectedOptions: string[] = field.value || []
  const showError = !isReadOnly && !!meta.error && meta.touched

  const handleChange = useCallback(
    option => () => {
      if (!isReadOnly) {
        const newValue = selectedOptions.find(value => option === value)
          ? selectedOptions.filter(value => value !== option)
          : [...selectedOptions, option]

        helpers.setValue(newValue)
        helpers.setTouched(true)
      }
    },
    [selectedOptions, isReadOnly]
  )

  return (
    <>
      {showLabel && (
        <StyledLabel styles={styles?.labelStyles}>{label}</StyledLabel>
      )}
      {options?.map((option, i) => (
        <Checkbox
          testID={`checkbox-${option.value}`}
          key={`option-${name}-${i}`}
          label={option.label}
          checked={!!selectedOptions?.find?.(value => option.value === value)}
          onPress={handleChange(option.value)}
          isReadOnly={isReadOnly}
          isHidden={isHidden}
        />
      ))}
      {showError ? (
        <ErrorContainer style={styles?.errorContainerStyles}>
          <ErrorText>
            {t(meta.error as string, { length: minLength })}
          </ErrorText>
        </ErrorContainer>
      ) : null}
    </>
  )
}

const DynamicMultiSelect = (props: DynamicMultiSelectInputDataProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { data, isReadOnly = false, callbackAfterAction } = props
  const { questionOptions } = data
  const { subType } = questionOptions
  return (
    <MultiSelectView menuIsOpen={menuIsOpen}>
      {subType === QuestionSubTypeEnum.CheckboxGroup ? (
        <DynamicCheckBoxMultiSelect
          data={data}
          isReadOnly={isReadOnly}
          callbackAfterAction={callbackAfterAction}
        />
      ) : subType === QuestionSubTypeEnum.DbLookup ? (
        <DynamicDropdownMultiSelect
          data={data}
          isReadOnly={isReadOnly}
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
          submitOnBlur={props?.submitOnBlur}
          callbackAfterAction={callbackAfterAction}
        />
      ) : null}
    </MultiSelectView>
  )
}

export const transformMultiSelectData = (
  question: Question,
  formSubmissionId: string
): MultiSelectInputDataProps => {
  const validations = question?.questionValidationMaps || []
  const minValidation = find(
    validation => validation.validation.type === ValidationTypeEnum.min,
    validations
  )
  let minLength
  if (minValidation) {
    minLength = Number(
      typeof minValidation?.value === 'string'
        ? JSON.parse(minValidation?.value)?.length
        : minValidation?.value?.length
    )
  }

  return {
    name: `questions.${question?.id}`,
    label: question?.questionText,
    styles: pick(
      ['containerStyles', 'labelStyles', 'styles'],
      question?.style || {}
    ),
    minLength,
    questionOptions: {
      subType: question?.subType,
      optionsEntity: question?.optionsEntity,
      optionsValues: question?.optionsValues
    },
    formSubmissionId,
    question: question,
    questionDependencyIds: question?.questionDependencies,
    options: question.optionsValues
  }
}

export default DynamicMultiSelect
