// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'

export const PrintableLabel = styled.label`
  ${({ theme: { colors, fontWeights, fontFamily, fontSizes } }) => `
  font: ${fontSizes[5]}px ${fontFamily};
  font-weight: ${fontWeights.bold};
  color: ${colors.inputLabels};
  `}
`

export const TooltipContent = styled.span`
  ${({ theme: { colors, fontWeights, fontSizes } }) => `
  font-weight: ${fontWeights.regular};
  color: ${colors.tints.grays.g300};
  font-size: ${fontSizes[4]}px;
  `}
`

export const PrintableContainer = styled.div`
  ${({ theme: { space } }) => `
    margin: ${space[3]}px 0;
  `}
`

export const InputContainer = styled.div`
  ${({ theme: { space } }) => `
  margin-top: ${space[2]}px;
  width: 80%;
  
  @media print {
    width: 95%;
  }
  `}
`

export const Content = styled.p`
  ${({ theme: { colors, fontWeights, fontFamily } }) => `
  font: 14px ${fontFamily};
  font-weight: ${fontWeights.regular};
  color: ${colors.text};
  `}
`
