import { useEffect } from 'react'
import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import useCurrentFormAnswer from '../../../hooks/useCurrentFormAnswer'
import { FormAnswerState } from '../../../recoil/currentFormAnswerAtom'

export const formAnswerQuery = gql`
  query formAnswer(
    $submissionEntityId: String!
    $submissionEntityType: String
  ) {
    formAnswer(
      submissionEntityId: $submissionEntityId
      submissionEntityType: $submissionEntityType
    ) {
      id
      submissionEntityId
      submissionEntityType
    }
  }
`

const useFormAnswerQuery = (submissionEntityId, submissionEntityType) => {
  const { t } = useTranslation()
  const { currentFormAnswer, setCurrentFormAnswer } = useCurrentFormAnswer(
    submissionEntityId,
    submissionEntityType
  )

  const { data, loading } = useQuery(formAnswerQuery, {
    variables: { submissionEntityId, submissionEntityType },
    skip: !submissionEntityId,
    errorMessage: t('error:submission:getSubmission')
  })

  useEffect(() => {
    if (data && data?.formAnswer) {
      const formAnswerState = {
        currentFormAnswer: data?.formAnswer
      } as FormAnswerState

      setCurrentFormAnswer(formAnswerState)
    }
  }, [data])

  return { formAnswer: currentFormAnswer, loading }
}

export default useFormAnswerQuery
