import React, { useEffect } from 'react'
import useAppCoordinator from '../../../AppCoordinator/useAppCoordinator'
import { useRoute } from '@react-navigation/native'
import useInvitationUserQuery from '../hooks/useInvitationUserQuery'
import LoadingScreen from '../../LoadingScreen'
import RowLayout from '../../authentication/components/layout/RowLayout'
import PageHeader from '../../authentication/components/sharedComponents/PageHeader'
import useTranslation from '../../../hooks/useTranslation'
import { H4 } from '../../../components/common/Text'
import CodeComponent from '../AuthCodeScreen/CodeComponent'

const AuthCodeSentScreen = () => {
  const { send }: any = useAppCoordinator()
  const { params }: any = useRoute()
  const { t } = useTranslation()
  const userId = params.userId
  const { user, loading } = useInvitationUserQuery({ id: userId })

  useEffect(() => {
    send({
      type: 'SHOW_INVITATION_AUTH_CODE',
      payload: {
        userId
      }
    })
  }, [])

  return loading ? (
    <LoadingScreen />
  ) : (
    <RowLayout>
      {user ? (
        <>
          <PageHeader
            title={t('joinTeam:authCode:header')}
            description={t('joinTeam:authCode:description', {
              expiration: '48'
            })}
          />

          <H4
            styles={{
              textAlign: 'center',
              marginBottom: '20px'
            }}
          >
            {t('joinTeam:authCode:enter')}
          </H4>

          <CodeComponent currentUser={user} />
        </>
      ) : (
        <H4
          styles={{
            textAlign: 'center',
            marginBottom: '20px'
          }}
        >
          {t('joinTeam:error:notFound')}
        </H4>
      )}
    </RowLayout>
  )
}

export default AuthCodeSentScreen
