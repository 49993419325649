import React from 'react'
import {
  FlatListProps,
  FlatListTableComponentsProps,
  MainFlatListComponentProps
} from '../types'
import useIsSmallScreen from '../../../../../hooks/useIsSmallScreen'
import FlatListComponent from './FlatListComponent'
import {
  FlatTableContainer,
  FlatTableStyledContainer,
  ScrollViewContainer
} from '../SharedStyledComponents'
import { shouldMemoizeComponent } from '../../../constants'
import { Flex } from '../../../../FlexBox'
import { H4 } from '../../../../common/Text'
import { ActivityIndicator } from 'react-native'

function sortData(data, key, dir = 'asc') {
  return data.sort((a, b) => {
    // if key value is string, sort lowercase
    const firstVal = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]
    const secondVal = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]

    if (firstVal < secondVal) {
      return dir === 'asc' ? -1 : 1
    }
    if (firstVal > secondVal) {
      return dir === 'asc' ? 1 : -1
    }
    return 0
  })
}

const FlatListComponents = React.memo((props: FlatListTableComponentsProps) => {
  const {
    leftTableWidth,
    rigthTableWidth,
    name,
    data,
    keyExtractor,
    loading,
    pageSize,
    emptyMessage,
    EmptyComponent,
    isSmallScreen,
    sortBy,
    sortDir,
    isSticky,
    headerRef
  } = props

  const sortedData = sortBy && data ? sortData(data, sortBy, sortDir) : data

  const showEmptyMessage =
    !data?.length && !loading && emptyMessage && !EmptyComponent

  const showEmptyComponent = EmptyComponent && !loading && !data?.length

  const widthScroll = isSticky ? 0 : 100 - leftTableWidth

  const MainFlatListComponent = (props: MainFlatListComponentProps): any => (
    <FlatListComponent
      name={name}
      keyExtractor={keyExtractor}
      data={sortedData}
      pageSize={pageSize}
      isSticky={isSticky}
      headerRef={headerRef}
      {...props}
    />
  )

  return (
    <>
      <FlatTableStyledContainer>
        {isSmallScreen ? (
          <FlatTableContainer width={100}>
            <MainFlatListComponent leftTable />
          </FlatTableContainer>
        ) : (
          <>
            <FlatTableContainer width={isSticky ? 100 : leftTableWidth}>
              <MainFlatListComponent leftTable renderHeader />
            </FlatTableContainer>
            {!isSticky && (
              <ScrollViewContainer width={widthScroll}>
                <FlatTableContainer
                  width={(rigthTableWidth || 0) < 100 ? 100 : rigthTableWidth}
                >
                  <MainFlatListComponent renderHeader />
                </FlatTableContainer>
              </ScrollViewContainer>
            )}
          </>
        )}
      </FlatTableStyledContainer>
      {loading ? <ActivityIndicator size={'small'} /> : null}
      {showEmptyMessage ? (
        <Flex
          flexDirection="row"
          justifyContent="center"
          marginTop={20}
          width="100%"
        >
          <H4>{emptyMessage}</H4>
        </Flex>
      ) : showEmptyComponent ? (
        <EmptyComponent />
      ) : null}
    </>
  )
}, shouldMemoizeComponent)
// Added custom memoize function to check function and object props
// e.g keyExtractor: Function, fetchMoreResults: Function

const FlatList = (props: FlatListProps): any => {
  const isSmallScreen = useIsSmallScreen()
  return <FlatListComponents isSmallScreen={isSmallScreen} {...props} />
}
export default FlatList
