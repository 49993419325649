import { useRecoilState, SetterOrUpdater } from 'recoil'
import { pick } from 'ramda'
import currentFormAnswerAtom, {
  FormAnswerState
} from '../recoil/currentFormAnswerAtom'
import { AnswerEntityTypeEnum } from '../types/form'
import { FormAnswer } from '../types'

export interface CurrentFormAnswerHelpers {
  currentFormAnswer?: FormAnswer
  setCurrentFormAnswer: SetterOrUpdater<FormAnswerState | undefined>
}

const useCurrentFormAnswer = (
  submissionEntityId: string,
  submissionEntityType: AnswerEntityTypeEnum
): CurrentFormAnswerHelpers => {
  const [formAnswerState, setCurrentFormAnswer] = useRecoilState(
    currentFormAnswerAtom({ submissionEntityId, submissionEntityType })
  ) as [
    FormAnswerState | undefined,
    SetterOrUpdater<FormAnswerState | undefined>
  ]

  return {
    ...pick(['currentFormAnswer'], formAnswerState || {}),
    setCurrentFormAnswer
  }
}

export default useCurrentFormAnswer
