import React, { useState, useRef } from 'react'
import { Platform } from 'react-native'
import TextInput from '../../ui-library/TextInput'
import useTranslation from '../../hooks/useTranslation'
import useValidateEmailMutation from './mutations/useValidateEmailMutation'
import {
  AuthenticationErrorContainer,
  ErrorText
} from './components/SharedStyledComponents'
import { LoginCheckSubStateProps } from '../../AppCoordinator/LoginCheck'
import useLastAttemptedEmail from '../../hooks/useLastAttemptedEmail'

import RowLayout from './components/layout/RowLayout'
import PageHeader from './components/sharedComponents/PageHeader'
import CallToActionBtns from './components/sharedComponents/CallToActionBtns'
import { isRouteSearchParam } from './LoginScreen/hooks/useRedirectLoginByQueryParams'
// Types
import { ALLOWED_LOGIN_ROUTES } from '../../types/form'

const VerifyEmailScreen = ({ send }: LoginCheckSubStateProps) => {
  const {
    lastAttemptedEmail,
    updateLastAttemptedEmail
  } = useLastAttemptedEmail()
  const [email, setEmail] = useState(lastAttemptedEmail || '')
  const [error, setError] = useState('')
  const emailRef = useRef(null)
  const { t } = useTranslation()

  const [validateEmail, loading] = useValidateEmailMutation({
    variables: { email },
    onCompleted: async results => {
      const {
        userExists,
        isInnovator,
        isInnovationAdvisor,
        hasPendingTeamMemberInvite,
        userId = null,
        isNewUser,
        isEmailDomainRegistered
      } = results?.validateEmail
      if (
        isNewUser &&
        userExists &&
        !isEmailDomainRegistered &&
        !hasPendingTeamMemberInvite &&
        !isInnovationAdvisor
      ) {
        if (email !== lastAttemptedEmail) {
          await updateLastAttemptedEmail(email)
        }

        return send('CONTINUE_REGISTER')
      }

      if (!userExists) {
        return setError(t('error:auth:unknownUsername'))
      }
      if (!isInnovator) {
        return setError(t('error:auth:accountNotAuthorized'))
      }

      if (results?.validateEmail) {
        if (email !== lastAttemptedEmail) {
          await updateLastAttemptedEmail(email)
        }
        // JC: If it's a user that was invited we redirect them to the joinTeam workflow
        if (hasPendingTeamMemberInvite && userId) {
          if (Platform.OS === 'web') {
            // @ts-ignore
            window.location = `/joinTeam/${userId}`
          }
        } else {
          return send('EMAIL_VERIFIED')
        }
      }
    }
  })

  return (
    <RowLayout>
      <PageHeader
        title={t('auth:verifyEmail:header')}
        description={t('auth:verifyEmail:description')}
      />
      <TextInput
        value={email}
        label={t('auth:login:email')}
        onChangeText={setEmail}
        autoCapitalize="none"
        ref={emailRef}
        style={{ minWidth: '100%' }}
      />
      {!!error && (
        <AuthenticationErrorContainer>
          <ErrorText>{error}</ErrorText>
        </AuthenticationErrorContainer>
      )}

      <CallToActionBtns
        primaryBtn={{
          title: t('auth:buttons:verifyEmail'),
          onPress: validateEmail,
          isProcessing: loading
        }}
        secondaryBtn={{
          title: t('common:buttons:cancel'),
          onPress: () => {
            if (Platform.OS === 'web') {
              if (isRouteSearchParam(ALLOWED_LOGIN_ROUTES.FORGOT_PASSWORD)) {
                document.location.replace(window.location.pathname)
              } else {
                document.location.reload()
              }
            }
          }
        }}
      />
    </RowLayout>
  )
}

export default VerifyEmailScreen
