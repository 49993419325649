import { StepsObject } from '../types'

export interface CallForSubmissionStepsProps {
  steps: StepsObject[]
  total: number
}

export interface TimelineItemProps {
  isActive?: boolean
  title: string
  subtitle?: string
}

export enum InnovationTagTypeEnum {
  Summary = 'summary',
  Financial = 'financial',
  Technology = 'technology',
  Clinical = 'clinical',
  Clients = 'clients',
  Geography = 'geography',
  Feedback = 'feedback'
}
