import { atom, RecoilState } from 'recoil'

export interface SubmissionProps {
  submissionId: string | null
  delegationId: string | null
}

const lastSubmittedSubmissionAtom: RecoilState<SubmissionProps> = atom({
  key: 'submittedSubmission',
  default: {
    submissionId: null,
    delegationId: null
  } as SubmissionProps
})

export default lastSubmittedSubmissionAtom
