import React from 'react'
import styled from 'styled-components/native'

import { ButtonConfig } from '../../DynamicForm/layout'
import TouchableIcon from '../../icon/TouchableIcon'
import Button from '../../../ui-library/Button'
import { Text, H3 } from '../Text'
import Modal from '../Modal'
import { Flex } from '../../FlexBox'

import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

const Title = styled(H3).attrs(props => ({
  styles: {
    color: props.theme.colors.primary
  },
  h3Style: {
    fontSize: '24px',
    fontWeight: 600
  }
}))`
  margin-bottom: 10px;
`

interface DialogProps {
  isOpen: boolean
  title: string
  description: string
  onClose?: () => void
  actions?: ButtonConfig[]
  closeable?: boolean
}

const Dialog = ({
  isOpen,
  onClose,
  title,
  description,
  actions = [],
  closeable = true
}: DialogProps) => {
  const isSmallScreen = useIsSmallScreen()
  const titleStyle = isSmallScreen ? { fontSize: 15 } : {}
  const dialogActions = actions.map((action, index) => (
    <Button
      key={index}
      title={action.title}
      type={action.type || 'solid'}
      onPress={action.onPress}
      containerStyle={{
        flexBasis: '48%'
      }}
      buttonStyle={{
        width: '100%'
      }}
      titleStyle={titleStyle}
    />
  ))

  return (
    <Modal
      open={isOpen}
      close={onClose}
      styles={{
        maxWidth: isSmallScreen ? '340px' : '500px',
        minWidth: '340px'
      }}
    >
      <Title>{title}</Title>
      {closeable && <CloseIcon onPress={onClose} />}
      <Text
        styles={{
          paddingTop: 15,
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 15
        }}
      >
        {description}
      </Text>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {dialogActions}
      </Flex>
    </Modal>
  )
}

export default Dialog
