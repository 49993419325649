export const CSV_SEPARATOR_CHARACTER = ','

const EXCLUDED_COLUMNS = ['selectionItemColumn']

const addQuotesToCsvRowText = (rowContent: any): string => {
  const rowContentString = String(rowContent)
  return `"${rowContentString}"`
}

const getCsvHeaders = ({ config, t }) => {
  const {
    customCsv = false,
    getCustomCsvColumns = () => {},
    excludedColumns = []
  } = config
  const cols = customCsv
    ? getCustomCsvColumns
      ? getCustomCsvColumns(t)
      : null
    : config.columns
        .filter(
          col => ![...EXCLUDED_COLUMNS, ...excludedColumns].includes(col.id)
        )
        .map(col => (col?.header ? `"${t(col?.header)}"` : `"Unknown column"`))

  return cols && cols.join(CSV_SEPARATOR_CHARACTER)
}

const getCvsRows = ({ config, data, currentUserId, t }) => {
  const {
    customCsv = false,
    getCustomCsvData = () => {},
    getCustomCsvBody = () => {},
    excludedColumns = []
  } = config
  const csvBody = customCsv ? getCustomCsvBody && getCustomCsvBody(data) : null
  const rows = data.map(submission => {
    const result = customCsv
      ? getCustomCsvData(submission)
      : config.columns
          .filter(
            col => ![...EXCLUDED_COLUMNS, ...excludedColumns].includes(col.id)
          )
          .map(col => {
            if (col.getCsvData) {
              const rowContent = col.getCsvData({
                ...col,
                item: submission,
                role: col.role || '',
                currentUserId,
                t
              })
              return addQuotesToCsvRowText(rowContent)
            } else {
              throw new Error(
                `"Column: ${col.header} ${col.id} needs to implement getCsv"`
              )
            }
          })

    return customCsv ? result : result.join(CSV_SEPARATOR_CHARACTER)
  })
  return customCsv && csvBody ? csvBody : rows.join('\n')
}

export const getCsvData = ({ config, data, currentUserId, t }) => {
  const headers = getCsvHeaders({ config, t })
  const rows = getCvsRows({ config, data, currentUserId, t })

  return headers ? `${headers}\n${rows}` : `${rows}`
}
