import React, { useContext, useRef } from 'react'
import { TableContext } from '../../hooks/TableProvider'
import {
  InnerTableContainer,
  TableContainer,
  TableMessageText
} from './SharedStyledComponents'
import useTranslation from '../../../../hooks/useTranslation'
import { ActivityIndicator, View } from 'react-native'
import FlatListTable from './FlatList'

const Table = (): any => {
  const { t } = useTranslation()
  const {
    error,
    loading,
    config: { isSticky }
  } = useContext(TableContext)

  const headerContainerRef = useRef(null)

  const styckyStyles = {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: '#FFFFFF'
  }

  return loading ? (
    <ActivityIndicator size={'small'} />
  ) : (
    <InnerTableContainer>
      <View
        style={isSticky ? styckyStyles : {}}
        ref={isSticky ? headerContainerRef : null}
      />
      <TableContainer>
        {error && (
          <TableMessageText>
            {t('error:table:errorLoadingItems')}
          </TableMessageText>
        )}
        <FlatListTable headerRef={headerContainerRef} />
      </TableContainer>
    </InnerTableContainer>
  )
}

export default Table
