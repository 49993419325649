import React, { FC } from 'react'
import { useTheme } from 'styled-components/native'
import { View } from 'react-native'
import { Description, getStyles } from './SharedStyledComponents'
import Button from '../../../../ui-library/Button'
import useTranslation from '../../../../hooks/useTranslation'

interface UpdateOrCreateProductProps {
  onClose: (val?) => void
  onCreate: (val?) => void
  onUpdate: (val?) => void
  hasMatchingDelegationProducts: Boolean
}

const UpdateOrCreateProduct: FC<UpdateOrCreateProductProps> = ({
  onClose,
  onCreate,
  onUpdate,
  hasMatchingDelegationProducts
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { space, colors } = theme
  const { btnContainerStyles, btnStyles } = getStyles(theme)

  return (
    <>
      <View
        style={{
          marginBottom: space[2]
        }}
      >
        <Description>
          {t('directoryProfile:products:updateOrCreate:create')}
        </Description>
        <Description>
          {t('directoryProfile:products:updateOrCreate:update')}
        </Description>
      </View>
      <div style={{ display: 'flex' }}>
        <Button
          testID={'cancelProductModal'}
          title={t('product:general:cancel')}
          type="outline"
          buttonStyle={{
            ...btnStyles,
            borderColor: colors.buttonVariant
          }}
          titleStyle={{
            color: colors.buttonVariant
          }}
          containerStyle={{
            ...btnContainerStyles,
            marginRight: space[1]
          }}
          onPress={onClose}
        />
        <Button
          testID={'createProduct'}
          title={t('directoryProfile:products:updateOrCreate:btn:create')}
          buttonStyle={btnStyles}
          containerStyle={{
            ...btnContainerStyles,
            marginRight: space[1]
          }}
          onPress={onCreate}
        />
        <Button
          testID={'updateProduct'}
          title={t('directoryProfile:products:updateOrCreate:btn:update')}
          buttonStyle={btnStyles}
          containerStyle={btnContainerStyles}
          onPress={onUpdate}
          disabled={!hasMatchingDelegationProducts}
        />
      </div>
    </>
  )
}

export default UpdateOrCreateProduct
