import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { CSVTypeEnum } from '../../../components/DynamicTable/types'
import { CSVExtraParamsObject } from '../../../components/DynamicTable/components/SearchBar/Buttons/CsvButton/Button'

const callForSubmissionQuery = gql`
  query getSubmissionIdsByCallForSubmissionId(
    $callForSubmissionId: String!
    $csvType: CSVTypeEnum!
    $groupId: String
    $isPDF: Boolean
    $advisorId: String
    $extraParams: [CSVExtraParamsObject]
  ) {
    getSubmissionIdsByCallForSubmissionId(
      callForSubmissionId: $callForSubmissionId
      csvType: $csvType
      groupId: $groupId
      isPDF: $isPDF
      advisorId: $advisorId
      extraParams: $extraParams
    ) {
      id
      name
      pdfCloudinaryId
    }
  }
`

interface SubmissionObject {
  id: string
  pdfCloudinaryId?: string
  name?: string
}

const useGetSubmissionIdsByCallForSubmissionQuery = (
  callForSubmissionId: string,
  csvType: CSVTypeEnum,
  groupId?: string,
  isPDF?: boolean,
  skip?: boolean,
  advisorId?: string,
  extraParams?: CSVExtraParamsObject[]
) => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(callForSubmissionQuery, {
    variables: {
      callForSubmissionId,
      csvType,
      groupId,
      isPDF,
      advisorId,
      extraParams
    },
    skip: skip || !callForSubmissionId || !csvType,
    fetchPolicy: 'cache-first',
    errorMessage: t('error:submissions:getCallsForSubmission')
  })

  return {
    submissions: data?.getSubmissionIdsByCallForSubmissionId as SubmissionObject[],
    refetch,
    loading
  }
}

export default useGetSubmissionIdsByCallForSubmissionQuery
