import React, { FC } from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import useTranslation from '../../../../../../hooks/useTranslation'

import {
  InnovationSubmission,
  SubmissionReviewQuestionAverage
} from '../../../../../../types'
import { Flex } from '../../../../../../components/FlexBox'
import { Text } from '../../../../../../components/common/Text'
import Commentary from './Commentary'
import useToast from '../../../../../../hooks/useToast'
import { parseJson } from '../../../../../../utils/parseJson'
import TextInput from '../../../../../../ui-library/TextInput'

const Grid = styled(View)`
  margin: 8px 0;
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-template-rows: 1fr;
  grid-row-gap: ${props => props.gutterSize}px;
  grid-column-gap: ${props => props.gutterSize}px;
`
export const Title = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    color: ${colors.text2};
    fontSize: ${fontSizes[5]}px;
    fontWeight: ${fontWeights.medium};
    marginRight: ${space[1]}px;
`}
`
export const Score = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    marginTop: ${space[2]}px;
    color: ${colors.text};
    fontSize: ${fontSizes[6]}px;
    fontWeight: ${fontWeights.regular};
`}
`

interface AvgRatingsProps {
  submission: InnovationSubmission
}

const getConfigData = (entity, setToastErrorMessage) => {
  if (!entity) {
    return null
  }
  try {
    return entity?.configData ? parseJson(entity.configData) : undefined
  } catch (e) {
    setToastErrorMessage(
      'Error while parsing retrieving Submission Average information'
    )
    console.error(e)
  }
}

const AvgRatings: FC<AvgRatingsProps> = ({ submission }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { setToastErrorMessage } = useToast()
  const configData = getConfigData(
    submission?.submissionAverage,
    setToastErrorMessage
  )

  const commentaryQuestions = configData?.commentaryQuestions as SubmissionReviewQuestionAverage[]

  if (!submission?.submissionAverage?.submissionScores?.length) {
    return null
  }

  return (
    <Flex flexDirection="column" justifyContent="flex-start" w="100%">
      <View
        style={{
          marginBottom: theme.space[3]
        }}
      >
        <Flex
          flexDirection="column"
          justifyContent="flex-start"
          w="100%"
          alignItem="flex-end"
        >
          <Title>{t('curation:submissionReview:wass')}</Title>
          <Score>
            {submission?.submissionAverage?.wass?.toFixed(2) || '-'}
          </Score>
        </Flex>
      </View>

      <Grid columns={1} gutterSize={10}>
        {submission?.submissionAverage?.submissionScores?.map(
          (score, index) => {
            const { value, question } = score

            const configData = getConfigData(question, setToastErrorMessage)

            return (
              <TextInput
                key={`submissionScore-${index}`}
                label={question.questionText}
                helperText={configData?.helperText}
                value={value?.toFixed(2)}
                isReadOnly
                withHelperText
              />
            )
          }
        )}
      </Grid>

      <TextInput
        label={t('curation:submissionReview:totalFav')}
        value={submission?.submissionAverage?.totalFav || '-'}
        isReadOnly
      />
      <Flex flexDirection="column" justifyContent="flex-start" mt={10}>
        {commentaryQuestions?.map((question, index) => (
          <Commentary
            key={`commentary-${index}`}
            label={question.questionText}
            contents={question.answerContents}
          />
        ))}
      </Flex>
    </Flex>
  )
}
export default AvgRatings
