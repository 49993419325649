import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { InnovationCategory } from '../../../types'

const categoriesQuery = gql`
  query innovationCategories {
    innovationCategories {
      id
      name
      sort
    }
  }
`

interface InnovationCategoriesObject {
  innovationCategories: InnovationCategory[]
  loading: boolean
}

const useCategoriesQuery = (): InnovationCategoriesObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(categoriesQuery, {
    errorMessage: t('error:general:categories:error')
  })

  return {
    innovationCategories: data?.innovationCategories,
    loading
  }
}

export default useCategoriesQuery
