import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const AlertIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg
        width={size}
        height={size}
        x="0px"
        y="0px"
        viewBox={`0 0 128 128`}
        {...rest}
      >
        <Path
          d="M75.3 13.4c-5-8.7-17.6-8.7-22.6 0L1.8 101.6c-5 8.7 1.3 19.6 11.3 19.6h101.8c10.1 0 16.3-10.9 11.3-19.6L75.3 13.4zm-17.8 91.4v-13h13.1v13.1H57.5zm13-26.1h-13V46h13.1v32.7z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default AlertIcon
