import { atom } from 'recoil'

interface MultiselectMenuI {
  menuIsOpen: boolean
}

export const multiselectMenuAtomFamily = atom<MultiselectMenuI | null>({
  key: 'multiselectMenuState',
  default: {
    menuIsOpen: false
  }
})
