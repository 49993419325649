import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const updateInnovationOnboarded = gql`
  mutation updateInnovationOnboardedAt($personId: String!) {
    updateInnovationOnboardedAt(personId: $personId)
  }
`

interface MutationProps {
  variables: any
  onCompleted?: (results: any) => void
  onError?: (error: any) => void
}

const useUpdateInnovationOnboarded = (options: MutationProps) => {
  const [updateOnboarding]: any[] = useMutation(
    updateInnovationOnboarded,
    options
  )

  return updateOnboarding
}

export default useUpdateInnovationOnboarded
