import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'

const getInnovationSubmissionsCSVMutation = gql`
  mutation getInnovationSubmissionsCSV(
    $ids: [String]
    $callForSubmissionId: String
    $groupId: String
    $csvType: CSVTypeEnum!
    $advisorId: String
    $extraParams: [CSVExtraParamsObject]
  ) {
    getInnovationSubmissionsCSV(
      ids: $ids
      callForSubmissionId: $callForSubmissionId
      groupId: $groupId
      csvType: $csvType
      advisorId: $advisorId
      extraParams: $extraParams
    )
  }
`

const useGenerateCsvMutation = (options = {}) => {
  const [generateCsv, { data, loading }]: any[] = useMutation(
    getInnovationSubmissionsCSVMutation,
    options
  )

  return [generateCsv, data, loading]
}

export default useGenerateCsvMutation
