import { useEffect } from 'react'

const AppListener = ({ send, firebaseToken }: any) => {
  useEffect(() => {
    if (!firebaseToken) {
      send('LOGGED_OUT')
    }
  }, [firebaseToken])

  return null
}

export default AppListener
