import React, { FC } from 'react'
import { Text, View } from 'react-native'
import styled from 'styled-components/native'
import Modal from '../../../components/common/Modal'
import TouchableIcon from '../../../components/icon/TouchableIcon'
import theme from '../../../constants/Theme'
import { Title } from './SharedComponents'
import { Flex } from '../../../components/FlexBox'
import useTranslation from '../../../hooks/useTranslation'
import Button from '../../../ui-library/Button'
import useRemoveInnovatorProduct from '../hooks/useRemoveInnovatorProduct'

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[2]}px;
  `}
`

interface ProductDeletionModalProps {
  title: string
  isOpen: boolean
  onClose: (val?) => void
  onCancel: (val?) => void
  children?: JSX.Element | JSX.Element[]
  productId: string | null
}

const ProductDeletionModal: FC<ProductDeletionModalProps> = ({
  title,
  isOpen,
  onClose,
  productId,
  onCancel
}) => {
  const { t } = useTranslation()
  const { colors, space } = theme
  const { btnContainerStyles, btnStyles } = getStyles(theme)
  const {
    removeProduct,
    loading: isCreatingProduct
  } = useRemoveInnovatorProduct()

  const handleDelete = async () => {
    if (productId) {
      const res = await removeProduct(productId)
      if (res) {
        onClose()
      }
    }
  }

  return (
    <Modal
      close={onCancel}
      open={isOpen}
      styles={{
        minWidth: '150px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={onCancel} />
      <Flex padding={space[3]}>
        <Title>{title}</Title>
        <View
          style={{
            marginTop: space[2]
          }}
        >
          <View
            style={{
              marginBottom: space[2]
            }}
          >
            <Description>
              {t('directoryProfile:products:productDeletion:description')}
            </Description>
          </View>

          <div style={{ display: 'flex' }}>
            <Button
              title={t('product:general:cancel')}
              type="outline"
              buttonStyle={{
                ...btnStyles,
                borderColor: colors.buttonVariant
              }}
              titleStyle={{
                color: colors.buttonVariant
              }}
              containerStyle={{
                ...btnContainerStyles,
                marginRight: space[3]
              }}
              onPress={onCancel}
            />
            <Button
              title={t('product:general:delete')}
              buttonStyle={{
                ...btnStyles,
                backgroundColor: colors.dangerButton
              }}
              containerStyle={btnContainerStyles}
              onPress={handleDelete}
              loading={isCreatingProduct}
              disabled={!productId}
            />
          </div>
        </View>
      </Flex>
    </Modal>
  )
}

const getStyles = theme => ({
  btnContainerStyles: { height: theme.space[4], marginTop: theme.space[3] },
  btnStyles: {
    width: 'fit-content',
    paddingBottom: theme.space[3],
    paddingTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[2],
    paddingRight: theme.space[4] - theme.space[2],
    height: theme.space[4],
    paddingHorizontal: theme.space[4]
  }
})

export default ProductDeletionModal
