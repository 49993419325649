import gql from 'graphql-tag'
// Hooks
import useMutation from '../../../hooks/useMutation'
import useTranslation from '../../../hooks/useTranslation'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import { callForSubmissionQuery } from './useCallForSubmissionQuery'
import { cfsAdvisorQuery } from '../../CallForSubmission/hooks/useAdvisorQuery'
// Flags
import { NEW_DYNAMIC_TABLE_FF } from '../../../components/DynamicTable/constants'

export const upsertSubmissionsToReviewByAdvisorMutation = gql`
  mutation upsertSubmissionsToReviewByAdvisor(
    $assignAll: Boolean
    $callForSubmissionId: String
    $advisorId: String
    $submissionsIdsToAdd: [String]
    $submissionsIdsToRemove: [String]
    $extraParams: [CSVExtraParamsObject]
  ) {
    upsertSubmissionsToReviewByAdvisor(
      assignAll: $assignAll
      callForSubmissionId: $callForSubmissionId
      advisorId: $advisorId
      submissionsIdsToAdd: $submissionsIdsToAdd
      submissionsIdsToRemove: $submissionsIdsToRemove
      extraParams: $extraParams
    ) {
      userInfo {
        id
      }
      submissionsToReview {
        submissionId
      }
    }
  }
`

const useUpsertSubmissionsToReviewByAdvisor = (
  cfsId: string,
  advisorId: string
) => {
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)

  let refetchQueries: object[] = [
    {
      query: cfsAdvisorQuery,
      variables: {
        cfsId,
        advisorId
      }
    }
  ]

  if (!isInnovationNewDynamicTable) {
    refetchQueries.push({
      query: callForSubmissionQuery,
      variables: {
        id: cfsId,
        withSubmissionReviews: false,
        withSubmissionReviewsAverages: false,
        withInnovationSubmissions: true
      }
    })
  }

  const [upsertSubmissionsToReviewByAdvisor, { loading }] = useMutation(
    upsertSubmissionsToReviewByAdvisorMutation,
    {
      refetchQueries,
      errorMessage: t('error:save:default')
    }
  )

  return {
    upsertSubmissionsToReviewByAdvisor,
    loading
  }
}

export default useUpsertSubmissionsToReviewByAdvisor
