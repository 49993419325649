import Sentry from './sentry'

export const parseJson = (stringJson: string | object) => {
  if (typeof stringJson === 'string') {
    try {
      return JSON.parse(stringJson)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  return stringJson
}
