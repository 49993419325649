import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Person } from '../types'
import useTranslation from './useTranslation'

const searchPeopleQuery = gql`
  fragment personFragment on Person {
    id
    firstName
    lastName
    employerName
    emailAddresses {
      email
    }
    user {
      id
      roles
      cfsSubmissionsCount(cfsId: $cfsId)
      submissionsCount
    }
  }

  query searchPeople($query: String!, $index: [String], $cfsId: String!) {
    search(
      query: $query
      index: $index
      first: 20
      adminSearch: true
      includeNonMembers: true
      includeUnpublishedPeople: false
    ) {
      hits {
        __typename
        ... on Person {
          ...personFragment
        }
      }
    }
  }
`

export interface SearchPeopleVariablesI {
  index?: string[]
  query?: string
  cfsId?: string
}

const defaultVariables: SearchPeopleVariablesI = {
  index: ['person'],
  query: ''
}

const getEmailAddress = (person: Person, t: any) => {
  if (person?.emailAddresses && person?.emailAddresses.length > 0) {
    return person?.emailAddresses[0].email
  }
  return t('curation:advisors:table:noEmail')
}

const useSearchPeopleQuery = (variables?: SearchPeopleVariablesI) => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(searchPeopleQuery, {
    variables: { ...defaultVariables, ...variables },
    skip: !variables || !variables.query
  })

  const people =
    data && data.search && data.search?.hits
      ? data.search.hits?.filter(hit => !!hit)
      : []
  const mappedData = people.map(person => ({
    value: person?.user?.id,
    label: `${person?.firstName} ${person?.lastName} (${getEmailAddress(
      person,
      t
    )})`,
    fullName: `${person?.firstName} ${person?.lastName}`,
    custom: { employerName: person?.employerName ?? '' },
    roles: person?.user?.roles,
    cfsSubmissionsCount: person?.user?.cfsSubmissionReviews?.length,
    submissionsCount: person?.user?.submissionsCount,
    person
  }))

  return { data: mappedData, loading }
}

export default useSearchPeopleQuery
