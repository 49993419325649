import { useState, useEffect, RefObject } from 'react'

const useOnScreen = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new window.IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )
    if (ref?.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [ref])

  return isIntersecting
}

export default useOnScreen
