import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useRoute } from '@react-navigation/native'
import SettingsTabs from './SettingsTabs'
import SubmissionsSelection from './SubmissionsSelection'
import useInnovationAccess from '../../../hooks/useInnovationAccess'

const SettingsStack = createStackNavigator()

const SettingsNavigator = () => {
  const route: any = useRoute()
  const callForSubmissionId = route.params?.cfsId

  const { advisorAssignment } = useInnovationAccess()

  return (
    <SettingsStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="SettingsTabs"
    >
      <SettingsStack.Screen
        name="SettingsTabs"
        children={() => (
          <SettingsTabs callForSubmissionId={callForSubmissionId} />
        )}
      />
      {advisorAssignment ? (
        <SettingsStack.Screen
          name="SubmissionsSelection"
          children={() => (
            <SubmissionsSelection callForSubmissionId={callForSubmissionId} />
          )}
        />
      ) : null}
    </SettingsStack.Navigator>
  )
}

export default SettingsNavigator
