import React from 'react'
import styled from 'styled-components/native'

import useTranslation from '../../hooks/useTranslation'

import { H4 } from '../../components/common/Text'
import { Flex } from '../../components/FlexBox'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import useGetFirstRole from '../../hooks/useGetFirstRole'
import { RoleEnum } from '../../types'

const EmptyCFSPromptContainer = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  w: '100%',
  marginTop: '50px'
})``

const Wrapper = styled(Flex)`
  width: ${props => (props.isSmallScreen ? '100%' : '35%')};
`

const EmptyCFSPrompt = () => {
  const { t } = useTranslation()
  const role = useGetFirstRole()

  const localeKey =
    role === RoleEnum.InnovatorTeamAdmin ||
    role === RoleEnum.InnovatorTeamMember
      ? 'homeScreen:cfs:roles:innovationStaff:emptyCFS'
      : `homeScreen:cfs:roles:${role}:emptyCFS`

  const isSmallScreen = useIsSmallScreen()
  return (
    <EmptyCFSPromptContainer>
      <Wrapper isSmallScreen={isSmallScreen}>
        <H4 h4Style={{ textAlign: 'center' }}>{t(localeKey)}</H4>
      </Wrapper>
    </EmptyCFSPromptContainer>
  )
}

export default EmptyCFSPrompt
