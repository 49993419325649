import React, { useEffect } from 'react'

import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import { StyledScrollView } from '../authentication/components/SharedStyledComponents'
import { Flex } from '../../components/FlexBox'
import W50Logo from '../../components/icon/W50Logo'
import InnovationBg from '../authentication/components/sharedComponents/InnovationBg'
import { Title, P } from '../common/styledComponents'
import useLegalDocumentsQuery from '../Product/hooks/useLegalDocumentsQuery'
import { useRoute } from '@react-navigation/native'
import useAppCoordinator from '../../AppCoordinator/useAppCoordinator'

const LegalScreen = () => {
  const { params } = useRoute()
  // @ts-ignore
  const agreementType = params.type
  const { send }: any = useAppCoordinator()

  // We need to tell the stateMachine what's the new state as we're not logged in nor in the login state
  useEffect(() => {
    send({
      type: 'SHOW_LEGAL_SCREEN',
      payload: {
        agreementType
      }
    })
  }, [])

  const isSmallScreen = useIsSmallScreen()

  const { legalDocuments, loading } = useLegalDocumentsQuery(agreementType)

  if (loading || !legalDocuments) return null

  return (
    <InnovationBg>
      <StyledScrollView
        paddingTop={isSmallScreen ? '10px' : 0}
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center'
        }}
      >
        <Flex
          p={isSmallScreen ? 3 : 1}
          maxWidth="1366px"
          justifyContent="center"
          alignItems="center"
          flexBasis="auto"
        >
          <Flex alignItems="center" justifyContent="center" marginBottom="20px">
            <W50Logo
              width="124px"
              height="47px"
              style={{
                marginBottom: 15
              }}
            />
            <Title>{legalDocuments.title}</Title>
          </Flex>

          <P>{legalDocuments.body}</P>
        </Flex>
      </StyledScrollView>
    </InnovationBg>
  )
}

export default LegalScreen
