import React from 'react'
import { FormikProps } from 'formik'
import useTranslation from '../../../hooks/useTranslation'
import TextInput from '../../../ui-library/TextInput'
import { Flex } from '../../../components/FlexBox'
import {
  AuthenticationErrorContainer,
  ErrorText
} from './SharedStyledComponents'
import changeText from '../../../utils/changeFormikText'
import { FormHeader } from './sharedComponents/PageHeader'

interface PasswordMatchFormProps extends FormikProps<any> {}

const PasswordMatchForm = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
  touched
}: PasswordMatchFormProps) => {
  const { t } = useTranslation()
  const renderErrors = () => {
    return !!errors.password || !!errors.confirmPassword ? (
      <AuthenticationErrorContainer>
        {!!errors.password && !!touched.password ? (
          <ErrorText>{errors.password}</ErrorText>
        ) : null}
        {!!errors.confirmPassword && !!touched.confirmPassword ? (
          <ErrorText>{errors.confirmPassword}</ErrorText>
        ) : null}
      </AuthenticationErrorContainer>
    ) : null
  }

  return (
    <Flex flexDirection="column" maxWidth="100%" width="100%" marginTop={15}>
      <FormHeader title={t('auth:register:passwordInfo')} />
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
      >
        <TextInput
          value={values.password}
          label={t('auth:forms:password')}
          onChangeText={value => changeText('password', value, setFieldValue)}
          onBlur={handleBlur('password')}
          autoCapitalize="none"
          secureTextEntry
          style={{ width: '100%' }}
          containerStyles={{ flexBasis: '48%' }}
        />
        <TextInput
          value={null}
          label={t('auth:forms:confirmPassword')}
          onChangeText={value =>
            changeText('confirmPassword', value, setFieldValue)
          }
          onBlur={handleBlur('confirmPassword')}
          autoCapitalize="none"
          secureTextEntry
          style={{ width: '100%' }}
          containerStyles={{ flexBasis: '48%' }}
        />
      </Flex>
      {renderErrors()}
    </Flex>
  )
}

export default PasswordMatchForm
