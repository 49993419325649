import gql from 'graphql-tag'

const QuestionGroupFieldsFragment = gql`
  fragment QuestionGroupFieldsFragment on QuestionGroup {
    id
    parentId
    dynamic
    type
    style
    configData
    __typename
  }
`

export default QuestionGroupFieldsFragment
