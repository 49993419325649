import useCurrentUser from '../../hooks/useCurrentUser'
import useCompanyQuery from '../../hooks/useCompanyQuery'
import useCompanyUsersQuery from '../../screens/Settings/hooks/useCompanyUsersQuery'
import useDelegationsQuery from '../../screens/Product/hooks/useDelegationsQuery'

// JC: We are prefetching the company and company users for innovators
// to speed up the directory profile experience
const CurrentCompanyController = () => {
  const { currentUser } = useCurrentUser()
  const isInnovator = currentUser?.innovationAccess?.innovatorTeam
  const companyId = isInnovator
    ? currentUser?.person?.innovationCompany?.id
    : undefined

  useCompanyQuery(companyId)
  useCompanyUsersQuery({
    id: companyId
  })
  useDelegationsQuery()

  return null
}

export default CurrentCompanyController
