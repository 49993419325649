import gql from 'graphql-tag'
import { InnovatorProduct } from '../types'
import useTranslation from './useTranslation'
import useQuery from './useQuery'

import InnovatorProductFragment from '../graphql/InnovatorProductFragment'

export const innovatorProductBySubmissionIdQuery = gql`
  query innovatorProductBySubmissionId($submissionId: String!) {
    innovatorProductBySubmissionId(submissionId: $submissionId) {
      ...InnovatorProductFragment
    }
  }
  ${InnovatorProductFragment}
`

interface InnovatorProductProps {
  product: InnovatorProduct
  loading: boolean
}

const useInnovatorProductBySubmissionId = (
  submissionId
): InnovatorProductProps => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(innovatorProductBySubmissionIdQuery, {
    variables: {
      submissionId: submissionId
    },
    skip: !submissionId,
    errorMessage: t('innovatorDirectory:details:queryError')
  })

  return {
    product: data?.innovatorProductBySubmissionId as InnovatorProduct,
    loading
  }
}

export default useInnovatorProductBySubmissionId
