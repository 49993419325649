import { useRecoilState } from 'recoil'
import { simulationAtom } from '../../recoil/simulationAtom'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Text } from '../../components/common/Text'
import styled from 'styled-components/native'
import useToggleState from '../../hooks/useToggle'

import useTranslation from '../../hooks/useTranslation'
import Dialog from '../../components/common/Dialog'

export const SIMULATOR_HEADER_HEIGHT = 36

const StyledView = styled(View)`
  width: 100%;
  background-color: #ff8080;
  height: ${SIMULATOR_HEADER_HEIGHT};
`

const Container = styled(TouchableOpacity)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 100%;
`

const SimulatorHeader: React.FC = () => {
  const [simulatorState, setSimulatorState] = useRecoilState(simulationAtom)

  const { t } = useTranslation()

  const {
    isOn: simDialogOn,
    turnOn: turnOnSimDialog,
    turnOff: turnOffSimDialog
  } = useToggleState(false)

  if (!simulatorState.simulatedId) {
    return null
  }

  return (
    <StyledView>
      <Container onPress={() => turnOnSimDialog()}>
        <Text>{t('simulation:exitView')}</Text>
        <Dialog
          isOpen={simDialogOn}
          title={t('simulation:exitDialog:title')}
          description={
            simulatorState.name
              ? t('simulation:exitDialog:bodyName', {
                  name: simulatorState.name
                })
              : t('simulation:exitDialog:body')
          }
          onClose={turnOffSimDialog}
          actions={[
            {
              title: t('common:buttons:cancel'),
              type: 'outline',
              onPress: turnOffSimDialog
            },
            {
              title: t('simulation:exitDialog:exitSimulationButton'),
              onPress: () => {
                setSimulatorState({ simulatedId: undefined, name: undefined })
                turnOffSimDialog()
              }
            }
          ]}
        />
      </Container>
    </StyledView>
  )
}

export default SimulatorHeader
