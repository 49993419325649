import { atom, RecoilState } from 'recoil'
import { CurrentUser } from '../types'

export interface CurrentUserAtom {
  firebaseInitialized: boolean
  firebaseToken: string | null
  firebaseEmail: string | null
  user: CurrentUser | null
  parentUser: CurrentUser | null
}

const currentUserAtom: RecoilState<CurrentUserAtom> = atom({
  key: 'currentUserState',
  default: {
    firebaseInitialized: false,
    firebaseToken: null,
    firebaseEmail: null,
    user: null,
    parentUser: null
  } as CurrentUserAtom
})

export default currentUserAtom
