import React, { FC } from 'react'
import { FlatList } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import { AnswerContent } from '../../../../../../types'
import { Flex } from '../../../../../../components/FlexBox'
import { Text } from '../../../../../../components/common/Text'

const Title = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    color: ${colors.text2};
    fontSize: ${fontSizes[5]}px;
    fontWeight: ${fontWeights.medium};
    marginRight: ${space[1]}px;
`}
`

export const CommentaryBubble = styled(Flex)`
  ${({ theme: { colors, space } }) => `
  background: ${colors.tints.grays.g100};
  padding: ${space[3]}px ${space[2]}px;
  border-radius: 10px;
  flex-direction: column
  margin-bottom: ${space[3]}px;
`}
`

interface CommentaryProps {
  label: string
  contents: AnswerContent[]
}

const Commentary: FC<CommentaryProps> = ({ label, contents }) => {
  const theme = useTheme()

  const filteredContents = contents.filter(
    (item: AnswerContent) => item.content && item.content.length > 0
  )

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{
        marginTop: theme.space[3]
      }}
    >
      <Title>{label}</Title>
      <Flex
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        style={{
          marginTop: theme.space[1]
        }}
      >
        <FlatList
          data={filteredContents}
          renderItem={({ item }) => (
            <CommentaryBubble>
              <Text
                style={{
                  color: theme.colors.text,
                  fontWeight: theme.fontWeights.regular,
                  fontSize: 14,
                  marginLeft: theme.space[1]
                }}
              >
                {item.content}
              </Text>
              <Flex textAlign="end">
                <Text
                  style={{
                    color: theme.colors.text,
                    fontWeight: theme.fontWeights.regular,
                    fontSize: theme.fontSizes[3],
                    marginLeft: theme.space[1]
                  }}
                >
                  - {item.author}
                </Text>
              </Flex>
            </CommentaryBubble>
          )}
        />
      </Flex>
    </Flex>
  )
}

export default Commentary
