import React from 'react'
import { ButtonContainer, ButtonFlex } from '../../SharedStyledComponents'
import Button from './Button'

const CsvButton = (): any => {
  return (
    <ButtonContainer>
      <ButtonFlex>
        <Button />
      </ButtonFlex>
    </ButtonContainer>
  )
}

export default CsvButton
