import { useRef } from 'react'
import { TableProps, UseTableOptions } from '../types'

const useTable = (options: UseTableOptions<any>): TableProps<any> => {
  const ref = useRef()
  const { config, data, isReadOnly = false } = options

  return {
    data,
    error: false,
    // @ts-ignore
    config,
    ref,
    isReadOnly
  }
}

export default useTable
