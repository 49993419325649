import * as Sentry from 'sentry-expo'
import { Platform } from 'react-native'

const captureException = (error: Error) => {
  if (Platform.OS === 'web') {
    Sentry.Browser.captureException(error)
  } else {
    Sentry.Native.captureException(error)
  }
}

// Expand Sentry methods as needed for platforms
export default {
  captureException
}
