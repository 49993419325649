import React, { ReactNode } from 'react'
import { StyleSheet, Keyboard, TouchableOpacity, ViewStyle } from 'react-native'

import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu'

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 23,
    borderTopRightRadius: 23,
    paddingBottom: 50,
    shadowColor: 'rgba(0,0,0, 0.50)',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5
  },
  wrapper: {
    borderTopLeftRadius: 23,
    borderTopRightRadius: 23,
    overflow: 'hidden'
  },
  item: {
    padding: 0
  }
})

const popoverStyles = StyleSheet.create({
  container: {
    borderRadius: 12,
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    paddingBottom: 0
  },
  wrapper: {},
  item: {}
})

const DummyTrigger = () => null

const ContextMenu = ({
  opened,
  onClick,
  onBackdropPress,
  children,
  icon,
  hideTrigger = false,
  isPopover = false,
  useBottomPadding = true,
  style,
  freezePosition = false
}: BottomSlideInMenuProps) => {
  const menuProps =
    typeof opened === 'undefined'
      ? {}
      : {
          opened,
          onBackdropPress
        }

  const triggerProps =
    typeof opened === 'undefined'
      ? {
          onPress: () => {
            Keyboard.dismiss()
            onClick?.()
          }
        }
      : {
          onPress: onClick
        }

  const stylesheet = isPopover ? popoverStyles : styles

  return (
    <Menu rendererProps={{ freezePosition }} {...menuProps} style={style}>
      <MenuTrigger
        testID="bottomSlideMenuButton"
        {...triggerProps}
        customStyles={{
          TriggerTouchableComponent: hideTrigger
            ? DummyTrigger
            : TouchableOpacity
        }}
        text="⋮"
      >
        {icon}
      </MenuTrigger>

      <MenuOptions
        customStyles={{
          optionsContainer: [
            stylesheet.container,
            {
              paddingBottom: useBottomPadding
                ? stylesheet.container.paddingBottom
                : 0,
              shadowColor: 'rgb(0,0,0)',
              shadowOpacity: 0.5
            }
          ],
          optionsWrapper: stylesheet.wrapper,
          optionWrapper: stylesheet.item,
          optionTouchable: {
            activeOpacity: 1.0,
            underlayColor: `#FFFFFF`
          }
        }}
      >
        {children}
      </MenuOptions>
    </Menu>
  )
}

interface BottomSlideInMenuProps {
  opened?: boolean
  onBackdropPress?: () => void
  onClick?: () => void
  children: React.ReactNode
  icon?: ReactNode
  hideTrigger?: boolean
  isPopover?: boolean
  useBottomPadding?: boolean
  style?: ViewStyle
  name?: string
  backdropStyleOverride?: { opacity: number }
  freezePosition?: boolean
}

interface BottomSlideInMenuOptionType {
  label: string | JSX.Element
  handleClick: any
}

export { BottomSlideInMenuOptionType }
export default ContextMenu
