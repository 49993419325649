import React from 'react'
import { Flex } from '../FlexBox'
import styled from 'styled-components/native'

const Container = styled(Flex).attrs(({ theme }) => ({
  flexWrap: 'wrap',
  flexDirection: theme.isSmallScreen ? 'column' : 'row',
  w: '100%'
}))``

const InputsContainer = ({ children }) => {
  return <Container>{children}</Container>
}

export default InputsContainer
