import React from 'react'
import styled from 'styled-components/native'
// Components/Types
import {
  Col,
  Row,
  RowSkeleton
} from '../../../../components/skeletonLoadings/components'
import { SkeletonTemplateProps } from '../../../../components/skeletonLoadings/types'
import { BorderCard } from '../../../Submission/components/skeletons/components'

import Layout from '../../../../constants/Layout'

const { window } = Layout

// Styled Components
const Container = styled(Col)`
  height: auto;
  min-width: 250px;
  width: 75%;
`
const Column = styled(Col)`
  ${({ theme: { space, sizes } }) => `
    min-height: ${sizes[4]}px;
    padding-horizontal: ${space[3]}px;
  `}
`
const FormContainer = styled(Col)`
  width: 100%;
  height: auto;
  ${({ theme: { space } }) => `
    padding-top: ${space[1]}px;
  `}
`
const OuterBorderCard = styled(BorderCard)`
  height: auto;
  justify-content: space-between;
  ${({ theme: { space, colors } }) => `
    margin-bottom: 0;
    padding-vertical: ${space[4]}px;
    padding-horizontal: ${space[4]}px;
    background: ${colors.appBackground}
  `}
`
const FormOuterBorderCard = styled(BorderCard)`
  justify-content: space-evenly;
  ${({ theme: { space }, height }) => `
    padding-top: ${space[4]}px;
    margin-bottom: ${space[4]}px;
    padding-horizontal: ${space[4] + space[3]}px;
    height: ${height || window.height * 0.7}px;
  `}
`
const FormInnerBorderCard = styled(BorderCard).attrs(
  ({ theme: { sizes, space } }) => ({
    badgeLeftPosition: 0,
    badgeWidth: '25%',
    badgeHeight: sizes[3],
    badgeTopPosition: -(space[3] + space[2] + space[1])
  })
)(({ height }) => ({ height: height || '15%' }))

const SkeletonText = styled(RowSkeleton)`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[2]}px;
  `}
`

export const SkeletonTitle = styled(RowSkeleton)`
  ${({ theme: { spaces, sizes } }) => `
  padding-vertical: ${spaces[4]}px;
    margin-bottom: ${sizes[2]}px;
  `}
`

export const ReviewSkeleton = React.memo(
  ({ theme: { sizes } }: SkeletonTemplateProps) => (
    <>
      <Column marginTop={sizes[2]}>
        <SkeletonText width="30%" height={sizes[3]} />
        <SkeletonText width="70%" height={sizes[4]} />
      </Column>
      <Column marginTop={sizes[2]}>
        <SkeletonText width="30%" height={sizes[3]} />
        <SkeletonText width="70%" height={sizes[4]} />
      </Column>
      <Column marginTop={sizes[2]}>
        <SkeletonText width="30%" height={sizes[3]} />
        <SkeletonText width="70%" height={sizes[4]} />
      </Column>
      <Column marginTop={sizes[2]}>
        <SkeletonText width="30%" height={sizes[3]} />
        <SkeletonText width="70%" height={sizes[4]} />
      </Column>

      <Container marginTop={sizes[4]}>
        <OuterBorderCard showBadge={false}>
          <Column marginTop={sizes[2]}>
            <SkeletonText width="100%" height={sizes[5]} />
          </Column>
        </OuterBorderCard>
      </Container>

      <Container marginTop={sizes[4]}>
        <OuterBorderCard showBadge={false}>
          <Column marginTop={sizes[2]}>
            <SkeletonText width="100%" height={sizes[5]} />
          </Column>
        </OuterBorderCard>
      </Container>
    </>
  )
)

export const HeaderSkeleton = React.memo((props: SkeletonTemplateProps) => {
  const {
    theme: { sizes }
  } = props
  return (
    <>
      <Row paddingTop="10" paddingRight="10" paddingLeft="10">
        <Col width="80%">
          <SkeletonText width="50%" height={sizes[4] - sizes[1]} />
        </Col>
        <Col width="20%" alignItems="end">
          <SkeletonText width="100%" height={sizes[4] - sizes[1]} />
        </Col>
      </Row>
    </>
  )
})

export const DetailsSkeleton = React.memo(() => {
  return (
    <>
      <Row paddingLeft={15} paddingRight={15} paddingTop={20}>
        <Col width="75%">
          <FormContainer>
            <FormOuterBorderCard>
              <FormInnerBorderCard height="12%" />
              <FormInnerBorderCard />
              <FormInnerBorderCard />
              <FormInnerBorderCard />
              <FormInnerBorderCard />
            </FormOuterBorderCard>
          </FormContainer>
        </Col>
      </Row>
    </>
  )
})
