import React, { useEffect, useState } from 'react'

// Hooks
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
import useTranslation from '../../../../../hooks/useTranslation'

// Skeleton
import TableSkeleton from '../../../../HomeScreen/TableSkeleton'
import SkeletonContainer from '../../../../../components/skeletonLoadings/SkeletonContainer'

// Components
import { TableContainer } from '../../../../../components/StaticForm'
import { DynamicTable, useTable } from '../../../../../components/Table'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../../../../components/DynamicTable'
import { View } from 'react-native'
import { CreateIcon } from '../../../constants'
import { FormValues, QuestionGroupEntityMapValues } from '../../types'
import TextInput from '../../../../../ui-library/TextInput'
import useDebounce from '../../../../../hooks/useDebounce'
import useFormsQuery from '../../../../Product/hooks/useFormsQuery'
import Dropdown, {
  DropdownOption
} from '../../../../../components/common/Dropdown'
import useGetQuestionGroupEntityMaps from '../Forms/hooks/useGetQuestionGroupEntityMaps'
import {
  EntityTypeEnum,
  QuestionGroupEntityMap
} from '../../../../../components/DynamicForm/types'
import { getFormBlocksTableConfig } from './constants'
import Modal from './components/Modal'

const FormBlocks = () => {
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentQuestion, setCurrentQuestion] = useState<
    QuestionGroupEntityMapValues | undefined
  >()
  const { forms = [], loading: fetchLoadingForms } = useFormsQuery()
  const [currentForm, setCurrentForm] = useState<FormValues | undefined>()

  const {
    questionGroupEntityMaps: initialQuestionGroupEntityMaps = [],
    loading: fetchLoading
  } = useGetQuestionGroupEntityMaps(
    false,
    currentForm?.id,
    undefined,
    EntityTypeEnum.QuestionGroup,
    { skip: !currentForm }
  )

  const [search, setSearch] = useState<string | undefined>(undefined)
  const debouncedSearch = useDebounce(search, 300)

  const formsOptions: DropdownOption[] = forms?.map(form => ({
    label: form.name,
    value: form.id
  }))

  useEffect(() => {
    if (!fetchLoadingForms && forms.length && !currentForm) {
      setCurrentForm(forms[0])
    }
  }, [fetchLoadingForms, forms, currentForm])

  const [data, setData] = useState<QuestionGroupEntityMap[]>([])

  useEffect(() => {
    if (fetchLoading && !isLoading) {
      setIsLoading(true)
    }
  }, [fetchLoading, isLoading])

  useEffect(() => {
    if (!fetchLoading) {
      let newData: QuestionGroupEntityMap[] =
        currentForm && initialQuestionGroupEntityMaps.length
          ? initialQuestionGroupEntityMaps
              .filter(
                qgem => qgem.questionGroupId === currentForm.questionGroupId
              )
              .sort((a, b) => a.sequence - b.sequence)
          : []

      if (debouncedSearch != null) {
        newData = newData.filter(question => {
          const allTerms = [
            ...Object.keys(question),
            ...Object.values(question).map(
              value => String(JSON.stringify(value)) ?? String(value)
            )
          ]
          const includeTerm = allTerms.find(term =>
            term.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
          if (includeTerm) {
            return true
          } else {
            return false
          }
        })
      }
      setData(newData)
      setIsLoading(false)
    }
  }, [
    initialQuestionGroupEntityMaps,
    currentForm,
    fetchLoading,
    debouncedSearch
  ])

  const handleEditFormBlock = (question: QuestionGroupEntityMapValues) => {
    setCurrentQuestion(question)
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const handleCreateFormBlock = () => {
    setCurrentQuestion(undefined)
    setIsOpen(true)
  }

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps = useTable({
    data,
    config: getFormBlocksTableConfig({
      handleEditFormBlock
    })
  })

  return (
    <View
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <View
        style={{
          width: '100%',
          height: 50,
          marginTop: 10,
          paddingHorizontal: 10,
          flexDirection: 'row'
        }}
      >
        {/* Filters */}
        <View
          style={{
            marginHorizontal: 10,
            height: '90%',
            width: '80%',
            flexDirection: 'row'
          }}
        >
          <TextInput
            style={{
              minHeight: 0,
              alignSelf: 'center'
            }}
            containerStyles={{
              width: 250,
              height: 50,
              marginRight: 10
            }}
            placeholder="Search..."
            value={search}
            onChangeText={setSearch}
          />
          <Dropdown
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentForm?.id ?? null}
            containerStyle={{ width: 350, height: 50, marginRight: 10 }}
            isLoading={fetchLoading}
            isDisabled={fetchLoading}
            options={formsOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => {
              const form = forms.find(form => form.id === value)
              setCurrentForm(form)
            }}
            name={'formTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
        </View>
        <CreateIcon
          style={{ alignSelf: 'flex-end' }}
          disabled={false}
          isLoading={false}
          title={t('platformManagement:forms:menuItems:formBlocks:addNew')}
          onPress={handleCreateFormBlock}
        />
      </View>

      {/* Table */}
      <View
        style={{
          flex: 1,
          paddingHorizontal: 10
        }}
      >
        <SkeletonContainer isLoading={!!fetchLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t(
                'platformManagement:forms:menuItems:formBlocks:noItems'
              )}
            />
          </TableContainer>
        </SkeletonContainer>
      </View>
      {currentForm ? (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          form={currentForm}
          question={currentQuestion}
          questions={data}
        />
      ) : null}
    </View>
  )
}

export default FormBlocks
