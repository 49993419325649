import gql from 'graphql-tag'

const QuestionValidationMapFieldsFragment = gql`
  fragment QuestionValidationMapFieldsFragment on QuestionValidationMap {
    id
    questionId

    validationId
    phase
    errorMessage
    value
    validation {
      id
      type
      questionDependencyId
    }
  }
`

export default QuestionValidationMapFieldsFragment
