import { atom, RecoilState } from 'recoil'

export interface PresentationsPersistSelectProps {
  [submissionId: string]: {
    [groupId: string]: {
      [selectKey: string]: boolean
    }
  }
}

const presentationsPersistSelectAtom: RecoilState<PresentationsPersistSelectProps> = atom(
  {
    key: 'presentationsPersistSelection',
    default: {}
  }
)

export default presentationsPersistSelectAtom
