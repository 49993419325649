import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  ButtonContainer,
  ButtonFlex,
  StyledButtonUI
} from '../../SharedStyledComponents'
import useTranslation from '../../../../../../hooks/useTranslation'
import useCreateSubmissionsBatchPdfMutation from '../../../../../../screens/Submission/hooks/useCreateSubmissionsBatchPdfMutation'
import useCreateSubmissionsPdfMutation from '../../../../../../screens/Submission/hooks/useCreateSubmissionPdfMutation'
import useDownloadStatus from '../../../../../../hooks/useDownloadStatus'
import useCurrentUser from '../../../../../../hooks/useCurrentUser'
import { TableContext } from '../../../../hooks/TableProvider'
import useDownloadFiles from '../../../../../../hooks/useDownloadFiles'
import useUpdatedInnovationSubmissionCloudinaryIdSubscription from '../../../../../../screens/Submission/hooks/useUpdatedInnovationSubmissionCloudinaryIdSubscription'
import { DownloadProps } from '../../../../../../recoil/currentDownloadStatusAtom'
import { InnovationSubmission } from '../../../../../../types'
import { GeneralButtonProps } from '../../types'
import useGetSubmissionIdsByCallForSubmissionQuery from '../../../../../../screens/Curation/hooks/useGetSubmissionIdsByCallForSubmissionQuery'
import { CSVExtraParamsObject, CSVTypeEnum } from '../../../../types'
import { getExtraParams, tableNameCSVTypeMap } from '../../../../utils'

interface GeneratePDFByCFSProps {
  callForSubmissionId: string
  csvType: CSVTypeEnum
  handleCreateSubmissionPDF: (data: any[]) => Promise<void>
  groupId?: string
  resetPDFButton: () => void
  extraParams?: CSVExtraParamsObject[]
  advisorId?: string
}

const GeneratePDFByCFS = (props: GeneratePDFByCFSProps) => {
  const {
    callForSubmissionId,
    csvType,
    groupId,
    handleCreateSubmissionPDF,
    resetPDFButton,
    extraParams,
    advisorId
  } = props
  const { submissions, loading } = useGetSubmissionIdsByCallForSubmissionQuery(
    callForSubmissionId,
    csvType,
    groupId,
    true,
    undefined,
    advisorId,
    extraParams
  )

  useEffect(() => {
    const handlePDFs = async () => {
      await handleCreateSubmissionPDF(submissions)
      resetPDFButton()
    }
    if (!loading && submissions.length) {
      handlePDFs()
    }
  }, [loading, submissions])

  return null
}

const GeneralButton = (props: GeneralButtonProps): any => {
  const { t } = useTranslation()
  const { sizes, colors } = useTheme()
  const { currentUserId } = useCurrentUser()
  const {
    selectedItemsLength,
    selectedItemsKeys,
    isItemSelected,
    data,
    selectAllElements,
    config: { name, cohortId, filters },
    queryFilters
  } = useContext(TableContext)
  const { isProduct } = props
  // Update Data Reference
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [generateAllPDFs, setGenerateAllPDFs] = useState(false)
  const {
    setCurrentDownloadStatus,
    isLoading: isLoadingDownloadStatus
  } = useDownloadStatus(currentUserId ? (currentUserId as string) : '')

  const { setCurrentDownloadFiles } = useDownloadFiles(
    currentUserId ? (currentUserId as string) : ''
  )

  const {
    createSubmissionsBatchPDF,
    loading: loadingCreateSubmissionsBatchPDF
  } = useCreateSubmissionsBatchPdfMutation()
  const {
    createSubmissionPDF,
    loading: loadingCreateSubmissionPDF
  } = useCreateSubmissionsPdfMutation()

  const [extraParams, setExtraParams] = useState<CSVExtraParamsObject[]>([])

  useEffect(() => {
    if (isLoading || !(selectedItemsLength > 0)) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [isLoading, selectedItemsLength])

  useEffect(() => {
    if (
      !loadingCreateSubmissionsBatchPDF &&
      !loadingCreateSubmissionPDF &&
      !isLoadingDownloadStatus
    ) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [
    loadingCreateSubmissionsBatchPDF,
    loadingCreateSubmissionPDF,
    isLoadingDownloadStatus
  ])

  const cfsId = data?.length ? data[0].callForSubmissionId : undefined
  const cfsName = data?.length ? data[0].callForSubmission?.name : ''
  const csvType = tableNameCSVTypeMap[name]

  const handleCreateSubmissionPDF = async (data: any[]) => {
    setIsLoading(true)
    setCurrentDownloadFiles({
      isLoadingFiles: true,
      isRefreshingFiles: false,
      files:
        data?.map(item => ({
          id: item?.id,
          label: item?.name,
          cloudinaryId: item?.pdfCloudinaryId,
          isDownloadable: true,
          isLoading: !item?.pdfCloudinaryId,
          subscriptionKeys: {
            innovationSubmissionId: item?.id,
            pdfCloudinaryId: item?.pdfCloudinaryId
          }
        })) ?? []
    })

    setCurrentDownloadStatus({
      attempts: 0,
      isVisible: true,
      isLoading: true,
      downloadLink: undefined,
      handleSubscription: useUpdatedInnovationSubmissionCloudinaryIdSubscription,
      getLinkDataKey: 'createInnovationSubmissionsBatchPdf',
      getLinkValuesKey: 'pdfCloudinaryId',
      handleGetLink: createSubmissionsBatchPDF,
      fileName: `${cfsName}-innovationSubmissions`
    } as DownloadProps)

    for (let index = 0; index < data.length; index++) {
      const submission =
        ((data && data[index]) as InnovationSubmission) ?? undefined

      let pdfCreated = true
      if (!submission?.pdfCloudinaryId) {
        const { createInnovationSubmissionPdf } = await createSubmissionPDF(
          submission.id,
          false
        )
        pdfCreated = createInnovationSubmissionPdf
      }
      if (!pdfCreated) {
        console.error('Error creating PDF for Submission: ' + submission.id)
      }
    }
    setIsLoading(false)
  }

  const handleCreateProductPDF = async () => {
    setIsLoading(true)

    const filesToDownload =
      data
        ?.filter(item => isItemSelected(item?.id))
        // ?.filter(item => item.companyInfo.products?.length > 0)
        ?.map(item => {
          if (item.companyInfo.products?.length > 0) {
            const productSubmission = item?.companyInfo.products[0]?.submission

            return {
              id: productSubmission?.id,
              label: item?.companyInfo.name,
              cloudinaryId: productSubmission?.pdfCloudinaryId,
              isLoading: !productSubmission?.pdfCloudinaryId,
              isDownloadable: true,
              subscriptionKeys: {
                innovationSubmissionId: productSubmission?.id,
                pdfCloudinaryId: productSubmission?.pdfCloudinaryId
              }
            }
          } else {
            // For innovators with no products created
            return {
              id: null,
              label: `${item?.companyInfo.name} - ${t(
                'common:download:noProducts'
              )}`,
              cloudinaryId: null,
              isLoading: false,
              isDownloadable: false,
              subscriptionKeys: {
                innovationSubmissionId: null,
                pdfCloudinaryId: null
              }
            }
          }
        }) ?? []

    setCurrentDownloadFiles({
      isLoadingFiles: true,
      isRefreshingFiles: false,
      files: filesToDownload
    })

    setCurrentDownloadStatus({
      isVisible: true,
      isLoading: true,
      downloadLink: undefined,
      handleSubscription: useUpdatedInnovationSubmissionCloudinaryIdSubscription,
      getLinkDataKey: 'createInnovationSubmissionsBatchPdf',
      getLinkValuesKey: 'pdfCloudinaryId',
      handleGetLink: createSubmissionsBatchPDF,
      fileName: 'innovatorDirectory'
    } as DownloadProps)

    for (let index = 0; index < selectedItemsKeys.length; index++) {
      let pdfCreated = true
      const companyId = selectedItemsKeys[index]
      const companyIndex = data?.findIndex(item => item?.id === companyId) ?? -1
      const companyProfile = (data && data[companyIndex]) ?? undefined
      const companyProduct =
        companyProfile?.companyInfo?.products?.length > 0
          ? companyProfile?.companyInfo?.products[0]
          : null
      if (companyProduct) {
        if (!companyProduct?.submission?.pdfCloudinaryId) {
          const { createInnovationSubmissionPdf } = await createSubmissionPDF(
            companyProduct?.submissionId,
            false
          )
          pdfCreated = createInnovationSubmissionPdf
        }
        if (!pdfCreated) {
          console.error(
            `Error creating Product PDF for Company: ${companyId} with product: ${companyProduct?.id}`
          )
        }
      }
    }

    setIsLoading(false)
  }

  const handleOnPress = async () => {
    if (isProduct) {
      handleCreateProductPDF()
    } else if (!selectAllElements) {
      await handleCreateSubmissionPDF(
        data
          ?.filter(item => isItemSelected(item?.id))
          ?.map(submission => ({
            id: submission.id,
            name: submission?.submissionInfo?.name,
            pdfCloudinaryId: submission?.pdfCloudinaryId
          })) ?? []
      )
    } else {
      setIsLoading(true)
      const extraParams = getExtraParams({
        filters,
        name,
        queryFilters
      })
      setExtraParams(extraParams)
      setGenerateAllPDFs(true)
    }
  }

  return (
    <ButtonContainer left>
      <ButtonFlex>
        <StyledButtonUI
          testID={'createPDFbtn'}
          isProcessing={isLoading}
          disabled={isDisabled}
          title={t('curation:submissionDetails:createPDF')}
          onPress={handleOnPress}
          buttonStyle={{
            width: sizes[6] + sizes[4],
            paddingVertical: 10,
            backgroundColor: colors.background
          }}
          titleStyle={{
            color: colors.darkIcon
          }}
        />
      </ButtonFlex>
      {selectAllElements && generateAllPDFs && cfsId ? (
        <GeneratePDFByCFS
          callForSubmissionId={cfsId}
          csvType={csvType}
          groupId={cohortId}
          handleCreateSubmissionPDF={handleCreateSubmissionPDF}
          resetPDFButton={() => setGenerateAllPDFs(false)}
          extraParams={extraParams}
          advisorId={currentUserId ? String(currentUserId) : undefined}
        />
      ) : null}
    </ButtonContainer>
  )
}

export default GeneralButton
