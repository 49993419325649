import React from 'react'
import { useTheme } from 'styled-components/native'

import useTranslation from '../../../../hooks/useTranslation'

import {
  PercentageLabel,
  QuestionBlock,
  QuestionWeightContainer,
  Title
} from './SettingsStyledComponents'
import TextInput from '../../../../ui-library/TextInput'

const PERCENTAGE_CHAR = '%'

const AdvisingCriteriaSection = ({
  advisorMaxFav,
  questionsWeights,
  onMaxFavsChange,
  onWeightChange,
  errors,
  getErrorMessage
}: any) => {
  const { space } = useTheme()
  const { t } = useTranslation()

  return (
    <>
      <Title>Advising Criteria</Title>

      <QuestionBlock style={{ marginTop: space[3], maxWidth: '200px' }}>
        <TextInput
          name="advisorMaxFav"
          value={advisorMaxFav}
          onChangeText={onMaxFavsChange}
          errorMessage={errors.advisorMaxFav}
          label={t('curation:general:form:advisorMaxFav')}
        />
      </QuestionBlock>

      <QuestionBlock
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        {questionsWeights.map((question, i) => {
          return (
            <QuestionWeightContainer key={`question-weight-${i}`}>
              <TextInput
                name=""
                value={question.weight}
                onChangeText={value => {
                  onWeightChange(value, i)
                }}
                errorMessage={getErrorMessage(i, 'weight')}
                label={question.questionText}
                style={{
                  flexGrow: 1
                }}
              />
              <PercentageLabel>{PERCENTAGE_CHAR}</PercentageLabel>
            </QuestionWeightContainer>
          )
        })}
      </QuestionBlock>
    </>
  )
}

export default AdvisingCriteriaSection
