import React from 'react'
import styled from 'styled-components/native'
// Components
import { SkeletonTemplateProps } from '../../components/skeletonLoadings/types'
import {
  Col,
  Row,
  RowSkeleton,
  SkeletonDivider
} from '../../components/skeletonLoadings/components'

// Style
const Container = styled.View`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[2]}px;
    margin-top: ${-space[2]}px;
  `}
`

interface Props extends SkeletonTemplateProps {
  numberOfRows?: number
}

const CallForSubmissionsStaffSkeleton = React.memo((props: Props) => {
  const { numberOfRows = 4 } = props
  return (
    <Container>
      <Row justifyContent="flex-start" marginBottom={22}>
        <RowSkeleton marginRight={155} width="13%" height={6} />
        <RowSkeleton marginRight={155} width="13%" height={6} />
        <RowSkeleton marginRight={155} width="11%" height={6} />
        <RowSkeleton width="13%" height={6} />
      </Row>
      <SkeletonDivider />
      <Col marginTop={10}>
        <TableRow isHeader {...props} />
        {Array.from(Array(numberOfRows), (_, index) => (
          <TableRow key={`key_tableRow_${index}`} index={index} {...props} />
        ))}
      </Col>
    </Container>
  )
})

const TableRowBG = styled(RowSkeleton)`
  width: 100%;
  justify-content: center;
  ${({ theme: { sizes } }) => ` 
    height: ${sizes[4] + sizes[2]}px;
  `}
`

const TableRowTextContainer = styled.View`
  width: 20%;
  height: 100%;
  justify-content: center;
  padding-horizontal: ${({ theme }) => theme.sizes[2]}px;
`

const TableRowText = styled(RowSkeleton)`
  margin-bottom: 0px;
  ${({ width, theme: { sizes } }) => ` 
    height: ${sizes[2] - 2}px;
    width: ${width || '50%'};
  `}
`

interface TableRowProps extends Props {
  index?: number
  isHeader?: boolean
}

export const TableRow = React.memo(
  ({ index, isHeader, theme: { colors } }: TableRowProps) => {
    const isEven: boolean = !!index && (index + 1) % 2 === 0
    const inputText = (width?: string | number) => (
      <TableRowTextContainer>
        <TableRowText
          width={width}
          colorLight={isHeader || isEven ? colors.lightGrey : colors.background}
        />
      </TableRowTextContainer>
    )
    return (
      <TableRowBG
        marginBottom={0}
        colorLight={isHeader || isEven ? colors.background : colors.lightGrey}
      >
        <Row justifyContent="flex-start" alignItems="center" height="100%">
          {inputText(isHeader ? '65%' : '55%')}
          {inputText(isHeader ? '65%' : '68%')}
          {inputText(isHeader ? '35%' : '40%')}
        </Row>
      </TableRowBG>
    )
  }
)

export default CallForSubmissionsStaffSkeleton
