import React from 'react'
import styled from 'styled-components/native'
import { ScrollView } from 'react-native'
import useTranslation from '../../hooks/useTranslation'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import { Flex } from '../../components/FlexBox'
import Link from '../../components/Link'
import theme from '../../constants/Theme'
import { H1, Text } from '../../components/common/Text'
import SubmissionWindowsCalendar from '../authentication/LoginScreen/SubmissionCalendar'

export const P = styled(Text)`
  color: ${props => props.theme.colors.text};
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
  text-align: justify;
`

const ContactText = styled(P)`
  margin-bottom: 0;
  font-style: italic;
`

const Column = styled(ScrollView)`
  ${({ isSmallScreen, paddingLeft, paddingRight, theme: { space } }) => `
    max-height: ${space[7] + space[6] + space[3]}px;
    padding-left: ${paddingLeft || 0}px;
    padding-right: ${paddingRight || 0}px;
    width: ${isSmallScreen ? '50%' : '100%'};
  `}
`

const Title = styled(H1).attrs(
  ({ theme: { space, fontSizes, colors }, isSmallScreen }) => ({
    styles: {
      marginBottom: space[3],
      marginTop: isSmallScreen ? space[2] : 0,
      textAlign: isSmallScreen ? 'center' : 'left',
      color: colors.blueBackground
    },
    h1Style: {
      fontSize: fontSizes[7],
      fontWeight: 'bold'
    }
  })
)``

const Subtitle = styled(H1).attrs(({ theme: { space, fontSizes } }) => ({
  styles: {
    marginBottom: space[3],
    marginTop: space[3]
  },
  h1Style: {
    fontSize: fontSizes[6],
    fontWeight: 'bold'
  }
}))``

const Content = () => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()

  return (
    <Flex
      testID={'welcomeContainer'}
      flexBasis={isSmallScreen ? 'auto' : '100%'}
      justifyContent="center"
      alignItems={isSmallScreen ? 'center' : 'flex-start'}
    >
      <Title isSmallScreen={isSmallScreen}>{t('homeScreen:title')}</Title>
      <Flex
        flexDirection={isSmallScreen ? 'column' : 'row'}
        flexBasis={isSmallScreen ? 'auto' : '100%'}
        justifyContent="center"
        alignItems={isSmallScreen ? 'center' : 'flex-start'}
      >
        <Column paddingRight={20}>
          <Subtitle>{t('homeScreen:gettingStarted')}</Subtitle>

          <P>{t('homeScreen:description:paragraph1')}</P>

          <P>{t('homeScreen:description:paragraph2')}</P>

          <P>{t('homeScreen:description:paragraph3')}</P>

          <P>{t('homeScreen:description:lastParagraph')}</P>

          <ContactText>
            <P>{t('homeScreen:description:clickHere:click')}</P>
            <Link
              to="mailto:innovator-support@world50.com"
              title={t('homeScreen:description:clickHere:mailto')}
              target="_blank"
              style={{
                color: theme.colors.primary,
                textDecoration: 'none'
              }}
            >
              {t('homeScreen:description:clickHere:mailto')}
            </Link>
            <P>{t('homeScreen:description:clickHere:end')}</P>
          </ContactText>
        </Column>
        <Column paddingLeft={20}>
          <Subtitle>{t('homeScreen:upcomingSubmissions')}</Subtitle>
          <SubmissionWindowsCalendar isHomeScreen marginTop={0} />
        </Column>
      </Flex>
    </Flex>
  )
}

export default Content
