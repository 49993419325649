import styled from 'styled-components/native'
import theme from '../../../../../constants/Theme'
import { Flex } from '../../../../FlexBox'
import { Text as TText } from 'react-native-elements'
import TouchableIcon from '../../../../icon/TouchableIcon'
import Modal from '../../../../common/Modal'
import CheckBox from '../../../../../ui-library/CheckBox'
import { View } from 'react-native'
import Button from '../../../../../ui-library/Button'

// =================================
// Modal Dialog Components
// =================================

export const ModalTitle = styled(TText)`
  font-size: ${theme.sizes[3] + theme.sizes[1]}px;
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: ${theme.sizes[2]};
`

export const ModalCloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

export const StyledModal = styled(Modal)`
  min-width: 450px;
  padding: 15px 24px;
`

export const ModalStyledView = styled(View)`
  margin-top: ${theme.space[3]};
`

export const ModalStyledButtonsView = styled(View)`
  margin-top: ${theme.space[3]};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const StyledCheckBox = styled(CheckBox)``

export const StyledModalButton = styled(Button)``

// =================================
// End Modal Dialog Components
// =================================

// =================================
// Modal Toast Components
// =================================

export const ToastContainer = styled(Flex)`
  padding: ${theme.space[2]}px;
  border-radius: 3px;
  margin-bottom: ${theme.space[3]}px;
`

export const ToastText = styled(TText)`
  color: ${theme.colors.primaryPalette.white};
  font-size: ${theme.sizes[3]}px;
`
// =================================
// End Modal Toast Components
// =================================
