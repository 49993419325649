import React, { useEffect, useMemo, useRef } from 'react'
import { Text } from 'react-native'
import { useTheme } from 'styled-components/native'
import useTranslation from '../../../hooks/useTranslation'
import { AnswerFileAttachmentMap } from '../../../types'
import Button from '../../../ui-library/Button'
import idToCloudinaryUrl from '../../../utils/idToCloudinaryUrl'
import {
  UploadConfirm,
  ScanningDescription,
  UploadContainer
} from './SharedStyledComponents'
import Icon from '../../icon'
import Link from '../../Link'

interface PreviewFileProps {
  file: AnswerFileAttachmentMap
}

const PreviewFile = ({ file }: PreviewFileProps) => {
  const viewRef = useRef(null)
  const { t } = useTranslation()
  const { colors, space } = useTheme()
  const { fileAttachment } = file

  const status = useMemo(() => fileAttachment?.moderationStatus, [file])
  const isProcessing = useMemo(
    () => fileAttachment?.moderationStatus === 'pending',
    [file]
  )
  const buttonLabel = useMemo(
    () =>
      fileAttachment?.moderationStatus === 'approved'
        ? 'common:labels:preview'
        : '',
    [status]
  )

  useEffect(() => {
    const label = {
      rejected: 'common:labels:malware',
      pending: 'common:labels:pending'
    }
    if (status === 'rejected' || status === 'pending') {
      // @ts-ignore
      viewRef?.current?.setNativeProps({ title: t(label[status]) })
    }
  }, [viewRef?.current])

  const toUrl = useMemo(
    () =>
      status === 'approved'
        ? idToCloudinaryUrl(fileAttachment?.cloudinaryId, {
            resource_type: 'raw'
          })
        : '',
    [status, fileAttachment?.cloudinaryId]
  )

  return (
    <UploadContainer>
      <UploadConfirm
        style={{ marginTop: 12, marginBottom: isProcessing ? 0 : 15 }}
      >
        <Icon
          name="fileReadyCircle"
          width={28}
          height={18}
          color={colors.saved}
        />
        <Text style={{ color: colors.saved }}>
          {t(
            `product:pitch:file:form:${
              isProcessing ? 'fileSaved' : 'fileUploaded'
            }`
          )}
        </Text>
      </UploadConfirm>
      {isProcessing ? (
        <ScanningDescription>
          <Text style={{ color: colors.buttonVariant }}>
            {t('product:pitch:file:form:scanningDescription')}
          </Text>
        </ScanningDescription>
      ) : status === 'approved' && toUrl ? (
        <Link
          style={{
            textDecoration: 'none'
          }}
          to={toUrl}
          title={t('common:labels:preview')}
          target="_blank"
        >
          <Button
            isProcessing={isProcessing}
            title={t(buttonLabel)}
            type="outline"
            buttonStyle={{
              width: 'fit-content',
              height: space[3] + space[1],
              paddingHorizontal: space[3],
              borderColor: colors.cta.secondary,
              borderWidth: 1.5
            }}
            titleStyle={{
              paddingTop: 1,
              paddingBottom: 1,
              textTransform: 'uppercase',
              fontWeight: '500',
              color: colors.cta.secondary
            }}
            containerStyle={{
              height: 'fit-content',
              marginLeft: space[2],
              marginRight: space[3]
            }}
          />
        </Link>
      ) : (
        <ScanningDescription>
          <Text style={{ color: colors.buttonVariant }}>
            {t('product:pitch:file:form:fileRejected')}
          </Text>
        </ScanningDescription>
      )}
    </UploadContainer>
  )
}

export default PreviewFile
