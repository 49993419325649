import { useEffect, useState } from 'react'

const usePropValue = (initialValue?: any, newValue?: any) => {
  const [propValue, setPropValue] = useState(initialValue)

  useEffect(() => {
    if (newValue != null && newValue !== propValue) {
      setPropValue(newValue)
    }
  }, [newValue])

  return propValue
}

export default usePropValue
