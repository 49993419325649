import gql from 'graphql-tag'

const InnovationSubmissionFieldsFragment = gql`
  fragment InnovationSubmissionFieldsFragment on InnovationSubmission {
    id
    step
    innovationSubmissionStatus: status
    pdfCloudinaryId
    hasBeenSubmitted
    hasBeenFastPassed
    callForSubmissionId
    callForSubmission {
      name
      currentStep {
        name
      }
    }
    updatedAt
    cohorts {
      group {
        id
        name
        shortName
      }
      deletedById
      isConfirmed
    }
    cohortsHistory {
      id
      name
      shortName
    }
    company {
      id
      name
      innovatorMembers {
        user {
          roles
        }
        emailAddresses {
          email
        }
      }
    }
    submissionInfo {
      name
      category
      categoryLabel
      otherCategoryLabel
      reasonForCut
      reasonForCutDescription
    }
    submissionReviews {
      avgScore
      userId
    }
    advisorsReviews {
      advisorId
      submissionId
      answers {
        id
        questionId
        value
      }
    }
    submissionAverage {
      avgScore
      stdDev
      totalFav
      totalAcademy
      wass
      submissionScores {
        submissionId
        question {
          id
          questionText
        }
        value
      }
    }
    submissionVotes @include(if: $withSubmissionVotes) {
      id
      company {
        id
        name
      }
      vote
      favorite
      reason
    }
  }
`

export default InnovationSubmissionFieldsFragment
