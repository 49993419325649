import moment from 'moment-timezone'

import { DateTime } from './../types'

type UnitOfTime = moment.unitOfTime.DurationConstructor

class DateService {
  getFormat(date: DateTime, format: string = 'MM/DD/YY') {
    return moment(date).format(format)
  }

  getDate(date: DateTime) {
    return moment(date).toDate()
  }

  getDateByAddingTime(date: DateTime, amount: number, unitOfTime: UnitOfTime) {
    return moment(date).add(amount, unitOfTime).toDate()
  }

  getYear(date: DateTime) {
    return moment(date).toDate().getFullYear()
  }

  getFormatWithTimeZone(
    date: DateTime,
    format: string = 'MM/DD/YYYY h:mm a z',
    timeZone = 'America/New_York'
  ) {
    return moment.tz(date, timeZone).format(format)
  }

  convertToLocalZone(
    date: DateTime,
    format: string = 'MM/DD/YYYY h:mm a',
    timeZone = 'America/New_York'
  ) {
    const dateWithoutZone = moment.tz(date, timeZone).format(format)
    const localZone = moment(dateWithoutZone).format('Z')
    const dateWithTimeZone = [dateWithoutZone, localZone].join('')

    return new Date(dateWithTimeZone)
  }

  convertToTimeZone(
    date: DateTime,
    format: string = 'MM/DD/YYYY h:mm a',
    timeZone = 'America/New_York'
  ) {
    const dateWithoutZone = moment(date).format(format)
    const otherZone = moment.tz(date, timeZone).format('Z')
    const dateWithOtherZone = [dateWithoutZone, otherZone].join('')

    return new Date(dateWithOtherZone)
  }

  getTimeZone(date: DateTime, timeZone = 'America/New_York') {
    return moment.tz(date, timeZone).format('z')
  }
}

const instance = new DateService()

export default instance
