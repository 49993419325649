import React from 'react'
import { DetailedViewProps } from '../types'
import { StyledDetailedViewContainer } from '../SharedStyledComponents'
import useTranslation from '../../../../../hooks/useTranslation'

const DetailedView = (props: DetailedViewProps) => {
  const { leftTable, getDetailedViewComponents, item, isSticky } = props
  const { t } = useTranslation()
  return (
    <StyledDetailedViewContainer leftTable={leftTable} isSticky={isSticky}>
      {getDetailedViewComponents({ item, leftTable, t })}
    </StyledDetailedViewContainer>
  )
}

export default DetailedView
