import useQuery from './useQuery'
import { equals } from 'ramda'
import Constants from 'expo-constants'
import { Platform } from 'react-native'
import gql from 'graphql-tag'

import Mixpanel from '../utils/Mixpanel'

export const configQuery = gql`
  query config($key: String!) {
    config(key: $key)
  }
`

export const AppIds = {
  MI6: 'mi6',
  MemberPlatform: 'member-platform',
  Innovation: 'innovation'
}

export const EVENT_MAP = {
  app: {
    launched: 'App Launched',
    backgrounded: 'App Backgrounded',
    nav: 'Navigated'
  },
  auth: {
    login: 'Logged In',
    logout: 'Logged Out',
    loginWithCode: 'Logged In with Auth Code',
    authCodeRequest: 'Auth Code Requested',
    changePassword: 'Changed Password',
    registered: 'Registered'
  },
  click: {
    button: 'Button Clicked',
    tab: 'Tab Clicked',
    field: 'Field Clicked'
  }
}

const getQueryParam = (url: string, param: string) => {
  // Expects a raw URL
  /* eslint-disable no-empty-character-class */
  param = param.replace(/[[]/, '[').replace(/[]]/, ']')
  /* eslint-enable no-empty-character-class */
  var regexS = '[?&]' + param + '=([^&#]*)'
  var regex = new RegExp(regexS)
  var results = regex.exec(url)
  if (results === null || (results && typeof results[1] !== 'string')) {
    return ''
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
  }
}

let mixpanel
let disabled
let lastProps: any
let lastEvent: string

const useMixpanel = () => {
  const { data } = useQuery(configQuery, {
    variables: { key: 'mixpanelDisabled' }
  })

  if (data) {
    disabled = data.config
  }

  if (Platform.OS === 'web') {
    Mixpanel.init(Constants?.manifest?.extra?.MIXPANEL_TOKEN)
  } else {
    mixpanel = callback =>
      Mixpanel.sharedInstanceWithToken(
        Constants?.manifest?.extra?.MIXPANEL_TOKEN
      )
        .then(() => callback())
        .catch(error => console.error('Failed to initialize Mixpanel: ', error))
  }

  // Track event without properties
  const track = async (event: string) => {
    if (disabled || disabled === undefined || event === lastEvent) return

    // Because app id needs to be sent with every event, we now need to forward
    // this event to trackWithProperties -- where appId will be added
    trackWithProperties(event, {})
  }

  // Track event with properties
  const trackWithProperties = async (event: string, properties: any) => {
    if (disabled || disabled === undefined) return
    // If properties of target object are same as previous, don't capture duplicate events
    if (lastProps && lastProps['token']) {
      delete lastProps['token'] // I have no idea why this property is being added
    }
    if (equals(lastProps, properties)) return

    if (
      properties.navArea &&
      lastProps &&
      lastProps.selection &&
      properties.navArea.includes(lastProps.selection)
    ) {
      lastProps = properties
      return
    }
    lastProps = properties

    // add the app id to the properties just before sending to mixpanel
    properties.appId = AppIds.Innovation

    if (mixpanel) {
      mixpanel(() => Mixpanel.trackWithProperties(event, properties))
    } else {
      Mixpanel.track(event, properties)
    }

    lastEvent = event
  }

  // Identify, i.e. associate to an existing mixpanel profile
  const identify = async (personId: string) => {
    if (disabled || disabled === undefined) return
    if (mixpanel) {
      mixpanel(() => Mixpanel.identify(personId))
    } else {
      Mixpanel.identify(personId)
    }
  }

  // Set the value of a user's persisted property (e.g. email, primary group, etc.)
  const set = async (propertyAndValue: any) => {
    if (disabled || disabled === undefined) return
    if (mixpanel) {
      mixpanel(() => Mixpanel.set(propertyAndValue))
    } else {
      Mixpanel.people.set(propertyAndValue)
    }
  }

  // Increment a property on a specific user
  const increment = async (property: string) => {
    if (disabled || disabled === undefined) return
    if (mixpanel) {
      mixpanel(() => Mixpanel.increment(property, 1))
    } else {
      Mixpanel.people.increment(property, 1)
    }
  }

  // Append items to a list property
  const union = async (list: string, items: string[]) => {
    if (disabled || disabled === undefined) return
    if (mixpanel) {
      mixpanel(() => Mixpanel.union(list, items))
    } else {
      Mixpanel.people.union(list, items)
    }
  }

  // Reset the identity (for logout etc)
  const reset = async () => {
    if (disabled || disabled === undefined) return
    mixpanel(() => Mixpanel.reset())
  }

  // Set the value of an immutable property on the user (e.g. First Touch UTM)
  const setOnce = async (propertyAndValue: any) => {
    if (disabled || disabled === undefined) return
    if (mixpanel) {
      mixpanel(() => Mixpanel.set_once(propertyAndValue))
    } else {
      Mixpanel.people.set_once(propertyAndValue)
    }
  }

  // Register a super property (to be delivered on all events until overwritten)
  const register = async (propertyAndValue: any) => {
    if (disabled || disabled === undefined) return
    if (mixpanel) {
      mixpanel(() => Mixpanel.register(propertyAndValue))
    } else {
      Mixpanel.register(propertyAndValue)
    }
  }

  // The structure of this function, as well as the specifics of the getQueryParams function
  // were adapted from this Mixpanel Community suggestion for tracking Last Touch UTM:
  // https://help.mixpanel.com/hc/en-us/articles/360001337103
  const setUTMParams = (path: string) => {
    var campaignKeywords: string[] = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(
      ' '
    )
    var kw = ''
    var params: any = {}
    var firstParams: any = {}
    var index: number
    for (index = 0; index < campaignKeywords.length; ++index) {
      kw = getQueryParam(path, campaignKeywords[index])
      if (kw.length) {
        params[campaignKeywords[index] + ' [last touch]'] = kw
      }
    }
    for (index = 0; index < campaignKeywords.length; ++index) {
      kw = getQueryParam(path, campaignKeywords[index])
      if (kw.length) {
        firstParams[campaignKeywords[index] + ' [first touch]'] = kw
      }
    }

    // add the last touch parameters to the user profile, and overwrite whatever was existing
    // add the first touch parameters, but only if they haven't been set before
    // then register the last touch parameters as super properties on all events going forward,
    // to be overwritten if new values are received
    set(params)
    setOnce(firstParams)
    register(params)
  }

  return {
    track,
    trackWithProperties,
    identify,
    set,
    increment,
    union,
    reset,
    setUTMParams
  }
}

export default useMixpanel
