import React, { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
// Hooks
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
// Components
import PreCurateDialog from './PreCurateDialog'
import Button from '../../../../../ui-library/Button'
import { Flex } from '../../../../../components/FlexBox'
import { useTable } from '../../../../../components/Table'
import Dropdown from '../../../../../components/common/Dropdown'
import useToast from '../../../../../hooks/useToast'
import useTranslation from '../../../../../hooks/useTranslation'
import { DynamicTable } from '../../../../../components/DynamicTable'
import { TableContainer } from '../../../../../components/StaticForm'
import { StyledLabel } from '../../../../../ui-library/TextInput/Label'
import {
  Container,
  SettingsContainer
} from '../../../components/SharedComponents'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
// Types
import { EngineEnum } from '../../../constants'
import {
  CallForSubmission,
  CallForSubmissionStepEnum,
  InnovationSubmissionStatusEnum,
  RoleEnum
} from '../../../../../types'
import { getPreCurationTableConfig } from '../../../../Curation/constants'

interface PreCurateTestSettings {
  callForSubmission: CallForSubmission
}

const PreCurateTestSettings = (props: PreCurateTestSettings) => {
  const { t } = useTranslation()
  const { callForSubmission } = props
  const { roles } = useCurrentUser()
  const [selectedElements, setSelectedElements] = useState<string[]>([])
  const { setToastWarningMessage } = useToast()
  const [openDialog, setOpenDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [statusFilteredBy, setStatusFilterBy] = useState<string>('Pass')

  const statusFilterOptions = [
    {
      label: 'Fast Pass',
      value: 'Fast Pass'
    },
    {
      label: 'Pass',
      value: 'Pass'
    },
    {
      label: 'Cut',
      value: 'Cut'
    },
    {
      label: 'Flagged',
      value: 'Flagged'
    }
  ]

  const isPrecurateStep: boolean = useMemo(
    () =>
      callForSubmission &&
      callForSubmission?.currentStep?.name ===
        CallForSubmissionStepEnum.preCurate,
    [callForSubmission]
  )

  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)

  const onSelectedItemsKeysChange = (keys: string[]) => {
    setSelectedElements(keys)
  }

  const closeDialog = useCallback(() => {
    setIsSubmitting(false)
    setOpenDialog(false)
  }, [])

  const tableProps = useTable({
    config: getPreCurationTableConfig({
      role:
        roles.includes(RoleEnum.Admin) ||
        roles.includes(RoleEnum.InnovationAdmin)
          ? RoleEnum.InnovationStaff
          : RoleEnum.InnovatorTeamMember,
      currentCFSStep: callForSubmission.currentStep.name,
      engine: EngineEnum.InnovationSubmission,
      cfsId: callForSubmission.id,
      isInnovationNewDynamicTable,
      passOrCutText: statusFilteredBy,
      isTestingModule: true
    }) as any
  })

  return (
    <SettingsContainer>
      <Container
        padding={`0`}
        style={{
          paddingBottom: 30
        }}
      >
        <Flex flexDirection="row" alignItems="center" marginBottom={16}>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
          <Dropdown
            name={t('curation:advisors:table:cfsStatus')}
            value={statusFilteredBy}
            placeholder={t('curation:advisors:table:cfsStatus')}
            options={statusFilterOptions}
            onSelect={setStatusFilterBy}
            isFilter
            isClearable
            style={{ marginBottom: 0, marginLeft: 32, minHeight: 0 }}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
          />
        </Flex>
        <TableContainer height="100%">
          <DynamicTable
            {...(tableProps as any)}
            onSelectedItemsKeysChange={onSelectedItemsKeysChange}
            emptyMessage={t('innovatorDirectory:table:noInnovators')}
          />
        </TableContainer>
      </Container>
      <View style={{ height: '5%' }}>
        <Button
          title={t('settings:testing:updateSubmissions')}
          onPress={() => {
            if (isPrecurateStep) {
              setOpenDialog(!openDialog)
            } else {
              setToastWarningMessage(t('settings:testing:noPreCurateCFS'))
            }
          }}
          disabled={
            !selectedElements || selectedElements.length < 1 || isSubmitting
          }
          isProcessing={isSubmitting}
        />
      </View>
      <PreCurateDialog
        openDialog={openDialog}
        triggerClose={closeDialog}
        selectedElements={selectedElements}
        submissionStatus={statusFilteredBy as InnovationSubmissionStatusEnum}
      />
    </SettingsContainer>
  )
}

export default PreCurateTestSettings
