import { atomFamily } from 'recoil'

export interface FileProps {
  id: string
  label: string
  cloudinaryId: string
  isLoading: boolean
  isDownloadable: boolean
  error?: any
  subscriptionKeys?: object
}

export interface FilesProps {
  files: FileProps[]
  isLoadingFiles: boolean
  isRefreshingFiles: boolean
}

const currentDownloadFilesAtom = atomFamily<FilesProps, string>({
  key: 'downloadFiles',
  default: {
    files: [],
    isLoadingFiles: true,
    isRefreshingFiles: false
  } as FilesProps
})

export default currentDownloadFilesAtom
