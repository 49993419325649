import theme from '../../../../../constants/Theme'

const buttonPadding = {
  vertical: theme.space[1] + theme.space[2],
  horizontal: theme.space[2] + theme.space[3]
}

export const btnContainerStyles = {
  height: theme.space[4],
  marginTop: theme.space[1] + theme.space[3]
}

export const btnStyles = {
  width: 'fit-content',
  paddingTop: buttonPadding.vertical,
  paddingBottom: buttonPadding.vertical,
  paddingLeft: buttonPadding.horizontal,
  paddingRight: buttonPadding.horizontal,
  height: theme.space[4]
}

export const DEFAULT_TIMEOUT = 5000
