import React, { useState } from 'react'
import { View } from 'react-native'
// Hooks
import useToast from '../../../../hooks/useToast'
import useTranslation from '../../../../hooks/useTranslation'
import useCreateMockSubmissionsMutation from './hooks/useCreateMockSubmissionsMutation'
// Components
import Button from '../../../../ui-library/Button'
import { useTable } from '../../../../components/Table'
import { getTestSubmissionTableConfig } from './constants'
import { DynamicTable } from '../../../../components/DynamicTable'
import { SettingsContainer } from '../../components/SharedComponents'
import { TableNameEnum } from '../../../../components/DynamicTable/types'

interface CreateSubmissionsTestingSettingsProps {
  cfsId: string
}
const CreateSubmissionsTestingSettings = (
  props: CreateSubmissionsTestingSettingsProps
) => {
  const { cfsId } = props
  const { setToastErrorMessage, setToastMessage } = useToast()
  const { t } = useTranslation()
  const [selectedElements, setSelectedElements] = useState<string[]>([])

  const { createMockSubmissions, loading } = useCreateMockSubmissionsMutation()

  const onSelectedItemsKeysChange = (keys: string[]) => {
    setSelectedElements(keys)
  }

  const handleCreateMockSubmissions = async () => {
    const result = await createMockSubmissions({
      variables: {
        id: cfsId,
        companyProfileIds: selectedElements,
        onlyRequiredQuestion: false,
        anyValidation: true
      }
    })
    if (result.errors) {
      setToastErrorMessage(String(result.errors))
    } else {
      setToastMessage(t('settings:testing:createMockSubmissionsSuccess'))
    }
  }

  const tableProps = useTable({
    config: getTestSubmissionTableConfig()
  } as any)

  return (
    <SettingsContainer>
      <View style={{ height: '95%' }}>
        <DynamicTable
          {...(tableProps as any)}
          onSelectedItemsKeysChange={onSelectedItemsKeysChange}
          config={{
            ...tableProps.config,
            name: tableProps.config.name as TableNameEnum
          }}
          emptyMessage={t('innovatorDirectory:table:noInnovators')}
        />
      </View>
      <View style={{ height: '5%' }}>
        <Button
          title={t('settings:testing:createSubmissions')}
          onPress={handleCreateMockSubmissions}
          isProcessing={loading}
          disabled={!selectedElements || selectedElements.length < 1 || loading}
        />
      </View>
    </SettingsContainer>
  )
}

export default CreateSubmissionsTestingSettings
