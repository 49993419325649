import React from 'react'
import { FlatListComponentProps } from '../types'
import { StyledFlatList } from '../SharedStyledComponents'
import Header from '../Header'
import Item from '../Item'

const defaultKeyExtractor = (item: any, i: any) => `${item?.id}_${i}`

const FlatListComponent = (props: FlatListComponentProps) => {
  const {
    name,
    data,
    keyExtractor = defaultKeyExtractor,
    leftTable,
    renderHeader,
    pageSize,
    isSticky,
    headerRef
  } = props

  const initialRender = Math.round(data.length / pageSize) * pageSize

  const headerComponentStyles = isSticky
    ? {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        background: '#FFFFFF'
      }
    : {}

  return (
    <StyledFlatList
      testID={'tableContainer'}
      key={name}
      data={data}
      keyExtractor={keyExtractor}
      ListHeaderComponent={
        renderHeader ? (
          <Header leftTable={leftTable} headerRef={headerRef} />
        ) : null
      }
      isSticky={isSticky}
      ListHeaderComponentStyle={headerComponentStyles}
      renderItem={({ item, index }) => (
        <Item index={index} item={item} leftTable={leftTable} />
      )}
      initialNumToRender={initialRender <= 0 ? 1 : initialRender - 1}
    />
  )
}

export default FlatListComponent
