import React from 'react'
import styled from 'styled-components/native'
// Components
import Skeleton from '../../../../components/skeletonLoadings/Skeleton'
import { ViewStyle } from 'react-native'

export const BorderContainer = styled.View`
  width: 100%;
  border-width: 1px;
  flex-direction: column;
  ${({ height, theme: { sizes, radii, colors } }) => `
    height: ${height || sizes[4]}px;
    border-color: ${colors.lightGrey};
    background-color: ${colors.white};
    border-radius: ${radii[3] * radii[1]}px;
  `}
`

const CardBadge = styled(Skeleton as any)`
  position: absolute;
  ${({
    badgeTopPosition,
    badgeLeftPosition,
    width,
    height,
    theme: { radii, colors, sizes }
  }) => {
    const badgeHeight = height || sizes[3] + sizes[1]
    return `
      top: ${badgeTopPosition || -(badgeHeight / 2)}px;
      left: ${badgeLeftPosition !== undefined ? badgeLeftPosition : sizes[4]}px;
      height: ${badgeHeight}px;
      width: ${width || '20%'};
      background-color: ${colors.lightGrey};
      border-radius: ${radii[3] * radii[1]}px;
    `
  }}
`

interface BorderCardProps {
  children?: any
  style?: ViewStyle
  badgeWidth?: number | string
  badgeHeight?: number | string
  showBadge?: boolean
  badgeTopPosition?: number
  badgeLeftPosition?: number
}

export const BorderCard = ({
  children,
  badgeWidth,
  badgeHeight,
  showBadge = true,
  badgeTopPosition,
  badgeLeftPosition,
  ...rest
}: BorderCardProps) => (
  <BorderContainer {...rest}>
    {showBadge && (
      <CardBadge
        width={badgeWidth}
        height={badgeHeight}
        badgeTopPosition={badgeTopPosition}
        badgeLeftPosition={badgeLeftPosition}
      />
    )}
    {children}
  </BorderContainer>
)
