import { useTheme } from 'styled-components/native'
import React, { useEffect, useMemo, useState } from 'react'
import useCurrentSubmissionsQuery from '../../hooks/useCurrentSubmissionsQuery'
import { Flex } from '../../../../components/FlexBox'
import { TouchableOpacity } from 'react-native'
import ArrowLeftIcon from '../../../../components/icon/ArrowLeftIcon'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import { Title } from '../index'
import useTranslation from '../../../../hooks/useTranslation'
import { InnovationSubmission } from '../../../../types'
import { useIsFocused } from '@react-navigation/native'
import { useRecoilValue } from 'recoil'
import {
  OutsideFetchMoreResultsProps,
  fetchMoreResultsByName
} from '../../../../recoil/fetchMoreResultsAtom'
import { DEFAULT_PAGE_SIZE } from '../../../../components/DynamicTable/hooks/TableProvider'
import { searchQueryByName } from '../../../../recoil/searchQueryAtom'

type ButtonAction = 'Next' | 'Prev'
interface NextPrevSubmissionsButtonProps {
  tableName: string
  submissionId?: string
  setSubmissionId: (submissionId: string) => void
  action?: ButtonAction
}

const NextPrevSubmissionButton = ({
  submissionId,
  setSubmissionId,
  action = 'Next',
  tableName
}: NextPrevSubmissionsButtonProps) => {
  const { t } = useTranslation()
  const focused = useIsFocused()
  const { colors, space } = useTheme()
  const isSmallScreen = useIsSmallScreen()
  const { submissions } = useCurrentSubmissionsQuery()
  const [currentSubmissionIndex, setCurrentSubmissionIndex] = useState(-1)
  const [nextSubmission, setNextSubmission] = useState<InnovationSubmission>()
  const [prevSubmission, setPrevSubmission] = useState<InnovationSubmission>()

  const { fetchMoreResults } = useRecoilValue(fetchMoreResultsByName(tableName))
  const { variables } = useRecoilValue(searchQueryByName(tableName))
  const offset = useMemo(
    () => Math.ceil(submissions.length / DEFAULT_PAGE_SIZE) + 1,
    [submissions]
  )
  const fetchMoreVariables: OutsideFetchMoreResultsProps = {
    variables: {
      engine: variables.engine,
      offset,
      size: variables.size,
      sort: variables.sort
    }
  }

  useEffect(() => {
    if (!focused) {
      setCurrentSubmissionIndex(-1)
      setNextSubmission(undefined)
    }
  }, [focused])

  useEffect(() => {
    if (submissions.length) {
      const index = submissions?.findIndex(sub => sub.id === submissionId)
      if (index > -1) setCurrentSubmissionIndex(index)
    }
  }, [submissions, submissionId])

  useEffect(() => {
    // next submission
    if (action === 'Next' && currentSubmissionIndex > -1) {
      if (
        currentSubmissionIndex > -1 &&
        submissions.length > currentSubmissionIndex
      ) {
        const newSubmission = submissions[currentSubmissionIndex + 1]
        setNextSubmission(newSubmission)
      }
    }
    // prev submission
    if (action === 'Prev' && currentSubmissionIndex > 0) {
      const prevSubmission = submissions[currentSubmissionIndex - 1]
      setPrevSubmission(prevSubmission)
    }
  }, [submissions, currentSubmissionIndex])

  const text =
    action === 'Next'
      ? t('callsForSubmission:table:nextSubmission')
      : t('callsForSubmission:table:prevSubmission')

  if (
    (!nextSubmission && action === 'Next') ||
    (!prevSubmission && action === 'Prev')
  ) {
    return null
  }

  const handlePressNext = async () => {
    if (nextSubmission) {
      if (submissions.length - 2 === currentSubmissionIndex) {
        await fetchMoreResults(fetchMoreVariables)
      }
      setSubmissionId(nextSubmission.id)
    }
  }

  return (
    <Flex
      style={{
        justifyContent: 'flex-end'
      }}
      width="28%"
      flexDirection="row"
    >
      {prevSubmission && (
        <TouchableOpacity
          onPress={() => setSubmissionId(prevSubmission.id)}
          style={{
            transform: [{ rotate: '0deg' }],
            marginRight: space[3] + space[1] // 20
          }}
        >
          <ArrowLeftIcon color={colors.lightGrey} />
        </TouchableOpacity>
      )}
      <Title isSmallScreen={isSmallScreen}>{text}</Title>
      {nextSubmission && (
        <TouchableOpacity
          onPress={handlePressNext}
          style={{
            transform: [{ rotate: '180deg' }],
            marginLeft: space[3] + space[1] // 20
          }}
        >
          <ArrowLeftIcon color={colors.lightGrey} />
        </TouchableOpacity>
      )}
    </Flex>
  )
}

export default NextPrevSubmissionButton
