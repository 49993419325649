import React from 'react'

import styled from 'styled-components/native'

const Container = styled.View`
  ${({ zIndex = 0, theme: { space } }) => `
    width: 100%;
    z-index: -${zIndex};
    margin-bottom: ${space[4]}px;
  `}
`

const Label = styled.Text`
  ${({
    theme: { colors, fontWeights, fontSizes, space },
    isDynamic,
    titleStyle
  }) => `
    color: ${colors.text1};
    fontWeight: ${titleStyle?.fontWeight || fontWeights.bold};
    fontSize: ${titleStyle?.fontSize || fontSizes[4]}px;
    position: absolute;
    top: -${fontSizes[4] / 2}px
    marginLeft: ${isDynamic ? 0 : 55}px;
    background:  ${colors.appBackground};
    padding: 0 ${space[1]}px;
    z-index: 2;
  `}
`

const Card = styled.View`
  ${({ theme }) => `
    position: relative;
    border-color: ${theme.colors.text1};
    border-style: solid;
    border-radius: 15px;
    background:  ${theme.colors.appBackground};
  `}
`

const Title = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes }, titleStyle }) => `
    color: ${titleStyle?.color || colors.text1};
    fontWeight: ${titleStyle?.fontWeight || fontWeights.bold};
    fontSize: ${titleStyle?.fontSize || fontSizes[4]}px;
  `}
`

const TitleCard = ({
  testID,
  asPrintable = false,
  titleStyle = undefined,
  children,
  title,
  capitalized = true,
  zIndexLayer = 0,
  isDynamic = false
}) =>
  asPrintable ? (
    <Container zIndex={zIndexLayer}>
      <Title titleStyle={titleStyle}>
        {capitalized ? title.toUpperCase() : title}
      </Title>
      <Card>{children}</Card>
    </Container>
  ) : (
    <Container testID={testID} zIndex={zIndexLayer}>
      <Label titleStyle={titleStyle} isDynamic={isDynamic}>
        {capitalized ? title.toUpperCase() : title}
      </Label>
      <Card>{children}</Card>
    </Container>
  )

export default TitleCard
