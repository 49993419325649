import { View } from 'react-native'
import styled from 'styled-components/native'

// Main Table Components

export const ContextContainer = styled(View)``

export const MainContainer = styled(View)`
  height: 100%;
  width: 100%;
`

export const AutoCompleteDropdownContainer = styled(View)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.space[3]}px;
`
