import React from 'react'
import styled from 'styled-components/native'
// Components
import {
  Row,
  RowSkeleton,
  TableInputText,
  TableSkeletonRow
} from '../../components/skeletonLoadings/components'
import Skeleton from '../../components/skeletonLoadings/Skeleton'
import ArrowRightIcon from '../../components/icon/ArrowRightIcon'

// Styled components
const Container = styled.View`
  width: 100%;
  ${({ theme: { space } }) => `
    margin-bottom: ${space[2]}px;
  `}
`
interface TableSkeletonProps {
  showIcon?: boolean
  showHeader?: boolean
  numberOfRows?: number
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({
  showHeader = true,
  numberOfRows = 5,
  showIcon
}) => (
  <Container>
    {showHeader && <TableRow isHeader />}
    {Array.from(Array(numberOfRows), (_, index) => (
      <TableRow
        key={`key_tableRow_${index}`}
        index={index}
        showIcon={showIcon}
      />
    ))}
  </Container>
)

// Styled components
const ELEMENT_HEIGHT: number = 6
const ShimmerRow = styled(RowSkeleton)({ height: ELEMENT_HEIGHT })
const BlankRow = styled(TableInputText)({ height: ELEMENT_HEIGHT })
const IconBG = styled(Skeleton).attrs(({ theme: { colors } }) => ({
  colorLight: colors.skeletonIcon
}))`
  position: absolute;
  align-self: center;
  align-items: center;
  justify-content: center;
  ${({ theme: { sizes } }) => {
    const size = sizes[4] - sizes[1]
    return ` 
      height: ${size}px;
      width: ${size}px;
      right: ${size}px;
      border-radius: ${size / 2}px;
    `
  }}
`
interface TableRowProps {
  index?: number
  isHeader?: boolean
  showIcon?: boolean
}

const TableRow = React.memo(({ index, isHeader, showIcon }: TableRowProps) => {
  const isEven: boolean = !!index && (index + 1) % 2 === 0
  const RowElement: any = isEven || isHeader ? ShimmerRow : BlankRow
  return (
    <TableRowContainer isEven={isEven} isHeader={isHeader}>
      <RowElement marginRight={93} width="4%" />
      <RowElement marginRight={55} width="10%" />
      <RowElement marginRight={110} width="4%" />
      <RowElement marginRight={86} width="13%" />
      <RowElement marginRight={93} width="7%" />
      {!isHeader && showIcon ? (
        <IconBG>
          <ArrowRightIcon />
        </IconBG>
      ) : (
        <RowElement width="4%" />
      )}
    </TableRowContainer>
  )
})

interface TableRowContainerI {
  children: any
  isEven: boolean
  isHeader?: boolean
}

const TableRowContainer = React.memo(
  ({ children, isEven, isHeader }: TableRowContainerI) =>
    isEven || isHeader ? (
      <Row
        paddingLeft={25}
        paddingRight={25}
        justifyContent="flex-start"
        marginTop={15}
        marginBottom={15}
      >
        {children}
      </Row>
    ) : (
      <TableSkeletonRow>{children}</TableSkeletonRow>
    )
)

export default TableSkeleton
