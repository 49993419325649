import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import unsavedChangesAtom from '../recoil/unsavedChangesAtom'
import { InnovationSubmissionStatusEnum } from '../types'
import useHistoryRoutes from './useHistoryRoutes'

/**
 * This hook is used on the TopBar (mobile and desktop) and SideBar to know whether to open or close
 * the unsaved changes dialog.
 */
export const useUnsavedChangesDialog = () => {
  const { pushToHistory } = useHistoryRoutes()
  const [unsavedChanges, setUnsavedChanges] = useRecoilState(unsavedChangesAtom)
  const [currentRoute, setCurrentRoute] = useState('')
  const [showDialog, setShowDialog] = useState(false)

  const resetUnsavedChanges = () => {
    setUnsavedChanges({
      hasUnsavedChanges: false,
      saveCallback: () => {},
      discardCallback: () => {}
    })
  }

  const continueNavigation = navigate => {
    const route = currentRoute

    setCurrentRoute('')
    setShowDialog(false)
    resetUnsavedChanges()

    pushToHistory({
      screen: route
    })

    currentRoute && navigate(route)
  }

  const setRouteAndDialog = (routeName, dialogState) => {
    setCurrentRoute(routeName)
    setShowDialog(dialogState)
  }

  return {
    unsavedChanges,
    setRouteAndDialog,
    continueNavigation,
    showDialog,
    setShowDialog,
    resetUnsavedChanges
  }
}

/**
 * This hook is used to save in an atom the current form dirty state and its
 * save callback so when the unsaved Dialog appears the saveBtn can trigger that
 * specific form's save callback
 * @param form
 * @param saveCallback
 * @param submission
 */
const useUnsavedChanges = (form, saveCallback, submission) => {
  const [unsavedChanges, setUnsavedChanges] = useRecoilState(unsavedChangesAtom)

  useEffect(() => {
    if (
      submission.isSubmission &&
      InnovationSubmissionStatusEnum.submitted === submission?.status
    ) {
      setUnsavedChanges({
        hasUnsavedChanges: false,
        saveCallback: () => {},
        discardCallback: () => {}
      })
    } else {
      setUnsavedChanges({
        hasUnsavedChanges: form.dirty,
        saveCallback,
        discardCallback: () => {
          form.resetForm(form.initialValues)
        }
      })
    }
  }, [JSON.stringify(form), JSON.stringify(submission)])

  return {
    unsavedChanges,
    setUnsavedChanges
  }
}

export default useUnsavedChanges
