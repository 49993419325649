import React, { useEffect, useState } from 'react'
import { View, ViewStyle } from 'react-native'

import { useField, useFormikContext } from 'formik'
import { pick } from 'ramda'
import { useRecoilValue } from 'recoil'

import { JSONStyles, Question, QuestionTypeEnum } from './types'
import Dropdown, { DropdownProps } from '../common/Dropdown'
import useTranslation from '../../hooks/useTranslation'
import useDropdownOptions from './hooks/useDropdownOptions'
import {
  ErrorContainer,
  ErrorText
} from '../../screens/authentication/components/SharedStyledComponents'
import useQuestionDependency from './hooks/useQuestionDependency'
import { PrintableLabel } from '../../ui-library/common/PrintableStyledComponents'
// Recoil
import { formSubmissionMetaDataAtomFamily } from './recoil/formSubmissionMetaDataAtomFamily'
import { marketSegmentsMetaDataAtomFamily } from './recoil/marketSegmentsMetaDataAtomFamily'
import { getSingleQuestion } from './recoil/questionAtomFamily'
import Label from '../../ui-library/TextInput/Label'

export type DropDownStyles = Pick<
  JSONStyles,
  'containerStyles' | 'errorContainerStyles' | 'labelStyles' | 'styles'
>

export interface QuestionOptionsArgs {
  optionsEntity?: string
  optionsValues?: JSON | Option[]
  subType?: string
  defaultLabel?: string
}

export interface DropDownDataProps {
  key: string
  name: string
  label?: string
  helperText?: string | undefined
  placeholder: string
  disabled?: boolean
  initialValue: string | undefined
  styles?: DropDownStyles
  questionOptions: QuestionOptionsArgs
  formSubmissionId: string
  questionDependencyIds?: string[]
  questionId: string
  type: QuestionTypeEnum
}

export interface Option {
  label: string
  value: string | null
}

interface DynamicDropDownDataProps extends DropdownProps {
  data: DropDownDataProps
  isReadOnly?: boolean
  asPrintable?: boolean
}

const DynamicDropDown = (props: DynamicDropDownDataProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { data, isReadOnly = false, asPrintable = false } = props
  const {
    name,
    label,
    helperText,
    placeholder,
    disabled,
    initialValue,
    styles,
    questionOptions,
    formSubmissionId,
    questionId,
    questionDependencyIds = []
  } = data

  const { t } = useTranslation()
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [dropdownFieldValue, setDropdownFieldValue] = useState<string>('')
  const question = useRecoilValue(getSingleQuestion(questionId))

  const cfsMetaData = useRecoilValue(
    formSubmissionMetaDataAtomFamily(formSubmissionId)
  )
  const marketSegmentsMetaData = useRecoilValue(
    marketSegmentsMetaDataAtomFamily(formSubmissionId)
  )

  const [field, meta] = useField({
    name: name,
    type: 'dropdown'
  })

  useEffect(() => {
    if (field?.value) {
      setDropdownFieldValue(field.value)
    }
  }, [field?.value])

  const { options, loading } = useDropdownOptions(
    questionOptions,
    {
      cfsMetaData,
      marketSegmentsMetaData
    },
    isReadOnly || disabled
  )

  useEffect(() => {
    if (question?.answer && !loading && options.length) {
      let value = null
      const shouldSetValue =
        options.findIndex(option => option.value === question.answer.value) > -1
      if (shouldSetValue) {
        value = question.answer.value
      }
      setFieldValue(name, value)
    }
  }, [question, options, loading])

  const hasError = !!meta.error && !!meta.touched
  const { visible } = useQuestionDependency(
    questionDependencyIds,
    questionId,
    dropdownFieldValue
  )

  useEffect(() => {
    if (initialValue) {
      setFieldValue(name, initialValue)
    }
  }, [initialValue])

  const handleChange = value => {
    setFieldValue(name, value)
    setFieldTouched(name)
  }
  const onInputChange = (input: any) => {
    setMenuIsOpen(input)
  }
  const containerStyle: ViewStyle = {
    flexBasis: '100%',
    zIndex: menuIsOpen ? 999999 : -1,
    // @ts-ignore
    ...(styles?.containerStyles || {})
  }
  const testID =
    label === t('submissions:forms:questions:presentersAttend')
      ? 'presentersAttend'
      : ''

  return (
    visible && (
      <View testID={testID} style={containerStyle}>
        <Label
          label={label}
          helperText={helperText}
          withHelperText={!!helperText}
          asPrintable={asPrintable}
        />
        {label && asPrintable && !isReadOnly && (
          <PrintableLabel>{label}</PrintableLabel>
        )}
        <Dropdown
          name={name}
          options={options}
          placeholder={loading ? 'Loading ...' : placeholder}
          isDisabled={disabled || isReadOnly || loading}
          onSelect={handleChange}
          value={field.value}
          style={styles?.styles}
          asPrintable={asPrintable}
          menuIsOpen={menuIsOpen}
          isLoading={loading}
          onMenuOpen={() => onInputChange(true)}
          onMenuClose={() => onInputChange(false)}
        />
        {hasError && !(isReadOnly || asPrintable) && meta.error && (
          <ErrorContainer>
            <ErrorText>{t(meta.error, { length })}</ErrorText>
          </ErrorContainer>
        )}
      </View>
    )
  )
}

export const transformDropdownInputData = (
  question: Question,
  formSubmissionId: string
): DropDownDataProps => {
  return {
    key: `questions-${question?.id}`,
    type: question?.type,
    name: `questions.${question?.id}`,
    placeholder: question?.configData?.optionPlaceholder as string,
    label: question?.questionText,
    styles: pick(
      ['containerStyles', 'labelStyles', 'styles'],
      question?.style || {}
    ),
    questionOptions: {
      subType: question?.subType,
      optionsEntity: question?.optionsEntity,
      optionsValues: question?.optionsValues
    },
    initialValue: undefined,
    formSubmissionId,
    questionId: question?.id,
    questionDependencyIds: question?.questionDependencies?.map(qd => qd?.id),
    helperText: question?.configData?.helperText
      ? String(question?.configData?.helperText)
      : undefined
  }
}

export default DynamicDropDown
