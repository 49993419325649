import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const updateLegalMutation = gql`
  mutation updateLegal(
    $termsId: String!
    $privacyId: String!
    $cookieId: String!
  ) {
    termsAgreement: createLegalAgreement(legalDocId: $termsId, location: web) {
      id
    }

    privacyAgreement: createLegalAgreement(
      legalDocId: $privacyId
      location: web
    ) {
      id
    }

    cookieAgreement: createLegalAgreement(
      legalDocId: $cookieId
      location: web
    ) {
      id
    }
  }
`

const useUpdateLegalMutation = (options = {}) => {
  const [updateLegal, { loading }]: any[] = useMutation(
    updateLegalMutation,
    options
  )

  return [updateLegal, loading]
}

export default useUpdateLegalMutation
