import React, { createContext, useEffect, useMemo, useState } from 'react'
import {
  ColumnDefinition,
  ConfigDefinition,
  FilterableColumnDefinition,
  SortableColumnDefinition,
  TableNameEnum,
  TableProps,
  TableProviderProps,
  defaultSortDir
} from '../types'
// Hooks
import useFilter from './useFilter'
import usePropValue from '../../../hooks/usePropValue'
import { TablesEnum } from '../../../screens/CallForSubmission/constants'
import { getInitialFilters, getInitialSortState } from '../utils'

export const DEFAULT_PAGE_SIZE = 20

const tablePropsInitialValues: TableProps<any> = {
  keyExtractor: (item: any, i: any) => `${item?.id}_${i}`,
  config: {
    columns: [{}] as [ColumnDefinition<any>],
    filters: {} as JSON,
    hitKey: '',
    name: '' as TableNameEnum,
    pageSize: DEFAULT_PAGE_SIZE,
    showTotalElements: false,
    cohortId: undefined
  } as ConfigDefinition<any>,
  error: '',
  fetchMore: () => {},
  isReadOnly: false,
  loading: false,
  refetch: () => {},
  initialSelectedItems: {},
  EmptyComponent: null,
  emptyMessage: ''
}

const initialValues = {
  ...tablePropsInitialValues,
  hasFilters: false,
  isAdviseTable: false,
  queryFilters: {} as JSON,
  setQueryFilters: (() => {}) as React.Dispatch<React.SetStateAction<{}>>,
  setSearchFiltersValue: (() => {}) as (value: any) => any,
  searchFiltersValue: '',
  fixedColumns: [] as (
    | ColumnDefinition<any>
    | SortableColumnDefinition<any>
    | FilterableColumnDefinition<any>
  )[],
  dynamicColumns: [] as (
    | ColumnDefinition<any>
    | SortableColumnDefinition<any>
    | FilterableColumnDefinition<any>
  )[],
  filteredColumns: [] as (
    | ColumnDefinition<any>
    | SortableColumnDefinition<any>
    | FilterableColumnDefinition<any>
  )[],
  leftTableWidth: 0,
  rigthTableWidth: 0,
  handleSelectItems: (() => {}) as (items?: any[], selectAll?: boolean) => void,
  isItemSelected: (() => {}) as (value?: string) => boolean,
  sortBy: '',
  sortDir: defaultSortDir,
  handleOnSort: (() => {}) as (newSortBy: any) => void,
  selectedItems: {} as Object,
  selectedItemsLength: 0,
  selectedItemsKeys: [] as string[],
  selectedAll: false as boolean | undefined,
  selectAllElements: false as boolean | undefined,
  handleSetSelectAllElements: (() => {}) as (value: boolean) => void,
  handleSetData: (() => {}) as (data: any[]) => void,
  totalElements: 0,
  handleTotalElements: (() => {}) as (value: number) => void,
  handleDetailedViewItems: (() => {}) as (itemId: string) => void,
  detailedViewItems: [] as string[]
}

const TableContext = createContext(initialValues)

function TableProvider<T>({ props, children }: TableProviderProps<T>): any {
  // Prop Values
  const keyExtractor = useMemo(
    () => props?.keyExtractor ?? initialValues.keyExtractor,
    [props?.keyExtractor]
  )
  const config = usePropValue(props.config, props.config)
  const [data, setData] = useState<any[] | undefined>(props.data)
  useEffect(() => {
    if (props?.data && data !== props?.data) {
      setData(props.data)
    }
  }, [props.data])
  const handleSetData = (data: any[]) => {
    if (data.length) {
      setData(data)
    }
  }
  const [totalElements, setTotalElements] = useState(
    initialValues.totalElements
  )
  const handleTotalElements = (value: number) => {
    setTotalElements(value)
  }
  const error = usePropValue(props?.error, props?.error)
  const isReadOnly = usePropValue(props?.isReadOnly, props?.isReadOnly)
  const loading = usePropValue(props?.loading, props?.loading)
  const refetch = usePropValue(props?.refetch, props?.refetch)
  const emptyMessage = usePropValue(props?.emptyMessage, props?.emptyMessage)
  const EmptyComponent = usePropValue(
    props?.EmptyComponent,
    props?.EmptyComponent
  )

  const isAdviseTable = useMemo(() => config?.tableId === TablesEnum.Advise, [
    config?.tableId
  ])

  // Memoized Values
  // Visible Columns Only
  const filteredColumns = useMemo(
    () => config?.columns?.filter(ic => !ic?.isHidden),
    [config?.columns]
  )
  // if table is sticky all columns are fixed
  const fixedColumns = useMemo(
    () =>
      config.isSticky
        ? filteredColumns
        : filteredColumns.filter(column => column.fixed),
    [filteredColumns]
  )
  // if table is sticky no columns are dynamic
  const dynamicColumns = useMemo(
    () =>
      config.isSticky ? [] : filteredColumns.filter(column => !column.fixed),
    [filteredColumns]
  )
  const hasFilters = useMemo(() => {
    const filitersSum =
      filteredColumns.reduce(
        (acum, col) =>
          acum + (col?.filter && col?.filter?.type === 'filter' ? 1 : 0),
        0
      ) + (config?.showStatusFilter ? 1 : 0)
    return filitersSum > 0
  }, [filteredColumns, config?.showStatusFilter])

  // Sort Filters
  const [[sortBy, sortDir], updateSort] = useState(
    getInitialSortState(filteredColumns) || ['', defaultSortDir]
  )
  const handleOnSort = newSortBy => {
    let newSortDir
    if (newSortBy === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      newSortDir = 'asc'
    }
    updateSort([newSortBy, newSortDir])
  }

  // Query Filters
  const { filters, setFilters } = useFilter({})
  const configFilters = usePropValue(config?.filters, config?.filters)
  const [queryFilters, setQueryFilters] = useState(configFilters)

  useEffect(() => {
    if (props?.data?.length && props?.onPropsDataChanged) {
      props.onPropsDataChanged?.(data, props?.data, handleSetData)
    }
  }, [props?.data, props?.onPropsDataChanged])

  useEffect(() => {
    const handleFilters = () => {
      if (filters) {
        setQueryFilters({
          ...queryFilters,
          ...filters
        })
      }
    }
    handleFilters()
    setSelectedAll(props?.initialSelectedItems ? undefined : false)
  }, [filters])
  useEffect(() => {
    const handleFilters = () => {
      if (configFilters) {
        setQueryFilters({
          ...queryFilters,
          ...configFilters
        })
      }
    }
    handleFilters()
    setSelectedAll(props?.initialSelectedItems ? undefined : false)
  }, [configFilters])

  // Search Filters
  const [searchFiltersValue, setSearchFiltersValue] = useState({
    ...getInitialFilters(filteredColumns)
  })

  // Table Widths
  const leftTableWidth = useMemo(
    () =>
      fixedColumns.reduce(
        (totalWidth, column) =>
          column.width ? totalWidth + column.width.valueOf() : totalWidth,
        0
      ),
    [fixedColumns]
  )
  const rigthTableWidth = useMemo(
    () =>
      dynamicColumns.reduce(
        (totalWidth, column) =>
          column.width ? totalWidth + column.width.valueOf() : totalWidth,
        0
      ),
    [dynamicColumns]
  )

  // Selected Items
  const [selectAllElements, setSelectAllElements] = useState<
    boolean | undefined
  >(undefined)
  const [selectedAll, setSelectedAll] = useState<boolean | undefined>(undefined)
  const [newItems, setNewItems] = useState<any[]>([])
  const [detailedViewItems, setDetailedViewItems] = useState<string[]>([])
  const [loadingNewItems, setLoadingNewItems] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState(
    props.initialSelectedItems ?? {}
  )

  const handleSetSelectAllElements = (value: boolean) => {
    setSelectAllElements(value)
    if (!value) {
      setSelectedAll(false)
    }
  }
  const handleSelectItems = (items?: any[], selectAll?: boolean) => {
    if (typeof selectAll !== 'undefined') {
      setSelectedAll(selectAll)
    } else {
      items && setNewItems(items)
      setSelectedAll(undefined)
      setSelectAllElements(undefined)
    }
    setLoadingNewItems(false)
  }

  // Reset Selected Items On UnCheck Select All
  useEffect(() => {
    if (typeof selectedAll !== 'undefined' && !selectedAll) {
      setSelectedItems({})
      setSelectAllElements(false)
    } else if (selectedAll) {
      let itemsObject = {}
      data?.map(item => {
        itemsObject = { ...itemsObject, [item?.id]: true }
      })
      setSelectedItems(itemsObject)
    }
  }, [selectedAll])
  // Add New Items To The SelectedItems List From The Table
  useEffect(() => {
    let itemsObject = {}
    if (newItems.length && !loadingNewItems) {
      setLoadingNewItems(true)
      newItems.map(item => {
        itemsObject = {
          ...itemsObject,
          [item]: (selectedItems && !selectedItems[item]) ?? true
        }
      })
      setSelectedItems({ ...selectedItems, ...itemsObject })
      setLoadingNewItems(false)
      setNewItems([])
    }
  }, [newItems, selectedItems, loadingNewItems])

  useEffect(() => {
    if (props?.onSelectAllElementsChange) {
      props?.onSelectAllElementsChange?.(selectAllElements)
    }
  }, [selectAllElements, props?.onSelectAllElementsChange])

  useEffect(() => {
    if (props?.onFiltersChange) {
      props?.onFiltersChange(queryFilters)
    }
  }, [queryFilters, props?.onFiltersChange])

  useEffect(() => {
    if (
      props?.initialSelectedItems &&
      Object.keys(props?.initialSelectedItems)?.length
    ) {
      setSelectedItems(props.initialSelectedItems)
    }
  }, [props?.initialSelectedItems])

  const isItemSelected = (itemKey?: string): boolean => {
    return (
      (itemKey && selectedItems && selectedItems[itemKey]) ??
      (typeof selectAllElements !== 'undefined' && selectAllElements) ??
      false
    )
  }
  const selectedItemsKeys = useMemo(
    () =>
      Object.keys(selectedItems ?? {}).filter(
        item => selectedItems && selectedItems[item]
      ),
    [selectedItems]
  )

  // Detect on selectedItemsKeys change
  useEffect(() => {
    props?.onSelectedItemsKeysChange?.(selectedItemsKeys, selectAllElements)
  }, [selectedItemsKeys, selectAllElements])

  const selectedItemsLength = useMemo(() => selectedItemsKeys.length, [
    selectedItemsKeys.length
  ])

  const handleDetailedViewItems = (itemId: string) => {
    const itemIndex = detailedViewItems.findIndex(item => item === itemId)
    if (itemIndex > -1) {
      setDetailedViewItems(detailedViewItems.filter(item => item !== itemId))
    } else {
      setDetailedViewItems([...detailedViewItems, itemId])
    }
  }

  const tablePropsValues: TableProps<any> = {
    keyExtractor,
    config,
    error,
    fetchMore: () => {},
    isReadOnly,
    loading,
    refetch,
    emptyMessage,
    EmptyComponent,
    data
  }

  const additionalProps = {
    hasFilters,
    isAdviseTable,
    queryFilters,
    setQueryFilters: setFilters,
    setSearchFiltersValue,
    searchFiltersValue,
    selectedItems,
    handleSelectItems,
    isItemSelected,
    sortDir,
    sortBy,
    handleOnSort,
    fixedColumns,
    dynamicColumns,
    filteredColumns,
    leftTableWidth,
    rigthTableWidth,
    selectedItemsKeys,
    selectedItemsLength,
    selectedAll,
    handleSetData,
    totalElements,
    handleTotalElements,
    handleSetSelectAllElements,
    selectAllElements,
    handleDetailedViewItems,
    detailedViewItems
  }

  const contextValues = {
    ...tablePropsValues,
    ...additionalProps
  }

  return (
    <TableContext.Provider value={contextValues}>
      {children}
    </TableContext.Provider>
  )
}

export { TableProvider, TableContext }
