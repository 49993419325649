import { gql } from '@apollo/client'
import Constants from 'expo-constants'
import API from '../API'
import { v4 as uuidv4 } from 'uuid'

const { DATA_EXTRACTION_URL } = Constants.manifest.extra

const generateSignedUploadRequestMutation = gql`
  mutation generateSignedUploadRequest(
    $publicId: String
    $resourceType: String
    $caption: String
    $moderation: String
    $notificationUrl: String
  ) {
    generateSignedUploadRequest(
      publicId: $publicId
      resourceType: $resourceType
      caption: $caption
      moderation: $moderation
      notificationUrl: $notificationUrl
    ) {
      api_url
      signature
      api_key
      timestamp
      public_id
    }
  }
`

interface UploadOptions {
  moderation: string | null | undefined
  resourceType: string | null | undefined
}

const uploadToCloudinary = (
  file,
  folder: string | null | undefined = null,
  onProgress,
  options?: UploadOptions
) => {
  return new Promise(async (resolve, reject) => {
    let variables: any = {
      caption: file.name
    }
    if (folder) {
      variables.publicId = `${folder}/${uuidv4()}`
    }

    if (options?.resourceType) {
      variables.resourceType = options.resourceType
    }

    if (options?.moderation) {
      variables.moderation = options.moderation

      if (DATA_EXTRACTION_URL) {
        variables.notificationUrl = DATA_EXTRACTION_URL
      }
    }

    const uploadRequestResult = await API.mutate({
      mutation: generateSignedUploadRequestMutation,
      variables,
      fetchPolicy: 'no-cache'
    })

    const signedUploadRequest =
      uploadRequestResult.data.generateSignedUploadRequest

    const xhr = new XMLHttpRequest()
    const form = new FormData()

    xhr.open('POST', signedUploadRequest.api_url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhr.upload.addEventListener('progress', e => {
      onProgress(Math.round((e.loaded / e.total) * 100))
    })

    xhr.onreadystatechange = () => {
      console.log(`XHR state ${xhr.readyState}, status ${xhr.status}`)

      if (xhr.readyState !== 4) return

      if (xhr.status === 200) {
        resolve({ ...JSON.parse(xhr.responseText), filename: file.name })
      } else {
        reject(xhr.statusText)
      }
    }

    form.append('timestamp', signedUploadRequest.timestamp)
    form.append('signature', signedUploadRequest.signature)
    form.append('api_key', signedUploadRequest.api_key)
    form.append('context', `caption=${file.name}`)
    form.append('file', file)

    if (signedUploadRequest.public_id) {
      form.append('public_id', signedUploadRequest.public_id)
    }

    if (options?.moderation) {
      form.append('moderation', options.moderation)

      if (DATA_EXTRACTION_URL) {
        form.append('notification_url', DATA_EXTRACTION_URL)
      }
    }

    xhr.send(form)
  })
}

export default uploadToCloudinary
