import React, { useCallback, FC } from 'react'

import { Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

// Hooks
import useTranslation from '../../../hooks/useTranslation'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import useHasPermission from '../../../hooks/useHasPermission'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useUpdateSubmissionStatusMutation from '../../../screens/Submission/hooks/useUpdateSubmissionStatusMutation'
// Components
import Modal from '../Modal'
import { Flex } from '../../FlexBox'
import Button from '../../../ui-library/Button'
import TouchableIcon from '../../icon/TouchableIcon'
import { Title } from '../../../screens/InnovatorDirectory/components/SharedComponents'
// Types
import { InnovationSubmissionStatusEnum, RoleEnum } from '../../../types'
import useDeleteCallForSubmissionMutation from '../../../screens/CallForSubmission/hooks/useDeleteCallForSubmissionMutation'
import { searchQueryByName } from '../../../recoil/searchQueryAtom'
import { useRecoilValue } from 'recoil'

interface DeleteDialogProps {
  innovationSubmissionId: string
  callForSubmissionId?: string
  isCFSDelete?: boolean
  openDialog: boolean
  triggerClose: () => void
}

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`
const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[3]}px;
  `}
`

const containerStyle = {
  flexBasis: '48%'
}
const buttonStyle = {
  width: '100%'
}

const REDIRECT_CURATE_TAB = [RoleEnum.Admin, RoleEnum.InnovationAdmin]

const DeleteDialog: FC<DeleteDialogProps> = ({
  innovationSubmissionId,
  callForSubmissionId,
  isCFSDelete = false,
  openDialog,
  triggerClose
}) => {
  const isSmallScreen = useIsSmallScreen()
  const { space, colors } = useTheme()
  const titleStyle = isSmallScreen ? { fontSize: 15 } : {}
  const { t } = useTranslation()
  const linkToScreen = useLinkToScreen()
  const [
    updateInnovationSubmissionStatus,
    loading
  ] = useUpdateSubmissionStatusMutation({
    refetchQueries: ['callsForSubmission']
  })
  const onClose = useCallback(() => {
    triggerClose()
  }, [])
  const searchQueriesVariables = useRecoilValue(
    searchQueryByName('callForSubmissionsSearchInnovationEngine')
  )

  const shouldRedirectToCurateTabs =
    useHasPermission(REDIRECT_CURATE_TAB) && callForSubmissionId

  const [deleteCFS, loadingDeleteCFS] = useDeleteCallForSubmissionMutation({
    update: (cache, { data }) => {
      if (data['deleteCallForSubmission']) {
        const options = {
          query: searchQueriesVariables.query,
          variables: searchQueriesVariables.variables
        }
        if (!options.query) return

        const cachedQuery = cache.readQuery({
          ...options
        })
        if (!cachedQuery) return

        cache.writeQuery({
          ...options,
          data: {
            ...cachedQuery,
            searchInnovationEngine: {
              ...cachedQuery.searchInnovationEngine,
              hits:
                cachedQuery.searchInnovationEngine?.hits?.filter(
                  cfs => !!cfs && cfs.id !== callForSubmissionId
                ) ?? []
            }
          }
        })
      }
    }
  })

  const deleteInnovationSubmission = async () => {
    const result = await updateInnovationSubmissionStatus(
      innovationSubmissionId,
      InnovationSubmissionStatusEnum.inProgress,
      false,
      true
    )

    if (result?.errors) {
      result?.errors && console.error(result.errors)
    } else {
      onClose()
      linkToScreen(
        shouldRedirectToCurateTabs ? 'CurationSubmissions' : 'SubmissionList',
        shouldRedirectToCurateTabs ? { id: callForSubmissionId } : {}
      )
    }
  }

  const deleteCallForSubmission = async () => {
    const result = await deleteCFS(callForSubmissionId)
    if (result?.errors) {
      result?.errors && console.error(result.errors)
    } else if (result?.data?.deleteCallForSubmission) {
      onClose()
      linkToScreen('Home')
    }
  }

  return (
    <Modal
      close={onClose}
      open={openDialog}
      testIDModal="deleteModal"
      styles={{
        minWidth: '350px',
        maxWidth: '450px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={onClose} />

      <Flex padding={space[3]}>
        <Title>
          {t(
            isCFSDelete
              ? 'product:general:deleteCallForSubmission'
              : 'product:general:deleteSubmission'
          )}
        </Title>
        <View
          style={{
            marginTop: space[3]
          }}
        >
          <>
            <Description>
              {t(
                isCFSDelete
                  ? 'callsForSubmission:dialog:deleteCFSDescription'
                  : 'callsForSubmission:dialog:deleteDescription'
              )}
            </Description>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              style={{
                marginTop: space[3]
              }}
            >
              <Button
                title={t('callsForSubmission:dialog:cancelBtn')}
                type="outline"
                onPress={onClose}
                containerStyle={containerStyle}
                buttonStyle={buttonStyle}
                titleStyle={titleStyle}
              />
              <Button
                title={t('product:general:delete')}
                testID="confirmDelete"
                type="solid"
                onPress={() => {
                  if (isCFSDelete) {
                    deleteCallForSubmission()
                  } else {
                    deleteInnovationSubmission()
                  }
                }}
                containerStyle={containerStyle}
                buttonStyle={{ ...buttonStyle, backgroundColor: colors.danger }}
                titleStyle={titleStyle}
                isProcessing={loading || loadingDeleteCFS}
              />
            </Flex>
          </>
        </View>
      </Flex>
    </Modal>
  )
}

export default DeleteDialog
