import React, { FC, useState, useMemo } from 'react'
import { clone } from 'ramda'
import styled, { useTheme } from 'styled-components/native'
import { useNavigation, useRoute } from '@react-navigation/native'
import useTranslation from '../../../hooks/useTranslation'

import {
  ScreenContainerWithMenuHeader,
  ShadowContainerWithTabs
} from '../../../components/layout/ScreenContainer'
import {
  ActionContainer,
  ScrollViewContent,
  Title
} from './components/SettingsStyledComponents'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import Button from '../../../ui-library/Button'
import { DynamicTable, useTable } from '../../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../../components/DynamicTable'

import useCallForSubmissionQuery, {
  callForSubmissionQuery
} from '../../Curation/hooks/useCallForSubmissionQuery'
import HeaderNav from './components/HeaderNav'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import useAdvisorQuery from '../hooks/useAdvisorQuery'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import { RoleEnum } from '../../../types'
import { EngineEnum } from '../constants'
import { getAdvisorSubmissionsSelectionTableConfig } from './constants'
import { Flex } from '../../../components/FlexBox'
import useUpsertSubmissionsToReviewByAdvisor from '../../Curation/hooks/useUpsertSubmissionsToReviewByAdvisor'
import useToast from '../../../hooks/useToast'
import SubmissionSelectionSkeleton from './components/SubmissionSelectionSkeleton'
import { NEW_DYNAMIC_TABLE_FF } from '../../../components/DynamicTable/constants'
import { getExtraParams } from '../../../components/DynamicTable/utils'
import {
  TableNameEnum,
  TableProps
} from '../../../components/DynamicTable/types'

const TableContainer = styled(Flex).attrs(({ height }) => ({
  flexDirection: 'row',
  marginTop: 0,
  w: '100%',
  height: height || '90%'
}))`
  overflow-y: auto;
`

interface SubmissionsSelectionProps {
  callForSubmissionId: string
}

const SubmissionsSelection: FC<SubmissionsSelectionProps> = ({
  callForSubmissionId
}) => {
  const { t } = useTranslation()
  const { space } = useTheme()
  const route: any = useRoute()
  const navigation = useNavigation()
  const { setToastMessage } = useToast()
  const linkToScreen = useLinkToScreen()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const advisorId = route.params.advisorId

  const { callForSubmission, loading: isFetching } = useCallForSubmissionQuery(
    callForSubmissionId
  )
  const { advisorInfo, loading: loadingAdvisor } = useAdvisorQuery(
    callForSubmissionId,
    advisorId
  )

  const {
    upsertSubmissionsToReviewByAdvisor,
    loading: isProcessing
  } = useUpsertSubmissionsToReviewByAdvisor(callForSubmissionId, advisorId)

  const submissionsToReview = useMemo(() => {
    return advisorInfo?.submissionsToReview?.map(sr => sr.submissionId)
  }, [advisorInfo?.submissionsToReview])

  const [hasChanged, setHasChanged] = useState(false)
  const [assignAll, setAssignAll] = useState<boolean>(false)
  const [queryFilters, setQueryFilters] = useState<JSON>(JSON.parse('{}'))

  const handleChangeFilters = currentFilters => {
    setQueryFilters(currentFilters)
  }

  const [[toAdd, toRemove], setAdvisorsData] = useState<[string[], string[]]>([
    [],
    []
  ])

  const handleSelectSubmissions = (
    selectedSubmissions: string[] | object,
    selectAllElements?: boolean
  ) => {
    let submissionsToAdd: string[] = []
    let submissionsToRemove: string[] = []

    if (isInnovationNewDynamicTable && Array.isArray(selectedSubmissions)) {
      if (selectAllElements) {
        submissionsToAdd = []
        submissionsToRemove = []
      } else {
        // Left and right join to detect the differences of the current selection
        submissionsToAdd = selectedSubmissions.filter(
          id => !submissionsToReview.includes(id)
        )
        submissionsToRemove = submissionsToReview.filter(
          id => !selectedSubmissions.includes(id)
        )
      }
    } else {
      // Old table functionality
      Object.entries(selectedSubmissions).forEach(([key, val]) => {
        if (val) {
          if (!submissionsToReview?.includes(key)) {
            submissionsToAdd.push(key)
          }
        } else if (submissionsToReview?.includes(key)) {
          submissionsToRemove.push(key)
        }
      })
    }

    setHasChanged(
      selectAllElements ||
        submissionsToAdd?.length > 0 ||
        submissionsToRemove?.length > 0
    )
    setAdvisorsData([submissionsToAdd, submissionsToRemove])
  }

  const tableProps = useTable({
    // @ts-ignore
    config: getAdvisorSubmissionsSelectionTableConfig({
      role: RoleEnum.InnovationAdvisor,
      currentCFSStep: callForSubmission?.currentStep?.name,
      engine: EngineEnum.InnovationSubmission,
      cfsId: callForSubmission?.id,
      setSelectedSubmissions: !isInnovationNewDynamicTable
        ? handleSelectSubmissions
        : () => {},
      isInnovationNewDynamicTable,
      marketSegmentId: callForSubmission?.marketSegments.length
        ? callForSubmission?.marketSegments[0]?.id
        : null
    })
  }) as TableProps<any> | any

  const handleSaveSubmissionsToReview = async () => {
    const extraParams = getExtraParams({
      filters: {} as JSON,
      name: TableNameEnum.AdvisingSubmissionSelection,
      queryFilters
    })
    const { data } = await upsertSubmissionsToReviewByAdvisor({
      variables: {
        assignAll,
        callForSubmissionId: callForSubmissionId,
        advisorId: advisorId,
        submissionsIdsToAdd: toAdd,
        submissionsIdsToRemove: toRemove,
        extraParams
      },
      update: (cache, { data }) => {
        try {
          const { upsertSubmissionsToReviewByAdvisor } = data
          if (
            upsertSubmissionsToReviewByAdvisor?.userInfo &&
            upsertSubmissionsToReviewByAdvisor?.submissionsToReview
          ) {
            const cfsVariables = {
              id: callForSubmissionId,
              withSubmissionReviews: false,
              withSubmissionReviewsAverages: false,
              withInnovationSubmissions: false,
              filterIncomplete: true
            }

            let cfsQueryData: any = clone(
              cache.readQuery({
                query: callForSubmissionQuery,
                variables: cfsVariables
              })
            )
            if (cfsQueryData && cfsQueryData?.callForSubmission) {
              const { userInfo } = upsertSubmissionsToReviewByAdvisor

              const advisorIndex = cfsQueryData?.callForSubmission?.advisorsUsers?.findIndex?.(
                advisor => advisor?.id === userInfo?.id
              )

              cfsQueryData.callForSubmission.advisorsUsers[
                advisorIndex
              ].cfsSubmissionReviews =
                upsertSubmissionsToReviewByAdvisor?.submissionsToReview

              cache.writeQuery({
                query: callForSubmissionQuery,
                variables: cfsVariables,
                data: { ...cfsQueryData }
              })
            }
          }
        } catch (error) {
          console.log({
            upsertSubmissionsToReviewByAdvisor_update_error: error
          })
        }
      }
    })

    if (data && data?.upsertSubmissionsToReviewByAdvisor) {
      setToastMessage(t(`callsForSubmission:success:changesSaved`))
    }
    setHasChanged(false)
  }

  const onSelectAllElementsChange = selectAllElements => {
    setAssignAll(!!selectAllElements)
  }

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  return (
    <ScreenContainerWithMenuHeader screenCategory="settings">
      <ShadowContainerWithTabs>
        <SkeletonContainer
          isLoading={
            loadingAdvisor || !advisorInfo || isFetching || !callForSubmission
          }
          Skeleton={SubmissionSelectionSkeleton}
        >
          <HeaderNav
            callForSubmission={callForSubmission}
            goBack={() => {
              linkToScreen('AdvisingSettings', {
                cfsId: callForSubmission?.id
              })
            }}
          />
          <ScrollViewContent>
            <Title>
              {t('settings:advisors:advisor')}
              {': '}
              {advisorInfo?.userInfo?.person?.firstName}{' '}
              {advisorInfo?.userInfo?.person?.lastName}
            </Title>
            <TableContainer height="100%">
              <TableComponent
                {...tableProps}
                onFiltersChange={handleChangeFilters}
                onSelectedItemsKeysChange={handleSelectSubmissions}
                onSelectAllElementsChange={onSelectAllElementsChange}
                initialSelectedItems={submissionsToReview?.reduce(
                  (a, v) => ({ ...a, [v]: !!v }),
                  {}
                )}
                emptyMessage={t('curation:settings:noSUbmissionToAdvise')}
              />
            </TableContainer>
          </ScrollViewContent>
          <ActionContainer>
            <Button
              type="outline"
              onPress={() => {
                navigation.navigate('CurateCFSList' as any)
              }}
              title={t('curation:buttons:cancel')}
              containerStyle={{
                marginRight: space[3]
              }}
            />
            <Button
              type="solid"
              onPress={handleSaveSubmissionsToReview}
              title={t('curation:buttons:save')}
              disabled={!hasChanged}
              loading={isProcessing}
            />
          </ActionContainer>
        </SkeletonContainer>
      </ShadowContainerWithTabs>
    </ScreenContainerWithMenuHeader>
  )
}

export default SubmissionsSelection
