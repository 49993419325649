import React from 'react'

import { useTheme } from 'styled-components/native'
// Types
import {
  TableConfig,
  CellRendererProps,
  ColumnDefinition
} from '../../../../../components/Table'
import { TableNameEnum } from '../../../../../components/DynamicTable/types'
// Hooks
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Theme from '../../../../../constants/Theme'
import { Flex } from '../../../../../components/FlexBox'
import TouchableIcon, {
  TouchableIconProps
} from '../../../../../components/icon/TouchableIcon'
import { SmallRow } from '../../../../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../../../../components/Table/components/DynamicTable/SharedStyledComponents'
import { FormValues } from '../../types'
import { Content } from '../../../SharedStyledComponents'
// Types

// Styles
const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[2]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'center',
  textAlignVertical: 'center',
  width: '100%'
}
const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}
const cellTextStyle = { textAlign: 'center' }

// Columns
const formNameColumn: ColumnDefinition<any> = {
  id: 'formNameColumn',
  header: 'platformManagement:forms:menuItems:forms:name',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  fixed: true,
  width: 25,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item.name}
        style={cellTextStyle}
      />
    )
  }
}

const formTypeColumn: ColumnDefinition<any> = {
  id: 'formTypeColumn',
  header: 'platformManagement:forms:menuItems:forms:type',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 10,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${item?.type}` || ''}
        style={cellTextStyle}
      />
    )
  }
}

const formEditColumn = ({ handleEditForm }) => ({
  id: 'formEditColumn',
  header: 'platformManagement:forms:menuItems:forms:edit',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 10,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors, sizes } = useTheme()
    const commonProps: TouchableIconProps = {
      name: 'pencil',
      width: 22,
      height: 22,
      color: colors.darkGradientBackground,
      style: { alignSelf: 'center', marginHorizontal: sizes[1] }
    }

    return (
      <Flex flexDirection="row" justifyContent="center">
        <TouchableIcon
          {...commonProps}
          onPress={() => {
            handleEditForm(item)
          }}
        />
      </Flex>
    )
  }
})

export const FORM_MNG_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: TableNameEnum.FormsManagementTable,
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

interface getFormsManagementTableArgs {
  handleEditForm: (form: FormValues) => void
}

export const getFormsTableConfig = ({
  handleEditForm
}: getFormsManagementTableArgs) => {
  const table = Object.assign({}, FORM_MNG_TABLE_CONFIG) as TableConfig<any>

  let columns = [
    formNameColumn,
    formTypeColumn,
    formEditColumn({
      handleEditForm
    })
  ]
  table.columns = columns
  table.showTotalElements = true

  return table
}
