import gql from 'graphql-tag'
import useMutation, { MutationProps } from '../../../hooks/useMutation'

const sendAuthenticationCodeMutation = gql`
  mutation sendAuthenticationCodeEmail($email: String!) {
    sendAuthenticationCodeEmail(email: $email)
  }
`

const useSendAuthCode = (options: MutationProps) => {
  const [sendCode]: any[] = useMutation(sendAuthenticationCodeMutation, options)

  return sendCode
}

export default useSendAuthCode
