import React, { FC } from 'react'

import { Flex } from '../../../../../components/FlexBox'

import useDynamicForm from '../../../../../hooks/useDynamicForm'
import {
  FormAnswer,
  InnovationSubmission,
  OriginSourceEnum
} from '../../../../../types'
import { Container } from '../../../../../components/DynamicForm/layout'
import { FormikProvider } from 'formik'
import { FormSkeleton } from '../../../../Submission/components/skeletons'
import SkeletonContainer from '../../../../../components/skeletonLoadings/SkeletonContainer'

export interface ProductFormProps {
  submission: InnovationSubmission
  formAnswer?: FormAnswer
}

const Product: FC<ProductFormProps> = ({ formAnswer, submission }) => {
  const { form, component: dynamicForm, loadingFormData } = useDynamicForm({
    formId: submission.callForSubmission?.formId,
    formSubmissionId: formAnswer?.id,
    originSource: OriginSourceEnum.Innovation,
    isReadOnly: true,
    callForSubmission: submission?.callForSubmission
  })

  return (
    <Flex flexDirection="column" maxWidth="100%" width="100%">
      <SkeletonContainer isLoading={loadingFormData} Skeleton={FormSkeleton}>
        <FormikProvider value={form}>
          <Container>{dynamicForm}</Container>
        </FormikProvider>
      </SkeletonContainer>
    </Flex>
  )
}

export default Product
