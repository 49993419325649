import React, { FC } from 'react'
import { Text, View } from 'react-native'
import { CallForSubmissionStepEnum } from '../../../../types'
import { useTheme } from 'styled-components/native'
import { Flex } from '../../../../components/FlexBox'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

interface Step {
  stepName: string
  curationStep?: CallForSubmissionStepEnum
}

const WizardStep: FC<Step> = ({ stepName, curationStep }) => {
  const { colors, space } = useTheme()
  const circleSize = space[1] + space[2]
  const isSmallScreen = useIsSmallScreen()
  return (
    <Flex
      width={isSmallScreen ? `${space[3]}px` : `${space[3]}%`}
      maxWidth="150px"
      style={{
        marginRight: 0 // isSmallScreen ? 0 : 20
      }}
      flexDirection="row"
      aligItems="center"
    >
      <View
        style={{
          width: circleSize,
          height: circleSize,
          borderRadius: 100,
          backgroundColor: colors.blueBackground,
          marginTop: 2,
          marginRight: space[3] + space[1]
        }}
      />
      {curationStep !== CallForSubmissionStepEnum.event && (
        <View
          style={{
            width: 1,
            height: '100%',
            backgroundColor: colors.blueBackground,
            position: 'absolute',
            left: 5,
            top: 1
          }}
        />
      )}
      {!isSmallScreen && <Text>{stepName}</Text>}
    </Flex>
  )
}

export default WizardStep
