import gql from 'graphql-tag'

import useMutation from '../../../hooks/useMutation'

export const createInnovationSubmissionMutation = gql`
  mutation createInnovationSubmission(
    $callForSubmissionId: String!
    $submissionId: String
    $productName: String
  ) {
    createInnovationSubmission(
      callForSubmissionId: $callForSubmissionId
      submissionId: $submissionId
      productName: $productName
    ) {
      id
    }
  }
`

const useCreateInnovationSubmission = callForSubmissionId => {
  const [upsertInnovationSubmission, { loading }]: any[] = useMutation(
    createInnovationSubmissionMutation,
    {
      refetchQueries: ['callsForSubmission', 'innovationSubmissions'],
      awaitRefetchQueries: true
    }
  )

  const createInnovationSubmission = async (
    submissionId?: string,
    productName?: string
  ) => {
    const results = await upsertInnovationSubmission({
      variables: {
        submissionId,
        callForSubmissionId,
        productName
      }
    })

    return results?.data?.createInnovationSubmission
  }

  return [createInnovationSubmission, loading]
}

export default useCreateInnovationSubmission
