import gql from 'graphql-tag'

const InnovationSubmissionFieldsFragment = gql`
  fragment InnovationSubmissionFieldsFragment on InnovationSubmission {
    id
    callForSubmissionId
    pdfCloudinaryId
    innovationSubmissionStatus: status
    step
    hasBeenFastPassed
    hasBeenSubmitted
    passOrCutText
    callForSubmission {
      name
      currentStep {
        name
      }
    }
    company {
      name
      innovatorMembers {
        user {
          roles
        }
        emailAddresses {
          email
        }
      }
    }
    submissionInfo {
      name
      categoryLabel
      reasonForCut
      reasonForCutDescription
    }
  }
`

export default InnovationSubmissionFieldsFragment
