import React from 'react'
import styled from 'styled-components/native'
import { AirbnbRating } from 'react-native-elements'
import { useDimensions } from '@react-native-community/hooks'
// Components
import Label from '../TextInput/Label'
import { Flex } from '../../components/FlexBox'
import { Text } from '../../components/common/Text'

const SMALLER_SCREEN_BREAKPOINT = 800

export const Count = styled(Text)`
  ${({ theme: { colors, fontWeights } }) => `
    color: ${colors.text2};
    fontSize: 14px;
    fontWeight: ${fontWeights.light};
    paddingLeft: 3px;
`}
`

export interface RatingProps {
  containerStyle?: any
  label?: string
  helperText?: string
  isReadOnly?: boolean
  showCount?: boolean
  ratingCount?: number
  count?: number
  onFinishRating?: Function
  startSize?: number
  withHelperText?: boolean
}

const Rating = ({
  label,
  helperText,
  withHelperText = true,
  isReadOnly = false,
  showCount = false,
  ratingCount = 4,
  count = 0,
  onFinishRating,
  startSize,
  containerStyle = {
    marginBottom: 10
  }
}: RatingProps) => {
  const { height } = useDimensions().window

  const isSmallerScreen = height < SMALLER_SCREEN_BREAKPOINT

  const startSizeValue = startSize || (isSmallerScreen ? 26 : 30)

  return (
    <Flex style={containerStyle} alignItems="flex-start">
      {label ? (
        <Label
          label={label}
          helperText={helperText}
          labelStyles={{}}
          withHelperText={withHelperText}
        />
      ) : null}

      <Flex flexDirection="row" alignItems="center">
        <AirbnbRating
          count={ratingCount}
          defaultRating={count}
          size={startSizeValue}
          showRating={false}
          onFinishRating={value =>
            isReadOnly ? null : onFinishRating?.(value)
          }
          isDisabled={isReadOnly}
        />
        {showCount ? <Count>({count})</Count> : null}
      </Flex>
    </Flex>
  )
}

export default Rating
