// Hooks
import useFeatureFlag from './useFeatureFlag'
import useInnovationAccess from './useInnovationAccess'

const useIsPlatformManagementEnabled = () => {
  const innovationAccess = useInnovationAccess()
  const isInnovationPlatformManagementEnabled = useFeatureFlag(
    'innovationPlatformManagement'
  )
  return (
    isInnovationPlatformManagementEnabled &&
    innovationAccess?.platformManagement
  )
}

export default useIsPlatformManagementEnabled
