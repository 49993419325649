import React, { useEffect } from 'react'
import { Answer, Question } from '../../../types/form'
import { View } from 'react-native'
import useFileUploader from '../../../hooks/useFileUploader'
import useUpsertAnswerFileAttachmentMap from '../../../screens/Product/hooks/useUpsertAnswerFileAttachmentMap'
import FileDropzone, { DropZoneContent } from '../../common/FileDropzone'
import { pick } from 'ramda'
import useQuestionQuery from '../../../screens/Product/hooks/useQuestionQuery'
import Constants from 'expo-constants'
import useToast from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'
import styledWeb, { useTheme } from 'styled-components/native'

import { DynamicFileDropzoneProps, FileDropzoneDataProps } from './constants'
import FileListBlock from './DynamicFilesBlock'
import {
  ErrorContainer,
  ErrorText
} from '../../../screens/authentication/components/SharedStyledComponents'

const ACCEPTED_FILE_TYPES_PROD = ['.pdf']
// txt files are used for testing fake virus files in lower environments
const ACCEPTED_FILE_TYPES = ['.pdf', '.txt']

const DynamicFileDropzone = (props: DynamicFileDropzoneProps) => {
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()

  const { space } = useTheme()

  const { data, isReadOnly = false } = props
  const { key, questionId, formSubmissionId, styles, label, helperText } = data

  const [uploadAnswerFileAttachmentMap] = useUpsertAnswerFileAttachmentMap(
    questionId,
    formSubmissionId
  )
  const [uploadFile, progress, error, success] = useFileUploader(
    uploadAnswerFileAttachmentMap
  )

  const { currentAttachments, question } = useQuestionQuery(
    questionId,
    formSubmissionId
  )

  useEffect(() => {
    if (error !== null) {
      if (error === 'noFileSelected') {
        setToastErrorMessage(t('dropZone:error:noFileSelected'))
      } else {
        setToastErrorMessage(t('dropZone:error:fileFailedToUpload'))
      }
    }
    if (success !== null) {
      setToastMessage(t('dropZone:success:fileUploaded'))
    }
  }, [error, success])

  const withProgress = props => (
    <DropZoneContent
      {...props}
      progress={progress}
      isReadOnly={isReadOnly}
      label={label}
      helperText={helperText}
    />
  )

  const hasAttachments = !!currentAttachments?.length

  const FDZContainer = styledWeb(View)`
    ${styles?.containerStyles && styles?.containerStyles}
    width: 100%;
    margin-top: ${isReadOnly ? space[3] : 0}px;
    margin-bottom: 16px;
    `

  const testID = label === 'Additional Uploads' ? 'uploadDoc' : null

  return (
    <FDZContainer testID={testID}>
      {!isReadOnly && (
        <FileDropzone
          label={label}
          key={key}
          accept={
            Constants?.manifest?.extra?.BUILD_ENV === 'production'
              ? ACCEPTED_FILE_TYPES_PROD.join(',')
              : ACCEPTED_FILE_TYPES.join(',')
          }
          upload={uploadFile}
          Content={withProgress}
          style={{
            marginBottom: !hasAttachments ? space[4] - space[2] - space[1] : 0,
            marginTop: space[1] + space[2]
          }}
          isReadOnly={isReadOnly}
        />
      )}
      {error && error === 'maxSizeExceeded' && !isReadOnly && (
        <ErrorContainer>
          <ErrorText>{t('dropZone:error:maxSize')}</ErrorText>
        </ErrorContainer>
      )}
      {hasAttachments && (
        <FileListBlock
          answer={question?.answer as Answer}
          attachments={currentAttachments}
          isReadOnly={isReadOnly}
        />
      )}
    </FDZContainer>
  )
}

export const transformFileDropzoneData = (
  question: Question,
  formSubmissionId: string
): FileDropzoneDataProps => {
  return {
    key: `questions-${question?.id}`,
    label: question?.questionText,
    helperText: question?.configData?.helperText ?? null,
    questionId: question?.id,
    formSubmissionId,
    styles: pick(
      ['containerStyles', 'labelStyles', 'styles'],
      question?.style || {}
    )
  }
}

export default DynamicFileDropzone
