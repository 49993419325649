import React from 'react'
import { FlatList, TouchableOpacity, View, Text } from 'react-native'
import styled from 'styled-components/native'
import { Text as TText } from 'react-native-elements'

export const SelectAllElementsText = styled(Text)`
  ${({ isHighlighted, theme: { colors } }) =>
    isHighlighted ? `color: #3366CC` : `color: ${colors.tints.grays.g400}`};
  ${({ isBold }) => (isBold ? 'font-weight: bold;' : '')}
`

export const SelectAllElementsLinkContainer = styled(View)`
  flex-direction: row;
  cursor: pointer;
  white-space: nowrap;
`

export const SelectAllElementsView = styled(View)``

export const SelectAllElementsContainer = styled(View)`
  height: 30px;
  width: 100%;
  background-color: transparent;
  flex-direction: column;
  margin-left: 15px;
  white-space: nowrap;
  margin-bottom: 15px;
`

export const HeaderComponentsContainer = styled(View)``

export const InnerTableContainer = styled(View)`
  overflow-y: auto;
  height: 100%;
  flex-shrink: 1;
`

export const TableContainer = styled(View)`
  display: flex;
  width: 100%;
  height: max-content;
`

export const TableMessageText = styled(TText)``

export const FlatTableStyledContainer = styled(View)`
  flex-direction: row;
  display: flex;
  width: 100%;
  height: max-content;
`

export const FlatTableContainer = styled(View)`
  ${({ width }) => (width ? `width: ${width}%` : 'width: auto')};
  height: 100%;
`

export const StyledFlatList = styled(FlatList)`
  overflow-y: hidden;
`

export const ScrollViewContainer = styled(View)`
  ${({ width }) => (width ? `width: ${width}%` : '')};
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`

export const RowContainer = styled(View)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dullBlue};
  padding: 15px;
  ${({ leftTable }) => {
    return leftTable
      ? `
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      `
      : `
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      `
  }}
  ${({ isSticky }) => {
    return isSticky
      ? `
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    `
      : ``
  }}
  ${({ marginLeft, marginRight, marginBottom }) => `
  margin-left: ${marginLeft || 0}px;
  margin-right: ${marginRight || 0}px;
  margin-bottom: ${marginBottom || 0}px;
  `};
`

export const Cell = styled(View)`
  /*
    Width with 5px less because each cell has a right margin of 5px
  */
  ${({ width }) => (width ? `width: calc(${width}% - 5px)` : 'flex: 1')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px` : 'unset')};
  display: flex;
  margin: ${({ theme, cellStyle }) =>
    cellStyle?.margin || theme.isSmallScreen ? 0 : '0px 5px 3px 0;'}
  flex-direction: column;
  max-height: ${({ theme }) =>
    theme.sizes[4] + theme.sizes[2] + theme.sizes[1]}px;
  overflow-y: ${({ hasSubtitle }) => (hasSubtitle ? 'visible' : 'scroll')};
`

export const SortContainer = styled(View)`
  height: ${({ theme }) => theme.sizes[1] + theme.sizes[3]}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SortButton = styled(TouchableOpacity)`
  align-self: center;
  align-items: center;
`

export const HeaderText = styled(Text).attrs(props => ({
  style: {
    fontSize: `${props.theme.fontSizes[3]}px`,
    fontWeight: props.theme.fontWeights.light,
    opacity: props.theme.opacity.inputLabels,
    whiteSpace: 'nowrap',
    ...props.style
  }
}))``

export const SortHandle = styled(Text)`
  font-size: 13px;
  padding-left: 5px;
`

export const TitleView = styled(View)`
  width: 100%;
  height: '100%;
`

export const SubTitleView = styled(View)`
  width: 100%;
`

export const TableRowLarge = styled(View)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: #d9d9d9;
  padding: 15px;
  ${({ leftTable }) => {
    return leftTable
      ? `
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      `
      : `
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      `
  }}
  ${({ marginLeft, marginRight, marginBottom }) => `
  margin-left: ${marginLeft || 0}px;
  margin-right: ${marginRight || 0}px;
  margin-bottom: ${marginBottom || 0}px;
  `};
`

export const TableRowSmall = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
`

export const AlternatingTableRowContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.sizes[0]} ${({ theme }) => theme.sizes[3]}px;
  margin-bottom: ${({ theme }) => theme.sizes[3]}px;
  background-color: ${({ theme, isRowSelected }) =>
    isRowSelected
      ? theme.colors.primaryPalette.black
      : theme.colors.primaryPalette.white};
  height: ${({ theme, rowHeight }) => rowHeight ?? theme.sizes[5]}px;
  ${({ theme, leftTable }) => {
    return leftTable
      ? `
      border-top-left-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      border-bottom-left-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      `
      : `
      border-top-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      border-bottom-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      `
  }}
  ${({ isSticky, theme }) => {
    return isSticky
      ? `
      border-top-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      border-bottom-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
    `
      : ''
  }}
  ${({ theme, hover, isRowSelected }) =>
    hover && !isRowSelected
      ? `
    background-color: ${theme.colors.tableRowHover};
  `
      : ``}
  ${({ theme, isSelected }) =>
    isSelected
      ? `
      border-top-width: 1px;
      border-top-color: ${theme.colors.darkIcon};
      border-bottom-width: 1px;
      border-bottom-color: ${theme.colors.darkIcon};
      `
      : ``}
  ${({ theme, isSelected, leftTable }) =>
    isSelected
      ? leftTable
        ? `
          border-left-width: 1px;
          border-left-color: ${theme.colors.darkIcon};
        `
        : `
          border-right-width: 1px;
          border-right-color: ${theme.colors.darkIcon};
        `
      : ``}
`

export const AlternatingHoverableRow = ({ children, ...rest }: any) => {
  return (
    <AlternatingTableRowContainer {...rest}>
      {children}
    </AlternatingTableRowContainer>
  )
}

export const StyledDetailedViewContainer = styled(View)`
  margin-bottom: 10px;
  max-width: 100%;
  height: 60px;
  align-items: center;
  flex-direction: row;
  overflow-x: hidden;
  ${({ theme }) => `
    border-top-width: 2px;
    border-top-color: ${theme.colors.primaryPalette.white};
    border-bottom-width: 2px;
    border-bottom-color: ${theme.colors.primaryPalette.white};
  `};
  ${({ theme, leftTable }) =>
    leftTable
      ? `
      border-left-width: 2px;
      border-left-color: ${theme.colors.primaryPalette.white};
      border-top-left-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      border-bottom-left-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      `
      : ``}
  ${({ isSticky, theme, leftTable }) =>
    isSticky || !leftTable
      ? `
      border-right-width: 2px;
      border-right-color: ${theme.colors.primaryPalette.white};
      border-top-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      border-bottom-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
    `
      : ''}
`
