import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { intersection } from 'ramda'
import { ActivityIndicator, ScrollView } from 'react-native'
import { FormikProvider } from 'formik'
import styled, { useTheme } from 'styled-components/native'

import { useIsFocused, useRoute, useNavigation } from '@react-navigation/native'

import useDebounce from '../../../hooks/useDebounce'
import useToast from '../../../hooks/useToast'
import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useDynamicForm from '../../../hooks/useDynamicForm'
import useTranslation from '../../../hooks/useTranslation'
import useSubmissionQuery from '../hooks/useSubmissionQuery'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import useFormAnswerQuery from '../hooks/useFormAnswerQuery'
import useMultiselectMenuOpen from '../../../hooks/useMultiselectMenuOpen'

import { OriginSourceEnum, RoleEnum } from '../../../types'
import { Container } from '../../../components/DynamicForm/layout'
import { Flex } from '../../../components/FlexBox'
import ScreenContainer from '../../../components/layout/ScreenContainer'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import CompanySummary from './CompanySummary'
import SubmissionUpdated from './SubmissionUpdated'
import { Text } from '../../../components/common/Text'
import FormButtons from '../../../components/DynamicForm/FormButtons'
import PizzaTracker from '../../../components/PizzaTracker/PizzaTracker'
// Skeletons
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import { CompanySummarySkeleton, FormSkeleton } from './skeletons'
// Types
import { AnswerEntityTypeEnum } from '../../../types/form'

const StyledScrollView = styled(ScrollView)`
  width: 100%;
  background: ${props => props.theme.colors.appBackground};
  padding: ${props => props.theme.space[4]}px;
  border-radius: ${props => props.theme.radii[4]}px;
  box-shadow: #0000001a 0px 4px 5.65px;
`

const StyledFlex = styled(Flex).attrs({
  w: '100%',
  flex: 1,
  flexDirection: 'column'
})``

const hasRole = (roleToCheck, currentUser) =>
  intersection(currentUser?.roles, roleToCheck).length > 0

const SubmissionForm = () => {
  const route: any = useRoute()
  const navigation = useNavigation()
  const { t } = useTranslation()
  const { trackWithProperties } = useMixpanel()
  const { roles, currentUser } = useCurrentUser()
  const linkToScreen = useLinkToScreen()
  const isScreenFocused = useIsFocused()
  const isSmallScreen = useIsSmallScreen()
  const usePizzaTracker = useFeatureFlag('innovationPizzaTracker')
  const { colors, space } = useTheme()
  const { setToastErrorMessage } = useToast()
  const keepCountdown = useRef(false)
  const refetchCount = useRef(0)
  const submissionId = isScreenFocused ? route?.params?.id : null
  const {
    submission,
    loading: loadingSubmission,
    hasChanged,
    setHasChanged,
    submissionUpdatedBy,
    refetch: refetchSubmission
  } = useSubmissionQuery(submissionId, true)
  const { menuIsOpen } = useMultiselectMenuOpen()
  const debounceMenuIsOpen = useDebounce(menuIsOpen, 100)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(
    submission?.hasBeenSubmitted ?? false
  )

  const onClose = useCallback(() => {
    trackWithProperties(EVENT_MAP.click.button, {
      category: 'submission',
      action: 'close'
    })
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      linkToScreen(
        hasRole(
          [RoleEnum.Admin, RoleEnum.InnovationAdmin, RoleEnum.InnovationStaff],
          currentUser
        )
          ? 'CurateCFSList'
          : hasRole([RoleEnum.InnovationAdvisor], currentUser)
          ? 'SubmissionList'
          : 'Home'
      )
    }
  }, [navigation, roles, currentUser])

  useEffect(() => {
    if (hasBeenSubmitted !== submission?.hasBeenSubmitted) {
      setHasBeenSubmitted(submission?.hasBeenSubmitted)
    }
  }, [submission])

  const { formAnswer, loading: loadingFormAnswer } = useFormAnswerQuery(
    submissionId,
    AnswerEntityTypeEnum.InnovationSubmission
  )

  const formId = useMemo(() => submission?.callForSubmission?.formId, [
    submission
  ])

  const delegationId = useMemo(() => {
    if (submission?.callForSubmission?.delegations?.length) {
      return submission?.callForSubmission?.delegations[0].id
    }
  }, [submission?.callForSubmission?.delegations])

  const {
    form,
    refetchFormData,
    component: dynamicForm,
    helpers,
    loadingFormData
  } = useDynamicForm({
    formId,
    formSubmissionId: formAnswer?.id,
    originSource: OriginSourceEnum.Innovation,
    isReadOnly:
      !submission?.canEdit ||
      (submission?.hasBeenSubmitted ?? hasBeenSubmitted),
    withFormButtonsConfig: {
      // in the old form since the Product tab was shared we needed to differentiate the form we were using
      isSubmission: true
    },
    callForSubmission: submission?.callForSubmission
  })

  if (loadingSubmission || loadingFormAnswer) {
    return (
      <Flex minWidth="300px" width="100%" marginTop="auto">
        <ActivityIndicator style={{ alignItems: 'center' }} size="large" />
      </Flex>
    )
  }

  /**
   * When coundown clock stop, refetch innovation submission query to make pizza tracker and submission form
   * react to the new info(submission window is closed) in real time
   */
  const onCountdownStop = () => {
    // only refetch submission one time, since countdown to 0 only occurs one time
    if (refetchCount.current === 0) {
      // avoid subsequent calls of this function due to re-renders of the countdown clock
      refetchCount.current += 1
      // show toats message for a day = 86400000 miliseconds
      setToastErrorMessage(
        t('submissions:countdownClock:toastErrorMessage'),
        86400000
      )
      // wait time to refetch to make sure step end date has passed
      setTimeout(() => {
        refetchSubmission({
          id: submissionId,
          withSubmissionReviewsAverages: true
        })
      }, 1000)
    }
  }

  const refreshForm = async () => {
    form.resetForm()
    // @ts-ignore
    await refetchFormData()
    setHasChanged([false, ''])
  }

  /**
   * Calculate if we should show the countdown clock at the begining of the first render.
   * We are using a ref to store the visibility of the countdown clock because we
   * do refetch of the submission info when clock reaches 0,
   * and if clock reaches 0 it shoulwd remain visible until page is manually refreshed,
   * ref allow us to keep the visibility info between re-renders
   */
  if (!loadingSubmission && keepCountdown.current === false) {
    // check if current step exists and is submission step
    if (
      submission &&
      submission.callForSubmission?.currentStep?.name === 'submission' &&
      !submission?.hasBeenSubmitted // if submission is already submitted then hide countdown clock once is finished
    ) {
      keepCountdown.current = true
    }
  }

  return (
    <ScreenContainer
      header="menu-header"
      style={{
        paddingHorizontal: isSmallScreen ? 0 : `${space[5]}px`,
        paddingVertical: isSmallScreen ? 0 : `${space[4]}px`
      }}
    >
      <StyledFlex>
        <Flex
          flexDirection={isSmallScreen ? 'column' : 'row'}
          width="100%"
          justifyContent="center"
          flexBasis="100%"
          margin="0 auto"
        >
          <StyledScrollView
            style={{
              width: '100%',
              maxWidth: 1000,
              ...(isSmallScreen ? { borderRadius: 0 } : {})
            }}
          >
            {usePizzaTracker && (
              <PizzaTracker
                cfs={submission?.callForSubmission}
                submission={submission}
              />
            )}
            <SkeletonContainer
              isLoading={loadingFormData}
              Skeleton={FormSkeleton}
            >
              {!usePizzaTracker && submission?.callForSubmission?.name && (
                <Flex alignItems="flex-end">
                  <Text>{submission?.callForSubmission?.name}</Text>
                </Flex>
              )}
              <FormikProvider value={form}>
                <Container>{dynamicForm}</Container>
              </FormikProvider>
            </SkeletonContainer>
          </StyledScrollView>

          <Flex
            testID={'submissionControls'}
            padding={isSmallScreen ? `${space[3]}px` : `0 0 0 ${space[5]}px`}
            style={{
              ...(isSmallScreen
                ? {
                    backgroundColor: colors.appBackground
                  }
                : {})
            }}
          >
            <SkeletonContainer
              isLoading={loadingFormData}
              Skeleton={CompanySummarySkeleton}
            >
              {!isSmallScreen && (
                <>
                  <CompanySummary company={submission?.company} />
                  {hasChanged && (
                    <SubmissionUpdated
                      submissionUpdatedBy={submissionUpdatedBy}
                      onRefresh={refreshForm}
                    />
                  )}
                </>
              )}
              <FormButtons
                form={form}
                formHelpers={helpers}
                innovationSubmissionId={submissionId}
                delegationId={delegationId}
                isSubmission
                canEdit={submission?.canEdit}
                canSubmit={submission?.canSubmit}
                canDelete={submission?.canDelete}
                isSmallScreen={isSmallScreen}
                disableSubmitButton={debounceMenuIsOpen}
                hasBeenSubmitted={submission?.hasBeenSubmitted}
                onCountdownStop={onCountdownStop}
                onClose={onClose}
                keepCountdown={keepCountdown.current}
              />
            </SkeletonContainer>
          </Flex>
        </Flex>
      </StyledFlex>
    </ScreenContainer>
  )
}

export default SubmissionForm
