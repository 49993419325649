import React, { createContext, useEffect, useState } from 'react'
import usePropValue from '../../../../hooks/usePropValue'
import {
  CFSDashboard,
  CapabilityObject,
  CategoryObject,
  CountryObject,
  DashboardProps,
  DashboardProviderProps,
  FilterObject,
  FundingObject,
  LanguageObject,
  ReviewMetricsObject,
  ReviewObject,
  SubmissionsObject,
  TopicObject,
  UserBaseObject
} from '../types'
import { TopQuestionsEnum } from '../components/types'
import { reviewQuestionsSystemLabelMap } from '../constants'
import { CallForSubmissionStepEnum } from '../../../../types'
import { InnovationTagTypeEnum } from '../common/types'
import capitalize from '../../../../utils/capitalize'

const initialValues: DashboardProps = {
  loading: true,
  data: undefined
}

const extendedInitialValues = {
  ...initialValues,
  submissions: [] as SubmissionsObject[],
  categories: [] as CategoryObject[],
  capabilities: [] as CapabilityObject[],
  funding: [] as FundingObject[],
  userBase: [] as UserBaseObject[],
  countries: [] as CountryObject[],
  languages: [] as LanguageObject[],
  topics: [] as TopicObject[],
  reviewMetrics: {} as ReviewMetricsObject,
  handleFilters: (() => {}) as (filter: FilterObject) => void,
  filters: [] as FilterObject[],
  callForSubmission: {
    id: '',
    average: 0,
    categories: [],
    name: '',
    steps: [],
    submissions: [],
    advisors: []
  } as CFSDashboard // To have callForSubmission Object independent
}

const DashboardContext = createContext(extendedInitialValues)

function DashboardProvider({ props, children }: DashboardProviderProps): any {
  const loading = usePropValue(initialValues.loading, props.loading)
  const data = usePropValue(initialValues.data, props.data)
  const [submissions, setSubmissions] = useState<SubmissionsObject[]>([])
  const [categories, setCategories] = useState<CategoryObject[]>([])
  const [capabilities, setCapabilities] = useState<CapabilityObject[]>([])
  const [countries, setCountries] = useState<CountryObject[]>([])
  const [languages, setLanguages] = useState<LanguageObject[]>([])
  const [topics, setTopics] = useState<TopicObject[]>([])
  const [filters, setFilters] = useState<FilterObject[]>([])
  const [funding, setFunding] = useState<FundingObject[]>([])
  const [userBase, setUserBase] = useState<UserBaseObject[]>([])

  const [reviewMetrics, setReviewMetrics] = useState<ReviewMetricsObject>(
    {} as ReviewMetricsObject
  )

  const handleFilters = (newFilter: FilterObject) => {
    if (newFilter.filterType === 'SearchText') {
      setFilters([
        ...filters.filter(filter => filter.filterType !== 'SearchText'),
        newFilter
      ])
    } else if (newFilter.filterType === 'Status') {
      if (newFilter.value === 'Total Submissions') {
        setFilters([
          ...filters.filter(filter => filter.filterType !== 'Status')
        ])
      } else {
        const currentStatusFilter = filters.find(
          filter => filter.value === newFilter.value
        )
        if (currentStatusFilter) {
          setFilters([
            ...filters.filter(filter => filter.filterType !== 'Status')
          ])
        } else {
          setFilters([
            ...filters.filter(filter => filter.filterType !== 'Status'),
            newFilter
          ])
        }
      }
    } else if (['Academy', 'Favorite'].includes(newFilter.filterType)) {
      const currentFilter = filters.find(
        filter => filter.filterType === newFilter.filterType
      )
      if (currentFilter) {
        setFilters(
          filters.filter(filter => filter.filterType !== newFilter.filterType)
        )
      } else {
        setFilters([...filters, newFilter])
      }
    } else {
      const filtersByType = filters.filter(
        currentFilter => currentFilter.filterType === newFilter.filterType
      )
      if (
        filtersByType
          .map(currentFilter => currentFilter.value)
          .includes(newFilter.value)
      ) {
        const newFilters = filters.filter(
          currentFilter =>
            !(
              currentFilter.value === newFilter.value &&
              currentFilter.filterType === newFilter.filterType
            )
        )
        setFilters(newFilters)
      } else {
        setFilters([...filters, newFilter])
      }
    }
  }

  const callForSubmission = usePropValue(
    extendedInitialValues.callForSubmission,
    data?.callForSubmission
  ) as CFSDashboard

  // Set initial submissions values
  useEffect(() => {
    if (callForSubmission.submissions.length) {
      let filteredSubmissions = callForSubmission.submissions
      for (let indexFilter = 0; indexFilter < filters.length; indexFilter++) {
        const filter = filters[indexFilter]
        switch (filter.filterType) {
          case 'Review':
            filteredSubmissions = filteredSubmissions.filter(submission => {
              const submissionReviewReviews = [
                ...new Set(
                  Object.values(submission.reviews).reduce(
                    (acc, review) => [...acc, ...Object.keys(review)],
                    [] as string[]
                  )
                )
              ]
              return submissionReviewReviews.includes(filter.value)
            })
            break
          case InnovationTagTypeEnum.Clients:
          case InnovationTagTypeEnum.Clinical:
          case InnovationTagTypeEnum.Feedback:
          case InnovationTagTypeEnum.Financial:
          case InnovationTagTypeEnum.Geography:
          case InnovationTagTypeEnum.Summary:
          case InnovationTagTypeEnum.Technology:
            filteredSubmissions = filteredSubmissions.filter(
              submission =>
                submission.topics.filter(
                  topic =>
                    topic.name === filter.value &&
                    topic.type === filter.filterType
                ).length > 0
            )
            break
          case 'Academy':
            filteredSubmissions = filteredSubmissions.filter(
              submission =>
                submission.reviews[reviewQuestionsSystemLabelMap.academy]
            )
            break
          case 'Favorite':
            filteredSubmissions = filteredSubmissions.filter(
              submission =>
                submission.reviews[reviewQuestionsSystemLabelMap.favorites]
            )
            break
          case 'SearchText':
            filteredSubmissions = filteredSubmissions.filter(
              item =>
                Object.values(item)
                  .join(' ')
                  .toLowerCase()
                  .indexOf(String(filter.value).toLowerCase()) > -1
            )
            break

          case TopQuestionsEnum.Capability:
            filteredSubmissions = filteredSubmissions.filter(submission =>
              filter.value === '-'
                ? !submission.capability
                : submission.capability === filter.value
            )
            break

          case TopQuestionsEnum.Category:
            filteredSubmissions = filteredSubmissions.filter(
              submission => submission.category === filter.value
            )
            break

          case TopQuestionsEnum.Country:
            filteredSubmissions = filteredSubmissions.filter(submission =>
              (submission.countries ?? []).includes(filter.value)
            )
            break

          case TopQuestionsEnum.Language:
            filteredSubmissions = filteredSubmissions.filter(submission =>
              (submission.languages ?? []).includes(filter.value.toLowerCase())
            )
            break

          case 'Status':
            if (filter.value !== 'Total Submissions') {
              const steps = Object.values(CallForSubmissionStepEnum)
              const stepIndex = steps.findIndex(step => step === filter.value)
              filteredSubmissions = filteredSubmissions.filter(submission =>
                steps
                  .slice(stepIndex + 1, steps.length)
                  .includes(submission.step as CallForSubmissionStepEnum)
              )
            }
            break
          default:
            break
        }
      }
      setSubmissions(filteredSubmissions)
    }
  }, [callForSubmission.submissions, filters])

  const getUniquesAndCount = (
    array: number[]
  ): { name: string; count: number }[] => {
    let result: { name: string; count: number }[] = []
    const uniqueValues = new Set(array)
    uniqueValues.forEach(uniqueValue => {
      const count = array.filter(value => value === uniqueValue).length
      result.push({ name: `${uniqueValue}`, count })
    })
    return result
  }

  // Update Funding
  useEffect(() => {
    let fundingValues: FundingObject[] = []
    if (submissions.length) {
      const submissionsFunding = submissions.map(submission =>
        parseFloat(submission.funding ?? 0)
      )
      fundingValues = getUniquesAndCount(submissionsFunding)
    }
    setFunding(fundingValues)
  }, [submissions])

  // Update UserBase
  useEffect(() => {
    let userBaseValues: UserBaseObject[] = []
    if (submissions.length) {
      const submissionsUserBase = submissions.map(submission =>
        parseFloat(submission.userBase ?? 0)
      )
      userBaseValues = getUniquesAndCount(submissionsUserBase)
    }
    setUserBase(userBaseValues)
  }, [submissions])

  // Update Capabilitty
  useEffect(() => {
    let capabilities: CapabilityObject[] = []
    if (submissions.length) {
      const capabilityValidation = {}
      for (
        let indexSubmission = 0;
        indexSubmission < submissions.length;
        indexSubmission++
      ) {
        const submissionCpability =
          submissions[indexSubmission].capability ?? `-`
        if (!capabilityValidation[submissionCpability]) {
          capabilityValidation[submissionCpability] = 1
        } else {
          capabilityValidation[submissionCpability] += 1
        }
      }
      capabilities = Object.keys(capabilityValidation).map(capability => ({
        name: capability,
        count: capabilityValidation[capability]
      }))
    }
    setCapabilities(capabilities)
  }, [submissions])

  // Update Categories
  useEffect(() => {
    let categories: CategoryObject[] = []
    if (submissions.length) {
      const categoryValidation = {}
      for (
        let indexSubmission = 0;
        indexSubmission < submissions.length;
        indexSubmission++
      ) {
        const submissionCategory =
          submissions[indexSubmission].category ?? 'Other'
        if (!categoryValidation[submissionCategory]) {
          categoryValidation[submissionCategory] = 1
        } else {
          categoryValidation[submissionCategory] += 1
        }
      }
      categories = Object.keys(categoryValidation).map(category => ({
        name: category,
        count: categoryValidation[category]
      }))
    }
    setCategories(categories)
  }, [submissions])

  // Update Countries
  useEffect(() => {
    const countriesValidation = {}
    for (
      let indexSubmission = 0;
      indexSubmission < submissions.length;
      indexSubmission++
    ) {
      const submission = submissions[indexSubmission]
      const submissionCountries = submission.countries ?? []
      for (
        let indexCountry = 0;
        indexCountry < submissionCountries.length;
        indexCountry++
      ) {
        const country = submissionCountries[indexCountry]
        if (!countriesValidation[country]) {
          countriesValidation[country] = 1
        } else {
          countriesValidation[country] += 1
        }
      }
    }
    const resultCountries = Object.keys(countriesValidation)
      .map(country => ({ name: country, count: countriesValidation[country] }))
      .sort((a, b) => b.count - a.count)
    setCountries(resultCountries)
  }, [submissions])

  // Update Languages
  useEffect(() => {
    const languagesValidation = {}
    for (
      let indexSubmission = 0;
      indexSubmission < submissions.length;
      indexSubmission++
    ) {
      const submission = submissions[indexSubmission]
      const submissionLanguages = submission.languages ?? []
      for (
        let indexLanguages = 0;
        indexLanguages < submissionLanguages.length;
        indexLanguages++
      ) {
        const language = submissionLanguages[indexLanguages]
        if (language) {
          if (!languagesValidation[language]) {
            languagesValidation[language] = 1
          } else {
            languagesValidation[language] += 1
          }
        }
      }
    }
    const allLanguages = Object.keys(languagesValidation)
      .map(language => ({
        name: capitalize(language),
        count: languagesValidation[language]
      }))
      .sort((a, b) => b.count - a.count)
    setLanguages(allLanguages)
  }, [submissions])

  // Update Topics
  useEffect(() => {
    const topicsValidation = {}
    const allTopics = submissions.reduce(
      (acc, submission) => [...acc, ...submission.topics],
      [] as TopicObject[]
    )
    for (let indexTopic = 0; indexTopic < allTopics.length; indexTopic++) {
      const topic = allTopics[indexTopic]

      if (topicsValidation?.[topic.type]?.[topic.name]) {
        topicsValidation[topic.type][topic.name] += topic.frequency
      } else if (topicsValidation?.[topic.type]) {
        topicsValidation[topic.type][topic.name] = topic.frequency
      } else {
        topicsValidation[topic.type] = {
          [topic.name]: topic.frequency
        }
      }
    }
    const currentAllTopics = Object.keys(topicsValidation)
      .reduce((acc, topicType) => {
        const topicsByType = topicsValidation[topicType]
        const topics = Object.keys(topicsByType).map(topic => {
          const count = (topicsByType[topic] ?? 0) as number
          const currentTopic = {
            name: topic,
            count: submissions.filter(
              submission =>
                submission.topics.filter(
                  sTopic => sTopic.name === topic && sTopic.type === topicType
                ).length > 0
            ).length,
            factor:
              submissions.filter(
                submission =>
                  submission.topics.filter(
                    sTopic => sTopic.name === topic && sTopic.type === topicType
                  ).length > 0
              ).length * count,
            type: topicType as InnovationTagTypeEnum,
            frequency: count
          }
          return currentTopic
        })
        return [...acc, ...topics]
      }, [] as TopicObject[])
      .sort((a, b) => b.count - a.count)

    setTopics(currentAllTopics)
  }, [submissions])

  // Update Review Metrics
  useEffect(() => {
    const getScoreByType = (type: string, review: ReviewObject): number => {
      let result = 0
      const reviewByType = review[type] ?? {}

      if (Object.keys(reviewByType).length > 0) {
        const reviewValues = Object.values(reviewByType)
        result = calculateAverage(reviewValues)
      }

      return result
    }

    let metricsResult: { [metric: string]: number[] } = {}

    const addItemToMetric = (metric: string, value: number) => {
      const currentValues = metricsResult[metric] ?? []
      metricsResult[metric] = [...currentValues, value]
    }

    const calculateAverage = (array: number[]): number => {
      const total = array.reduce((acc, curr) => acc + curr, 0)
      const average = total / array.length
      return average
    }

    let favorites: number = 0
    let academy: number = 0

    let resultMetricsObject: ReviewMetricsObject = {
      wass: 0,
      marketFit: 0,
      ii: 0,
      fundamentals: 0,
      traction: 0,
      submission: 0,
      favorites,
      academy
    }
    if (submissions.length) {
      for (
        let indexSubmission = 0;
        indexSubmission < submissions.length;
        indexSubmission++
      ) {
        const submission = submissions[indexSubmission]
        const reviews = submission.reviews

        if (reviews) {
          addItemToMetric(
            'marketFit',
            getScoreByType(reviewQuestionsSystemLabelMap.marketFit, reviews)
          )
          addItemToMetric(
            'ii',
            getScoreByType(reviewQuestionsSystemLabelMap.ii, reviews)
          )
          addItemToMetric(
            'fundamentals',
            getScoreByType(reviewQuestionsSystemLabelMap.fundamentals, reviews)
          )
          addItemToMetric(
            'traction',
            getScoreByType(reviewQuestionsSystemLabelMap.traction, reviews)
          )
          addItemToMetric(
            'submission',
            getScoreByType(reviewQuestionsSystemLabelMap.submission, reviews)
          )
          if (reviews[reviewQuestionsSystemLabelMap.academy]) {
            academy = academy + 1
          }
          if (reviews[reviewQuestionsSystemLabelMap.favorites]) {
            favorites = favorites + 1
          }
        }
      }

      resultMetricsObject = {
        wass: calculateAverage(submissions.map(submission => submission.wass)),
        marketFit: calculateAverage(metricsResult['marketFit'] ?? []),
        ii: calculateAverage(metricsResult['ii'] ?? []),
        fundamentals: calculateAverage(metricsResult['fundamentals'] ?? []),
        traction: calculateAverage(metricsResult['traction'] ?? []),
        submission: calculateAverage(metricsResult['submission'] ?? []),
        favorites,
        academy
      }
    }
    setReviewMetrics(resultMetricsObject)
  }, [submissions])

  const contextValues = {
    loading,
    data,
    callForSubmission,
    submissions,
    categories,
    capabilities,
    countries,
    languages,
    handleFilters,
    filters,
    reviewMetrics,
    funding,
    userBase,
    topics
  }
  return (
    <DashboardContext.Provider value={contextValues}>
      {children}
    </DashboardContext.Provider>
  )
}

export { DashboardProvider, DashboardContext }
