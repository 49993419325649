import { atomFamily } from 'recoil'
import { AnswerFileAttachmentMap } from '../types'
import { Answer, Question } from '../types/form'

export interface QuestionState {
  currentQuestion?: Question
  currentAttachments?: AnswerFileAttachmentMap[]
  answers?: Answer[]
}

const currentQuestionAtom = atomFamily<QuestionState, string>({
  key: 'currentQuestionState',
  default: {}
})

export default currentQuestionAtom
