import gql from 'graphql-tag'
// Hooks
import useQuery from '../../../hooks/useQuery'
import useTranslation from '../../../hooks/useTranslation'
// Types
import { InnovationCategory } from '../../../types'

const innovationCategoriesQuery = gql`
  query innovationCategories(
    $marketSegmentIds: [String]
    $excludeOther: Boolean
  ) {
    innovationCategories(
      marketSegmentIds: $marketSegmentIds
      excludeOther: $excludeOther
    ) {
      id
      name
      isActive
      submissionsCount
      marketSegments {
        id
        name
      }
    }
  }
`

interface InnovationCategoriesResult {
  loading?: boolean
  innovationCategories: InnovationCategory[]
}

const useGetInnovationCategories = (
  marketSegmentIds = [],
  options = {},
  excludeOther = false
): InnovationCategoriesResult => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(innovationCategoriesQuery, {
    variables: { marketSegmentIds, excludeOther },
    errorMessage: t('error:general:categories:error'),
    fetchPolicy: 'cache-and-network',
    ...options
  })

  return {
    innovationCategories: data?.innovationCategories,
    loading
  }
}

export default useGetInnovationCategories
