import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Platform } from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TextInput, { TextInputProps } from '../../ui-library/TextInput'
import useTranslation from '../../hooks/useTranslation'
import useValidateEmailMutation from './mutations/useValidateEmailMutation'
import {
  AuthenticationErrorContainer,
  ErrorText
} from './components/SharedStyledComponents'
import { RegisterCheckSubStateProps } from '../../AppCoordinator/RegisterCheck'
import useLastAttemptedEmail from '../../hooks/useLastAttemptedEmail'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import { isRouteSearchParam } from './LoginScreen/hooks/useRedirectLoginByQueryParams'
import useSignupUser from './mutations/useSignupUser'
import { INPUT_MAX_LENGTH_SM, EHIR_SUPPORT_EMAIL } from './constants'
import { Flex } from '../../components/FlexBox'
import RowLayout from './components/layout/RowLayout'
import changeText from '../../utils/changeFormikText'
import PageHeader from './components/sharedComponents/PageHeader'
import CallToActionBtns from './components/sharedComponents/CallToActionBtns'
import { useUnclaimableDomainsQuery } from './queries/useRegistrationDataQuery'
// Types
import { ALLOWED_LOGIN_ROUTES } from '../../types/form'

const initialValues = {
  firstName: '',
  lastName: '',
  email: ''
}

const VerifyEmailRegisterScreen = ({ send }: RegisterCheckSubStateProps) => {
  const {
    lastAttemptedEmail,
    updateLastAttemptedEmail
  } = useLastAttemptedEmail()
  const {
    data,
    loading: loadingRegistrationData
  } = useUnclaimableDomainsQuery()

  const isSmallScreen = useIsSmallScreen()

  const [schema, setSchema] = useState(
    Yup.object().shape({
      firstName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
      lastName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
      email: Yup.string().required('Required')
    })
  )

  useEffect(() => {
    if (data?.unclaimableEmailDomains.length > 0) {
      const prohibitsDomains = data?.unclaimableEmailDomains.map(d => d.domain)

      Yup.addMethod(Yup.string, 'domainCheck', function (errorMessage) {
        return this.test(`domainCheck`, errorMessage, function (value) {
          const { path, createError } = this

          const hasProhibitDomain = prohibitsDomains?.find(domain =>
            value?.includes(domain)
          )
          if (hasProhibitDomain) {
            return createError({ path, message: errorMessage })
          }
          return true
        })
      })

      setSchema(
        Yup.object().shape({
          firstName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
          lastName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
          email: Yup.string()
            .required('Required')
            // @ts-ignore
            .domainCheck('Your email domain is not claimable')
        })
      )
    }
  }, [data?.unclaimableEmailDomains])

  const { t } = useTranslation()
  const {
    values,
    errors,
    setFieldError,
    setFieldValue,
    handleBlur,
    validateForm,
    submitForm,
    touched,
    dirty,
    isValid,
    isValidating,
    isSubmitting,
    setSubmitting
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async () => {
      if (isEmailDomainClaimed) {
        await updateLastAttemptedEmail(values.email)
        signupUser()
        setSubmitting(isSubmitting)
      } else {
        validateEmail()
      }
    },
    enableReinitialize: true
  })

  const [companyId, setCompanyId] = useState('')

  useEffect(() => {
    if (lastAttemptedEmail) {
      changeText('email', lastAttemptedEmail, setFieldValue)
    }
  }, [lastAttemptedEmail])

  const [signupUser, signupLoading] = useSignupUser({
    variables: {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: uuidv4(),
      innovationEnabled: true
    },
    onCompleted: data => {
      if (data?.signup) {
        return send({
          type: 'EMAIL_VERIFIED',
          payload: {
            registerUserToCompany: isEmailDomainClaimed,
            companyId: companyId
          }
        })
      }
    }
  })

  const [isEmailDomainClaimed, setEmailDomainClaimed] = useState(false)

  const [validateEmail, loading] = useValidateEmailMutation({
    variables: { email: values.email },
    onCompleted: async results => {
      const {
        userExists,
        isDomainBlacklisted,
        isEmailDomainClaimed,
        isEmailDomainRegistered,
        companyId
      } = results?.validateEmail

      setCompanyId(companyId)

      if (
        userExists ||
        isDomainBlacklisted ||
        isEmailDomainRegistered ||
        isEmailDomainClaimed
      ) {
        setSubmitting(false)
      }

      if (userExists) {
        return setFieldError('email', t('error:auth:knownUsername'))
      }

      if (isDomainBlacklisted) {
        return setFieldError('email', t('error:auth:invalidEmailDomain'))
      }

      setEmailDomainClaimed(isEmailDomainClaimed)

      if (isEmailDomainClaimed) {
        return setFieldError(
          'email',
          t('error:auth:emailDomainAlreadyRegistered', {
            supportEmail: EHIR_SUPPORT_EMAIL
          })
        )
      }

      if (values.email !== lastAttemptedEmail) {
        await updateLastAttemptedEmail(values.email)
      }

      return signupUser()
    }
  })

  const validateAndSubmit = () => {
    validateForm().then(() => submitForm())
  }

  if (loadingRegistrationData) {
    return (
      <RowLayout>
        <ActivityIndicator size="large" />
      </RowLayout>
    )
  }

  const disableInputs: boolean = isValidating || isSubmitting || loading
  const commonTextInputProps: TextInputProps = {
    autoCapitalize: 'none',
    editable: !disableInputs
  }

  return (
    <RowLayout minHeight={500}>
      <PageHeader
        title={t('auth:verifyEmail:header')}
        description={t('auth:verifyEmail:description')}
        marginTop={isSmallScreen ? 50 : 0}
      />
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
      >
        <TextInput
          testID={'regFirstName'}
          value={values.firstName}
          onChangeText={value => changeText('firstName', value, setFieldValue)}
          onBlur={handleBlur('firstName')}
          label={t('auth:forms:labels:firstName')}
          hasError={!!errors.firstName && !!touched.firstName}
          maxLength={INPUT_MAX_LENGTH_SM}
          autoFocus
          style={{ width: '100%' }}
          containerStyles={{ flexBasis: '35%' }}
          {...commonTextInputProps}
        />
        <TextInput
          testID={'regLastName'}
          value={values.lastName}
          onChangeText={value => changeText('lastName', value, setFieldValue)}
          onBlur={handleBlur('lastName')}
          hasError={!!errors.lastName && !!touched.lastName}
          label={t('auth:forms:labels:lastName')}
          maxLength={INPUT_MAX_LENGTH_SM}
          style={{ width: '100%' }}
          containerStyles={{ flexBasis: '64%', paddingLeft: '10px' }}
          {...commonTextInputProps}
        />
      </Flex>
      <TextInput
        testID={'regEmail'}
        value={values.email}
        onChangeText={value => changeText('email', value, setFieldValue)}
        onBlur={handleBlur('email')}
        label={t('auth:forms:email')}
        placeholder={t('')}
        style={{ minWidth: '100%' }}
        containerStyles={{ marginTop: 7 }}
        {...commonTextInputProps}
      />
      {!!errors.email && (
        <AuthenticationErrorContainer>
          <ErrorText>{errors.email}</ErrorText>
        </AuthenticationErrorContainer>
      )}

      {isEmailDomainClaimed ? (
        <CallToActionBtns
          primaryBtn={{
            testID: 'addToCompanyProfile',
            title: t('auth:buttons:addToCompanyProfile'),
            isProcessing: loading || signupLoading,
            onPress: submitForm,
            disabled: !dirty || disableInputs
          }}
          secondaryBtn={{
            testID: 'regCancel',
            title: t('common:buttons:cancel'),
            disabled: disableInputs,
            onPress: () => {
              if (Platform.OS === 'web') {
                if (isRouteSearchParam(ALLOWED_LOGIN_ROUTES.REGISTER)) {
                  document.location.replace(window.location.pathname)
                } else {
                  document.location.reload()
                }
              }
            }
          }}
        />
      ) : (
        <CallToActionBtns
          primaryBtn={{
            testID: 'regVerify',
            title: t('auth:buttons:verifyEmail'),
            isProcessing: loading || signupLoading,
            onPress: validateAndSubmit,
            disabled: !dirty || !isValid || disableInputs
          }}
          secondaryBtn={{
            testID: 'regCancel',
            title: t('common:buttons:cancel'),
            disabled: disableInputs,
            onPress: () => {
              if (Platform.OS === 'web') {
                if (isRouteSearchParam(ALLOWED_LOGIN_ROUTES.REGISTER)) {
                  document.location.replace(window.location.pathname)
                } else {
                  document.location.reload()
                }
              }
            }
          }}
        />
      )}
    </RowLayout>
  )
}

export default VerifyEmailRegisterScreen
