import gql from 'graphql-tag'
import useQuery from '../../../hooks/useQuery'

const legalDocuments = gql`
  query legalAgreement($type: LegalDocumentType!) {
    legalAgreement: latestLegalDocument(type: $type) {
      id
      title
      body
    }
  }
`

interface LegalDocuments {
  title: string | undefined
  body: string
}

interface LegalDocumentObject {
  legalDocuments: LegalDocuments
  loading: boolean
}

const LEGAL_DOCUMENT_PREFIX = 'innovation'
const capitalize = s => s[0].toUpperCase() + s.slice(1)

const useLegalDocumentsQuery = (type): LegalDocumentObject => {
  const innovationType = `${LEGAL_DOCUMENT_PREFIX}${capitalize(type)}`

  const { data, loading } = useQuery(legalDocuments, {
    variables: { type: innovationType }
  })

  return {
    legalDocuments: data?.legalAgreement,
    loading
  }
}

export default useLegalDocumentsQuery
