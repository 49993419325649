import { useRecoilState } from 'recoil'
import { multiselectMenuAtomFamily } from '../recoil/multiselectMenuAtom'

const useMultiselectMenuOpen = () => {
  const [currentState, setState] = useRecoilState(multiselectMenuAtomFamily)

  const setMenuOpened = (menuIsOpen: boolean) => {
    setState({ menuIsOpen })
  }

  return {
    setMenuOpened,
    menuIsOpen: currentState?.menuIsOpen
  }
}

export default useMultiselectMenuOpen
