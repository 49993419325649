import React, { useState, useCallback, createRef } from 'react'
import { View, Text } from 'react-native'
import DatePicker from 'react-datepicker'
import styled from 'styled-components/native'
import 'react-datepicker/dist/react-datepicker.css'
import DateService from '../../services/dateService'

import useTranslation from '../../hooks/useTranslation'
import TouchableIcon from '../../components/icon/TouchableIcon'

import {
  ErrorContainer,
  ErrorText
} from '../../screens/authentication/components/SharedStyledComponents'
import './styleOverride.css'
import { StyledLabel } from '../TextInput/Label'
import { FieldMetaProps } from 'formik'
import { FormikDateInputProps } from '.'
import { propsToFilter } from '../../utils/NonReactNativeProp'
import { Flex } from '../../components/FlexBox'

// TODO: This is functional for our purposes, but it really needs some work

export interface DateInputProps extends FormikDateInputProps {
  value: any
  hasError?: boolean
  errorMessage?: string
  meta?: FieldMetaProps<any>
  handleChange?: (value: any) => void
  isDateIconVisible?: boolean
  dateIconColor?: string
  isClosableIconVisible?: boolean
  parentContainerStyles?: any
  withTime?: boolean
}

const DateIcon = styled(TouchableIcon)
  .withConfig({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !propsToFilter.includes(prop)
  })
  .attrs(props => ({
    name: 'newCalendar',
    color: props.dateIconColor || props.theme.colors.text2,
    width: props.theme.fontSizes[7],
    height: props.theme.fontSizes[7]
  }))`
  margin-right: ${props => props.theme.space[2]}px;
  margin-left: ${props => props.theme.space[1]}px;
`

const StyledContainer = styled(View)`
  ${({ theme, hasError, style }) => `
        backgroundColor: ${
          style?.backgroundColor
            ? style.backgroundColor
            : theme.colors.inputBackground
        };
        border: 1px solid ${
          hasError ? theme.colors.inputBorderError : theme.colors.inputBorder
        };
        border-radius: ${theme.radii[3]}px;
        margin-bottom: 8px;
        height: 40px;
        position: relative;
        width: ${style?.width ? style.width : '100%'};
        box-sizing: border-box;
        padding:  0 7px;

        display: flex;
        flex-direction: row;
        align-items: center;
        
        justify-content: space-between;
        .react-datepicker-wrapper {
          flex: 1;
        }

        input { 
          width: 100%;
          height: 32px;
          font-family:
            system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
        }
        input[type="time"] { flex: 1; }
        input[type="time"]::-webkit-calendar-picker-indicator {
          background: none;
      }
    `}
`

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: 12px;
  right: 4px;
`

const TimezoneText = styled(Text)`
  ${({ theme, isReadOnly }) => `
        padding-left: ${theme.space[2]}px;
        font-size: 13px;
        color: ${isReadOnly ? theme.colors.tints.grays.g300 : theme.colors.text}
    `}
`

export default function DateInput(props: DateInputProps) {
  const { t } = useTranslation()

  const calendarRef = createRef()
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const onCalendarOpen = useCallback(() => setIsCalendarOpen(true), [])
  const onCalendarClose = useCallback(() => setIsCalendarOpen(false), [])

  const {
    meta,
    value,
    label,
    initialDate,
    maximumDate,
    minimumDate,
    containerStyles,
    parentContainerStyles = {},
    hasError = false,
    isReadOnly = false,
    errorMessage,
    isDateIconVisible = true,
    dateIconColor,
    isClosableIconVisible = true,
    onBlur,
    handleChange,
    withTime = false
  } = props

  const tz = value ? DateService.getTimeZone(value) : null

  return (
    <View
      style={{
        ...parentContainerStyles
      }}
    >
      {label && <StyledLabel>{label}</StyledLabel>}

      <StyledContainer
        hasError={hasError}
        style={{
          ...{
            zIndex: isCalendarOpen ? 2 : 0,
            paddingRight: value ? 17 : 0
          },
          ...containerStyles
        }}
      >
        {withTime ? (
          <Flex justifyContent="right" flexDirection="row" alignItems="center">
            <DatePicker
              ref={calendarRef}
              readOnly={isReadOnly}
              portalId="root-portal"
              selected={value}
              onChange={handleChange}
              onBlur={onBlur}
              onClickOutside={onBlur}
              maxDate={maximumDate}
              minDate={minimumDate}
              onCalendarOpen={onCalendarOpen}
              onCalendarClose={onCalendarClose}
              openToDate={initialDate}
              disabledKeyboardNavigation
              disabled={isReadOnly}
              showTimeSelect
              timeFormat="h:mm aa"
              dateFormat="MM/dd/yyyy, h:mm a"
              locale="en-US"
              timeIntervals={15}
              timeCaption="time"
              className="ehir-date-input"
              wrapperClassName="ehir-date-wrapper"
            />
            {tz && <TimezoneText isReadOnly={isReadOnly}>{tz}</TimezoneText>}
          </Flex>
        ) : (
          <DatePicker
            ref={calendarRef}
            readOnly={isReadOnly}
            portalId="root-portal"
            selected={value}
            onChange={handleChange}
            onBlur={onBlur}
            onClickOutside={onBlur}
            maxDate={maximumDate}
            minDate={minimumDate}
            onCalendarOpen={onCalendarOpen}
            onCalendarClose={onCalendarClose}
            openToDate={initialDate}
            disabledKeyboardNavigation
            disabled={isReadOnly}
          />
        )}
        {isDateIconVisible && (
          <DateIcon
            dateIconColor={dateIconColor}
            onPress={() => {
              if (!isReadOnly && calendarRef.current) {
                // @ts-ignore
                calendarRef.current.setOpen(true)
                setIsCalendarOpen(true)
              }
            }}
          />
        )}
        {!!value && isClosableIconVisible && (
          <CloseIcon
            onPress={handleChange ? () => handleChange('') : undefined}
          />
        )}
      </StyledContainer>
      {hasError && !isReadOnly && meta?.error ? (
        <ErrorContainer>
          <ErrorText>{t(meta.error, { length })}</ErrorText>
        </ErrorContainer>
      ) : (
        errorMessage && (
          <ErrorContainer>
            <ErrorText>{errorMessage}</ErrorText>
          </ErrorContainer>
        )
      )}
    </View>
  )
}
