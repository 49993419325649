import React from 'react'

import styled, { useTheme } from 'styled-components/native'
// Types
import {
  TableConfig,
  CellRendererProps,
  ColumnDefinition
} from '../../components/Table'
// Hooks
import useTranslation from '../../hooks/useTranslation'
// Components
import Theme from '../../constants/Theme'
import { Flex } from '../../components/FlexBox'
import TouchableIcon, {
  TouchableIconProps
} from '../../components/icon/TouchableIcon'
import { SmallRow } from '../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../components/Table/components/DynamicTable/SharedStyledComponents'
import {
  SubtitleCell,
  LabelAndTitleCell
} from '../../components/DynamicTable/components/Table/Item/common/TitleCellContent'
// Types
import { MarketSegment } from '../../types'
import { StyledButtonUI } from '../../components/DynamicTable/components/SearchBar/SharedStyledComponents'
import { H4 } from '../../components/common/Text'

export const ModalContainer = styled.View`
  flex-direction: column;
  ${({ theme: { space } }) => `
    padding-vertical: ${space[1]}px;
  `}
`

export const Title = styled(H4).attrs(({ theme: { fontWeights } }) => ({
  h4Style: {
    fontWeight: fontWeights.medium
  }
}))``

export const CancelButton = styled(StyledButtonUI).attrs(
  ({ theme: { sizes } }) => ({
    type: 'outline',
    buttonStyle: {
      width: sizes[6]
    }
  })
)`
  ${({ theme: { sizes } }) => `
    margin-right: ${sizes[2]}px;
  `}
`
export const SaveButton = styled(StyledButtonUI).attrs(
  ({ isDelete, theme: { sizes, colors } }) => ({
    buttonStyle: {
      width: sizes[6],
      backgroundColor: isDelete ? colors.dangerButton : colors.buttonBackground
    }
  })
)`
  ${({ isDelete, theme: { sizes } }) => `
    margin-right: ${isDelete ? 0 : sizes[2]}px;
  `}
`

export const Footer = styled.View`
  flex-direction: row;
  ${({ theme: { space } }) => `
    margin-top: ${space[3]}px;
  `}
`

export const FooterLegend = styled.View`
  flex-direction: col;
  max-width: 400px;
  ${({ theme: { space } }) => `
    margin-top: ${space[3]}px;
  `}
`

export const InputContainer = styled.View`
  ${({ theme: { space } }) => `
    margin-top: ${space[2]}px;
  `}
`

export const CreateIcon = styled(StyledButtonUI).attrs(
  ({ theme: { sizes } }) => ({
    buttonStyle: {
      width: sizes[6] + sizes[5]
    }
  })
)``

// Styles
const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[2]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'left',
  textAlignVertical: 'center',
  width: '100%'
}
const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}
const cellTextStyle = { textAlign: 'left' }

// Components
const Content = ({ isSmallScreen, label, title, style = {} }) => {
  return isSmallScreen ? (
    <LabelAndTitleCell label={label} title={title} style={style} />
  ) : (
    <SubtitleCell text={title} style={style} />
  )
}

// Columns
const categoryNameColumn: ColumnDefinition<any> = {
  id: 'categoryNameColumn',
  header: 'platformManagement:categories:columnName',
  headerStyle: { ...headerStyle, textAlign: 'left' },
  width: 5,
  emptyMessage: '',
  fixed: true,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item?.name || ''}
        style={cellTextStyle}
      />
    )
  }
}

const activeInactiveColumn: ColumnDefinition<any> = {
  id: 'activeInactiveColumn',
  header: 'platformManagement:categories:activeHeaderName',
  headerStyle: { ...headerStyle, textAlign: 'left' },
  width: 2,
  emptyMessage: '',
  fixed: true,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const title = item.isActive ? 'Active' : 'Inactive'
    const color = item.isActive ? Theme.colors.accent : Theme.colors.inactive
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={title}
        style={{ ...cellTextStyle, color }}
      />
    )
  }
}

const categoryMarketSegmentsColumn: ColumnDefinition<any> = {
  id: 'categoryMarketSegmentsColumn',
  header: 'product:general:marketSegments:title',
  headerStyle: { ...headerStyle },
  width: 5,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    const title = item?.marketSegments?.length
      ? item.marketSegments
          .map((ms: MarketSegment) => ms?.name || '')
          .join(', ')
      : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={title}
        style={cellTextStyle}
      />
    )
  }
}

const categoryEditColumn = ({ onEditCategory }) => ({
  id: 'categoryEditDeleteColumn',
  header: 'platformManagement:categories:edit',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 1,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors, sizes } = useTheme()
    const commonProps: TouchableIconProps = {
      name: 'pencil',
      width: 22,
      height: 22,
      color: colors.darkGradientBackground,
      style: { alignSelf: 'center', marginHorizontal: sizes[1] }
    }

    return (
      <Flex flexDirection="row" justifyContent="center">
        <TouchableIcon
          {...commonProps}
          onPress={() => {
            onEditCategory(item)
          }}
        />
      </Flex>
    )
  }
})

export const CATEGORIES_MNG_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: 'CategoriesManagementTableConfig',
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

interface getCategoriesManagementTableArgs {
  onEditCategory: (category: any) => void
}

export const getCategoriesManagementTableConfig = ({
  onEditCategory
}: getCategoriesManagementTableArgs) => {
  const table = Object.assign(
    {},
    CATEGORIES_MNG_TABLE_CONFIG
  ) as TableConfig<any>
  table.name = 'categories-management-table'

  let columns = [
    categoryNameColumn,
    categoryMarketSegmentsColumn,
    activeInactiveColumn,
    categoryEditColumn({
      onEditCategory
    })
  ]
  table.columns = columns
  table.isSticky = true
  table.showTotalElements = true

  return table
}
