import React from 'react'

import styled from 'styled-components/native'
import {
  useRoute,
  getFocusedRouteNameFromRoute
} from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
// Hooks
import useTranslation from '../../hooks/useTranslation'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import useIsFormManagementEnabled from '../../hooks/useIsFormManagementEnabled'
// Component
import { Title } from '../CallForSubmission/components/SharedComponents'
import {
  ShadowContainerWithTabs,
  ScreenContainerWithMenuHeader
} from '../../components/layout/ScreenContainer'
import TopTabBar from '../../components/layout/TopTabBar'
import TopTabBarMobile from '../../components/layout/TopTabBarMobile'
// Tab screens
import FormTab from './tabs/FormTab'
import FormBlockTab from './tabs/FormBlockTab'
import QuestionBlockTab from './tabs/QuestionBlockTab'

const TAB_WIDTH = 256

// Styled Components
const HeaderNavContainer = styled.View`
  width: 100%;
  ${({ theme: { space } }) => `
    padding-top: ${space[3]}px;
    padding-horizontal: ${space[3]}px;
  `}
`

// Tabs
const Tabs = createMaterialTopTabNavigator()
const formManagementTabs = [
  {
    name: 'Forms',
    Component: FormTab,
    options: {
      title: `Forms`,
      webTitle: 'Forms',
      disabled: false
    }
  },
  {
    name: 'FormBlocks',
    Component: FormBlockTab,
    options: {
      title: `Form Blocks`,
      webTitle: 'Form Blocks',
      disabled: false
    }
  },
  {
    name: 'QuestionBlocks',
    Component: QuestionBlockTab,
    options: {
      title: `Question Blocks`,
      webTitle: 'Question Blocks',
      disabled: false
    }
  }
]

const FormManagementTabs = () => {
  const route: any = useRoute()
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const currentRouteName = getFocusedRouteNameFromRoute(route)

  const isFormManagementEnabled = useIsFormManagementEnabled()

  if (!isFormManagementEnabled) return null

  return (
    <ScreenContainerWithMenuHeader screenCategory="formManagement">
      <ShadowContainerWithTabs>
        <HeaderNavContainer>
          <Title text={t('formManagement:title')} />
        </HeaderNavContainer>
        <Tabs.Navigator
          swipeEnabled={false}
          tabBar={props =>
            !isSmallScreen ? (
              <TopTabBar tabContainerWidth={TAB_WIDTH} {...props} />
            ) : (
              <TopTabBarMobile {...props} />
            )
          }
        >
          {formManagementTabs.map(({ name, Component, options }) => (
            <Tabs.Screen
              key={name}
              name={name}
              children={() =>
                name === currentRouteName ? <Component /> : null
              }
              options={options}
            />
          ))}
        </Tabs.Navigator>
      </ShadowContainerWithTabs>
    </ScreenContainerWithMenuHeader>
  )
}

export default FormManagementTabs
