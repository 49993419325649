import React, { useMemo, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Text } from '../../../../components/common/Text'
import MenuItem from './MenuItem'
import { SidebarOption, SidebarOptionsEnum } from '../types'
import { sidebarOptions } from '../constants'

const Components = () => {
  const { colors } = useTheme()
  const [activeOption, setActiveOption] = useState<SidebarOptionsEnum>(
    SidebarOptionsEnum.Forms
  )

  const ActiveComponent = useMemo(() => {
    const optionGroup = sidebarOptions.reduce(
      (acc, optionGroup) => [...acc, ...optionGroup.options],
      [] as SidebarOption[]
    )
    const currentActiveComponent = optionGroup.find(
      option => option.option === activeOption
    )?.component
    return currentActiveComponent
  }, [activeOption])

  return (
    <View
      style={{
        flex: 1,
        margin: 10,
        flexDirection: 'row'
      }}
    >
      {/* Sidebar Menu Options */}
      <View
        style={{
          width: '16%',
          height: '100%',
          borderColor: colors.tints.grays.g100,
          borderRightWidth: 1,
          paddingRight: 5,
          paddingTop: 10
        }}
      >
        {sidebarOptions.map(optionGroup => (
          <View key={optionGroup.label} style={{ marginBottom: 25 }}>
            <Text
              styles={{
                color: colors.tints.grays.g400,
                textAlign: 'right',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                marginRight: 10,
                height: 25
              }}
            >
              {optionGroup.label}
            </Text>
            {optionGroup.options.map(option => (
              <MenuItem
                key={option.label}
                label={option.label}
                option={option.option}
                isActive={activeOption === option.option}
                setActiveOption={setActiveOption}
              />
            ))}
          </View>
        ))}
      </View>

      {/* Option Content */}
      <View
        style={{
          width: '84%',
          height: '100%'
        }}
      >
        {ActiveComponent ? <ActiveComponent /> : null}
      </View>
    </View>
  )
}

export default Components
