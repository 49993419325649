import gql from 'graphql-tag'
import { FileProps } from '../../../recoil/currentDownloadFilesAtom'

export const innovationSubmissionCloudinaryIdUpdated = gql`
  subscription innovationSubmissionCloudinaryIdUpdated(
    $id: String!
    $pdfCloudinaryId: String
  ) {
    innovationSubmissionCloudinaryIdUpdated(
      id: $id
      pdfCloudinaryId: $pdfCloudinaryId
    ) {
      id
      pdfCloudinaryId
    }
  }
`

interface InnovationSubmissionCloudinaryIdUpdatedProps {
  subscriptionData: any
  setFiles: (value: any) => void
  files: FileProps[]
}

export const onInnovationSubmissionCloudinaryIdUpdated = ({
  subscriptionData,
  setFiles,
  files
}: InnovationSubmissionCloudinaryIdUpdatedProps) => {
  const newFiles = [...files]
  let file: FileProps
  const submissionId =
    subscriptionData?.data?.innovationSubmissionCloudinaryIdUpdated?.id
  const pdfCloudinaryId =
    subscriptionData?.data?.innovationSubmissionCloudinaryIdUpdated
      ?.pdfCloudinaryId

  const fileIndex = files?.findIndex(file => file.id === submissionId) ?? -1

  if (fileIndex > -1 && pdfCloudinaryId) {
    const currentFile = files[fileIndex]
    file = {
      ...currentFile,
      cloudinaryId: pdfCloudinaryId,
      isLoading: false
    }
    newFiles.splice(fileIndex, 1)
    newFiles.push(file)
    setFiles({ files: newFiles })
  } else if (fileIndex > -1 && !pdfCloudinaryId) {
    const currentFile = files[fileIndex]
    file = {
      ...currentFile,
      cloudinaryId: pdfCloudinaryId,
      error: true,
      isLoading: false
    }
    newFiles.splice(fileIndex, 1)
    newFiles.push(file)
    setFiles({ files: newFiles })
  }
}
