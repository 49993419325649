import { atom, RecoilState } from 'recoil'

export enum MessageStatus {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

export interface Message {
  message: string
  status?: MessageStatus
}

export const DEFAULT_TOAST_MESSAGE: Message = {
  message: '',
  status: MessageStatus.Success
}

const toastMessage: RecoilState<Message> = atom({
  key: 'toastMessage',
  default: DEFAULT_TOAST_MESSAGE
})

export default toastMessage
