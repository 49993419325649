import { atom } from 'recoil'

export interface UnsavedChangesAtom {
  hasUnsavedChanges: boolean
  saveCallback: () => void
  discardCallback: () => void
}

const unsavedChanges = atom<UnsavedChangesAtom>({
  key: 'unsavedChanges',
  default: {
    hasUnsavedChanges: false,
    saveCallback: () => {},
    discardCallback: () => {}
  }
})

export default unsavedChanges
