import React, { useContext } from 'react'
import { Text, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { DashboardContext } from '../hooks/DashboardProvider'

const DashboardTitle = () => {
  const {
    callForSubmission: { name }
  } = useContext(DashboardContext)
  const { colors } = useTheme()

  return (
    <View
      style={{
        width: '100%',
        marginBottom: 10,
        marginLeft: 10,
        paddingRight: 30
      }}
    >
      <Text
        style={{
          fontSize: 35,
          fontWeight: 'bold',
          color: colors.tints.grays.g300,
          paddingBottom: 5,
          borderBottomColor: colors.tints.grays.g200,
          borderBottomWidth: 1
        }}
      >
        {name}
      </Text>
    </View>
  )
}

export default DashboardTitle
