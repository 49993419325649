import React, { FC } from 'react'
import { useTheme } from 'styled-components/native'
import { TouchableOpacity } from 'react-native'
import useLinkToScreen from '../../../../hooks/useLinkToScreen'
// Hooks
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import useHistoryRoutes from '../../../../hooks/useHistoryRoutes'
// Components
import { Title } from '../index'
import { Flex } from '../../../../components/FlexBox'
import NextPrevSubmissionButton from './NextPrevSubmissionButton'
import ArrowLeftIcon from '../../../../components/icon/ArrowLeftIcon'
// Types
import { InnovationSubmission, RoleEnum } from '../../../../types'

interface HeaderProps {
  submission: InnovationSubmission
  callForSubmissionId: string
  role: RoleEnum
  setSubmissionId: (submissionId: string) => void
  tableName: string
}

const Header: FC<HeaderProps> = ({
  submission,
  callForSubmissionId,
  role,
  setSubmissionId,
  tableName
}) => {
  const { colors, space } = useTheme()
  const isSmallScreen = useIsSmallScreen()
  const linkToScreen = useLinkToScreen()

  const { history } = useHistoryRoutes()

  const onPopNavigate = () => {
    let routeName = 'AdviseSubmissions'
    let params: any = { id: callForSubmissionId }
    if (role === RoleEnum.InnovationStaff) {
      routeName = 'CurationTabs'
      params.screen =
        history?.[history?.length - 2]?.screen || 'CurationPreCuration'
    }
    linkToScreen(routeName, params)
  }

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Flex width="44%" flexDirection="row" alignItems="center">
        <TouchableOpacity
          onPress={onPopNavigate}
          style={{
            marginRight: space[3] + space[1] // 20
          }}
        >
          <ArrowLeftIcon color={colors.lightGrey} />
        </TouchableOpacity>
        <Title
          isSmallScreen={isSmallScreen}
        >{`${submission?.callForSubmission?.name}`}</Title>
      </Flex>
      <NextPrevSubmissionButton
        tableName={tableName}
        submissionId={submission.id}
        setSubmissionId={setSubmissionId}
        action="Prev"
      />
      <NextPrevSubmissionButton
        tableName={tableName}
        submissionId={submission.id}
        setSubmissionId={setSubmissionId}
      />
    </Flex>
  )
}

export default Header
