import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { find, isEmpty } from 'ramda'
import { FormikErrors, FormikValues, useFormik } from 'formik'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import useFormQuery from './useFormQuery'
import useUpsertAnswersMutation from './useUpsertAnswersMutation'
import generateSchemaValidation from '../utils/generateSchema'
import DynamicForm from '../components/DynamicForm'
import { CallForSubmission, OriginSourceEnum, MarketSegment } from '../types'
import {
  Answer,
  EntityTypeEnum,
  FormData,
  FormQuestionConditions,
  InnovationFormTypeRenderOptions,
  PlatformRenderOptions,
  Question,
  QuestionGroup,
  QuestionGroupEntityMap,
  QuestionSubTypeEnum,
  QuestionTypeEnum,
  ValidationPhaseEnum
} from '../types/form'
import { Question as DynamicQuestionType } from '../components/DynamicForm/types'
import usePreventNavOnDirtyState from './usePreventNavigationOnDirtyState'
import useTranslation from './useTranslation'
import useToast from './useToast'
import useUpsertInnovatorProduct from '../screens/Submission/hooks/useUpsertInnovatorProduct'
// Recoil
import { productFormByType } from '../recoil/productFormsAtom'

import { getAllQuestions } from '../components/DynamicForm/recoil/questionAtomFamily'
import { formSubmissionMetaDataAtomFamily } from '../components/DynamicForm/recoil/formSubmissionMetaDataAtomFamily'
import { marketSegmentsMetaDataAtomFamily } from '../components/DynamicForm/recoil/marketSegmentsMetaDataAtomFamily'
import { formQuestionsConditionsAtomFamily } from '../recoil/formQuestionsConditionsAtomFamily'
import { AppIds } from './useMixpanel'

interface FormButtonsProps {
  isSubmission: boolean
}

interface DynamicFormProps {
  type?: string
  formId?: string
  formSubmissionId?: string
  formPhase?: ValidationPhaseEnum
  answerEntityId?: string
  answerEntityType?: string
  onSubmit?: (values: FormikValues, formData?: FormData) => any
  onDirty?: (dirty: boolean) => any
  originSource: OriginSourceEnum
  resetOnSubmit?: boolean
  isReadOnly?: boolean
  asPrintable?: boolean
  withFormButtonsConfig?: FormButtonsProps
  callForSubmission?: CallForSubmission
  marketSegment?: MarketSegment | null
  refetchQueries?: string[]
  isInnovatorProduct?: boolean
  submissionId?: string | null
  beforeRemoveDisabled?: boolean
  formQuestionConditions?: FormQuestionConditions
  shouldRenderAnswers?: boolean
  isInnovatorAlumni?: boolean
}

export type SetInitialFieldValue = (fieldPath: string, value: any) => void
export type SetInitialFieldValues = (fieldPath: string, value: any) => void

export interface DynamicFormHelpers {
  setCurrentPhase: Function
  setAllFieldsTouched: () => void
  getFormErrorCount: (errors: any) => number
  saveSingleField: (field: string, values: FormikValues) => void
  resetTouchedField: (field: string) => void
  resetAllTouchFields: () => void
}

interface DynamicForm {
  form: any
  loadingFormData: boolean
  loadingUpsertAnswer: boolean
  component: React.ReactNode
  helpers: DynamicFormHelpers
  refetchFormData?: () => void
}

export const getDefaultValueByType = (questionType: QuestionTypeEnum) => {
  const dict = {
    [QuestionTypeEnum.Textarea]: '',
    [QuestionTypeEnum.TextInput]: '',
    [QuestionTypeEnum.Integer]: null,
    [QuestionTypeEnum.MultiSelect]: [],
    default: null
  }
  return questionType in dict ? dict[questionType] : dict['default']
}

const getQuestionValue = (question: Question, shouldRenderAnswers = true) => {
  const { type } = question
  if (question?.answer?.value && shouldRenderAnswers) {
    switch (type) {
      case QuestionTypeEnum.DateInput:
        return Date.parse(question?.answer?.value)
      default:
        // Validate if the current Question has optionValues
        const optionsValues = (question?.optionsValues ?? []) as any[]
        if (optionsValues.length) {
          const currentAnswer = question?.answer?.value
          // Validate if the current answers are included into the new question optionValues
          if (Array.isArray(currentAnswer) && currentAnswer.length) {
            let newAnswer: any[] = []
            for (let index = 0; index < currentAnswer.length; index++) {
              const element = currentAnswer[index]
              if (optionsValues.map(option => option.value).includes(element)) {
                newAnswer.push(element)
              }
            }
            return newAnswer
          }
        }
        return question?.answer?.value
    }
  } else {
    return getDefaultValueByType(type)
  }
}

const generateDynamicDefaultValues = (
  formData: FormData,
  setter: (values: object) => void,
  shouldRenderAnswers = true
) => {
  const questions = formData.questions
  const newDefaultValues: any = {}

  if (formData?.formGroup?.questionGroupEntityMaps) {
    newDefaultValues['questions'] = {}

    // Handle nesting recursively
    const processQuestionGroupEntityMap = (
      questionGroupEntityMap: QuestionGroupEntityMap
    ) => {
      const { entityId, entity } = questionGroupEntityMap
      if (questionGroupEntityMap.entityType === EntityTypeEnum.Question) {
        const question = find(q => q.id === entity.id, questions)
        newDefaultValues.questions[entityId] = getQuestionValue(
          question as Question,
          shouldRenderAnswers
        )
      }

      if (questionGroupEntityMap.entityType === EntityTypeEnum.QuestionGroup) {
        const { dynamic, questionGroupEntityMaps } = entity as QuestionGroup
        // generate default values for dynamic questions
        if (!dynamic && questionGroupEntityMaps.length) {
          questionGroupEntityMaps.forEach(processQuestionGroupEntityMap)
        }
      }
    }

    formData.formGroup.questionGroupEntityMaps.forEach(
      processQuestionGroupEntityMap
    )
  }

  setter(newDefaultValues)
}

const generateDynamicValidations = (
  formData: FormData,
  setter: (values: object) => void,
  phase: ValidationPhaseEnum,
  isInnovatorAlumni?: boolean
) => {
  let schemaObject: any = {}

  /**
   * All non dynamic group questions will be handled at the top level
   */
  let filteredGroups = formData?.questionGroups?.filter(group => !group.dynamic)

  // Filter only on Publish Phase
  if (phase === ValidationPhaseEnum.Publish) {
    let questionGroupBlackList: any[] = []
    // Filter QuestionGroups by the RenderOptions
    filteredGroups = filteredGroups.filter(questionGroup => {
      const configData = questionGroup.configData
      const renderOptions = configData?.renderOptions ?? {}
      const platformRenderOptions = (renderOptions[AppIds.Innovation] ??
        {}) as PlatformRenderOptions
      const platformRenderFormType = (platformRenderOptions?.innovationFormTypes ??
        []) as InnovationFormTypeRenderOptions[]

      // Hide if exists the shouldHidden on the render option of the questionGroup
      const shouldPass =
        platformRenderFormType.every(
          renderOption => !renderOption?.shouldHide
        ) ?? false

      if (
        !shouldPass &&
        questionGroup?.questionGroupEntityMaps?.length &&
        questionGroup?.questionGroupEntityMaps?.some(
          qg => qg?.entity?.__typename === 'QuestionGroup'
        )
      ) {
        questionGroupBlackList.push({
          id: questionGroup.id,
          questionGroupEntityMaps: questionGroup?.questionGroupEntityMaps?.filter(
            qg => qg?.entity?.__typename === 'QuestionGroup'
          )
        })
      }

      return shouldPass
    })

    // The blacklist was created in case there are still QuestionGroups
    // that belonged to a QuestionGroup already filtered by renderOptions.

    if (questionGroupBlackList.length) {
      filteredGroups = filteredGroups.filter(
        questionGroup =>
          !questionGroupBlackList.some(bl => {
            const entityIds = bl?.questionGroupEntityMaps?.map(
              qgem => qgem?.entity?.id
            )
            return entityIds?.includes(questionGroup.id)
          })
      )
    }
  }

  schemaObject['questions'] = generateSchemaValidation(
    filteredGroups as any[],
    formData.questions as any,
    phase,
    isInnovatorAlumni
  )

  setter(schemaObject)
}

const generateAutoSaveFieldValidations = (
  formData: FormData,
  field: string
) => {
  let schemaObject: any = {}

  const filteredGroups = formData?.questionGroups?.filter(
    group => !group.dynamic
  )

  schemaObject['questions'] = generateSchemaValidation(
    // @ts-ignore Fix this - duplicate types
    filteredGroups,
    formData.questions.filter(question => question.id === field),
    ValidationPhaseEnum.Save
  )

  return schemaObject
}

const excludedByTypes = [QuestionTypeEnum.FileDropzone]

const excludeByTypeSubtype = {
  [QuestionTypeEnum.MultiSelect]: QuestionSubTypeEnum.DbLookup
}

const questionIncludedByTypeSubType = (question: Question): boolean =>
  excludedByTypes.includes(question.type) ||
  (!!excludeByTypeSubtype?.[question.type] &&
    excludeByTypeSubtype[question.type] === question?.subType)

interface QuestionMapObject {
  [key: string]: Question | DynamicQuestionType
}

const mapAnswerValuesForSubmit = (
  values: FormikValues,
  formData: FormData,
  formQuestions: DynamicQuestionType[],
  answerEntityType?: string,
  formSubmissionId?: string
): Answer[] | null => {
  const formQuestionValues = values['questions']
  if (
    !formQuestions ||
    isEmpty(formQuestions) ||
    !formData ||
    isEmpty(formData)
  ) {
    return null
  }
  // This is just creating a lookup so it is easier to get values in the next function
  const questionMap: QuestionMapObject = {}

  if (formQuestions) {
    formQuestions.forEach(item => {
      questionMap[item.id] = item
    })
  } else {
    formData.questions.forEach(item => {
      questionMap[item.id] = item
    })
  }

  // answers for submission
  const answers: Answer[] = []

  for (const [key, value] of Object.entries(formQuestionValues)) {
    if (!questionIncludedByTypeSubType(questionMap[key] as Question)) {
      let answer: Answer = {
        value,
        questionId: questionMap[key]?.id
      }
      if (formSubmissionId) {
        answer.formSubmissionId = formSubmissionId
        answer.answerEntityType = answerEntityType
      }
      // include id if answer is existing
      if (questionMap[key]?.answer?.id) {
        answer.id = questionMap[key]?.answer?.id
      }
      answers.push(answer)
    }
  }

  return answers
}

const createFormComponent = (
  formData: FormData,
  isReadOnly,
  asPrintable,
  formSubmissionId: string,
  callForSubmission?: CallForSubmission,
  callbackAfterAction?: () => void,
  isInnovatorProduct?: boolean
) =>
  React.createElement(DynamicForm, {
    formGroup: formData?.formGroup,
    isReadOnly,
    asPrintable,
    formSubmissionId,
    callForSubmission,
    formQuestions: formData.questions,
    callbackAfterAction,
    isInnovatorProduct
  })

/**
 * Counts the number of Formik validation errors and returns a total.
 * The structure of the errors object isn't quite what you'd think.
 * There's a `questions` key that holds an array of key/value pairs
 * matching the dynamic form values, but then there's also a top-level
 * version of the same error with a key matching `question.${id}` that
 * contains the same error information. Simply counting top level
 * keys won't work. You have to eliminate the `questions` array first.
 */
export const countErrors = (errors: any) => {
  if (!errors) return 0
  const res = Object.keys(errors).filter(
    error => typeof errors[error] !== 'string'
  ).length
  return res
}

export const mapInitialValuesTouched = (initialValues: any) => {
  const initialValuesTouched = {}

  for (const key in initialValues) {
    initialValuesTouched[key] = {}
    Object.keys(initialValues[key]).forEach(subKey => {
      initialValuesTouched[key][subKey] = true
    })
  }

  return initialValuesTouched
}

const mapValuesTouched = (field: string, initialValues: any) => {
  const valuesTouched = {}

  for (const key in initialValues) {
    valuesTouched[key] = {}
    Object.keys(initialValues[key]).forEach(subKey => {
      if (subKey !== field) {
        valuesTouched[key][subKey] = initialValues[key][subKey]
      }
    })
  }

  return valuesTouched
}

const useDynamicForm = ({
  type,
  formId,
  formSubmissionId,
  formPhase,
  answerEntityId,
  answerEntityType,
  onSubmit,
  originSource,
  resetOnSubmit = true,
  isReadOnly = false,
  asPrintable = false,
  withFormButtonsConfig,
  callForSubmission,
  marketSegment,
  refetchQueries = [],
  isInnovatorProduct = false,
  submissionId = null,
  beforeRemoveDisabled = false,
  shouldRenderAnswers = true,
  formQuestionConditions,
  onDirty,
  isInnovatorAlumni
}: DynamicFormProps): DynamicForm => {
  const { t } = useTranslation()
  const { setToastMessage } = useToast()
  const {
    formData,
    loadingFormData,
    setQuestionWithAnswers,
    refetchFormData
  } = useFormQuery({
    type,
    answerEntityId,
    formId,
    formSubmissionId
  })
  const recoilPhase = useRecoilValue(productFormByType(type as any))?.phase
  const phase = (formPhase || recoilPhase) as ValidationPhaseEnum
  const [currentPhase, setCurrentPhase] = useState<ValidationPhaseEnum>(phase)
  const formQuestions = useRecoilValue(getAllQuestions) as DynamicQuestionType[]

  const updateProductCallback = useCallback(async () => {
    if (isInnovatorProduct) {
      await updateInnovatorProduct(submissionId, false)
    }
  }, [isInnovatorProduct])

  let component: any = useRef(null)

  const setCallForSubmission = useRecoilCallback(({ set }) => () => {
    set(formSubmissionMetaDataAtomFamily(formSubmissionId as string), {
      type,
      formId,
      formSubmissionId,
      answerEntityId,
      answerEntityType,
      originSource,
      // @ts-ignore Fix this
      callForSubmission
    })
  })

  const setMarketSegment = useRecoilCallback(({ set }) => () => {
    set(marketSegmentsMetaDataAtomFamily(formSubmissionId as string), {
      marketSegments: marketSegment ? [marketSegment] : null
    })
  })

  useEffect(() => {
    if (formSubmissionId && callForSubmission) {
      setCallForSubmission()
    }
    if (formSubmissionId && marketSegment) {
      setMarketSegment()
    }
  }, [callForSubmission, marketSegment, formSubmissionId])

  // Form conditions
  const setFormQuestionConditions = useRecoilCallback(({ set }) => () => {
    set(
      formQuestionsConditionsAtomFamily(formSubmissionId as string),
      formQuestionConditions || null
    )
  })
  useEffect(() => {
    if (formQuestionConditions) setFormQuestionConditions()
  }, [formQuestionConditions])

  const { updateInnovatorProduct } = useUpsertInnovatorProduct()

  const [upsertAnswers, loadingUpsertAnswer] = useUpsertAnswersMutation({
    onError: e => console.error(e),
    onCompleted: ({ upsertAnswers }) => {
      if (
        upsertAnswers &&
        !isEmpty(upsertAnswers) &&
        upsertAnswers?.length > 1
      ) {
        setQuestionWithAnswers(formData?.questions, upsertAnswers)
        setToastMessage(t('product:success'))
      } else {
        console.warn(
          `No upsertAnswers returned from upsertAnswers mutation: `,
          upsertAnswers
        )
      }
    },
    refetchQueries: [...refetchQueries, ...['innovationSubmissions']],
    errorMessage: t('error:form:answerQuestions')
  })

  const [dynamicDefaultValues, setDynamicDefaultValues] = useState({})
  const [dynamicSaveValidationSchema, setDynamicSaveValidationSchema] = <any>(
    useState()
  )
  const [dynamicSubmitValidationSchema, setDynamicSubmitValidationSchema] = <
    any
  >useState()
  const [dynamicPublishValidationSchema, setDynamicPublishValidationSchema] = <
    any
  >useState()
  const [dynamicReviewValidationSchema, setDynamicReviewValidationSchema] = <
    any
  >useState()

  const getValidationSchema = (
    override?: ValidationPhaseEnum,
    field?: string
  ) => {
    let autoSaveValidationSchema
    const schemaPhase =
      override || (withFormButtonsConfig ? currentPhase : phase)

    const isAutoSave = !!(formData && field)
    if (isAutoSave) {
      autoSaveValidationSchema = generateAutoSaveFieldValidations(
        formData,
        field
      )
    }

    return Yup.object().shape({
      ...(schemaPhase === ValidationPhaseEnum.Review
        ? dynamicReviewValidationSchema
        : schemaPhase === ValidationPhaseEnum.Submit
        ? dynamicSubmitValidationSchema
        : schemaPhase === ValidationPhaseEnum.Publish
        ? dynamicPublishValidationSchema
        : isAutoSave
        ? autoSaveValidationSchema
        : dynamicSaveValidationSchema)
    })
  }

  const formik = useFormik({
    initialValues: { ...dynamicDefaultValues },
    validateOnBlur: true,
    validateOnChange: false,
    validate: async (values: FormikValues) => {
      const errors: FormikErrors<FormikValues> = { questions: {} }
      let field
      if (values?.autoSave) {
        field = Object.keys(formik.touched?.questions ?? {})[0]
      }
      const schema = getValidationSchema(values.phase, field)

      try {
        schema.validateSync(values, { abortEarly: false })
      } catch (error) {
        error?.inner?.forEach(error => {
          // @ts-ignore Fix this
          errors.questions[error?.path?.split('.')[1]] = error?.message
        })
      }

      return isEmpty(errors.questions) ? {} : errors
    },
    onSubmit: async values => {
      if (formData && formSubmissionId && !isReadOnly) {
        const answers = mapAnswerValuesForSubmit(
          values,
          formData,
          formQuestions,
          answerEntityType,
          formSubmissionId
        )

        await upsertAnswers({
          variables: { answers, originSource }
        })
      }

      // perform optional external submit action
      if (onSubmit) {
        await onSubmit(values, formData)
      }

      if (resetOnSubmit) {
        setDynamicDefaultValues(values)
        formik.resetForm(values)
      }
    },
    enableReinitialize: true
  })

  useEffect(() => {
    // update the answers locally to avoid display the values on the form
    if (formData && !shouldRenderAnswers) {
      var questionMap: QuestionMapObject = {}
      formData.questions.forEach(item => {
        questionMap[item.id] = item
      })

      // answers for submission
      const answers: Answer[] = []

      for (const [key] of Object.entries(questionMap)) {
        let answer: Answer = {
          value: '',
          questionId: questionMap[key]?.id
        }

        if (formSubmissionId) {
          answer.formSubmissionId = formSubmissionId
          answer.answerEntityType = answerEntityType
        }
        // include id if answer is existing
        if (questionMap[key]?.answer?.id) {
          answer.id = questionMap[key]?.answer?.id
        }
        answers.push(answer)
      }

      setQuestionWithAnswers(formData?.questions, answers)
    }
  }, [formData])

  useEffect(() => {
    if (!formData || isReadOnly || !onDirty) return
    onDirty?.(formik.dirty)
  }, [formData, isReadOnly, formik?.dirty, onDirty])

  useEffect(() => {
    if (!formData || isEmpty(formData)) {
      return
    }
    generateDynamicDefaultValues(
      formData,
      setDynamicDefaultValues,
      shouldRenderAnswers
    )
    generateDynamicValidations(
      formData,
      setDynamicSaveValidationSchema,
      ValidationPhaseEnum.Save
    )
    generateDynamicValidations(
      formData,
      setDynamicSubmitValidationSchema,
      ValidationPhaseEnum.Submit
    )
    generateDynamicValidations(
      formData,
      setDynamicPublishValidationSchema,
      ValidationPhaseEnum.Publish
    )
    generateDynamicValidations(
      formData,
      setDynamicReviewValidationSchema,
      ValidationPhaseEnum.Review,
      isInnovatorAlumni
    )
  }, [formData])

  useEffect(() => {
    if (!formData || isEmpty(formData) || !formSubmissionId) {
      return
    }
    component.current = createFormComponent(
      formData,
      isReadOnly,
      asPrintable,
      formSubmissionId,
      callForSubmission,
      updateProductCallback,
      isInnovatorProduct
    )
  }, [
    formData,
    isReadOnly,
    asPrintable,
    formSubmissionId,
    callForSubmission,
    submissionId,
    updateProductCallback
  ])

  /**
   * Flags all of the fields as touched so that the entire set of inputs in the
   * form is validated on submit.
   */
  const setAllFieldsTouched = () => {
    formik.setTouched(mapInitialValuesTouched(formik.initialValues))
  }

  const resetTouchedField = (field: string) => {
    formik.setTouched(mapValuesTouched(field, formik.touched))
  }

  const resetAllTouchFields = () => {
    formik.setTouched({})
  }

  const saveSingleField = async (field: string, values: FormikValues) => {
    if (formData && formSubmissionId && !isReadOnly) {
      const formQuestion = formQuestions.filter(
        question => question.id === field
      )
      const answers = mapAnswerValuesForSubmit(
        values,
        formData,
        formQuestion,
        answerEntityType,
        formSubmissionId
      )
      await upsertAnswers({
        variables: { answers, originSource }
      })

      // JC: When we auto-saved the product we need to update the field to know that
      // a its PDF needs to be regenerate it.
      if (isInnovatorProduct) {
        await updateInnovatorProduct(submissionId, false, ['company'])
      }
    }
  }

  const getFormErrorCount = errors => countErrors(errors)

  usePreventNavOnDirtyState(formik, isReadOnly, beforeRemoveDisabled)

  return {
    form: formik,
    // @ts-ignore Fix this
    formData,
    refetchFormData,
    upsertAnswers,
    loadingFormData,
    loadingUpsertAnswer,
    component: component?.current,
    helpers: {
      setCurrentPhase,
      setAllFieldsTouched,
      getFormErrorCount,
      saveSingleField,
      resetTouchedField,
      resetAllTouchFields
    }
  }
}

export default useDynamicForm
