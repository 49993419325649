import React, { useContext, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/native'
import Card from '../common/Card'
import useTranslation from '../../../../hooks/useTranslation'
import { DashboardContext } from '../hooks/DashboardProvider'
import { TopicProps } from './types'
import { Text, TouchableOpacity, View } from 'react-native'
import AnimatedComponent from '../../../../components/common/AnimatedComponent'
import capitalize from '../../../../utils/capitalize'
import { AnimatedEffect } from '../../../../components/common/AnimatedComponent/types'
import { InnovationTagTypeEnum } from '../common/types'
import { sortByCountAndGetTopElements } from '../common/utils'

const Topic = (props: TopicProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { index, topic, handleSelectTrend, selectedTrends } = props
  const { name, type, count, frequency } = topic

  const zoomEffect: AnimatedEffect = {
    initialValue: 1,
    mouseEvent: 'Hover',
    styleProperty: value => ({
      transform: [{ scaleX: value }, { scaleY: value }]
    }),
    timeIn: 150,
    timeOut: 100,
    toValue: 1.07
  }

  return (
    <AnimatedComponent
      hoverColor={colors.tableRowHover}
      effects={[zoomEffect]}
      style={{
        width: '250px',
        overflow: 'visible',
        marginBottom: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 10,
        borderColor: colors.tints.grays.g100,
        backgroundColor: selectedTrends.includes(name)
          ? colors.tints.grays.g100
          : undefined
      }}
    >
      <TouchableOpacity
        onPress={() => {
          handleSelectTrend(name, type)
        }}
      >
        <Text
          style={{
            fontWeight: '550',
            marginBottom: 2,
            color: colors.tints.grays.g500
          }}
        >
          {`${index + 1}. ${name
            .split(' ')
            .map(w => capitalize(w))
            .join(' ')}`}
        </Text>
        <Text style={{ color: colors.tints.grays.g200 }}>
          {t('dashboard:components:trends:topicLegend', {
            frequency,
            count
          })}
        </Text>
      </TouchableOpacity>
    </AnimatedComponent>
  )
}

const Trends = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { topics, handleFilters } = useContext(DashboardContext)
  const [selectedTrends, setSelectedTrends] = useState<string[]>([])
  const [currentType, setCurrentType] = useState<InnovationTagTypeEnum | 'All'>(
    'All'
  )
  const tagTypes = Object.values(InnovationTagTypeEnum)

  const handleSelectTrend = (trend: string, type: InnovationTagTypeEnum) => {
    if (!selectedTrends.includes(trend)) {
      setSelectedTrends([...selectedTrends, trend])
    } else {
      const filteredTrends = selectedTrends.filter(
        currentTrend => currentTrend !== trend
      )
      setSelectedTrends(filteredTrends)
    }
    handleFilters({ filterType: type, value: trend })
  }

  const topicsData = useMemo(() => {
    let data: any[] = []
    switch (currentType) {
      case 'All':
        data = topics
        break
      default:
        data = topics.filter(topic => topic.type === currentType)
        break
    }
    const filteredData = data.length
      ? sortByCountAndGetTopElements(data, 'factor', 'name', 'asc', 10, false)
      : []
    return filteredData
  }, [topics, currentType])

  const filterStyles = {
    height: 'fit-content',
    fontSize: 10,
    marginHorizontal: 2,
    paddingHorizontal: 7,
    paddingVertical: 3,
    marginBottom: 5,
    borderColor: colors.tints.grays.g100,
    borderWidth: 1,
    borderRadius: 10
  }
  return (
    <Card
      minWidth="100%"
      title={t('dashboard:components:trends:title')}
      styles={{
        flex: 1,
        paddingBottom: 20,
        height: 'fit-content'
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: 'transparent',
          overflow: 'visible'
        }}
      >
        <View
          style={{
            marginVertical: 5,
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'wrap'
          }}
        >
          <TouchableOpacity onPress={() => setCurrentType('All')}>
            <Text
              style={{
                ...filterStyles,
                backgroundColor:
                  currentType === 'All' ? colors.tints.grays.g100 : undefined
              }}
            >
              {'All'}
            </Text>
          </TouchableOpacity>
          {tagTypes.map(tagType => (
            <TouchableOpacity onPress={() => setCurrentType(tagType)}>
              <Text
                style={{
                  ...filterStyles,
                  backgroundColor:
                    currentType === tagType
                      ? colors.tints.grays.g100
                      : undefined
                }}
              >
                {capitalize(tagType)}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <View
          style={{
            overflow: 'visible',
            margin: 10,
            flexWrap: 'wrap',
            flexDirection: 'row'
          }}
        >
          {topicsData.map((topic, index) => (
            <Topic
              index={index}
              topic={topic}
              handleSelectTrend={handleSelectTrend}
              selectedTrends={selectedTrends}
            />
          ))}
        </View>
      </View>
    </Card>
  )
}

export default Trends
