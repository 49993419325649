import React, { FC } from 'react'

// Types
import { InnovationAccessKey } from '../../types'
// Hooks
import useFeatureFlag from '../../hooks/useFeatureFlag'
import useTranslation from '../../hooks/useTranslation'
import useAddCategoryController from './hooks/useCategoryMngController'
import useGetInnovationCategories from './hooks/useGetInnovationCategories'
// Components
import ActionHeader from './components/ActionHeader'
import CategoryModal from './components/CategoryModal'
import { TableContainer } from '../../components/StaticForm'
import { DynamicTable, useTable } from '../../components/Table'
import { getCategoriesManagementTableConfig } from './constants'
import { Container } from '../CallForSubmission/components/SharedComponents'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'
import { ScreenContainerWithTabs } from '../../components/layout/ScreenContainer'
import withNavigationPermissions from '../../navigation/withNavigationPermissions'
// Skeleton
import TableSkeleton from '../HomeScreen/TableSkeleton'
import SkeletonContainer from '../../components/skeletonLoadings/SkeletonContainer'

// Types
interface CategoryManagementScreenProps {}

const CategoryManagementScreen: FC<CategoryManagementScreenProps> = () => {
  // State
  const [selectedMarketSegment, setSelectedMarketSegment] = React.useState([])
  // Hooks
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)

  const {
    loading: fetchLoading,
    innovationCategories
  } = useGetInnovationCategories(selectedMarketSegment, {}, true)

  // Form/Modal Config
  const {
    modalProps,
    onAddNewCategory,
    onEditCategory
  } = useAddCategoryController()

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps = useTable({
    data: innovationCategories,
    config: getCategoriesManagementTableConfig({
      onEditCategory
    })
  })

  return (
    <ScreenContainerWithTabs>
      <Container>
        <ActionHeader
          onAddNewCategory={onAddNewCategory}
          selectedMarketSegment={selectedMarketSegment}
          onMarketSegmentSelection={setSelectedMarketSegment}
        />
        <SkeletonContainer isLoading={!!fetchLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...(tableProps as any)}
              emptyMessage={t('platformManagement:categories:noCategories')}
            />
          </TableContainer>
        </SkeletonContainer>
      </Container>
      <CategoryModal {...modalProps} />
    </ScreenContainerWithTabs>
  )
}

const CategoryManagement = withNavigationPermissions(CategoryManagementScreen, [
  InnovationAccessKey.PLATFORM_MANAGEMENT
])

export default CategoryManagement
