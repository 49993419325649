import React, { useMemo, useEffect, useState } from 'react'

import { useTheme } from 'styled-components/native'
import { Linking, Platform, View } from 'react-native'
import { Badge } from 'react-native-elements'
// Hooks/Utils
import useTranslation from '../../../../hooks/useTranslation'
import idToCloudinaryUrl from '../../../../utils/idToCloudinaryUrl'
import useSubmissionPdfSubscription from '../../../../hooks/useSubmissionPdfSubscription'
import useCreateSubmissionPdfMutation from '../../../Submission/hooks/useCreateSubmissionPdfMutation'
import { getCurrencyFormat } from '../../../../services/currencyService'
// Components
import theme from '../../../../constants/Theme'
import Link from '../../../../components/Link'
import Icon from '../../../../components/icon'
import Button from '../../../../ui-library/Button'
import { Flex } from '../../../../components/FlexBox'
import { Text } from '../../../../components/common/Text'
import { Label, Content, ContainerWithTitle } from './SharedComponents'

const getUrl = initialUrl =>
  initialUrl
    ? initialUrl.includes('http')
      ? initialUrl
      : `//${initialUrl}`
    : ''

const maskFundingValue = (value: number) =>
  getCurrencyFormat(value, {
    style: 'currency'
  })

const CompanyInfo = ({ submission }) => {
  const { colors, space } = useTheme()
  const { t } = useTranslation()
  const { createSubmissionPDF, loading } = useCreateSubmissionPdfMutation()
  const [creatingPDF, setCreatingPDF] = useState(false)
  const company = submission.company

  const submissionPdfUrl = useMemo(() => {
    return submission?.pdfCloudinaryId
      ? idToCloudinaryUrl(submission?.pdfCloudinaryId, {
          resource_type: 'image'
        })
      : null
  }, [submission?.pdfCloudinaryId])

  const isPDFDownloadable = useMemo(() => !!submissionPdfUrl, [
    submissionPdfUrl
  ])

  useEffect(() => {
    if (isPDFDownloadable) {
      setCreatingPDF(false)
    }
  }, [isPDFDownloadable])

  useSubmissionPdfSubscription({
    submissionId: submission.id,
    pdfCloudinaryId: submission?.pdfCloudinaryId
  })

  const handleCreateSubmissionPDF = async () => {
    const pdfResult = await createSubmissionPDF(submission?.id)
    setCreatingPDF(true)

    if (pdfResult?.errors) {
      console.error(pdfResult.errors)
    }
  }

  const website = company?.companyProfile?.url
  const data = [
    {
      label: t('curation:submissionDetails:company:countryHQ'),
      value: company.companyProfile?.country?.name
    },
    {
      label: t('curation:submissionDetails:company:yearFounded'),
      value: company.companyProfile?.yearFounded
    },
    {
      label: t('curation:submissionDetails:company:employeeCount'),
      value: t(
        `auth:forms:companyEmployeeCountOptions:${company.companyProfile?.employeeCount}`
      )
    },
    {
      label: t('curation:submissionDetails:company:funding'),
      value: maskFundingValue(company.companyProfile?.funding)
    }
  ]

  const isGeneratingPDF = useMemo(
    () => (isPDFDownloadable ? false : loading || creatingPDF),
    [isPDFDownloadable, loading, creatingPDF]
  )

  const pdfButtonStyles = useMemo(
    () => ({
      standard: {
        iconStyle: { color: colors.primaryPalette.black },
        titleStyle: {
          textTransform: 'capitalize',
          color: colors.primaryPalette.black
        },
        buttonStyle: {
          backgroundColor: colors.appBackground,
          borderColor: colors.primaryPalette.black
        }
      },
      hover: {
        iconStyle: { color: colors.primaryPalette.white },
        buttonStyle: {
          borderColor: colors.primaryPalette.black,
          backgroundColor: colors.primaryPalette.black
        }
      }
    }),
    [colors]
  )

  const badgeText = company.isAlumni ? 'Alumni Innovator' : ''

  return (
    <ContainerWithTitle
      title={t('curation:submissionDetails:company:title')}
      badgeText={badgeText}
    >
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        marginTop={2}
      >
        <View style={{ flexBasis: '50%' }}>
          <Content
            style={{
              fontSize: '18px'
            }}
          >
            {company?.name || ''}
            {company.isAlumni ? (
              <Badge
                value={badgeText}
                badgeStyle={{
                  backgroundColor: colors.blueBackground,
                  marginLeft: 5
                }}
              />
            ) : (
              <></>
            )}
          </Content>
        </View>
        <View style={{ flexBasis: '50%', alignItems: 'flex-end' }}>
          <Button
            icon={
              <Icon
                name={isPDFDownloadable ? 'download' : 'arrow-path'}
                color={colors.primaryPalette.black}
                width={14}
                height={14}
              />
            }
            title={
              isPDFDownloadable
                ? t('innovatorDirectory:actions:pdf')
                : isGeneratingPDF
                ? t('curation:submissionDetails:generatingPDF')
                : t('curation:submissionDetails:createPDF')
            }
            type="outline"
            buttonStyle={
              isGeneratingPDF
                ? { padding: space[1], ...pdfButtonStyles.hover.buttonStyle }
                : pdfButtonStyles.standard.buttonStyle
            }
            titleStyle={{
              color: colors.primaryPalette.black,
              textTransform: 'capitalize'
            }}
            iconStyle={
              isGeneratingPDF
                ? pdfButtonStyles.hover.iconStyle
                : pdfButtonStyles.standard.iconStyle
            }
            onPress={async () => {
              if (isPDFDownloadable && submissionPdfUrl) {
                if (Platform.OS === 'web') {
                  window.open(submissionPdfUrl, '_blank')
                } else {
                  await Linking.openURL(submissionPdfUrl)
                }
              } else {
                await handleCreateSubmissionPDF()
              }
            }}
            loading={isGeneratingPDF}
          />
        </View>
      </Flex>

      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        marginBottom={3}
      >
        <View style={{ flexBasis: '100%', alignItems: 'flex-start' }}>
          <Link
            to={getUrl(website)}
            title={t('curation:submissionDetails:company:website')}
            target="_blank"
            style={{
              textDecoration: 'none',
              marginBottom: 0
            }}
          >
            <Text
              styles={{
                color: theme.colors.primary
              }}
            >
              {website}
            </Text>
          </Link>
        </View>
      </Flex>

      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        marginTop={2}
        marginBottom={4}
      >
        {data.map((item, index) => (
          <View key={index}>
            <Label>{item.label}</Label>
            <Content>{item.value}</Content>
          </View>
        ))}
      </Flex>
    </ContainerWithTitle>
  )
}

export default CompanyInfo
