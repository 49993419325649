import React, { useEffect, useState } from 'react'
import { LayoutAnimation } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
// Types
import { SkeletonContainerProps, SkeletonTemplateProps } from './types'

// Styles
const ListContainer = styled.View`
  height: 100%;
  flex-direction: column;
`

const SkeletonContainer: React.FC<SkeletonContainerProps> = ({
  Skeleton,
  children,
  delay,
  skeletonProps = {},
  isLoading = false,
  numberOfSkeletons = 1
}) => {
  const [loadingDelay, setLoadingDelay] = useState<boolean>(false)
  const theme = useTheme()

  const defaultProps: SkeletonTemplateProps = {
    ...skeletonProps,
    theme
  }

  // (Optional) Mobile animation when the loading status
  //  changes and it displays the content
  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear)
  }, [isLoading, loadingDelay])

  // If the skeleton needs a delay to display the skeleton
  useEffect(() => {
    if (delay && delay > 0) {
      setLoadingDelay(true)
      const handler = setTimeout(() => {
        setLoadingDelay(false)
      }, delay)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [delay])

  // Render
  const loadingState = loadingDelay || isLoading
  if (loadingState) {
    if (numberOfSkeletons > 1) {
      return (
        <ListContainer>
          {Array(numberOfSkeletons).fill(<Skeleton {...defaultProps} />)}
        </ListContainer>
      )
    }
    return <Skeleton {...defaultProps} />
  }
  return children
}

export default SkeletonContainer
