import React from 'react'
import { View, Text } from 'react-native'
import styled from 'styled-components/native'
import { MenuOption } from 'react-native-popup-menu'

import OptionsRow from './OptionsRow'

const IconContainer = styled(View)`
  width: 48px;
  height: 48px;
  margin: 11px;
  margin-left: 0;
  align-items: center;
  justify-content: center;
`

const IconContainerWeb = styled(IconContainer)`
  width: 36px;
  height: 16px;
  margin: 5px 6px 5px 0px;
  margin-left: 0;
  transform: scale(0.65);

  ${props =>
    props.alignTop &&
    `
    align-self: flex-start;
  `}
`

const TextContainer = styled(View)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const OptionText = styled(Text)`
  font-size: 13px;
  color: ${({ theme, labelColor }) => labelColor || theme.colors.text.primary};
`

const CaptionText = styled(Text)`
  font-size: ${({ theme, fullScreenWebDeappify }) =>
    theme.isFullScreenWeb && fullScreenWebDeappify
      ? theme.fontSizes[1]
      : theme.fontSizes[2]}px;
  line-height: ${({ theme, fullScreenWebDeappify }) =>
    theme.isFullScreenWeb && fullScreenWebDeappify
      ? theme.lineHeights[1]
      : theme.lineHeights[4]};
`

const MenuOptions = ({ options = [] }: IMenuOptionsProps) => {
  const IconContainerComponent = IconContainerWeb
  return (
    <>
      {options.map((option, index) => (
        <MenuOption
          {...option.customProps}
          onSelect={option.handleClick}
          key={index}
        >
          <OptionsRow>
            {option.icon ? (
              <IconContainerComponent alignTop={option.subLabel}>
                {option.icon}
              </IconContainerComponent>
            ) : null}
            <TextContainer>
              <OptionText {...(option.labelProps || {})}>
                {option.label}
              </OptionText>
              {option.subLabel && (
                <CaptionText {...(option.labelProps || {})}>
                  {option.subLabel}
                </CaptionText>
              )}
            </TextContainer>
          </OptionsRow>
        </MenuOption>
      ))}
    </>
  )
}

export interface IMenuOption {
  customProps?: any
  labelProps?: any
  labelColor?: string
  label: string
  subLabel?: string
  icon?: Element | JSX.Element
  handleClick: () => void
}

export interface IMenuOptionsProps {
  options?: IMenuOption[]
}

export default MenuOptions
