import React, { FC, useCallback, useState } from 'react'

import { TouchableOpacity, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import RemovePopup from '../../common/Dialog/RemovePopup'
import { Container } from '../../common/SharedStyledComponents'

import { Text } from '../../common/Text'
import CloseIcon from '../../icon/CloseIcon'
import DateService from '../../../services/dateService'

import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import useTranslation from '../../../hooks/useTranslation'
import useFeatureFlag from '../../../hooks/useFeatureFlag'

import { DateTime } from '../../../types'
import { Answer } from '../../../types/form'

import useIsInBreakpoint from '../../../hooks/useIsInBreakpoint'
import { Breakpoint } from '../../../types/breakpoint'
import { QuestionTypeEnum, Question, QuestionGroup } from '../types'
// Services
import {
  containsNumbers,
  getCurrencyFormat,
  formatFloatsToCommas
} from '../../../services/currencyService'
import TouchableIcon from '../../icon/TouchableIcon'
import FormComponent from './FormComponent'

export const Label = styled(Text)`
  ${({ theme, styles }) => `
    color: ${styles?.color ? styles.color : theme.colors.subTitle};
    margin-left: ${theme.space[0]}px;
    margin-bottom: ${theme.space[1]}px;
    position: relative;
`}
`
const formatAnswerByQuestionType = (
  answer: string,
  questionType: QuestionTypeEnum
): string => {
  if (questionType === QuestionTypeEnum.Integer) {
    return getCurrencyFormat(Number(answer))
  } else if (
    answer &&
    questionType === QuestionTypeEnum.TextInput &&
    containsNumbers(answer)
  ) {
    return formatFloatsToCommas(String(answer))
  }
  return answer
}

interface DynamicItemsBlockProps {
  children: JSX.Element | JSX.Element[] | null | Boolean
  questions: Question[]
  formSubmissionId: string
  questionGroup: QuestionGroup
  answers?: Answer[]
  onRemoveAnswer?: (answerId: string) => Promise<any>
  isReadOnly?: boolean
  asPrintable?: boolean
}

interface ItemProps {
  children: JSX.Element | JSX.Element[] | null | Boolean
  data: Answer
  questions: Question[]
  questionGroup: QuestionGroup
  formSubmissionId: string
  i: number
  isReadOnly: boolean
  onRemoveAnswer: ((answerId: string) => Promise<any>) | undefined
  answers: Answer[]
  asPrintable?: boolean
}

const Item = (props: ItemProps) => {
  const {
    asPrintable,
    isReadOnly,
    onRemoveAnswer,
    data,
    answers,
    questions,
    formSubmissionId,
    questionGroup,
    children,
    i
  } = props
  const { colors, space } = useTheme()
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const innovationDynamicQuestionsEditForm = useFeatureFlag(
    'innovationDynamicQuestionsEditForm'
  )
  const isSmallScreen = useIsInBreakpoint(
    Breakpoint.Tablet,
    Breakpoint.Desktop,
    Breakpoint.Phone
  )
  const { trackWithProperties } = useMixpanel()

  const [popupVisibility, setPopupVisibility] = useState({
    isVisible: false,
    index: -1
  })

  const onPopupToggle = useCallback(
    index => () => {
      setPopupVisibility(({ isVisible }) => ({
        index,
        isVisible: !isVisible
      }))
    },
    [setPopupVisibility]
  )

  const onRemoveItem = useCallback(
    i => () => {
      setPopupVisibility(({ isVisible }) => ({
        index: -1,
        isVisible: !isVisible
      }))

      if (onRemoveAnswer && answers) {
        onRemoveAnswer(answers[i].id)
      }

      trackWithProperties(EVENT_MAP.click.button, {
        category: 'customers',
        action: 'delete'
      })
    },
    [answers, setPopupVisibility]
  )

  const [title, subTitle, ...info] = Object.values(data.value).map(
    (text, j) => ({
      question: questions[j]?.questionText,
      questionType: questions[j]?.type as QuestionTypeEnum,
      answer:
        questions[j]?.type === 'dateInput'
          ? DateService.getFormat(text as DateTime)
          : (text as string)
    })
  )

  return (
    <Container
      key={`answer-${data.id}`}
      flexDirection="column"
      flexWrap="wrap"
      minWidth="100%"
      backgroundColor={asPrintable ? null : colors.formCard}
      borderColor={asPrintable ? colors.tints.grays.g100 : colors.formCard}
      marginBottom={16}
      zIndex={answers.length - i}
    >
      {isEditMode ? (
        <FormComponent
          formSubmissionId={formSubmissionId}
          questionGroup={questionGroup}
          questions={questions}
          answers={answers}
          currentAnswer={data}
          handleCloseForm={() => setIsEditMode(false)}
        >
          {children}
        </FormComponent>
      ) : (
        <>
          <View
            style={{
              marginBottom: space[3],
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              ...(isSmallScreen
                ? { flexDirection: 'column' }
                : {
                    flexDirection: 'row'
                  })
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Label>{title.question}</Label>
              <Text styles={{ fontWeight: 'bold' }}>{title.answer}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: innovationDynamicQuestionsEditForm
                  ? space[4]
                  : space[1],
                flex: 1,
                ...(isSmallScreen ? { marginTop: space[3] } : {})
              }}
            >
              <Label>{subTitle?.question}</Label>
              <Text>{subTitle?.answer}</Text>
            </View>
            {!isReadOnly && (
              <TouchableOpacity
                onPress={onPopupToggle(i)}
                style={{
                  position: 'absolute',
                  flexDirection: 'row',
                  top: 0,
                  right: 0
                }}
              >
                {innovationDynamicQuestionsEditForm ? (
                  <TouchableIcon
                    width={20}
                    height={20}
                    disabled={false}
                    name="editUser"
                    color={colors.primary}
                    style={{ alignSelf: 'center' }}
                    onPress={() => setIsEditMode(true)}
                  />
                ) : null}
                <CloseIcon
                  color={colors.darkIcon}
                  style={{ width: space[3], height: space[3] }}
                />
              </TouchableOpacity>
            )}
          </View>
          {info.map(({ question, answer, questionType }, i) => (
            <View
              key={`info-${i}-${data.id}`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: i < info.length - 1 ? space[3] : space[0]
              }}
            >
              <Label>{question}</Label>
              <Text styles={{ color: colors.subTitleDescription }}>
                {formatAnswerByQuestionType(answer, questionType)}
              </Text>
            </View>
          ))}
          {onRemoveItem &&
            popupVisibility.isVisible &&
            popupVisibility.index === i && (
              <RemovePopup
                title={`${t('product:general:delete')} ${title.answer}`}
                onPrimaryClick={onRemoveItem(i)}
                onSecondaryClick={onPopupToggle(i)}
                styles={{
                  position: 'absolute',
                  top: space[3],
                  right: space[4]
                }}
              />
            )}
        </>
      )}
    </Container>
  )
}

const DynamicItemsBlock: FC<DynamicItemsBlockProps> = ({
  questions,
  formSubmissionId,
  questionGroup,
  answers = [],
  onRemoveAnswer,
  isReadOnly = false,
  asPrintable = false,
  children
}) => {
  const { space } = useTheme()

  return (
    <View style={{ marginTop: asPrintable ? space[1] : space[3] }}>
      {answers.map((data, i) => (
        <Item
          children={children}
          data={data}
          i={i}
          answers={answers}
          isReadOnly={isReadOnly}
          onRemoveAnswer={onRemoveAnswer}
          questions={questions}
          asPrintable={asPrintable}
          formSubmissionId={formSubmissionId}
          questionGroup={questionGroup}
        />
      ))}
    </View>
  )
}

export default DynamicItemsBlock
