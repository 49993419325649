import { atomFamily } from 'recoil'
import { FormQuestionConditions } from '../types/form'

export const formQuestionsConditionsAtomFamily = atomFamily<
  FormQuestionConditions | null,
  string
>({
  key: 'formQuestionsConditionsAtomFamily',
  default: null
})
