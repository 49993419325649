import React from 'react'
import { Image, Platform } from 'react-native'
import idToCloudinaryUrl from '../utils/idToCloudinaryUrl'

const CloudinaryImage = ({ publicId, transforms, ...rest }: any) => {
  const url = idToCloudinaryUrl(publicId, {
    ...transforms,
    quality: 'auto:eco',
    fetchFormat: Platform.OS === 'web' ? 'auto' : 'jpg'
  })

  return <Image source={{ uri: url }} {...rest} />
}

export default CloudinaryImage
