export const removeNonNumeric = (value: string) => value?.replace(/[^0-9]/g, '')

const changeText = (
  key: string,
  value: string | boolean,
  formikSetValue: Function
) => {
  return formikSetValue(key, value)
}

export default changeText
