import React, { useCallback, useState } from 'react'

import styled, { useTheme } from 'styled-components/native'

import useTranslation from '../../../hooks/useTranslation'

import Button from '../../../ui-library/Button'
import { Flex } from '../../FlexBox'

import useQuestionQuery from '../../../screens/Product/hooks/useQuestionQuery'
import useDeleteAnswerMutation from '../../../hooks/useDeleteAnswerMutation'
import DynamicItemsBlock from './DynamicItemsBlock'
import { Container } from '../../common/SharedStyledComponents'
import { parseJson } from '../../../utils/parseJson'
import Label from '../../../ui-library/TextInput/Label'
import FormComponent from './FormComponent'
import { Question, QuestionGroup } from '../types'

const DynamicBlockContainer = styled(Flex).attrs(() => ({
  minWidth: '100%',
  maxWidth: '100%'
}))`
  z-index: -${({ groupIndex }) => groupIndex || '1'};
`

interface DynamicBlockProps {
  children: JSX.Element | JSX.Element[] | null | Boolean
  questionGroup: QuestionGroup
  questions: Question[]
  isReadOnly?: boolean
  asPrintable?: boolean
  formSubmissionId: string
  groupIndex: number
  testID?: string
}

const DynamicBlock = ({
  children,
  questionGroup,
  questions,
  isReadOnly = false,
  asPrintable = false,
  formSubmissionId,
  groupIndex = 0
}: DynamicBlockProps) => {
  const { t } = useTranslation()
  const [deleteAnswer] = useDeleteAnswerMutation()
  const { answers, loading: loadingAnswers } = useQuestionQuery(
    questions[0]?.id,
    formSubmissionId
  )
  const [isFormCollapsed, setIsFormCollapsed] = useState(true)
  const theme = useTheme()
  const { colors, space } = theme
  const { btnStyles } = getStyles(theme)
  const questionGroupEntityMaps = questionGroup.questionGroupEntityMaps[0]
  const question = questionGroupEntityMaps.entity as Question
  const configData = question?.configData
    ? parseJson(question.configData)
    : undefined

  const onDisplayForm = useCallback(() => {
    setIsFormCollapsed(false)
  }, [setIsFormCollapsed])

  const onRemoveAnswer = useCallback(async id => {
    await deleteAnswer({
      variables: {
        id
      },
      refetchQueries: ['question']
    })
  }, [])

  const handleCloseForm = () => {
    setIsFormCollapsed(true)
  }

  const testID =
    question.questionText === 'Additional Contact'
      ? 'additionalPOC'
      : question.questionText === 'Name & Company of Corporate Partner'
      ? 'corpRef'
      : question.questionText === 'Name of Individual'
      ? 'indivRef'
      : question.questionText === 'Publication Date'
      ? 'publication'
      : question.questionText === 'Since'
      ? 'clientsSubform'
      : question.questionText === 'Round Date'
      ? 'fundingSubform'
      : question.questionText === 'Competitor Name'
      ? 'competitorsSubform'
      : question.questionText === 'Certification Name'
      ? 'certification'
      : question.questionText === 'Country'
      ? 'country'
      : question.questionText === 'Language'
      ? 'language'
      : null

  return (
    <DynamicBlockContainer testID={testID} groupIndex={groupIndex}>
      <DynamicItemsBlock
        children={children}
        questionGroup={questionGroup}
        formSubmissionId={formSubmissionId}
        questions={questions}
        answers={answers}
        onRemoveAnswer={onRemoveAnswer}
        isReadOnly={isReadOnly}
        asPrintable={asPrintable}
      />
      {!isReadOnly && configData && configData?.showLabel && (
        <Label
          label={questionGroup?.configData?.blockLabel || question.questionText}
        />
      )}
      {!isReadOnly && (
        <Container
          flexDirection="column"
          flexWrap="wrap"
          minWidth="100%"
          backgroundColor={colors.formCard}
          borderColor={colors.formCard}
        >
          {!isFormCollapsed && (
            <>
              <FormComponent
                formSubmissionId={formSubmissionId}
                questionGroup={questionGroup}
                questions={questions}
                answers={loadingAnswers ? [] : answers}
                handleCloseForm={handleCloseForm}
              >
                {children}
              </FormComponent>
            </>
          )}
          {!isReadOnly && isFormCollapsed && (
            <Button
              title={t('product:general:addNew')}
              buttonStyle={btnStyles}
              containerStyle={{ height: space[4] }}
              disabled={isReadOnly}
              onPress={onDisplayForm}
            />
          )}
        </Container>
      )}
    </DynamicBlockContainer>
  )
}

const getStyles = theme => ({
  btnContainerStyles: { height: theme.space[4], marginTop: theme.space[3] },
  btnStyles: {
    width: 'fit-content',
    paddingBottom: theme.space[3],
    paddingTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[2],
    paddingRight: theme.space[4] - theme.space[2],
    height: theme.space[4],
    paddingHorizontal: theme.space[4]
  }
})

export default DynamicBlock
