import React from 'react'

import { H3, H4, Text } from '../../../../components/common/Text'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

export const FormHeader = ({ title }) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <H4
      styles={{ marginBottom: 5, fontWeight: '300' }}
      h4Style={{ fontSize: isSmallScreen ? '20px' : '27px' }}
    >
      {title}
    </H4>
  )
}
interface PageHeaderProps {
  title: string
  description?: string
  marginTop?: number
}
const PageHeader = ({ title, description, marginTop }: PageHeaderProps) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <>
      <H3
        styles={{
          textAlign: 'center',
          marginBottom: 30,
          fontWeight: '300',
          marginTop: marginTop
        }}
        h3Style={{ fontSize: isSmallScreen ? '29px' : '36px' }}
      >
        {title}
      </H3>
      {description ? (
        <Text
          styles={{
            marginBottom: 50,
            textAlign: 'center',
            fontSize: '18px'
          }}
        >
          {description}
        </Text>
      ) : null}
    </>
  )
}

export default PageHeader
