import React, { useMemo, useState } from 'react'
import useTranslation from '../../../../hooks/useTranslation'
import { DynamicTableFiltersProps, FilterComponentProps } from './constants'
import AutoCompleteDropdown from '../AutoCompleteDropdown'
import { filterType, autoCompleteDropwdownStyle } from '../constants'
import { DropdownOption } from '../../../common/Dropdown'
import useFilterOptions from '../../hooks/useFilterOptions'
import {
  DynamicFilterContainer,
  FilterTitleContainer
} from './SharedStyledComponents'
import { CallForSubmissionStatusEnum } from '../../../../types'
import {
  TablesEnum,
  statusMap,
  statusMapAdvise
} from '../../../../screens/CallForSubmission/constants'
import { StyledLabel } from '../../../../ui-library/TextInput/Label'

const FilterHookComponent = ({
  filter,
  queryFilter,
  header,
  handleChangeFilters
}: FilterComponentProps) => {
  const filterOptions: DropdownOption[] =
    useFilterOptions(filter?.isHook && filter) || []

  return (
    filter && (
      <AutoCompleteDropdown
        isMulti={filter.isMulti}
        options={filterOptions}
        placeholder={header}
        value={queryFilter[filter.key]}
        onSelectValue={value =>
          handleChangeFilters({ ...queryFilter, [filter.key]: value })
        }
        styles={autoCompleteDropwdownStyle}
      />
    )
  )
}

const FilterComponent = ({
  filter,
  queryFilter,
  header,
  handleChangeFilters,
  value
}: FilterComponentProps) => {
  const { isHook } = filter

  return (
    filter &&
    (isHook ? (
      <FilterHookComponent
        filter={filter}
        queryFilter={queryFilter}
        header={header}
        handleChangeFilters={handleChangeFilters}
      />
    ) : (
      filter && (
        <AutoCompleteDropdown
          options={filter.options ?? []}
          placeholder={header}
          value={value ?? queryFilter[filter.key]}
          onSelectValue={handleChangeFilters}
          styles={autoCompleteDropwdownStyle}
        />
      )
    ))
  )
}

function DynamicTableFilters<T>({
  enableFilters,
  handleChangeFilters,
  queryFilter,
  tableColumns,
  showStatusFilter,
  isAlternativeView,
  tableId
}: DynamicTableFiltersProps<T>) {
  const { t } = useTranslation()
  const [status, setStatus] = useState(CallForSubmissionStatusEnum.open)

  const statusOptions = [
    {
      label: t('callsForSubmission:status:open'),
      value: CallForSubmissionStatusEnum.open
    },
    {
      label: t('callsForSubmission:status:upcoming'),
      value: CallForSubmissionStatusEnum.upcoming
    },
    {
      label: t('callsForSubmission:status:closed'),
      value: CallForSubmissionStatusEnum.closed
    }
  ]

  const hasFilters = useMemo(() => {
    const filitersSum =
      tableColumns.reduce(
        (acum, col) =>
          acum + (col?.filter && col?.filter?.type === 'filter' ? 1 : 0),
        0
      ) + (showStatusFilter ? 1 : 0)
    return filitersSum > 0
  }, [tableColumns])

  const columnsWithFiltersCount = tableColumns.filter(
    c => !!c.filter && c.filter.type === 'filter'
  ).length

  const statusFilter = {
    key: 'statusFilterId',
    type: 'filter' as filterType,
    options: statusOptions
  }

  if (!enableFilters) {
    return <></>
  }

  return (
    <DynamicFilterContainer
      hasFilters={hasFilters}
      isAlternativeView={isAlternativeView}
    >
      {!!columnsWithFiltersCount && (
        <FilterTitleContainer>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
        </FilterTitleContainer>
      )}
      {showStatusFilter && (
        <FilterComponent
          filter={statusFilter}
          value={status}
          queryFilter={queryFilter}
          header={t('callsForSubmission:filter:status')}
          handleChangeFilters={value => {
            setStatus(value)
            handleChangeFilters({
              ...queryFilter,
              ...(tableId === TablesEnum.Advise
                ? statusMapAdvise[value]
                : statusMap[value])
            })
          }}
        />
      )}
      {tableColumns.map((col, index) =>
        col.filter ? (
          col.filter.type === 'filter' ? (
            <FilterComponent
              key={`dynamicTable-filter-${index}`}
              filter={col.filter}
              queryFilter={queryFilter}
              header={t(col.header)}
              handleChangeFilters={handleChangeFilters}
            />
          ) : null
        ) : null
      )}
    </DynamicFilterContainer>
  )
}

export default DynamicTableFilters
