import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const createAlumniMembershipMutation = gql`
  mutation createAlumniMembership($companyId: String!) {
    createAlumniMembership(companyId: $companyId) {
      id
    }
  }
`

const useCreateAlumniMembershipMutation = (options = {}) => {
  const [
    createAlumniMembership,
    { loading: loadingCreateAlumniMembership }
  ]: any[] = useMutation(createAlumniMembershipMutation, options)

  return [createAlumniMembership, loadingCreateAlumniMembership]
}

export default useCreateAlumniMembershipMutation
