import React, { ComponentType } from 'react'
import { InnovationAccessKey } from '../types'
import useLinkToScreen from '../hooks/useLinkToScreen'
import useCurrentUser from '../hooks/useCurrentUser'

function withNavigationPermissions<Props>(
  Component: ComponentType<Props>,
  requiredAccess: InnovationAccessKey[],
  routeName = 'Home'
) {
  return (props: Props) => {
    const { currentUser } = useCurrentUser()
    const linkToScreen = useLinkToScreen()

    // JC: We need to check that the user has at least one role as this is happening before the currentUser
    // query finishes. Otherwise we would always redirect to `routeName` on deepLink
    if (currentUser?.innovationAccess) {
      const hasPermission = requiredAccess.find(
        key => !!currentUser.innovationAccess[key]
      )
      if (!hasPermission) {
        console.info(`
          You don't have permissions for this screen.
          Needed permissions are: [${requiredAccess.join(', ')}]
        `)
        linkToScreen(routeName)
        return null
      }

      return <Component {...(props as Props)} />
    }

    return null
  }
}

export default withNavigationPermissions
