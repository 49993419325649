import React from 'react'
import styled from 'styled-components/native'
// Components/Types
import {
  Col,
  RowSkeleton,
  SkeletonDivider,
  Row
} from '../../../components/skeletonLoadings/components'
import { TableRow } from '../../CallForSubmission/CallForSubmissionsStaffSkeleton'
import { SkeletonTemplateProps } from '../../../components/skeletonLoadings/types'
import { BorderCard } from '../../Submission/components/skeletons/components'

// Styled Components
const Container = styled(Col)`
  height: auto;
  min-width: 250px;
  width: 100%;
  padding: 0 15px;
`
const Column = styled(Col)`
  ${({ theme: { space, sizes } }) => `
    min-height: ${sizes[4]}px;
    padding-horizontal: ${space[3]}px;
  `}
`
const OuterBorderCard = styled(BorderCard)`
  height: auto;
  justify-content: space-between;
  ${({ theme: { space, colors } }) => `
    margin-bottom: 0;
    padding-vertical: ${space[2]}px;
    padding-horizontal: ${space[2]}px;
    background: ${colors.lightGrey}
  `}
`
const SkeletonText = styled(RowSkeleton)`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[2]}px;
  `}
`

export const SkeletonTitle = styled(RowSkeleton)`
  ${({ theme: { spaces, sizes } }) => `
  padding-vertical: ${spaces[4]}px;
    margin-bottom: ${sizes[2]}px;
  `}
`

const Skeleton = React.memo((props: SkeletonTemplateProps) => {
  const {
    theme: { sizes }
  } = props
  return (
    <>
      <Row paddingTop="10" paddingRight="10" paddingLeft="10">
        <Col width="80%">
          <SkeletonText width="50%" height={sizes[4] - sizes[1]} />
        </Col>
        <Col width="20%" alignItems="end">
          <SkeletonText width="100%" height={sizes[4] - sizes[1]} />
        </Col>
      </Row>
      <SkeletonDivider marginTop="5" />
      <Container marginTop={sizes[5] - sizes[1]}>
        <OuterBorderCard showBadge={false}>
          <Column>
            <SkeletonText
              width="20%"
              height={sizes[4] + sizes[1]}
              style={{ background: 'white', marginBottom: 0 }}
            />
          </Column>
        </OuterBorderCard>
      </Container>
      <Row paddingLeft={15} paddingRight={15}>
        <Col marginTop={10} width="100%">
          <TableRow isHeader {...props} />
          {Array.from(Array(4), (_, index) => (
            <TableRow key={`key_tableRow_${index}`} index={index} {...props} />
          ))}
        </Col>
      </Row>
    </>
  )
})

export default Skeleton
