import React from 'react'
import { ErrorText } from '../../../authentication/components/SharedStyledComponents'
import useTranslation from '../../../../hooks/useTranslation'
import { useField } from 'formik'
import { View } from 'react-native'
import Dropdown, {
  DropdownOption
} from '../../../../components/common/Dropdown'

const DropdownField = ({
  fieldName,
  isReadOnly,
  type,
  options
}: {
  fieldName: string
  isReadOnly: boolean
  type: string
  options: DropdownOption[]
}) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField({ name: fieldName })

  return (
    <View style={{ marginTop: 5 }}>
      <Dropdown
        label={t(
          `platformManagement:forms:menuItems:${type}:fields:${field.name}`
        )}
        options={options}
        isDisabled={isReadOnly}
        style={{
          minHeight: 0,
          marginBottom: 0
        }}
        containerStyle={{ width: 350, height: 50, marginRight: 10 }}
        controlStyle={{ minHeight: 0 }}
        isClearable
        isFilter
        closeMenuOnSelect
        value={field.value}
        menuPortalTarget={document?.body}
        onBlur={() => {
          helpers.setTouched(true)
        }}
        onSelect={value => {
          helpers.setValue(value)
        }}
        name={field.name}
        placeholder={t(
          `platformManagement:forms:menuItems:${type}:fields:${field.name}Placeholder`
        )}
      />

      {meta.error && meta.touched ? <ErrorText>{meta.error}</ErrorText> : null}
    </View>
  )
}

export default DropdownField
