import { InnovationTagTypeEnum } from '../common/types'
import { StepsObject, TopicObject } from '../types'

export interface StepProps {
  step: StepsObject
  color: string
  opacity: number
  total: number
  handleClick: (step: string) => void
  average?: number
}

export interface TopicProps {
  index: number
  topic: TopicObject
  handleSelectTrend: (trend: string, type: InnovationTagTypeEnum) => void
  selectedTrends: string[]
}

export enum TopQuestionsEnum {
  Capability = 'Capability',
  Category = 'Category',
  Country = 'Country',
  Language = 'Language',
  UserBase = 'UserBase',
  Funding = 'Funding'
}
