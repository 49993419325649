import React, { useCallback, useEffect, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import download from 'downloadjs'
import { useTheme } from 'styled-components/native'
import { Text } from '../../components/common/Text'
import Icon from '../../components/icon'

// Hooks
import useCurrentUser from '../../hooks/useCurrentUser'
import useDownloadFiles from '../../hooks/useDownloadFiles'
import useDownloadStatus from '../../hooks/useDownloadStatus'
import useTranslation from '../../hooks/useTranslation'
import useGetInnovationSubmissionsByIds from '../../hooks/useGetInnovationSubmissionsByIds'
import { DownloadProps } from '../../recoil/currentDownloadStatusAtom'
import Button from '../../ui-library/Button'
import FileStatus from './FileStatus'
import {
  DownloadStatusActivityIndicator,
  DownloadStatusCloseContainer,
  DownloadStatusContainer,
  DownloadStatusContentView,
  DownloadStatusTouchableOpacity,
  FilesContainer
} from './styledComponents'
import { Platform } from 'react-native'

import Sentry from '../../utils/sentry'

const ATTEMPTS_LIMIT = 2
const ATTEMPT_TIMEOUT = 15000

const DownloadStatus = () => {
  const [error, setError] = useState(false)
  const timer = React.useRef<NodeJS.Timeout>()
  const [hasDownloadableFiles, setHasDownloadableFiles] = useState(true)
  const [mergedPDF, setMergePDF] = useState<PDFDocument | undefined>()
  const { t } = useTranslation()
  const { sizes, space, colors } = useTheme()
  const { currentUserId } = useCurrentUser()
  const { getSubmissionByIds } = useGetInnovationSubmissionsByIds()
  const cuId = currentUserId ? (currentUserId as string) : ''
  const {
    attempts,
    isVisible,
    isLoading = true,
    downloadLink,
    setCurrentDownloadStatus,
    handleSubscription,
    handleGetLink,
    setAttempts,
    getLinkDataKey,
    fileName
  } = useDownloadStatus(cuId)

  const {
    files,
    isLoadingFiles,
    isRefreshingFiles,
    setIsLoadingFiles,
    setIsRefreshingFiles,
    setCurrentDownloadFiles
  } = useDownloadFiles(cuId)

  const refreshFilesCallback = useCallback(async () => {
    if (attempts !== undefined && attempts >= ATTEMPTS_LIMIT) {
      setCurrentDownloadStatus({
        isVisible: true,
        isLoading: false,
        downloadLink: '',
        fileName
      } as DownloadProps)
      const sortedFiles = [...files].sort((a, b) =>
        a.isLoading === b.isLoading ? 0 : a.isLoading ? -1 : 1
      )
      setCurrentDownloadFiles({
        files: sortedFiles,
        isLoadingFiles: false,
        isRefreshingFiles: false
      })
      return
    }
    setIsRefreshingFiles(true)
    let newDownloadStatusState: any = {
      files
    }
    try {
      const updatedSubmissions = await getSubmissionByIds({
        variables: {
          submissionIds: files?.map(file => file.id)
        }
      })

      if (updatedSubmissions?.data?.getInnovationSubmissionsByIds?.length) {
        const {
          data: { getInnovationSubmissionsByIds }
        } = updatedSubmissions
        const updatedObject = {}
        for (const item of getInnovationSubmissionsByIds) {
          updatedObject[item.id] = item
        }

        let newFiles: any[] = []
        for (const file of files) {
          let currentFile = { ...file }
          if (updatedObject?.[currentFile.id] && currentFile.isLoading) {
            const pdfCloudinaryId =
              updatedObject?.[currentFile.id]?.pdfCloudinaryId

            currentFile.cloudinaryId = pdfCloudinaryId
            currentFile.isLoading = !pdfCloudinaryId?.length
          }
          newFiles.push(currentFile)
        }
        newDownloadStatusState = { files: newFiles }
      }
    } catch (error) {
      Sentry.captureException(
        new Error(`Error while merging PDSs. error: ${JSON.stringify(error)}`)
      )
    } finally {
      setCurrentDownloadFiles({
        ...newDownloadStatusState,
        isLoadingFiles: true,
        isRefreshingFiles: false
      })
      if (attempts !== undefined && attempts < ATTEMPTS_LIMIT) {
        setAttempts(attempts + 1)
      }
    }
  }, [files, attempts])

  useEffect(() => {
    if (!isLoading && !downloadLink) {
      setError(true)
    } else {
      setError(false)
    }
  }, [isLoading, downloadLink])

  useEffect(() => {
    if (files.length > 0 && !isRefreshingFiles) {
      const filesToDownload = files?.filter(
        file => file?.isDownloadable || file?.id
      )
      if (filesToDownload.length === 0) {
        setHasDownloadableFiles(false)
        setIsLoadingFiles(false)
      } else {
        const findLoadingFileIndex =
          filesToDownload?.findIndex(file => file.isLoading) ?? -1
        const isLoadingFiles = findLoadingFileIndex > -1

        setHasDownloadableFiles(true)
        setIsLoadingFiles(isLoadingFiles)
      }
    }
  }, [files, isRefreshingFiles])

  useEffect(() => {
    if (
      files.length > 0 &&
      isLoadingFiles &&
      attempts !== undefined &&
      attempts >= 0
    ) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        refreshFilesCallback()
      }, ATTEMPT_TIMEOUT)
    }
  }, [files, isLoadingFiles, attempts])

  const mergePdfs = async (cloudinaryUrls = []): Promise<boolean> => {
    try {
      const pdfsPromises = cloudinaryUrls.map(async url => {
        const pdfBytes = await fetch(url).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(pdfBytes)

        return pdfDoc
      })

      const pdfs = await Promise.all(pdfsPromises)
      const doc = await PDFDocument.create()

      for (let i = 0; i < pdfs.length; i++) {
        const contentCurrentPDF = await doc.copyPages(
          pdfs[i],
          pdfs[i].getPageIndices()
        )

        for (const page of contentCurrentPDF) {
          doc.addPage(page)
        }
      }
      setMergePDF(doc)
      return true
    } catch (error) {
      Sentry.captureException(
        new Error(`Error while merging PDSs. error: ${JSON.stringify(error)}`)
      )
      return false
    }
  }

  useEffect(() => {
    const handleIsLoadingAllFiles = async () => {
      if (
        !isLoadingFiles &&
        handleGetLink &&
        files.every(file => file?.cloudinaryId?.length)
      ) {
        clearTimeout(timer.current)
        const hasErrors = files?.filter(file => file.error).length > 0
        const filesToDownload = files?.filter(
          file => file?.isDownloadable || file?.id
        )
        if (filesToDownload.length > 0 && !hasErrors) {
          const data = await handleGetLink(
            filesToDownload?.map(file => file?.cloudinaryId)
          )

          if (data && getLinkDataKey && data?.[getLinkDataKey]) {
            const result = await mergePdfs(data[getLinkDataKey]?.cloudinaryUrls)
            let downloadLink = ''
            if (result) {
              downloadLink = data[getLinkDataKey]?.cloudinaryLink
            }
            setCurrentDownloadStatus({
              isVisible: true,
              isLoading: false,
              downloadLink,
              fileName
            } as DownloadProps)
          }
        } else {
          setCurrentDownloadStatus({
            isVisible: true,
            isLoading: false,
            downloadLink: '',
            fileName
          } as DownloadProps)
        }
      }
    }
    handleIsLoadingAllFiles()
  }, [files, isLoadingFiles, handleGetLink])

  const onClose = () => {
    clearTimeout(timer.current)
    setCurrentDownloadStatus({
      attempts: undefined,
      isVisible: false,
      isLoading: false,
      downloadLink: undefined,
      fileName: undefined
    } as DownloadProps)

    setMergePDF(undefined)
  }

  const buttonStyle = {
    width: sizes[6] - sizes[2],
    height: sizes[4] - sizes[1],
    paddingVertical: 10,
    backgroundColor: colors.background,
    borderColor: '#0C0C0C'
  }

  const titleStyle = {
    color: colors.darkIcon
  }

  if (!isVisible) {
    return null
  }

  return (
    <DownloadStatusContainer>
      <DownloadStatusCloseContainer>
        <DownloadStatusTouchableOpacity onPress={onClose}>
          <Icon name="close" color="#000000" width={15} height={15} />
        </DownloadStatusTouchableOpacity>
      </DownloadStatusCloseContainer>
      <DownloadStatusContentView>
        {isLoading ? (
          <DownloadStatusActivityIndicator />
        ) : hasDownloadableFiles ? (
          <Icon
            name={error || !hasDownloadableFiles ? 'errorIcon' : 'fileReady'}
            color={error || !hasDownloadableFiles ? 'red' : '#00C389'}
            style={{ marginRight: '10px' }}
          />
        ) : null}

        <Text
          styles={{
            fontSize: space[1] + space[2],
            color: '#000000',
            marginRight: '30px'
          }}
        >
          {t(
            isRefreshingFiles
              ? 'common:download:refreshing'
              : isLoading
              ? 'common:download:preparingPDF'
              : !hasDownloadableFiles
              ? 'common:download:noFilesToDownload'
              : error
              ? 'common:download:error'
              : 'common:download:fileReady'
          )}
        </Text>
        {!isRefreshingFiles && !isLoading && !error && hasDownloadableFiles ? (
          <Button
            disabled={false}
            title={t('common:download:download')}
            buttonStyle={buttonStyle}
            titleStyle={titleStyle}
            onPress={async () => {
              if (Platform.OS === 'web' && mergedPDF) {
                // Serialize the PDFDocument to bytes (a Uint8Array)
                // @ts-ignore
                const pdfBytes = await mergedPDF.save()

                // Trigger the browser to download the PDF document
                // @ts-ignore
                download(pdfBytes, `${fileName}.pdf`, 'application/pdf')
              }
            }}
          />
        ) : null}
      </DownloadStatusContentView>
      {!isRefreshingFiles && files.length > 0 ? (
        <FilesContainer>
          {files.map(file => (
            <FileStatus
              key={`file-${file.id}`}
              handleSubscription={handleSubscription}
              file={file}
            />
          ))}
        </FilesContainer>
      ) : null}
    </DownloadStatusContainer>
  )
}

export default DownloadStatus
