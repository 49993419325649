import { useRecoilState, SetterOrUpdater } from 'recoil'
import { pick } from 'ramda'
import currentQuestionAtom, {
  QuestionState
} from '../recoil/currentQuestionAtom'

export interface CurrentQuestionHelpers extends QuestionState {
  setCurrentQuestion: SetterOrUpdater<QuestionState>
}

const useCurrentQuestion = (questionId: string): CurrentQuestionHelpers => {
  const [questionState, setCurrentQuestion] = useRecoilState(
    currentQuestionAtom(questionId)
  ) as [QuestionState, SetterOrUpdater<QuestionState>]
  return {
    ...pick(
      ['currentQuestion', 'currentAttachments', 'answers'],
      questionState || {}
    ),
    setCurrentQuestion
  }
}

export default useCurrentQuestion
