import { atomFamily } from 'recoil'
import { FormSubmissionMetaData } from '../types'

export const formSubmissionMetaDataAtomFamily = atomFamily<
  FormSubmissionMetaData | null,
  string
>({
  key: 'formSubmissionMetaDataState',
  default: null
})
