import React, { ReactNode } from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import { useDimensions } from '@react-native-community/hooks'
import { CheckBox as ElementsCheckBox } from 'react-native-elements'
// Components
import Icon from '../../components/icon'
import Theme from '../../constants/Theme'
import { Flex } from '../../components/FlexBox'

const SMALLER_SCREEN_BREAKPOINT = 800

export interface CheckBoxInputProps {
  label?: string
  checked: boolean
  onPress: () => void
  isReadOnly?: boolean
  isHidden?: boolean
  labelStyles?: any
  containerStyles?: any
  styles?: any
  flexContainerStyles?: any
  showChildren?: boolean
  renderChildrenComponent?: () => ReactNode
  checkedIconName?: string
  checkedColor?: string
  uncheckedColor?: string
  isSelectable?: boolean
  testID?: string
}

const defaultContainerStyles = {
  backgroundColor: 'transparent',
  borderWidth: 0,
  padding: 0,
  margin: 0,
  marginLeft: 0,
  marginRight: 0,
  width: 'auto'
}

const CheckBox = (props: CheckBoxInputProps) => {
  const {
    label,
    checked,
    onPress,
    labelStyles,
    containerStyles = defaultContainerStyles,
    flexContainerStyles = {
      paddingLeft: '10px',
      marginBottom: '0px'
    },
    isReadOnly = false,
    isHidden = false,
    renderChildrenComponent,
    checkedIconName,
    checkedColor = isReadOnly ? Theme.colors.disabled : Theme.colors.text,
    uncheckedColor = isReadOnly ? Theme.colors.disabled : Theme.colors.text,
    isSelectable = false,
    testID
  } = props

  const color = isReadOnly ? Theme.colors.disabled : Theme.colors.inputLabels

  const { height } = useDimensions().window

  const isSmallerScreen = height < SMALLER_SCREEN_BREAKPOINT

  const textStyle = labelStyles || {
    marginLeft: Theme.sizes[1],
    fontSize: isSmallerScreen ? Theme.fontSizes[3] - 1 : Theme.fontSizes[3] + 1,
    fontWeight: 'regular',
    color: color,
    marginRight: 0
  }
  const getIcon = (iconName, color) => (
    <Icon name={iconName} color={color} width={30} height={30} />
  )

  const checkedIcon = checkedIconName
    ? getIcon(checkedIconName, Theme.colors.feedbackInfo)
    : null
  const uncheckedIcon = checkedIconName
    ? getIcon(checkedIconName, '#b5bbbf')
    : null

  const isSelectableStyles = {
    marginTop: '0px',
    paddingTop: '0px',
    marginBottom: '0px',
    paddingBottom: '0px'
  }
  return (
    <View
      style={
        isSelectable && {
          ...isSelectableStyles
        }
      }
    >
      <Flex
        testID={testID}
        flexDirection="row"
        alignItems="center"
        style={{
          ...flexContainerStyles,
          ...(isSelectable
            ? { ...isSelectableStyles }
            : checkedIcon && uncheckedIcon
            ? {
                paddingLeft: '2px',
                marginBottom: '5px'
              }
            : {
                paddingLeft: '10px',
                marginBottom: '5px'
              })
        }}
      >
        {isHidden ? null : checkedIcon && uncheckedIcon ? (
          <ElementsCheckBox
            title={label}
            containerStyle={defaultContainerStyles}
            size={isHidden ? 0 : 20}
            textStyle={textStyle}
            wrapperStyle={{ marginLeft: 0 }}
            checkedIcon={checkedIcon}
            uncheckedIcon={uncheckedIcon}
            checked={checked}
            onPress={isReadOnly ? undefined : onPress}
            Component={isReadOnly ? TouchableWithoutFeedback : undefined}
          />
        ) : (
          <ElementsCheckBox
            title={label}
            containerStyle={containerStyles}
            size={isHidden ? 0 : 20}
            textStyle={textStyle}
            wrapperStyle={{ marginLeft: 0 }}
            checkedColor={checkedColor}
            uncheckedColor={uncheckedColor}
            checked={checked}
            onPress={isReadOnly ? undefined : onPress}
            Component={isReadOnly ? TouchableWithoutFeedback : undefined}
          />
        )}
        {renderChildrenComponent && renderChildrenComponent()}
      </Flex>
    </View>
  )
}

export default CheckBox
