import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const deleteAnswersMutation = gql`
  mutation deleteAnswers($ids: [String]!) {
    deleteAnswers(ids: $ids)
  }
`

const useDeleteAnswersMutation = (options = {}) => {
  const [deleteAnswers, { loading: loadingDeleteAnswers }]: any[] = useMutation(
    deleteAnswersMutation,
    options
  )

  return [deleteAnswers, loadingDeleteAnswers]
}

export default useDeleteAnswersMutation
