import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const registrationToCompanyMutation = gql`
  mutation registration(
    $firstName: String!
    $lastName: String!
    $jobTitle: String!
    $personId: String!
    $email: String!
    $companyId: String!
    $termsId: String!
    $privacyId: String!
    $cookieId: String!
  ) {
    # Mutations execute sequentially, add email and update person first
    email: addEmailAddressToPerson(id: $personId, email: $email) {
      id
    }

    person: upsertPerson(
      id: $personId
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
    ) {
      id
      firstName
      lastName
      jobTitle
    }

    employment: createEmployment(
      personId: $personId
      companyId: $companyId
      title: $jobTitle
    ) {
      id
      startDate
      company {
        id
        name
        companyProfile {
          id
          url
          yearFounded
          funding
          employeeCount
          country {
            id
            name
          }
          domains {
            id
            name
          }
        }
      }
    }

    termsAgreement: createLegalAgreement(legalDocId: $termsId, location: web) {
      id
    }

    privacyAgreement: createLegalAgreement(
      legalDocId: $privacyId
      location: web
    ) {
      id
    }

    cookieAgreement: createLegalAgreement(
      legalDocId: $cookieId
      location: web
    ) {
      id
    }
  }
`

const useRegistrationToCompanyMutation = (options = {}) => {
  const [register, { loading }]: any[] = useMutation(
    registrationToCompanyMutation,
    options
  )

  return [register, loading]
}

export default useRegistrationToCompanyMutation
