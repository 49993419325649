import { Company, RoleEnum, User } from '../../types'

export interface CodeComponentProps {
  currentUser: User
}

export const RolesDict = {
  [RoleEnum.InnovatorTeamAdmin]: 'Admin',
  [RoleEnum.InnovatorTeamMember]: 'Member'
}

export interface UserListProps {
  user: User
  index: number
  i: number
  isPending: boolean
  onEditToggle: (user: User) => any
  canEdit?: boolean
  canDelete?: boolean
  testID?: string
}

export interface InviteUserProps {
  innovationCompany: Company
  containerTestId?: string
}

export interface ListMembersSectionProps {
  innovationCompany: Company
  users?: User[]
  titleKey: string
  emptyLabelKey: string
  style?: Object
  isPending?: boolean
  loading: boolean
  testID?: string
}

export interface UserListContentProps {
  i: number
  user: User
  isPending: boolean
  onPopupToggle: (index: number) => any
  onEditToggle: (user: User) => any
  onResend?: (user: User) => any
  canEdit?: boolean
  canDelete?: boolean
}

export interface UserListTitlesContentProps {
  user: User
}

export interface UserListRemoveDialogProps {
  user: User
  i: number
  onRemoveUser: (userId: string) => any
  onPopupToggle: (index: number) => any
}
