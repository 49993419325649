import gql from 'graphql-tag'

const FormFieldsFragment = gql`
  fragment FormFieldsFragment on Form {
    id
    name
    type
    isActive
    questionGroupId
    __typename
  }
`

export default FormFieldsFragment
