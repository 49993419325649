import React, { useContext } from 'react'

import SearchBar from './SearchBar'
import { MainContainer } from './SharedStyledComponents'
import Table from './Table'
import { TableContext } from '../hooks/TableProvider'
import { TableMessageText } from './Table/SharedStyledComponents'
import { TableNameEnum } from '../types'

const MainTable = (): any => {
  const {
    config: { enableFilters, tableId, showTotalElements, name },
    data = [],
    loading,
    totalElements
  } = useContext(TableContext)

  const isAdvisingTable = name === TableNameEnum.Advising

  return (
    <MainContainer key={tableId}>
      {enableFilters ? <SearchBar /> : null}
      <Table />
      {(!loading && showTotalElements && totalElements) ||
      (!loading && isAdvisingTable && totalElements) ? (
        <TableMessageText
          style={{
            fontStyle: 'italic',
            marginTop: 5,
            textAlign: 'right'
          }}
        >{`${data.length} of ${totalElements}`}</TableMessageText>
      ) : null}
    </MainContainer>
  )
}

export default MainTable
