import React, { useEffect, useMemo, useState } from 'react'

// Hooks
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Modal from './components/Modal'
import { TableContainer } from '../../../../../components/StaticForm'
import { DynamicTable, useTable } from '../../../../../components/Table'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../../../../components/DynamicTable'
// Skeleton
import TableSkeleton from '../../../../HomeScreen/TableSkeleton'
import SkeletonContainer from '../../../../../components/skeletonLoadings/SkeletonContainer'
import { View } from 'react-native'
import { CreateIcon } from '../../../constants'
import { FormValues, QuestionGroupValues, QuestionTypesEnum } from '../../types'
import Dropdown, {
  DropdownOption
} from '../../../../../components/common/Dropdown'
import {
  EntityTypeEnum,
  QuestionGroup
} from '../../../../../components/DynamicForm/types'
import TextInput from '../../../../../ui-library/TextInput'
import useDebounce from '../../../../../hooks/useDebounce'
import { getQuestionGroupEntityMapsTableConfig } from './constants'
import useFormsQuery from '../../../../Product/hooks/useFormsQuery'
import useGetQuestionGroupEntityMaps from '../Forms/hooks/useGetQuestionGroupEntityMaps'

const QuestionGroups = () => {
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentQuestion, setCurrentQuestion] = useState<
    QuestionGroupValues | undefined
  >()
  const [currentFormBlock, setCurrentFormBlock] = useState<
    QuestionGroupValues | undefined
  >()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [
    currentQuestionParentType,
    setCurrentQuestionParentType
  ] = useState<QuestionTypesEnum>(QuestionTypesEnum.Children)

  const { forms, loading: fetchLoadingForms } = useFormsQuery()

  const [currentForm, setCurrentForm] = useState<FormValues | undefined>()

  useEffect(() => {
    if (!fetchLoadingForms && forms.length && !currentForm) {
      setCurrentForm(forms[0])
    }
  }, [fetchLoadingForms, forms, currentForm])

  const {
    loading: fetchLoading,
    questionGroupEntityMaps
  } = useGetQuestionGroupEntityMaps(
    currentQuestionParentType === QuestionTypesEnum.Parent,
    currentForm?.id,
    undefined,
    EntityTypeEnum.QuestionGroup,
    { skip: fetchLoadingForms || !currentForm }
  )

  const currentFormBlocks = useMemo(() => {
    if (
      !fetchLoadingForms &&
      currentForm &&
      !fetchLoading &&
      questionGroupEntityMaps &&
      questionGroupEntityMaps.length
    ) {
      const blocks = questionGroupEntityMaps.filter(
        block => block.questionGroupId === currentForm.questionGroupId
      )
      return blocks
    }
    return []
  }, [fetchLoading, questionGroupEntityMaps, currentForm])

  const questionGroups = useMemo(() => {
    if (
      !fetchLoading &&
      questionGroupEntityMaps &&
      questionGroupEntityMaps.length
    ) {
      let result = questionGroupEntityMaps ?? []
      if (currentFormBlock) {
        result = result.filter(
          item => item.questionGroupId === currentFormBlock.id
        )
      }
      return (result.map(item => item.entity) ?? []) as QuestionGroup[]
    }
    return []
  }, [fetchLoading, currentFormBlock, questionGroupEntityMaps])

  const [search, setSearch] = useState<string | undefined>(undefined)

  const debouncedSearch = useDebounce(search, 300)

  const [data, setData] = useState<QuestionGroup[]>(questionGroups)

  useEffect(() => {
    if ((fetchLoading || fetchLoadingForms) && !isLoading) {
      setIsLoading(true)
    }
  }, [fetchLoading, fetchLoadingForms, isLoading])

  useEffect(() => {
    if (!fetchLoading) {
      let newData: QuestionGroup[] = questionGroups ?? []

      if (debouncedSearch != null) {
        newData = newData.filter(question => {
          const allTerms = [
            ...Object.keys(question),
            ...Object.values(question).map(
              value => String(JSON.stringify(value)) ?? String(value)
            )
          ]
          const includeTerm = allTerms.find(term =>
            term.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
          if (includeTerm) {
            return true
          } else {
            return false
          }
        })
      }
      setData(newData)
      setIsLoading(false)
    }
  }, [questionGroups, fetchLoading, debouncedSearch])

  const handleEditQuestion = (question: QuestionGroupValues) => {
    setCurrentQuestion(question)
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const handleCreateQuestion = () => {
    setCurrentQuestion(undefined)
    setIsOpen(true)
  }

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps = useTable({
    data,
    config: getQuestionGroupEntityMapsTableConfig({
      handleEditQuestion
    })
  })

  const questionParentTypes = Object.keys(QuestionTypesEnum)
  const questionParentTypesValues = Object.values(QuestionTypesEnum)

  const parentTypeOptions: DropdownOption[] = questionParentTypes.map(
    (typeKey, index) => ({
      label: typeKey,
      value: questionParentTypesValues[index]
    })
  )

  const formsOptions: DropdownOption[] = forms?.map(form => ({
    label: form.name,
    value: form.id
  }))

  const formBlocksOptions: DropdownOption[] = currentFormBlocks?.map(qg => ({
    label: JSON.stringify(qg.entity?.configData) ?? '',
    value: qg.entityId
  }))

  return (
    <View
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <View
        style={{
          width: '100%',
          height: 50,
          marginTop: 10,
          paddingHorizontal: 10,
          flexDirection: 'row'
        }}
      >
        {/* Filters */}
        <View
          style={{
            marginHorizontal: 10,
            height: '90%',
            width: '80%',
            flexDirection: 'row'
          }}
        >
          <Dropdown
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentQuestionParentType}
            containerStyle={{ width: 150, height: 50, marginRight: 10 }}
            isLoading={fetchLoading}
            isDisabled={fetchLoading}
            options={parentTypeOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => setCurrentQuestionParentType(value)}
            name={'parentQuestionTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
          <Dropdown
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentForm?.id ?? null}
            containerStyle={{ width: 250, height: 50, marginRight: 10 }}
            isLoading={fetchLoading}
            isDisabled={fetchLoading}
            options={formsOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => {
              const form = forms.find(form => form.id === value)
              setCurrentForm(form)
            }}
            name={'formTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
          <Dropdown
            isClearable
            closeMenuOnSelect
            style={{
              minHeight: 0,
              marginBottom: 0
            }}
            value={currentFormBlock?.id ?? null}
            containerStyle={{ width: 250, height: 50, marginRight: 10 }}
            isLoading={fetchLoading}
            isDisabled={fetchLoading}
            options={formBlocksOptions}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
            onSelect={value => {
              const questionGroup = questionGroupEntityMaps.find(
                qg => qg.entityId === value
              )
              setCurrentFormBlock(questionGroup?.entity as QuestionGroupValues)
            }}
            name={'formTypesSelector'}
            placeholder={t(
              'platformManagement:forms:menuItems:questions:selectorPlaceholder'
            )}
          />
          <TextInput
            style={{
              minHeight: 0,
              alignSelf: 'center'
            }}
            containerStyles={{
              width: 250,
              height: 50
            }}
            placeholder="Search..."
            value={search}
            onChangeText={setSearch}
          />
        </View>
        <CreateIcon
          style={{ alignSelf: 'flex-end' }}
          disabled={!currentForm?.id}
          isLoading={false}
          title={t('platformManagement:forms:menuItems:questionGroups:addNew')}
          onPress={handleCreateQuestion}
        />
      </View>

      {/* Table */}
      <View
        style={{
          flex: 1,
          paddingHorizontal: 10
        }}
      >
        <SkeletonContainer isLoading={isLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t('platformManagement:categories:noCategories')}
            />
          </TableContainer>
        </SkeletonContainer>
      </View>
      {currentForm ? (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          question={currentQuestion}
          isParent={currentQuestionParentType === QuestionTypesEnum.Parent}
          form={currentForm}
        />
      ) : null}
    </View>
  )
}

export default QuestionGroups
