import React, { FC, useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import Modal from '../../../../components/common/Modal'
import TouchableIcon from '../../../../components/icon/TouchableIcon'

import { Company, Delegation, InnovatorProduct } from '../../../../types'
import Button from '../../../../ui-library/Button'
import { Title } from '../SharedComponents'
import { Description, getStyles } from './SharedStyledComponents'
import { Flex } from '../../../../components/FlexBox'
import { Message } from '../../../../recoil/toastMessageAtom'
import ModalToast from '../../../Settings/components/ModalToast'
import UpdateOrCreateProduct from './UpdateOrCreateProduct'
import useTranslation from '../../../../hooks/useTranslation'
import useLastSubmittedSubmission from '../../../../hooks/useLastSubmittedSubmission'
import CreateProduct from './CreateProduct'
import UpdateProduct from './UpdateProduct'

const CONTAINER_WIDTH = '600px'
const Container = styled(Flex)`
  ${({ theme: { space } }) => `
    margin-top: ${space[2]}px;
    width: ${CONTAINER_WIDTH}px;
  `}
`

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

interface ProductCreationModalProps {
  company?: Company
  delegations: Delegation[]
  products?: InnovatorProduct[]
  isOpen: boolean
  onClose: (val?) => void
  setProductSelectedId: (val?) => void
  children?: JSX.Element | JSX.Element[]
  testID?: string
}

enum ProductMode {
  UpdateOrCreate = 'updateOrCreate',
  Create = 'create',
  Update = 'update',
  UpdatedPublishedProduct = 'updatedPublishedProduct'
}

const ProductUpdateOrCreateModal: FC<ProductCreationModalProps> = ({
  isOpen,
  onClose,
  setProductSelectedId,
  company,
  delegations,
  products
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { sizes, space } = theme
  const [toastMessage, setToastMessage] = useState<Message | null>(null)
  const {
    lastSubmittedSubmission,
    setLastSubmittedSubmission
  } = useLastSubmittedSubmission()
  const [mode, setMode] = useState<ProductMode | null>(
    lastSubmittedSubmission?.submissionId
      ? ProductMode.UpdateOrCreate
      : ProductMode.Create
  )

  const onCreate = useCallback(() => {
    setMode(ProductMode.Create)
  }, [mode])

  const onUpdate = useCallback(() => {
    setMode(ProductMode.Update)
  }, [mode])

  const triggerClose = product => {
    setLastSubmittedSubmission({ submissionId: null, delegationId: null })
    onClose(product)
  }

  const onUpdatedPublishedProduct = product => {
    setLastSubmittedSubmission({ submissionId: null, delegationId: null })
    setProductSelectedId(product?.id)
    setMode(ProductMode.UpdatedPublishedProduct)
  }

  const matchingDelegationProducts = useMemo(() => {
    const delegationId = lastSubmittedSubmission?.delegationId
    if (products?.length) {
      return products?.filter(p => p.delegation?.id === delegationId)
    }
    return []
  }, [lastSubmittedSubmission, products])

  const { btnContainerStyles, btnStyles } = getStyles(theme)

  return (
    <Modal
      testIDModal={'createProductModal'}
      close={triggerClose}
      open={isOpen}
      styles={{
        minWidth: '550px',
        maxWidth: '850px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={triggerClose} />
      <Flex padding={space[3]}>
        <ModalToast message={toastMessage} />
        <Title>
          {mode === ProductMode.UpdatedPublishedProduct
            ? t('directoryProfile:products:updatedPublishedProduct:title')
            : lastSubmittedSubmission.submissionId
            ? t('directoryProfile:products:updateOrCreate:title')
            : t('directoryProfile:products:prefillProduct:title')}
        </Title>
        <View style={{ marginTop: space[2] }}>
          {mode === ProductMode.UpdatedPublishedProduct ? (
            <Container>
              <Description>
                {t(
                  'directoryProfile:products:updatedPublishedProduct:description'
                )}
              </Description>
              <Flex justifyContent="center">
                <Button
                  title={t('common:buttons:close')}
                  buttonStyle={{ ...btnStyles, width: sizes[6] }}
                  containerStyle={{
                    ...btnContainerStyles,
                    alignItems: 'center'
                  }}
                  onPress={onClose}
                />
              </Flex>
            </Container>
          ) : mode === ProductMode.UpdateOrCreate ? (
            <UpdateOrCreateProduct
              onClose={triggerClose}
              onCreate={onCreate}
              onUpdate={onUpdate}
              hasMatchingDelegationProducts={
                matchingDelegationProducts?.length > 0
              }
            />
          ) : mode === ProductMode.Update ? (
            <UpdateProduct
              lastSubmittedSubmission={lastSubmittedSubmission}
              company={company}
              matchingDelegationProducts={matchingDelegationProducts}
              onClose={triggerClose}
              setToastMessage={setToastMessage}
              onUpdatedPublishedProduct={onUpdatedPublishedProduct}
            />
          ) : mode === ProductMode.Create ? (
            <CreateProduct
              lastSubmittedSubmission={lastSubmittedSubmission}
              delegations={delegations}
              company={company}
              onClose={triggerClose}
              setToastMessage={setToastMessage}
            />
          ) : null}
        </View>
      </Flex>
    </Modal>
  )
}

export default ProductUpdateOrCreateModal
