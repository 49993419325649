import React, { useCallback, FC, useContext } from 'react'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import useIsSmallScreen from '../../../../../hooks/useIsSmallScreen'
import Modal from '../../../../../components/common/Modal'
import { Flex } from '../../../../../components/FlexBox'
import Button from '../../../../../ui-library/Button'
import TouchableIcon from '../../../../../components/icon/TouchableIcon'
import CollapsibleContainer from '../../../../../screens/InnovatorDirectory/components/common/CollapsibleContainer'
import { DashboardContext } from '../../hooks/DashboardProvider'
import { FilterBadge } from '../FiltersBar'
import { TopQuestionsEnum } from '../types'
import { FilterObject } from '../../types'

interface FiltersModalProps {
  openDialog: boolean
  triggerClose?: () => void
}

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

const containerStyle = {
  flexBasis: '48%'
}
const buttonStyle = {
  width: '100%'
}

interface CollapsibleFilterTypeProps {
  title: string
  handleFilters: (filter: FilterObject) => void
  filterItems: { name: string }[]
  filterType: TopQuestionsEnum | 'SearchText'
  subtitle?: string
}

const CollapsibleFilterType = (props: CollapsibleFilterTypeProps) => {
  const { filterItems, filterType, title, subtitle, handleFilters } = props
  return (
    <CollapsibleContainer title={title} subtitle={subtitle}>
      <Flex flexDirection="row" flexWrap="wrap" alignItems="center">
        {filterItems.map(item => (
          <FilterBadge
            showRemoveIcon={false}
            filter={{
              filterType,
              value: item.name
            }}
            handlePress={handleFilters}
          />
        ))}
      </Flex>
    </CollapsibleContainer>
  )
}

const FiltersModal: FC<FiltersModalProps> = ({ openDialog, triggerClose }) => {
  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()
  const {
    categories,
    countries,
    languages,
    handleFilters,
    filters
  } = useContext(DashboardContext)
  const titleStyle = isSmallScreen ? { fontSize: 15 } : {}

  const onClose = useCallback(() => {
    triggerClose && triggerClose()
  }, [])

  const handleApplyFilters = () => {
    onClose()
  }

  const filterItems = useCallback(
    (array: { name: string }[], type: TopQuestionsEnum): { name: string }[] => {
      return array.filter(
        item =>
          !filters
            .filter(filter => filter.filterType === type)
            .map(filter => filter.value)
            .includes(item.name)
      )
    },
    [filters]
  )

  const filterCategories = [
    // Categories
    {
      handleFilters,
      title: 'Category',
      subtitle: 'Add categories',
      filterItems: filterItems(categories, TopQuestionsEnum.Category),
      filterType: TopQuestionsEnum.Category
    },
    // Countries
    {
      handleFilters,
      title: 'Country',
      subtitle: 'Add countries',
      filterItems: filterItems(countries, TopQuestionsEnum.Country),
      filterType: TopQuestionsEnum.Country
    },
    // Languages
    {
      handleFilters,
      title: 'Language',
      subtitle: 'Add languages',
      filterItems: filterItems(languages, TopQuestionsEnum.Language),
      filterType: TopQuestionsEnum.Language
    }
  ]

  return (
    <Modal
      close={onClose}
      open={openDialog}
      styles={{
        width: '700px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={onClose} />

      <Flex padding={space[3]}>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: space[3]
          }}
        >
          {filters.map(filter => (
            <FilterBadge
              filter={filter}
              handlePress={filter => handleFilters(filter)}
            />
          ))}
        </View>
        {filterCategories.map(filter => (
          <CollapsibleFilterType
            handleFilters={filter.handleFilters}
            title={filter.title}
            subtitle={filter.subtitle}
            filterItems={filter.filterItems}
            filterType={filter.filterType}
          />
        ))}
        <Button
          title={'Apply Filters'}
          type="solid"
          onPress={handleApplyFilters}
          containerStyle={{ ...containerStyle, marginTop: space[3] }}
          buttonStyle={buttonStyle}
          titleStyle={titleStyle}
        />
      </Flex>
    </Modal>
  )
}

export default FiltersModal
