import React, { useRef, useState, useEffect } from 'react'
import {
  View,
  StyleSheet,
  Platform,
  TextInput,
  Text as RNText
} from 'react-native'
import {
  CodeField,
  Cursor,
  useClearByFocusCell,
  useBlurOnFulfill
} from 'react-native-confirmation-code-field'
import { path } from 'ramda'
import useTranslation from '../../hooks/useTranslation'
import { H4 } from '../../components/common/Text'
import {
  AuthenticationErrorContainer,
  ErrorText,
  SuccessText
} from './components/SharedStyledComponents'
import styled from 'styled-components/native'
import useSendAuthCode from './mutations/useSendAuthCode'
import useVerifyAuthCode from './mutations/useVerifyAuthCode'
import Firebase from '../../Firebase'
import { getAuth as getFBAuth, signInWithCustomToken } from 'firebase/auth'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLastAttemptedEmail from '../../hooks/useLastAttemptedEmail'
import useThemeColor from '../../hooks/useThemeColor'
import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'

import RowLayout from './components/layout/RowLayout'
import PageHeader from './components/sharedComponents/PageHeader'
import CallToActionBtns from './components/sharedComponents/CallToActionBtns'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

const CELL_COUNT = 6

export const CodeContainer = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${props => props.theme.space[3]}px;
`

const AuthCodeSentScreen = ({ send, state, payload }: any) => {
  const isSmallScreen = useIsSmallScreen()
  const { t } = useTranslation()
  const { trackWithProperties, track } = useMixpanel()
  const { currentUser } = useCurrentUser()
  const { lastAttemptedEmail } = useLastAttemptedEmail()
  const [newCodeDisabled, setNewCodeDisabled] = useState(false)
  const [authCode, setAuthCode] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)
  const [successText, setSuccessText] = useState('')
  const [authCodeError, setAuthCodeError] = useState()
  const desktopCodeFieldRef = useRef<TextInput>()
  const codeFieldRef = useBlurOnFulfill({
    value: authCode,
    cellCount: CELL_COUNT
  })

  const sendCode = useSendAuthCode({
    errorMessage: t('error:auth:sendAuthCode')
  })

  const sendAuthCode = async () => {
    if (!lastAttemptedEmail) {
      console.error(
        'lastAttemptedEmail does not exists. sendCode mutation not being fired!'
      )
      return
    }
    trackWithProperties(EVENT_MAP.auth.authCodeRequest, {
      destinationEmail: lastAttemptedEmail
    })
    await sendCode({ variables: { email: lastAttemptedEmail } })
  }

  useEffect(() => {
    if (lastAttemptedEmail) {
      trackWithProperties(EVENT_MAP.auth.authCodeRequest, {
        destinationEmail: lastAttemptedEmail
      })
      sendCode({ variables: { email: lastAttemptedEmail } })
    }
  }, [])

  const loginWithToken = async (token: any) => {
    try {
      await signInWithCustomToken(getFBAuth(Firebase), token)
      track(EVENT_MAP.auth.loginWithCode)
      if (state.loginCheck) {
        send('CODE_VERIFIED_RESET')
      } else if (state.registerCheck) {
        if (payload.registerUserToCompany) {
          send({
            type: 'CODE_VERIFIED_REGISTER_USER_TO_COMPANY',
            payload: payload
          })
        } else {
          send('CODE_VERIFIED_REGISTER')
        }
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const [verifyCode] = useVerifyAuthCode({
    onCompleted: results => {
      setSuccessText(t('error:auth:correctCode'))
      setTimeout(() => {
        loginWithToken(results.verifyAuthenticationCode)
      }, 1000)
    },
    onError: error => {
      setIsVerifying(false)
      if (!error || !error.message) {
        return
      }
      if (error.message === 'Incorrect Authentication Code') {
        setAuthCodeError(t('error:auth:badCode'))
      } else if (error.message === 'No Authentication Code For This User') {
        setAuthCodeError(t('error:auth:codeExpired'))
      } else {
        // going to leave the below console just in case we need a new message
        console.warn(`Code verify error: "${error.message}" `, error)
        setAuthCodeError(t('error:auth:other'))
      }
    }
  })

  const verifyAuthCode = async () => {
    const emailForCode = path(['email'], currentUser) || lastAttemptedEmail
    if ((!emailForCode && !currentUser) || !authCode) return
    setIsVerifying(true)
    await verifyCode({
      variables: { email: emailForCode, code: authCode }
    })
  }

  const isDesktopWeb = Platform.OS === 'web'
  const activeRef = isDesktopWeb ? desktopCodeFieldRef : codeFieldRef
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: authCode,
    setValue: setAuthCode
  })

  const handleVerifyCode = () => {
    setAuthCodeError(undefined)
    verifyAuthCode()
  }

  const sendNewCode = () => {
    sendAuthCode()
    setNewCodeDisabled(true)
    setTimeout(() => {
      setNewCodeDisabled(false)
    }, 5000)
  }

  const isInt = val => {
    return val && Number.isInteger(Number(val))
  }

  const isValidCode = () => {
    const hasValue = !!authCode
    const isSixDigits = hasValue && (authCode || '').length === 6
    const isInteger = isInt(Number(authCode))
    return hasValue && isSixDigits && isInteger
  }

  const inputBorderColor = useThemeColor('inputText')

  return (
    <RowLayout>
      <PageHeader
        title={t('auth:authCode:header')}
        description={t('auth:authCode:description', { expiration: '24' })}
      />

      <H4 styles={{ textAlign: 'center', marginBottom: '20px' }}>
        {t('auth:authCode:enter')}
      </H4>

      <CodeContainer>
        <CodeField
          {...props}
          testID={'authCodeInput'}
          ref={activeRef}
          onSubmitEditing={() =>
            isDesktopWeb && isValidCode() && handleVerifyCode
          }
          value={authCode}
          onChangeText={setAuthCode}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFieldRoot}
          keyboardType={Platform.OS === 'web' ? 'default' : 'number-pad'}
          textContentType="oneTimeCode"
          renderCell={({ index, symbol, isFocused }) => (
            <RNText
              key={index}
              style={[
                styles.defaultCell,
                isSmallScreen ? styles.smallCellContent : styles.cellContent,
                { borderColor: inputBorderColor, color: inputBorderColor },
                index === 0 ? { marginLeft: 0 } : {},
                isFocused && styles.focusCell
              ]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              {symbol || (isFocused ? <Cursor /> : null)}
            </RNText>
          )}
        />
      </CodeContainer>
      <AuthenticationErrorContainer>
        <ErrorText>{authCodeError || ''}</ErrorText>
        <SuccessText>{successText || ''}</SuccessText>
      </AuthenticationErrorContainer>

      <CallToActionBtns
        primaryBtn={{
          title: t('auth:buttons:authenticate'),
          onPress: handleVerifyCode,
          disabled: !isValidCode() || isVerifying,
          isProcessing: isVerifying
        }}
        secondaryBtn={{
          title: newCodeDisabled
            ? t('auth:authCode:codeSent')
            : t('auth:authCode:newCode'),
          onPress: newCodeDisabled ? () => {} : sendNewCode,
          disabled: false
        }}
      />
    </RowLayout>
  )
}

const styles = StyleSheet.create({
  root: { flex: 1, padding: 5 },
  title: { textAlign: 'center', fontSize: 30 },
  codeFieldRoot: { marginTop: 5 },
  defaultCell: {
    borderWidth: 1,
    marginLeft: 10,
    textAlign: 'center',
    borderRadius: 3
  },
  cellContent: {
    width: 60,
    height: 70,
    lineHeight: 65,
    fontSize: 40
  },
  smallCellContent: {
    width: 50,
    height: 60,
    lineHeight: 55,
    fontSize: 28
  },
  focusCell: {
    borderColor: 'rgba(255, 255, 255, .5)'
  }
})

export default AuthCodeSentScreen
