import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Animated, Easing, View } from 'react-native'
import { AnimatedComponentProps, MouseEvent } from './types'
import { AnimatedView } from './SharedStyledComponents'

const OPACITY_CLICKED = 0.5
const OPACITY_HOVER = 1

const AnimatedComponent = (props: AnimatedComponentProps) => {
  const {
    effects = [],
    children,
    style,
    hoverColor,
    clickColor,
    clickOpacity = false,
    hoverOpacity = false
  } = props
  const [isHover, setIsHover] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  const animationRefs = useRef(
    effects.map(effect => new Animated.Value(effect.initialValue))
  )

  const createAnimationIn = ({ type }: { type: MouseEvent }) => {
    const currentEffects = effects.filter(effect => effect.mouseEvent === type)
    const animations = currentEffects.map((effect, index) =>
      Animated.timing(animationRefs.current[index], {
        toValue: effect.toValue,
        duration: effect.timeIn,
        easing: Easing.ease,
        useNativeDriver: true
      })
    )
    Animated.parallel(animations).start()
  }

  const createAnimationOut = ({ type }: { type: MouseEvent }) => {
    const currentEffects = effects.filter(effect => effect.mouseEvent === type)
    const animations = currentEffects.map((effect, index) =>
      Animated.timing(animationRefs.current[index], {
        toValue: effect.initialValue,
        duration: effect.timeOut,
        easing: Easing.ease,
        useNativeDriver: true
      })
    )
    Animated.parallel(animations).start()
  }
  const isAnimated = useMemo(() => effects.length > 0, [effects])

  useEffect(() => {
    if (isClicked) {
      createAnimationIn({ type: 'Click' })
    } else {
      createAnimationOut({ type: 'Click' })
    }
  }, [isClicked])

  useEffect(() => {
    if (isHover) {
      createAnimationIn({ type: 'Hover' })
    } else {
      createAnimationOut({ type: 'Hover' })
    }
  }, [isHover])

  useEffect(() => {
    createAnimationIn({ type: 'Init' })
  }, [])

  const opacity = useMemo(() => {
    if (isClicked && clickOpacity) return OPACITY_CLICKED
    if (isHover && hoverOpacity) return OPACITY_HOVER
    return 1
  }, [isClicked, isHover, clickOpacity, hoverOpacity])

  const backgroundColor = useMemo(() => {
    if (isClicked && clickColor) return clickColor
    if (isHover && hoverColor) return hoverColor
    return undefined
  }, [isClicked, isHover, clickColor, hoverColor])

  const handlePressIn = () => {
    setIsClicked(true)
  }

  const handlePressOut = () => {
    setIsClicked(false)
  }

  const handleHoverIn = () => {
    setIsHover(true)
  }

  const handleHoverOut = () => {
    setIsHover(false)
  }

  const additionalStyles = useMemo(
    () =>
      effects.reduce((acc, effect, index) => {
        const currentAnimRef = animationRefs.current[index]
        const interpolate = effect.interpolateValues
        let styleValue: any = currentAnimRef
        if (interpolate) {
          const interpolatedValue = currentAnimRef.interpolate({
            inputRange: [effect.initialValue, effect.toValue],
            outputRange: interpolate
          })
          styleValue = interpolatedValue
        }
        const styleProperty = effect.styleProperty(styleValue)
        return { ...acc, ...styleProperty }
      }, {}),

    [effects, animationRefs]
  )

  const ContainerComponent = isAnimated ? AnimatedView : View

  return (
    <ContainerComponent
      style={[
        style ?? {},
        { opacity, ...(backgroundColor ? { backgroundColor } : {}) },
        additionalStyles
      ]}
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
      onMouseDown={handlePressIn}
      onMouseUp={handlePressOut}
    >
      {children}
    </ContainerComponent>
  )
}

export default AnimatedComponent
