import React from 'react'

import styled from 'styled-components/native'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

import { Flex } from '../../../components/FlexBox'
import InnovationBg from '../components/sharedComponents/InnovationBg'
import {
  BaseContainer,
  OverlayContainer,
  StyledScrollView
} from '../components/SharedStyledComponents'
import { LoginLogo } from '../../../components/icon/W50Logo'

import FooterLinks from '../components/sharedComponents/FooterLinks'
import ToastAnchor from '../../../components/ToastAnchor'

const StyledToastAnchor = styled(ToastAnchor)`
  top: 0;
  position: fixed;
`

export const Column = ({ padding, children, alignSelf }) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <Flex
      flexDirection="column"
      flexBasis={isSmallScreen ? 'auto' : '45%'}
      width={isSmallScreen ? '100%' : '45%'}
      height={isSmallScreen ? 'auto' : '45%'}
      alignSelf={alignSelf}
      justifyContent="flex-start"
      alignItems="flex-start"
      maxWidth={550}
      padding={padding}
    >
      {children}
    </Flex>
  )
}

export const Layout = ({ children, height }) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <OverlayContainer>
      <InnovationBg>
        <BaseContainer isSmallScreen={isSmallScreen}>
          {isSmallScreen ? null : <LoginLogo position={'relative'} />}
          <StyledToastAnchor />
          <StyledScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center'
            }}
            paddingTop={16}
          >
            {isSmallScreen ? (
              <LoginLogo style={{ paddingTop: 0 }} position={'absolute'} />
            ) : null}
            <Flex
              flex={1}
              flexDirection={isSmallScreen ? 'column' : 'row'}
              flexBasis="auto"
              flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
              justifyContent="center"
              alignItems="center"
              maxWidth="1366px"
              height={isSmallScreen ? height : '650px'}
            >
              {children}
              <FooterLinks />
            </Flex>
          </StyledScrollView>
        </BaseContainer>
      </InnovationBg>
    </OverlayContainer>
  )
}
