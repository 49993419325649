import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'ramda'

import InputField from '../../../InputField'
import { FormikProvider, useFormik } from 'formik'
import {
  FormField,
  QuestionGroupEntityMapValues,
  FormBlockQuestionModalFormProps,
  QuestionValues
} from '../../../../types'
import useToast from '../../../../../../../hooks/useToast'
import useTranslation from '../../../../../../../hooks/useTranslation'
import useGetQuestions from '../../../Questions/hooks/useGetQuestions'
import { CancelButton, Footer, SaveButton } from '../../../../../constants'
import { EntityTypeEnum } from '../../../../../../../components/DynamicForm/types'
import DropdownField from '../../../DropdownField'
import useUpsertQuestionGroupEntityMapAndEntity from '../../../Forms/hooks/useUpsertQuestionGroupEntityMap'

const questionFields: FormField[] = [
  {
    field: 'sequence',
    Component: InputField,
    isParent: false
  },
  {
    field: 'entityId',
    Component: DropdownField,
    isParent: false
  }
]

// Yup validation
const getAddQuestionValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    sequence: Yup.number().required(),
    entityId: Yup.string().required()
  })

const Form = (props: FormBlockQuestionModalFormProps) => {
  const { onClose, question, isEdit, questions, questionGroupId } = props
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()

  const {
    questions: allQuestions = [],
    loading: loadingQuestions
  } = useGetQuestions(false)

  // Mutations
  const [
    upsertQuestionGroupEntityMap,
    isLoading
  ] = useUpsertQuestionGroupEntityMapAndEntity({
    onError: e => console.error(e),
    onCompleted: ({ upsertQuestionGroupEntityMapAndEntity }) => {
      if (
        upsertQuestionGroupEntityMapAndEntity &&
        !isEmpty(upsertQuestionGroupEntityMapAndEntity)
      ) {
        setToastMessage(
          t(
            'platformManagement:forms:menuItems:formBlockQuestions:successSaved'
          )
        )
        onClose()
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:formBlockQuestions:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:formBlockQuestions:errorSave')
  })

  const isNew = useMemo(() => !question?.id, [question?.id])

  let initialValues = {
    id: isNew ? undefined : question?.id,
    sequence: isNew ? questions?.length ?? 0 : question?.sequence,
    entityId: isNew ? undefined : question?.entityId,
    entityType: EntityTypeEnum.Question
  }

  // Formik
  const form = useFormik<QuestionGroupEntityMapValues>({
    initialValues: { ...initialValues },
    validationSchema: getAddQuestionValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      const input = {
        id: isNew ? undefined : question?.id,
        questionGroupId,
        entityType: EntityTypeEnum.Question,
        sequence: parseInt(`${values.sequence}`),
        dynamic: false,
        parentId: values.entityId
      }
      upsertQuestionGroupEntityMap({
        variables: {
          ...input
        },
        refetchQueries: ['getQuestionGroupEntityMaps']
      })
      onClose()
    }
  })

  const validateAndSubmit = () => {
    form.validateForm().then(() => form.submitForm())
  }

  const enableSubmit = useMemo(() => {
    return form.isValid && form.dirty
  }, [form])

  let questionParentIdValidation = {}

  const allQuestionsOptionsMap = allQuestions
    .reduce((acc, q) => {
      if (isNew) {
        if (!questionParentIdValidation[`${q.parentId}`]) {
          questionParentIdValidation[`${q.parentId}`] = true
          acc = [...acc, q]
        }
      } else {
        acc = [...acc, q]
      }
      return acc
    }, [] as QuestionValues[])
    .map(q => ({
      label: q.questionText,
      value: q.id
    }))

  return (
    <FormikProvider value={form}>
      {questionFields
        .filter(formField => (isNew ? !formField.hideOnNew : true))
        .map(formField => {
          const { Component, field, isParent } = formField
          const componentProps = {
            type: 'formBlockQuestions',
            key: field,
            fieldName: field,
            isReadOnly: (isEdit && isParent) || loadingQuestions || isLoading,
            options: allQuestionsOptionsMap
          }

          return <Component {...componentProps} />
        })}

      <Footer>
        <CancelButton
          isProcessing={isLoading || loadingQuestions}
          onPress={onClose}
          title={t('common:buttons:cancel')}
        />
        <SaveButton
          disabled={!enableSubmit}
          onPress={validateAndSubmit}
          isProcessing={isLoading || loadingQuestions}
          title={t(`common:buttons:${isEdit ? 'update' : 'save'}`)}
        />
      </Footer>
    </FormikProvider>
  )
}

export default Form
