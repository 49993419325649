import { Text, View } from 'react-native'
import styled from 'styled-components/native'

export const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[2]}px;
  `}
`

export const InputContainer = styled(View)`
  width: 50%;
`

export const getStyles = theme => ({
  btnContainerStyles: { height: theme.space[4], marginTop: theme.space[3] },
  btnStyles: {
    width: 'fit-content',
    paddingBottom: theme.space[3],
    paddingTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[2],
    paddingRight: theme.space[4] - theme.space[2],
    height: theme.space[4],
    paddingHorizontal: theme.space[4]
  }
})
