import React from 'react'
import styled from 'styled-components/native'
// Components/Types
import { BorderCard } from './components'
import {
  Col,
  RowSkeleton
} from '../../../../components/skeletonLoadings/components'
import { SkeletonTemplateProps } from '../../../../components/skeletonLoadings/types'
// import Skeleton from '../../../../components/skeletonLoadings/Skeleton'
// Styled Components
const Container = styled(Col)`
  height: auto;
  max-width: 250px;
  min-width: 250px;
`
const Column = styled(Col)`
  ${({ theme: { space, sizes } }) => `
    min-height: ${sizes[4]}px;
    padding-horizontal: ${space[3]}px;
  `}
`
const OuterBorderCard = styled(BorderCard)`
  height: auto;
  justify-content: space-between;
  ${({ theme: { space } }) => `
    margin-bottom: ${space[4]}px;
    padding-vertical: ${space[3]}px;
  `}
`
const SkeletonText = styled(RowSkeleton)`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[2]}px;
  `}
`
const Separator = styled(RowSkeleton)`
  width: 100%;
  height: 1px;
  ${({ theme: { space } }) => `
    margin-top: ${space[3]}px;
    margin-bottom: ${space[3]}px;
  `}
`
const SkeletonButton = styled(RowSkeleton)`
  width: 80%;
  margin-left: auto;
  ${({ theme: { sizes } }) => `
    height: ${sizes[4]}px;
  `}
`

interface CompanySummarySkeletonProps extends SkeletonTemplateProps {}

const CompanySummarySkeleton = React.memo(
  ({ theme: { sizes } }: CompanySummarySkeletonProps) => (
    <Container>
      <OuterBorderCard showBadge={false}>
        <Column>
          <SkeletonText width="65%" height={sizes[2]} />
          <SkeletonText width="50%" height={2} />
          <SkeletonText width="50%" height={2} />
        </Column>
        <Separator />
        <Column>
          <SkeletonText width="65%" height={sizes[2]} />
          <SkeletonText width="50%" height={2} />
        </Column>
      </OuterBorderCard>
      <SkeletonButton />
    </Container>
  )
)

export default CompanySummarySkeleton
