import gql from 'graphql-tag'

const CompanyUsersFragment = gql`
  fragment CompanyUsersFragment on User {
    id
    roles
    lastLogin
    innovationOnboardedAt
    innovationEnabled
    settings {
      innovatorCommsPlatformAdmin
      innovatorCommsCFS
      innovatorCommsCFSSelectionAndPresentation
      innovatorCommsInvoicing
    }
    person {
      id
      firstName
      lastName
      jobTitle
      emailAddresses {
        email
      }
    }
  }
`

export default CompanyUsersFragment
