import React, { useContext } from 'react'
import { useTheme } from 'styled-components/native'
import Card from '../common/Card'
import { TouchableOpacity, View } from 'react-native'
import useTranslation from '../../../../hooks/useTranslation'
import { Text } from '../../../../components/common/Text'
import { AnimatedEffect } from '../../../../components/common/AnimatedComponent/types'
import { DashboardContext } from '../hooks/DashboardProvider'
import Icon from '../../../../components/icon'

interface MetricProps {
  title: string
  value: number
  color?: string
  decimals?: number
  hideTitle?: boolean
  icon?: JSX.Element
}

const Metric = (props: MetricProps) => {
  const { colors } = useTheme()
  const {
    color = colors.tints.grays.g300,
    title,
    value,
    decimals = 2,
    hideTitle,
    icon
  } = props

  const zoomEffect: AnimatedEffect = {
    initialValue: 1,
    mouseEvent: 'Hover',
    styleProperty: value => ({
      transform: [{ scaleX: value }, { scaleY: value }]
    }),
    timeIn: 200,
    timeOut: 150,
    toValue: 1.15
  }

  return (
    <Card
      styles={{
        backgroundColor: color,
        padding: 0,
        justifyContent: 'center'
      }}
      width={'100px'}
      height={hideTitle ? 45 : 60}
      effects={[zoomEffect]}
      clickOpacity
      hoverOpacity
    >
      <View
        style={{
          width: '100%'
        }}
      >
        <Text
          styles={{
            margin: 0,
            padding: 0,
            color: 'white',
            fontSize: hideTitle ? 25 : 30, // 45
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          <>
            {icon ?? null}
            {value.toFixed(decimals)}
          </>
        </Text>
        {hideTitle ? null : (
          <Text
            styles={{
              textTransform: 'uppercase',
              color: 'white',
              textAlign: 'center',
              fontSize: 10
            }}
          >
            {title}
          </Text>
        )}
      </View>
    </Card>
  )
}

const Metrics = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { reviewMetrics, handleFilters } = useContext(DashboardContext)

  const metrics = Object.keys(reviewMetrics)
  const academyAndFavorites = ['academy', 'favorites']

  const getIcon = (name: string) => (
    <Icon
      name={name}
      color="white"
      width={20}
      height={20}
      style={{ marginRight: 10 }}
    />
  )

  const handlePress = (metric: string) => {
    handleFilters({
      filterType: metric === 'favorites' ? 'Favorite' : 'Academy',
      value: metric
    })
  }

  return (
    // 430
    <Card width={`255px`} title={t('dashboard:components:metrics:title')}>
      <View
        style={{
          width: '100%',
          height: '80%',
          justifyContent: 'center'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {metrics
            .filter(metric => academyAndFavorites.includes(metric))
            .map(metric => (
              <TouchableOpacity onPress={() => handlePress(metric)}>
                <Metric
                  title={metric}
                  color={
                    metric === 'favorites'
                      ? colors.danger
                      : colors.cta.secondary
                  }
                  icon={
                    metric === 'favorites'
                      ? getIcon('favorite')
                      : getIcon('academy')
                  }
                  decimals={0}
                  hideTitle
                  value={reviewMetrics[metric]}
                />
              </TouchableOpacity>
            ))}
          {metrics
            .filter(metric => !academyAndFavorites.includes(metric))
            .map((metric, index) => (
              <Metric
                title={metric}
                color={index === 0 ? colors.tints.grays.g500 : undefined}
                value={reviewMetrics[metric]}
              />
            ))}
        </View>
      </View>
    </Card>
  )
}

export default Metrics
