import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { Flex } from '../../FlexBox'
import Icon from '../../icon'

export const SpinnerIcon = styled(ActivityIndicator)``

export const FieldContainer = styled(View)`
  width: 50%;
`

export const EditFormIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'editFormIcon',
  width: theme.sizes[3],
  height: theme.sizes[3],
  color: theme.colors.cta.secondary
}))``

export const EditFormContainer = styled(View)`
  ${({ theme }) => `
    padding-left: ${theme.space[3]}px;
    margin-top: ${theme.space[3]}px;
  `}
`

export const FileCardContainer = styled(View)``

export const CardView = styled(View)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`

export const CardContentVew = styled(View)`
  display: flex;
  flex-direction: row;
`
export const CardTitleContent = styled(View)`
  display: flex;
  flex-direction: row;
  width: 92%;
`

export const CardLabelContent = styled(View)`
  ${({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${theme.space[3]}px;
    width: 30%;
  `}
`

export const CardPreviewIconContent = styled(View)`
  ${({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 30%;
  margin-left: ${theme.sizes[2]}px;
  min-width: ${theme.sizes[5]}px;
  `}
`

export const CardRemoveButton = styled(TouchableOpacity)`
  justify-content: center;
  align-items: center;
  width: 8%;
  height: auto;
`

export const CardEditFormButton = styled(TouchableOpacity)`
  justify-content: center;
  margin-left: ${({ theme }) => theme.space[1]}px;
  align-items: center;
  width: 8%;
  height: auto;
`

export const Container = styled(Flex).attrs(
  ({
    theme: { colors, space, radii, borderWidths },
    styles = {},
    ...props
  }) => ({
    borderColor: props.borderColor ? props.borderColor : colors.inputBorder,
    borderWidth: borderWidths[1],
    borderRadius: radii[4],
    borderStyle: 'solid',
    p: props.padding ? props.padding : `${space[3]}px`,
    my: `${space[3]}px`,
    w: '100%',
    ...styles
  })
)``

export const CardContainer = styled(View)`
  max-width: 100%;
  display: flex;
  justify-content: center;
`

export const UploadConfirm = styled(View)`
  display: flex;
  flex-direction: row;
`

export const ScanningDescription = styled(View)`
  display: flex;
  flex-direction: row;
  margin-left: ${({ theme }) => theme.sizes[2] - 2}px;
  margin-top: ${({ theme }) => theme.sizes[2] - 2}px;
  width: 50%;
`

export const UploadContainer = styled(View)`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.sizes[2] - 2}px;
`
