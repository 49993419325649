import React, { FC } from 'react'

import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { useDimensions } from '@react-native-community/hooks'

import { Flex } from '../../../components/FlexBox'
import { CallForSubmission, CallForSubmissionStatusEnum } from '../../../types'
import { Title, NextStep } from '../SubmissionDetails'
import TouchableIcon from '../../../components/icon/TouchableIcon'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import useCFSStepDatesRangeDiff from '../hooks/useCFSStepDatesRangeDiff'
import { orderedSteps } from '../hooks/useSetupNextStepMutation'
import useTranslation from '../../../hooks/useTranslation'
import capitalize from '../../../utils/capitalize'
import moment from 'moment-timezone'
import useInnovationAccess from '../../../hooks/useInnovationAccess'

const SHOW_SUBTITLE_BREAKPOINT = 1500

interface CurationTabsHeaderProps {
  callForSubmission?: CallForSubmission
}

const Container = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => `
    width: 100%;
    padding: ${theme.space[4]}px ${theme.space[4]}px ${theme.space[4]}px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${theme.colors.tabBorder}
  `}
`

const CFSTitlesContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  ali items: center;
`
const CFSLeftContainer = styled(Flex)`
  width: 60%;
`
const CFSRightContainer = styled(Flex)`
  width: 40%;
`
const CFSSubtitle = styled(Title)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[5]}px;
  `}
`
const CFSNextStep = styled(NextStep)`
  text-align: right;
`
const CFSNextStepSubtitle = styled(CFSNextStep)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[5]}px;
  `}
`

const CurationTabsHeader: FC<CurationTabsHeaderProps> = ({
  callForSubmission
}) => {
  const linkToScreen = useLinkToScreen()
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { width } = useDimensions().window

  const showSubtitles = width < SHOW_SUBTITLE_BREAKPOINT

  const { cfsTitleStep, cfsSubtitleStep } = useCFSStepDatesRangeDiff(
    callForSubmission
  )
  const currentCFSStep = callForSubmission?.currentStep.name
  const orderedIndex = orderedSteps.findIndex(step => step === currentCFSStep)
  const nextStep = orderedSteps[orderedIndex + 1]
  const dateFormat = 'MM/DD/YY'
  const stepData = callForSubmission?.stepDateRanges.map(sdr => ({
    openAt: moment(sdr.openAt).format(dateFormat),
    closeAt: moment(sdr.closeAt).format(dateFormat),
    name: sdr.curationStep
  }))
  const nextStepData = stepData?.find(step => {
    return step.name === nextStep
  })
  const nextStepName = t(`callsForSubmission:step:name:${nextStep}`)
  const isEventStep = currentCFSStep === 'event'
  const {
    advisorAssignment,
    callForSubmissionAdvisingSettings,
    callForSubmissionSettings
  } = useInnovationAccess()

  const canNavigateToSettings =
    advisorAssignment ||
    callForSubmissionAdvisingSettings ||
    callForSubmissionSettings

  const nextStepTimestamp = nextStepData?.openAt
    ? `(${nextStepData?.openAt} - ${nextStepData?.closeAt})`
    : ''

  const isFinished =
    callForSubmission?.status === CallForSubmissionStatusEnum.closed

  return (
    <Container>
      <CFSTitlesContainer>
        <CFSLeftContainer flexDirection="column">
          <Title>
            {!showSubtitles ? cfsTitleStep : callForSubmission?.name || ''}
          </Title>
          {showSubtitles ? <CFSSubtitle>{cfsSubtitleStep}</CFSSubtitle> : null}
        </CFSLeftContainer>

        {!isEventStep && !isFinished ? (
          <CFSRightContainer flexDirection="column" alignItems="flex-end">
            <CFSNextStep>{`Next Phase » ${capitalize(nextStepName)} ${
              !showSubtitles ? nextStepTimestamp : ''
            }`}</CFSNextStep>
            {showSubtitles ? (
              <CFSNextStepSubtitle>{nextStepTimestamp}</CFSNextStepSubtitle>
            ) : null}
          </CFSRightContainer>
        ) : null}
      </CFSTitlesContainer>
      {canNavigateToSettings ? (
        <TouchableIcon
          name="cog"
          onPress={() => {
            linkToScreen('CFSSettings', { cfsId: callForSubmission?.id })
          }}
          color={colors.tints.grays.g400}
          width={20}
          height={20}
        />
      ) : null}
    </Container>
  )
}

export default CurationTabsHeader
