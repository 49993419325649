import { useActor } from '@xstate/react'
import appCoordinatorMachine from './stateMachine'

const useAppCoordinator = () => {
  const [state, send] = useActor(appCoordinatorMachine)

  return {
    state,
    send
  }
}

export default useAppCoordinator
