import React, { FC, useEffect, useState } from 'react'
import { gql } from 'graphql-tag'
import { ApolloCache } from '@apollo/client'

import { Text } from '../../../../../../components/common/Text'
import { Flex } from '../../../../../../components/FlexBox'
import useTranslation from '../../../../../../hooks/useTranslation'
import {
  CallForSubmissionStepEnum,
  CurrentCFSStep,
  InnovationSubmission,
  InnovationSubmissionStatusEnum
} from '../../../../../../types'
import useUpdateSubmissionStatusMutation from '../../../../../Submission/hooks/useUpdateSubmissionStatusMutation'
import useToast from '../../../../../../hooks/useToast'
import useIsSmallScreen from '../../../../../../hooks/useIsSmallScreen'
import CutFeedback from './CutFeedback'
import Dropdown, {
  DropdownOption
} from '../../../../../../components/common/Dropdown'
import { StaffFeedbackView } from '../../SharedComponents'

interface FeedbackProps {
  submission: InnovationSubmission
  isDisabled?: boolean
  displayOnlyIcons?: boolean
}

interface StaffFeedbackProps {
  submission: InnovationSubmission
  currentStep?: CurrentCFSStep
}

export const getCurrentStaffFeedback = ({
  submission,
  currentStep
}: StaffFeedbackProps) => {
  const {
    status,
    // @ts-ignore
    innovationSubmissionStatus, // this is due to an alias in the InnovationSubmissionFieldsFragment
    step,
    hasBeenFastPassed
  } = submission
  const submissionStatus = status || innovationSubmissionStatus
  const isCFSInSubmission =
    CallForSubmissionStepEnum.submission === currentStep?.name

  const isSubmissionInSubmission = step === CallForSubmissionStepEnum.submission

  const isSubmissionInPreCurate = step === CallForSubmissionStepEnum.preCurate

  let displayedText = '-'

  if (!isCFSInSubmission) {
    if (hasBeenFastPassed) {
      displayedText = 'Fast-passed'
    } else if (!isSubmissionInPreCurate && !isSubmissionInSubmission) {
      displayedText = 'Accepted'
    } else {
      switch (submissionStatus) {
        case InnovationSubmissionStatusEnum.rejected:
          displayedText = 'Rejected'
          break
        case InnovationSubmissionStatusEnum.flagged:
          displayedText = 'To be Reviewed'
          break
        default:
          displayedText = '-'
          break
      }
    }
  }

  return displayedText
}

const dropdownOptions: DropdownOption[] = [
  {
    label: 'Pass',
    value: InnovationSubmissionStatusEnum.submitted
  },
  {
    label: 'Cut',
    value: InnovationSubmissionStatusEnum.rejected
  },
  {
    label: 'Fast Pass',
    value: InnovationSubmissionStatusEnum.fastPassed
  },
  {
    label: 'Flag for Discussion',
    value: InnovationSubmissionStatusEnum.flagged
  }
]

const StaffFeedback: FC<FeedbackProps> = ({
  submission,
  isDisabled,
  displayOnlyIcons = false
}) => {
  const isSmallScreen = useIsSmallScreen()
  const { setToastMessage, setToastErrorMessage } = useToast()
  const {
    // @ts-ignore
    innovationSubmissionStatus, // this is due to an alias in the InnovationSubmissionFieldsFragment
    status, // this is due to an alias in the InnovationSubmissionFieldsFragment
    step,
    hasBeenFastPassed
  } = submission
  const { t } = useTranslation()
  const submissionStatus = status || innovationSubmissionStatus
  const [updateInnovationSubmissionStatus] = useUpdateSubmissionStatusMutation({
    update: async (cache: ApolloCache<any>, { data }) => {
      try {
        if (data?.updateInnovationSubmissionStatus?.id?.length) {
          cache.writeFragment({
            id: `InnovationSubmission:${data.updateInnovationSubmissionStatus.id}`,
            fragment: gql`
              fragment InnovationSubmissionFields on InnovationSubmission {
                id
                step
                status
                hasBeenFastPassed
                hasBeenSubmitted
              }
            `,
            data: {
              id: data.updateInnovationSubmissionStatus.id,
              step: data.updateInnovationSubmissionStatus?.step,
              status: data.updateInnovationSubmissionStatus?.status,
              hasBeenFastPassed:
                data.updateInnovationSubmissionStatus?.hasBeenFastPassed,
              hasBeenSubmitted:
                data.updateInnovationSubmissionStatus?.hasBeenSubmitted
            }
          })
        }
      } catch (updateInnovationSubmissionStatusUpdateError) {
        console.log(updateInnovationSubmissionStatusUpdateError)
      }
    },
    onCompleted: () => {
      setToastMessage(t('submissions:forms:statusUpdatedSuccess'))
    },
    onError: () => {
      setToastErrorMessage(t('submissions:forms:statusUpdatedError'))
    }
  })
  const [dropdownValue, setDropdownValue] = useState('')
  const [renderAnswers, setRenderAnswers] = useState(true)

  const cutFormId = submission?.callForSubmission?.cutFormId

  const isSubmissionInSubmission =
    submission.step === CallForSubmissionStepEnum.submission

  const isSubmissionInPreCurate =
    submission.step === CallForSubmissionStepEnum.preCurate

  useEffect(() => {
    let dropdownValueT
    if (hasBeenFastPassed) {
      dropdownValueT = InnovationSubmissionStatusEnum.fastPassed
    } else if (!isSubmissionInPreCurate && !isSubmissionInSubmission) {
      dropdownValueT = InnovationSubmissionStatusEnum.submitted
    } else {
      switch (submissionStatus) {
        case InnovationSubmissionStatusEnum.rejected:
          dropdownValueT = InnovationSubmissionStatusEnum.rejected
          break
        case InnovationSubmissionStatusEnum.flagged:
          dropdownValueT = InnovationSubmissionStatusEnum.flagged
          break
        default:
          dropdownValueT = null
          break
      }
    }
    setDropdownValue(dropdownValueT)
    setRenderAnswers(dropdownValueT === InnovationSubmissionStatusEnum.rejected)
  }, [
    hasBeenFastPassed,
    isSubmissionInPreCurate,
    isSubmissionInSubmission,
    submissionStatus
  ])

  const handleUpdate = async (
    toStatus: InnovationSubmissionStatusEnum,
    formSubmitted?: boolean
  ) => {
    setRenderAnswers(false)
    const finalStatus =
      submissionStatus !== InnovationSubmissionStatusEnum.rejected &&
      (toStatus === submissionStatus ||
        (toStatus === InnovationSubmissionStatusEnum.submitted &&
          step === CallForSubmissionStepEnum.advising) ||
        (hasBeenFastPassed &&
          toStatus === InnovationSubmissionStatusEnum.fastPassed))
        ? InnovationSubmissionStatusEnum.inProgress
        : toStatus

    if (
      finalStatus !== InnovationSubmissionStatusEnum.rejected ||
      (finalStatus === InnovationSubmissionStatusEnum.rejected && formSubmitted)
    ) {
      const result = await updateInnovationSubmissionStatus(
        submission.id,
        finalStatus
      )
      return result
    } else if (
      finalStatus === InnovationSubmissionStatusEnum.rejected &&
      !formSubmitted
    ) {
      setDropdownValue(InnovationSubmissionStatusEnum.rejected)
    }
  }

  return (
    <StaffFeedbackView>
      {!displayOnlyIcons && (
        <Flex marginBottom={3}>
          <Text>
            {t(
              `curation:submissionReview:${
                isDisabled ? 'readOnly' : 'setStatus'
              }`
            )}
          </Text>
        </Flex>
      )}

      <Flex
        width="100%"
        flexDirection="row"
        justifyContent={isSmallScreen ? 'center' : 'flex-start'}
      >
        <Dropdown
          testID={'staffFeedbackDropdown'}
          name="submissionStatusDropdown"
          value={dropdownValue}
          options={dropdownOptions}
          onSelect={handleUpdate}
          menuPortalTarget={document?.body}
          isDisabled={isDisabled}
        />
      </Flex>
      {dropdownValue === InnovationSubmissionStatusEnum.rejected &&
        cutFormId && (
          <CutFeedback
            handleUpdateState={() =>
              handleUpdate(InnovationSubmissionStatusEnum.rejected, true)
            }
            shouldRenderAnswers={renderAnswers}
            cutFormId={cutFormId}
            submissionId={submission.id}
            isDisabled={isDisabled}
          />
        )}
    </StaffFeedbackView>
  )
}

export default StaffFeedback
