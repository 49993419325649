import * as Linking from 'expo-linking'

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Login: 'login',
      NotFound: '*',
      Legal: 'legal/:type',
      JoinTeam: 'joinTeam/:userId',
      App: {
        screens: {
          Home: '',
          Submission: {
            screens: {
              SubmissionList: 'submissions',
              SubmissionPrefill: {
                // id: is CFS id
                path: 'submission/prefill/:id',
                screens: {
                  SubmissionPrefillForm: 'submissionPrefillForm'
                }
              },
              SavedSubmission: 'submission/:id'
            }
          },
          CallForSubmission: {
            screens: {
              CallForSubmissionList: 'callForSubmission',
              Dashboard: 'dashboard/:cfsId',
              CFSSettings: {
                path: 'cfs-settings/:cfsId',
                screens: {
                  SettingsTabs: {
                    screens: {
                      GeneralSettings: 'general-settings',
                      AdvisingSettings: 'advising-settings',
                      TestingSettings: {
                        path: 'testing-settings',
                        screens: {
                          CreateSubmissionsTestSettings:
                            'create-submissions-test-settings',
                          SubmissionsTestSettings: 'submissions-test-settings',
                          PreCurateTestSettings: 'pre-curate-test-settings',
                          AdvisingTestSettings: 'advising-test-settings'
                        }
                      }
                    }
                  },
                  SubmissionsSelection: {
                    path: 'submission-selection/:advisorId'
                  }
                }
              },
              CurateCFSList: 'curate',
              AdviseCFSList: 'advise',
              CreateNewCFS: 'createNewCFS'
            }
          },
          InnovatorDirectory: {
            screens: {
              InnovatorDirectoryList: 'innovator-directory',
              InnovatorDirectoryProfile: 'innovator-directory/:companyId',
              InnovatorDirectoryDetails:
                'innovator-directory/:companyId/edit-company',
              InnovatorDirectoryMembers:
                'innovator-directory/:companyId/members'
            }
          },
          CompanyProfile: 'companyProfile',
          Settings: {
            path: 'settings',
            screens: {
              Members: 'members'
            }
          },
          Curate: {
            screens: {
              CurationTabs: {
                path: 'curate/:id',
                screens: {
                  CurationGeneral: 'general',
                  CurationSubmissions: 'submissions',
                  CurationPreCuration: 'precuration',
                  CurationAdvising: 'advising',
                  CurationCohort: 'cohort',
                  CurationPresentations: 'presentations'
                }
              },
              CurationSubmissionDetails: {
                path: 'curate/:id/details/:submissionId'
              },
              CurationAdvisorDetail: {
                path: 'curate/:id/advising/:advisorId'
              }
            }
          },
          Advise: {
            screens: {
              AdviseSubmissions: {
                path: 'advise/:id'
              },
              AdviseSubmissionDetails: {
                path: 'advise/:id/details/:submissionId'
              }
            }
          },
          PlatformManagement: {
            path: 'platform-management',
            screens: {
              CategoryManagement: 'categories',
              FormsManagement: 'forms'
            }
          },
          FormManagement: {
            path: 'form-management',
            screens: {
              Forms: 'forms',
              FormBlocks: 'formBlocks',
              QuestionBlocks: 'questionBlocks',
              FormPreview: 'formPreview'
            }
          }
        }
      }
    }
  }
}
