import React, { ReactNode } from 'react'
import { View } from 'react-native'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

interface ModalProps {
  children: ReactNode
  close: (val?) => void
  open: boolean
  styles?: any
  testID?: string
  testIDModal?: string
}

const Modal = ({
  children,
  open,
  close,
  styles,
  testID,
  testIDModal
}: ModalProps) => {
  return (
    <ReactModal
      testId={testIDModal}
      isOpen={open}
      onRequestClose={close}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0
        },
        content: {
          position: 'static',
          borderRadius: '10px',
          background: '#fff'
        }
      }}
    >
      <View
        testID={testID}
        style={[
          {
            minWidth: '350px',
            padding: '0 15px'
          },
          styles
        ]}
      >
        {children}
      </View>
    </ReactModal>
  )
}
export default Modal
