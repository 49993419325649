import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const deleteAnswerMutation = gql`
  mutation deleteAnswer($id: String!) {
    deleteAnswer(id: $id)
  }
`

const useDeleteAnswerMutation = (options = {}) => {
  const [deleteAnswer, { loading: loadingDeleteAnswer }]: any[] = useMutation(
    deleteAnswerMutation,
    options
  )

  return [deleteAnswer, loadingDeleteAnswer]
}

export default useDeleteAnswerMutation
