import gql from 'graphql-tag'
import useQuery from '../../../hooks/useQuery'
import useTranslation from '../../../hooks/useTranslation'

const getUnclaimableDomains = gql`
  query {
    unclaimableEmailDomains: unclaimableEmailDomains {
      domain
    }
    blacklistedDomains: getBlackListDomains {
      domain
    }
  }
`

const getRegistrationData = gql`
  query {
    countries: countries {
      id
      name
    }
    unclaimableEmailDomains: unclaimableEmailDomains {
      domain
    }

    origins: origins {
      id
      source
      trusted
    }

    terms: latestLegalDocument(type: innovationTerms) {
      id
    }

    privacy: latestLegalDocument(type: innovationPrivacy) {
      id
    }

    cookie: latestLegalDocument(type: innovationCookie) {
      id
    }
  }
`

const useRegistrationDataQuery = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(getRegistrationData, {
    errorMessage: t('error:auth:failedVerifyRegistration')
  })

  return { data, loading }
}

export default useRegistrationDataQuery

export const useUnclaimableDomainsQuery = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(getUnclaimableDomains, {
    errorMessage: t('error:auth:failedVerifyRegistration')
  })

  return { data, loading }
}
