import React from 'react'
import { QuestionGroupEntityMap } from '../../components/DynamicForm/types'
import { Form } from '../../types'

export enum FormTypeEnum {
  General = 'general',
  Healthcare = 'healthcare',
  Legal = 'legal',
  Marketing = 'marketing',
  Procurement = 'procurement',
  DiversityAndInclusion = 'diversity_and_Inclusion',
  CertificationInnovation = 'certification_innovation',
  CustomersInnovation = 'customers_innovation',
  CompetitorsInnovation = 'competitors_innovation',
  SubmissionReview = 'submission_review',
  CutFeedback = 'cut_feedback'
}

export interface QuestionGroupEntityMapValues
  extends Partial<QuestionGroupEntityMap> {
  questions?: QuestionGroupEntityMapValues[]
}

export interface FormValues extends Partial<Form> {
  questionGroupEntityMaps?: QuestionGroupEntityMapValues[]
}

interface BaseModalProps {
  formType?: FormTypeEnum
  isParent?: boolean
  isOpen: boolean
  onClose: () => void
}

export interface FormModalProps extends BaseModalProps {
  form?: FormValues
}

export enum ModalTabsEnum {
  General = 'general',
  Validations = 'validation',
  Dependencies = 'dependencies',
  Questions = 'questions',
  Blocks = 'blocks'
}
export interface ModalTabProps {
  tabKey: ModalTabsEnum
  label: string
  component: (props: any) => React.ReactNode
}
