import React from 'react'
import { View, TouchableOpacity, ViewStyle } from 'react-native'
import {
  MaterialTopTabBarProps,
  MaterialTopTabDescriptor,
  MaterialTopTabNavigationOptions
} from '@react-navigation/material-top-tabs/lib/typescript/src/types'
import { Text } from '../common/Text'
import styled from 'styled-components/native'

import { useUnsavedChangesDialog } from '../../hooks/useUnsavedChanges'
import { UnsavedChangesDialog } from './sharedComponents'
import useHistoryRoutes from '../../hooks/useHistoryRoutes'
import useTranslation from '../../hooks/useTranslation'
// import useMixpanel from '../../../hooks/useMixpanel'
// import { useNavigationState } from '@react-navigation/native'

interface TabOptions extends MaterialTopTabNavigationOptions {
  disabled?: boolean
  errorCount?: number
  tabContainerWidth?: ViewStyle
}

interface TabDescriptor extends MaterialTopTabDescriptor {
  options: TabOptions
}

export const Column = styled(View)`
  display: flex;
  flex-direction: column;
`

const TabBarRow = styled(View)`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    background-color: ${theme.colors.primaryPalette.white};
  `}
`

const TabContainer = styled(TouchableOpacity)`
  ${({ theme, isSelected, tabContainerWidth }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-width: ${isSelected ? 3 : 0}px;
    padding: ${theme.space[3]}px ${theme.space[5]}px;
    border: 0px solid ${theme.colors.blueBackground};
    ${tabContainerWidth ? `width: ${tabContainerWidth}px;` : ''}
  `}
`

export const TabBarLabel = styled(Text)`
  ${({ theme, isSelected, isDisabled, hasError }) => {
    let tabBarColor = theme.colors.inactive
    if (isDisabled) {
      tabBarColor = theme.colors.disabled
    } else if (hasError) {
      tabBarColor = theme.colors.danger
    } else if (isSelected) {
      tabBarColor = theme.colors.text
    }
    return `
    color: ${tabBarColor};
    font-size: ${theme.fontSizes[4]}px;
`
  }}
`

interface TopTabBarProps extends MaterialTopTabBarProps {
  bottomComponent?: any
  BottomComponent?: any
  tabContainerWidth?: string | number
}

const TopTabBar = ({
  state,
  descriptors,
  tabContainerWidth,
  navigation,
  BottomComponent = null
}: TopTabBarProps) => {
  const { pushToHistory } = useHistoryRoutes()
  const { t } = useTranslation()
  const route = state.routes[state.index]
  const descriptor: TabDescriptor = descriptors[route.key]

  if (descriptor?.options?.disabled) {
    navigation.navigate('ProductGeneral')
  }

  const {
    unsavedChanges,
    setRouteAndDialog,
    continueNavigation,
    showDialog,
    setShowDialog
  } = useUnsavedChangesDialog()

  return (
    <Column>
      <TabBarRow>
        {state.routes.map((route, index) => {
          const { options }: TabDescriptor = descriptors[route.key]
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name

          const isFocused = state.index === index

          const onPress = () => {
            if (options.disabled) {
              return
            }
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true
            })

            if (!isFocused && !event.defaultPrevented) {
              if (unsavedChanges.hasUnsavedChanges) {
                setRouteAndDialog(route.name, true)
              } else {
                pushToHistory({
                  screen: route.name
                })
                navigation.navigate(route.name)
              }
            }
          }

          const onLongPress = () => {
            pushToHistory({
              screen: route.name
            })
            navigation.emit({
              type: 'tabLongPress',
              target: route.key
            })
          }

          let testID: string | null = null
          switch (label) {
            case t('curation:tabs:submissions'):
              testID = 'submissionsTab'
              break
            case t('curation:tabs:preCuration'):
              testID = 'preCurationTab'
              break
            case t('curation:tabs:advising'):
              testID = 'advisingTab'
              break
            case t('curation:tabs:cohort'):
              testID = 'cohortTab'
              break
            case t('curation:tabs:presentations'):
              testID = 'presentationsTab'
              break
          }

          return (
            <TabContainer
              key={index}
              tabContainerWidth={tabContainerWidth}
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={testID}
              onPress={onPress}
              onLongPress={onLongPress}
              isSelected={isFocused}
            >
              <TabBarLabel
                isSelected={isFocused}
                isDisabled={!!options.disabled}
                hasError={!!options.errorCount}
              >
                {label}
              </TabBarLabel>
            </TabContainer>
          )
        })}
      </TabBarRow>

      {BottomComponent}

      <UnsavedChangesDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        discardChangesCallback={() => {
          unsavedChanges.discardCallback()
          continueNavigation(navigation.navigate)
        }}
        saveChangesCallback={async () => {
          await unsavedChanges.saveCallback()
          continueNavigation(navigation.navigate)
        }}
      />
    </Column>
  )
}

export default TopTabBar
