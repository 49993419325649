import React from 'react'
import styled from 'styled-components/native'
// Components/Types
import TableSkeleton from './TableSkeleton'
import { Col, RowSkeleton } from '../../components/skeletonLoadings/components'
import { SkeletonTemplateProps } from '../../components/skeletonLoadings/types'

// Style
const RowContainer = styled.View`
  ${({ theme: { space, containerRadius } }) => `
    margin-bottom: ${space[2]}px;
    border-radius: ${containerRadius}px;
  `}
`
const Container = styled(RowContainer)`
  ${({ theme: { space }, padding }) => `
    padding: ${padding || '0px'}
    margin-bottom: ${space[2]}px;
    border-radius: 0px;
  `}
`

const TextRowSkeleton = styled(RowSkeleton)`
  ${({ theme: { space } }) => ` 
    margin-bottom: ${space[3] + space[2]}px;
  `}
`

interface SubmitterHomeSkeletonProps extends SkeletonTemplateProps {
  numberOfRows?: number
}

const SubmitterHomeSkeleton = React.memo(
  (props: SubmitterHomeSkeletonProps) => {
    const {
      theme: { sizes },
      numberOfRows = 4
    } = props
    return (
      <Container marginBottom="0" padding="15px">
        <TextRowSkeleton width="50%" height={sizes[3] + sizes[1]} />
        <TextRowSkeleton width="40%" height={sizes[3] + sizes[1]} />
        <Col>
          <TextRowSkeleton width="35%" height={sizes[2]} />
          <TextRowSkeleton width="30%" height={sizes[2]} />
          <TextRowSkeleton width="35%" height={sizes[2]} />
          <TextRowSkeleton width="30%" height={sizes[2]} />
          <TextRowSkeleton width="35%" height={sizes[2]} />
          <TextRowSkeleton width="30%" height={sizes[2]} />
        </Col>
        <Col marginTop={50}>
          <TableSkeleton numberOfRows={numberOfRows} showIcon />
        </Col>
      </Container>
    )
  }
)

export default SubmitterHomeSkeleton
