import { RoleEnum } from '../types'

export const CURATION_PERMISSIONS = [
  RoleEnum.InnovationStaff,
  RoleEnum.InnovationAdvisor,
  RoleEnum.Admin,
  RoleEnum.InnovationAdmin
]

export const STAFF_PERMISSIONS = [
  RoleEnum.InnovationStaff,
  RoleEnum.Admin,
  RoleEnum.InnovationAdmin
]

export const ADVISORS_PERMISSIONS = [
  RoleEnum.InnovationAdvisor,
  RoleEnum.Admin,
  RoleEnum.InnovationAdmin
]

export const INNOVATOR_TEAM_ADMIN_PERMISSIONS = [RoleEnum.InnovatorTeamAdmin]
export const INNOVATOR_TEAM_MEMBER_PERMISSIONS = [RoleEnum.InnovatorTeamMember]
export const INNOVATOR_TEAM_PERMISSIONS = [
  ...INNOVATOR_TEAM_ADMIN_PERMISSIONS,
  ...INNOVATOR_TEAM_MEMBER_PERMISSIONS
]
