import gql from 'graphql-tag'
// Hooks
import useQuery from '../../../../../../hooks/useQuery'
import useTranslation from '../../../../../../hooks/useTranslation'
// Types
import { Question } from '../../../../../../components/DynamicForm/types'
import QuestionFieldsFragment from '../../../../../../graphql/QuestionFieldsFragment'
import { FormTypeEnum } from '../../../types'

const questionsQuery = gql`
  query getQuestions(
    $parent: Boolean
    $formType: FormTypeEnum
    $formId: String
  ) {
    getQuestions(parent: $parent, formType: $formType, formId: $formId) {
      ...QuestionFieldsFragment
    }
  }
  ${QuestionFieldsFragment}
`

interface QuestionsResult {
  loading?: boolean
  questions: Question[]
}

const useGetQuestions = (
  parent = true,
  formType?: FormTypeEnum,
  formId?: string | null,
  options = {}
): QuestionsResult => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(questionsQuery, {
    variables: { parent, formType, formId },
    errorMessage: t('error:general:questions:error'),
    fetchPolicy: 'cache-and-network',
    ...options
  })

  return {
    questions: data?.getQuestions,
    loading
  }
}

export default useGetQuestions
