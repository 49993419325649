import React, { FC, useMemo, useState } from 'react'
import { prop, uniqBy } from 'ramda'

import withNavigationPermissions from '../../navigation/withNavigationPermissions'

import { ScreenContainerWithTabs } from '../../components/layout/ScreenContainer'
import { useTable, DynamicTable } from '../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'

import { Flex } from '../../components/FlexBox'
import { Container } from '../CallForSubmission/components/SharedComponents'
import { TableContainer } from '../../components/StaticForm'
import Dropdown from '../../components/common/Dropdown'

import { getCohortSubmissionTableConfig } from './constants'
import { StyledLabel } from '../../ui-library/TextInput/Label'
import { InnovationAccessKey } from '../../types'
import useTranslation from '../../hooks/useTranslation'
import { EngineEnum } from '../CallForSubmission/constants'
import { SubmissionsProps } from './Precuration'
import { QuestionTypeEnum } from '../../types/form'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'
import useSelectSubmissionForCohortMutation from './hooks/useSelectSubmissionForCohortMutation'
import useClearSubmissionAssignmentToCohortVotingMutation from './hooks/useClearSubmissionAssignmentToCohortVoting'

const CohortScreen: FC<SubmissionsProps> = ({
  id,
  role,
  currentCFSStep,
  delegations,
  reviewFormQuestions,
  callForSubmission
}) => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { t } = useTranslation()
  const { upsertSubmissionGroupMap } = useSelectSubmissionForCohortMutation()
  const {
    clearSubmissionAssignmentToCohortVoting
  } = useClearSubmissionAssignmentToCohortVotingMutation()

  const handleUpdateCohortSelection = async variables => {
    const clearVariables = {
      groupId: variables.group?.id,
      submissionId: variables.submissionId
    }
    let errors
    if (variables.isSelected === true) {
      errors = { errors } = await clearSubmissionAssignmentToCohortVoting({
        variables: clearVariables
      })
    } else {
      errors = { errors } = await upsertSubmissionGroupMap({ variables })
    }
    return !!errors
  }

  const groupList = useMemo(() => {
    return uniqBy(
      prop('id'),
      delegations.reduce((groups, delegation) => {
        return [
          ...groups,
          ...delegation.groups.map(cohort => ({
            label: cohort.shortName,
            value: cohort,
            ...cohort
          }))
        ]
      }, [])
    )
  }, [delegations])

  const cohortPassCutFilterOptions = [
    {
      label: t('curation:cohort:table:pass'),
      value: 'PASS'
    },
    {
      label: t('curation:cohort:table:cut'),
      value: 'CUT'
    }
  ]

  const [groupFilteredBy, setGroupFilterBy] = useState<any>(
    groupList && groupList.length ? groupList[0]?.value : null
  )

  const [passCutFilteredBy, setpassCutFilterBy] = useState<string>()

  const reviewQuestions =
    reviewFormQuestions?.filter(q => q.type === QuestionTypeEnum.Rating) || []

  const tableProps = useTable({
    config: getCohortSubmissionTableConfig({
      groupList: groupFilteredBy ? [groupFilteredBy] : groupList,
      cohortId: groupFilteredBy?.id || undefined,
      reviewQuestions,
      currentCFSStep,
      cfsId: id,
      role,
      engine: EngineEnum.InnovationSubmission,
      callForSubmission,
      isInnovationNewDynamicTable,
      cohortPassOrCut: passCutFilteredBy,
      handleUpdateCohortSelection
    })
  })

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable
  return (
    <ScreenContainerWithTabs>
      <Container>
        <Flex flexDirection="row" alignItems="center" marginBottom={16}>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
          <Dropdown
            value={groupFilteredBy}
            placeholder={t('curation:cohort:table:cohortFilter')}
            options={groupList}
            onSelect={setGroupFilterBy}
            isFilter
            style={{
              marginBottom: 0,
              marginLeft: 16,
              minHeight: 0
            }}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
          />
          <Dropdown
            name={'Pass/Cut'}
            value={passCutFilteredBy}
            placeholder={t('curation:cohort:table:passCutFilter')}
            options={cohortPassCutFilterOptions}
            onSelect={setpassCutFilterBy}
            isFilter
            isClearable
            style={{ marginBottom: 0, marginLeft: 32, minHeight: 0 }}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
          />
        </Flex>
        <TableContainer height="100%">
          <TableComponent
            {...tableProps}
            emptyMessage={t('curation:cohort:noSubmissions')}
          />
        </TableContainer>
      </Container>
    </ScreenContainerWithTabs>
  )
}

const CohortPage = withNavigationPermissions(CohortScreen, [
  InnovationAccessKey.CURATE
])

export default CohortPage
