import { atomFamily, CallbackInterface } from 'recoil'
import { sortDir } from '../components/DynamicTable/types'
import { EngineEnum } from '../screens/CallForSubmission/constants'

export interface OutsideFetchMoreResultsProps {
  variables: {
    offset: number
    size: number
    sort: [{ sortBy: string; sortDir: sortDir }]
    engine: EngineEnum
  }
}

export interface FetchMoreResultsByName {
  fetchMoreResults: (props: OutsideFetchMoreResultsProps) => Promise<void>
}

const DEFAULT_VALUE: FetchMoreResultsByName = {
  fetchMoreResults: async () => {}
}

export const fetchMoreResultsByName = atomFamily<
  FetchMoreResultsByName,
  string
>({
  key: 'fetchMoreResultsByName',
  default: (name: string) => ({
    ...DEFAULT_VALUE,
    name
  })
})

export const setFetchMoreResultsByName = ({ set }: CallbackInterface) => (
  name: string,
  fetchMoreResults: FetchMoreResultsByName
) => {
  set(fetchMoreResultsByName(name), fetchMoreResults)
}
