import React from 'react'
import styled, { useTheme } from 'styled-components/native'

import useTranslation from '../../hooks/useTranslation'

import Button from '../../ui-library/Button'
import RowLayout from './components/layout/RowLayout'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import { Text } from '../../components/common/Text'
import { Platform, View } from 'react-native'

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const Content = styled(Text)`
  ${({ theme }) => `
    text-align: center;
    color: ${theme.colors.primaryPalette.white};
    margin-bottom: ${theme.space[4]}px;
    font-size: ${theme.sizes[4]}px;
`}
`

const UnclaimableDomain = () => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const { fontSizes } = useTheme()

  const handleClose = async () => {
    if (Platform.OS === 'web') {
      document.location.reload()
    }
  }

  return (
    <RowLayout>
      <Content>{t('common:unclaimableDomain')}</Content>
      <Container>
        <Button
          title={t('common:buttons:close')}
          onPress={handleClose}
          containerStyle={{
            flexBasis: isSmallScreen ? '30%' : '20%'
          }}
          buttonStyle={{
            width: '100%'
          }}
          titleStyle={{
            fontSize: fontSizes[4]
          }}
        />
      </Container>
    </RowLayout>
  )
}

export default UnclaimableDomain
