import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import useFeatureFlag from '../../../hooks/useFeatureFlag'
import useTranslation from '../../../hooks/useTranslation'
import useDebounce from '../../../hooks/useDebounce'

import Modal from '../components/Modal'
import { TableContainer } from '../../../components/StaticForm'
import { DynamicTable, useTable } from '../../../components/Table'
import { NEW_DYNAMIC_TABLE_FF } from '../../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../../components/DynamicTable'

import TableSkeleton from '../../../screens/HomeScreen'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'

import { CreateIcon } from '../../PlatformManagement/constants'
import { FormValues } from '../../PlatformManagement/FormsManagement/types'
import TextInput from '../../../ui-library/TextInput'
import { getFormsTableConfig } from '../components/Form/constants'
import useFormsQuery from '../../../screens/Product/hooks/useFormsQuery'
import { Form } from '../../../types'

const FormTableContainer = styled(View)`
  width: 100%;
  height: 100%;
  ${({ theme: { colors } }) => `
    background: ${colors.appBackground};
  `}
`

const SearchRow = styled(View)`
  width: 100%;
  flex-direction: row;
  ${({ theme: { sizes } }) => `
    height: ${sizes[3] + sizes[4] + 2}px;
    marginTop: ${sizes[1] + sizes[2] - 2}px;
    paddingHorizontal: ${sizes[1] + sizes[2] - 2}px;
  `}
`

const Search = styled(View)`
  height: 90%;
  width: 80%;
  flex-direction: row;
  ${({ theme: { sizes } }) => `
    marginHorizontal: ${sizes[1] + sizes[2] - 2}px;
  `}
`

const Table = styled(View)`
  flex: 1;
  ${({ theme: { sizes } }) => `
    paddingHorizontal: ${sizes[1] + sizes[2] - 2}px;
  `}
`

const FormTab = () => {
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentForm, setCurrentForm] = useState<FormValues | undefined>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { forms, loading: fetchLoading } = useFormsQuery()

  const [search, setSearch] = useState<string | undefined>(undefined)

  const debouncedSearch = useDebounce(search, 300)

  const [data, setData] = useState<Form[]>(forms)

  useEffect(() => {
    if (fetchLoading && !isLoading) {
      setIsLoading(true)
    }
  }, [fetchLoading, isLoading])

  useEffect(() => {
    if (!fetchLoading && forms.length) {
      let newData: Form[] = forms ?? []

      if (debouncedSearch != null) {
        newData = newData.filter(question => {
          const allTerms = [
            ...Object.keys(question),
            ...Object.values(question).map(
              value => String(JSON.stringify(value)) ?? String(value)
            )
          ]
          const includeTerm = allTerms.find(term =>
            term.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
          if (includeTerm) {
            return true
          } else {
            return false
          }
        })
      }
      setData(newData)
      setIsLoading(false)
    }
  }, [forms, fetchLoading, debouncedSearch])

  const handleEditForm = (form: FormValues) => {
    setCurrentForm(form)
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const handleCreateForm = () => {
    setCurrentForm(undefined)
    setIsOpen(true)
  }

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps = useTable({
    data,
    config: getFormsTableConfig({
      handleEditForm
    })
  })

  return (
    <FormTableContainer>
      <SearchRow>
        <Search>
          <TextInput
            style={{
              minHeight: 0,
              alignSelf: 'center'
            }}
            containerStyles={{
              width: 250,
              height: 50
            }}
            placeholder="Search..."
            value={search}
            onChangeText={setSearch}
          />
        </Search>
        <CreateIcon
          style={{ alignSelf: 'flex-end' }}
          disabled={false}
          isLoading={false}
          title={t('platformManagement:forms:menuItems:forms:addNew')}
          onPress={handleCreateForm}
        />
      </SearchRow>

      <Table>
        <SkeletonContainer isLoading={isLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t('platformManagement:categories:noCategories')}
            />
          </TableContainer>
        </SkeletonContainer>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose} form={currentForm} />
    </FormTableContainer>
  )
}

export default FormTab
