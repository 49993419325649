import React, { useState, useRef, useEffect } from 'react'

import { View, Linking, Platform } from 'react-native'
import {
  useRoute,
  getFocusedRouteNameFromRoute,
  useNavigation
} from '@react-navigation/native'
import styled from 'styled-components/native'
import { useRecoilValue } from 'recoil'
// Hooks
import useToast from '../../../hooks/useToast'
import useOnScreen from '../../../hooks/useOnScreen'
import useTranslation from '../../../hooks/useTranslation'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import useInnovationAccess from '../../../hooks/useInnovationAccess'
import { useUnsavedChangesDialog } from '../../../hooks/useUnsavedChanges'
import useIsFormManagementEnabled from '../../../hooks/useIsFormManagementEnabled'
import useIsPlatformManagementEnabled from '../../../hooks/useIsPlatformManagementEnabled'
// Recoil
import { currentFormResetAtom } from '../../../recoil/productFormsAtom'
// Components
import Icon from '../../icon'
import MenuItem from './MenuItem'
import Firebase from '../../../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import client from '../../../API'
import { UnsavedChangesDialog } from '../sharedComponents'
import Header from './Header'
import Logo from './Logo'
import ScheduleCallBtn from './ScheduleCallBtn'
import NewGradient from '../../../screens/authentication/components/sharedComponents/NewGradient'

const Container = styled.ScrollView.attrs(() => ({
  contentContainerStyle: {
    height: '100%'
  }
}))`
  flex: 1;
  background-color: #000;
  background-image: url('${require('../../../../assets/images/footer.png')}');
  background-size: 1500px;
  background-position: bottom center;
  background-repeat: no-repeat;
`

const NavMenu = styled(View)`
  ${({ theme: { space } }) => `
    margin: ${space[3]}px 0;
    align-items: flex-start;
    height: 97%;
  `}
`

const SideBar = () => {
  // States
  // States
  const [isBottomBtnVisible, setBottomBtnVisible] = useState<boolean>(true)
  // Hooks
  const { track } = useMixpanel()
  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()
  const { setCurrentUser, currentUser } = useCurrentUser()
  const innovationAccess = useInnovationAccess()
  const linkToScreen = useLinkToScreen()
  const route = useRoute()
  const navigation = useNavigation()
  const resetFormErrors = useRecoilValue(currentFormResetAtom)
  const scheduleAdvisingCallEnabled = useFeatureFlag(
    'innovationScheduleAdvisingCall'
  )
  const isFormManagementEnabled = useIsFormManagementEnabled()
  const isInnovationPlatformManagementEnabled = useIsPlatformManagementEnabled()
  // If the focused route is not found, we need to assume it's the initial screen
  // This can happen during if there hasn't been any navigation inside the screen
  // In our case, it's "Home" as that's the first screen inside the navigator
  const currentRouteName = getFocusedRouteNameFromRoute(route) ?? 'Home'

  // This is done as react-navigation doesn't provide the current route when using nested navigators
  const getActualRoute = navigation => {
    const state = navigation.getState()
    let actualRoute = state.routes[state.index]

    while (actualRoute.state && actualRoute.state.index !== undefined) {
      actualRoute = actualRoute.state.routes[actualRoute.state.index]
    }

    return actualRoute?.params?.screen || actualRoute?.name
  }

  const currentNestedRouteName = getActualRoute(navigation)

  const {
    unsavedChanges,
    setRouteAndDialog,
    continueNavigation,
    showDialog,
    setShowDialog,
    resetUnsavedChanges
  } = useUnsavedChangesDialog()

  const logout = async () => {
    resetUnsavedChanges()
    setCurrentUser(null)
    await getFBAuth(Firebase).signOut()
    track(EVENT_MAP.auth.logout)
    await client.clearStore()
    // clear any toast message remaining
    setToastErrorMessage('', 0)
  }

  const onPressHandler = (routeName, params = {}) => () => {
    if (unsavedChanges.hasUnsavedChanges) {
      setRouteAndDialog(routeName, true)
    } else {
      resetFormErrors && resetFormErrors()
      linkToScreen(routeName, params || undefined)
    }
  }

  const supportMailTo =
    'mailto:innovator-support@world50.com?subject=Innovation app support request'
  const supportHandler = () => {
    if (Platform.OS === 'web') {
      window.open(supportMailTo, '_blank')
    } else {
      Linking.openURL(supportMailTo)
    }
  }

  const isActive = (baseRouteName, nestedRoutes: string[] = []) =>
    baseRouteName === currentRouteName ||
    // @ts-ignore
    nestedRoutes.includes(currentNestedRouteName)

  const menuItems = [
    {
      id: 'home',
      label: t('common:buttons:home'),
      icon: <Icon name="home" width={28} height={28} />,
      onPress: onPressHandler('Home'),
      isActive: isActive('Home')
    }
  ]

  if (currentUser?.innovationAccess?.innovatorTeam) {
    menuItems.push(
      {
        id: 'callsForSubmission',
        label: t('callsForSubmission:title'),
        icon: <Icon name="callsForSubmission" width={28} height={28} />,
        onPress: onPressHandler('CallForSubmissionList'),
        isActive: isActive('CallForSubmission')
      },
      {
        id: 'submissions',
        label: t('submissions:title'),
        icon: <Icon name="submissions" width={28} height={21} />,
        onPress: onPressHandler('SubmissionList'),
        isActive: isActive('Submission')
      },
      {
        id: 'directoryProfile',
        label: t('directoryProfile:title'),
        icon: <Icon name="directoryProfile" width={28} height={21} />,
        onPress: onPressHandler('InnovatorDirectoryProfile', {
          companyId: currentUser?.person?.innovationCompany?.id
        }),
        isActive: isActive('', [
          'InnovatorDirectoryProfile',
          'InnovatorDirectoryDetails'
        ])
      },
      {
        id: 'settings',
        label: t('settings:members:title'),
        icon: <Icon name="teamMembers" width={28} height={21} />,
        onPress: onPressHandler('InnovatorDirectoryMembers', {
          companyId: currentUser?.person?.innovationCompany?.id
        }),
        isActive: isActive('', ['InnovatorDirectoryMembers'])
      }
    )
  }

  if (innovationAccess?.directory) {
    menuItems.push({
      id: 'innovatorDirectory',
      label: t('innovatorDirectory:title'),
      icon: <Icon name="directoryProfile" width={28} height={21} />,
      onPress: onPressHandler('InnovatorDirectoryList'),
      isActive: isActive('InnovatorDirectory')
    })
  }

  if (innovationAccess?.curate) {
    menuItems.push({
      id: 'curate',
      label: t('curation:sideBar:curate'),
      icon: <Icon name="curate" width={24} height={24} />,
      onPress: onPressHandler('CurateCFSList'),
      isActive: isActive('Curate', [
        'CurateCFSList',
        'CurationGeneral',
        'CurationSubmissions',
        'CurationPreCuration',
        'CurationCohort',
        'CurationPresentations'
      ])
    })
  }

  if (innovationAccess?.advise) {
    menuItems.push({
      id: 'advise',
      label: t('curation:sideBar:advise'),
      icon: <Icon name="advise" width={24} height={24} />,
      onPress: onPressHandler('AdviseCFSList'),
      isActive: isActive('Advise', [
        'AdviseCFSList',
        'AdviseSubmissions',
        'AdviseSubmissionDetails'
      ])
    })
  }

  menuItems.push({
    id: 'support',
    label: t('common:buttons:support'),
    icon: <Icon name="support" width={28} height={28} />,
    onPress: supportHandler,
    isActive: false
  })

  if (isFormManagementEnabled) {
    menuItems.push({
      id: 'formManagement',
      label: t('formManagement:title'),
      icon: <Icon name="form" width={24} height={24} />,
      onPress: onPressHandler('FormManagement'),
      isActive: isActive('FormManagement', [
        'Forms',
        'FormBlocks',
        'QuestionBlocks',
        'FormPreview'
      ])
    })
  }

  if (isInnovationPlatformManagementEnabled) {
    menuItems.push({
      id: 'platformManagement',
      label: t('platformManagement:title'),
      icon: <Icon name="cog" width={24} height={24} />,
      onPress: onPressHandler('CategoryManagement'),
      isActive: isActive('PlatformManagement', [
        'CategoryManagement',
        'FormsManagement'
      ])
    })
  }

  menuItems.push({
    id: 'logout',
    label: t('common:buttons:logout'),
    icon: <Icon name="logOut" width={28} height={18} />,
    onPress: logout,
    isActive: false
  })

  const menu = menuItems.map(
    ({ id, label, icon, onPress, isActive }, index) => (
      <MenuItem
        key={`${label}-${index}`}
        testID={`nav_tab_item_${id}`}
        label={label}
        Icon={icon}
        onPress={onPress}
        highlight={isActive}
      />
    )
  )

  return (
    <Container>
      <NewGradient>
        <NavMenu testID={'navContainer'}>
          <Logo />
          {menu}
          {scheduleAdvisingCallEnabled && !isBottomBtnVisible ? (
            <ScheduleCallBtn />
          ) : null}
          <Header />
          {scheduleAdvisingCallEnabled && isBottomBtnVisible ? (
            <ScheduleCallBtn />
          ) : null}
          <VisibilityValidator
            onVisibleChange={isVisible => {
              setBottomBtnVisible(isVisible)
            }}
          />
        </NavMenu>
        <UnsavedChangesDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          discardChangesCallback={() => {
            unsavedChanges.discardCallback()
            continueNavigation(linkToScreen)
          }}
          saveChangesCallback={async () => {
            await unsavedChanges.saveCallback()
            continueNavigation(linkToScreen)
          }}
        />
      </NewGradient>
    </Container>
  )
}

const VisibilityValidatorView = styled.View`
  width: 100%;
  ${({ theme: { sizes } }) => `
    height: ${sizes[1]}px;
  `}
`

const VisibilityValidator = ({
  onVisibleChange
}: {
  onVisibleChange?: (isVisible: boolean) => void
}) => {
  // Refs
  const visibilityValidatorRef = useRef(null)
  // Hooks
  const isVisible = useOnScreen(visibilityValidatorRef)
  // Effects
  useEffect(() => {
    onVisibleChange?.(isVisible)
  }, [isVisible])

  return <VisibilityValidatorView ref={visibilityValidatorRef} />
}

export default SideBar
