import gql from 'graphql-tag'

import { callForSubmissionQuery } from './useCallForSubmissionQuery'

import useTranslation from '../../../hooks/useTranslation'
import useMutation from '../../../hooks/useMutation'

export const upsertCallForSubmissionMutation = gql`
  mutation upsertCallForSubmission(
    $id: String
    $name: String!
    $academyElegible: Boolean!
    $shortlistLimit: Int
    $favoritesLimit: Int
    $delegationIds: [String]
    $originSource: OriginSourceEnum
    $stepDateRanges: [StepDateRangeUpsertInput]
    $advisors: [String]
    $reviewFormId: String
    $advisorMaxFav: Int
    $formId: String
    $disclaimer: Boolean
    $disclaimerEventLocation: String
    $disclaimerInnovatorFee: String
    $disclaimerContactEmail: String
  ) {
    upsertCallForSubmission(
      id: $id
      name: $name
      shortlistLimit: $shortlistLimit
      favoritesLimit: $favoritesLimit
      academyElegible: $academyElegible
      delegationIds: $delegationIds
      originSource: $originSource
      stepDateRanges: $stepDateRanges
      advisors: $advisors
      advisorMaxFav: $advisorMaxFav
      reviewFormId: $reviewFormId
      formId: $formId
      disclaimer: $disclaimer
      disclaimerEventLocation: $disclaimerEventLocation
      disclaimerInnovatorFee: $disclaimerInnovatorFee
      disclaimerContactEmail: $disclaimerContactEmail
    ) {
      id
      academyElegible
      shortlistLimit
      favoritesLimit
      advisorMaxFav
      stepDateRanges {
        id
        curationStep
        openAt
        closeAt
      }
      disclaimer
      disclaimerEventLocation
      disclaimerInnovatorFee
      disclaimerContactEmail
    }
  }
`

const useUpsertCallForSubmissionMutation = (
  cfsId?: string,
  refetchOnMutationIsCompleted = true
) => {
  const { t } = useTranslation()

  const [upsertCallForSubmission, { loading }] = useMutation(
    upsertCallForSubmissionMutation,
    {
      ...(refetchOnMutationIsCompleted
        ? {
            refetchQueries: [
              {
                query: callForSubmissionQuery,
                variables: {
                  id: cfsId,
                  withSubmissionReviews: false,
                  withSubmissionReviewsAverages: false,
                  withInnovationSubmissions: true
                }
              }
            ]
          }
        : {}),
      errorMessage: t('error:save:default')
    }
  )

  return {
    upsertCallForSubmission,
    loading
  }
}

export default useUpsertCallForSubmissionMutation
