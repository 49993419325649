import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const deleteAlumniMembershipMutation = gql`
  mutation deleteAlumniMembership($id: String!) {
    deleteAlumniMembership(id: $id) {
      id
    }
  }
`

const useDeleteAlumniMembershipMutation = (options = {}) => {
  const [
    deleteAlumniMembership,
    { loading: loadingDeleteAlumniMembership }
  ]: any[] = useMutation(deleteAlumniMembershipMutation, options)

  return [deleteAlumniMembership, loadingDeleteAlumniMembership]
}

export default useDeleteAlumniMembershipMutation
