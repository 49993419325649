import React, { useCallback } from 'react'
import { ActivityIndicator, View, Platform } from 'react-native'
import { Trans } from 'react-i18next'
import styled, { useTheme } from 'styled-components/native'

import { Text } from '../../components/common/Text'
import useTranslation from '../../hooks/useTranslation'
import RowLayout from './components/layout/RowLayout'
import PageHeader from './components/sharedComponents/PageHeader'
import useRegistrationDataQuery from './queries/useRegistrationDataQuery'
import useUpdateLegalMutation from './mutations/useUpdateLegal'
import Button from '../../ui-library/Button'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import { TermsLink } from './components/sharedComponents/FooterLinks'

const TransContainer = styled(View)`
  display: flex;
  flex-direction: row;
  color: #ffffff;
  margin-bottom: 10px;
  ${Platform.OS === 'web' && 'white-space: pre;'}
`

const TermsText = styled(Text)`
  color: #ffffff;
  margin-bottom: 10px;
`

const NewTermsScreen = ({ send }) => {
  const { sizes, fontSizes } = useTheme()
  const { data, loading } = useRegistrationDataQuery()
  const [upadateLegal, termsLoading] = useUpdateLegalMutation({
    onCompleted: () => {
      send('TERMS_VERIFIED')
    }
  })
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()

  const handleSubmit = useCallback(() => {
    upadateLegal({
      variables: {
        termsId: data?.terms?.id,
        privacyId: data?.privacy?.id,
        cookieId: data?.cookie?.id
      }
    })
  }, [data, upadateLegal])

  return (
    <RowLayout>
      <PageHeader title={t('auth:verifyTerms:header')} />
      {loading ? (
        <ActivityIndicator size="large" />
      ) : (
        <>
          <TransContainer>
            <TermsText>
              <Trans
                i18nKey="verifyTerms"
                defaults={t('auth:verifyTerms:revised')}
                components={{
                  linkOne: (
                    <TermsLink to="/legal/terms" title="legal:tos:title" />
                  ),
                  linkTwo: (
                    <TermsLink
                      to="/legal/privacy"
                      title="legal:privacy:title"
                    />
                  ),
                  linkThree: (
                    <TermsLink to="/legal/cookie" title="legal:cookies:title" />
                  )
                }}
              />
            </TermsText>
          </TransContainer>
          <TermsText>{t('auth:verifyTerms:accepting')}</TermsText>
          <TermsText>{t('auth:verifyTerms:thanks')}</TermsText>
          <Button
            title={t('auth:verifyTerms:buttonLabel')}
            onPress={handleSubmit}
            isProcessing={termsLoading}
            containerStyle={{
              flexBasis: isSmallScreen ? '50%' : '40%',
              marginTop: sizes[3],
              padding: sizes[3]
            }}
            buttonStyle={{
              width: '100%'
            }}
            titleStyle={{
              fontSize: fontSizes[4]
            }}
          />
        </>
      )}
    </RowLayout>
  )
}

export default NewTermsScreen
