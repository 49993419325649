import React, { FC, useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'

import useTranslation from '../../../hooks/useTranslation'
import useToast from '../../../hooks/useToast'

import { ScreenContainerWithTabs } from '../../../components/layout/ScreenContainer'
import AdvisorUsersSelector from './components/AdvisorUsersSelector'
import useUpsertCallForSubmissionAdvisorsMutation from '../../Curation/hooks/useUpsertCallForSubmissionAdvisorsMutation'
import {
  ActionContainer,
  HorizontalSeparator,
  QuestionBlock,
  ScrollViewContent,
  WarningContainer,
  WarningText
} from './components/SettingsStyledComponents'
import {
  CallForSubmission,
  CallForSubmissionStepEnum,
  InnovationAccessKey
} from '../../../types'
import withNavigationPermissions from '../../../navigation/withNavigationPermissions'
import { GeneralSettingsSkeleton } from './components/Skeleton'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import Button from '../../../ui-library/Button'
import { useFormik } from 'formik'
import useUpsertCallForSubmissionAdvisorSettingsMutation from '../../Curation/hooks/useUpsertCallForSubmissionAdvisorSettingsMutation'
import { Question, QuestionTypeEnum } from '../../../types/form'
import {
  generateAdvisorSettingsInitialValues,
  getAdvisorSettingsValidationSchema
} from './formUtils'
import AdvisingCriteriaSection from './components/AdvisingCriteriaSection'
import useInnovationAccess from '../../../hooks/useInnovationAccess'
import changeText, { removeNonNumeric } from '../../../utils/changeFormikText'

interface AdvisorSettingsProps {
  callForSubmission: CallForSubmission
  isLoading: boolean
}

interface QuestionWeightField {
  id: string
  questionText: string
  weight: number
}

interface GeneralFormValues {
  advisorMaxFav: number
  questionsWeights: QuestionWeightField[]
}

const getReviewQuestionWeights = (
  reviewQuestions: Question[] | undefined
): Question[] => {
  const reviewQuestionsWithWeights = reviewQuestions?.filter(
    question =>
      (question.type === QuestionTypeEnum.Rating ||
        question.type === QuestionTypeEnum.Checkbox) &&
      !question.questionText?.toLowerCase().includes('academy')
  )

  return reviewQuestionsWithWeights || []
}

// @ts-ignore
const getNumber = (val: string | number) => parseInt(val, 10)

const StepsToWarn = [CallForSubmissionStepEnum.voting]

const AdvisorSettings: FC<AdvisorSettingsProps> = ({
  isLoading,
  callForSubmission
}) => {
  const { t } = useTranslation()
  const { space } = useTheme()
  const { setToastMessage } = useToast()
  const navigation = useNavigation()
  const {
    upsertCallForSubmissionAdvisorSettings,
    loading: isProcessing
  } = useUpsertCallForSubmissionAdvisorSettingsMutation(callForSubmission?.id)
  const {
    callForSubmissionAdvisingSettings: canEditAdvisingCriteria,
    advisorAssignment: canAssignAdvisors
  } = useInnovationAccess()
  const {
    upsertCallForSubmissionAdvisors,
    loading: loadingCallForSubmissionAdvisors
  } = useUpsertCallForSubmissionAdvisorsMutation(callForSubmission?.id)

  const [shouldWarnFromChanges, setShouldWarnFromChanges] = useState(false)
  const [selectionChange, setSelectionChange] = useState(false)
  const [[toAdd, toRemove], setAdvisorsData] = useState<[string[], string[]]>([
    [],
    []
  ])
  const [defaultValues, setDefaultValues] = useState<GeneralFormValues>({
    advisorMaxFav: 3,
    questionsWeights: []
  })

  useEffect(() => {
    const questionsWeights = getReviewQuestionWeights(
      callForSubmission.reviewFormQuestions
    )

    setDefaultValues(
      // @ts-ignore
      generateAdvisorSettingsInitialValues(
        questionsWeights,
        callForSubmission.advisorMaxFav
      )
    )

    if (callForSubmission?.currentStep?.name) {
      setShouldWarnFromChanges(
        callForSubmission?.currentStep?.name
          ? StepsToWarn.includes(callForSubmission?.currentStep?.name)
          : true
      )
    }
  }, [callForSubmission])

  const handleSaveAdvisors = async () => {
    const { data } = await upsertCallForSubmissionAdvisors({
      variables: {
        callForSubmissionId: callForSubmission.id,
        toAddAdvisorsIds: toAdd,
        toRemoveAdvisorsIds: toRemove
      }
    })
    if (data && data?.upsertCallForSubmissionAdvisors) {
      setToastMessage(t(`callsForSubmission:success:changesSaved`))
    }
    setSelectionChange(false)
  }

  const {
    values,
    errors,
    setFieldValue,
    submitForm,
    dirty,
    resetForm,
    isValid
  } = useFormik<GeneralFormValues>({
    validationSchema: getAdvisorSettingsValidationSchema(),
    // @ts-ignore
    initialValues: defaultValues,
    enableReinitialize: true,
    onSubmit: async ({
      advisorMaxFav,
      questionsWeights
    }: GeneralFormValues) => {
      const variables = {
        id: callForSubmission.id,
        advisorMaxFav: getNumber(advisorMaxFav),
        questionsWeights: questionsWeights.map(qw => ({
          id: qw.id,
          weight: getNumber(qw.weight)
        }))
      }

      const { data } = await upsertCallForSubmissionAdvisorSettings({
        variables
      })

      if (data && data.upsertCallForSubmissionAdvisorSettings) {
        const questionsWeights = getReviewQuestionWeights(
          data.upsertCallForSubmissionAdvisorSettings.reviewFormQuestions
        )
        setToastMessage(t(`callsForSubmission:success:changesSaved`))
        setDefaultValues(
          // @ts-ignore
          generateAdvisorSettingsInitialValues(
            questionsWeights,
            data.upsertCallForSubmissionAdvisorSettings.advisorMaxFav
          )
        )
      }
    }
  })

  const onWeightChange = (value, index) => {
    changeText(
      `questionsWeights[${index}].weight`,
      removeNonNumeric(value),
      setFieldValue
    )
  }

  const onMaxFavsChange = value => {
    changeText('advisorMaxFav', removeNonNumeric(value), setFieldValue)
  }
  const getErrorMessage = (index, field) => {
    // @ts-ignore
    return errors?.questionsWeights?.length > 0 &&
      // @ts-ignore
      errors?.questionsWeights[index]
      ? errors?.questionsWeights[index][field]
      : null
  }
  return (
    <ScreenContainerWithTabs>
      <ScrollViewContent>
        <SkeletonContainer
          isLoading={isLoading || !callForSubmission}
          Skeleton={GeneralSettingsSkeleton}
        >
          {shouldWarnFromChanges ? (
            <WarningContainer>
              <WarningText>
                {t('curation:general:form:advisorSettingsWarning')}
              </WarningText>
            </WarningContainer>
          ) : null}
          {canEditAdvisingCriteria ? (
            <>
              <AdvisingCriteriaSection
                errors={errors}
                advisorMaxFav={values.advisorMaxFav}
                questionsWeights={values.questionsWeights}
                onMaxFavsChange={onMaxFavsChange}
                onWeightChange={onWeightChange}
                getErrorMessage={getErrorMessage}
              />
              <HorizontalSeparator />
            </>
          ) : null}

          {canAssignAdvisors ? (
            <QuestionBlock>
              <AdvisorUsersSelector
                callForSubmission={callForSubmission}
                handleSelectionChange={setSelectionChange}
                setAdvisorsData={setAdvisorsData}
                loadingCallForSubmissionAdvisors={
                  loadingCallForSubmissionAdvisors
                }
              />
            </QuestionBlock>
          ) : null}
        </SkeletonContainer>
      </ScrollViewContent>
      <ActionContainer>
        <Button
          type="outline"
          onPress={() => {
            resetForm()
            navigation.navigate('CurateCFSList')
          }}
          title={t('curation:buttons:cancel')}
          containerStyle={{
            marginRight: space[3]
          }}
        />
        <Button
          type="solid"
          onPress={async () => {
            if (dirty && isValid) {
              await submitForm()
            }
            if (selectionChange) {
              await handleSaveAdvisors()
            }
          }}
          title={t('curation:buttons:save')}
          disabled={dirty ? !isValid : !selectionChange}
          loading={loadingCallForSubmissionAdvisors || isProcessing}
        />
      </ActionContainer>
    </ScreenContainerWithTabs>
  )
}

const AdvisorSettingsWithPermissions = withNavigationPermissions(
  AdvisorSettings,
  [InnovationAccessKey.CURATE]
)

export default AdvisorSettingsWithPermissions
