import gql from 'graphql-tag'

const innovationSubmissionUpdatedSubscription = gql`
  subscription innovationSubmissionUpdated(
    $statuses: [InnovationSubmissionStatusEnum]
  ) {
    innovationSubmissionUpdated(statuses: $statuses) {
      id
      callForSubmissionId
      status
      updatedAt
      callForSubmission {
        id
        name
        delegations {
          id
          name
        }
        marketSegments {
          id
          name
        }
      }
    }
  }
`

export default innovationSubmissionUpdatedSubscription
