import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import CallForSubmissionsScreen from './CallForSubmissionsScreen'
import CallForSubmissionInnovatorSkeleton from './CallForSubmissionsInnovatorSkeleton'
import CallForSubmissionStaffSkeleton from './CallForSubmissionsStaffSkeleton'

import useTranslation from '../../hooks/useTranslation'
import { RoleEnum } from '../../types'
import useHasPermission from '../../hooks/useHasPermission'
import { INNOVATOR_TEAM_PERMISSIONS } from '../../constants/roles'
import { TablesEnum } from './constants'
import SettingsNavigator from './SettingsScreen/SettingsNavigator'
import useInnovationAccess from '../../hooks/useInnovationAccess'
import CallForSubmissionFormScreen from './components/CallForSubmissionFormScreen'
import useCurrentUser from '../../hooks/useCurrentUser'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import DashboardScreen from './Dashboard'

const CallForSubmissionStack = createStackNavigator()

const CallForSubmissionNavigator = () => {
  const { t } = useTranslation()
  const isInnovator = useHasPermission(INNOVATOR_TEAM_PERMISSIONS)
  const {
    curate: canCurate,
    callForSubmissionSettings: canEditCfsSettings,
    callForSubmissionAdvisingSettings: canEditAdvisingSettings,
    advise: canAdvise
  } = useInnovationAccess()
  const { isAdmin: canCreateCFS } = useCurrentUser()

  const isInnovationCreateNewCallForSubmission = useFeatureFlag(
    'innovationCreateNewCallForSubmission'
  )
  const isInnovationAnalyticsSuiteEnabled = useFeatureFlag(
    'innovationAnalyticsSuite'
  )

  if (!isInnovator && !canCurate && !canAdvise) {
    return null
  }

  return (
    <CallForSubmissionStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="CallForSubmissionList"
    >
      {isInnovator ? (
        <CallForSubmissionStack.Screen
          name="CallForSubmissionList"
          children={() => (
            <CallForSubmissionsScreen
              Skeleton={CallForSubmissionInnovatorSkeleton}
              title={t('callsForSubmission:title')}
              role={RoleEnum.InnovatorTeamAdmin}
            />
          )}
        />
      ) : null}

      {canCurate ? (
        <CallForSubmissionStack.Screen
          name="CurateCFSList"
          children={() => (
            <CallForSubmissionsScreen
              Skeleton={CallForSubmissionStaffSkeleton}
              title={t('curation:sideBar:curate')}
              role={RoleEnum.InnovationStaff}
              canCreateCFS={canCreateCFS}
            />
          )}
        />
      ) : null}

      {canEditCfsSettings || canEditAdvisingSettings ? (
        <CallForSubmissionStack.Screen
          name="CFSSettings"
          component={SettingsNavigator}
        />
      ) : null}
      {canAdvise ? (
        <CallForSubmissionStack.Screen
          name="AdviseCFSList"
          children={() => (
            <CallForSubmissionsScreen
              Skeleton={CallForSubmissionStaffSkeleton}
              title={t('curation:sideBar:advise')}
              role={RoleEnum.InnovationAdvisor}
              tableId={TablesEnum.Advise}
            />
          )}
        />
      ) : null}

      {canCreateCFS && isInnovationCreateNewCallForSubmission ? (
        <CallForSubmissionStack.Screen
          name="CreateNewCFS"
          children={() => <CallForSubmissionFormScreen />}
        />
      ) : null}
      {canCreateCFS && isInnovationAnalyticsSuiteEnabled ? (
        <CallForSubmissionStack.Screen
          name="Dashboard"
          component={DashboardScreen}
        />
      ) : null}
    </CallForSubmissionStack.Navigator>
  )
}

export default CallForSubmissionNavigator
