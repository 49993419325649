import React, { useContext, ReactNode } from 'react'
import { View } from 'react-native'
import styled, { ThemeContext } from 'styled-components/native'
import { StyledTextInput, TextInputProps } from '../TextInput'
import {
  ErrorContainer,
  ErrorText
} from '../../screens/authentication/components/SharedStyledComponents'

const PaddedStyledTextInput = styled(StyledTextInput)`
  flex: 1;
  padding-right: 30px;
`
export interface AdornedTextInputProps extends TextInputProps {
  errorMessage?: string
  renderInnerComponent?: () => ReactNode
  renderRightComponent?: () => ReactNode
}

const AdornedTextInput = (props: AdornedTextInputProps) => {
  const {
    name,
    containerStyles,
    errorMessage,
    renderInnerComponent,
    renderRightComponent,
    hasError,
    isReadOnly
  } = props

  const theme = useContext(ThemeContext) as any

  return (
    <View style={containerStyles}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <PaddedStyledTextInput
          nativeID={name}
          name={name}
          placeholderTextColor={theme.colors.placeholderText}
          hasError={hasError}
          isReadOnly={isReadOnly}
          editable={!isReadOnly}
          selectTextOnFocus={!isReadOnly}
          {...props}
        />
        {renderInnerComponent && renderInnerComponent()}
        {renderRightComponent && renderRightComponent()}
      </View>
      {errorMessage ? (
        <ErrorContainer>
          <ErrorText>{errorMessage}</ErrorText>
        </ErrorContainer>
      ) : null}
    </View>
  )
}

export default AdornedTextInput
