import gql from 'graphql-tag'

const CallsForSubmissionFieldsFragment = (isAdvising?: boolean) => {
  const CallsForSubmissionFields = `
  fragment CallsForSubmissionFieldsFragment on CallForSubmission {
    id
    name
    status
    academyElegible
    marketSegments {
      name
    }
    advisorsInfo {
      user {
        id
      }
      reviews
    }
    totalSubmissions(isAdvising: ${isAdvising ?? false})
    currentStep {
      name
      status
      openAt
      closeAt
    }
    stepDateRanges {
      id
      curationStep
      openAt
      closeAt
    }
    delegations {
      name
    }
  }
`
  return gql`
    ${CallsForSubmissionFields}
  `
}

export default CallsForSubmissionFieldsFragment
