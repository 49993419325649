import React from 'react'
// Hooks
import useRedirectLoginByQueryParams from './hooks/useRedirectLoginByQueryParams'
// Components
import Form from './Form'
import LoginInfo from './LoginInfo'
import Divider from '../components/sharedComponents/Divider'
import ColumnLayout from '../components/layout/ColumnLayout'
import InnovationBg from '../components/sharedComponents/InnovationBg'

const LoginScreen = () => {
  useRedirectLoginByQueryParams()
  return (
    <InnovationBg>
      <ColumnLayout>
        <LoginInfo />
        <Divider />
        <Form />
      </ColumnLayout>
    </InnovationBg>
  )
}

export default LoginScreen
