import { useSubscription } from '@apollo/client'
import callForSubmissionCreatedSubscription from '../subscriptions/callForSubmissionCreatedSubscription'
import { callsForSubmissionQuery } from './useCallsForSubmissionQuery'
import { RoleEnum } from '../../../types'

const onCallForSubmissionCreated = (client, subscriptionData, query, role) => {
  const options = { query, variables: { role } }

  const cachedQuery = client.readQuery({
    ...options
  })
  const newCallForSubmission = subscriptionData.data.callForSubmissionCreated
  const existing = cachedQuery?.callsForSubmission

  client.writeQuery({
    ...options,
    data: {
      ...cachedQuery,
      callsForSubmission: [newCallForSubmission, ...existing]
    }
  })
}

const useCreatedCallForSubmissionSubscription = (role: RoleEnum) => {
  useSubscription(callForSubmissionCreatedSubscription, {
    variables: {
      role
    },
    onData: ({ client, data }) => {
      onCallForSubmissionCreated(client, data, callsForSubmissionQuery, role)
    }
  })
}

export default useCreatedCallForSubmissionSubscription
