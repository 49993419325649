import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import useTranslation from '../../../hooks/useTranslation'
import {
  HeaderText,
  SortButton,
  SortContainer,
  SortHandle
} from './SharedStyledComponents'
import { TableHeaderCellProps } from './constants'
import CheckBox from '../../../ui-library/CheckBox'
import { SubTitleView, TitleView } from './DynamicTable/SharedStyledComponents'

const TableHeaderCell = ({
  isSelectable,
  header,
  sort,
  orderBy,
  orderDir,
  selectAll = false,
  selectCurrent = false,
  headerStyle,
  handleChangeSort,
  handleSelectCurrent,
  subtitle = []
}: TableHeaderCellProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [sortIcon, setSortIcon] = useState('▲')
  const hasSubtitle = subtitle.length > 0
  const subtitleWidth = hasSubtitle ? 100 / subtitle.length : 0

  const handleOnPress = () => {
    handleSelectCurrent?.(!selectCurrent)
  }

  useEffect(() => {
    if (orderBy === sort?.key) {
      setSortIcon(orderDir === 'asc' ? '▲' : '▼')
    }
  }, [sort?.key, orderBy, orderDir])

  return (
    <SortContainer>
      {isSelectable && (
        <CheckBox
          isSelectable
          checked={selectAll || selectCurrent}
          onPress={handleOnPress}
        />
      )}
      {sort ? (
        <SortButton onPress={() => handleChangeSort(sort.key)}>
          <HeaderText style={{ ...headerStyle, width: '100%' }}>
            {t(header)}
            <SortHandle
              style={{
                color:
                  orderBy === sort?.key
                    ? colors.tints.grays.g500
                    : colors.tints.grays.g100
              }}
            >
              {sortIcon}
            </SortHandle>
          </HeaderText>
        </SortButton>
      ) : (
        <TitleView>
          <SubTitleView>
            <HeaderText style={{ ...headerStyle, width: '100%' }}>
              {t(header)}
            </HeaderText>
          </SubTitleView>
          {hasSubtitle && (
            <SubTitleView
              style={{
                overflow: 'visible',
                flexDirection: 'row'
              }}
            >
              {subtitle.map(subT => (
                <HeaderText
                  style={{
                    width: `${subtitleWidth}%`,
                    textAlign: 'center'
                  }}
                >
                  {t(subT.label)}
                </HeaderText>
              ))}
            </SubTitleView>
          )}
        </TitleView>
      )}
    </SortContainer>
  )
}

export default TableHeaderCell
