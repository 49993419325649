import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { companyUsersQuery } from './useCompanyUsersQuery'

const inviteTeamMemberMutation = gql`
  mutation inviteInnovatorTeamMember(
    $email: String!
    $firstName: String!
    $lastName: String!
    $jobTitle: String!
    $companyId: String!
    $role: RoleEnum!
    $innovatorCommsPlatformAdmin: Boolean
    $innovatorCommsCFS: Boolean
    $innovatorCommsCFSSelectionAndPresentation: Boolean
    $innovatorCommsInvoicing: Boolean
  ) {
    inviteInnovatorTeamMember(
      email: $email
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      companyId: $companyId
      role: $role
      innovatorCommsPlatformAdmin: $innovatorCommsPlatformAdmin
      innovatorCommsCFS: $innovatorCommsCFS
      innovatorCommsCFSSelectionAndPresentation: $innovatorCommsCFSSelectionAndPresentation
      innovatorCommsInvoicing: $innovatorCommsInvoicing
    ) {
      id
      role
      person {
        id
        firstName
        lastName
      }
    }
  }
`

const resendInviteMutation = gql`
  mutation resendInnovatorTeamMemberInvitation(
    $userId: String!
    $companyId: String!
  ) {
    resendInnovatorTeamMemberInvitation(
      userId: $userId
      companyId: $companyId
    ) {
      id
      role
      person {
        id
        firstName
        lastName
      }
    }
  }
`
const useInviteTeamMemberMutation = (
  companyId: string
): [(values: any) => any, boolean, (values: any) => any, boolean] => {
  const refetchQueries = [
    'getInnovationUsersByCompanyId',
    { query: companyUsersQuery, variables: { id: companyId } }
  ]
  const [inviteTeamMember, { loading: loadingInviteMember }] = useMutation(
    inviteTeamMemberMutation,
    {
      refetchQueries: refetchQueries
    }
  )
  const [resendInvitation, { loading: loadingResend }] = useMutation(
    resendInviteMutation,
    {
      refetchQueries: refetchQueries
    }
  )

  const inviteMember = useCallback(
    ({
      email,
      firstName,
      lastName,
      jobTitle,
      role,
      innovatorCommsPlatformAdmin,
      innovatorCommsCFS,
      innovatorCommsCFSSelectionAndPresentation,
      innovatorCommsInvoicing
    }) => {
      return inviteTeamMember({
        variables: {
          email: email.toLowerCase(),
          firstName,
          lastName,
          jobTitle,
          companyId,
          role,
          innovatorCommsPlatformAdmin,
          innovatorCommsCFS,
          innovatorCommsCFSSelectionAndPresentation,
          innovatorCommsInvoicing
        }
      })
    },
    [companyId, inviteTeamMember]
  )

  const resendInvite = useCallback(
    ({ userId }) => {
      return resendInvitation({
        variables: {
          userId,
          companyId
        }
      })
    },
    [companyId, resendInvitation]
  )

  return [inviteMember, loadingInviteMember, resendInvite, loadingResend]
}

export default useInviteTeamMemberMutation
