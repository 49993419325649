import gql from 'graphql-tag'
// Hooks
import useQuery from '../../../../../../hooks/useQuery'
import useTranslation from '../../../../../../hooks/useTranslation'
// Types
import {
  EntityTypeEnum,
  QuestionGroupEntityMap
} from '../../../../../../components/DynamicForm/types'
import QuestionGroupEntityMapFieldsFragment from '../../../../../../graphql/QuestionGroupEntityMapFieldsFragment'
import QuestionFieldsFragment from '../../../../../../graphql/QuestionFieldsFragment'
import QuestionGroupFieldsFragment from '../../../../../../graphql/QuestionGroupFieldsFragment'

const questionGroupEntityMapsQuery = gql`
  query getQuestionGroupEntityMaps(
    $parent: Boolean
    $formId: String
    $questionGroupId: String
    $entityType: QuestionGroupEntityMapEnum
  ) {
    getQuestionGroupEntityMaps(
      parent: $parent
      formId: $formId
      questionGroupId: $questionGroupId
      entityType: $entityType
    ) {
      ...QuestionGroupEntityMapFieldsFragment
      entity {
        ... on Question {
          ...QuestionFieldsFragment
        }
        ... on QuestionGroup {
          ...QuestionGroupFieldsFragment
        }
      }
    }
  }
  ${QuestionGroupEntityMapFieldsFragment}
  ${QuestionFieldsFragment}
  ${QuestionGroupFieldsFragment}
`

interface QuestionGroupsResult {
  loading?: boolean
  questionGroupEntityMaps: QuestionGroupEntityMap[]
}

const useGetQuestionGroupEntityMaps = (
  parent: boolean,
  formId?: string,
  questionGroupId?: string,
  entityType?: EntityTypeEnum,
  options = {}
): QuestionGroupsResult => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(questionGroupEntityMapsQuery, {
    variables: { parent, formId, entityType, questionGroupId },
    errorMessage: t('error:general:questions:error'),
    fetchPolicy: 'cache-and-network',
    ...options
  })

  return {
    questionGroupEntityMaps: data?.getQuestionGroupEntityMaps,
    loading
  }
}

export default useGetQuestionGroupEntityMaps
