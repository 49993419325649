import React from 'react'

import {
  useRoute,
  getFocusedRouteNameFromRoute
} from '@react-navigation/native'
import styled from 'styled-components/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
// Hooks
import useTranslation from '../../hooks/useTranslation'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'
import useIsPlatformManagementEnabled from '../../hooks/useIsPlatformManagementEnabled'
// Components
import {
  ShadowContainerWithTabs,
  ScreenContainerWithMenuHeader
} from '../../components/layout/ScreenContainer'
import TopTabBar from '../../components/layout/TopTabBar'
import TopTabBarMobile from '../../components/layout/TopTabBarMobile'
import { Title } from '../CallForSubmission/components/SharedComponents'
// Tab Screens
import CategoryManagement from './CategoryManagement'
import FormsManagement from './FormsManagement'
import useFeatureFlag from '../../hooks/useFeatureFlag'

// Styled Components
const HeaderNavContainer = styled.View`
  width: 100%;
  ${({ theme: { space } }) => `
    padding-top: ${space[3]}px;
    padding-horizontal: ${space[3]}px;
  `}
`
// Tabs
const Tabs = createMaterialTopTabNavigator()
const tabScreens = [
  {
    name: 'CategoryManagement',
    Component: CategoryManagement,
    options: {
      title: `Category Management`,
      webTitle: 'Category Management',
      disabled: false
    }
  }
]
const formBuilderTab = {
  name: 'FormsManagement',
  Component: FormsManagement,
  options: {
    title: `Forms Management`,
    webTitle: 'Forms Management',
    disabled: false
  }
}

const PlatformManagementTabs = () => {
  const route: any = useRoute()
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const { trackWithProperties } = useMixpanel()
  const currentRouteName = getFocusedRouteNameFromRoute(route)

  const isInnovationFormBuilderEnabled = useFeatureFlag('innovationFormBuilder')

  const isPlatformManagementEnabled = useIsPlatformManagementEnabled()
  if (!isPlatformManagementEnabled) {
    return null
  }

  const platformManagementTabs = isInnovationFormBuilderEnabled
    ? [...tabScreens, formBuilderTab]
    : tabScreens

  return (
    <ScreenContainerWithMenuHeader screenCategory="platformManagement">
      <ShadowContainerWithTabs>
        <HeaderNavContainer>
          <Title text={t('platformManagement:title')} />
        </HeaderNavContainer>
        <Tabs.Navigator
          swipeEnabled={false}
          tabBar={props =>
            !isSmallScreen ? (
              <TopTabBar {...props} />
            ) : (
              <TopTabBarMobile {...props} />
            )
          }
        >
          {platformManagementTabs.map(({ name, Component, options }) => (
            <Tabs.Screen
              key={name}
              name={name}
              children={() =>
                name === currentRouteName ? <Component /> : null
              }
              options={options}
              listeners={{
                tabPress: () => {
                  trackWithProperties(EVENT_MAP.click.tab, {
                    tabName: name
                  })
                }
              }}
            />
          ))}
        </Tabs.Navigator>
      </ShadowContainerWithTabs>
    </ScreenContainerWithMenuHeader>
  )
}

export default PlatformManagementTabs
