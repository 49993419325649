import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { CallForSubmission, RoleEnum } from '../../../types'
import useUpdatedCallForSubmissionSubscription from './useUpdatedCallForSubmissionSubscription'
import useCreatedCallForSubmissionSubscription from './useCreatedCallForSubmissionSubscription'

export const CallForSubmissionFieldsFragment = gql`
  fragment CallForSubmissionFieldsFragment on CallForSubmission {
    id
    name
    status
    academyElegible
    stepDateRanges {
      id
      curationStep
      openAt
      closeAt
    }
    currentStep {
      name
      status
      openAt
      closeAt
    }
    advisors
    delegations {
      id
      name
    }
    marketSegments {
      id
      name
    }
    innovationSubmissions {
      id
      status
      hasBeenSubmitted
      hasBeenFastPassed
    }
  }
`

export const callsForSubmissionQuery = gql`
  query callsForSubmission($role: RoleEnum) {
    callsForSubmission(role: $role) {
      ...CallForSubmissionFieldsFragment
    }
  }
  ${CallForSubmissionFieldsFragment}
`

interface CFSObject {
  callsForSubmission: CallForSubmission[]
  loading: boolean
}

const useCallsForSubmissionQuery = (
  role: RoleEnum,
  skip?: boolean
): CFSObject => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(callsForSubmissionQuery, {
    variables: {
      role
    },
    skip,
    errorMessage: t('error:submissions:getCallsForSubmission')
  })

  useUpdatedCallForSubmissionSubscription(role)
  useCreatedCallForSubmissionSubscription(role)

  return {
    callsForSubmission: data?.callsForSubmission,
    loading
  }
}

export default useCallsForSubmissionQuery
