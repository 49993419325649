import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'
import useTranslation from '../../../hooks/useTranslation'

export const clearSubmissionAssignmentToCohortVotingMutation = gql`
  mutation clearSubmissionAssignmentToCohortVoting(
    $groupId: String!
    $submissionId: String!
  ) {
    clearSubmissionAssignmentToCohortVoting(
      groupId: $groupId
      submissionId: $submissionId
    ) {
      id
      cohorts {
        group {
          id
          name
        }
        isConfirmed
        hasPresentedBefore
        deletedById
      }
    }
  }
`

const useClearSubmissionAssignmentToCohortVotingMutation = () => {
  const { t } = useTranslation()
  const [clearSubmissionAssignmentToCohortVoting, { loading }] = useMutation(
    clearSubmissionAssignmentToCohortVotingMutation,
    {
      errorMessage: t('error:save:default')
    }
  )

  return {
    clearSubmissionAssignmentToCohortVoting,
    loading
  }
}

export default useClearSubmissionAssignmentToCohortVotingMutation
