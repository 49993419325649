import { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { Route } from '@react-navigation/native'

// Recoil atoms
import {
  hasUnsavedChangesAtom as hasUnsavedAtom,
  unsavedAdvisorChangesAtom as unsavedAtom,
  UnsavedAdvisorChangesAtomData
} from '../../recoil/unsavedAdvisorChangesAtomFamily'

// Types
interface useUnsavedAdvisorChangesValues {
  unsavedValues: any | null
  shouldInitForm: boolean
  hasUnsavedChanges: boolean
  formRouteState: Route<any> | null
  resetState: () => void
  setHasUnsaved: (bool: boolean) => void
  setUnsavedAdvisorState: (newState: UnsavedAdvisorChangesAtomData) => void
}

const useUnsavedAdvisorChanges = (): useUnsavedAdvisorChangesValues => {
  const [unsavedState, setUnsavedValues] = useRecoilState(unsavedAtom)
  const [hasUnsavedState, setHasUnsavedState] = useRecoilState(hasUnsavedAtom)

  // Functions
  const resetState = () => {
    setUnsavedValues({
      unsavedValues: null,
      formRouteState: null
    })
    setHasUnsavedState(false)
  }
  const setUnsavedAdvisorState = (newState: UnsavedAdvisorChangesAtomData) => {
    setUnsavedValues(newState)
  }
  const setHasUnsaved = (bool: boolean) => {
    setHasUnsavedState(bool)
  }

  // Memo
  const unsavedValues = useMemo(() => unsavedState?.unsavedValues, [
    unsavedState?.unsavedValues
  ])
  const shouldInitForm = useMemo(() => !!unsavedState?.shouldInitForm, [
    unsavedState?.shouldInitForm
  ])
  const formRouteState = useMemo(() => unsavedState?.formRouteState || null, [
    unsavedState?.formRouteState
  ])
  const hasUnsavedChanges = useMemo(() => hasUnsavedState, [hasUnsavedState])

  return {
    unsavedValues,
    formRouteState,
    shouldInitForm,
    hasUnsavedChanges,
    resetState,
    setHasUnsaved,
    setUnsavedAdvisorState
  }
}

export default useUnsavedAdvisorChanges
