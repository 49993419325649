import gql from 'graphql-tag'
// Hooks
import useQuery from '../../../../../../hooks/useQuery'
import useTranslation from '../../../../../../hooks/useTranslation'
// Types
import { QuestionGroup } from '../../../../../../components/DynamicForm/types'
import QuestionGroupFieldsFragment from '../../../../../../graphql/QuestionGroupFieldsFragment'

const questionGroupsQuery = gql`
  query getQuestionGroups(
    $parent: Boolean
    $questionGroupId: String
    $formId: String
  ) {
    getQuestionGroups(
      parent: $parent
      questionGroupId: $questionGroupId
      formId: $formId
    ) {
      ...QuestionGroupFieldsFragment
    }
  }
  ${QuestionGroupFieldsFragment}
`

interface QuestionGroupsResult {
  loading?: boolean
  questionGroups: QuestionGroup[]
}

const useGetQuestionGroups = (
  parent = true,
  questionGroupId?: string,
  formId?: string,
  options = {}
): QuestionGroupsResult => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(questionGroupsQuery, {
    variables: { parent, questionGroupId, formId },
    errorMessage: t('error:general:questions:error'),
    fetchPolicy: 'cache-and-network',
    ...options
  })

  return {
    questionGroups: data?.getQuestionGroups,
    loading
  }
}

export default useGetQuestionGroups
