import React, { FC } from 'react'
import styled, { useTheme } from 'styled-components/native'
import {
  CompanyUrl,
  EditButton,
  MemberPill,
  ProfileContainer,
  Subtitle,
  Title
} from './SharedComponents'
import { Text } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'
import DateService from '../../../services/dateService'
import { getCurrencyFormat } from '../../../services/currencyService'
import { Company, User } from '../../../types'
import useTranslation from '../../../hooks/useTranslation'
import CollapsibleContainer from './common/CollapsibleContainer'

const StatsContainer = styled(Flex)`
  ${({ theme: { space, radii, colors } }) => `
  border-radius: ${radii[4]}px;
  background-color: ${colors.primaryPalette.white};
  padding: ${space[3]}px ${space[3]}px;
  flex-direction: row;
  margin-top: ${space[3]}px; 
  margin-bottom: ${space[3]}px;
  `}
`

const StatsColumn = styled(Flex)`
  flex: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Label = styled.Text`
  ${({ theme: { colors, space, fontSizes } }) => `
    color: ${colors.tints.grays.g400};
    fontSize: ${fontSizes[3]}px;
    margin-bottom: ${space[2]}px;
  `}
`

const getUrl = initialUrl =>
  initialUrl
    ? initialUrl.includes('http')
      ? initialUrl
      : `//${initialUrl}`
    : ''
const formatYearFounded = year => {
  if (!year) {
    return ''
  }

  return DateService.getFormat(year, 'YYYY')
}

interface ProfileCompanySummaryProps {
  company?: Company
  users?: User[]
  pendingUsers?: User[]
}

const ProfileCompanySummary: FC<ProfileCompanySummaryProps> = ({
  company,
  users,
  pendingUsers = []
}) => {
  const { t } = useTranslation()
  const { fontWeights, fontSizes, sizes } = useTheme()
  const website = company?.companyProfile?.url || ''
  const innovators = users || []
  const companyMembersTitle = t('innovatorDirectory:profile:companyMembers')
  const companyMembersSubtitle = `${innovators.length} ${
    innovators.length > 1
      ? t('innovatorDirectory:profile:members')
      : t('innovatorDirectory:profile:member')
  }\n${pendingUsers?.length} ${
    pendingUsers?.length > 1
      ? t('innovatorDirectory:profile:pendingMembers')
      : t('innovatorDirectory:profile:pendingMember')
  }`
  const companyStats = [
    {
      label: t('innovatorDirectory:profile:founded'),
      value: formatYearFounded(company?.companyProfile?.yearFounded)
    },
    {
      label: t('innovatorDirectory:profile:totalFunded'),
      value: `${
        company?.companyProfile?.funding
          ? getCurrencyFormat(company?.companyProfile?.funding as any, {
              style: 'currency',
              notation: 'compact'
            })
          : 0
      }`
    },
    {
      label: t('innovatorDirectory:profile:employees'),
      value: t(
        `auth:forms:companyEmployeeCountOptions:${company?.companyProfile?.employeeCount}`
      )
    }
  ]

  return (
    <ProfileContainer testID={'companyInfoContainer'}>
      <Title>{company?.name}</Title>
      <CompanyUrl url={getUrl(website)} title={website} />
      <Text styles={{ fontSize: sizes[3] - sizes[1] }}>
        {company?.companyProfile?.country?.name || ''}
      </Text>

      <StatsContainer>
        {companyStats.map((stat, index) => (
          <StatsColumn key={`stat-${index}`}>
            <Label>{stat.label}</Label>
            <Text
              styles={{
                fontSize: fontSizes[4],
                fontWeight: fontWeights.bold
              }}
            >
              {stat.value}
            </Text>
          </StatsColumn>
        ))}
      </StatsContainer>

      <CollapsibleContainer
        title={companyMembersTitle}
        subtitle={companyMembersSubtitle}
      >
        {innovators.map((innovator, index) => (
          <MemberPill key={`innovator-${index}`} innovator={innovator} />
        ))}
        {pendingUsers?.length ? (
          <Subtitle>{t('innovatorDirectory:profile:pendingMembers')}</Subtitle>
        ) : null}
        {pendingUsers.map((innovator, index) => (
          <MemberPill key={`pendingInnovator-${index}`} innovator={innovator} />
        ))}
      </CollapsibleContainer>

      <Flex flexDirection="row" display="flex">
        <EditButton
          testID="innovator_directory_company_details_btn"
          title={t('innovatorDirectory:profile:company')}
          route="InnovatorDirectoryDetails"
          containerStyle={{
            width: '50%',
            paddingHorizontal: sizes[1]
          }}
          params={{
            companyId: company?.id
          }}
        />

        <EditButton
          testID="innovator_directory_members_btn"
          title={t('innovatorDirectory:profile:members')}
          route="InnovatorDirectoryMembers"
          params={{
            companyId: company?.id
          }}
          containerStyle={{
            paddingHorizontal: sizes[1],
            width: '50%'
          }}
        />
      </Flex>
    </ProfileContainer>
  )
}
export default ProfileCompanySummary
