import gql from 'graphql-tag'

export const answerFileAttachmentMapUpdated = gql`
  subscription answerFileAttachmentMapUpdated(
    $answerId: String!
    $answerFileAttachmentMapId: String!
  ) {
    answerFileAttachmentMapUpdated(
      answerId: $answerId
      answerFileAttachmentMapId: $answerFileAttachmentMapId
    ) {
      id
      name
      answerId
      fileAttachmentId
      fileAttachment {
        id
        fileName
        cloudinaryId
        moderationStatus
      }
      createdAt
      origin {
        id
        source
      }
    }
  }
`

export const onAnswerFileAttachmentUpdated = (
  client,
  subscriptionData,
  query,
  questionId,
  formSubmissionId
) => {
  const cachedQuery = client.readQuery({
    query,
    variables: { id: questionId, formSubmissionId }
  })

  const updatedAnswerFileAttachmentMap =
    subscriptionData.data.answerFileAttachmentMapUpdated

  const updatedAnswerFileAttachments = cachedQuery?.question?.answer?.answerFileAttachmentsMap?.map(
    answerFileAttachment => {
      if (answerFileAttachment.id !== updatedAnswerFileAttachmentMap.id) {
        return answerFileAttachment
      }
      return updatedAnswerFileAttachmentMap
    }
  )

  client.writeQuery({
    query,
    variables: { id: questionId, formSubmissionId },
    data: {
      ...cachedQuery,
      question: {
        ...cachedQuery.question,
        answer: {
          ...cachedQuery.answer,
          answerFileAttachmentsMap: updatedAnswerFileAttachments
        }
      }
    }
  })
}
