import { useMemo } from 'react'

const useFilterOptions = filter => {
  const { hookOptions } = filter
  const { useHookQuery, hookParams, hookKey, labelKey, valueKey } = hookOptions
  const data = useHookQuery(hookParams)

  const loading = useMemo(() => data?.loading ?? true, [data?.loading])

  const resultData = useMemo(
    () =>
      data[hookKey]?.map(element => ({
        label: element[labelKey],
        value: element[valueKey]
      })) ?? [],
    [data[hookKey]]
  )

  return { data: resultData, loading }
}

export default useFilterOptions
