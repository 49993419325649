import gql from 'graphql-tag'

const InnovationSubmissionFieldsFragment = gql`
  fragment InnovationSubmissionFieldsFragment on InnovationSubmission {
    id
    company {
      id
      name
    }
    callForSubmissionId
    submissionInfo {
      name
      categoryLabel
      otherCategoryLabel
    }
  }
`

export default InnovationSubmissionFieldsFragment
