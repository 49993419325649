import { Cloudinary } from 'cloudinary-core'
import Constants from 'expo-constants'

const cl = new Cloudinary({
  cloud_name: Constants?.manifest?.extra?.CLOUDINARY_NAME,
  secure: true
})

const idToCloudinaryUrl = (publicId, options = {}) => {
  return cl.url(publicId, options)
}

export const idToCloudinaryAvatarUrl = (publicId: string, size = 64) => {
  return cl.url(publicId, {
    width: size,
    height: size,
    crop: 'fill',
    gravity: 'face',
    quality: 'auto:eco',
    fetchFormat: 'auto'
  })
}

export default idToCloudinaryUrl
