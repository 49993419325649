import { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'
import { DocumentNode } from 'graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { RoleEnum } from '../../types'
import { DropdownOption } from '../common/Dropdown'
import { EngineEnum } from '../../screens/CallForSubmission/constants'

export type CellRendererProps<T> = {
  item: T
  label?: string | null
  itemMap?: string
  isEditable?: boolean
  role?: RoleEnum
}

export type CellRenderer<T> = FunctionComponent<CellRendererProps<T>>

export const defaultSortDir = 'asc'

export type sortDir = 'asc' | 'desc'

export type SortState = [string, sortDir]

export interface SortableColumnProps<T> {
  key: string
  isDefault?: boolean
  defaultDir?: sortDir
}

export interface SortableColumnDefinition<T> extends ColumnDefinition<T> {
  sort: SortableColumnProps<T> | undefined
}

export type filterType = 'search' | 'filter'

export interface FilterableColumnProps<T> {
  key: string
  type: filterType
  options?: DropdownOption[]
  isHook?: boolean
  isMulti?: boolean
  styles?: {}
  defaultValue?: T
  isFilterArray?: boolean
  handleChange?: (value: any, setQueryFilters: any) => void
  disabledFilterBySelection?: (currentOptionSelection: any) => void
  getValue?: (queryFilters: any) => string
}

export interface FilterableColumnDefinition<T> extends ColumnDefinition<T> {
  filter?: FilterableColumnProps<T> | undefined
}

export interface ColumnDefinition<T> {
  id?: string | null
  header?: string | null
  itemMap?: string
  headerStyle?: any
  isEditable?: boolean
  emptyMessage?: string | null
  sort?: SortableColumnProps<T> | undefined
  filter?: FilterableColumnProps<T> | undefined
  Cell: CellRenderer<T>
  width?: Number
  minWidth?: Number
  length?: Number
  fixed?: boolean
  isHidden?: boolean
  isSelectable?: boolean
  role?: RoleEnum
  subtitle?: any[]
  cellStyle?: ViewStyle
  sortContainerStyle?: ViewStyle
}

export interface ConfigDefinition<T> {
  name: TableNameEnum
  queryDynamicName?: string
  headerContainerStyle?: any
  RowWrapper?: any
  LargeRow?: React.ReactNode
  SmallRow?: React.ReactNode
  enableFilters?: boolean
  hitKey: string
  engine?: EngineEnum | undefined
  pageSize: number
  filters: JSON
  columns: (
    | ColumnDefinition<T>
    | SortableColumnDefinition<T>
    | FilterableColumnDefinition<T>
  )[]
  queryConditions?: {
    [key: string]: any
  }
  fixed?: boolean
  showSearchField?: boolean
  showCsvButton?: boolean
  showPDFButton?: boolean
  isSticky?: boolean
  showProductPDFButton?: boolean
  showStatusFilter?: boolean
  rowHeight?: Number
  isAlternativeView?: boolean
  tableId?: string
  getQuestionAndAnswers?: boolean
  csvTypesMap?: any[]
  skipCache?: boolean
  skipFetchData?: boolean
  fetchingDataOnFocus?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  updateMutation?: DocumentNode
  removeMutation?: DocumentNode
  refetchQueries?: string[]
  onRowSelect?: (value: any) => any
  customQueryFields?: DocumentNode
  showTotalElements?: boolean
  cohortId?: string
  getDetailedViewComponents?: (item: any) => JSX.Element[]
  enableSelectAllElements?: boolean
}

export interface TableProps<T> {
  config: ConfigDefinition<T>
  data?: T[]
  loading: boolean
  error: any
  refetch: any
  fetchMore: any
  keyExtractor?: ((item: T) => string) | ((item: T, i: any) => string)
  isReadOnly: boolean
  emptyMessage?: string
  EmptyComponent?: any
  onRenderAgain?: (
    prevProps: TableProps<any>,
    nextProps: TableProps<any>
  ) => boolean
  initialSelectedItems?: object
  onSelectedItemsKeysChange?: (
    arr: string[],
    selectAllElements?: boolean
  ) => void
  onPropsDataChanged?: (
    data: any,
    propsData: any,
    handleSetData: Function
  ) => void
  onSelectAllElementsChange?: (selectAllElements: any) => void
  onFiltersChange?: (filter: any) => void
  enableSelectAllElements?: boolean
}

export interface TableProviderProps<T> {
  props: TableProps<T>
  children: JSX.Element | JSX.Element[] | null | Boolean
}

export interface UseTableRowOptions<T> {
  config: ConfigDefinition<T>
}

export interface DataProps {
  data: any[]
}

export interface DataProviderProps {
  props: DataProps
  children: JSX.Element | JSX.Element[] | null | Boolean
}

export enum CSVExtraParamTypeEnum {
  Question = 'question',
  Status = 'status'
}

export interface CSVExtraParamsObject {
  id: string
  appSearchId: string
  type: CSVExtraParamTypeEnum
  values?: string[]
}

export enum CSVTypeEnum {
  Submission = 'submission',
  SubmissionWithResponses = 'submissionWithResponses',
  PreCurate = 'preCurate',
  PreCurateWithFeedback = 'preCurateWithFeedback',
  CohortRawData = 'cohortRawData',
  CohortSelectionTable = 'cohortSelectionTable',
  CohortWithResponses = 'cohortWithResponses',
  CohortAssignmentDistributionList = 'cohortAssignmentDistributionList',
  Presentation = 'presentation',
  PresentationInvitedInnovators = 'presentationInvitedInnovators',
  PresentationInnovatorScores = 'presentationInnovatorScores',
  Advising = 'advising'
}

export enum TableNameEnum {
  Submission = 'submissions-table',
  PreCuration = 'precuration-table',
  Cohort = 'cohort-selection-table',
  Presentation = 'presentation-table',
  Advising = 'advising-table',
  AdvisingSubmissionSelection = 'advising-submission-selection-table',
  TestSettingsCreateSubmissions = 'test-settings-create-submissions-table',
  FormsManagementTable = 'forms-management-table',
  FormBlocksManagementTable = 'forms-blocks-management-table',
  QuestionGroupsManagementTable = 'question-groups-management-table',
  QuestionsManagementTable = 'questions-management-table'
}

export interface GetExtraParamsProps {
  name: TableNameEnum
  filters: JSON
  queryFilters: JSON
  csvType?: CSVTypeEnum
}
