import React from 'react'

import {
  ScreenContainerWithMenuHeader,
  StyledViewContainerAutoHeight
} from '../../components/layout/ScreenContainer'
import CallForSubmissionTable from './CallForSubmissionTable'

import { Container, Title } from './components/SharedComponents'

import EmptyCFSPrompt from '../HomeScreen/EmptyCFSPrompt'
import {
  CallForSubmission,
  CallForSubmissionStatusEnum,
  CallForSubmissionStepEnum,
  CallForSubmissionStepStatusEnum,
  RoleEnum
} from '../../types'
import { EngineEnum, TablesEnum } from './constants'
// Skeletons
import { EmptySkeleton } from '../../components/skeletonLoadings/components'
import useInnovationAccess from '../../hooks/useInnovationAccess'
import CreateCallForSubmission from './components/CreateCallForSubmission'
import useFeatureFlag from '../../hooks/useFeatureFlag'

interface CFSProps {
  Skeleton?: any
  title: string
  role: RoleEnum
  tableId?: TablesEnum
  canCreateCFS?: boolean
}

const CallForSubmissionsScreen = ({
  title,
  role,
  Skeleton,
  tableId,
  canCreateCFS
}: CFSProps) => {
  const showCurrentAndUpcomingCFSFilter = (cfs: CallForSubmission) => {
    if (
      role === RoleEnum.InnovatorTeamAdmin ||
      role === RoleEnum.InnovatorTeamMember
    ) {
      return (
        cfs?.currentStep?.name === CallForSubmissionStepEnum.submission ||
        (cfs?.currentStep?.status === CallForSubmissionStepStatusEnum.closed &&
          cfs?.status === CallForSubmissionStatusEnum.upcoming)
      )
    } else if (role === RoleEnum.InnovationAdvisor) {
      return (
        cfs?.currentStep?.name === CallForSubmissionStepEnum.advising &&
        cfs?.currentStep?.status === CallForSubmissionStepStatusEnum.open
      )
    }
    return true
  }

  const isInnovationCreateNewCallForSubmission = useFeatureFlag(
    'innovationCreateNewCallForSubmission'
  )

  const {
    callForSubmissionSettings: canEditSettings,
    callForSubmissionAdvisingSettings,
    advisorAssignment
  } = useInnovationAccess()

  const canNavigateToSettings =
    canEditSettings || advisorAssignment || callForSubmissionAdvisingSettings

  return (
    <ScreenContainerWithMenuHeader screenCategory="callForSubmission">
      <StyledViewContainerAutoHeight testID={'openCFSContainer'}>
        <Title
          text={title}
          rightButton={
            canCreateCFS && isInnovationCreateNewCallForSubmission
              ? CreateCallForSubmission
              : undefined
          }
        />
        <Container>
          <CallForSubmissionTable
            tableId={tableId}
            Skeleton={Skeleton ?? EmptySkeleton}
            EmptyComponent={EmptyCFSPrompt}
            role={role}
            engine={EngineEnum.InnovationCallForSubmission}
            dataFilter={showCurrentAndUpcomingCFSFilter}
            canEditSettings={canNavigateToSettings}
          />
        </Container>
      </StyledViewContainerAutoHeight>
    </ScreenContainerWithMenuHeader>
  )
}

export default CallForSubmissionsScreen
