import gql from 'graphql-tag'
// Hooks
import useMutation from '../../../hooks/useMutation'
import useTranslation from '../../../hooks/useTranslation'
// Subscriptions
import useUpdateCFSAfterReviewSubscription from '../../Advise/hooks/useUpdateCFSAfterReviewSubscription'

export const submissionAverageMutation = gql`
  mutation updateSubmissionAverage($submissionId: String!) {
    updateSubmissionAverage(submissionId: $submissionId) {
      id
      submissionId
      wass
      avgScore
    }
  }
`

const useSubmissionAverageMutation = (cfsId?: string) => {
  const { t } = useTranslation()
  const [updateAverage, { loading }] = useMutation(submissionAverageMutation, {
    errorMessage: t('error:save:default')
  })

  useUpdateCFSAfterReviewSubscription(cfsId)

  return {
    updateAverage,
    loading
  }
}

export default useSubmissionAverageMutation
