import { SetterOrUpdater, useRecoilState } from 'recoil'
import { pick } from 'ramda'
import currentDownloadFilesAtom, {
  FilesProps
} from '../recoil/currentDownloadFilesAtom'

const useDownloadFiles = (id: string) => {
  const [downloadFiles, setCurrentDownloadFiles] = useRecoilState(
    currentDownloadFilesAtom(id)
  ) as [FilesProps, SetterOrUpdater<FilesProps>]

  const setIsLoadingFiles = value =>
    setCurrentDownloadFiles({ ...downloadFiles, isLoadingFiles: value })

  const setFiles = values =>
    setCurrentDownloadFiles({ ...downloadFiles, files: values?.files })

  const setIsRefreshingFiles = isRefreshingFiles =>
    setCurrentDownloadFiles({ ...downloadFiles, isRefreshingFiles })

  return {
    ...pick(
      ['files', 'isLoadingFiles', 'isRefreshingFiles'],
      downloadFiles || {}
    ),
    setCurrentDownloadFiles,
    setIsLoadingFiles,
    setIsRefreshingFiles,
    setFiles
  }
}

export default useDownloadFiles
