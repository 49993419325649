import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import {
  MaterialTopTabBarProps,
  MaterialTopTabDescriptor,
  MaterialTopTabNavigationOptions
} from '@react-navigation/material-top-tabs/lib/typescript/src/types'
import styled, { useTheme } from 'styled-components/native'

import ArrowLeftIcon from '../icon/ArrowLeftIcon'
import { TabBarLabel, Column } from './TopTabBar'

import { useUnsavedChangesDialog } from '../../hooks/useUnsavedChanges'
import { UnsavedChangesDialog } from './sharedComponents'
import useHistoryRoutes from '../../hooks/useHistoryRoutes'

interface TabOptions extends MaterialTopTabNavigationOptions {
  disabled?: boolean
}

interface TabDescriptor extends MaterialTopTabDescriptor {
  options: TabOptions
}

const TabBarRow = styled(View)`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-right: ${theme.space[3]}px;
    padding-left: ${theme.space[3]}px;
    align-items: center;
    background-color: ${theme.colors.background};
    border: 0px solid ${theme.colors.tabBorder};
    border-bottom-width: 1px;
  `}
`

const TabContainer = styled(View)`
  ${({ theme, isSelected }) => `
   flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: ${theme.space[3]}px;
    padding-bottom: ${theme.space[3]}px;
    border: 0px solid ${theme.colors.tints.grays.g100};
    border-bottom-width: ${isSelected ? 3 : 0}px;
  `}
`

interface TopTabBarProps extends MaterialTopTabBarProps {
  bottomComponent?: any
}

export const TopTabBarMobile = ({
  state,
  descriptors,
  navigation
}: TopTabBarProps) => {
  const { pushToHistory } = useHistoryRoutes()
  const { colors } = useTheme()
  const route = state.routes[state.index]
  const { options }: TabDescriptor = descriptors[route.key]

  if (options?.disabled) {
    navigation.navigate('ProductGeneral')
  }

  const label =
    options.tabBarLabel !== undefined
      ? options.tabBarLabel
      : options.title !== undefined
      ? options.title
      : route.name

  const {
    unsavedChanges,
    setRouteAndDialog,
    continueNavigation,
    showDialog,
    setShowDialog
  } = useUnsavedChangesDialog()

  const changeTab = route => {
    const descriptor: TabDescriptor = descriptors[route.key]

    if (!descriptor?.options?.disabled) {
      if (unsavedChanges.hasUnsavedChanges) {
        setRouteAndDialog(route.name, true)
      } else {
        pushToHistory({
          screen: route.name
        })
        navigation.navigate(route.name)
      }
    }
  }

  return (
    <Column>
      <TabBarRow>
        {state.index !== 0 && (
          <TouchableOpacity
            onPress={() => changeTab(state.routes[state.index - 1])}
          >
            <ArrowLeftIcon color={colors.lightGrey} />
          </TouchableOpacity>
        )}
        <TabContainer
          accessibilityRole="button"
          accessibilityLabel={options.tabBarAccessibilityLabel}
          testID={options.tabBarTestID}
          style={{ flex: 1 }}
        >
          <TabBarLabel isDisabled={!!options.disabled}>{label}</TabBarLabel>
        </TabContainer>
        {state.index !== state.routes.length - 1 && (
          <TouchableOpacity
            onPress={() => changeTab(state.routes[state.index + 1])}
          >
            <ArrowLeftIcon
              color={colors.lightGrey}
              style={{ transform: [{ rotate: '180deg' }] }}
            />
          </TouchableOpacity>
        )}
      </TabBarRow>
      <UnsavedChangesDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        discardChangesCallback={() => {
          unsavedChanges.discardCallback()
          continueNavigation(navigation.navigate)
        }}
        saveChangesCallback={async () => {
          await unsavedChanges.saveCallback()
          continueNavigation(navigation.navigate)
        }}
      />
    </Column>
  )
}

export default TopTabBarMobile
