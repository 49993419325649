import gql from 'graphql-tag'
import { JSONStyles } from '../../../types/form'
import { FileDropzoneProps } from '../../common/FileDropzone'

export const MAX_LENGT_TEXT_INPUT = 100

export type FileDropzoneStyles = Pick<
  JSONStyles,
  'containerStyles' | 'errorContainerStyles' | 'labelStyles' | 'styles'
>

export interface FileDropzoneDataProps {
  key: string
  label?: string
  questionId: string
  formSubmissionId: string
  helperText?: string | null
  styles?: FileDropzoneStyles
}

export interface DynamicFileDropzoneProps extends FileDropzoneProps {
  data: FileDropzoneDataProps
}

export interface DropZoneContentProps {
  selectFileButton: JSX.Element
  progress?: number | null
  isReadOnly?: boolean
}

export const deleteAnswerFileAttachmentMapMutation = gql`
  mutation deleteAnswerFileAttachmentMap($id: String!) {
    deleteAnswerFileAttachmentMap(id: $id)
  }
`
