import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import QuestionGroupEntityMapFieldsFragment from '../../../../../../graphql/QuestionGroupEntityMapFieldsFragment'

export const upsertQuestionGroupEntityMapAndEntityMutation = gql`
  mutation upsertQuestionGroupEntityMapAndEntity(
    $id: String
    $questionGroupId: String!
    $entityType: QuestionGroupEntityMapEnum!
    $sequence: Int!
    $dynamic: Boolean!
    $style: JSON
    $configData: JSON
    $parentId: String
  ) {
    upsertQuestionGroupEntityMapAndEntity(
      id: $id
      questionGroupId: $questionGroupId
      entityType: $entityType
      sequence: $sequence
      dynamic: $dynamic
      style: $style
      configData: $configData
      parentId: $parentId
    ) {
      ...QuestionGroupEntityMapFieldsFragment
    }
  }
  ${QuestionGroupEntityMapFieldsFragment}
`

const useUpsertQuestionGroupEntityMapAndEntity = (options = {}) => {
  const [
    upsertQuestionGroupEntityMapAndEntity,
    { loading: loadingUpsertQuestionGroupEntityMapAndEntity }
  ]: any[] = useMutation(upsertQuestionGroupEntityMapAndEntityMutation, options)

  return [
    upsertQuestionGroupEntityMapAndEntity,
    loadingUpsertQuestionGroupEntityMapAndEntity
  ]
}

export default useUpsertQuestionGroupEntityMapAndEntity
