import { CallForSubmissionStepEnum } from '../../../types'
import {
  appSearchColumnMap,
  Content,
  getEngineHitKey,
  headerStyle
} from '../constants'
import {
  AppSearchFiltersInput,
  CellRendererProps,
  mergeFilters
} from '../../../components/Table'
import {
  getCategoryColumn as catColum,
  commonFixedColumns,
  companyColumn,
  CURATION_SUBMISSIONS_TABLE_CONFIG,
  getQueryFiltersByCFS,
  getTableConfigArgs,
  productColumn
} from '../../Curation/constants'
import React, { useEffect, useState } from 'react'
import CheckBox from '../../../ui-library/CheckBox'
import { Flex } from '../../../components/FlexBox'
import { ActivityIndicatorComponent } from '../components/SharedComponents'
import useCategoriesByMarketSegmentQuery from '../hooks/useCategoriesByMarketSegmentQuery'
import InnovationSubmissionAdviseFieldsFragment from './graphql/InnovationSubmissionAdviseFieldsFragment'
import { TableNameEnum } from '../../../components/DynamicTable/types'

const selectSubmission = {
  id: 'selectionItemColumn',
  width: 8,
  header: 'callsForSubmission:table:selectItem',
  headerStyle,
  emptyMessage: '',
  isEditable: false,
  fixed: true,
  isSelectable: true,
  Cell: ({
    item: submission,
    // @ts-ignore
    setSelectedSubmissions = () => {},
    isSmallScreen,
    label,
    selectItemProps
  }: CellRendererProps<any>) => {
    const submissionId = submission?.id
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
      setIsSelected(!!selectItemProps?.isSelected)
      setSelectedSubmissions(selectItemProps?.selectedItems)
    }, [selectItemProps?.isSelected])

    useEffect(() => {
      setIsSelected(!!selectItemProps?.selectedItems?.[submissionId])
    }, [selectItemProps?.selectedItems?.[submissionId]])

    const onToggle = selectItemProps?.onToggle
      ? selectItemProps?.onToggle
      : selectItemProps?.onSelect
      ? selectItemProps?.onSelect
      : undefined
    const onPress = () => {
      setIsSelected(!isSelected)
      onToggle && onToggle(submissionId)
    }
    const isDisabled = !!selectItemProps?.isDisabled

    const CheckBoxComponent = () => {
      return (
        <CheckBox
          checked={isSelected}
          onPress={onPress}
          isReadOnly={isDisabled}
          flexContainerStyles={{
            paddingLeft: 0,
            marginBottom: 0
          }}
        />
      )
    }
    return isSmallScreen ? (
      <Flex>
        <Content isSmallScreen={isSmallScreen} label={label} title="" />
        {selectItemProps?.isLoading ? (
          <ActivityIndicatorComponent
            style={{ alignItems: 'baseline' }}
            size="small"
          />
        ) : (
          <CheckBoxComponent />
        )}
      </Flex>
    ) : selectItemProps?.isLoading ? (
      <ActivityIndicatorComponent
        style={{ alignItems: 'baseline' }}
        size="small"
      />
    ) : (
      <CheckBoxComponent />
    )
  }
}

interface AdvisorSubmissionSelectionTableConfigArgs extends getTableConfigArgs {
  setSelectedSubmissions: (val) => void
  marketSegmentId: string | null
}

export const getAdvisorSubmissionsSelectionTableConfig = (
  args: AdvisorSubmissionSelectionTableConfigArgs
) => {
  const {
    currentCFSStep,
    cfsId,
    engine,
    setSelectedSubmissions,
    isInnovationNewDynamicTable,
    marketSegmentId
  } = args
  const table = Object.assign({}, CURATION_SUBMISSIONS_TABLE_CONFIG)
  table.queryDynamicName = `advisorSubmissionSelectionSearchInnovationEngine`
  table.name = TableNameEnum.AdvisingSubmissionSelection

  const categoryColumn = {
    ...catColum(),
    filter: {
      type: 'filter',
      key: appSearchColumnMap['category'],
      isFilterArray: true,
      isMulti: true,
      defaultValue: '',
      isHook: true,
      styles: { minHeight: 0, maxWidth: '33vw' },
      hookOptions: {
        useHookQuery: useCategoriesByMarketSegmentQuery,
        hookParams: {
          marketSegmentId: marketSegmentId
        },
        hookKey: 'innovationCategories',
        labelKey: 'name',
        valueKey: 'id'
      },
      handleChange: (selection, setQueryFilters) => {
        setQueryFilters({
          category: selection?.category?.length
            ? selection?.category
            : undefined
        })
      }
    }
  }
  let columns: any[] = [
    {
      ...selectSubmission,
      ...{
        setSelectedSubmissions
      }
    },
    companyColumn,
    productColumn,
    categoryColumn
  ]

  table.columns = columns.map(column => ({
    ...column,
    fixed: !!commonFixedColumns.filter(
      fixedColumn => fixedColumn === column?.id
    ).length
  }))
  table.enableFilters = true
  table.isAlternativeView = true
  table.showStatusFilter = false
  table.showPDFButton = false
  table.showCsvButton = false
  table.isSticky = true
  table.engine = engine
  table.hitKey = getEngineHitKey(engine)
  table.skipFetchData = currentCFSStep === CallForSubmissionStepEnum.submission
  if (isInnovationNewDynamicTable) {
    table.customQueryFields = InnovationSubmissionAdviseFieldsFragment
  }
  const baseFilters = getQueryFiltersByCFS(cfsId)
  const filters: AppSearchFiltersInput = {
    all: [],
    none: []
  }

  if (
    currentCFSStep !== CallForSubmissionStepEnum.submission &&
    filters.none &&
    filters.all
  ) {
    filters.none.push({
      [appSearchColumnMap['submissionStep']]:
        CallForSubmissionStepEnum.submission
    })

    filters.none.push({
      [appSearchColumnMap['submissionStep']]:
        CallForSubmissionStepEnum.preCurate
    })

    filters.none.push({ [appSearchColumnMap['hasBeenFastPassed']]: 'true' })
  }

  table.filters = mergeFilters(baseFilters, filters)

  return table
}
