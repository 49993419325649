import { appSearchColumnMap } from '../../screens/CallForSubmission/constants'
import {
  CSVExtraParamTypeEnum,
  CSVExtraParamsObject,
  TableNameEnum
} from './types'

export const NEW_DYNAMIC_TABLE_FF = 'innovationNewDynamicTable'

const arrayEquals = (oldKeys: string[], newKeys: string[]): boolean => {
  return (
    Array.isArray(oldKeys) &&
    Array.isArray(newKeys) &&
    oldKeys.length === newKeys.length &&
    oldKeys.every((val, index) => val === newKeys[index])
  )
}

export const shouldMemoizeComponent = (oldProps, newProps) => {
  let shouldMemoize = oldProps === newProps

  if (!shouldMemoize) {
    if (!oldProps || !newProps) {
      return false
    }
    const newKeys: string[] = Object.keys(oldProps)
    const oldKeys: string[] = Object.keys(newProps)

    shouldMemoize = arrayEquals(oldKeys, newKeys)

    if (shouldMemoize) {
      for (const keyProp of newKeys) {
        const evaluatedOldProp = oldProps[keyProp]
        const evaluatedNewProp = newProps[keyProp]

        switch (typeof evaluatedNewProp) {
          case 'object':
            if (evaluatedNewProp) {
              shouldMemoize = shouldMemoizeComponent(
                evaluatedOldProp,
                evaluatedNewProp
              )
            } else {
              // For null values
              shouldMemoize = evaluatedNewProp === evaluatedOldProp
            }
            break
          case 'function':
            shouldMemoize =
              evaluatedOldProp.toString() === evaluatedNewProp.toString()
            break
          default:
            shouldMemoize = evaluatedOldProp === evaluatedNewProp
            break
        }
        if (!shouldMemoize) {
          break
        }
      }
    } else {
      // Should re render because prop keys are different
    }
  }

  return shouldMemoize
}

const categoryFilters: CSVExtraParamsObject[] = [
  {
    id: 'product-category',
    appSearchId: appSearchColumnMap['category'],
    type: CSVExtraParamTypeEnum.Question
  },
  {
    id: 'legacy-ehir-primary-category',
    appSearchId: appSearchColumnMap['category'],
    type: CSVExtraParamTypeEnum.Question
  }
]

const cohortFilters: CSVExtraParamsObject[] = [
  {
    id: 'cohortPassGroupId',
    appSearchId: appSearchColumnMap['cohortPassGroupId'],
    type: CSVExtraParamTypeEnum.Status
  },
  {
    id: 'cohortCutGroupId',
    appSearchId: appSearchColumnMap['cohortCutGroupId'],
    type: CSVExtraParamTypeEnum.Status
  }
]

export const tableFiltersMap: { [key: string]: CSVExtraParamsObject[] } = {
  [TableNameEnum.Submission]: [
    ...categoryFilters,
    {
      id: 'submissionStatus',
      appSearchId: appSearchColumnMap['hasBeenSubmitted'],
      type: CSVExtraParamTypeEnum.Status
    }
  ],
  [TableNameEnum.PreCuration]: [
    ...categoryFilters,
    {
      id: 'preCurateStatus',
      appSearchId: appSearchColumnMap['passOrCutText'],
      type: CSVExtraParamTypeEnum.Status
    }
  ],
  [TableNameEnum.Cohort]: [...cohortFilters],
  [TableNameEnum.Presentation]: [
    ...categoryFilters,
    {
      id: 'invites',
      appSearchId: appSearchColumnMap['invites'],
      type: CSVExtraParamTypeEnum.Status
    },
    {
      id: 'spotlights',
      appSearchId: appSearchColumnMap['spotlights'],
      type: CSVExtraParamTypeEnum.Status
    }
  ],
  [TableNameEnum.Advising]: [...categoryFilters],
  [TableNameEnum.AdvisingSubmissionSelection]: [...categoryFilters]
}
