import { Text } from 'react-native-elements'
import styled from 'styled-components/native'
import Theme from '../../../../../../constants/Theme'

const EllipsizedText = styled(Text).attrs({
  ellipsizeMode: 'tail',
  numberOfLines: 1
})`
  min-width: 100%;
  overflow: hidden;
  white-space: pre;
  display: inline-block;
  font-size: ${Theme.fontSizes[3]}px;
`

export default EllipsizedText
