import { useCallback } from 'react'
import { find, propEq } from 'ramda'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import useCurrentQuestion from '../../../hooks/useCurrentQuestion'

const upsertAnswerFileAttachmentMapMutation = gql`
  mutation upsertAnswerFileAttachmentMap(
    $name: String
    $answerId: String
    $questionId: String
    $formSubmissionId: String
    $cloudinaryDocument: CloudinaryDocumentObject
  ) {
    upsertAnswerFileAttachmentMap(
      name: $name
      answerId: $answerId
      questionId: $questionId
      formSubmissionId: $formSubmissionId
      cloudinaryDocument: $cloudinaryDocument
    ) {
      id
      name
      answerId
      fileAttachmentId
    }
  }
`

const useUpsertAnswerFileAttachmentMap = (
  questionId: string,
  formSubmissionId: string
) => {
  const { answer } = useCurrentQuestion(questionId)
  const [upsertAnswerFileAttachmentMap, { loading }]: any[] = useMutation(
    upsertAnswerFileAttachmentMapMutation,
    {
      refetchQueries: ['question']
    }
  )

  const uploadAnswerFileAttachmentMap = useCallback(
    file => {
      console.log('Uploading answer', file)
      const variables = {
        answerId: answer?.id,
        name: file?.filename,
        questionId,
        formSubmissionId,
        cloudinaryDocument: {
          cloudinaryId: file?.public_id,
          fileName: file?.filename,
          moderationStatus: find(
            propEq('kind', 'perception_point'),
            file?.moderation
          )?.status
        }
      }
      upsertAnswerFileAttachmentMap({ variables })
    },
    [answer]
  )

  return [uploadAnswerFileAttachmentMap, loading]
}

export default useUpsertAnswerFileAttachmentMap
