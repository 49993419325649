import React, { useContext } from 'react'
import { useTheme } from 'styled-components/native'
import Card from '../common/Card'
import { TouchableOpacity, View } from 'react-native'
import useTranslation from '../../../../hooks/useTranslation'
import { DashboardContext } from '../hooks/DashboardProvider'
import { Text } from '../../../../components/common/Text'
import CloudinaryAvatar from '../../../../components/CloudinaryAvatar'
import { AnimatedEffect } from '../../../../components/common/AnimatedComponent/types'
import { AdvisorsObject } from '../types'

interface FilterBadgeProps {
  partner: string
  setPartner: (value: 'Advisors' | 'Cohorts') => void
}

const FilterBadge = (props: FilterBadgeProps) => {
  const { partner } = props
  const { colors } = useTheme()
  const filterStyles = {
    fontSize: 10,
    marginHorizontal: 2,
    paddingHorizontal: 7,
    paddingVertical: 3,
    marginBottom: 5,
    borderColor: colors.tints.grays.g100,
    borderWidth: 1,
    borderRadius: 10
  }
  return (
    <TouchableOpacity>
      <Text
        style={{
          ...filterStyles,
          backgroundColor:
            partner === 'Advisors' ? colors.tints.grays.g100 : undefined
        }}
      >
        {partner}
      </Text>
    </TouchableOpacity>
  )
}

const PartnersInfo = ({ name, photo, company }) => {
  const zoomEffect: AnimatedEffect = {
    initialValue: 1,
    mouseEvent: 'Hover',
    styleProperty: value => ({
      transform: [{ scaleX: value }, { scaleY: value }]
    }),
    timeIn: 200,
    timeOut: 150,
    toValue: 1.15
  }
  return (
    <Card
      styles={{ padding: 5 }}
      width={'130px'}
      height={70}
      effects={[zoomEffect]}
      clickOpacity
      hoverOpacity
    >
      <View
        style={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        {photo && (
          <View
            style={{
              alignSelf: 'flex-start',
              marginRight: 5
            }}
          >
            <CloudinaryAvatar publicId={photo} size={48} />
          </View>
        )}
        <View
          style={{
            flex: 1,
            flexDirection: 'column'
          }}
        >
          <Text styles={{ textAlign: 'center', fontSize: 11, marginBottom: 5 }}>
            {name}
          </Text>
          <Text
            styles={{ textAlign: 'center', fontSize: 11, fontWeight: 'bold' }}
          >
            {company}
          </Text>
        </View>
      </View>
    </Card>
  )
}
const Partners = () => {
  const { t } = useTranslation()
  const {
    callForSubmission: { advisors },
    handleFilters
  } = useContext(DashboardContext)

  const handlePress = (advisor: AdvisorsObject) => {
    handleFilters({ filterType: 'Review', value: advisor.personId })
  }

  return (
    <Card
      minWidth={'31%'}
      styles={{ flex: 1 }}
      title={t('dashboard:components:partners:title')}
    >
      <View style={{ flexDirection: 'row', width: '100%', height: '10%' }}>
        {['Advisors', 'Cohorts'].map(partner => (
          <FilterBadge partner={partner} />
        ))}
      </View>
      <View
        style={{
          width: '100%',
          height: '75%',
          paddingVertical: 5,
          paddingHorizontal: 10,
          flexDirection: 'row',
          flexWrap: 'wrap',
          overflow: 'scroll',
          justifyContent: 'center'
        }}
      >
        {advisors.map(advisor => (
          <TouchableOpacity onPress={() => handlePress(advisor)}>
            <PartnersInfo
              name={advisor.name}
              photo={advisor.photo}
              company={advisor.company}
            />
          </TouchableOpacity>
        ))}
      </View>
    </Card>
  )
}

export default Partners
