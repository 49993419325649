import React, { useContext, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/native'
import useTranslation from '../../../../hooks/useTranslation'
import { DashboardContext } from '../hooks/DashboardProvider'
import { getRandomColor, sortByCountAndGetTopElements } from '../common/utils'
import PieChart from './PieChart'
import Card from '../common/Card'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { Text } from '../../../../components/common/Text'
import { TopQuestionsEnum } from './types'

interface FilterBadgeProps {
  topQuestion: TopQuestionsEnum
  currentTopQuestion: TopQuestionsEnum
  setTopQuestion: (value: TopQuestionsEnum) => void
  setLoading: (value: boolean) => void
}

const FilterBadge = (props: FilterBadgeProps) => {
  const { topQuestion, setTopQuestion, setLoading, currentTopQuestion } = props
  const { colors } = useTheme()
  const filterStyles = {
    fontSize: 10,
    marginHorizontal: 2,
    paddingHorizontal: 7,
    paddingVertical: 3,
    marginBottom: 5,
    borderColor: colors.tints.grays.g100,
    borderWidth: 1,
    borderRadius: 10
  }
  return (
    <TouchableOpacity
      onPress={() => {
        if (topQuestion !== currentTopQuestion) {
          setLoading(true)
          setTopQuestion(currentTopQuestion)
        }
      }}
    >
      <Text
        style={{
          ...filterStyles,
          backgroundColor:
            topQuestion === currentTopQuestion
              ? colors.tints.grays.g100
              : undefined
        }}
      >
        {currentTopQuestion}
      </Text>
    </TouchableOpacity>
  )
}

const TopByQuestion = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { categories, capabilities, countries, languages } = useContext(
    DashboardContext
  )
  const [topQuestion, setTopQuestion] = useState<TopQuestionsEnum>(
    TopQuestionsEnum.Capability
  )

  const questionData = useMemo(() => {
    let data: any[] = []
    switch (topQuestion) {
      case TopQuestionsEnum.Capability:
        data = capabilities
        break
      case TopQuestionsEnum.Category:
        data = categories
        break
      case TopQuestionsEnum.Country:
        data = countries
        break
      case TopQuestionsEnum.Language:
        data = languages
        break
      default:
        return []
    }
    const filteredData = data.length
      ? sortByCountAndGetTopElements(data, 'count', 'name', 'asc', 7, true)
      : []
    return filteredData
  }, [topQuestion, categories, countries, languages])

  const chart = useMemo(() => {
    setLoading(false)
    return questionData.map(({ count, name }) => ({
      value: count,
      title: name,
      color: getRandomColor()
    }))
  }, [questionData, loading])

  return (
    <Card
      minWidth={`36%`}
      styles={{ flex: 1 }}
      title={t('dashboard:components:topByQuestion:title')}
    >
      <View style={{ flexDirection: 'row', width: '100%', height: '10%' }}>
        {[
          TopQuestionsEnum.Capability,
          TopQuestionsEnum.Category,
          TopQuestionsEnum.Country,
          TopQuestionsEnum.Language
        ].map(currentTopQuestion => (
          <FilterBadge
            currentTopQuestion={currentTopQuestion}
            setTopQuestion={setTopQuestion}
            topQuestion={topQuestion}
            setLoading={setLoading}
          />
        ))}
      </View>
      {loading ? (
        <ActivityIndicator size={'small'} />
      ) : (
        <PieChart type={topQuestion} values={chart} />
      )}
    </Card>
  )
}

export default TopByQuestion
