import { interpret, createMachine } from 'xstate'

const loginStates = {
  initial: 'login',
  states: {
    login: {
      on: {
        SUCCESS: '#appCoordinatorMachine.verifyRegistrationCheck',
        FORGOT_PASSWORD: 'verifyEmail',
        REGISTER: '#appCoordinatorMachine.registerCheck',
        SHOW_LEGAL_SCREEN: '#appCoordinatorMachine.legal',
        SHOW_INVITATION_AUTH_CODE: '#appCoordinatorMachine.joinTeam'
      }
    },
    verifyEmail: {
      on: {
        EMAIL_VERIFIED: 'authCodeCheck',
        CONTINUE_REGISTER: '#appCoordinatorMachine.registerCheck.authCodeCheck'
      }
    },
    authCodeCheck: {
      on: {
        CODE_VERIFIED_RESET: '#appCoordinatorMachine.verifyRegistrationCheck'
      }
    },
    passwordReset: {
      on: {
        PASSWORD_SET: '#appCoordinatorMachine.verifyTermsCheck'
      }
    }
  }
}

const registerStates = {
  initial: 'verifyEmail',
  states: {
    verifyEmail: {
      on: {
        EMAIL_VERIFIED: 'authCodeCheck',
        CODE_VERIFIED_REGISTER: 'registerNewUser'
      }
    },
    authCodeCheck: {
      on: {
        CODE_VERIFIED_REGISTER: 'registerNewUser',
        CODE_VERIFIED_REGISTER_USER_TO_COMPANY: 'registerUserToCompany'
      }
    },
    registerNewUser: {
      on: {
        REGISTERED: '#appCoordinatorMachine.app',
        LOGGED_OUT: '#appCoordinatorMachine.loginCheck'
      }
    },
    registerUserToCompany: {
      on: {
        REGISTERED: '#appCoordinatorMachine.app',
        LOGGED_OUT: '#appCoordinatorMachine.loginCheck'
      }
    }
  }
}

const verifyRegistrationStates = {
  initial: 'verify',
  states: {
    verify: {
      on: {
        VERIFIED: [
          {
            target: '#appCoordinatorMachine.loginCheck.passwordReset',
            cond: (_, __, meta) => {
              return meta?.state?.event?.type === 'CODE_VERIFIED_RESET'
            }
          },
          {
            // Legal screens send this state and we can skip checks with this condition
            target: '#appCoordinatorMachine.legal',
            cond: (_, __, meta) => {
              return meta?.state?.event?.type === 'SHOW_LEGAL_SCREEN'
            }
          },
          {
            target: '#appCoordinatorMachine.verifyTermsCheck'
          }
        ],
        UNVERIFIED: [
          {
            // Legal screens send this state and we can skip checks with this condition
            target: '#appCoordinatorMachine.legal',
            cond: (_, __, meta) => {
              return meta?.state?.event?.type === 'SHOW_LEGAL_SCREEN'
            }
          },
          {
            target: '#appCoordinatorMachine.registerCheck.registerNewUser'
          }
        ],
        ERROR: '#appCoordinatorMachine.loginCheck'
      }
    }
  }
}

const verifyTermsCheck = {
  initial: 'verifyTerms',
  states: {
    verifyTerms: {
      on: {
        TERMS_VERIFIED: '#appCoordinatorMachine.app'
      }
    }
  }
}

const joinTeamStates = {
  initial: 'join',
  states: {
    join: {
      on: {
        INVITATION_CODE_VERIFIED:
          '#appCoordinatorMachine.loginCheck.passwordReset',
        NOT_CLAIMABLE: '#appCoordinatorMachine.unclaimableDomain'
      }
    }
  }
}

const appCoordinatorMachine = createMachine({
  id: 'appCoordinatorMachine',
  initial: 'loadingCheck',
  states: {
    loadingCheck: {
      on: {
        LOGGED_IN: 'verifyRegistrationCheck',
        NOT_LOGGED_IN: 'loginCheck',
        BYPASS_LOGIN: 'app',
        SHOW_LEGAL_SCREEN: 'legal',
        SHOW_INVITATION_AUTH_CODE: 'joinTeam'
      }
    },
    verifyRegistrationCheck: {
      ...verifyRegistrationStates
    },
    loginCheck: {
      ...loginStates
    },
    registerCheck: {
      ...registerStates
    },
    verifyTermsCheck: {
      ...verifyTermsCheck
    },
    app: {
      on: {
        LOGGED_OUT: 'loginCheck',
        RESETTING_PASSWORD: 'resettingPassword'
      }
    },
    resettingPassword: {
      on: {
        AUTH_CODE_REQUESTED: 'loginCheck.authCodeCheck'
      }
    },
    legal: {},
    joinTeam: {
      ...joinTeamStates
    },
    unclaimableDomain: {}
  }
})

const service = interpret(appCoordinatorMachine)
  .onTransition(state =>
    console.log(
      '%cApp Coordinator Machine State',
      'background:black;color:green',
      state.value
    )
  )
  .start()

export default service
