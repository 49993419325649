import gql from 'graphql-tag'
import useTranslation from '../../../../hooks/useTranslation'
import useQuery from '../../../../hooks/useQuery'
import { DashboardObject } from '../types'
import { useMemo } from 'react'

const getInnovationDashboardQuery = gql`
  query getInnovationDashboard($cfsId: String!) {
    getInnovationDashboard(cfsId: $cfsId) {
      callForSubmission {
        id
        name
        average
        advisors {
          id
          name
          photo
          company
          personId
        }
        submissions {
          id
          name
          userBase
          funding
          status
          step
          category
          capability
          countries
          languages
          reviews
          wass
          topics {
            name
            frequency
            type
          }
          company {
            id
            name
            url
            yearFounded
            funding
            employeeCount
          }
        }
        steps {
          step
          openAt
          closeAt
          status
        }
      }
    }
  }
`

const useInnovationDashboardQuery = (cfsId: string, skip?: boolean) => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(getInnovationDashboardQuery, {
    variables: {
      cfsId
    },
    skip: skip || !cfsId,
    fetchPolicy: 'cache-first',
    errorMessage: t('dashboard:common:error')
  })

  const dashboardData = useMemo(
    () => data?.getInnovationDashboard as DashboardObject,
    [data?.getInnovationDashboard]
  )

  return {
    data: dashboardData,
    refetch,
    loading
  }
}

export default useInnovationDashboardQuery
