import React, { useCallback, useContext } from 'react'
import {
  SelectAllElementsContainer,
  SelectAllElementsLinkContainer,
  SelectAllElementsText
} from '../SharedStyledComponents'
import { TableContext } from '../../../hooks/TableProvider'
import useTranslation from '../../../../../hooks/useTranslation'

interface SelectAllElementsProps {
  leftTable: boolean
  enableSelectAllElements: boolean
}

const SelectAllElements = (props: SelectAllElementsProps) => {
  const { t } = useTranslation()
  const {
    data,
    totalElements,
    handleSetSelectAllElements,
    selectAllElements
  } = useContext(TableContext)
  const { leftTable, enableSelectAllElements } = props

  const currentElements = data ? data.length : 0

  const showSelectAllElementsText =
    (data && data.length > 0 && leftTable) ?? false

  const handleOnClick = useCallback(() => {
    handleSetSelectAllElements(!selectAllElements)
  }, [selectAllElements, handleSetSelectAllElements])

  return (
    <SelectAllElementsContainer
      onClick={enableSelectAllElements ? handleOnClick : () => {}}
    >
      {showSelectAllElementsText ? (
        <>
          <SelectAllElementsLinkContainer>
            <SelectAllElementsText>
              {t('common:table:selectAll:label1')}
            </SelectAllElementsText>
            <SelectAllElementsText isBold>{`${
              selectAllElements ? totalElements : currentElements
            } `}</SelectAllElementsText>
            <SelectAllElementsText>{`${t('common:table:selectAll:label2')}${
              selectAllElements ? '' : t('common:table:selectAll:label3')
            }${t('common:table:selectAll:label4')}`}</SelectAllElementsText>
          </SelectAllElementsLinkContainer>

          {enableSelectAllElements ? (
            <SelectAllElementsLinkContainer>
              <SelectAllElementsText isHighlighted>
                {selectAllElements
                  ? t('common:table:selectAll:label5')
                  : t('common:table:selectAll:label6')}
              </SelectAllElementsText>
              <SelectAllElementsText isHighlighted isBold>
                {selectAllElements ? '' : `${totalElements} `}
              </SelectAllElementsText>
              <SelectAllElementsText isHighlighted>
                {selectAllElements ? '' : t('common:table:selectAll:label7')}
              </SelectAllElementsText>
            </SelectAllElementsLinkContainer>
          ) : null}
        </>
      ) : null}
    </SelectAllElementsContainer>
  )
}

export default SelectAllElements
