import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { pick } from 'ramda'

import { Question, JSONStyles } from './types'
import DateInput from '../../ui-library/DateInput'
import useTranslation from '../../hooks/useTranslation'
import useQuestionDependency from './hooks/useQuestionDependency'
import { useField } from 'formik'

const DateContainer = styled(View)`
  flex-basis: 100%;
`

export type DynamicDateInputStyles = Pick<
  JSONStyles,
  'containerStyles' | 'errorContainerStyles' | 'labelStyles' | 'styles'
>

interface DateInputProps {
  name: string
  label: string
  styles: DynamicDateInputStyles
  formSubmissionId: string
  questionDependencyIds?: string[]
  questionId: string
  initialValue: string | undefined
}

interface DynamicDateInputProps {
  isReadOnly?: boolean
  data: DateInputProps
}

const DynamicDateInput = (props: DynamicDateInputProps) => {
  const { t } = useTranslation()
  const { data, isReadOnly = false } = props
  const {
    name,
    label,
    questionId,
    questionDependencyIds = [],
    initialValue,
    styles
  } = data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name)
  const { visible } = useQuestionDependency(
    questionDependencyIds,
    questionId,
    null
  )

  return (
    visible && (
      <DateContainer>
        <DateInput
          onBlur={() => helpers.setTouched(true)}
          name={name}
          label={t(label)}
          containerStyles={{
            width: '100%',
            ...((styles?.styles as any) || {})
          }}
          isReadOnly={isReadOnly}
          initialValue={initialValue}
        />
      </DateContainer>
    )
  )
}

export default DynamicDateInput

export const transformDateInputData = (
  question: Question,
  formSubmissionId: string
): DateInputProps => {
  return {
    name: `questions.${question?.id}`,
    label: question?.questionText,
    styles: pick(
      ['containerStyles', 'labelStyles', 'styles'],
      question?.style || {}
    ),
    formSubmissionId,
    questionId: question?.id,
    questionDependencyIds: question?.questionDependencies?.map(qd => qd?.id),
    initialValue: ''
  }
}
