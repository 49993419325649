import { useEffect } from 'react'
import Firebase from '../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'

const ResetPasswordCheck = ({ send }) => {
  useEffect(() => {
    send('AUTH_CODE_REQUESTED')
    getFBAuth(Firebase).signOut()
  }, [])

  return null
}

export default ResetPasswordCheck
