import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { InnovationCategory } from '../../../types'

const innovationCategoriesForCFSAndAdvisorQuery = gql`
  query innovationCategoriesForCFSAndAdvisor(
    $advisorUserId: String!
    $cfsId: String!
  ) {
    innovationCategoriesForCFSAndAdvisor(
      advisorUserId: $advisorUserId
      cfsId: $cfsId
    ) {
      id
      name
      sort
    }
  }
`

interface InnovationCategoriesObject {
  innovationCategories: InnovationCategory[]
  loading: boolean
}

const useInnovationCategoriesForCFSAndAdvisorQuery = ({
  advisorUserId,
  cfsId
}): InnovationCategoriesObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(
    innovationCategoriesForCFSAndAdvisorQuery,
    {
      variables: {
        advisorUserId,
        cfsId
      },
      skip: !advisorUserId || !cfsId,
      errorMessage: t('error:general:categories:error')
    }
  )

  return {
    innovationCategories: data?.innovationCategoriesForCFSAndAdvisor,
    loading
  }
}

export default useInnovationCategoriesForCFSAndAdvisorQuery
