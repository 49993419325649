import { useEffect } from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import useTranslation from './useTranslation'

export default function usePreventNavOnDirtyState(
  formik,
  isReadOnly: boolean,
  beforeRemoveDisabled = false
) {
  const navigation = useNavigation()
  const { t } = useTranslation()

  useEffect(() => {
    if (!formik.dirty || isReadOnly) return

    // handles browser unload events like refreshes and
    // navigating to a new site
    if (Platform.OS === 'web' && formik.dirty && !isReadOnly) {
      window.addEventListener('beforeunload', interruptUnload)
    }

    // handles in-app navigation through react-navigation events
    let removeNavigationListener = () => {}
    if (!beforeRemoveDisabled) {
      removeNavigationListener = navigation.addListener('beforeRemove', e => {
        const discardChanges = window.confirm(t('common:unsavedChanges'))
        if (!discardChanges) {
          e.preventDefault()
        }
      })
    }

    return () => {
      removeNavigationListener()
      if (Platform.OS === 'web') {
        window.removeEventListener('beforeunload', interruptUnload)
      }
    }
  }, [navigation, formik.dirty, isReadOnly])
}

function interruptUnload(e) {
  e.preventDefault()
  e.returnValue = ''
}
