import React from 'react'
import { Text as ElementsText } from 'react-native-elements'
import useThemeColor from '../../hooks/useThemeColor'

export const Text = ({ children, styles, ...rest }: TextProps) => {
  const color = useThemeColor('text')

  return (
    <ElementsText style={[{ color }, styles]} {...rest}>
      {children}
    </ElementsText>
  )
}

export const H1 = ({ children, styles, h1Style }: TextProps) => {
  const color = useThemeColor('text')

  return (
    <ElementsText style={[{ color }, styles]} h1Style={h1Style} h1>
      {children}
    </ElementsText>
  )
}

export const H2 = ({ children, styles, h2Style }: TextProps) => {
  const color = useThemeColor('text')

  return (
    <ElementsText style={[{ color }, styles]} h2Style={h2Style} h2>
      {children}
    </ElementsText>
  )
}

export const H3 = ({ children, styles, h3Style }: TextProps) => {
  const color = useThemeColor('text')

  return (
    <ElementsText style={[{ color }, styles]} h3Style={h3Style} h3>
      {children}
    </ElementsText>
  )
}

export const H4 = ({ children, styles, h4Style }: TextProps) => {
  const color = useThemeColor('text')

  return (
    <ElementsText style={[{ color }, styles]} h4Style={h4Style} h4>
      {children}
    </ElementsText>
  )
}

export const Label = ({ children, styles }: TextProps) => {
  return (
    <ElementsText style={styles} h4>
      {children}
    </ElementsText>
  )
}

export interface TextProps {
  children: string | string[]
  styles?: any
  onPress?: () => void
  h1Style?: any
  h2Style?: any
  h3Style?: any
  h4Style?: any
  testID?: string
}
