import React, { useState, useCallback, FC } from 'react'

import { Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

// Hooks
import useTranslation from '../../../../../hooks/useTranslation'
import useIsSmallScreen from '../../../../../hooks/useIsSmallScreen'
import useToast from '../../../../../hooks/useToast'
import useUpdateBulkSubmissionStatusMutation from '../../../../Submission/hooks/useUpdateBulkSubmissionStatusMutation'
// Components
import Modal from '../../../../../components/common/Modal'
import { Flex } from '../../../../../components/FlexBox'

import Button from '../../../../../ui-library/Button'
import TouchableIcon from '../../../../../components/icon/TouchableIcon'
import { Title } from '../../../../InnovatorDirectory/components/SharedComponents'
import { StyledLabel } from '../../../../../ui-library/TextInput/Label'
import Dropdown, {
  DropdownOption
} from '../../../../../components/common/Dropdown'
import { InnovationSubmissionStatusEnum } from '../../../../../types'

interface PreCurateDialogProps {
  openDialog: boolean
  triggerClose: () => void
  selectedElements: string[]
  submissionStatus?: InnovationSubmissionStatusEnum
}

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`
const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[3]}px;
  `}
`

const containerStyle = {
  flexBasis: '48%'
}
const buttonStyle = {
  width: '100%'
}

const dropdownOptions: DropdownOption[] = [
  {
    label: 'Pass',
    value: InnovationSubmissionStatusEnum.submitted
  },
  {
    label: 'Cut',
    value: InnovationSubmissionStatusEnum.rejected
  },
  {
    label: 'Fast Pass',
    value: InnovationSubmissionStatusEnum.fastPassed
  },
  {
    label: 'Flag for Discussion',
    value: InnovationSubmissionStatusEnum.flagged
  }
]

const PreCurateDialog: FC<PreCurateDialogProps> = ({
  openDialog,
  triggerClose,
  selectedElements,
  submissionStatus
}) => {
  const { setToastErrorMessage, setToastMessage } = useToast()
  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()
  const titleStyle = isSmallScreen ? { fontSize: 15 } : {}
  const { t } = useTranslation()
  const [
    updateBulkInnovationSubmissionStatus,
    loading
  ] = useUpdateBulkSubmissionStatusMutation({
    refetchQueries: ['preCurationSearchInnovationEngine'],
    onError: error => {
      console.log(error)
      setToastErrorMessage(t('settings:testing:errorUpdatingSubmissions'))
      onClose()
    },
    onCompleted: () => {
      setToastMessage(t('settings:testing:allSubmissionsUpdated'))
      onClose()
    }
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dropdownValue, setDropdownValue] = useState('')

  const onClose = useCallback(() => {
    triggerClose()
    setIsLoading(false)
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true)
    await updateBulkInnovationSubmissionStatus(
      selectedElements as string[],
      dropdownValue,
      false,
      false,
      true
    )
  }

  return (
    <Modal
      close={onClose}
      open={openDialog}
      styles={{
        minWidth: '350px',
        maxWidth: '450px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={onClose} />

      <Flex padding={space[3]}>
        <Title>{t('settings:testing:updateSubmissions')}</Title>
        <View
          style={{
            marginTop: space[3]
          }}
        >
          {selectedElements?.length ? (
            <Description>
              {t('settings:testing:updateSubmissionsDescription', {
                count: selectedElements.length
              })}
            </Description>
          ) : null}
          <View style={{ marginVertical: space[3] }}>
            <StyledLabel>{t('curation:advisors:table:cfsStatus')}</StyledLabel>
            <Dropdown
              name="preCurateSubmissionStatusDropdown"
              value={dropdownValue}
              options={dropdownOptions}
              onSelect={toStatus => setDropdownValue(toStatus)}
              menuPortalTarget={document?.body}
            />
          </View>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: space[3] }}
          >
            <Button
              title={t('callsForSubmission:dialog:cancelBtn')}
              type="outline"
              onPress={onClose}
              containerStyle={containerStyle}
              buttonStyle={buttonStyle}
              titleStyle={titleStyle}
            />
            <Button
              title={t('common:buttons:confirm')}
              type="solid"
              onPress={handleSubmit}
              containerStyle={containerStyle}
              buttonStyle={buttonStyle}
              titleStyle={titleStyle}
              disabled={
                !dropdownValue ||
                dropdownOptions.find(opt => opt.label === submissionStatus)
                  ?.value === dropdownValue ||
                isLoading
              }
              isProcessing={loading || isLoading}
            />
          </Flex>
        </View>
      </Flex>
    </Modal>
  )
}

export default PreCurateDialog
