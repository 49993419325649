import { SidebarOptions, SidebarOptionsEnum } from './types'
import Questions from './Components/Questions'
import QuestionGroups from './Components/QuestionGroups'
import Forms from './Components/Forms'
import FormBlocks from './Components/FormBlocks'
import FormBlockQuestionsAndGroups from './Components/FormBlockQuestions'

export const sidebarOptions: SidebarOptions[] = [
  {
    label: 'Forms',
    options: [
      {
        option: SidebarOptionsEnum.Forms,
        label: 'platformManagement:forms:menuItems:forms:title',
        component: Forms
      },
      {
        option: SidebarOptionsEnum.FormBlock,
        label: 'platformManagement:forms:menuItems:formBlocks:title',
        component: FormBlocks
      },
      {
        option: SidebarOptionsEnum.FormBlockQuestions,
        label: 'platformManagement:forms:menuItems:formBlockQuestions:title',
        component: FormBlockQuestionsAndGroups
      }
    ]
  },
  {
    label: 'Questions',
    options: [
      {
        option: SidebarOptionsEnum.QuestionGroups,
        label: 'platformManagement:forms:menuItems:questionGroups:title',
        component: QuestionGroups
      },
      {
        option: SidebarOptionsEnum.Questions,
        label: 'platformManagement:forms:menuItems:questions:title',
        component: Questions
      }
    ]
  }
]
