import React, { useState, useEffect } from 'react'
import { MessageStatus } from '../../../../../recoil/toastMessageAtom'
import theme from '../../../../../constants/Theme'
import { ModalToastProps } from './types'
import { DEFAULT_TIMEOUT } from './constants'
import { ToastContainer, ToastText } from './SharedStyledComponents'

const ModalToast = (props: ModalToastProps): any => {
  const { message } = props
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!!message && !show) {
      setShow(true)
      setTimeout(() => setShow(false), DEFAULT_TIMEOUT)
    }
  }, [message])

  return show ? (
    <ToastContainer
      style={{
        backgroundColor:
          message?.status === MessageStatus.Success
            ? theme.colors.cta.secondary
            : theme.colors.danger
      }}
    >
      <ToastText>{message?.message}</ToastText>
    </ToastContainer>
  ) : null
}

export default ModalToast
