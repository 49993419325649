import React, { FC } from 'react'

// Types
import { InnovationAccessKey } from '../../../types'
// Components
import { ScreenContainerWithTabs } from '../../../components/layout/ScreenContainer'
import withNavigationPermissions from '../../../navigation/withNavigationPermissions'
import Components from './Components'
import { View } from 'react-native'
import { FormsManagementScreenProps } from './types'

// Types

const FormsManagementScreen: FC<FormsManagementScreenProps> = () => {
  return (
    <ScreenContainerWithTabs>
      <View style={{ width: '100%', height: '100%' }}>
        <Components />
      </View>
    </ScreenContainerWithTabs>
  )
}

const FormsManagement = withNavigationPermissions(FormsManagementScreen, [
  InnovationAccessKey.PLATFORM_MANAGEMENT
])

export default FormsManagement
