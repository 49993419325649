import React from 'react'
import { DynamicTableItemProps } from './constants'
import DynamicTableRow from './DynamicTableRow'

function DynamicTableItem<T>({ item, ...rest }: DynamicTableItemProps<T>) {
  const {
    config: { RowWrapper }
  } = rest
  const dynamicTableRowProps = {
    item,
    ...rest
  }
  return RowWrapper ? (
    <RowWrapper item={item}>
      <DynamicTableRow {...(dynamicTableRowProps as any)} />
    </RowWrapper>
  ) : (
    <DynamicTableRow {...(dynamicTableRowProps as any)} />
  )
}

export default DynamicTableItem
