import React from 'react'

import { useTheme } from 'styled-components/native'
// Types
import {
  TableConfig,
  CellRendererProps,
  ColumnDefinition
} from '../../../../../components/Table'
import { TableNameEnum } from '../../../../../components/DynamicTable/types'
// Hooks
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Theme from '../../../../../constants/Theme'
import { Flex } from '../../../../../components/FlexBox'
import TouchableIcon, {
  TouchableIconProps
} from '../../../../../components/icon/TouchableIcon'
import { SmallRow } from '../../../../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../../../../components/Table/components/DynamicTable/SharedStyledComponents'
import { QuestionGroupEntityMapValues } from '../../types'
import { Content } from '../../../SharedStyledComponents'
// Types

// Styles
const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[2]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'center',
  textAlignVertical: 'center',
  width: '100%'
}
const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}
const cellTextStyle = { textAlign: 'center' }

// Columns
const formBlockSequenceColumn: ColumnDefinition<any> = {
  id: 'formBlockSequenceColumn',
  header: 'platformManagement:forms:menuItems:formBlocks:blockSequence',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  fixed: true,
  width: 20,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    const blockSequence = `${item?.sequence}`

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={blockSequence}
        style={cellTextStyle}
      />
    )
  }
}

const formBlockTitleColumn: ColumnDefinition<any> = {
  id: 'formBlockTitleColumn',
  header: 'platformManagement:forms:menuItems:formBlocks:blockTitle',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 25,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    const configData = JSON.parse(
      JSON.stringify(item?.configData) ??
        JSON.stringify(item?.entity?.configData) ??
        '{}'
    )

    const blockTitle = `${
      configData?.title ||
      configData?.blockLabel ||
      configData?.questionGroupTitle ||
      ''
    }`

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={blockTitle}
        style={cellTextStyle}
      />
    )
  }
}

const formBlockEditColumn = ({ handleEditFormBlock }) => ({
  id: 'formBlockEditColumn',
  header: 'platformManagement:forms:menuItems:questions:edit',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 10,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors, sizes } = useTheme()
    const commonProps: TouchableIconProps = {
      name: 'pencil',
      width: 22,
      height: 22,
      color: colors.darkGradientBackground,
      style: { alignSelf: 'center', marginHorizontal: sizes[1] }
    }

    return (
      <Flex flexDirection="row" justifyContent="center">
        <TouchableIcon
          {...commonProps}
          onPress={() => {
            handleEditFormBlock(item)
          }}
        />
      </Flex>
    )
  }
})

export const QUESTIONS_MNG_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: TableNameEnum.FormBlocksManagementTable,
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

interface getFormBlocksTableArgs {
  handleEditFormBlock: (formBlock: QuestionGroupEntityMapValues) => void
}

export const getFormBlocksTableConfig = ({
  handleEditFormBlock
}: getFormBlocksTableArgs) => {
  const table = Object.assign(
    {},
    QUESTIONS_MNG_TABLE_CONFIG
  ) as TableConfig<any>

  let columns = [
    formBlockSequenceColumn,
    formBlockTitleColumn,
    formBlockEditColumn({
      handleEditFormBlock
    })
  ]
  table.columns = columns
  table.showTotalElements = true

  return table
}
