import React, {
  forwardRef,
  useContext,
  ReactNode,
  useRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import {
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  View
} from 'react-native'
import styled, { ThemeContext } from 'styled-components/native'

import { TextInputStyles } from '../../components/DynamicForm/TextInput'
import {
  ErrorContainer,
  ErrorText
} from '../../screens/authentication/components/SharedStyledComponents'

import Label from './Label'
import { propsToFilter } from '../../utils/NonReactNativeProp'
import {
  Content,
  InputContainer,
  PrintableContainer
} from '../common/PrintableStyledComponents'
import { FieldInputProps } from 'formik'

export const StyledTextInput = styled(RNTextInput).withConfig({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldForwardProp: (prop, defaultValidatorFn) => !propsToFilter.includes(prop)
})`
  ${({ theme, style, hasError, isReadOnly, errorMessage, small }) => `
    color: ${isReadOnly ? theme.colors.disabled : theme.colors.inputText};
    background: ${
      style?.backgroundColor
        ? style?.backgroundColor
        : theme.colors.inputBackground
    };
    border: 1px solid;
    border-color: ${
      hasError || errorMessage
        ? theme.colors.inputBorderError
        : theme.colors.inputBorder
    }
    border-radius: ${small ? theme.radii[4] : theme.radii[3]}px;
    margin-bottom: 8px;
    min-height: ${small ? 32 : 40}px;
    width: 100%;
    padding: 7px;
    position: relative;
`}
`

const TextAreaHiddenView = styled(View)`
  width: 1px;
  margin-right: -1px;
  position: absolute;
`

export interface TextInputProps extends RNTextInputProps {
  name?: string
  styles?: TextInputStyles
  hasError?: boolean
  errorMessage?: string
  label?: string
  helperText?: string
  // TODO this will be removed once we do the changes in the db and we fill the helperText
  withHelperText?: boolean
  labelStyles?: any
  containerStyles?: any
  renderRightComponent?: () => ReactNode
  tabIndex?: number
  isReadOnly?: boolean
  asPrintable?: boolean
  isAutoSizable?: boolean
  field?: FieldInputProps<any>
  testID?: string
  small?: boolean
}

export default forwardRef(TextInput)

function TextInput(props: TextInputProps, ref) {
  const {
    name,
    styles,
    labelStyles = {},
    label,
    containerStyles = {},
    renderRightComponent,
    hasError,
    errorMessage,
    isReadOnly,
    helperText,
    withHelperText = false,
    asPrintable = false,
    isAutoSizable = false,
    value,
    field,
    testID,
    small = false
  } = props

  const textInputName = useMemo(() => name ?? field?.name, [name, field?.name])

  const heightKeeperRef = useRef<HTMLDivElement>(null)
  const textInput = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => textInput.current)

  const setDynamicHeight = useCallback(() => {
    if (textInput?.current && heightKeeperRef?.current) {
      textInput.current.style.height = 'inherit'
      textInput.current.style.height = textInput.current.scrollHeight + 'px'
      heightKeeperRef.current.style.height =
        textInput.current.scrollHeight + 'px'
    }
  }, [heightKeeperRef, textInput])

  useEffect(() => {
    if (isAutoSizable && textInputName && value) {
      textInput?.current?.addEventListener('input', setDynamicHeight)
      window.addEventListener('resize', setDynamicHeight)
      setDynamicHeight()
    }
  }, [setDynamicHeight, isAutoSizable, textInputName, value])

  useEffect(() => {
    if (isAutoSizable && isReadOnly && value) {
      setDynamicHeight()
    }
  }, [setDynamicHeight, isAutoSizable, isReadOnly, value])

  const theme = useContext(ThemeContext) as any

  const inputStyle = {
    // @ts-ignore
    ...(props?.style || {}),
    outlineColor: theme.colors.blueBackground,
    outlineWidth: 1
  }

  if (asPrintable) {
    return (
      <PrintableContainer>
        <Label
          label={label}
          helperText={helperText}
          withHelperText={withHelperText}
          labelStyles={labelStyles}
          asPrintable
        />
        <InputContainer>
          <Content>{props.value}</Content>
        </InputContainer>
      </PrintableContainer>
    )
  }

  return (
    <View style={[containerStyles, { marginBottom: 10 }]}>
      <Label
        label={label}
        helperText={helperText}
        labelStyles={labelStyles}
        withHelperText={withHelperText}
      />
      <TextAreaHiddenView ref={heightKeeperRef} />
      <StyledTextInput
        testID={testID}
        nativeID={textInputName}
        ref={textInput}
        name={textInputName}
        placeholderTextColor={theme.colors.placeholderText}
        hasError={hasError}
        isReadOnly={isReadOnly}
        editable={!isReadOnly}
        {...props}
        style={inputStyle}
        small={small}
      />
      {renderRightComponent && renderRightComponent()}
      {errorMessage ? (
        <ErrorContainer style={styles?.errorContainerStyles}>
          <ErrorText>{errorMessage}</ErrorText>
        </ErrorContainer>
      ) : null}
    </View>
  )
}
