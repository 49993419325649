import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  stroke: string
  size?: number
  style?: object | object[]
}

const BinIcon = React.memo(
  ({ color, stroke, size = 16, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg
        width={size}
        height={size}
        x="0px"
        y="0px"
        viewBox={`0 0 16 16`}
        fill={'none'}
        {...rest}
      >
        <Path
          d="M4.79998 3.20001V1.60001C4.79998 1.0109 5.27755 0.53334 5.86665 0.53334H10.1333C10.7224 0.53334 11.2 1.0109 11.2 1.60001V3.20001M1.06665 3.73334H14.9333M2.66665 3.73334V14.4C2.66665 14.9891 3.14421 15.4667 3.73332 15.4667H12.2667C12.8558 15.4667 13.3333 14.9891 13.3333 14.4V3.73334M7.99998 7.46667V12.8M5.86665 9.60001V12.8M10.1333 9.60001V12.8"
          fill={color}
          stroke={stroke}
        />
      </Svg>
    </View>
  )
)

export default BinIcon
