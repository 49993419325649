import { useRecoilState } from 'recoil'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { isEmpty } from 'ramda'
import lastAttemptedEmailAtom from '../recoil/lastAttemptedEmailAtom'

const useLastAttemptedEmail = () => {
  const [lastAttemptedEmailState, setLastAttemptedEmailState] = useRecoilState(
    lastAttemptedEmailAtom
  )

  const lastAttemptedEmail: string | undefined = isEmpty(
    lastAttemptedEmailState?.email
  )
    ? undefined
    : lastAttemptedEmailState?.email

  const initLastAttemptedEmail = async callback => {
    if (!lastAttemptedEmail) {
      const lastAttempted = await AsyncStorage.getItem('lastAttemptedUsername')
      if (lastAttempted && callback) {
        callback(lastAttempted)
        setLastAttemptedEmailState({ email: lastAttempted })
      }
    }
  }

  const updateLastAttemptedEmail = async email => {
    await AsyncStorage.setItem('lastAttemptedUsername', email)
    setLastAttemptedEmailState({ email })
  }

  return {
    lastAttemptedEmail,
    updateLastAttemptedEmail,
    initLastAttemptedEmail
  }
}

export default useLastAttemptedEmail
