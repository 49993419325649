import gql from 'graphql-tag'
import { useSetRecoilState } from 'recoil'

import Sentry from '../../../utils/sentry'
import useMutation from '../../../hooks/useMutation'
import generatingPDFAtom from '../../../recoil/generatingPDFAtom'

export const upsertInnovatorProductPublishLogQuery = gql`
  mutation upsertInnovatorProductPublishLog(
    $innovatorProductId: String!
    $isPublished: Boolean!
  ) {
    upsertInnovatorProductPublishLog(
      innovatorProductId: $innovatorProductId
      isPublished: $isPublished
    ) {
      id
      innovatorProductId
      isPublished
    }
  }
`

const useUpsertInnovatorProductPublishLog = () => {
  const setCreatingPDF = useSetRecoilState(generatingPDFAtom)

  const [
    upsertInnovatorProductPublishLogMutation,
    { loading }
  ]: any[] = useMutation(upsertInnovatorProductPublishLogQuery, {
    refetchQueries: ['innovatorProductBySubmissionId']
  })

  const upsertInnovatorProductPublishLog = async (
    innovationSubmissionId: string,
    innovatorProductId: string,
    isPublished: boolean
  ) => {
    if (isPublished) {
      setCreatingPDF(true)
    }

    const results = await upsertInnovatorProductPublishLogMutation({
      variables: {
        innovatorProductId,
        isPublished
      },
      update: (cache, { data: { upsertInnovatorProductPublishLog } }) => {
        try {
          if (!upsertInnovatorProductPublishLog?.isPublished) {
            cache.writeFragment({
              id: `InnovationSubmission:${innovationSubmissionId}`,
              fragment: gql`
                fragment InnovationSubmissionFragment on InnovationSubmission {
                  id
                  pdfCloudinaryId
                }
              `,
              data: {
                id: innovationSubmissionId,
                pdfCloudinaryId: null
              }
            })
          }
        } catch (upsertInnovatorProductPublishLogMutationUpdateError) {
          Sentry.captureException(
            new Error(
              `Error while merging PDSs. error: ${JSON.stringify(
                upsertInnovatorProductPublishLogMutationUpdateError
              )}`
            )
          )
        }
      }
    })

    return results
  }

  return { upsertInnovatorProductPublishLog, loading }
}

export default useUpsertInnovatorProductPublishLog
