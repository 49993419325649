import React, { forwardRef } from 'react'

import MainTable from './components'
import { TableProvider } from './hooks/TableProvider'
import { shouldMemoizeComponent } from './constants'
import { TableProps } from './types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DynamicTable<T>(props: TableProps<T>, ref) {
  return (
    <TableProvider props={props}>
      <MainTable />
    </TableProvider>
  )
}

// We need to add a custom memoized validation function
// because we are sending Object elements as a prop values.
//
// React perform a Shallow Comparison by default
// and always returns false on Objects/Functions comparison
const refTable = forwardRef(DynamicTable)
const memoizedTable = React.memo(refTable, shouldMemoizeComponent)

export { memoizedTable as DynamicTable }
