import React, { useContext } from 'react'
import { HeaderText, SubTitleView, TitleView } from '../SharedStyledComponents'
import useTranslation from '../../../../../hooks/useTranslation'
import { ColumnProps } from '../types'
import { TableContext } from '../../../hooks/TableProvider'

const Column = (props: ColumnProps): any => {
  const { t } = useTranslation()
  const context = useContext(TableContext)
  const { headerStyle, header, subtitle } = props
  const hasSubtitle = subtitle.length > 0
  const subtitleWidth = hasSubtitle ? 100 / subtitle.length : 0

  return (
    <TitleView>
      <SubTitleView>
        <HeaderText style={{ ...headerStyle, width: '100%' }}>
          {t(header)}
        </HeaderText>
      </SubTitleView>
      {hasSubtitle && (
        <SubTitleView
          style={{
            overflow: 'visible',
            flexDirection: 'row'
          }}
        >
          {subtitle.map((subT, index) => (
            <HeaderText
              key={`column-subtitle-header-index-${index}`}
              style={{
                width: `${subtitleWidth}%`,
                textAlign: 'center'
              }}
            >
              {/* If subtitle is a function, called it, if not transltate the key */}
              {typeof subT.label === 'function'
                ? subT.label(context)
                : t(subT.label)}
            </HeaderText>
          ))}
        </SubTitleView>
      )}
    </TitleView>
  )
}

export default Column
