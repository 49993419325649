import React, { useCallback, useState } from 'react'
import { NavigationContainer } from '@react-navigation/native'

import LoadingScreen from '../screens/LoadingScreen'
import LinkingConfiguration from './LinkingConfiguration'
import RootNavigator from './navigators/RootNavigator'
import useCurrentUserLoader from '../hooks/useCurrentUserLoader'
import useMixpanel, { EVENT_MAP } from '../hooks/useMixpanel'
import Dialog from '../components/common/Dialog'
import useIsSmallScreen from '../hooks/useIsSmallScreen'
import { useTranslation } from 'react-i18next'
import useHasPermission from '../hooks/useHasPermission'
import { CURATION_PERMISSIONS } from '../constants/roles'
import navigationRef from './navigationRef'

export default function Navigation() {
  const { t } = useTranslation()
  const { firebaseInitialized } = useCurrentUserLoader()
  const { trackWithProperties } = useMixpanel()
  const isSmallScreen = useIsSmallScreen()
  const isCurator = useHasPermission(CURATION_PERMISSIONS)

  const [forceResponsiveDialogOpen, setForceResponsiveDialogOpen] = useState(
    true
  )

  const [currentRouteName, setCurrentRouteName] = useState<string>('')

  const onCloseResponsiveDialog = useCallback(() => {
    setForceResponsiveDialogOpen(false)
  }, [])

  if (!firebaseInitialized) {
    return <LoadingScreen />
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      onReady={() => {
        setCurrentRouteName(
          navigationRef?.current?.getCurrentRoute()?.name as string
        )
      }}
      onStateChange={async () => {
        const previousRouteName = currentRouteName
        // @ts-ignore
        const currentNavigationRouteName = navigationRef?.current?.getCurrentRoute()
          ?.name

        if (previousRouteName !== currentNavigationRouteName) {
          trackWithProperties(EVENT_MAP.app.nav, {
            route: currentNavigationRouteName
          })
        }

        setCurrentRouteName(currentNavigationRouteName as string)
      }}
    >
      <RootNavigator />
      <Dialog
        isOpen={isCurator && isSmallScreen && forceResponsiveDialogOpen}
        title={t('common:dialogs:responsiveDisabled:title')}
        description={t('common:dialogs:responsiveDisabled:description')}
        onClose={onCloseResponsiveDialog}
      />
    </NavigationContainer>
  )
}
