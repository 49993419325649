import React from 'react'
import { Platform } from 'react-native'
import styled from 'styled-components/native'
import { Trans } from 'react-i18next'

import useTranslation from '../../../../hooks/useTranslation'

import { Flex } from '../../../../components/FlexBox'
import { Text } from '../../../../components/common/Text'
import Link from '../../../../components/Link'
import theme from '../../../../constants/Theme'

const FooterContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => (theme.isSmallScreen ? '' : 'align-self: flex-end;')}
  margin-top: ${({ theme }) => (theme.isSmallScreen ? '40px' : '60px')};
  padding: 10px 0;
  ${Platform.OS === 'web' ? 'padding: 17px 5%' : 'justify-content: center'};
  margin-bottom: 150px;
`

const FooterText = styled(Text)`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.copyright};
`

const defaultLinkStyles = {
  color: theme.colors.brightPrimary,
  textDecoration: 'none',
  fontStyle: 'italic'
}

export const TermsLink = ({ to, title, linkStyles = defaultLinkStyles }) => {
  const { t } = useTranslation()

  return (
    <Link to={to} title={t(title)} target="_blank" style={linkStyles}>
      {t(title)}
    </Link>
  )
}

const FooterLinks = ({ style }: any) => {
  const { t } = useTranslation()

  return (
    <FooterContainer style={style}>
      <FooterText
        style={{
          marginBottom: '10px',
          textAlign: 'center'
        }}
      >
        <Trans
          i18nKey="verifyTerms"
          defaults={t('legal:content')}
          components={{
            linkOne: <TermsLink to="/legal/terms" title="legal:tos:title" />,
            linkTwo: (
              <TermsLink to="/legal/privacy" title="legal:privacy:title" />
            ),
            linkThree: (
              <TermsLink to="/legal/cookie" title="legal:cookies:title" />
            )
          }}
        />
      </FooterText>

      <FooterText>{t('common:copyright')}</FooterText>
    </FooterContainer>
  )
}

export default FooterLinks
