import { FunctionComponent } from 'react'
import Theme from '../../../constants/Theme'
import { DropdownOption, DropdownValue } from '../../common/Dropdown'
import { ColumnDefinition } from '../types'

export const textInputStyle = {
  borderRadius: 4,
  marginTop: 4,
  marginBottom: 4,
  paddingLeft: 8,
  width: '100%',
  fontSize: Theme.fontSizes[3]
}

export const autoCompleteDropwdownStyle = {
  minHeight: 0,
  marginLeft: 16,
  marginBottom: 0,
  maxWidth: '25vw'
}

export interface AutoCompleteDropdownProps {
  options: DropdownOption[]
  value: DropdownValue
  placeholder: string
  onSelectValue: (value: string) => void
  disableFiltering?: boolean
  loading?: boolean
  styles?: any
  isMulti?: boolean
}

export interface SearchComponentProps {
  filter: FilterableColumnProps<any>
  queryFilter: Object
  header: string
  handleChangeSearchFilter: (value: string) => void
}

export interface FilterComponentProps {
  filter: FilterableColumnProps<any>
  queryFilter: Object
  header: string
  handleChangeFilters: (value: string) => void
}

export interface SortComponentProps {
  enableFilters: boolean
  header: string
  headerStyle: any
  sort: SortableColumnProps<any> | undefined
  filter?: FilterableColumnProps<any> | undefined
  orderBy: string | boolean
  orderDir: sortDir | boolean
  handleChangeSort: (value: string) => void
}

export interface TableHeaderCellProps {
  selectAll?: boolean
  selectCurrent?: boolean
  enableFilters: boolean
  isSelectable?: boolean
  handleSelectCurrent?: (value: boolean) => void
  handleSelect: (value: boolean) => void
  selectedItems?: object
  header: string
  width?: Number
  headerStyle: any
  sort: SortableColumnProps<any> | undefined
  filter: FilterableColumnProps<any> | undefined
  orderBy: string | boolean
  orderDir: sortDir | boolean
  queryFilter: Object
  handleChangeSort: (value: string) => void
  handleChangeSearchFilters: (value: string) => void
  handleChangeFilters: (value: string) => void
  subtitle?: any[]
}

export interface ConfigDefinition<T> {
  name: string
  headerContainerStyle?: any
  RowWrapper?: any
  LargeRow?: React.ReactNode
  SmallRow?: React.ReactNode
  query?: string
  pageSize?: number
  enableFilters?: boolean
  hitsKey?: string
  columns: (
    | ColumnDefinition<T>
    | SortableColumnDefinition<T>
    | FilterableColumnDefinition<T>
  )[]
}

export type filterType = 'search' | 'filter'

export interface FilterableColumnProps<T> {
  key: string
  type: filterType
  options?: DropdownOption[]
  isHook?: boolean
  defaultValue?: T
  isFilterArray?: boolean
  isMulti?: boolean
}

export interface FilterableColumnDefinition<T> extends ColumnDefinition<T> {
  filter?: FilterableColumnProps<T> | undefined
}

export interface TableProps<T> {
  config: ConfigDefinition<T>
  data: T[]
  loading: boolean
  error: any
  refetch: any
  fetchMore: any
  keyExtractor?: (item: T) => string
  isReadOnly: boolean
  emptyMessage?: string
  sortState?: SortState
  handleChangeSort?: (value: string) => void
  onRenderAgain?: (
    prevProps: TableProps<any>,
    nextProps: TableProps<any>
  ) => boolean
}

export type sortDir = 'asc' | 'desc'

export type SortState = [string, sortDir]

export type CellRendererProps<T> = {
  item: T
}

export type CellRenderer<T> = FunctionComponent<CellRendererProps<T>>

export interface SortableColumnProps<T> {
  key: string
  isDefault?: boolean
  defaultDir?: sortDir
}

export interface SortableColumnDefinition<T> extends ColumnDefinition<T> {
  sort: SortableColumnProps<T> | undefined
}

function getDefaultSortColumn<T>(
  columns: (ColumnDefinition<T> | SortableColumnDefinition<T>)[]
): SortableColumnDefinition<T> | undefined {
  // columns marked as sortDefault
  const sortDefaultCols = columns.filter(
    c => c['sort'] && c['sort']['isDefault']
  )
  if (sortDefaultCols.length === 1)
    return sortDefaultCols[0] as SortableColumnDefinition<T>
  if (sortDefaultCols.length > 1) {
    throw new Error(
      `Cannot specify more than one sortDefault column, found ${sortDefaultCols.length}`
    )
  }
  // none marked as sortDefault, take first sortable column
  const sortableCols = columns.filter(c => c['sort'])
  if (sortableCols.length > 1)
    return sortableCols[0] as SortableColumnDefinition<T>
}

export function getInitialSortState<T>(
  columns: ColumnDefinition<T>[]
): SortState | undefined {
  const defaultSortColumn = getDefaultSortColumn(columns)
  return defaultSortColumn
    ? [
        // @ts-ignore
        defaultSortColumn.sort.key,
        // @ts-ignore
        defaultSortColumn.sort.defaultDir || 'asc'
      ]
    : undefined
}

export const getInitialFilters = columns => {
  return columns
    ?.filter(({ filter }) => filter && filter.defaultValue !== undefined)
    ?.reduce(
      (acc, { filter: { key, defaultValue } }) => ({
        ...acc,
        [key]: defaultValue
      }),
      {}
    )
}

export const CSV_SEPARATOR_CHARACTER = ','

const EXCLUDED_COLUMNS = ['selectionItemColumn']

const addQuotesToCsvRowText = (rowContent: any): string => {
  const rowContentString = String(rowContent)
  return `"${rowContentString}"`
}

const getCsvHeaders = ({ config, t }) => {
  const {
    customCsv = false,
    getCustomCsvColumns = () => {},
    excludedColumns = []
  } = config
  const cols = customCsv
    ? getCustomCsvColumns
      ? getCustomCsvColumns(t)
      : null
    : config.columns
        .filter(
          col => ![...EXCLUDED_COLUMNS, ...excludedColumns].includes(col.id)
        )
        .map(col => (col?.header ? `"${t(col?.header)}"` : `"Unknown column"`))

  return cols && cols.join(CSV_SEPARATOR_CHARACTER)
}

const getCvsRows = ({ config, data, currentUserId, t }) => {
  const {
    customCsv = false,
    getCustomCsvData = () => {},
    getCustomCsvBody = () => {},
    excludedColumns = []
  } = config
  const csvBody = getCustomCsvBody && getCustomCsvBody(data)
  const rows = data.map(submission => {
    const result = customCsv
      ? getCustomCsvData(submission)
      : config.columns
          .filter(
            col => ![...EXCLUDED_COLUMNS, ...excludedColumns].includes(col.id)
          )
          .map(col => {
            if (col.getCsvData) {
              const rowContent = col.getCsvData({
                ...col,
                item: submission,
                role: col.role || '',
                currentUserId,
                t
              })
              return addQuotesToCsvRowText(rowContent)
            } else {
              throw new Error(
                `"Column: ${col.header} ${col.id} needs to implement getCsv"`
              )
            }
          })

    return customCsv ? result : result.join(CSV_SEPARATOR_CHARACTER)
  })
  return customCsv && csvBody ? csvBody : rows.join('\n')
}

export const getCsvData = ({ config, data, currentUserId, t }) => {
  const headers = getCsvHeaders({ config, t })
  const rows = getCvsRows({ config, data, currentUserId, t })

  return headers ? `${headers}\n${rows}` : `${rows}`
}
