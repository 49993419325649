import { SetterOrUpdater, useRecoilState } from 'recoil'
import lastSubmittedSubmissionAtom, {
  SubmissionProps
} from '../recoil/lastSubmittedSubmissionAtom'

const useLastSubmittedSubmission = () => {
  const [lastSubmittedSubmission, setLastSubmittedSubmission] = useRecoilState(
    lastSubmittedSubmissionAtom
  ) as [SubmissionProps, SetterOrUpdater<SubmissionProps>]

  return {
    lastSubmittedSubmission,
    setLastSubmittedSubmission
  }
}

export default useLastSubmittedSubmission
