import React, { FC, useState } from 'react'
import { useTheme } from 'styled-components/native'
import useDynamicForm from '../../../../../../hooks/useDynamicForm'
import { OriginSourceEnum } from '../../../../../../types'
import { Flex } from '../../../../../../components/FlexBox'
import { FormikProvider } from 'formik'
import Button from '../../../../../../ui-library/Button'
import useTranslation from '../../../../../../hooks/useTranslation'
import {
  AnswerEntityTypeEnum,
  ValidationPhaseEnum
} from '../../../../../../types/form'
import { ActivityIndicator } from 'react-native'
import useIsSmallScreen from '../../../../../../hooks/useIsSmallScreen'
import useFormAnswerQuery from '../../../../../Submission/hooks/useFormAnswerQuery'
import useSubmissionQuery from '../../../../../Submission/hooks/useSubmissionQuery'

interface CutFeedbackProps {
  cutFormId: string
  isDisabled?: boolean
  submissionId: string
  shouldRenderAnswers: boolean
  handleUpdateState: () => void
}

const CutFeedback: FC<CutFeedbackProps> = ({
  cutFormId,
  isDisabled,
  submissionId,
  shouldRenderAnswers,
  handleUpdateState
}) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()

  const { formAnswer, loading: loadingFormAnswer } = useFormAnswerQuery(
    submissionId,
    AnswerEntityTypeEnum.InnovationCutFeedback
  )

  const { submission } = useSubmissionQuery(submissionId)

  const {
    form,
    loadingFormData,
    component: dynamicForm,
    helpers
  } = useDynamicForm({
    formId: cutFormId,
    formSubmissionId: formAnswer?.id,
    formPhase: ValidationPhaseEnum.Review,
    answerEntityType: AnswerEntityTypeEnum.InnovationCutFeedback,
    originSource: OriginSourceEnum.Innovation,
    withFormButtonsConfig: {
      isSubmission: true
    },
    callForSubmission: submission?.callForSubmission,
    isReadOnly: isDisabled,
    shouldRenderAnswers: shouldRenderAnswers
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (loadingFormData) {
    return null
  }

  const validateForm = async () => {
    helpers.setAllFieldsTouched()

    const errors = await form.validateForm()

    return helpers.getFormErrorCount(errors)
  }

  const submitReview = async () => {
    helpers.setCurrentPhase(ValidationPhaseEnum.Submit)
    setIsSubmitting(true)

    await new Promise<void>(resolve =>
      setTimeout(async () => {
        const errors = await validateForm()

        if (!errors) {
          await handleUpdateState()
          await form.submitForm()
        }
        setIsSubmitting(false)
        resolve()
      }, 10)
    )
  }

  return (
    <Flex flexDirection="column" justifyContent="flex-start" w="100%">
      <FormikProvider value={form}>
        <Flex
          style={{
            position: 'relative'
          }}
        >
          {isSubmitting || loadingFormAnswer ? (
            <ActivityIndicator
              style={{
                alignItems: 'baseline',
                position: 'absolute',
                top: '50%',
                right: '55%'
              }}
              size="large"
            />
          ) : null}
          <Flex
            testID={'cutFeedback'}
            style={{
              ...(isSubmitting
                ? {
                    filter: 'blur(4px)'
                  }
                : {})
            }}
          >
            {dynamicForm}
          </Flex>
        </Flex>
      </FormikProvider>
      <Flex
        flexDiertion="row"
        flexWrap="wrap"
        alignItems={isSmallScreen ? 'flex-end' : 'flex-start'}
        w="100%"
        pt={`${space[4] + space[2]}px`}
      >
        <Button
          type="solid"
          title={t('curation:buttons:submit')}
          buttonStyle={{ width: '100%' }}
          containerStyle={{
            width: space[7] - space[5] - space[3],
            marginBottom: space[0]
          }}
          isProcessing={isSubmitting}
          disabled={!form.dirty}
          onPress={submitReview}
          accessibilityLabel={'endOfScreen'}
        />
      </Flex>
    </Flex>
  )
}

export default CutFeedback
