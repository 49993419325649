import { useSubscription } from '@apollo/client'
import useDownloadFiles from '../../../hooks/useDownloadFiles'
import useCurrentUser from '../../../hooks/useCurrentUser'
import {
  onInnovationSubmissionCloudinaryIdUpdated,
  innovationSubmissionCloudinaryIdUpdated
} from '../subscriptions/innovationSubmissionCloudinaryIdUpdatedSubscription'

interface UseUpdatedInnovationSubmissionCloudinaryIdSubscriptionProps {
  innovationSubmissionId: string
  pdfCloudinaryId?: string
  downloadStatus: any
  files: any
  setCurrentDownloadStatus: any
}
const useUpdatedInnovationSubmissionCloudinaryIdSubscription = ({
  innovationSubmissionId,
  pdfCloudinaryId
}: UseUpdatedInnovationSubmissionCloudinaryIdSubscriptionProps) => {
  const { currentUserId } = useCurrentUser()
  const cuId = currentUserId ? (currentUserId as string) : ''
  const { files, setFiles } = useDownloadFiles(cuId)

  useSubscription(innovationSubmissionCloudinaryIdUpdated, {
    variables: { id: innovationSubmissionId, pdfCloudinaryId },
    skip: !innovationSubmissionId,
    onData: ({ data }) => {
      return onInnovationSubmissionCloudinaryIdUpdated({
        subscriptionData: data,
        setFiles,
        files
      })
    }
  })
}

export default useUpdatedInnovationSubmissionCloudinaryIdSubscription
