import gql from 'graphql-tag'

const useUpdateCFSAfterReviewQuery = gql`
  subscription updateCFSAfterReview(
    $id: String!
    $withInnovationSubmissions: Boolean!
  ) {
    updateCFSAfterReview(id: $id) {
      id
      advisorsFavCount {
        id
        favCount
      }
      innovationSubmissions @include(if: $withInnovationSubmissions) {
        id
        submissionAverage {
          totalFav
          totalAcademy
          wass
          submissionScores {
            value
            question {
              type
              questionText
              configData
            }
          }
        }
      }
    }
  }
`

export default useUpdateCFSAfterReviewQuery
