import Container from './Container'
import Title from './Title'
import InputsContainer from './InputsContainer'
import TableContainer from './TableContainer'

const NAME_MAX_LENGTH = 100
const DESCRIPTION_MAX_LENGTH = 250

export {
  Container,
  Title,
  InputsContainer,
  TableContainer,
  NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH
}
