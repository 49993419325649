import React, { useState } from 'react'
import {
  TableRowContainer as TableRowLarge,
  TableColumn as TableRowSmall,
  TableCell
} from '../SharedStyledComponents'
import useTableRow from '../../hooks/useTableRow'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import { useTheme } from 'styled-components/native'

const TableRowHandler = ({
  tableColumns,
  config,
  item,
  isReadOnly,
  leftTable,
  setSelectedItems,
  selectedItems,
  setSelectAll,
  setSelectCurrent,
  rowHeight = -1 as Number,
  widthFixedTable,
  widthDynamicTable
}) => {
  const { space } = useTheme()
  const { LargeRow, SmallRow, queryDynamicName, isSticky } = config
  const handlers = useTableRow({ config })
  const [hover, setHover] = useState(false)
  const isSmallScreen = useIsSmallScreen()

  const getCellWidth = (width: number) => {
    const tableWidthV = leftTable
      ? widthFixedTable
      : widthDynamicTable + (widthDynamicTable > 100 ? space[2] : 0)
    return (width / tableWidthV) * 100
  }

  const LargeRowComponent = LargeRow || TableRowLarge
  const SmallRowComponent = SmallRow || TableRowSmall

  const selectItemProps = {
    isSelected: selectedItems[item?.id],
    selectedItems,
    onToggle: id => {
      setSelectedItems({ ...selectedItems, [id]: !selectedItems[id] })
      setSelectAll?.(false)
      setSelectCurrent?.(false)
    },
    onSelect: id => {
      setSelectedItems({ [id]: true })
      config?.onRowSelect && config?.onRowSelect(id)
      setSelectAll?.(false)
      setSelectCurrent?.(false)
    },
    isDisabled: isReadOnly
  }

  const cellProps = {
    handlers,
    isReadOnly,
    isSmallScreen,
    selectItemProps,
    queryDynamicName
  }

  return isSmallScreen ? (
    <SmallRowComponent>
      {tableColumns.map(
        ({ Cell, isEditable, header, itemMap, role, ...rest }, i) => (
          <TableCell key={i} width="100%">
            <Cell
              item={item}
              itemMap={itemMap}
              label={header}
              isEditable={isEditable}
              role={role}
              {...cellProps}
              {...rest}
            />
          </TableCell>
        )
      )}
    </SmallRowComponent>
  ) : (
    <LargeRowComponent
      leftTable={leftTable}
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      rowStyle={hover ? 'even' : 'odd'}
      isSticky={isSticky}
      isSelected={selectedItems[item?.id]}
      rowHeight={rowHeight}
    >
      {tableColumns.map(
        ({ Cell, width, minWidth, isEditable, itemMap, role, ...rest }, i) => {
          return (
            <TableCell key={i} width={getCellWidth(width)} minWidth={minWidth}>
              <Cell
                item={item}
                isEditable={isEditable}
                itemMap={itemMap}
                role={role}
                {...cellProps}
                {...rest}
              />
            </TableCell>
          )
        }
      )}
    </LargeRowComponent>
  )
}

export default TableRowHandler
