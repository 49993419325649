import { CallForSubmissionStepEnum, CurrentCFSStep } from '../../../types'

export const orderedSteps = [
  CallForSubmissionStepEnum.submission,
  CallForSubmissionStepEnum.preCurate,
  CallForSubmissionStepEnum.advising,
  CallForSubmissionStepEnum.cohort,
  CallForSubmissionStepEnum.voting,
  CallForSubmissionStepEnum.finalSelections,
  CallForSubmissionStepEnum.selection,
  CallForSubmissionStepEnum.event
]

interface useSetupNextStepProps {
  currentStep: CurrentCFSStep
  currentRouteName: string
}

const useSetupNextStep = ({
  currentStep,
  currentRouteName
}: useSetupNextStepProps) => {
  let baseStep = currentStep.name

  if (currentRouteName === 'CurationSubmissions') {
    baseStep = CallForSubmissionStepEnum.preCurate
  } else if (currentRouteName === 'CurationCohort') {
    baseStep = CallForSubmissionStepEnum.cohort
  } else if (currentRouteName === 'CurationPresentations') {
    baseStep = CallForSubmissionStepEnum.selection
  }

  const stepIndex = orderedSteps.findIndex(step => step === baseStep)
  const currentStepName = orderedSteps[stepIndex]
  const nextStepIndex = stepIndex + 1

  const nextStepName = orderedSteps[nextStepIndex]
  const nextNextStepName = orderedSteps[nextStepIndex + 1]

  return {
    stepIndex,
    currentStepName,
    nextStepIndex,
    nextStepName,
    nextNextStepName
  }
}

export default useSetupNextStep
