import React, { useContext, useEffect, useState } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import Card from '../common/Card'
import Table from './Table'
import { useTheme } from 'styled-components/native'
import { DashboardContext } from '../hooks/DashboardProvider'
import { currencyFormat } from '../common/utils'
import { InnovationSubmissionStatusEnum } from '../../../../types'
import capitalize from '../../../../utils/capitalize'
import useTranslation from '../../../../hooks/useTranslation'
import useLinkToScreen from '../../../../hooks/useLinkToScreen'
import { ColumProp } from './Table/types'
import { Text } from '../../../../components/common/Text'
import { CompanyObject, SubmissionsObject } from '../types'
import {
  EmployeeCountRangeEnum,
  EmployeeCountRangeValuesEnum
} from '../../../../types/company'

const Submissions = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const linkToScreen = useLinkToScreen()
  const [currentFilter, setCurrentFilter] = useState<
    'Submissions' | 'Companies'
  >('Submissions')
  const [[tableColumns, tableData], setTableData] = useState<
    [ColumProp[], SubmissionsObject[] | CompanyObject[]]
  >([[], []])
  const [loading, setLoading] = useState<boolean>(true)
  const {
    callForSubmission: { id: cfsId },
    submissions
  } = useContext(DashboardContext)

  const handleClick = (id: string) => {
    linkToScreen('CurationSubmissionDetails', {
      submissionId: id,
      id: cfsId
    })
  }

  useEffect(() => {
    let tempColumns: ColumProp[] = []
    let tempData: SubmissionsObject[] | CompanyObject[] = []
    if (submissions.length) {
      const getEmployeeRangeValue = (range: string): string => {
        const rangeIndex = Object.values(EmployeeCountRangeEnum).findIndex(
          key => key === range
        )
        const values = Object.values(EmployeeCountRangeValuesEnum)
        return values[rangeIndex] ?? '-'
      }
      setTableData([[], []])
      switch (currentFilter) {
        case 'Submissions':
          tempColumns = [
            {
              key: 'name',
              columnType: 'String',
              name: t('dashboard:components:submissions:name'),
              width: 12,
              getData: item => item.name,
              handleClick: (id: string) => handleClick(id)
            },
            {
              key: 'userBase',
              columnType: 'Number',
              name: t('dashboard:components:submissions:userBase'),
              width: 7,
              getData: item => currencyFormat(item.userBase)
            },
            {
              key: 'funding',
              columnType: 'Number',
              name: t('dashboard:components:submissions:funding'),
              width: 6,
              getData: item => currencyFormat(item.funding, true)
            },
            {
              key: 'status',
              columnType: 'String',
              name: t('dashboard:components:submissions:status'),
              width: 6,
              getData: item => capitalize(item.status),
              getColor: item =>
                item.status === InnovationSubmissionStatusEnum.rejected
                  ? colors.danger
                  : item.status === InnovationSubmissionStatusEnum.flagged
                  ? colors.primaryPalette.alert
                  : item.status === InnovationSubmissionStatusEnum.academy
                  ? colors.tints.blues.b200
                  : colors.success
            }
          ]
          tempData = submissions
          break
        case 'Companies':
          tempColumns = [
            {
              key: 'name',
              columnType: 'String',
              name: t('dashboard:components:companies:name'),
              width: 10,
              getData: item => item.name,
              handleClick: (id: string) =>
                linkToScreen('InnovatorDirectoryProfile', {
                  companyId: id
                })
            },
            {
              key: 'yearFounded',
              columnType: 'Number',
              name: t('dashboard:components:companies:yearFounded'),
              width: 5,
              getData: item => item.yearFounded
            },
            {
              key: 'employees',
              columnType: 'String',
              name: t('dashboard:components:companies:employees'),
              width: 8,
              getData: item => getEmployeeRangeValue(item.employeeCount)
            },
            {
              key: 'funding',
              columnType: 'Number',
              name: t('dashboard:components:companies:funding'),
              width: 10,
              getData: item => currencyFormat(item.funding, true)
            }
          ]
          tempData = submissions.map(submission => submission.company)
          break
      }
    }
    setTableData([tempColumns, tempData])
    setLoading(false)
  }, [currentFilter, submissions])

  const filterStyles = {
    fontSize: 10,
    marginHorizontal: 2,
    paddingHorizontal: 7,
    paddingVertical: 3,
    marginBottom: 5,
    borderColor: colors.tints.grays.g100,
    borderWidth: 1,
    borderRadius: 10
  }

  return (
    <Card
      minWidth="62%"
      styles={{ flex: 1 }}
      title={t('dashboard:components:submissions:title')}
    >
      <View
        style={{ height: 'fit-content', flexDirection: 'row', marginBottom: 5 }}
      >
        <TouchableOpacity
          onPress={() => {
            if (currentFilter !== 'Submissions') {
              setLoading(true)
              setCurrentFilter('Submissions')
            }
          }}
        >
          <Text
            style={{
              ...filterStyles,
              backgroundColor:
                currentFilter === 'Submissions'
                  ? colors.tints.grays.g100
                  : undefined
            }}
          >{`Submissions`}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            if (currentFilter !== 'Companies') {
              setLoading(true)
              setCurrentFilter('Companies')
            }
          }}
        >
          <Text
            style={{
              ...filterStyles,
              backgroundColor:
                currentFilter === 'Companies'
                  ? colors.tints.grays.g100
                  : undefined
            }}
          >{`Companies`}</Text>
        </TouchableOpacity>
      </View>
      {loading ? (
        <ActivityIndicator size={'small'} />
      ) : (
        <View
          style={{
            flex: 1
          }}
        >
          <Table columns={tableColumns} data={tableData} />
        </View>
      )}
    </Card>
  )
}

export default Submissions
