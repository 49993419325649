import React, { useState, useRef, useEffect } from 'react'
import Firebase from '../../../Firebase'
import { getAuth as getFBAuth, signInWithEmailAndPassword } from 'firebase/auth'
import TextInput from '../../../ui-library/TextInput'
import { H3, Text } from '../../../components/common/Text'
import Button from '../../../ui-library/Button'
import useTranslation from '../../../hooks/useTranslation'
import useValidateEmailMutation from '../mutations/useValidateEmailMutation'
import {
  AuthenticationErrorContainer,
  ErrorText
} from '../components/SharedStyledComponents'

import useAppCoordinator from '../../../AppCoordinator/useAppCoordinator'
import useLastAttemptedEmail from '../../../hooks/useLastAttemptedEmail'
import useThemeColor from '../../../hooks/useThemeColor'
import { Flex } from '../../../components/FlexBox'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import CallToActionBtns from '../components/sharedComponents/CallToActionBtns'

import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'

const Form = () => {
  const isSmallScreen = useIsSmallScreen()
  const { track } = useMixpanel()
  const { send, state }: any = useAppCoordinator()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const { t } = useTranslation()
  const color = useThemeColor('text')
  const {
    lastAttemptedEmail,
    initLastAttemptedEmail,
    updateLastAttemptedEmail
  } = useLastAttemptedEmail()

  useEffect(() => {
    if (!lastAttemptedEmail) {
      requestAnimationFrame(() => {
        initLastAttemptedEmail(lastAttempted => setEmail(lastAttempted))
      })
    }
  }, [])

  useEffect(() => {
    if (!email && lastAttemptedEmail) {
      setEmail(lastAttemptedEmail)
    }
  }, [lastAttemptedEmail])

  const [validateEmail, loading] = useValidateEmailMutation({
    variables: { email },
    onCompleted: results => {
      const { userExists, isInnovator } = results?.validateEmail
      if (!userExists) {
        return setError(t('error:auth:unknownUsername'))
      }
      if (!isInnovator) {
        return setError(t('error:auth:accountNotAuthorized'))
      }
      if (results?.validateEmail) {
        return login()
      }
    }
  })

  const onEmailChange = email => {
    setEmail(email.trim())
  }

  const login = () => {
    setIsProcessing(true)
    signInWithEmailAndPassword(getFBAuth(Firebase), email, password)
      .then(() => {
        track(EVENT_MAP.auth.login)
        updateLastAttemptedEmail(email)
        send('SUCCESS')
      })
      .catch(err => {
        const errorCode = err.code ?? 'cannotValidateEmail'
        let errorMessage = ''
        switch (errorCode) {
          case 'auth/missing-password':
            errorMessage = t(`error:auth:missingPassword`)
            break
          case 'auth/invalid-login-credentials':
            errorMessage = t(`error:auth:invalidLoginCredentials`)
            break
          default:
            errorMessage = t(`error:auth:${errorCode}`)
            break
        }
        setError(errorMessage)
        setIsProcessing(false)
        // @ts-ignore
        emailRef.current && emailRef.current.focus()
      })
  }

  const onSubmitEmail = () => {
    // @ts-ignore
    passwordRef.current && passwordRef.current.focus()
    updateLastAttemptedEmail(email)
  }

  if (state.value.loginCheck !== 'login') {
    return null
  }

  return (
    <Flex
      flexDirection="column"
      flexBasis={isSmallScreen ? 'auto' : '45%'}
      width={isSmallScreen ? '100%' : '45%'}
      maxWidth={550}
      paddingLeft={isSmallScreen ? 3 : 40}
      paddingRight={isSmallScreen ? 3 : 40}
    >
      <H3
        styles={{ marginBottom: 16, fontWeight: '300' }}
        h3Style={{ fontSize: isSmallScreen ? '29px' : '36px' }}
      >
        {t('auth:login:header')}
      </H3>
      <TextInput
        testID={'loginEmail'}
        value={email}
        onChangeText={onEmailChange}
        autoCapitalize="none"
        label={t('auth:login:email')}
        autoFocus
        ref={emailRef}
        onSubmitEditing={onSubmitEmail}
        style={{ width: '100%' }}
        containerStyles={{ marginBottom: 7 }}
      />
      <TextInput
        testID={'loginPassword'}
        value={password}
        onChangeText={setPassword}
        autoCapitalize="none"
        secureTextEntry
        label={t('auth:forms:password')}
        ref={passwordRef}
        onSubmitEditing={validateEmail}
        style={{ width: '100%' }}
      />
      {!!error && (
        <AuthenticationErrorContainer>
          <ErrorText>{error}</ErrorText>
        </AuthenticationErrorContainer>
      )}
      <CallToActionBtns
        primaryBtn={{
          testID: 'signinBtn',
          title: t('auth:buttons:login'),
          onPress: validateEmail,
          isProcessing: isProcessing || loading
        }}
        secondaryBtn={{
          testID: 'resetBtn',
          title: t('auth:buttons:resetPassword'),
          onPress: () => send('FORGOT_PASSWORD')
        }}
      />
      <Text
        styles={[
          { color },
          {
            fontSize: 14,
            marginTop: isSmallScreen ? 20 : 40,
            marginBottom: isSmallScreen ? 20 : 40,
            textAlign: 'center'
          }
        ]}
      >
        {t('auth:login:or')}
      </Text>
      <Button
        testID={'regBtn'}
        title={t('auth:buttons:register')}
        onPress={() => send('REGISTER')}
        buttonStyle={{ width: '100%' }}
        containerStyle={{ width: '100%' }}
      />
    </Flex>
  )
}

export default Form
