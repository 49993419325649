import React, { useEffect } from 'react'
import Navigation from '../navigation'
import Providers from './providers'
import { Platform } from 'react-native'

const AppRoot = () => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      if (window.navigator.userAgent.includes('Windows')) {
        // @ts-ignore
        import('../utils/windowsFixes.css')
      }
    }
  }, [])

  return (
    <Providers>
      <Navigation />
    </Providers>
  )
}

export default AppRoot
