export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const currencyFormat = (value: string, isCurrency?: boolean): string => {
  const amount = parseFloat(value) ?? 0
  return amount
    ? new Intl.NumberFormat('en-US', {
        style: isCurrency ? 'currency' : undefined,
        currency: isCurrency ? 'USD' : undefined,
        maximumFractionDigits: 0
      }).format(amount)
    : '-'
}

export const sortByCountAndGetTopElements = (
  array: any[],
  sortKey: string,
  labelKey: string,
  sortDirection: 'asc' | 'desc',
  topElements?: number,
  addOtherElement?: boolean,
  addOtherElementToFirstElement?: boolean
): any[] => {
  const arrayToOrder = [...array]
  const ordered = arrayToOrder.sort((a, b) =>
    sortDirection === 'asc' ? b[sortKey] - a[sortKey] : a[sortKey] - b[sortKey]
  )
  if (topElements != null && topElements < ordered.length) {
    if (addOtherElement) {
      const otherElement = ordered.reduce(
        (acc, item, index) => acc + (index < topElements ? 0 : item[sortKey]),
        0
      )
      let result = ordered.filter((_, index) => index < topElements)
      const otherElementValue = { [labelKey]: 'Other', [sortKey]: otherElement }
      if (addOtherElementToFirstElement) {
        result = [otherElementValue, ...result]
      } else {
        result.push(otherElementValue)
      }
      return result
    } else {
      return ordered.slice(0, topElements)
    }
  } else {
    return ordered
  }
}

// Function to calculate the Euclidean distance between two points
const euclideanDistance = (point1: number, point2: number) => {
  return Math.abs(point1 - point2)
}

// Function to find the index of the closest centroid to a point
const closestCentroidIndex = (point: number, centroids: number[]): number => {
  let minDistance = Infinity
  let closestIndex = -1

  centroids.forEach((centroid, index) => {
    const distance = euclideanDistance(point, centroid)
    if (distance < minDistance) {
      minDistance = distance
      closestIndex = index
    }
  })

  return closestIndex
}

// Function to update the centroids based on the points assigned to each cluster
const updateCentroids = (clusters: number[][], points: number[]): number[] => {
  const centroids: number[] = []

  clusters.forEach(cluster => {
    const clusterPoints = cluster.map(index => points[index])
    const centroid = clusterPoints.reduce((acc, point) => {
      return acc + point
    }, 0)

    centroids.push(centroid / clusterPoints.length)
  })

  return centroids
}

// Function to perform K-means and get the clustering
const getClusters = (points: number[], k: number): number[][] => {
  // Initialize centroids randomly
  let centroids: number[] = []
  for (let i = 0; i < k; i++) {
    centroids.push(points[Math.floor(Math.random() * points.length)])
  }

  let prevClusters: number[][] | null = null
  let clusters: number[][] = []

  // Repeat until convergence
  while (true) {
    // Assign points to the closest centroid
    clusters = new Array(k).fill([]).map(() => [])

    points.forEach((point, index) => {
      const closestIndex = closestCentroidIndex(point, centroids)
      clusters[closestIndex].push(index)
    })

    // Update centroids
    const newCentroids = updateCentroids(clusters, points)

    // Check for convergence
    if (
      JSON.stringify(newCentroids) === JSON.stringify(centroids) ||
      JSON.stringify(clusters) === JSON.stringify(prevClusters)
    ) {
      break
    }

    prevClusters = clusters
    centroids = newCentroids
  }

  return clusters
}

export const groupAndCreateCategories = (
  array: any[],
  categoryNumber: number = 10
): any[] => {
  let result: { name: string; count: number }[] = []

  // Sort the data to identify potential outliers
  const sortedData = [...array.map(value => parseFloat(value.name))].sort(
    (a, b) => a - b
  )
  const minValue = Math.min(...sortedData) - 1
  const maxValue = Math.max(...sortedData) + 1
  const clusters = getClusters(sortedData, categoryNumber)
  const sortedClusters = clusters.sort((a, b) => a[0] - b[0])

  for (
    let indexCluster = 0;
    indexCluster < sortedClusters.length;
    indexCluster++
  ) {
    const cluster = sortedClusters[indexCluster] ?? []
    if (cluster && cluster.length) {
      let clusterMinValue =
        indexCluster === 0 ? minValue : sortedData[cluster[0]]
      let clusterMaxValue =
        indexCluster === sortedClusters.length - 1
          ? maxValue
          : sortedData[cluster[cluster.length - 1]]
      const count = sortedData.filter(
        sd => sd >= clusterMinValue && sd < clusterMaxValue
      ).length
      const toValue = clusterMaxValue
      const clusterValue = {
        name: `${toValue}`,
        count
      }
      result.push(clusterValue)
    }
  }

  return result
}
