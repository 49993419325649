import { View } from 'react-native'
import styled from 'styled-components/native'
// Components
import Skeleton from './Skeleton'

export const RowSkeleton = styled(Skeleton as any)(
  ({
    theme: { radii, sizes },
    height,
    width,
    marginBottom,
    marginTop,
    marginRight,
    alignSelf,
    borderRadius
  }) => ({
    borderRadius: borderRadius || radii[4],
    height: height || sizes[3] - 6,
    width: width || sizes[5],
    marginBottom: marginBottom || 'auto',
    marginTop: marginTop || 'auto',
    marginRight: marginRight || 'auto',
    alignSelf: alignSelf || 'auto'
  })
)

export const SkeletonDivider = styled(RowSkeleton)`
  ${({ theme: { sizes }, marginBottom, marginTop }) => `
      width: 100%;
      height: ${sizes[1] - 3}px;
      marginBottom: ${marginBottom || 0}px;
      marginTop:  ${marginTop ? `${marginTop}px` : 'auto'};
  `}
`

export const SkeletonTitle = styled(RowSkeleton)`
  ${({ theme: { sizes } }) => `
    margin-bottom: ${sizes[2]}px;
  `}
`

export const Row = styled.View`
  width: 100%;
  flex-direction: row;
  ${({
    justifyContent,
    alignItems,
    marginBottom,
    marginTop,
    paddingLeft,
    paddingRight,
    paddingTop,
    backgroundColor
  }) => `
    align-items: ${alignItems || 'flex-start'};
    justify-content: ${justifyContent || 'space-between'};
    margin-bottom: ${marginBottom || 'auto'}px;
    margin-top: ${marginTop || 'auto'}px;
    padding-left: ${paddingLeft ? `${paddingLeft}px` : 'auto'};
    padding-top: ${paddingTop ? `${paddingTop}px` : 'auto'};
    padding-right: ${paddingRight || 'auto'}px;
    background-color: ${backgroundColor || 'transparent'};
  `}
`

export const Col = styled.View`
  ${({ width, alignItems, flexDirection, marginTop = 0, height }) => `
    width: ${width || 'auto'};
    display: flex;
    flex-direction: ${flexDirection || 'column'};
    align-items: ${alignItems || 'flex-start'};
    margin-top: ${marginTop}px;
    height: ${`${height}px` || 'auto'};
  `}
`

export const HeroSkeleton = styled(Skeleton as any)`
  ${({ theme: { sizes, colors, containerRadius }, isFullScreenWeb }) => `
    height: ${sizes[6] + sizes[4]}px;
    background-color: ${colors.skeletonGray1};
    ${
      isFullScreenWeb
        ? `border-top-right-radius: ${containerRadius}px; border-top-left-radius: ${containerRadius}px;`
        : ''
    }
  `}
  width: 100%;
`

export const TableInputText = styled(View)(
  ({
    theme: { radii, sizes, colors },
    height,
    width,
    marginBottom,
    marginRight,
    alignSelf,
    borderRadius,
    backgroundColor
  }) => ({
    borderRadius: borderRadius || radii[4],
    height: height || sizes[3] - 6,
    width: width || sizes[5],
    marginBottom: marginBottom || 'auto',
    marginRight: marginRight || 'auto',
    alignSelf: alignSelf || 'auto',
    backgroundColor: backgroundColor || colors.background
  })
)

export const TableSkeletonRow = styled(Skeleton as any)`
  ${({
    theme: { space },
    height,
    justifyContent,
    borderRadius,
    padding,
    paddingTop,
    paddingBottom
  }) => `
    flex-direction: row;
    display: flex;
    height: ${height || space[4] + 2}px;
    justify-content: ${justifyContent || 'flex-start'};
    border-radius: ${borderRadius || space[4] / 2}px;
    padding: ${padding || space[4] - 7}px;
    padding-top: ${paddingTop || space[3] - 1}px;
    padding-bottom: ${paddingBottom || space[3] - 1}px;
  `}
`

export const TextInputSkeleton = styled(RowSkeleton)(
  ({
    theme: { radii, sizes, colors },
    borderRadius,
    borderColor,
    backgroundColor,
    borderWidth
  }) => ({
    borderRadius: borderRadius || radii[4],
    borderColor: borderColor || colors.lightGrey,
    borderWidth: borderWidth || sizes[1] - 3,
    backgroundColor: backgroundColor || colors.background
  })
)

export const EmptySkeleton = styled(Skeleton as any)`
  width: 100%;
  ${({ theme: { sizes } }) => `
    height: ${sizes[2]}px;
  `}
`
