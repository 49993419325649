import gql from 'graphql-tag'

const upsertAnswerFileAttachmentMapMutation = gql`
  mutation upsertAnswerFileAttachmentMap(
    $id: String
    $name: String
    $answerId: String
    $questionId: String
    $formSubmissionId: String
    $fileAttachmentId: String
  ) {
    upsertAnswerFileAttachmentMap(
      id: $id
      name: $name
      answerId: $answerId
      questionId: $questionId
      formSubmissionId: $formSubmissionId
      fileAttachmentId: $fileAttachmentId
    ) {
      id
    }
  }
`

export default upsertAnswerFileAttachmentMapMutation
