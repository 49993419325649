import React from 'react'

import { useTheme } from 'styled-components/native'
// Types
import {
  TableConfig,
  CellRendererProps,
  ColumnDefinition
} from '../../../../../components/Table'
import { TableNameEnum } from '../../../../../components/DynamicTable/types'
// Hooks
import useTranslation from '../../../../../hooks/useTranslation'
// Components
import Theme from '../../../../../constants/Theme'
import { Flex } from '../../../../../components/FlexBox'
import TouchableIcon, {
  TouchableIconProps
} from '../../../../../components/icon/TouchableIcon'
import { SmallRow } from '../../../../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../../../../components/Table/components/DynamicTable/SharedStyledComponents'
import { QuestionGroupEntityMapValues } from '../../types'
import { Content } from '../../../SharedStyledComponents'
import { EntityTypeEnum } from '../../../../../components/DynamicForm/types'
// Types

// Styles
const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[2]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'center',
  textAlignVertical: 'center',
  width: '100%'
}
const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}
const cellTextStyle = { textAlign: 'center' }

// Columns
const questionSequenceColumn: ColumnDefinition<any> = {
  id: 'questionSequenceColumn',
  header: 'platformManagement:forms:menuItems:formBlockQuestions:sequence',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  fixed: true,
  width: 10,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${item?.sequence ?? 0}`}
        style={cellTextStyle}
      />
    )
  }
}

const questionTypeColumn: ColumnDefinition<any> = {
  id: 'questionTypeColumn',
  header: 'platformManagement:forms:menuItems:formBlockQuestions:type',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  fixed: true,
  width: 20,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${item?.entityType}`}
        style={cellTextStyle}
      />
    )
  }
}

const questionGroupTitleColumn: ColumnDefinition<any> = {
  id: 'questionGroupTitleColumn',
  header: 'platformManagement:forms:menuItems:formBlockQuestions:groupTitle',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 25,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    let title = ``

    if (item.entityType === EntityTypeEnum.Question) {
      title = `${item.entity.questionText}`
    } else {
      const configData = JSON.parse(
        JSON.stringify(item?.entity?.configData) ?? '{}'
      )
      title =
        configData?.title ||
        configData?.blockLabel ||
        configData?.questionGroupTitle ||
        ''
    }

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={title}
        style={cellTextStyle}
      />
    )
  }
}

const questionGroupTypeDynamicColumn: ColumnDefinition<any> = {
  id: 'questionGroupTypeDynamicColumn',
  header:
    'platformManagement:forms:menuItems:formBlockQuestions:groupTypeDynamic',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 10,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    let title: string = ``
    if (item.entityType === EntityTypeEnum.Question) {
      title = `${item?.entity?.type}` || ''
    } else {
      title = `${item?.entity?.type} - ${item?.entity?.dynamic}` || ''
    }

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={title}
        style={cellTextStyle}
      />
    )
  }
}

const questionEditColumn = ({ handleEditQuestion }) => ({
  id: 'questionEditColumn',
  header: 'platformManagement:forms:menuItems:formBlockQuestions:edit',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 10,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors, sizes } = useTheme()
    const commonProps: TouchableIconProps = {
      name: 'pencil',
      width: 22,
      height: 22,
      color: colors.darkGradientBackground,
      style: { alignSelf: 'center', marginHorizontal: sizes[1] }
    }

    return (
      <Flex flexDirection="row" justifyContent="center">
        <TouchableIcon
          {...commonProps}
          onPress={() => {
            handleEditQuestion(item)
          }}
        />
      </Flex>
    )
  }
})

export const QUESTIONS_MNG_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: TableNameEnum.QuestionGroupsManagementTable,
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

interface getQuestionsManagementTableArgs {
  handleEditQuestion: (question: QuestionGroupEntityMapValues) => void
}

export const getQuestionGroupEntityMapsTableConfig = ({
  handleEditQuestion
}: getQuestionsManagementTableArgs) => {
  const table = Object.assign(
    {},
    QUESTIONS_MNG_TABLE_CONFIG
  ) as TableConfig<any>

  let columns = [
    questionSequenceColumn,
    questionTypeColumn,
    questionGroupTitleColumn,
    questionGroupTypeDynamicColumn,
    questionEditColumn({
      handleEditQuestion
    })
  ]
  table.columns = columns
  table.showTotalElements = true

  return table
}
