import React from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import {
  Row,
  RowSkeleton
} from '../../../../components/skeletonLoadings/components'
import TableSkeleton from '../../../HomeScreen/TableSkeleton'

const Container = styled(View)`
  ${({ theme: { space } }) => `
    padding: ${space[5]}px;
    margin-bottom: ${space[0]}px;
  `}
`
const SubmissionSelectionSkeleton = () => {
  const theme = useTheme()
  return (
    <Container>
      <Row justifyContent="flex-start" marginTop={30} marginBottom={10}>
        <RowSkeleton marginRight={110} width="5%" height={theme.sizes[2] - 2} />
      </Row>
      <Row justifyContent="flex-start" marginBottom={20}>
        <RowSkeleton
          marginRight={15}
          width="14%"
          height={theme.sizes[4] + theme.sizes[2]}
          borderRadius={10}
        />
        <RowSkeleton
          marginRight={15}
          width="14%"
          height={theme.sizes[4] + theme.sizes[2]}
          borderRadius={10}
        />
        <RowSkeleton
          width="10%"
          height={theme.sizes[4] + theme.sizes[2]}
          borderRadius={10}
        />
      </Row>
      <TableSkeleton />
    </Container>
  )
}

export default SubmissionSelectionSkeleton
