import { useRecoilState } from 'recoil'
import currentUserAtom from '../recoil/currentUserAtom'
import { Company, CurrentUser as CurrentUserType, RoleEnum } from '../types'

export interface CurrentUserHook {
  firebaseInitialized: boolean
  firebaseToken: string | null
  firebaseEmail: string | null
  currentUser: CurrentUserType | null
  currentUserId: String | undefined
  currentPersonId: String | undefined
  isAdmin: boolean
  isAssociate: boolean
  isInnovatorTeamAdmin: boolean
  isInnovationStaff: boolean
  isInnovationAdmin: boolean
  roles: RoleEnum[]
  innovationCompany?: Company
  setCurrentUser: (val: CurrentUserType | null) => void
  setCompany: (company: Company | null | undefined) => void
  setFirebase: (
    token: string | null | undefined,
    email: string | null | undefined
  ) => void
  isCurrentPerson: (id: String | undefined) => boolean
  isCurrentUser: (id: String | undefined) => boolean
}

const useCurrentUser = (): CurrentUserHook => {
  const [currentUserState, setCurrentUserState] = useRecoilState(
    currentUserAtom
  )

  const currentUser = currentUserState?.user

  const isCurrentPerson = id => {
    const currentPersonId = currentUser?.person?.id
    return !!id && !!currentPersonId && id === currentPersonId
  }

  const isCurrentUser = id => {
    const currentUserId = currentUser?.id
    return !!id && !!currentUserId && id === currentUserId
  }

  const setCurrentUser = val => {
    let newVal = val || null
    setCurrentUserState({ ...currentUserState, user: newVal })
  }

  const setFirebase = (token, email) => {
    if (!token) {
      setCurrentUserState({
        firebaseInitialized: true,
        firebaseToken: null,
        firebaseEmail: null,
        user: null
      })
    } else {
      setCurrentUserState({
        ...currentUserState,
        firebaseInitialized: true,
        firebaseToken: token,
        firebaseEmail: email
      })
    }
  }

  const setCompany = company => {
    if (company) {
      const { user } = currentUserState
      setCurrentUserState({
        ...currentUserState,
        user: {
          ...user,
          person: {
            ...user.person,
            innovationCompany: company,
            companies: [...user.person?.companies, company]
          }
        }
      })
    }
  }

  const hasRole = roleToCheck =>
    currentUser?.roles?.includes(roleToCheck) || false

  return {
    firebaseInitialized: currentUserState?.firebaseInitialized,
    firebaseToken: currentUserState?.firebaseToken,
    firebaseEmail: currentUserState.firebaseEmail,
    currentUser,
    currentUserId: currentUser?.id,
    currentPersonId: currentUser?.person?.id,
    isAdmin: hasRole(RoleEnum.Admin),
    isAssociate: hasRole(RoleEnum.Associate),
    isInnovatorTeamAdmin: hasRole(RoleEnum.InnovatorTeamAdmin),
    isInnovationStaff: hasRole(RoleEnum.InnovationStaff),
    isInnovationAdmin: hasRole(RoleEnum.InnovationAdmin),
    roles: currentUser?.roles || [],
    innovationCompany: currentUser?.person?.innovationCompany,
    setCurrentUser,
    setFirebase,
    setCompany,
    isCurrentPerson,
    isCurrentUser
  }
}

export default useCurrentUser
