import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { InnovationCategory } from '../../../types'

const categoriesByMarketSegmentQuery = gql`
  query innovationCategoriesByMarketSegment($marketSegmentId: String!) {
    innovationCategoriesByMarketSegment(marketSegmentId: $marketSegmentId) {
      id
      name
      sort
    }
  }
`

interface InnovationCategoriesObject {
  innovationCategories: InnovationCategory[]
  loading: boolean
}

const useCategoriesByMarketSegmentQuery = ({
  marketSegmentId
}): InnovationCategoriesObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(categoriesByMarketSegmentQuery, {
    variables: {
      marketSegmentId
    },
    skip: !marketSegmentId,
    errorMessage: t('error:general:categories:error')
  })

  return {
    innovationCategories: data?.innovationCategoriesByMarketSegment,
    loading
  }
}

export default useCategoriesByMarketSegmentQuery
