import { InnovationAccess } from '../types'
import useCurrentUser from './useCurrentUser'

const useInnovationAccess = () => {
  const { currentUser } = useCurrentUser()

  const innovationAccess: InnovationAccess | {} =
    currentUser?.innovationAccess || {}
  return {
    ...innovationAccess,
    accessReady: !!currentUser
  }
}

export default useInnovationAccess
