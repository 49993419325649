import React from 'react'
import styled from 'styled-components/native'
// Components
import { SkeletonTemplateProps } from '../../components/skeletonLoadings/types'
import Skeleton from '../../components/skeletonLoadings/Skeleton'
import {
  Col,
  Row,
  RowSkeleton
} from '../../components/skeletonLoadings/components'
import ArrowRightIcon from '../../components/icon/ArrowRightIcon'

// Style
const RowContainer = styled.View`
  ${({ theme: { space, containerRadius } }) => `
    margin-bottom: ${space[2]}px;
    border-radius: ${containerRadius}px;
  `}
`
const Container = styled(RowContainer)`
  ${({ theme: { space }, padding }) => `
    padding: ${padding || '0px'}
    margin-bottom: ${space[2]}px;
    border-radius: 0px;
  `}
`

interface Props extends SkeletonTemplateProps {
  numberOfRows?: number
}

const CallForSubmissionsInnovatorSkeleton = React.memo((props: Props) => {
  const { numberOfRows = 4 } = props
  return (
    <Container marginBottom="0" padding="0">
      <Col marginTop={0}>
        <TableRow isHeader {...props} />
        {Array.from(Array(numberOfRows), (_, index) => (
          <TableRow key={`key_tableRow_${index}`} index={index} {...props} />
        ))}
      </Col>
    </Container>
  )
})

const TableRowBG = styled(RowSkeleton)`
  width: 100%;
  justify-content: center;
  ${({ theme: { sizes } }) => ` 
    height: ${sizes[4] + sizes[2]}px;
  `}
`

const TableRowTextContainer = styled.View`
  width: 20%;
  height: 100%;
  justify-content: center;
  padding-horizontal: ${({ theme }) => theme.sizes[2]}px;
`

const TableRowText = styled(RowSkeleton)`
  margin-bottom: 0px;
  ${({ width, theme: { sizes } }) => ` 
    height: ${sizes[2]}px;
    width: ${width || '50%'};
  `}
`

const IconBG = styled(Skeleton).attrs(({ theme: { colors } }) => ({
  colorLight: colors.skeletonIcon
}))`
  align-items: center;
  justify-content: center;
  ${({ theme: { sizes } }) => {
    const size = sizes[4] - sizes[1]
    return ` 
      height: ${size}px;
      width: ${size}px;
      border-radius: ${size / 2}px;
    `
  }}
`

interface TableRowProps extends Props {
  index?: number
  isHeader?: boolean
}

const TableRow = React.memo(
  ({ index, isHeader, theme: { colors } }: TableRowProps) => {
    const isEven: boolean = !!index && (index + 1) % 2 === 0
    const inputText = (width?: string | number) => (
      <TableRowTextContainer>
        <TableRowText
          width={width}
          colorLight={isHeader || isEven ? colors.lightGrey : colors.background}
        />
      </TableRowTextContainer>
    )
    return (
      <TableRowBG
        marginBottom={0}
        colorLight={isHeader || isEven ? colors.background : colors.lightGrey}
      >
        <Row justifyContent="flex-start" alignItems="center" height="100%">
          {inputText(isHeader ? '40%' : '50%')}
          {inputText(isHeader ? '80%' : '50%')}
          {inputText(isHeader ? '80%' : '25%')}
          {inputText(isHeader ? '25%' : '40%')}
          <TableRowTextContainer>
            {!isHeader && (
              <IconBG>
                <ArrowRightIcon />
              </IconBG>
            )}
          </TableRowTextContainer>
        </Row>
      </TableRowBG>
    )
  }
)

export default CallForSubmissionsInnovatorSkeleton
