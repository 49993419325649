import gql from 'graphql-tag'
import useMutation from '../../../hooks/useMutation'
import { submissionQuery } from './useSubmissionQuery'

export const createInnovationSubmissionPdfMutation = gql`
  mutation createInnovationSubmissionPdf($id: String!) {
    createInnovationSubmissionPdf(id: $id)
  }
`

const useCreateSubmissionPdfMutation = () => {
  const [createSubmissionPDFMutation, { loading, error }] = useMutation(
    createInnovationSubmissionPdfMutation
  )

  const createSubmissionPDF = async (submissionId, refetchQueries = true) => {
    const { data } = await createSubmissionPDFMutation({
      variables: {
        id: submissionId
      },
      refetchQueries: refetchQueries
        ? [
            {
              query: submissionQuery,
              variables: {
                id: submissionId,
                withSubmissionReviewsAverages: true
              }
            }
          ]
        : []
    })

    return data
  }

  return {
    createSubmissionPDF,
    loading,
    error
  }
}

export default useCreateSubmissionPdfMutation
