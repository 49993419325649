import React, { useCallback, useEffect } from 'react'

import { useField, useFormikContext } from 'formik'

import DateInput from './DateInput'

// TODO: This is functional for our purposes, but it really needs some work

export interface FormikDateInputProps {
  name?: string
  label?: string
  maximumDate?: Date
  minimumDate?: Date
  placeholder?: string
  initialDate?: Date
  onBlur?: (e: any) => void
  containerStyles?: any
  isReadOnly?: boolean
  initialValue?: string | undefined
}

export default function FormikDateInput(props: FormikDateInputProps) {
  const {
    name,
    label,
    maximumDate,
    minimumDate,
    initialDate = new Date(),
    onBlur,
    containerStyles = {},
    isReadOnly = false,
    initialValue
  } = props

  const [field, meta] = useField({ name, type: 'date' })
  const { setFieldValue } = useFormikContext()
  const value = field.value
  const hasError = !!meta.error && !!meta.touched

  const handleChange = useCallback(
    value => {
      setFieldValue(name, value)
    },
    [name]
  )

  useEffect(() => {
    setFieldValue(name, initialValue)
  }, [initialValue])

  return (
    <DateInput
      name={name}
      meta={meta}
      value={!value ? '' : value}
      label={label}
      initialDate={initialDate}
      maximumDate={maximumDate}
      minimumDate={minimumDate}
      containerStyles={containerStyles}
      hasError={hasError}
      isReadOnly={isReadOnly}
      onBlur={onBlur}
      handleChange={handleChange}
    />
  )
}
