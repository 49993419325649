import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
// Hooks
import useAdvisorQuery from '../../../hooks/useAdvisorQuery'
import useTranslation from '../../../../../hooks/useTranslation'
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'
// Components
import Dropdown, {
  DropdownOption
} from '../../../../../components/common/Dropdown'
import Button from '../../../../../ui-library/Button'
import { useTable } from '../../../../../components/Table'
import { DynamicTable } from '../../../../../components/DynamicTable'
import { TableContainer } from '../../../../../components/StaticForm'
import { getAdvisorsProgressTableConfig } from '../../../../Curation/constants'
import {
  Container,
  SettingsContainer
} from '../../../components/SharedComponents'
import { NEW_DYNAMIC_TABLE_FF } from '../../../../../components/DynamicTable/constants'
import AdvisingDialog from './AdvisingDialog'
import { StyledLabel } from '../../../../../ui-library/TextInput/Label'
import { Flex } from '../../../../../components/FlexBox'
// Types
import { EngineEnum } from '../../../constants'
import { CallForSubmission } from '../../../../../types'

interface AdvisingTestSettings {
  callForSubmission: CallForSubmission
}

const AdvisingTestSettings = (props: AdvisingTestSettings) => {
  const { t } = useTranslation()
  const { callForSubmission } = props
  const [selectedElements, setSelectedElements] = useState<string[]>([])
  const [openDialog, setOpenDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const advisorsOptions: DropdownOption[] =
    callForSubmission.advisorsUsers?.map(user => ({
      label: `${user.person.firstName} ${user.person.lastName}`,
      value: user.id
    })) ?? []

  const [advisorId, setAdvisorId] = useState<string>(
    advisorsOptions?.[0]?.value
  )
  const [favoriteQuestionId, setFavoriteQuestionId] = useState('')
  const [academyQuestionId, setAcademyQuestionId] = useState('')
  const { advisorInfo, loading: loadingAdvisor } = useAdvisorQuery(
    callForSubmission.id,
    advisorId
  )

  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)

  const onSelectedItemsKeysChange = (keys: string[]) => {
    setSelectedElements(keys)
  }

  const closeDialog = useCallback(() => {
    setIsSubmitting(false)
    setOpenDialog(false)
  }, [])

  const tableProps = useTable({
    config: getAdvisorsProgressTableConfig({
      cfsId: callForSubmission.id,
      engine: EngineEnum.InnovationSubmission,
      advisorId,
      favoriteQuestionId,
      submissionIdsToReview: (advisorInfo?.submissionsToReview ?? []).map(
        sr => sr.submissionId
      ),
      academyQuestionId,
      isInnovationNewDynamicTable
    }) as any
  })

  useEffect(() => {
    if (callForSubmission) {
      setFavoriteQuestionId(
        callForSubmission.reviewFormQuestions?.find(rfq =>
          rfq.questionText.toLowerCase().includes('favorite')
        )?.id || ''
      )
      setAcademyQuestionId(
        callForSubmission.reviewFormQuestions?.find(rfq =>
          rfq.questionText.toLowerCase().includes('academy')
        )?.id || ''
      )
    }
  }, [callForSubmission])

  return (
    <SettingsContainer>
      <Container
        padding={`0`}
        style={{
          paddingBottom: 30
        }}
      >
        <Flex flexDirection="row" alignItems="center" marginBottom={16}>
          <StyledLabel>{t('common:table:filterBy')}</StyledLabel>
          <Dropdown
            name={t('curation:advisors:table:cfsStatus')}
            value={advisorId}
            placeholder={t('curation:advisors:table:cfsStatus')}
            options={advisorsOptions}
            onSelect={setAdvisorId}
            isFilter
            style={{ marginBottom: 0, marginLeft: 32, minHeight: 0 }}
            controlStyle={{ minHeight: 0 }}
            menuPortalTarget={document?.body}
          />
        </Flex>
        <TableContainer height="100%">
          <DynamicTable
            {...(tableProps as any)}
            onSelectedItemsKeysChange={onSelectedItemsKeysChange}
            emptyMessage={t('innovatorDirectory:table:noInnovators')}
          />
        </TableContainer>
      </Container>
      <View style={{ height: '5%' }}>
        <Button
          title={t('settings:testing:updateSubmissions')}
          onPress={() => setOpenDialog(!openDialog)}
          disabled={
            !selectedElements ||
            selectedElements.length < 1 ||
            isSubmitting ||
            loadingAdvisor
          }
          isProcessing={isSubmitting || loadingAdvisor}
        />
      </View>
      <AdvisingDialog
        openDialog={openDialog}
        selectedElements={selectedElements}
        triggerClose={closeDialog}
        setIsSubmitting={setIsSubmitting}
        setSelectedElements={setSelectedElements}
        advisorId={advisorId}
      />
    </SettingsContainer>
  )
}

export default AdvisingTestSettings
