import React, { useContext, useMemo, useEffect, useState } from 'react'
import { View, ViewStyle } from 'react-native'
import Select, { components as RScomponents } from 'react-select'
import styled, { ThemeContext, useTheme } from 'styled-components/native'
import { path } from 'ramda'
import { Flex } from '../FlexBox'

import { Text } from './Text'
import { lighten } from 'polished'
import useTranslation from '../../hooks/useTranslation'
import { propsToFilter } from '../../utils/NonReactNativeProp'
import { Content } from '../../ui-library/common/PrintableStyledComponents'

export type DropdownValue = string | string[]

export const DropdownTitle = styled(Text)
  .withConfig({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !propsToFilter.includes(prop)
  })
  .attrs(props => ({
    styles: {
      fontSize: props.theme.fontSizes[4],
      color: props.isDisabled
        ? props.theme.colors.disabled
        : props.theme.colors.inputText
    }
  }))``

export const DropdownSubtitle = styled(Text).attrs(props => ({
  styles: {
    fontSize: props.theme.fontSizes[3],
    color: lighten(0.25, props.theme.colors.dropDownTextColor)
  }
}))``

const StyledLabel = styled(Text)`
  ${({ theme, styles }) => `
    color: ${styles?.color ? styles.color : theme.colors.inputLabels};
    margin-left: ${styles?.marginLeft ? styles?.marginLeft : 7}px;
    margin-bottom: 3px;
    position: relative;
  `}
`
const GroupLabel = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.cta.secondary}
    font-weight: ${theme.fontWeights.bold}
  `}
`

const MaxLimitText = styled(Text).attrs(
  ({ error, theme: { colors, fontSizes, space } }) => ({
    styles: {
      fontSize: fontSizes[3],
      color: error ? colors.danger : colors.primary,
      marginLeft: space[2]
    }
  })
)``

interface DefaultOptionProps {
  label: String
}

export const DefaultOptionLabel = ({ isDisabled, testID }) => {
  const { colors } = useTheme()

  return ({ label }: DefaultOptionProps) => (
    <Flex testID={`dropdownOption-${testID}-${label}`} flexDirection="column">
      <DropdownTitle
        numberOfLines={1}
        ellipsizeMode="tail"
        isDisabled={isDisabled}
        style={{
          color: isDisabled ? colors.disabled : colors.inputText
        }}
      >
        {label}
      </DropdownTitle>
    </Flex>
  )
}

export const LeftIconInput = (props: any) => {
  return (
    <>
      <Flex flexDirection="row" style={{ marginLeft: 8 }}>
        {props.selectProps.leftIcon}
      </Flex>
      <RScomponents.ValueContainer {...props}>
        {props.children}
      </RScomponents.ValueContainer>
    </>
  )
}

export const MultiValueWithLimitControl = ({ selectProps, ...rest }: any) => {
  const { t } = useTranslation()
  const { maxAmountError, maxAmount } = selectProps
  return (
    <>
      <RScomponents.Control {...rest} />
      <MaxLimitText error={maxAmountError}>
        {t('validation:error:maxAmount', { maxAmount: maxAmount })}
      </MaxLimitText>
    </>
  )
}

export const formatGroupLabel = data => (
  <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
    <GroupLabel>{data.label}</GroupLabel>
  </Flex>
)

const ViewContainer = styled(View)`
  width: inherit;
`
export default function Dropdown({
  options,
  name,
  label,
  value,
  onSelect,
  onChange,
  placeholder,
  isMulti = false,
  style = {},
  closeMenuOnSelect = true,
  onBlur,
  hasError,
  dropdownIcon,
  leftIcon,
  formatOptionLabel = null,
  onMenuScrollToBottom,
  onInputChange,
  isClearable,
  maxAmount,
  isDisabled,
  noOptionsMessage,
  filterOption,
  isReadOnly = false,
  isLoading = false,
  asPrintable = false,
  isFilter = false,
  isGroupedOptions = false,
  containerStyle = {},
  controlStyle = {},
  menuIsOpen = null,
  menuPortalTarget = null,
  testID,
  ...restProps
}: DropdownProps) {
  const theme: any = useContext(ThemeContext)
  const [open, setOpen] = useState<boolean>(false)
  const styles = getStyles(theme, style, controlStyle, hasError)
  const [selectorOptions, setSelectorOptions] = useState<
    DropdownOption[] | GroupedOption[]
  >([])

  const components = {
    IndicatorSeparator: false
  } as any

  if (dropdownIcon) {
    components.DropdownIndicator = props => (
      <RScomponents.DropdownIndicator {...props}>
        {dropdownIcon}
      </RScomponents.DropdownIndicator>
    )
  }
  if (isDisabled) {
    components.DropdownIndicator = () => null
  }

  if (leftIcon) {
    components.ValueContainer = LeftIconInput
  }

  const maxAmountError = isMulti && maxAmount && value.length > maxAmount
  if (isMulti && maxAmount) {
    components.Control = MultiValueWithLimitControl
  }

  // react-select wants an object as a value
  const currentSelection = useMemo(() => {
    if (selectorOptions?.length) {
      if (isMulti) {
        return ((value as string[]) || []).map(v =>
          selectorOptions.find(option => option.value === v)
        )
      } else if (isGroupedOptions) {
        return options
          .map(o => o.options)
          .flat()
          .find(option => option.value === value)
      }
      return selectorOptions.find(option => option.value === value)
    }
    return null
  }, [value, isMulti, selectorOptions, isGroupedOptions])

  const handleChange = selected => {
    if (isMulti) {
      selected = selected && selected.length ? selected : []
      if (!maxAmount || maxAmount >= selected.length) {
        const value = selected.map(s => s.value)

        onSelect && onSelect(value)
        onChange && onChange(name, value)
      }
    } else {
      onSelect && onSelect(path(['value'], selected))
      onChange && onChange(name, selected.value)
    }
  }

  const openMenu = () => setOpen(true)

  const closeMenu = () => setOpen(false)

  useEffect(() => {
    if (options?.length) {
      setSelectorOptions(options)
    }
  }, [options])

  let selectProps: any = {
    options,
    name,
    closeMenuOnSelect,
    placeholder,
    isMulti,
    isFilter,
    styles,
    value: currentSelection || '',
    onChange: handleChange,
    onBlur,
    components,
    isLoading,
    formatOptionLabel:
      formatOptionLabel || DefaultOptionLabel({ isDisabled, testID }),
    onMenuScrollToBottom,
    onInputChange,
    onMenuOpen: restProps?.onMenuOpen || openMenu,
    onMenuClose: restProps?.onMenuClose || closeMenu,
    isClearable,
    isDisabled,
    noOptionsMessage: () => noOptionsMessage,
    leftIcon,
    closeMenuOnScroll: true,
    filterOption,
    maxAmount,
    maxAmountError,
    formatGroupLabel,
    menuPortalTarget,
    menuIsOpen:
      restProps?.onMenuOpen && restProps?.onMenuClose && menuIsOpen !== null
        ? menuIsOpen
        : open
  }

  const currentSelectedOption =
    (asPrintable || isReadOnly) && value
      ? options.find(op => op.value === value)
      : null

  const isOpen: boolean = menuIsOpen || open

  const viewContainerStyle: ViewStyle = {
    ...containerStyle,
    zIndex: isOpen ? 9999999 : 0
  }

  return (
    <ViewContainer testID={testID} style={viewContainerStyle}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {asPrintable || isReadOnly ? (
        <View
          style={{
            paddingLeft: theme.space[2]
          }}
        >
          <Content>{currentSelectedOption?.label}</Content>
        </View>
      ) : (
        <Select {...selectProps} />
      )}
    </ViewContainer>
  )
}

export interface DropdownOption {
  label: string
  value: any
  custom?: Object
}
export interface GroupedOption {
  label: string
  options: DropdownOption[]
}

export interface DropdownProps {
  options: DropdownOption[] | GroupedOption[]
  name: string
  value: DropdownValue | null
  label?: string
  placeholder?: any
  onSelect?: (DropdownValue) => void
  onChange?: (name: string, value: any) => void
  onBlur?: (any) => void
  isMulti?: boolean
  style?: any
  containerStyle?: any
  controlStyle?: any
  closeMenuOnSelect?: boolean
  hasError?: boolean
  dropdownIcon?: any
  leftIcon?: any
  formatOptionLabel?: any
  onMenuScrollToBottom?: (any) => void
  onInputChange?: (any) => void
  onMenuOpen?: (any) => void
  onMenuClose?: (any) => void
  isClearable?: boolean
  maxAmount?: number
  isDisabled?: boolean
  noOptionsMessage?: string
  filterOption?: (any) => boolean
  isLoading?: boolean
  isReadOnly?: boolean
  asPrintable?: boolean
  isFilter?: boolean
  isGroupedOptions?: boolean
  menuIsOpen?: boolean | null
  menuPortalTarget?: HTMLElement | null
  testID?: string
}

export const getStyles = (theme, style, controlStyle, hasError) => ({
  placeholder: () => ({
    width: 'fit-content',
    position: 'relative'
  }),
  singleValue: () => ({
    position: 'relative'
  }),
  input: provided => ({
    ...provided,
    color: theme.colors.inputText
  }),
  container: (provided, { isFocused, isMulti, selectProps }) => ({
    ...provided,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: isFocused
      ? theme.colors.dropDownOnFocus
      : hasError
      ? theme.colors.danger
      : theme.colors.inputBorder,
    borderRadius: theme.radii[3],
    backgroundColor: selectProps?.isFilter
      ? 'transparent'
      : theme.colors.inputBackground,
    minWidth: '100%',
    fontSize: 14,
    fontFamily:
      'system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    alignItems: 'baseline',
    marginBottom: 20,
    minHeight: isMulti && 120,
    height: isMulti && 'auto',
    ...style
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    width: '100%',
    position: 'relative',
    fontSize: 14,
    fontFamily:
      'system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    backgroundColor: isSelected
      ? theme.colors.dropDownSelected
      : isFocused
      ? theme.colors.dropDownOnFocus
      : 'transparent'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    alignItems: 'flex-start',
    height: 'auto'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 5,
    cursor: 'pointer',
    color: theme.colors.dropDownIndicatorColor,
    '&:hover': {
      color: theme.colors.dropDownIndicatorOnFocus
    },
    alignSelf: 'center',
    alignItems: 'center'
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 5,
    cursor: 'pointer',
    color: theme.colors.dropDownIndicatorOnFocus,
    '&:hover': {
      color: theme.colors.dropDownOnFocus
    },
    alignItems: 'flex-start'
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    background: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: data?.custom?.autoFilled ? '#2F2' : theme.colors.primary,
    borderRadius: 20,
    overflow: 'hidden',
    fontWeight: 'bold'
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: theme.colors.primary
  }),
  multiValueRemove: provided => ({
    ...provided,
    borderRadius: 10,
    background: theme.colors.primary,
    color: '#fff',
    padding: 0,
    height: 14,
    width: 14,
    alignSelf: 'center',
    marginRight: 3,
    marginLeft: 4,
    cursor: 'pointer'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    height: 22
  }),
  control: (provided, { isMulti, selectProps }) => ({
    ...provided,
    borderWidth: 0,
    minHeight:
      isMulti && !provided.minHeight ? 100 : selectProps?.isFilter ? 20 : 40,
    alignItems: isMulti ? 'flex-start' : 'center',
    backgroundColor: 'transparent',
    boxShadow: 0,
    color: theme.colors.dropDownTextColor,
    ...controlStyle
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9999,
    minWidth: 'fit-content',
    backgroundColor: theme.colors.dropDownBackground
  }),
  menuPortal: provided => ({
    ...provided,
    marginTop: 0,
    zIndex: 9999
  })
})
