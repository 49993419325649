import React, { createContext, useState, useContext } from 'react'
import { MenuProvider } from 'react-native-popup-menu'
import styled from 'styled-components/native'
import { ViewStyle } from 'react-native'

const StyledProvider = styled(MenuProvider as any).attrs(({ config }) => ({
  customStyles: {
    backdrop: {
      backgroundColor: '#000000',
      opacity: 0.5,
      ...config.backdropStyleOverride
    }
  }
}))``

/**
 * Unfortunately, the backdrop styles for react-native-popup-menu are global,
 * so we need to get creative to allow the styles to change for different types
 * of popups. This uses context to allow the caller of the popup to set the
 * background as it opens
 */
export const PopupMenuProvider = (props: any) => {
  const [config, setConfig] = useState<PopupMenuConfig>(defaultConfig)

  return (
    <PopupMenuConfigContext.Provider value={setConfig}>
      <StyledProvider {...props} config={config} />
    </PopupMenuConfigContext.Provider>
  )
}

type PopupMenuConfig = {
  backdropStyleOverride?: ViewStyle
}

type ConfigContext = (config: PopupMenuConfig) => void

const PopupMenuConfigContext = createContext<ConfigContext>(() => {})

const defaultConfig = {}

/**
 * Convenience hook to allow consumers of modals to set and unset their configs
 * while they're open
 */
export const usePopupMenuConfig = (config: PopupMenuConfig) => {
  const setConfig = useContext(PopupMenuConfigContext)
  return {
    onOpen: () => setConfig(config),
    onClose: () => setConfig(defaultConfig)
  }
}
