import React, { createContext, useState, useEffect } from 'react'
import { AppState } from 'react-native'

import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'

const initialValues: AppStateProviderContextProps = {
  appState: AppState.currentState
}

export const AppStateContext = createContext(initialValues)
export const AppStateProvider = ({ children }: AppStateProviderProps) => {
  const { trackWithProperties, track } = useMixpanel()
  const [appState, setAppState] = useState(AppState.currentState)

  const handleAppStateChange = nextAppState => {
    setAppState(nextAppState)

    if (nextAppState === 'background') {
      trackWithProperties(EVENT_MAP.app.backgrounded, {
        appState: 'background'
      })
    }
  }

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange)
    return () => AppState.removeEventListener('change', handleAppStateChange)
  }, [])

  useEffect(() => {
    track(EVENT_MAP.app.launched)
  }, [])

  return (
    <AppStateContext.Provider value={{ appState }}>
      {children}
    </AppStateContext.Provider>
  )
}

interface AppStateProviderContextProps {
  appState: String
}
interface AppStateProviderProps {
  children: JSX.Element | JSX.Element[] | null | Boolean
}
