import React, { FC } from 'react'

import { Linking, Platform } from 'react-native'
import styled from 'styled-components/native'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
// Components
import PhoneIcon from '../../icon/PhoneIcon'

// Constants
const DEFAULT_REDIRECT =
  'https://innovationroundtables.typeform.com/to/wls7McGP'

// Styled components
const Container = styled.View`
  bottom: 0;
  position: sticky;
  width: 100%;
  ${({ theme: { colors, space } }) => `
    padding-horizontal: ${space[4]}px;
    background-color: ${colors.header};
    padding-vertical: ${space[3] + space[1]}px;
  `}
`
const Button = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme: { colors, radii, space } }) => `
    border-radius: ${radii[6]}px;
    padding-vertical: ${space[3] - 2}px;
    background-color: ${colors.menuItem};
    padding-horizontal: ${space[3] + space[2]}px;
  `}
`
const ButtonLabel = styled.Text`
  color: black;
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[3] + 2}px;
  `}
`
const CustomPhoneIcon = styled(PhoneIcon)`
  ${({ theme: { space } }) => `
    margin-right: ${space[1]}px; 
  `}
`

interface ScheduleCallBtnProps {
  onSubmit?: () => void
}

const ScheduleCallBtn: FC<ScheduleCallBtnProps> = ({ onSubmit }) => {
  // Hooks
  const { t } = useTranslation()

  // Events
  const onPress = async () => {
    if (onSubmit) {
      onSubmit?.()
    } else if (Platform.OS === 'web') {
      window.open(DEFAULT_REDIRECT, '_blank')
    } else {
      await Linking.openURL(DEFAULT_REDIRECT)
    }
  }

  return (
    <Container>
      <Button onPress={onPress}>
        <CustomPhoneIcon />
        <ButtonLabel>{t('common:buttons:scheduleAdvising')}</ButtonLabel>
      </Button>
    </Container>
  )
}

export default ScheduleCallBtn
