import React from 'react'

import { Flex } from '../../../../components/FlexBox'
import Button from '../../../../ui-library/Button'

import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

interface ActionBtnProp {
  title: string
  isProcessing?: boolean
  disabled?: boolean
  onPress: Function
  testID?: string
}
interface CallToActionProp {
  primaryBtn: ActionBtnProp
  secondaryBtn: ActionBtnProp
}

const CallToActionBtns = ({ secondaryBtn, primaryBtn }: CallToActionProp) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <Flex
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-between"
      width="100%"
      marginTop="30px"
    >
      <Button
        testID={secondaryBtn.testID}
        title={secondaryBtn.title}
        onPress={secondaryBtn.onPress}
        isProcessing={secondaryBtn.isProcessing}
        disabled={secondaryBtn.disabled}
        containerStyle={{ flexBasis: isSmallScreen ? '50%' : '40%' }}
        buttonStyle={{
          width: '100%',
          borderWidth: 0
        }}
        titleStyle={{
          fontSize: 14
        }}
      />
      <Button
        testID={primaryBtn.testID}
        title={primaryBtn.title}
        onPress={primaryBtn.onPress}
        isProcessing={primaryBtn.isProcessing}
        disabled={primaryBtn.disabled}
        containerStyle={{
          flexGrow: 1
        }}
        buttonStyle={{
          width: isSmallScreen ? '100%' : '80%',
          alignSelf: 'flex-end'
        }}
      />
    </Flex>
  )
}

export default CallToActionBtns
