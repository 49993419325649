import gql from 'graphql-tag'

const InnovatorProductFragment = gql`
  fragment InnovatorProductFragment on InnovatorProduct {
    id
    submissionId
    formId
    updatedAt
    hasLatestPDF
    publishedLog {
      isPublished
      publishedAt
    }
    delegation {
      name
      id
      marketSegment {
        id
        name
      }
    }
    submission {
      updatedAt
      submissionInfo {
        name
        category
        categoryLabel
      }
    }
  }
`

export default InnovatorProductFragment
