import gql from 'graphql-tag'
import useQuery from '../../hooks/useQuery'

const getRegistrationData = gql`
  query {
    currentUser {
      id
      roles
      innovationOnboardedAt
      innovationEnabled
      person {
        id
        firstName
        lastName
        jobTitle
        photo {
          id
          cloudinaryId
        }
        innovationCompany {
          id
          name
          companyProfile {
            id
            url
            yearFounded
            funding
            employeeCount
            domains {
              id
              name
            }
          }
        }
      }
    }
  }
`

const useVerifyRegistration = (options = {}) => {
  const { data, loading, error } = useQuery(getRegistrationData, options)

  return { data, loading, error }
}

export default useVerifyRegistration
