import { useEffect } from 'react'
import Firebase from '../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import useCurrentUser from '../hooks/useCurrentUser'

const useCurrentUserLoader = () => {
  const {
    setFirebase,
    firebaseInitialized,
    firebaseToken,
    firebaseEmail
  } = useCurrentUser()

  useEffect(() => {
    const clearCallback = getFBAuth(Firebase).onAuthStateChanged(
      (user: any) => {
        setFirebase(user ? user.getIdToken() : null, user ? user.email : null)
      }
    )

    return () => clearCallback()
  }, [])

  return { firebaseInitialized, firebaseToken, firebaseEmail }
}

export default useCurrentUserLoader
