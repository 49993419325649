import React, { useContext, useState, cloneElement } from 'react'
import { ActivityIndicator, ViewStyle, TextStyle, View } from 'react-native'
import {
  Button as RNEButton,
  ButtonProps as RNEButtonProps
} from 'react-native-elements'
import './styleOverride.css'
import styled, { ThemeContext } from 'styled-components/native'

const StyledButton = styled(RNEButton)`
  position: relative;
  border-radius: 30%;
`

interface ButtonStyles {
  buttonStyle?: ViewStyle
  titleStyle?: TextStyle
  containerStyle?: ViewStyle
  iconContainerStyle?: ViewStyle
  iconStyle?: ViewStyle | TextStyle
  disabledStyle?: ViewStyle
}

interface ButtonProps extends RNEButtonProps {
  isProcessing?: boolean
  icon?: any
  buttonStyle?: ViewStyle
  titleStyle?: TextStyle
  containerStyle?: ViewStyle
  iconContainerStyle?: ViewStyle
  iconStyle?: ViewStyle | TextStyle
  disabledStyle?: ViewStyle
  hoverStyles?: ButtonStyles
  testID?: string
}

export default function Button({
  isProcessing,
  buttonStyle,
  titleStyle,
  containerStyle,
  disabledStyle,
  iconContainerStyle,
  iconStyle,
  disabled,
  icon,
  type = 'solid',
  hoverStyles = {},
  testID,
  ...rest
}: ButtonProps) {
  const theme = useContext(ThemeContext) as any
  const [hover, setHover] = useState(false)

  const buttonStyles = {
    solid: {
      containerStyle: {},
      buttonStyle: {
        backgroundColor: disabled
          ? theme.colors.disabled
          : theme.colors.buttonBackground,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.buttonBorder,
        borderRadius: theme.radii[5],
        ...buttonStyle
      },
      titleStyle: {
        color: theme.colors.buttonText
      },
      disabledStyle: {
        backgroundColor: theme.colors.buttonBackground,
        borderColor: 'transparent',
        opacity: 0.2
      },
      disabledTitleStyle: {
        color: theme.colors.buttonText
      }
    },
    outline: {
      buttonStyle: {
        backgroundColor: 'transparent',
        borderColor: theme.colors.buttonBackground,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.radii[5]
      },
      titleStyle: {
        color: theme.colors.buttonBackground
      },
      containerStyle: {},
      disabledStyle: {
        backgroundColor: 'transparent',
        opacity: 0.2
      },
      disabledTitleStyle: {
        color: theme.colors.buttonBackground
      }
    },
    clear: {
      buttonStyle: {
        width: 'auto',
        backgroundColor: 'transparent',
        padding: 0
      },
      titleStyle: {
        color: theme.colors.primary,
        fontSize: 17
      },
      containerStyle: {},
      disabledStyle: {
        backgroundColor: 'transparent'
      },
      disabledTitleStyle: {
        color: theme.colors.buttonBackground
      }
    }
  }

  const showIcon = icon || isProcessing
  const ButtonIcon = icon ? (
    cloneElement(icon, hover ? hoverStyles?.iconStyle : iconStyle)
  ) : (
    <></>
  )
  const Spinner = (
    <ActivityIndicator
      size="small"
      color={theme.colors.buttonText}
      {...(hover ? hoverStyles?.iconStyle : iconStyle)}
    />
  )

  return (
    <StyledButton
      testID={testID}
      disabled={disabled}
      icon={
        showIcon ? (
          /* @ts-ignore icon or isProcessing is assured to be set here */
          <IconContainer style={iconContainerStyle}>
            {isProcessing ? Spinner : ButtonIcon}
          </IconContainer>
        ) : undefined
      }
      containerStyle={{
        ...buttonStyles[type].containerStyle,
        ...containerStyle
      }}
      buttonStyle={{
        width: 178,
        margin: 0,
        padding: 7,
        ...buttonStyles[type].buttonStyle,
        ...buttonStyle,
        ...(hover ? hoverStyles?.buttonStyle ?? {} : {})
      }}
      titleStyle={{
        fontSize: theme.fontSizes[3],
        fontStyle: 'normal',
        textTransform: 'uppercase',
        ...buttonStyles[type].titleStyle,
        ...titleStyle,
        ...(hover ? hoverStyles?.titleStyle ?? {} : {})
      }}
      disabledStyle={{
        ...buttonStyles[type].disabledStyle,
        ...disabledStyle
      }}
      disabledTitleStyle={{
        ...buttonStyles[type].disabledTitleStyle
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...rest}
    />
  )
}

interface IconContainerProps {
  children: JSX.Element
  style: ViewStyle
}

const IconContainer = ({ style, children }: IconContainerProps) => (
  <View style={{ position: 'absolute', left: 15, top: 9, ...style }}>
    {children}
  </View>
)
