import React, { useEffect } from 'react'
import styled from 'styled-components/native'
import Rating from '../../ui-library/Rating'
import DateService from '../../services/dateService'
import { useWindowDimensions, Text, View } from 'react-native'
import { TabView, TabBar } from 'react-native-tab-view'
import useTranslation from '../../hooks/useTranslation'
import useCallForSubmissionQuery from '../../screens/Curation/hooks/useCallForSubmissionQuery'
import Icon from '../icon'
import {
  CallForSubmissionStepEnum,
  CallForSubmission,
  InnovationSubmission,
  InnovationSubmissionStatusEnum,
  CallForSubmissionStatusEnum
} from '../../types'

const TITLE_COLOR = '#666F9C'
const TAB_INACTIVE_TEXT_COLOR = '#afb8d5'
const TAB_ACTIVE_TEXT_COLOR = '#595d6f'

const Title = styled.Text`
  text-align: center;
  ${({ theme: { fontSizes } }) => `
    color: ${TITLE_COLOR};
    font-size: ${fontSizes[8]}px;
  `}
`
const SubmissionTabContainer = styled.View`
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]}px;
`
const StyledText = styled(Text)``
const DisclaimerText = styled(StyledText)`
  padding-left: ${({ theme }) => theme.space[3]}px;
`
const DisclaimerContainer = styled(SubmissionTabContainer)`
  gap: ${({ theme }) => theme.space[4]}px;
`
const CircleContainer = styled(View)`
  ${({ theme, background }) => `
    background: ${background || theme.colors.primaryPalette.black};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    z-index: 100;
  `}
`
const Container = styled(View)`
  flex-direction: row;
`
const ScoresContainer = styled(View)`
  flex-direction: column;
  width: 50%;
`
const WAS = styled(View)`
  flex-direction: column;
  ${({ theme: { sizes } }) => `
    size: ${sizes[2] + 2}px;
  `}
`

const Circle = ({ isEnable, isCompleted }) => {
  const background = !isEnable ? '#cccccc' : isCompleted ? '#ecf9f8' : '#ffecf2'
  const iconColor = !isEnable ? '#cccccc' : isCompleted ? '#1ec8b6' : '#ff375f'
  return (
    <CircleContainer background={background}>
      <Icon
        color={iconColor}
        name={isCompleted ? 'check' : 'x-mark'}
        width={18}
        height={18}
      />
    </CircleContainer>
  )
}

const TabContainer = styled(View)`
  ${({ theme: { space, colors } }) => `
    padding-top: ${space[2]}px;
    padding-bottom: ${space[4] - space[1]}px;
    color: ${colors.primaryPalette.black};
  `}
`

const VCenterText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledRating = styled(Rating).attrs(() => ({
  isReadOnly: true,
  startSize: 15,
  containerStyle: { marginTop: 2, marginBottom: 2, marginLeft: 5 }
}))``

const SubmittedTab = ({ isComplete, cfs, submission }) => {
  const { t } = useTranslation()
  const currentCFSStep = getCFSStep(cfs) || submission.step
  const isInSubmissionStep =
    submission.hasBeenSubmitted ||
    ![CallForSubmissionStepEnum.submission].includes(currentCFSStep)

  const delegationLabel =
    cfs?.disclaimer && cfs.delegations?.length
      ? cfs.delegations.map(del => del.name).join(', ')
      : ''

  const eventStep = cfs?.stepDateRanges.find(
    cfsStep => cfsStep.curationStep === CallForSubmissionStepEnum.event
  )

  return (
    <SubmissionTabContainer>
      {isInSubmissionStep ? (
        <StyledText>
          {t(
            `submissions:pizzaTracker:${
              isComplete ? 'submitted' : 'noSubmitted'
            }`
          )}
        </StyledText>
      ) : null}
      {cfs?.disclaimer && !!eventStep ? (
        <DisclaimerContainer>
          <DisclaimerText>
            {t('submissions:pizzaTracker:disclaimerTitle', {
              delegations: delegationLabel,
              eventType: cfs?.delegations?.length > 1 ? 'events' : 'event'
            })}
          </DisclaimerText>
          <DisclaimerText>
            {t('submissions:pizzaTracker:disclaimerSubTitle', {
              disclaimerEventLocation: cfs.disclaimerEventLocation?.trim?.(),
              disclaimerStartDate:
                eventStep && eventStep?.openAt
                  ? DateService.getFormatWithTimeZone(
                      new Date(eventStep.openAt),
                      'MMMM DD, YYYY'
                    ).trim?.()
                  : '',
              disclaimerInnovatorFee: cfs.disclaimerInnovatorFee?.trim?.(),
              disclaimerContactEmail: cfs.disclaimerContactEmail?.trim?.()
            })}
          </DisclaimerText>
        </DisclaimerContainer>
      ) : null}
    </SubmissionTabContainer>
  )
}

const CurationTab = ({ isComplete }) => {
  const { t } = useTranslation()
  return isComplete ? (
    <Text>{t('submissions:pizzaTracker:curationComplete')}</Text>
  ) : (
    <Text>{t('submissions:pizzaTracker:curationIncomplete')}</Text>
  )
}

const AdvisingTab = ({ average, submission, cfs }) => {
  const { t } = useTranslation()
  const cfsCurrentStep = getCFSStep(cfs)
  const callForSubmissionId = submission?.callForSubmissionId
  const { callForSubmission, loading } = useCallForSubmissionQuery(
    callForSubmissionId,
    false,
    true,
    true,
    false
  )
  const submissionsWAS = callForSubmission?.innovationSubmissions
    ?.filter(
      innovationS =>
        innovationS.passOrCutText !== '' && innovationS.passOrCutText !== 'Cut'
    )
    .map(innovationS => Number(innovationS.submissionAverage?.wass))
  const averageWAS = !loading
    ? (
        submissionsWAS?.reduce((a, b) => a + b) / submissionsWAS?.length
      ).toFixed(2)
    : ''
  const isAlumni = submission?.company.isAlumni

  if (
    submission &&
    submission.step === CallForSubmissionStepEnum.advising &&
    submission.status === InnovationSubmissionStatusEnum.inProgress &&
    [
      CallForSubmissionStepEnum.preCurate,
      CallForSubmissionStepEnum.advising,
      CallForSubmissionStepEnum.cohort
    ].includes(cfsCurrentStep)
  ) {
    return (
      <VCenterText>
        {t('submissions:pizzaTracker:advisingIncomplete')}
      </VCenterText>
    )
  }
  let scores: JSX.Element[] = []
  average?.submissionScores.forEach((score, index) => {
    return scores.push(
      <VCenterText key={`advising-score${index}`}>
        {score.question.questionText}
        <StyledRating count={score.value} />({Math.round(score.value * 10) / 10}
        )
      </VCenterText>
    )
  })
  const was = submission?.submissionAverage?.wass?.toFixed(2)
  return (
    <>
      <Text style={{ marginBottom: '0.5em' }}>
        {t('submissions:pizzaTracker:advisingComplete')}
      </Text>
      <Container>
        <ScoresContainer>{scores}</ScoresContainer>
        {isAlumni ? (
          <WAS>
            <Text style={{ marginVertical: 6 }}>
              {t('submissions:pizzaTracker:was', { was })}
            </Text>
            <Text>
              {t('submissions:pizzaTracker:averageWAS', { averageWAS })}
            </Text>
          </WAS>
        ) : null}
      </Container>
    </>
  )
}

const VotingTab = ({ isComplete }) => {
  const { t } = useTranslation()
  return isComplete ? (
    <Text>{t('submissions:pizzaTracker:votingComplete')}</Text>
  ) : (
    <Text>{t('submissions:pizzaTracker:votingIncomplete')}</Text>
  )
}

const InvitesTab = ({ isComplete }) => {
  const { t } = useTranslation()
  return isComplete ? (
    <Text>{t('submissions:pizzaTracker:invitesComplete')}</Text>
  ) : (
    <Text>{t('submissions:pizzaTracker:invitesIncomplete')}</Text>
  )
}

const Scene = ({ route, submission, cfs, isEnable }) => {
  if (!isEnable) return null
  const isComplete = isTabComplete(route.key, submission, cfs)
  switch (route.key) {
    case 'submitted':
      return (
        <SubmittedTab
          cfs={cfs}
          isComplete={isComplete}
          submission={submission}
        />
      )
    case 'curation':
      return <CurationTab isComplete={isComplete} />
    case 'advising':
      return (
        <AdvisingTab
          average={submission.submissionAverage}
          cfs={cfs}
          submission={submission}
        />
      )
    case 'voting':
      return <VotingTab isComplete={isComplete} />
    case 'invites':
      return <InvitesTab isComplete={isComplete} />
    default:
      return <></>
  }
}

const getCFSStep = cfs => {
  return cfs.status === CallForSubmissionStatusEnum.closed
    ? CallForSubmissionStepEnum.event
    : cfs?.currentStep?.name
}

const isTabEnable = (key, cfs, submission) => {
  if (!key || !cfs || !submission) return false
  const CFSStep = getCFSStep(cfs) || submission.step
  let validStates
  switch (key) {
    case 'submitted':
      return (
        submission.hasBeenSubmitted ||
        [
          CallForSubmissionStepEnum.submission,
          CallForSubmissionStepEnum.preCurate,
          CallForSubmissionStepEnum.advising,
          CallForSubmissionStepEnum.cohort,
          CallForSubmissionStepEnum.voting,
          CallForSubmissionStepEnum.finalSelections,
          CallForSubmissionStepEnum.selection,
          CallForSubmissionStepEnum.event
        ].includes(CFSStep)
      )
    case 'curation':
      validStates = [
        CallForSubmissionStepEnum.advising,
        CallForSubmissionStepEnum.cohort,
        CallForSubmissionStepEnum.voting,
        CallForSubmissionStepEnum.finalSelections,
        CallForSubmissionStepEnum.selection,
        CallForSubmissionStepEnum.event
      ]
      return (
        validStates.includes(CFSStep) &&
        (validStates.includes(submission.step) ||
          submission.step === CallForSubmissionStepEnum.preCurate)
      )
    case 'advising':
      validStates = [
        CallForSubmissionStepEnum.advising,
        CallForSubmissionStepEnum.cohort,
        CallForSubmissionStepEnum.voting,
        CallForSubmissionStepEnum.finalSelections,
        CallForSubmissionStepEnum.selection,
        CallForSubmissionStepEnum.event
      ]
      return (
        validStates.includes(CFSStep) &&
        (validStates.includes(submission.step) ||
          submission.step === CallForSubmissionStepEnum.advising ||
          isTabComplete('curation', submission, cfs))
      )
    case 'voting':
      validStates = [
        CallForSubmissionStepEnum.voting,
        CallForSubmissionStepEnum.finalSelections,
        CallForSubmissionStepEnum.selection,
        CallForSubmissionStepEnum.event
      ]
      return (
        validStates.includes(CFSStep) &&
        (validStates.includes(submission.step) ||
          submission.step === CallForSubmissionStepEnum.advising ||
          submission.step === CallForSubmissionStepEnum.cohort)
      )
    case 'invites':
      validStates = [
        CallForSubmissionStepEnum.selection,
        CallForSubmissionStepEnum.event
      ]
      return (
        validStates.includes(CFSStep) &&
        (validStates.includes(submission.step) ||
          submission.step === CallForSubmissionStepEnum.cohort ||
          submission.step === CallForSubmissionStepEnum.voting)
      )
  }
}

const isTabComplete = (key, submission, cfs) => {
  switch (key) {
    case 'submitted':
      return submission.hasBeenSubmitted
    case 'curation':
      return (
        // if submission is not rejected and CFS step still is in curation OR submission passed to next step
        (submission.step === CallForSubmissionStepEnum.preCurate &&
          submission.status !== InnovationSubmissionStatusEnum.rejected &&
          cfs.step) ||
        [
          CallForSubmissionStepEnum.advising,
          CallForSubmissionStepEnum.cohort,
          CallForSubmissionStepEnum.voting,
          CallForSubmissionStepEnum.finalSelections,
          CallForSubmissionStepEnum.selection,
          CallForSubmissionStepEnum.event
        ].includes(submission.step)
      )
    case 'advising':
      return [
        CallForSubmissionStepEnum.advising,
        CallForSubmissionStepEnum.cohort,
        CallForSubmissionStepEnum.voting,
        CallForSubmissionStepEnum.finalSelections,
        CallForSubmissionStepEnum.selection,
        CallForSubmissionStepEnum.event
      ].includes(submission.step)
    case 'voting':
      const hasPass =
        submission.cohorts.length &&
        submission.cohorts.find(c => c.deletedById === null)
      return (
        (submission.step === CallForSubmissionStepEnum.cohort &&
          submission.status === InnovationSubmissionStatusEnum.inProgress &&
          hasPass) ||
        [
          CallForSubmissionStepEnum.voting,
          CallForSubmissionStepEnum.finalSelections,
          CallForSubmissionStepEnum.selection,
          CallForSubmissionStepEnum.event
        ].includes(submission.step)
      )
    case 'invites':
      const isInvited = submission.cohorts?.find(c => c.isInvite)
      return isInvited
  }
  return false
}

const PizzaTracker = ({
  cfs,
  submission
}: {
  cfs: CallForSubmission | any
  submission: InnovationSubmission
}) => {
  const layout = useWindowDimensions()

  const [index, setIndex] = React.useState(0)
  const defaultProperties = {
    color: TAB_INACTIVE_TEXT_COLOR,
    isEnabled: false,
    isCompleted: false
  }
  const [routes, setRoutes] = React.useState([
    { key: 'submitted', title: 'Submitted', ...defaultProperties },
    { key: 'curation', title: 'Curation', ...defaultProperties },
    { key: 'advising', title: 'Advising', ...defaultProperties },
    { key: 'voting', title: 'Voting', ...defaultProperties },
    { key: 'invites', title: 'Invites', ...defaultProperties }
  ])

  // every time hasBeenSubmitted value changes, recalcute route properties for pizza tracker
  useEffect(() => {
    // calculate route extra info
    const newRoutes = [...routes]
    for (var i in newRoutes) {
      const route = newRoutes[i]
      const isEnabled = isTabEnable(route.key, cfs, submission)
      const isCompleted = isEnabled && isTabComplete(route.key, submission, cfs)
      route['isEnabled'] = isEnabled
      route['color'] = isEnabled
        ? TAB_ACTIVE_TEXT_COLOR
        : TAB_INACTIVE_TEXT_COLOR
      route['isCompleted'] = isCompleted
    }
    setRoutes(newRoutes)
  }, [submission?.hasBeenSubmitted])

  if (!submission) return null

  const renderTabBar = props => (
    <TabBar
      {...props}
      renderLabel={({ route }) => {
        return (
          <Text style={{ color: route['color'], margin: 8 }}>
            {route.title}
          </Text>
        )
      }}
      renderIcon={({ route }) => {
        return (
          <Circle
            isEnable={route['isEnabled']}
            isCompleted={route['isCompleted']}
          />
        )
      }}
      indicatorStyle={{ backgroundColor: '#666f9c' }}
      onTabPress={event => {
        // disable click if tab is disabled
        if (!event.route['isEnabled']) {
          event.preventDefault()
        }
      }}
      style={{ backgroundColor: 'transparent' }}
    />
  )

  const onIndexChange = index => {
    setIndex(index)
  }

  return (
    <>
      <View>
        <Title>{submission.callForSubmission?.name}</Title>
      </View>
      <TabView
        renderTabBar={renderTabBar}
        navigationState={{ index, routes }}
        renderScene={props => {
          const isEnable = props.route['isEnabled']
          return (
            <TabContainer>
              <Scene
                {...props}
                isEnable={isEnable}
                submission={submission}
                cfs={cfs}
              />
            </TabContainer>
          )
        }}
        onIndexChange={onIndexChange}
        initialLayout={{ width: layout.width }}
      />
    </>
  )
}

export default PizzaTracker
