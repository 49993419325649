import React, { FC, useEffect } from 'react'
import { useTheme } from 'styled-components/native'
import { SubmissionProps } from '../../../../recoil/lastSubmittedSubmissionAtom'
import { Company, InnovatorProduct } from '../../../../types'
import useTranslation from '../../../../hooks/useTranslation'
import useSubmissionsQuery from '../../../Submission/hooks/useSubmissionsQuery'
import { MessageStatus } from '../../../../recoil/toastMessageAtom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Flex } from '../../../../components/FlexBox'
import { ActivityIndicator, View } from 'react-native'
import Dropdown from '../../../../components/common/Dropdown'
import SubmissionPrefillSummary from '../../../Submission/components/SubmissionPrefillSummary'
import Button from '../../../../ui-library/Button'
import {
  Description,
  getStyles,
  InputContainer
} from './SharedStyledComponents'
import useUpdateInnovatorProduct from '../../hooks/useUpdateInnovatorProduct'

interface UpdateProductProps {
  lastSubmittedSubmission: SubmissionProps
  company?: Company
  matchingDelegationProducts?: InnovatorProduct[]
  onClose: (val?) => void
  setToastMessage: (val?) => void
  onUpdatedPublishedProduct: (val?) => void
}

const UpdateProduct: FC<UpdateProductProps> = ({
  company,
  lastSubmittedSubmission,
  matchingDelegationProducts,
  onClose,
  setToastMessage,
  onUpdatedPublishedProduct
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { space, colors } = theme
  const { btnContainerStyles, btnStyles } = getStyles(theme)
  const { updateInnovatorProduct, loading } = useUpdateInnovatorProduct(
    company?.id
  )
  const {
    submissions = [],
    loading: isSubmissionLoading
  } = useSubmissionsQuery()

  const productOptions =
    matchingDelegationProducts?.map(product => ({
      value: product.id,
      label: product.submission.submissionInfo?.name || ''
    })) || []

  const {
    resetForm,
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const product = matchingDelegationProducts?.find(
          p => p.id === values.productId
        )

        const isPublishedProduct =
          product &&
          product?.publishedLog?.isPublished &&
          product?.publishedLog?.publishedAt

        const { data, errors } = await updateInnovatorProduct(
          values.submissionId,
          values.productId,
          product?.submissionId
        )
        if (data.updateInnovatorProduct) {
          resetForm()
          if (isPublishedProduct) {
            onUpdatedPublishedProduct(data.updateInnovatorProduct)
          } else {
            onClose(data.updateInnovatorProduct)
          }
        } else if (errors) {
          setToastMessage({
            message: t('error:defaultMutation'),
            status: MessageStatus.Error
          })
        }
      } catch (e) {
        setToastMessage({
          message: t('error:defaultMutation'),
          status: MessageStatus.Error
        })
      }
    },
    validationSchema: Yup.object().shape({
      submissionId: Yup.string().required(),
      productId: Yup.string().required()
    }),
    initialValues: {
      submissionId: '',
      productId: ''
    }
  })

  useEffect(() => {
    if (lastSubmittedSubmission?.submissionId) {
      setFieldValue('submissionId', lastSubmittedSubmission?.submissionId)
    }
  }, [lastSubmittedSubmission?.submissionId])

  if (isSubmissionLoading) {
    return <ActivityIndicator />
  }

  const getCurrentSubmission = () =>
    submissions.find(s => s.id === values.submissionId)

  return (
    <Flex>
      <View
        style={{
          marginTop: space[2]
        }}
      >
        <View>
          <View
            style={{
              marginBottom: space[2]
            }}
          >
            <Description>
              {t('directoryProfile:products:prefillProduct:productSelection')}
            </Description>
          </View>

          <InputContainer>
            <Dropdown
              name="productId"
              value={values.productId}
              options={productOptions}
              onChange={setFieldValue}
              containerStyle={{
                width: '100%'
              }}
              menuPortalTarget={document?.body}
            />
          </InputContainer>
          {values.submissionId !== null && (
            <SubmissionPrefillSummary submission={getCurrentSubmission()} />
          )}
        </View>

        <Flex flexDirection="row">
          <Button
            title={t('product:general:cancel')}
            type="outline"
            buttonStyle={{
              ...btnStyles,
              borderColor: colors.buttonVariant
            }}
            titleStyle={{
              color: colors.buttonVariant
            }}
            containerStyle={{
              ...btnContainerStyles,
              marginRight: space[3]
            }}
            onPress={onClose}
          />
          <Button
            title={t('common:buttons:continue')}
            buttonStyle={btnStyles}
            containerStyle={btnContainerStyles}
            onPress={() => handleSubmit()}
            disabled={!dirty || !isValid}
            loading={loading}
          />
        </Flex>
      </View>
    </Flex>
  )
}

export default UpdateProduct
