import React from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import useTranslation from '../../../hooks/useTranslation'
import Icon from '../../../components/icon'
import Button from '../../../ui-library/Button'

export const Container = styled(View)`
  ${({ theme: { space, colors, radii } }) => `
    background: ${colors.appBackground};
    border-radius: ${radii[2]}px;
    border-left-width: 5px;
    border-left-color: ${colors.primaryPalette.alert};
    max-width: 250px;
    min-width: 250px;
    margin-bottom: ${space[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
  `}
`
const InnerContainer = styled(View)`
  ${({ theme: { space } }) => `
    padding: ${space[3]}px;
  `}
`

const Title = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes, space } }) => `
    color: ${colors.primaryPalette.black};
    fontWeight: ${fontWeights.bold};
    fontSize: ${fontSizes[5]}px;
    margin-bottom: ${space[2]}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    `}
`

const P = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes } }) => `
    color: ${colors.tints.grays.g400};
    fontWeight: ${fontWeights.regular};
    fontSize: ${fontSizes[3]}px;
    line-height: 20px;
  `}
`

interface SubmissionUpdatedProps {
  submissionUpdatedBy?: string
  onRefresh: () => void
}

const SubmissionUpdated = ({
  submissionUpdatedBy,
  onRefresh
}: SubmissionUpdatedProps) => {
  const { t } = useTranslation()
  const { sizes, colors } = useTheme()

  return (
    <Container>
      <InnerContainer
        style={{
          borderBottomWidth: 1,
          borderColor: '#D9D9D9'
        }}
      >
        <Title>
          <Icon
            style={{ marginBottom: 0, paddingBottom: 0, marginRight: sizes[1] }}
            name="alert"
            width={sizes[3]}
            height={sizes[3]}
          />
          {t('submissions:updated:title')}
        </Title>
        <View>
          <P>
            {t('submissions:updated:description', {
              updatedBy: submissionUpdatedBy
            })}
          </P>
        </View>
        <View
          style={{
            marginTop: sizes[3],
            alignItems: 'center'
          }}
        >
          <Button
            title={t('common:buttons:refresh')}
            onPress={onRefresh}
            buttonStyle={{
              backgroundColor: colors.primaryPalette.alert
            }}
          />
        </View>
      </InnerContainer>
    </Container>
  )
}

export default SubmissionUpdated
