import { atom, atomFamily, CallbackInterface, selector } from 'recoil'
import { ProductTabsEnum, ValidationPhaseEnum } from '../types/form'

type Phase = ValidationPhaseEnum | null

export interface ProductForm {
  type: string
  phase: Phase
  isSaving: boolean
  isValidating: boolean
  errors?: number
}

const DEFAULT_PRODUCT_FORM: ProductForm = {
  type: '', // Must be set dynamically
  errors: 0,
  phase: ValidationPhaseEnum.Save,
  isSaving: false,
  isValidating: false
}

/**
 * This list is static and only includes tabs, but could be made dynamic if needed.
 * This can manage any form we want it to manage.
 * In order to use this dynamically in the form system we will
 * need to create a selector or hook to manage this list.
 * This is the preferred way to manage this in recoil.
 * https://github.com/facebookexperimental/Recoil/issues/41
 */
const PRODUCT_FORMS = Object.values(ProductTabsEnum)

/**
 * Atom to manage product form atom family.
 * Maintains a list of product forms.
 */
export const productFormsAtom = atom<string[]>({
  key: 'productFormsState',
  default: PRODUCT_FORMS
})

/**
 * Current Form Reset Function
 */
export const currentFormResetAtom = atom<Function | null>({
  key: 'currentFormResetState',
  default: () => null
})

/**
 * Get product form by form type
 */
export const productFormByType = atomFamily<ProductForm, string>({
  key: 'productFormState',
  default: (type: string) => ({
    ...DEFAULT_PRODUCT_FORM,
    type
  })
})

/**
 * Get all managed product forms.
 */
export const allProductForms = selector<ProductForm[]>({
  key: 'allProductFormsSelector',
  get: ({ get }) => {
    const tabTypes = get(productFormsAtom)
    return tabTypes.map(type => get(productFormByType(type)))
  }
})

/**
 * Setter function to be used with useRecoilCallback that
 * sets the form phase by type.
 */
export const setFormByType = ({ set }: CallbackInterface) => (
  type: string,
  form: ProductForm
) => {
  set(productFormByType(type), form)
}

/**
 * Setter function to be used with useRecoilCallback that
 * sets all the forms to the same phase.
 */
export const setAllFormsPhase = ({ set }: CallbackInterface) => (
  formTypes: string[],
  phase: Phase
) => {
  formTypes.forEach(type => {
    set(productFormByType(type), form => ({ ...form, phase }))
  })
}

/**
 * Setter function to be used with useRecoilCallback that
 * sets all the forms to the same phase.
 */
export const setFormPhase = ({ set }: CallbackInterface) => (
  type: string,
  phase: Phase
) => {
  set(productFormByType(type), form => ({ ...form, phase }))
}

/**
 * Setter function to be used with useRecoilCallback that
 * sets all the forms to the same phase.
 */
export const setFormIsValidating = ({ set }: CallbackInterface) => (
  type: string,
  isValidating: boolean
) => {
  set(productFormByType(type), form => ({ ...form, isValidating }))
}

/**
 * Setter function to be used with useRecoilCallback that
 * sets all the forms to the same isSaving.
 */
export const setAllFormsIsValidating = ({ set }: CallbackInterface) => (
  formTypes: string[],
  isValidating: boolean
) => {
  formTypes.forEach(type => {
    set(productFormByType(type), form => ({ ...form, isValidating }))
  })
}

/**
 * Setter function to be used with useRecoilCallback that
 * sets all the forms to the same isSaving.
 */
export const setAllFormsIsSaving = ({ set }: CallbackInterface) => (
  formTypes: string[],
  isSaving: boolean
) => {
  formTypes.forEach(type => {
    set(productFormByType(type), form => ({ ...form, isSaving }))
  })
}

export const areFormsInSubmissionPhaseSelector = selector<boolean>({
  key: 'areFormsInSubmissionPhaseSelector',
  get: ({ get }) => {
    return get(allProductForms).every(
      form => form.phase === ValidationPhaseEnum.Submit
    )
  }
})

export const areFormsValidatingSelector = selector<boolean>({
  key: 'areFormsValidatingSelector',
  get: ({ get }) => {
    return get(allProductForms).every(form => form.isValidating)
  }
})

export const haveAllFormsPassedValidationSelector = selector<boolean>({
  key: 'haveAllFormsPassedValidation',
  get: ({ get }) => get(allProductForms).every(form => !form.errors)
})

/**
 * Setter function to be used with useRecoilCallback that
 * reset all forms.
 */
export const resetAllForms = ({ set }: CallbackInterface) => (
  formTypes: string[]
) => {
  formTypes.forEach(type => {
    set(productFormByType(type), () => ({
      ...DEFAULT_PRODUCT_FORM,
      type
    }))
  })
}

export default productFormsAtom
