import React from 'react'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color?: string
  size?: number
  style?: object | object[]
}

const ArrowRightIcon = React.memo(
  ({ color = 'white', size = 20, ...rest }: IconProps) => (
    <Svg width={size} height={size} viewBox="0 0 17 16" fill="none" {...rest}>
      <Path
        d="M16.2071 8.70711C16.5976 8.31658 16.5976 7.68342 16.2071 7.29289L9.84315 0.928932C9.45262 0.538408 8.81946 0.538408 8.42893 0.928932C8.03841 1.31946 8.03841 1.95262 8.42893 2.34315L14.0858 8L8.42893 13.6569C8.03841 14.0474 8.03841 14.6805 8.42893 15.0711C8.81946 15.4616 9.45262 15.4616 9.84315 15.0711L16.2071 8.70711ZM0.5 9H15.5V7H0.5V9Z"
        fill={color}
      />
    </Svg>
  )
)

export default ArrowRightIcon
