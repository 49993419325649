import React from 'react'

import useTranslation from '../../../hooks/useTranslation'
import Button from '../../../ui-library/Button'
import useLinkToScreen from '../../../hooks/useLinkToScreen'

const CreateCallForSubmission = () => {
  const { t } = useTranslation()
  const linkToScreen = useLinkToScreen()
  return (
    <Button
      testID={'createCFSbtn'}
      onPress={() => linkToScreen('CreateNewCFS')}
      containerStyle={{
        width: '100%'
      }}
      title={t('callsForSubmission:form:create')}
      style={{
        alignSelf: 'flex-end'
      }}
    />
  )
}

export default CreateCallForSubmission
