import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const finishRegistrationMutation = gql`
  mutation finishInnovatorRegistration {
    finishInnovatorRegistration
  }
`

interface MutationProps {
  onCompleted?: (results: any) => void
  onError?: (error: any) => void
}

const useFinishInnovatorRegistration = (options: MutationProps) => {
  const [finishRegistration]: any[] = useMutation(
    finishRegistrationMutation,
    options
  )

  return finishRegistration
}

export default useFinishInnovatorRegistration
