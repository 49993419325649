import React, { useContext, useMemo } from 'react'
import { ViewContainer } from './components/SharedStyledComponents'
import { View } from 'react-native'
import DashboardSkeleton from './Skeleton'
import { useTheme } from 'styled-components/native'
import { DashboardContext } from './hooks/DashboardProvider'
import StepsSummary from './components/StepsSummary'
import DashboardTitle from './components/DashboardTitle'
import Submissions from './components/Submissions'
import Trends from './components/Trends'
import TopByQuestion from './components/TopByQuestion'
import FiltersBar from './components/FiltersBar'
import Metrics from './components/Metrics'
import Distribution from './components/Distribution'
import Partners from './components/Partners'
import { useDimensions } from '@react-native-community/hooks'
import { DASHBOARD_BREAKPOINT } from './constants'

const DashboardComponents = () => {
  const { loading } = useContext(DashboardContext)
  const theme = useTheme()

  const { width } = useDimensions().window

  const singleColumnLayout = useMemo(() => width <= DASHBOARD_BREAKPOINT, [
    width
  ])

  return loading ? (
    <DashboardSkeleton theme={theme} />
  ) : (
    <ViewContainer>
      <View style={{ flex: 1 }}>
        {/* Dashboard Title: Call For Submission Name */}
        <DashboardTitle />

        {/* Summary Cards */}
        <StepsSummary />

        <FiltersBar />

        <View
          style={{
            flexDirection: singleColumnLayout ? 'column' : 'row',
            flexWrap: 'wrap'
          }}
        >
          {/* Components */}
          <View
            style={{
              marginTop: 10,
              flexDirection: 'row',
              flexWrap: 'wrap',
              maxWidth: singleColumnLayout ? '100%' : '73%',
              width: 'fit-content',
              paddingRight: 10,
              justifyContent: 'space-between'
            }}
          >
            {/* Main Sumissions */}
            <Submissions />

            {/* Top By Question */}
            <TopByQuestion />

            {/* Other metric */}
            <Metrics />

            {/* Submissions Distribution */}
            <Distribution />

            {/* Advisors Info */}
            <Partners />
          </View>
          <View
            style={{
              marginTop: 10,
              maxWidth: singleColumnLayout ? '100%' : '22%',
              paddingRight: 10
            }}
          >
            {/* Trending now */}
            <Trends />
          </View>
        </View>
      </View>
    </ViewContainer>
  )
}

export default DashboardComponents
