import React from 'react'

import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useTranslation from '../../../hooks/useTranslation'

import {
  TableRowContainer as TableRowLarge,
  TableColumn as TableRowSmall,
  TableCell,
  DummyCell,
  EmptyCell,
  EmptyText,
  HeaderText
} from './SharedStyledComponents'

const TableRowEmpty = ({ config }) => {
  const { columns } = config
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()

  return isSmallScreen ? (
    <TableRowSmall>
      {columns.map(({ header, emptyMessage }, i) => {
        if (!header) return null
        return (
          <TableCell width="100%" key={`${header}_${i}`}>
            <HeaderText>{t(header)}</HeaderText>
            {emptyMessage ? (
              <DummyCell>
                <EmptyText>{t(emptyMessage)}</EmptyText>
              </DummyCell>
            ) : (
              <DummyCell />
            )}
          </TableCell>
        )
      })}
    </TableRowSmall>
  ) : (
    <TableRowLarge>
      {columns.map(({ width, header, emptyMessage }, i) => {
        if (!header) {
          return (
            <TableCell width={width} key={`${header}_${i}`}>
              <EmptyCell width={width} />
            </TableCell>
          )
        }

        return (
          <TableCell width={width} key={`${header}_${i}`}>
            {emptyMessage ? (
              <DummyCell width={width}>
                <EmptyText>{t(emptyMessage)}</EmptyText>
              </DummyCell>
            ) : (
              <DummyCell />
            )}
          </TableCell>
        )
      })}
    </TableRowLarge>
  )
}

export default TableRowEmpty
