import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { useTheme } from 'styled-components/native'
import useTranslation from '../../../../../../../../hooks/useTranslation'

import { TouchableOpacity, View } from 'react-native'
import { Text } from '../../../../../../../../components/common/Text'
import AnimatedComponent from '../../../../../../../../components/common/AnimatedComponent'
import { Footer, SaveButton } from '../../../../../../constants'
import {
  BlocksProps,
  QuestionGroupEntityMapContentProps,
  QuestionGroupEntityMapValues
} from '../../../../../types'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'ramda'
import useToast from '../../../../../../../../hooks/useToast'
import useGetQuestionGroupEntityMaps from '../../../hooks/useGetQuestionGroupEntityMaps'
import {
  EntityTypeEnum,
  JSONStyles,
  QuestionGroupConfigData
} from '../../../../../../../../components/DynamicForm/types'
import InputField from '../../../../InputField'
import { JSONValidation } from '../../../../../../utils'
import useUpsertQuestionGroupEntityMapAndEntity from '../../../hooks/useUpsertQuestionGroupEntityMap'
import Dropdown, {
  DropdownOption
} from '../../../../../../../../components/common/Dropdown'

const questionFields = [
  {
    field: 'sequence',
    component: InputField
  },
  {
    field: 'dynamic',
    component: InputField
  },
  {
    field: 'style',
    component: InputField,
    isJSON: true
  },
  {
    field: 'configData',
    component: InputField,
    isJSON: true
  }
]

// Yup validation
const getValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    sequence: Yup.number().integer().default(0).required(),
    dynamic: Yup.boolean().default(false),
    style: JSONValidation,
    configData: JSONValidation
  })

const ParentQuestion = ({
  parentQuestion,
  setParentQuestion
}: {
  setParentQuestion: (question: QuestionGroupEntityMapValues) => void
  parentQuestion?: QuestionGroupEntityMapValues
}) => {
  const { t } = useTranslation()
  const [data, setData] = useState<QuestionGroupEntityMapValues[]>([])
  const { loading, questionGroupEntityMaps } = useGetQuestionGroupEntityMaps(
    true,
    undefined,
    undefined,
    EntityTypeEnum.QuestionGroup
  )

  useEffect(() => {
    if (!loading && questionGroupEntityMaps) {
      setData(questionGroupEntityMaps)
    }
  }, [questionGroupEntityMaps, loading])

  const questionsOptions: DropdownOption[] = useMemo(
    () =>
      data.map(question => ({
        label: JSON.stringify(question?.entity?.configData) ?? '',
        value: question.id
      })),
    [data]
  )

  return (
    <View style={{ width: '120%', marginTop: 10 }}>
      <Text styles={{ marginBottom: 5 }}>{'Parent Question'}</Text>
      <Dropdown
        isFilter
        isDisabled={loading}
        closeMenuOnSelect
        style={{
          minHeight: 0,
          marginBottom: 0
        }}
        value={parentQuestion?.id ?? null}
        containerStyle={{ width: 250, height: 50, marginRight: 10 }}
        isLoading={loading}
        options={questionsOptions}
        controlStyle={{ minHeight: 0 }}
        menuPortalTarget={document?.body}
        onSelect={value => {
          const currentQuestion = questionGroupEntityMaps.find(
            question => question.id === value
          )
          if (currentQuestion) {
            setParentQuestion(currentQuestion)
          }
        }}
        name={'parentQuestionTypesSelector'}
        placeholder={t(
          'platformManagement:forms:menuItems:questions:selectorPlaceholder'
        )}
      />
    </View>
  )
}

const QuestionGroupEntityMapContent = (
  props: QuestionGroupEntityMapContentProps
) => {
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()
  const [parentQuestion, setParentQuestion] = useState<
    QuestionGroupEntityMapValues | undefined
  >()
  const { questionGroupEntityMap, form } = props

  const [
    upsertQuestionGroupEntityMapAndEntity,
    isLoading
  ] = useUpsertQuestionGroupEntityMapAndEntity({
    onError: e => console.error(e),
    onCompleted: ({ upsertQuestionGroupEntityMapAndEntity }) => {
      if (
        upsertQuestionGroupEntityMapAndEntity &&
        !isEmpty(upsertQuestionGroupEntityMapAndEntity)
      ) {
        setToastMessage(
          t('platformManagement:forms:menuItems:forms:successSaved')
        )
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:forms:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:questionGroups:errorSave')
  })

  const currentQuestion = parentQuestion || questionGroupEntityMap

  const questionKeys = [
    ...Object.keys(currentQuestion),
    ...Object.keys(currentQuestion.entity ?? {})
  ]

  let initialValues: any = {}

  for (
    let indexQuestionKey = 0;
    indexQuestionKey < questionKeys.length;
    indexQuestionKey++
  ) {
    const questionKey = questionKeys[indexQuestionKey]
    if (
      ['id', ...questionFields.map(qField => qField.field)].includes(
        questionKey
      )
    ) {
      const currentValue =
        currentQuestion[questionKey] ?? currentQuestion?.entity?.[questionKey]

      if (typeof currentValue === 'object' && currentValue != null) {
        initialValues[questionKey] = JSON.stringify(currentValue)
      } else {
        initialValues[questionKey] = currentValue ?? undefined
      }
    }
  }

  // Formik
  const formik = useFormik<{
    id: string
    sequence: string
    dynamic: boolean | string
    style: JSONStyles
    configData: QuestionGroupConfigData
  }>({
    initialValues,
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      upsertQuestionGroupEntityMapAndEntity({
        variables: {
          id: questionGroupEntityMap.id ? values.id : undefined,
          entityType: EntityTypeEnum.QuestionGroup,
          sequence: parseInt(values.sequence),
          dynamic:
            typeof values.dynamic === 'string'
              ? values.dynamic === 'true'
              : values.dynamic,
          style: values.style,
          configData: values.configData,
          parentId: parentQuestion?.entityId,
          questionGroupId: form.questionGroupId
        },
        refetchQueries: ['getQuestionGroupEntityMaps']
      })
    }
  })

  const validateAndSubmit = async () => {
    try {
      await formik.validateForm().then(() => formik.submitForm())
      setParentQuestion(undefined)
    } catch (error) {
      console.error(error)
    }
  }

  const enableSubmit = useMemo(() => {
    return formik.isValid && formik.dirty
  }, [formik])

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row' }}>
        <View
          style={{
            flex: 1,
            marginTop: 10
          }}
        >
          {!questionGroupEntityMap.id ? (
            <ParentQuestion
              parentQuestion={parentQuestion}
              setParentQuestion={setParentQuestion}
            />
          ) : null}
          <FormikProvider value={formik}>
            {questionFields.map(({ field }) => (
              <InputField
                type="forms"
                key={field}
                fieldName={field}
                isReadOnly={false}
              />
            ))}

            <Footer>
              <SaveButton
                disabled={!enableSubmit}
                onPress={validateAndSubmit}
                isProcessing={isLoading}
                title={t(`common:buttons:update`)}
              />
            </Footer>
          </FormikProvider>
        </View>
      </View>
    </View>
  )
}

const Blocks = (props: BlocksProps) => {
  const { colors } = useTheme()
  const { form } = props

  const {
    questionGroupEntityMaps: initialQuestionGroupEntityMaps = []
  } = useGetQuestionGroupEntityMaps(
    false,
    form.id,
    undefined,
    EntityTypeEnum.QuestionGroup
  )

  const questionGroupEntityMaps = initialQuestionGroupEntityMaps
    .filter(qgem => qgem.questionGroupId === form.questionGroupId)
    .sort((a, b) => a.sequence - b.sequence)

  const [
    currentQuestionGroupEntityMap,
    setCurrentQuestionGroupEntityMap
  ] = useState<QuestionGroupEntityMapValues | undefined>(
    questionGroupEntityMaps?.[0]
  )

  return (
    <View
      style={{
        marginTop: 5,
        width: '100%',
        height: 500,
        flexDirection: 'row'
      }}
    >
      <View
        style={{
          width: '20%',
          height: '100%',
          borderColor: colors.tints.grays.g100,
          borderRightWidth: 1
        }}
      >
        {questionGroupEntityMaps.map(questionGroupEntityMap => (
          <TouchableOpacity
            key={questionGroupEntityMap.id}
            style={{
              width: '100%',
              marginBottom: 5,
              backgroundColor:
                questionGroupEntityMap.id === currentQuestionGroupEntityMap?.id
                  ? colors.tableRowHover
                  : 'transparent'
            }}
            onPress={() =>
              setCurrentQuestionGroupEntityMap(questionGroupEntityMap)
            }
          >
            <AnimatedComponent
              hoverOpacity
              hoverColor={colors.tableRowHover}
              style={{
                width: '100%',
                height: 40,
                justifyContent: 'center',
                borderColor: colors.blueBackground,
                borderBottomWidth: 1.5
              }}
            >
              <Text styles={{ textAlign: 'center' }}>
                {`${questionGroupEntityMap.sequence} - ${questionGroupEntityMap.entity?.configData?.title}`}
              </Text>
            </AnimatedComponent>
          </TouchableOpacity>
        ))}
        <TouchableOpacity
          key={'addNewQuestionGroupEntityMapId'}
          style={{
            borderRadius: 50,
            width: '100%',
            marginTop: 20,
            backgroundColor: colors.cta.primary
          }}
          onPress={() =>
            setCurrentQuestionGroupEntityMap({
              sequence: 0,
              dynamic: false,
              style: {},
              configData: {}
            } as any)
          }
        >
          <AnimatedComponent
            hoverOpacity
            hoverColor={colors.tableRowHover}
            style={{
              width: '100%',
              height: 40,
              justifyContent: 'center'
            }}
          >
            <Text
              styles={{ textAlign: 'center', color: 'white' }}
            >{`Add New +`}</Text>
          </AnimatedComponent>
        </TouchableOpacity>
      </View>
      <View
        style={{
          width: '80%',
          height: '100%',
          paddingHorizontal: 10
        }}
      >
        {currentQuestionGroupEntityMap ? (
          <QuestionGroupEntityMapContent
            form={form}
            questionGroupEntityMap={currentQuestionGroupEntityMap}
          />
        ) : null}
      </View>
    </View>
  )
}

export default Blocks
