import { atomFamily } from 'recoil'
import { FormAnswer } from '../types'
import { AnswerEntityTypeEnum } from '../types/form'

export interface FormAnswerState {
  currentFormAnswer?: FormAnswer
}

const currentFormAnswerAtom = atomFamily<
  FormAnswerState | undefined,
  { submissionEntityId: string; submissionEntityType: AnswerEntityTypeEnum }
>({
  key: 'currentFormAnswerState',
  default: undefined
})

export default currentFormAnswerAtom
