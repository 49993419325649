import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'
import { EngineEnum } from '../../../screens/CallForSubmission/constants'

// JC: Since we are using the query searchInnovationEngine in all the tables,
// we created a dynamic query name to target specific tables to be able to save the query variables
// and e able to target it while updating the cache
export const getSearchInnovationEngineQuery = (
  normalizedQueryName: string,
  engine: EngineEnum,
  customQueryFields: DocumentNode
) => {
  const queryString = `query ${normalizedQueryName}(
    $size: Int
    $sorts: [SortObjectInput!]
    $engine: InnovationSearchEngine!
    $query: String
    $boosts: [BoostsInput!]
    $filters: FiltersInput
    $offset: Int
  ) {
    searchInnovationEngine(
      engine: $engine
      query: $query
      boosts: $boosts
      filters: $filters
      sorts: $sorts
      size: $size
      offset: $offset
    ) {
      total
      requestId
      hits {
        ${
          engine === EngineEnum.InnovationCallForSubmission
            ? `... on CallForSubmission {
          ...CallsForSubmissionFieldsFragment
        }`
            : engine === EngineEnum.InnovationSubmission
            ? `... on InnovationSubmission {
          ...InnovationSubmissionFieldsFragment
        }`
            : engine === EngineEnum.InnovationInnovatorDirectoryEngine
            ? `... on CompanyProfile {
          ...CompanyProfileFragment
        }`
            : ``
        }
      }
    }
  }
  `
  return gql`
    ${queryString}
    ${customQueryFields}
  `
}
