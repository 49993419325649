import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'ramda'
import styled, { useTheme } from 'styled-components/native'

import { InputContainer } from './SharedStyledComponents'
import { FormikErrors, FormikProvider, FormikValues, useFormik } from 'formik'
import {
  AnswerEntityTypeEnum,
  QuestionTypeEnum,
  ValidationPhaseEnum,
  QuestionGroup,
  Question
} from '../types'
import DateService from '../../../services/dateService'
import generateSchemaValidation from '../../../utils/generateSchema'
import {
  getDefaultValueByType,
  mapInitialValuesTouched
} from '../../../hooks/useDynamicForm'
import useUpsertAnswersMutation from '../../../hooks/useUpsertAnswersMutation'
import useToast from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'
import { OriginSourceEnum } from '../../../types'
import { useRecoilCallback } from 'recoil'
import { setQuestionAnswer } from '../recoil/questionAtomFamily'
import { Answer } from '../../../types/form'
import Button from '../../../ui-library/Button'

interface FormComponentProps {
  children: JSX.Element | JSX.Element[] | null | Boolean
  questions: Question[]
  questionGroup: QuestionGroup
  formSubmissionId: string
  handleCloseForm: () => void
  currentAnswer?: Answer
  isReadOnly?: boolean
  answers?: Answer[]
}

const ButtonContainer = styled.View`
  z-index: -1;
  flex-direction: row;
`

const setAllFieldsTouched = formik => {
  formik.setTouched(mapInitialValuesTouched(formik.initialValues))
}

const generateDynamicValidations = (
  questionGroup: QuestionGroup,
  questions: Question[]
) => {
  let schemaObject: any = {}

  schemaObject['questions'] = generateSchemaValidation(
    [questionGroup],
    questions,
    ValidationPhaseEnum.Submit
  )

  return Yup.object().shape(schemaObject)
}

const generateDynamicDefaultValues = (questions: Question[]) => {
  const newDefaultValues: any = {}
  if (questions.length) {
    newDefaultValues['questions'] = {}
    questions.forEach(question => {
      newDefaultValues.questions[question.id] = getDefaultValueByType(
        question.type
      )
    })
  }

  return newDefaultValues
}

const getFormattedValue = (ans: Answer, questions: Question[]): Object => {
  let formattedValues = {}
  Object.keys(ans.value).forEach((k, i) => {
    if (questions[i]?.type === QuestionTypeEnum.DateInput) {
      formattedValues[k] = DateService.getFormat(ans.value[k])
    } else {
      formattedValues[k] = ans.value[k]
    }
  })
  formattedValues['id'] = ans.id

  return formattedValues
}

const getStyles = theme => ({
  btnContainerStyles: { height: theme.space[4], marginTop: theme.space[3] },
  btnStyles: {
    width: 'fit-content',
    paddingBottom: theme.space[3],
    paddingTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[2],
    paddingRight: theme.space[4] - theme.space[2],
    height: theme.space[4],
    paddingHorizontal: theme.space[4]
  }
})

const FormComponent = (props: FormComponentProps) => {
  const {
    children,
    questions,
    questionGroup,
    currentAnswer,
    formSubmissionId,
    handleCloseForm,
    isReadOnly = false,
    answers = []
  } = props
  const { setToastMessage, setToastErrorMessage } = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const theme = useTheme()
  const { t } = useTranslation()
  const { colors, space } = theme
  const { btnContainerStyles, btnStyles } = getStyles(theme)
  const setQuestionWithAnswers = useRecoilCallback(setQuestionAnswer)
  const [tableData, setTableData] = useState<Object[]>([])

  useEffect(() => {
    let data: Object[] = []
    if (answers?.length) {
      answers.map((ans: Answer) => {
        data.push(getFormattedValue(ans, questions))
      })
    }
    setTableData(data)
  }, [JSON.stringify(answers), questions])

  const [upsertAnswers, loadingUpsertAnswer] = useUpsertAnswersMutation({
    onError: e => console.error(e),
    onCompleted: ({ upsertAnswers }) => {
      if (upsertAnswers && !isEmpty(upsertAnswers)) {
        setToastMessage(t('product:success'))
        const answer: Answer = upsertAnswers[0]
        setTableData([...tableData, getFormattedValue(answer, questions)])
      } else {
        setToastErrorMessage(t('error:form:answerQuestions'))
        console.warn(
          `No upsertAnswers returned from upsertAnswers mutation: `,
          upsertAnswers
        )
      }
    },
    errorMessage: t('error:form:answerQuestions')
  })

  const initialValues = useMemo(() => generateDynamicDefaultValues(questions), [
    questions
  ])

  const answerDynamicBlockQuestions = async (values: FormikValues) => {
    const questionValues = values['questions']
    let dynamicBlockJSONValue = {}
    questions.forEach(question => {
      dynamicBlockJSONValue = {
        ...dynamicBlockJSONValue,
        [question.id]: questionValues[question.id]
      }
    })
    const answers: Answer[] = [
      {
        id: currentAnswer ? currentAnswer?.id : undefined,
        questionId: questions[0].id,
        answerEntityType: AnswerEntityTypeEnum.InnovationSubmission,
        formSubmissionId,
        value: dynamicBlockJSONValue
      }
    ]
    await upsertAnswers({
      variables: {
        answers,
        dynamic: true,
        originSource: OriginSourceEnum.Innovation
      },
      refetchQueries: ['question']
    })
  }

  const form = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    validate: async (values: FormikValues) => {
      const touchedFields = form.touched?.questions ?? {}
      const errors: FormikErrors<FormikValues> = { questions: {} }
      const schema = generateDynamicValidations(questionGroup, questions)

      try {
        schema.validateSync(values, { abortEarly: false })
      } catch (error) {
        error?.inner?.forEach(error => {
          const questionId = error?.path?.split('.')[1]
          if (touchedFields && touchedFields[questionId]) {
            errors.questions[questionId] = error.message
          }
        })
      }

      return isEmpty(errors.questions) ? {} : errors
    },
    onSubmit: async (values, { setSubmitting, setStatus, resetForm }) => {
      setIsLoading(true)
      try {
        await answerDynamicBlockQuestions(values)
        resetForm({})
        setStatus({ success: true })
      } catch (error) {
        setStatus({ success: false })
        setSubmitting(false)
      } finally {
        setQuestionWithAnswers(questions, [])
        if (currentAnswer) {
          handleCloseForm()
        }
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    if (currentAnswer) {
      const currentQuestions = Object.keys(currentAnswer.value)
      for (
        let indexQuestion = 0;
        indexQuestion < currentQuestions.length;
        indexQuestion++
      ) {
        const currentQuestionId = currentQuestions[indexQuestion]
        const currentValues = getFormattedValue(currentAnswer, questions)
        const currentQuestion = questions.find(
          question => question.id === currentQuestionId
        )
        if (currentQuestion) {
          const { setValue } = form.getFieldHelpers(
            `questions.${currentQuestionId}`
          )
          setValue(
            currentQuestion.type === QuestionTypeEnum.DateInput
              ? new Date(currentValues[currentQuestionId] ?? undefined)
              : currentValues[currentQuestionId]
          )
        }
      }
    }
  }, [currentAnswer])

  const handleAdd = useCallback(async () => {
    setAllFieldsTouched(form)
    const errors = await form.validateForm()
    if (isEmpty(errors)) {
      await form.submitForm()
    }
  }, [questions])

  const onCloseForm = useCallback(() => {
    form.resetForm()
    handleCloseForm()
  }, [form])

  return (
    <>
      <InputContainer style={{ flexDirection: 'row' }}>
        <FormikProvider value={form}>{children}</FormikProvider>
      </InputContainer>
      <ButtonContainer>
        <Button
          title={t('product:general:cancel')}
          type="outline"
          buttonStyle={{
            ...btnStyles,
            borderColor: colors.buttonVariant
          }}
          titleStyle={{
            color: colors.buttonVariant
          }}
          containerStyle={{
            ...btnContainerStyles,
            marginRight: space[3]
          }}
          disabled={isReadOnly}
          onPress={onCloseForm}
        />
        <Button
          title={
            currentAnswer
              ? t('product:general:staticButtonEdit')
              : questionGroup?.configData?.buttonLabel ||
                t('product:general:staticButton')
          }
          buttonStyle={btnStyles}
          containerStyle={btnContainerStyles}
          disabled={isReadOnly || isLoading || loadingUpsertAnswer}
          onPress={handleAdd}
        />
      </ButtonContainer>
    </>
  )
}

export default FormComponent
