import React, { useEffect, useRef } from 'react'
import { Platform } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Firebase from '../../Firebase'
import { getAuth as getFBAuth, updatePassword } from 'firebase/auth'

import useTranslation from '../../hooks/useTranslation'

import { LoginCheckSubStateProps } from '../../AppCoordinator/LoginCheck'
import TextInput, { TextInputProps } from '../../ui-library/TextInput'
import {
  AuthenticationErrorContainer,
  ErrorText
} from './components/SharedStyledComponents'
import useToast from '../../hooks/useToast'
import changeText from '../../utils/changeFormikText'
import RowLayout from './components/layout/RowLayout'
import PageHeader from './components/sharedComponents/PageHeader'
import CallToActionBtns from './components/sharedComponents/CallToActionBtns'
import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'

const ChangePasswordScreen = ({ send }: LoginCheckSubStateProps) => {
  const { t } = useTranslation()
  const { track } = useMixpanel()
  const { setToastErrorMessage } = useToast()
  const newPasswordRef = useRef()
  const confirmPasswordRef = useRef()

  useEffect(() => {
    // @ts-ignore Protecting against ref not being set
    newPasswordRef?.current?.focus()
  }, [])

  const submit = async values => {
    const currentUserFB = getFBAuth(Firebase).currentUser
    if (!currentUserFB) {
      return setToastErrorMessage(t('error:auth:changePassword'))
    }
    try {
      await updatePassword(currentUserFB, values.newPassword)
      track(EVENT_MAP.auth.changePassword)
      send('PASSWORD_SET')
    } catch (error) {
      setToastErrorMessage(t('error:auth:changePassword'))
    }
  }

  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, t('settings:changePassword:tooShort'))
      .required(t('settings:changePassword:required')),
    newPasswordAgain: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null as any],
        t('settings:changePassword:match')
      )
      .required(t('settings:changePassword:againRequired'))
  })

  return (
    <Formik
      initialValues={{ newPassword: '', newPasswordAgain: '' }}
      onSubmit={submit}
      validationSchema={passwordSchema}
    >
      {({
        setFieldValue,
        submitForm,
        values,
        errors,
        touched,
        validateForm,
        isValidating,
        isSubmitting
      }) => {
        const disableInputs: boolean = isValidating || isSubmitting
        const commonTextInputProps: TextInputProps = {
          autoCapitalize: 'none',
          secureTextEntry: true,
          editable: !disableInputs,
          style: { minWidth: '100%' }
        }
        return (
          <RowLayout>
            <PageHeader title={t('auth:resetPassword:header')} />
            <TextInput
              value={values.newPassword}
              label={t('auth:forms:newPassword')}
              onChangeText={value =>
                changeText('newPassword', value, setFieldValue)
              }
              ref={newPasswordRef}
              onSubmitEditing={() => {
                // @ts-ignore Protecting against ref not being set
                confirmPasswordRef?.current?.focus()
              }}
              {...commonTextInputProps}
            />
            <AuthenticationErrorContainer>
              {errors.newPassword && touched.newPassword ? (
                <ErrorText>{errors.newPassword}</ErrorText>
              ) : null}
            </AuthenticationErrorContainer>
            <TextInput
              label={t('auth:forms:confirmPassword')}
              value={values.newPasswordAgain}
              onChangeText={value =>
                changeText('newPasswordAgain', value, setFieldValue)
              }
              ref={confirmPasswordRef}
              onSubmitEditing={() => {
                validateForm().then(() => submitForm())
              }}
              {...commonTextInputProps}
            />
            <AuthenticationErrorContainer>
              {errors.newPasswordAgain && touched.newPasswordAgain ? (
                <ErrorText>{errors.newPasswordAgain}</ErrorText>
              ) : null}
            </AuthenticationErrorContainer>

            <CallToActionBtns
              primaryBtn={{
                title: t('auth:buttons:submit'),
                disabled: disableInputs,
                onPress: () => {
                  validateForm().then(() => submitForm())
                }
              }}
              secondaryBtn={{
                title: t('common:buttons:cancel'),
                disabled: disableInputs,
                onPress: () => {
                  if (Platform.OS === 'web') {
                    document.location.reload()
                  }
                }
              }}
            />
          </RowLayout>
        )
      }}
    </Formik>
  )
}

export default ChangePasswordScreen
