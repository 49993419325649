const DEFAULT_LOCALE = 'en-US'
const DEFAULT_CURRENCY = 'USD'

const CONTAINS_NUMBERS_REGEX = /^\d+(\.\d*[1-9])?$/g

interface GetCurrencyFormatProps {
  locale?: string
  currency?: string
  style?: string
  notation?: 'compact' | 'standard'
  minimumFractionDigits?: number
}

export const getCurrencyFormat = (
  value: number | bigint,
  options: GetCurrencyFormatProps = {}
): string => {
  const {
    locale = DEFAULT_LOCALE,
    style = 'decimal' as any,
    notation = 'standard' as any,
    currency = DEFAULT_CURRENCY,
    minimumFractionDigits = 0
  } = options
  const currencyObj = new Intl.NumberFormat(locale, {
    style,
    currency: currency,
    notation,
    minimumFractionDigits
  })
  return currencyObj.format(value)
}

export const containsNumbers = (str: string): boolean => {
  const re = new RegExp(CONTAINS_NUMBERS_REGEX)
  return re.test(str)
}

export const formatFloatsToCommas = (str: string): string => {
  const re = new RegExp(CONTAINS_NUMBERS_REGEX)
  const boolStr = re.test(str)
  if (boolStr) {
    const stringFormatted = str.replace(CONTAINS_NUMBERS_REGEX, strNumber => {
      const number = Number(strNumber)
      return Number.isInteger(number)
        ? getCurrencyFormat(number).toString()
        : strNumber
    })
    return stringFormatted
  }
  return str
}
