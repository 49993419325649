import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { CallForSubmission } from '../../../types'

export const callForSubmissionQuery = gql`
  query callForSubmission($id: String!) {
    callForSubmission(id: $id) {
      id
      name
      status
      stepDateRanges {
        curationStep
        openAt
        closeAt
      }
      currentStep {
        name
        openAt
        closeAt
      }
      delegations {
        id
        groups {
          id
          shortName
        }
      }
      marketSegments {
        id
        name
      }
      advisorsInfo {
        user {
          id
          person {
            firstName
            lastName
          }
        }
        status
        reviews
        favorite
        academy
      }
      reviewFormQuestions {
        id
        questionText
        type
      }
      canEditSubmissions
    }
  }
`

const useCallForSubmissionQuery = (id: string, skip?: boolean) => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(callForSubmissionQuery, {
    variables: {
      id
    },
    skip: skip || !id,
    fetchPolicy: 'cache-first',
    errorMessage: t('error:submissions:getCallsForSubmission')
  })

  return {
    callForSubmission: data?.callForSubmission as CallForSubmission,
    refetch,
    loading
  }
}

export default useCallForSubmissionQuery
