import React, { FC, useCallback } from 'react'
import { ViewStyle } from 'react-native'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import Button from '../../../ui-library/Button'

interface ButtonRowProps {
  buttons: ButtonConfig[]
  leftButton?: ButtonConfig
}

export interface ButtonConfig {
  title: string
  onPress: (item?: any) => any
  isProcessing?: boolean
  isDisabled?: boolean
  type?: 'solid' | 'clear' | 'outline'
  iconStyle?: ViewStyle
  hoverStyles?: object
  buttonStyle?: object
  titleStyle?: object
}

const GroupButtons: FC<ButtonRowProps> = ({ buttons, leftButton }) => {
  const isSmallScreen = useIsSmallScreen()

  const buttonComponents = buttons.map((item, index) => {
    const hasMultipleButtons = buttons.length > 1 && index < buttons.length - 1
    let props = {
      onPress: item.onPress,
      title: item.title,
      key: index,
      isProcessing: item.isProcessing || false,
      disabled: item.isDisabled || false,
      type: item.type || 'solid',
      iconStyle: item.iconStyle,
      buttonStyle: item?.buttonStyle,
      titleStyle: item?.titleStyle,
      hoverStyles: item?.hoverStyles
    }
    if (isSmallScreen) {
      props['buttonStyle'] = { width: '100%' }
      props['containerStyle'] = {
        width: isSmallScreen ? '120px' : 'auto'
      }
    }

    if (hasMultipleButtons) {
      const margins = { marginBottom: isSmallScreen ? 0 : 15 }
      props['containerStyle'] = props['containerStyle']
        ? { ...props['containerStyle'], ...margins }
        : margins
    }

    return <Button {...props} accessibilityLabel={'endOfScreen'} />
  })

  const getLeftButtonComponent = useCallback(() => {
    const leftButtonProps = {
      onPress: leftButton?.onPress,
      title: leftButton?.title,
      key: '0-rightFooterButton',
      isProcessing: leftButton?.isProcessing || false,
      disabled: leftButton?.isDisabled || false,
      containerStyle: {
        width: isSmallScreen ? '100%' : 'auto',
        marginBottom: 15,
        marginRight: isSmallScreen ? 15 : 0
      },
      buttonStyle: { width: '100%' }
    }

    const button = (
      <Button
        type="outline"
        {...leftButtonProps}
        accessibilityLabel={'endOfScreen'}
      />
    )
    return button
  }, [leftButton, isSmallScreen])

  if (!leftButton && !buttons?.length) {
    return null
  }

  return (
    <>
      {leftButton && getLeftButtonComponent()}
      {buttonComponents}
    </>
  )
}

export default GroupButtons
