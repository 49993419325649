import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import QuestionGroupFieldsFragment from '../../../../../../graphql/QuestionGroupFieldsFragment'

export const upsertParentQuestionGroupMutation = gql`
  mutation upsertParentQuestionGroup(
    $input: QuestionGroupInput
    $questionGroupId: String
  ) {
    upsertParentQuestionGroup(
      input: $input
      questionGroupId: $questionGroupId
    ) {
      ...QuestionGroupFieldsFragment
    }
  }
  ${QuestionGroupFieldsFragment}
`

const useUpsertParentQuestionGroup = (options = {}) => {
  const [
    upsertQuestionGroup,
    { loading: loadingUpsertQuestionGroup }
  ]: any[] = useMutation(upsertParentQuestionGroupMutation, options)

  return [upsertQuestionGroup, loadingUpsertQuestionGroup]
}

export default useUpsertParentQuestionGroup
