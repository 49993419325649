import React, { useContext } from 'react'
import { SearchContainer } from '../SharedStyledComponents'
import { TableContext } from '../../../hooks/TableProvider'
import DebouncedTextInput from '../../common/DebouncedTextInput'
import useTranslation from '../../../../../hooks/useTranslation'
import { searchDebouncedInputStyles } from '../constants'
import theme from '../../../../../constants/Theme'

const SearchBox = (): any => {
  const { t } = useTranslation()
  const {
    config: { isAlternativeView, enableFilters, tableId, name },
    setSearchFiltersValue
  } = useContext(TableContext)
  const testID = tableId || name

  return (
    <SearchContainer isAlternativeView={isAlternativeView}>
      {enableFilters
        ? ((
            <DebouncedTextInput
              testID={`searchBar-${testID}`}
              initialValue={''}
              placeholder={t('common:search')}
              onChangeText={value => setSearchFiltersValue({ value })}
              style={{
                ...searchDebouncedInputStyles,
                marginLeft: isAlternativeView
                  ? theme.sizes[2]
                  : theme.sizes[3] + theme.sizes[2],
                width: isAlternativeView ? '85%' : '70%'
              }}
            />
          ) as any)
        : null}
    </SearchContainer>
  )
}
export default SearchBox
