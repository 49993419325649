import React from 'react'
import {
  TableRowContainer as TableRowLarge,
  TableColumn as TableRowSmall,
  TableCell
} from './SharedStyledComponents'
import useTableRow from '../hooks/useTableRow'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

const TableRowHandler = ({ config, item, index, isReadOnly, ...rest }) => {
  const columns = rest?.columns ?? config?.columns ?? []
  const { LargeRow, SmallRow } = config
  const handlers = useTableRow({ config })
  const isSmallScreen = useIsSmallScreen()

  const LargeRowComponent = LargeRow || TableRowLarge
  const SmallRowComponent = SmallRow || TableRowSmall

  return isSmallScreen ? (
    <SmallRowComponent>
      {columns.map(
        ({ Cell, isEditable, header, itemMap, role, ...rest }, i) => (
          <TableCell key={i} width="100%">
            <Cell
              item={item}
              itemMap={itemMap}
              handlers={handlers}
              label={header}
              isEditable={isEditable}
              isReadOnly={isReadOnly}
              isSmallScreen={isSmallScreen}
              role={role}
              {...rest}
            />
          </TableCell>
        )
      )}
    </SmallRowComponent>
  ) : (
    <LargeRowComponent rowStyle={index % 2 === 0 ? 'even' : 'odd'}>
      {columns.map(({ Cell, width, isEditable, itemMap, role, ...rest }, i) => (
        <TableCell key={i} width={width}>
          <Cell
            item={item}
            handlers={handlers}
            isEditable={isEditable}
            itemMap={itemMap}
            isReadOnly={isReadOnly}
            isSmallScreen={isSmallScreen}
            role={role}
            {...rest}
          />
        </TableCell>
      ))}
    </LargeRowComponent>
  )
}

export default TableRowHandler
