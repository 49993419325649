import { View } from 'react-native'
import styled from 'styled-components/native'
import { Flex } from '../../../FlexBox'
import Button from '../../../../ui-library/Button'

// HEADER SHARED COMPONENTS

export const Container = styled(View)`
  ${({ isAlternativeView }) => (isAlternativeView ? 'display: flex' : '')};
  position: relative;
  z-index: 2;
  background-color: white;
  ${({ isAlternativeView }) =>
    isAlternativeView ? 'flex-direction: row' : ''};
  ${({ theme: { isSmallScreen } }) =>
    isSmallScreen ? 'display: inline-block' : ''};
`

// =================================
// Search Bar Components
// =================================

export const SearchBarContainer = styled(View)`
  display: flex;
  flex-direction: row;
  height: ${({ theme }) => theme.sizes[5]}px;
  border-radius: 12px;
  margin: ${({ theme }) => theme.space[2]}px 0
    ${({ theme }) => theme.space[2]}px 0;
  width: ${({ isAlternativeView }) => (isAlternativeView ? 30 : 100)}%;
  background-color: ${({ isAlternativeView }) =>
    isAlternativeView ? 'transparent' : '#d9d9d9'};
`

export const SearchContainer = styled(View)`
  height: 100%;
  justify-content: center;
  width: ${({ isAlternativeView }) => (isAlternativeView ? 100 : 50)}%;
  background-color: ${({ backgroundColor }) => backgroundColor ?? ''};
`

// Buttons

export const ButtonsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  padding-right: ${({ theme }) => theme.space[3]}px;
  justify-content: flex-end;
`

export const ButtonContainer = styled(View)`
  justify-content: center;
  height: 100%;
  width: ${({ theme }) => theme.sizes[6] + theme.sizes[4]}}px;
  margin-left: ${({ theme }) => theme.sizes[2]}}px;
`

export const ButtonFlex = styled(Flex)`
  width: 100%;
  align-items: center;
`

export const StyledButtonUI = styled(Button)``

// =================================
// End Search Bar Components
// =================================

// =================================
// Search Bar Filters Components
// =================================
export const FilterContainer = styled(View)`
  ${({ isAlternativeView }) => (isAlternativeView ? 'width: fit-content' : '')};
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme, hasFilters }) =>
    hasFilters ? theme.space[3] : 0}px;
  margin-top: ${({ theme }) => theme.space[3]}px;
  ${({ isAlternativeView }) =>
    isAlternativeView ? 'align-items: center' : ''};
`

export const FilterTitleContainer = styled(View)`
  justify-content: center;
  margin-right: ${({ theme }) => theme.space[3]}px;
  font-family: sans-serif;
`
