import React from 'react'
import Chart from './Chart'
import { View } from 'react-native'
import { GaussChartProps } from './types'

const BarChart = (props: GaussChartProps) => {
  const { values, formatLabel } = props

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View
        style={{
          width: '100%',
          paddingHorizontal: 10
        }}
      >
        <Chart values={values} formatLabel={formatLabel} />
      </View>
    </View>
  )
}

export default BarChart
