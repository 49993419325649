import * as Yup from 'yup'

export const JSONValidation = Yup.string()
  .test('json', 'Invalid JSON format', value => {
    try {
      if (value) {
        JSON.parse(value)
        return true
      } else {
        return true
      }
    } catch (error) {
      return false
    }
  })
  .notRequired()
  .nullable()
