import React, { useCallback, useEffect, useState } from 'react'

import { View, Text, ScrollView, ActivityIndicator } from 'react-native'

import {
  useIsFocused,
  useRoute,
  useFocusEffect
} from '@react-navigation/native'

import styled, { useTheme } from 'styled-components/native'

import Dropdown, { GroupedOption } from '../../components/common/Dropdown'

import {
  ScreenContainerWithMenuHeader,
  StyledViewContainer
} from '../../components/layout/ScreenContainer'

import useTranslation from '../../hooks/useTranslation'
import Button from '../../ui-library/Button'
import useSubmissionsQuery from './hooks/useSubmissionsQuery'
import useCreateInnovationSubmission from '../CallForSubmission/hooks/useCreateInnovationSubmission'
import useCallsForSubmissionQuery from '../CallForSubmission/hooks/useCallsForSubmissionQuery'
import { CallForSubmissionStepEnum, RoleEnum } from '../../types'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import SubmissionPrefillSummary from './components/SubmissionPrefillSummary'

import TextInput from '../../ui-library/TextInput'
import { Flex } from '../../components/FlexBox'
import useCurrentUser from '../../hooks/useCurrentUser'
import useCompanyQuery from '../../hooks/useCompanyQuery'
import { getProductLabel } from '../../utils/products'

const Title = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[6]}px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: ${theme.space[4]}px;
  `}
`
const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[1]}px;
  `}
`

const SubmissionPrefill = ({ navigation }) => {
  const theme = useTheme()
  const route: any = useRoute()
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const isSmallScreen = useIsSmallScreen()
  const { btnContainerStyles, btnStyles, iconStyles } = getStyles(theme)

  const { currentUser } = useCurrentUser()
  const { company, loading: isCompanyLoading } = useCompanyQuery(
    currentUser?.person?.innovationCompany?.id
  )

  const CFSId = route?.params?.id
  const [
    createInnovationSubmission,
    isCreatingCFS
  ] = useCreateInnovationSubmission(CFSId)
  const {
    submissions = [],
    loading: isSubmissionLoading
  } = useSubmissionsQuery(!isFocused)
  const { callsForSubmission = [] } = useCallsForSubmissionQuery(
    RoleEnum.InnovatorTeamAdmin,
    !isFocused
  )

  const { colors, space } = theme
  const submittedSubmissions = submissions
    .filter(
      ({ hasBeenSubmitted, submittedAt, step }) =>
        (hasBeenSubmitted && submittedAt) ||
        step !== CallForSubmissionStepEnum.submission
    )
    .sort((a, b) => {
      const x = (new Date(a.submittedAt) as any) as number
      const y = (new Date(b.submittedAt) as any) as number

      return y - x
    })
  const callForSubmission = callsForSubmission.find(({ id }) => id === CFSId)
  const { name = '' } = callForSubmission || {}
  const productOptions = [
    ...(company?.products?.map(product => ({
      value: product?.submissionId,
      label: getProductLabel(product)
    })) || [{}])
  ]
  const defaultOption = {
    label: t('submissions:prefill:newSubmission'),
    value: 'new'
  }
  const submissionOpts = [
    defaultOption,
    ...submittedSubmissions.map(({ id, callForSubmission, submissionInfo }) => {
      return {
        value: id,
        label: `${callForSubmission?.name}: ${submissionInfo?.name}`
      }
    })
  ]
  const submissionOptions = [
    {
      label: t('submissions:prefill:prefillGroups:products'),
      options: productOptions
    },
    {
      label: t('submissions:prefill:prefillGroups:pastSubmissions'),
      options: submissionOpts
    }
  ] as GroupedOption[]

  const [selectedOption, setSelectedOption] = useState(defaultOption.value)
  const [newProductName, setNewProductName] = useState('')

  const resetForm = useCallback(() => {
    setSelectedOption(defaultOption.value)
    setNewProductName('')
  }, [])

  const createNewSubmission = useCallback(async () => {
    const isPrefill = selectedOption !== defaultOption.value

    const newSubmission = await createInnovationSubmission(
      isPrefill ? selectedOption : undefined,
      isPrefill ? undefined : newProductName
    )

    if (newSubmission) {
      resetForm()
      navigation.navigate('SavedSubmission', { id: newSubmission.id })
    }
  }, [selectedOption, createInnovationSubmission, setSelectedOption])

  const checkCFSStatus = useCallback(async () => {
    if (!isSubmissionLoading && !isCreatingCFS) {
      const submission = submissions.find(
        ({ callForSubmissionId }) => callForSubmissionId === CFSId
      )

      if (submission) {
        navigation.navigate('SavedSubmission', { id: submission.id })
      }
    }
  }, [CFSId, submissions, isCreatingCFS, isSubmissionLoading])

  useFocusEffect(
    useCallback(() => {
      return () => {
        resetForm()
      }
    }, [])
  )

  useEffect(() => {
    if (isFocused && !isSubmissionLoading) {
      checkCFSStatus()
    }
  }, [isFocused, isSubmissionLoading])

  if (isSubmissionLoading || isCompanyLoading) {
    return null
  }

  const getCurrentSubmission = () =>
    submittedSubmissions.find(s => s.id === selectedOption)

  return (
    <ScreenContainerWithMenuHeader screenCategory="submissionPrefill">
      <ScrollView>
        <StyledViewContainer
          style={{
            maxWidth: 900
          }}
        >
          <View
            style={{
              flex: 1,
              padding: isSmallScreen
                ? theme.space[3]
                : theme.space[5] - theme.space[3]
            }}
          >
            <Title>{name}</Title>
            {submittedSubmissions.length > 0 ? (
              <>
                <Description>
                  {t('submissions:prefill:submissionSelection')}
                </Description>
                <Description>
                  {t(
                    'submissions:prefill:submissionSelectionWithNewSubmission'
                  )}
                </Description>
              </>
            ) : (
              <Description>
                {t('submissions:prefill:brandNewSubmission')}
              </Description>
            )}

            <Dropdown
              testID={'prefillDropdown'}
              name="option"
              value={selectedOption}
              isGroupedOptions
              options={submissionOptions}
              onSelect={setSelectedOption}
              style={{
                marginTop: theme.space[3]
              }}
              menuPortalTarget={document?.body}
            />
            {selectedOption !== defaultOption.value && (
              <SubmissionPrefillSummary submission={getCurrentSubmission()} />
            )}
            {selectedOption === defaultOption.value && (
              <View
                testID={'subName'}
                style={{
                  width: '50%'
                }}
              >
                <TextInput
                  name="name"
                  value={newProductName}
                  onChangeText={(text: string) => setNewProductName(text)}
                  label={t('submissions:prefill:submissionName')}
                />
              </View>
            )}

            <Flex flexDirection="row">
              <Button
                testID={'cancelBtn'}
                title={t('product:general:cancel')}
                type="outline"
                buttonStyle={{
                  ...btnStyles,
                  borderColor: colors.buttonVariant
                }}
                titleStyle={{
                  color: colors.buttonVariant
                }}
                containerStyle={{
                  ...btnContainerStyles,
                  marginRight: space[3]
                }}
                onPress={() => {
                  navigation.navigate('CallForSubmission')
                }}
              />
              <Button
                testID={'continueBtn'}
                title={
                  isCreatingCFS
                    ? t('common:buttons:loading')
                    : t('common:buttons:continue')
                }
                buttonStyle={btnStyles}
                containerStyle={btnContainerStyles}
                onPress={createNewSubmission}
                disabled={
                  isCreatingCFS ||
                  (selectedOption === defaultOption.value
                    ? !newProductName
                    : false)
                }
              />
              {isCreatingCFS && (
                <ActivityIndicator style={iconStyles} size="small" />
              )}
            </Flex>
          </View>
        </StyledViewContainer>
      </ScrollView>
    </ScreenContainerWithMenuHeader>
  )
}

const getStyles = theme => ({
  btnContainerStyles: { height: theme.space[4], marginTop: theme.space[3] },
  iconStyles: {
    marginTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[3]
  },
  btnStyles: {
    width: 'fit-content',
    paddingBottom: theme.space[3],
    paddingTop: theme.space[3],
    paddingLeft: theme.space[4] - theme.space[2],
    paddingRight: theme.space[4] - theme.space[2],
    height: theme.space[4],
    paddingHorizontal: theme.space[4]
  }
})

export default SubmissionPrefill
