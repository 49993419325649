import gql from 'graphql-tag'

import useMutation from '../../../hooks/useMutation'
import { formAnswerQuery } from '../../Submission/hooks/useFormAnswerQuery'

export const updateInnovatorProductMutation = gql`
  mutation updateInnovatorProduct(
    $companyId: String!
    $submissionId: String!
    $productId: String!
  ) {
    updateInnovatorProduct(
      companyId: $companyId
      submissionId: $submissionId
      productId: $productId
    ) {
      id
      formId
      submissionId
    }
  }
`

const useUpdateInnovatorProduct = companyId => {
  const [updateProduct, { loading }]: any[] = useMutation(
    updateInnovatorProductMutation
  )

  const updateInnovatorProduct = async (
    submissionId: string,
    productId: string,
    productSubmissionId?: string
  ) => {
    const results = await updateProduct({
      variables: {
        companyId,
        submissionId,
        productId
      },

      refetchQueries: [
        'company',
        'getFormById',
        {
          query: formAnswerQuery,
          variables: {
            submissionEntityId: productSubmissionId
          },
          skip: !productSubmissionId
        }
      ],
      awaitRefetchQueries: true
    })

    return results
  }

  return { updateInnovatorProduct, loading }
}

export default useUpdateInnovatorProduct
