import React, { useMemo } from 'react'
import { useTheme } from 'styled-components/native'
// Components
import TableHeaderCell from '../TableHeaderCell'
import { DynamicTableHeaderProps } from './constants'
import { TableCell, TableRowContainer } from '../SharedStyledComponents'
import DynamicTableSelectAllElements from './DynamicTableSelectAllElements'

function DynamicTableHeader<T>({
  config,
  tableColumns,
  handleOnSort,
  handleSelect,
  selectedItems,
  sortBy,
  sortDir,
  setSearchFilters,
  searchFilters,
  setQueryFilters,
  queryFilters,
  leftTable,
  isSticky,
  widthFixedTable,
  widthDynamicTable,
  totalElements = 0,
  currentElements = 0,
  currentSelection = 0,
  selectAll = false,
  selectCurrent = false,
  handleSelectAll,
  handleSelectCurrent,
  enableSelectAllElements = false
}: DynamicTableHeaderProps<T>) {
  const { space } = useTheme()
  const { headerContainerStyle, enableFilters = false } = config

  const getCellWidth = (width: number) => {
    const tableWidthV = leftTable
      ? widthFixedTable
      : widthDynamicTable + (widthDynamicTable > 100 ? space[2] : 0)
    return (width / tableWidthV) * 100
  }

  const showSelectAllElementsText: boolean = useMemo(
    () =>
      (selectAll || selectCurrent) &&
      currentElements > 0 &&
      currentElements < totalElements,
    [selectAll, selectCurrent, totalElements, currentElements]
  )

  return (
    <>
      <TableRowContainer
        leftTable={leftTable}
        isSticky={isSticky}
        marginLeft={headerContainerStyle?.marginLeft}
        marginRight={headerContainerStyle?.marginRight}
        marginBottom={headerContainerStyle?.marginBottom}
      >
        {tableColumns.map(
          ({ sort, filter, header, width, headerStyle = {}, ...rest }, i) => {
            return (
              <TableCell
                width={getCellWidth(width?.valueOf() ?? 0)}
                key={`${header}_${i}`}
                hasSubtitle
              >
                <TableHeaderCell
                  enableFilters={enableFilters}
                  handleChangeSort={handleOnSort}
                  handleSelect={handleSelect}
                  selectedItems={selectedItems}
                  handleSelectCurrent={handleSelectCurrent}
                  selectAll={selectAll}
                  selectCurrent={selectCurrent}
                  orderBy={sortBy}
                  orderDir={sortDir}
                  filter={filter}
                  sort={sort}
                  header={header as string}
                  headerStyle={headerStyle}
                  handleChangeSearchFilters={value =>
                    filter?.key &&
                    setSearchFilters({
                      ...searchFilters,
                      [filter.key]: value
                    })
                  }
                  handleChangeFilters={value =>
                    filter?.key &&
                    setQueryFilters({
                      ...queryFilters,
                      [filter.key]: filter?.isFilterArray ? [value] : value
                    })
                  }
                  queryFilter={queryFilters}
                  {...rest}
                />
              </TableCell>
            )
          }
        )}
      </TableRowContainer>
      {showSelectAllElementsText ? (
        <DynamicTableSelectAllElements
          leftTable={leftTable}
          selectAll={selectAll}
          selectCurrent={selectCurrent}
          totalElements={totalElements}
          currentSelection={currentSelection}
          enableSelectAllElements={enableSelectAllElements}
          handleSelectAll={handleSelectAll}
        />
      ) : null}
    </>
  )
}

export default DynamicTableHeader
