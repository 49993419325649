import React, { useEffect } from 'react'
import LoadingScreen from './LoadingScreen'

const LoadingCheck = ({ send, firebaseInitialized, firebaseToken }: any) => {
  useEffect(() => {
    if (firebaseInitialized) {
      send(firebaseToken ? 'LOGGED_IN' : 'NOT_LOGGED_IN')
    }
  }, [firebaseInitialized])

  return <LoadingScreen />
}

export default LoadingCheck
