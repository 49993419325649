import React, { FC } from 'react'
import { Flex } from '../../../components/FlexBox'
import useTranslation from '../../../hooks/useTranslation'
import { ListSkeleton } from './skeletons/CompanySummarySkeleton'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import { getCompanySubmissionsTableConfig } from '../constants'
import { useTable, DynamicTable } from '../../../components/Table'
import CollapsibleContainer from './common/CollapsibleContainer'
import { TableContainerView } from './common/SharedStyledComponents'

interface CompanySubmissionListProps {
  companyId: string
  productSelectedId?: string | null
  onProductSelected?: (value: string) => void
}

const CompanySubmissionList: FC<CompanySubmissionListProps> = ({
  companyId,
  onProductSelected
}) => {
  const { t } = useTranslation()

  const tableProps = useTable({
    config: {
      ...getCompanySubmissionsTableConfig({
        companyId,
        onRowSelect: onProductSelected
      })
    }
  })

  return (
    <SkeletonContainer isLoading={!companyId} Skeleton={ListSkeleton}>
      <Flex>
        <CollapsibleContainer
          title={t('directoryProfile:submissions:title')}
          subtitle={'Company past submissions'}
        >
          <Flex>
            <TableContainerView>
              <DynamicTable {...tableProps} />
            </TableContainerView>
          </Flex>
        </CollapsibleContainer>
      </Flex>
    </SkeletonContainer>
  )
}

export default CompanySubmissionList
