import React, { useMemo, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  DEFAULT_TOAST_MESSAGE,
  Message
} from '../../../../../recoil/toastMessageAtom'
import useTranslation from '../../../../../hooks/useTranslation'
import ModalToast from './ModalToast'
import { ModalDialogProps } from './types'
import { btnContainerStyles, btnStyles } from './constants'
import {
  ModalCloseIcon,
  ModalStyledView,
  ModalStyledButtonsView,
  ModalTitle,
  StyledCheckBox,
  StyledModal,
  StyledModalButton
} from './SharedStyledComponents'

const ModalDialog = (props: ModalDialogProps): any => {
  const { t } = useTranslation()
  const { colors, space } = useTheme()
  const [toastMessage, setToastMessage] = useState<Message | null>(null)
  const {
    title,
    isOpen,
    onClose,
    handleSubmit,
    csvTypesMap,
    isProcessing
  } = props
  const [values, setValues] = useState<{}>(
    csvTypesMap.reduce(
      (acc, csvType) => ({ ...acc, [csvType.name]: false }),
      {}
    )
  )
  const ALL_CSV_TYPES = [...new Set(csvTypesMap)]

  const handleClose = () => {
    setToastMessage(DEFAULT_TOAST_MESSAGE)
    onClose && onClose()
  }

  const isDisabled = useMemo(() => {
    return !Object.values(values).filter(value => value).length
  }, [values])

  return (
    <StyledModal close={handleClose} open={isOpen}>
      <ModalTitle>{title}</ModalTitle>
      <ModalCloseIcon onPress={handleClose} />

      {toastMessage?.message && <ModalToast message={toastMessage} />}
      <ModalStyledView>
        {ALL_CSV_TYPES.map(({ name }, i) => (
          <StyledCheckBox
            key={`export-csv-type-${name}-${i}`}
            label={t(`settings:exportCSV:${name}`)}
            checked={values[name]}
            onPress={() => setValues({ ...values, [name]: !values[name] })}
          />
        ))}
      </ModalStyledView>

      <ModalStyledButtonsView>
        <StyledModalButton
          title={t('settings:members:form:cancel')}
          type="outline"
          buttonStyle={{
            ...btnStyles,
            borderColor: colors.buttonVariant
          }}
          titleStyle={{
            color: colors.buttonVariant
          }}
          containerStyle={{
            ...btnContainerStyles,
            marginRight: space[3]
          }}
          onPress={() => {
            handleClose()
          }}
        />
        <StyledModalButton
          title={isProcessing ? '' : t('common:table:exportCSV')}
          buttonStyle={btnStyles}
          containerStyle={btnContainerStyles}
          onPress={() => handleSubmit(values)}
          disabled={isDisabled}
          isProcessing={isProcessing}
        />
      </ModalStyledButtonsView>
    </StyledModal>
  )
}

export default ModalDialog
