import React, { useCallback, FC } from 'react'

import { Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

// Hooks
import useTranslation from '../../../hooks/useTranslation'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
// Components
import Modal from '../Modal'
import { Flex } from '../../FlexBox'
import Button from '../../../ui-library/Button'
import TouchableIcon from '../../icon/TouchableIcon'
import { Title } from '../../../screens/InnovatorDirectory/components/SharedComponents'

interface EditDialogProps {
  isSubmission: boolean
  handleEditSubmission: () => Promise<void>
  loading: boolean
  openDialog: boolean
  triggerClose: () => void
}

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`
const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[3]}px;
  `}
`

const containerStyle = {
  flexBasis: '48%'
}
const buttonStyle = {
  width: '100%'
}

const EditDialog: FC<EditDialogProps> = ({
  isSubmission,
  handleEditSubmission,
  loading,
  openDialog,
  triggerClose
}) => {
  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()
  const titleStyle = isSmallScreen ? { fontSize: 15 } : {}
  const { t } = useTranslation()

  const onClose = useCallback(() => {
    triggerClose()
  }, [])

  return (
    <Modal
      testIDModal="editModal"
      close={onClose}
      open={openDialog}
      styles={{
        minWidth: '350px',
        maxWidth: '450px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={onClose} />

      <Flex padding={space[3]}>
        <Title>
          {t(
            isSubmission
              ? 'product:general:editSubmission'
              : 'product:general:editProduct'
          )}
        </Title>
        <View
          style={{
            marginTop: space[3]
          }}
        >
          <>
            <Description>
              {t('callsForSubmission:dialog:editDescription')}
            </Description>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              style={{
                marginTop: space[3]
              }}
            >
              <Button
                title={t('callsForSubmission:dialog:cancelBtn')}
                type="outline"
                onPress={onClose}
                containerStyle={containerStyle}
                buttonStyle={buttonStyle}
                titleStyle={titleStyle}
              />
              <Button
                title={t(
                  isSubmission
                    ? 'product:general:editSubmission'
                    : 'product:general:editProduct'
                )}
                testID="confirmEdit"
                type="solid"
                onPress={async () => {
                  handleEditSubmission && (await handleEditSubmission())
                  triggerClose()
                }}
                containerStyle={containerStyle}
                buttonStyle={{ ...buttonStyle }}
                titleStyle={titleStyle}
                isProcessing={loading}
              />
            </Flex>
          </>
        </View>
      </Flex>
    </Modal>
  )
}

export default EditDialog
