import React, { useContext, useEffect, useState } from 'react'
import useTranslation from '../../../../../hooks/useTranslation'
import { HeaderText, SortButton, SortHandle } from '../SharedStyledComponents'
import { TableContext } from '../../../hooks/TableProvider'
import theme from '../../../../../constants/Theme'
import { SortColumnProps } from '../types'

const SortColumn = (props: SortColumnProps): any => {
  const {
    sort: { key: sortKey },
    headerStyle,
    header
  } = props
  const { handleOnSort, sortBy, sortDir } = useContext(TableContext)
  const { t } = useTranslation()
  const [sortIcon, setSortIcon] = useState('▲')

  useEffect(() => {
    if (sortBy === sortKey) {
      setSortIcon(sortDir === 'asc' ? '▲' : '▼')
    }
  }, [sortKey, sortBy, sortDir])

  return (
    <SortButton
      onPress={() => handleOnSort(sortKey)}
      style={{ ...headerStyle, alignSelf: 'center' }}
    >
      <HeaderText style={{ ...headerStyle, width: '100%' }}>
        {t(header)}
        <SortHandle
          style={{
            color:
              sortBy === sortKey
                ? theme.colors.tints.grays.g500
                : theme.colors.tints.grays.g200
          }}
        >
          {sortIcon}
        </SortHandle>
      </HeaderText>
    </SortButton>
  )
}

export default SortColumn
