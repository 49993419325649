import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import NewGradient from './NewGradient'

const Container = styled(View)`
  ${({ width }) => `
    width: ${width || '100%'};
  `}
  flex: 1;
  height: 100%;
  background-color: #000;
  background-image: url('${require('../../../../../assets/images/footer.png')}');
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
`

export const InnovationBg = ({ children, width }: any) => {
  return (
    <Container width={width}>
      <NewGradient>{children}</NewGradient>
    </Container>
  )
}

export default InnovationBg
