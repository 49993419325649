import gql from 'graphql-tag'

const CallForSubmissionFieldsFragment = (cfsCurrentStep: string = '') => gql`
  fragment CallForSubmissionFieldsFragment on CallForSubmission {
    id
    name
    status
    academyElegible
    stepDateRanges {
      id
      curationStep
      openAt
      closeAt
    }
    currentStep {
      name
      status
      openAt
      closeAt
    }
    advisors
    delegations {
      id
      name
    }
    marketSegments {
      id
      name
    }
    innovationSubmissions(cfsCurrentStep: "${cfsCurrentStep}") {
      id
      status
      hasBeenSubmitted
      hasBeenFastPassed
    }
  }
`

export default CallForSubmissionFieldsFragment
