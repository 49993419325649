import React, { FC } from 'react'

import styled from 'styled-components/native'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
// Components
import MarketSegmentSelector from './MarketSegmentSelector'
import { StyledButtonUI } from '../../../components/DynamicTable/components/SearchBar/SharedStyledComponents'
// Styled Components
const Container = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  ${({ theme: { space } }) => `
    padding: ${space[3]}px;
  `}
`
const DropdownContainer = styled.View`
  width: auto;
  ${({ theme: { space } }) => `
    margin-left: ${space[3]}px;
  `}
`
const CreateIcon = styled(StyledButtonUI).attrs(({ theme: { sizes } }) => ({
  buttonStyle: {
    width: sizes[6] + sizes[5]
  }
}))``

// Types
interface ActionHeaderProps {
  fetchCategoriesLoading?: boolean
  selectedMarketSegment: string[]
  onMarketSegmentSelection: (selection: any) => void
  onAddNewCategory: () => void
}

const ActionHeader: FC<ActionHeaderProps> = ({
  fetchCategoriesLoading = false,
  selectedMarketSegment,
  onMarketSegmentSelection,
  onAddNewCategory
}) => {
  // Hooks
  const { t } = useTranslation()
  // Render
  const isLoading = !!fetchCategoriesLoading

  return (
    <Container>
      <DropdownContainer>
        <MarketSegmentSelector
          value={selectedMarketSegment}
          onSelect={onMarketSegmentSelection}
          externalLoading={isLoading}
        />
      </DropdownContainer>
      <CreateIcon
        disabled={isLoading}
        isLoading={isLoading}
        title={t('platformManagement:categories:createCategory')}
        onPress={onAddNewCategory}
      />
    </Container>
  )
}

export default ActionHeader
