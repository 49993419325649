import gql from 'graphql-tag'
import CallForSubmissionFieldsFragment from '../../../graphql/CallForSubmissionFieldsFragment'
import InnovationSubmissionFieldsFragment from '../../../graphql/InnovationSubmissionFieldsFragment'
import CompanyProfileFragment from '../../../graphql/DirectoryCompanyProfileFragment'

// JC: Since we are using the query searchInnovationEngine in all the tables,
// we created a dynamic query name to target specific tables to be able to save the query variables
// and e able to target it while updating the cache
export const getSearchInnovationEngineQuery = (
  normalizedQueryName,
  currentCFSStep,
  getQuestionAndAnswers
) => {
  const queryString = `query ${normalizedQueryName}(
    $size: Int
    $sorts: [SortObjectInput!]
    $engine: InnovationSearchEngine!
    $query: String
    $boosts: [BoostsInput!]
    $filters: FiltersInput
    $offset: Int
    $withSubmissionVotes: Boolean!
    $withDirectoryTypesenseData: Boolean
  ) {
    searchInnovationEngine(
      engine: $engine
      query: $query
      boosts: $boosts
      filters: $filters
      sorts: $sorts
      size: $size
      offset: $offset
    ) {
      total
      hits {
        ... on CallForSubmission {
          ...CallForSubmissionFieldsFragment
        }
        ... on InnovationSubmission {
          ...InnovationSubmissionFieldsFragment
          ${
            getQuestionAndAnswers
              ? `submissionAnswers {
                value
                question {
                  id
                  questionText
                }
              }`
              : ``
          }          
        }
        ... on CompanyProfile {
          ...CompanyProfileFragment
        }
      }
    }
  }
  `
  return gql`
      ${queryString}
      ${InnovationSubmissionFieldsFragment},
      ${CallForSubmissionFieldsFragment(currentCFSStep)}
      ${CompanyProfileFragment}
  `
}
