import { useEffect } from 'react'
import Constants from 'expo-constants'
import useCurrentUser, { CurrentUserHook } from '../../hooks/useCurrentUser'
import useQuery from '../../hooks/useQuery'
import Firebase from '../../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import currentUserQuery from './currentUserQuery'
import { CurrentUser } from '../../types'
import useTranslation from '../../hooks/useTranslation'
import useMixpanel from '../../hooks/useMixpanel'
import { idToCloudinaryAvatarUrl } from '../../utils/idToCloudinaryUrl'

export enum UserAccountStatusEnum {
  Active = 'active',
  Inactive = 'inactive'
}

const BUILD_ENV = Constants?.manifest?.extra?.BUILD_ENV

const createPendoOptions = queryUser => {
  if (!queryUser) {
    return
  }

  const idSuffix = BUILD_ENV
  const person = queryUser.person
  const emails = person.emailAddresses

  return {
    visitor: {
      id: `${person.id}-${idSuffix}`, // Required if user is logged in
      email: emails && emails.length > 0 ? emails[0].email : null,
      full_name: `${person.firstName} ${person.lastName}`,
      user_id: queryUser.id,
      user_roles: queryUser.roles,
      companyId: person?.innovationCompany?.id,
      company: person?.innovationCompany?.name,
      title: person.jobTitle
    }
  }
}

const initPendo = pendoOptions => {
  if (!pendoOptions) {
    return
  }

  // @ts-ignore
  window?.pendo?.initialize(pendoOptions)
}

const setPendoUser = pendoOptions => {
  if (!pendoOptions) {
    return
  }

  // @ts-ignore
  window.pendo.identify(pendoOptions)
}

const CurrentUserController = () => {
  const { t } = useTranslation()
  const { identify, set } = useMixpanel()
  const {
    firebaseToken,
    currentUserId,
    setCurrentUser
  }: CurrentUserHook = useCurrentUser()

  const { data } = useQuery(currentUserQuery, {
    skip: !getFBAuth(Firebase).currentUser,
    fetchPolicy: 'no-cache',
    errorMessage: t('error:general:currentUser')
  })

  // Set current user metadata
  useEffect(() => {
    const queryUser: CurrentUser | null = data?.currentUser || null
    if (currentUserId !== queryUser?.id) {
      const identifyString = (queryUser?.person?.id as string) || ''
      identify(identifyString)

      const {
        firstName,
        lastName,
        salesforceId,
        primaryGroup,
        emailAddresses,
        photo
        // @ts-ignore
      } = queryUser.person

      const avatar = photo?.cloudinaryId
        ? idToCloudinaryAvatarUrl(photo.cloudinaryId)
        : undefined

      set({
        $first_name: lastName,
        $last_name: firstName,
        $avatar: avatar,
        $name: firstName + ' ' + lastName,
        'Salesforce ID': salesforceId,
        $email: emailAddresses?.[0]?.email,
        'Primary Group': primaryGroup ? primaryGroup.name : null
      })

      setCurrentUser(queryUser)
    }
  }, [data])

  useEffect(() => {
    const queryUser: CurrentUser | null = data?.currentUser || null

    let pendoOptions = createPendoOptions(queryUser)
    initPendo(pendoOptions)

    if (currentUserId !== queryUser?.id) {
      setPendoUser(pendoOptions)
    }
  }, [data])

  useEffect(() => {
    if (!firebaseToken) {
      setCurrentUser(null)
    }
  }, [firebaseToken])

  return null
}

export default CurrentUserController
