import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'ramda'

import InputField from '../../../InputField'
import { FormikProvider, useFormik } from 'formik'
import {
  QuestionGroupModalFormProps,
  QuestionGroupValues
} from '../../../../types'
import useUpsertParentQuestionGroup from '../../hooks/useUpsertParentQuestionGroup'
import useToast from '../../../../../../../hooks/useToast'
import useTranslation from '../../../../../../../hooks/useTranslation'
import { CancelButton, Footer, SaveButton } from '../../../../../constants'
import useGetQuestionGroups from '../../hooks/useGetQuestionGroups'
import Dropdown, {
  DropdownOption
} from '../../../../../../../components/common/Dropdown'
import { View } from 'react-native'
import { Text } from '../../../../../../../components/common/Text'
import { QuestionGroupTypeEnum } from '../../../../../../../components/DynamicForm/types'
import { JSONValidation } from '../../../../../utils'

const questionFields = [
  {
    field: 'type',
    component: InputField,
    isParent: false
  },
  {
    field: 'dynamic',
    component: InputField,
    isParent: false
  },
  {
    field: 'style',
    component: InputField,
    isJSON: true
  },
  {
    field: 'configData',
    component: InputField,
    isJSON: true
  }
]

// Yup validation
const getAddQuestionGroupValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    type: Yup.mixed<QuestionGroupTypeEnum>()
      .oneOf(Object.values(QuestionGroupTypeEnum))
      .required(),
    dynamic: Yup.boolean().default(false),
    style: JSONValidation,
    configData: JSONValidation
  })

const getInputValues = (
  values: QuestionGroupValues,
  isParent: boolean,
  isEdit: boolean
): QuestionGroupValues => {
  const valuesKeys = Object.keys(values)
  const parentQuestions = ['id']

  const excludeVariables = isParent && !isEdit ? parentQuestions : []

  let result = {}
  result = isParent && !isEdit ? { parentId: values['id'] } : {}

  for (
    let indexValueKey = 0;
    indexValueKey < valuesKeys.length;
    indexValueKey++
  ) {
    const valueKey = valuesKeys[indexValueKey]
    if (values[valueKey] && !excludeVariables.includes(valueKey)) {
      const customQuestionField = questionFields.find(
        field => field.field === valueKey
      )
      if (!(isParent && customQuestionField?.isParent)) {
        if (customQuestionField && customQuestionField.isJSON) {
          result[valueKey] = JSON.stringify(JSON.parse(values[valueKey]))
        } else {
          if (valueKey === 'dynamic' && typeof values[valueKey] === 'string') {
            result[valueKey] = values[valueKey] === 'true'
          } else {
            result[valueKey] = values[valueKey]
          }
        }
      }
    }
  }

  return result
}

const ParentQuestionGroup = ({
  parentQuestion,
  setParentQuestion,
  questionType
}: {
  setParentQuestion: (question: QuestionGroupValues) => void
  parentQuestion?: QuestionGroupValues
  questionType?: QuestionGroupTypeEnum
}) => {
  const { t } = useTranslation()
  const [data, setData] = useState<QuestionGroupValues[]>([])
  const { loading, questionGroups: questions } = useGetQuestionGroups(
    true,
    undefined
  )

  useEffect(() => {
    if (!loading && questions) {
      let newData: QuestionGroupValues[] = questions.filter(q => !q.parentId)
      if (questionType) {
        newData = newData.filter(question => question.type === questionType)
      }
      setData(newData)
    }
  }, [questions, loading, questionType])

  const questionsOptions: DropdownOption[] = useMemo(
    () =>
      data.map(question => ({
        label: JSON.stringify(question?.configData) ?? '',
        value: question.id,
        custom: question
      })),
    [data]
  )

  return (
    <View style={{ width: '100%', marginTop: 10 }}>
      <Text styles={{ marginBottom: 5 }}>{'Parent Question'}</Text>
      <Dropdown
        isFilter
        isDisabled={loading}
        closeMenuOnSelect
        style={{
          minHeight: 0,
          marginBottom: 0
        }}
        value={parentQuestion?.id ?? null}
        containerStyle={{ width: 250, height: 50, marginRight: 10 }}
        isLoading={loading}
        options={questionsOptions}
        controlStyle={{ minHeight: 0 }}
        menuPortalTarget={document?.body}
        onSelect={value => {
          const currentQuestion = questions.find(
            question => question.id === value
          )
          if (currentQuestion) {
            setParentQuestion(currentQuestion)
          }
        }}
        name={'parentQuestionTypesSelector'}
        placeholder={t(
          'platformManagement:forms:menuItems:questions:selectorPlaceholder'
        )}
      />
    </View>
  )
}

const Form = (props: QuestionGroupModalFormProps) => {
  const {
    onClose,
    question,
    isParent,
    questionType,
    isEdit,
    questionGroupId
  } = props
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()
  const [parentQuestion, setParentQuestion] = useState<
    QuestionGroupValues | undefined
  >()

  const currentQuestion = useMemo(() => question || parentQuestion, [
    parentQuestion,
    question
  ])

  const isCurrentParent = isParent || !!currentQuestion

  // Mutations
  const [upsertParentQuestionGroup, isLoading] = useUpsertParentQuestionGroup({
    onError: e => console.error(e),
    onCompleted: ({ upsertParentQuestionGroup }) => {
      if (upsertParentQuestionGroup && !isEmpty(upsertParentQuestionGroup)) {
        setToastMessage(
          t('platformManagement:forms:menuItems:questionGroups:successSaved')
        )
        onClose()
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:questionGroups:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:questionGroups:errorSave')
  })

  const questionKeys = currentQuestion
    ? Object.keys(currentQuestion)
    : undefined

  let initialValues = {}

  if (currentQuestion && questionKeys) {
    for (
      let indexQuestionKey = 0;
      indexQuestionKey < questionKeys.length;
      indexQuestionKey++
    ) {
      const questionKey = questionKeys[indexQuestionKey]
      if (
        ['id', ...questionFields.map(qField => qField.field)].includes(
          questionKey
        )
      ) {
        const currentValue = currentQuestion[questionKey]
        if (typeof currentValue === 'object' && currentValue != null) {
          initialValues[questionKey] = JSON.stringify(currentValue)
        } else {
          initialValues[questionKey] = currentValue ?? undefined
        }
      }
    }
  }

  // Formik
  const form = useFormik<QuestionGroupValues>({
    initialValues: { ...initialValues },
    validationSchema: getAddQuestionGroupValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      const input = getInputValues(values, isCurrentParent, isEdit)
      upsertParentQuestionGroup({
        variables: { input, questionGroupId },
        refetchQueries: ['getQuestionGroupEntityMaps']
      })
    }
  })

  const validateAndSubmit = () => {
    form.validateForm().then(() => form.submitForm())
  }

  const enableSubmit = useMemo(() => {
    return form.isValid && form.dirty
  }, [form])

  return (
    <FormikProvider value={form}>
      {!isEdit ? (
        <ParentQuestionGroup
          setParentQuestion={setParentQuestion}
          parentQuestion={parentQuestion}
          questionType={questionType}
        />
      ) : null}
      {questionFields.map(({ field, isParent }) => (
        <InputField
          type="questionGroups"
          key={field}
          fieldName={field}
          isReadOnly={isParent === isCurrentParent}
        />
      ))}
      <Footer>
        <CancelButton
          isProcessing={isLoading}
          onPress={onClose}
          title={t('common:buttons:cancel')}
        />
        <SaveButton
          disabled={!enableSubmit}
          onPress={validateAndSubmit}
          isProcessing={isLoading}
          title={t(`common:buttons:${isEdit ? 'update' : 'save'}`)}
        />
      </Footer>
    </FormikProvider>
  )
}

export default Form
