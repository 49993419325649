import React, { FC, useCallback } from 'react'
import { ViewStyle, Text, View } from 'react-native'
import { useDropzone } from 'react-dropzone'
import Button from '../../ui-library/Button'
import styled, { useTheme } from 'styled-components/native'
import useTranslation from '../../hooks/useTranslation'
import Icon from '../../components/icon'
import { H4 } from './Text'
import { propsToFilter } from '../../utils/NonReactNativeProp'
import Tooltip from '../../ui-library/TextInput/Tooltip'

export const FDZContainer = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed transparent;
  border-color: ${({ hasError, isDragActive, theme }) => {
    return isDragActive
      ? theme.colors.primary
      : hasError
      ? theme.colors.danger
      : theme.colors.tints.grays.g100
  }};
  border-radius: 10px;
  box-sizing: border-box;
  background: ${({ isReadOnly, theme }) =>
    isReadOnly ? theme.colors.inputBackground : 'transparent'};
`

export const DZHeaderText = styled(Text)`
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 5px;
`

export const DZSubText = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`

export const DZIconContainer = styled(View)`
  ${({ theme: { space } }) => `
    margin: ${space[2]}px;
  `}
`

export const UploadContainer = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const DocumentIcon = styled(Icon)
  .withConfig({
    shouldForwardProp: prop => !propsToFilter.includes(prop)
  })
  .attrs(({ theme, isReadOnly }) => ({
    name: 'fileDropZone',
    width: theme.sizes[4],
    height: theme.sizes[4],
    color: isReadOnly ? theme.colors.disabled : theme.colors.darkIcon
  }))``

export const DropZoneContent = ({
  label,
  helperText = null,
  selectFileButton,
  progress,
  isReadOnly = false
}: DropZoneContentProps) => {
  const { colors, fontSizes } = useTheme()
  const { t } = useTranslation()
  const textColor = isReadOnly ? colors.disabled : colors.darkIcon
  const headerText =
    label && label !== 'Product Pitch Deck.'
      ? label
      : t('product:pitch:file:form:dropFile')

  return (
    <UploadContainer>
      {progress ? (
        <H4 styles={{ marginLeft: 10 }}>{progress?.toString()}%</H4>
      ) : (
        <>
          <DZIconContainer>
            <DocumentIcon isReadOnly={isReadOnly} />
          </DZIconContainer>
          {helperText ? (
            <Tooltip text={helperText}>
              <DZHeaderText style={{ color: textColor }}>
                {headerText}
              </DZHeaderText>
            </Tooltip>
          ) : (
            <DZHeaderText style={{ color: textColor }}>
              {headerText}
            </DZHeaderText>
          )}
          <DZSubText>
            <Text style={{ color: textColor }}>
              {' '}
              {t('product:pitch:file:form:or')}{' '}
            </Text>
            {selectFileButton}
          </DZSubText>
          <Text
            style={{
              color: textColor,
              fontSize: fontSizes[2]
            }}
          >
            {t('dropZone:maxSize')}
          </Text>
        </>
      )}
    </UploadContainer>
  )
}

export interface DropZoneContentProps {
  selectFileButton: JSX.Element
  progress?: number | null
  isReadOnly?: boolean
  label?: string
  helperText?: string | null
}

export interface FileDropzoneFile {
  name: string
  type: string
}

export default function FileDropzone({
  hasError,
  upload,
  Content,
  accept,
  multiple = false,
  isReadOnly = false,
  ...rest
}: FileDropzoneProps) {
  const { colors } = useTheme()
  const { t } = useTranslation()

  const onDrop = useCallback(async acceptedFiles => {
    const file = acceptedFiles[0]
    await upload(file)
  }, [])

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
    disabled: isReadOnly
  })

  const inputProps: any = getInputProps()
  const rootProps = getRootProps({
    onClick: e => e.stopPropagation()
  })
  const selectFileButton = (
    <Button
      title={t('dropZone:selectFile')}
      type="clear"
      onPress={open}
      disabled={isReadOnly}
      titleStyle={{
        textTransform: 'lowercase',
        color: colors.cta.primary
      }}
    />
  )

  return (
    <FDZContainer
      isDragActive={isDragActive}
      hasError={hasError}
      isReadOnly={isReadOnly}
      {...rest}
      {...rootProps}
      tabIndex={-1}
    >
      <input {...inputProps} tabIndex={-1} />
      {Content && <Content selectFileButton={selectFileButton} />}
      {!Content && (
        <>
          <DZHeaderText>{t('dropZone:dropFileHere')}</DZHeaderText>
          <DZSubText>
            <Text>{t('dropZone:or')} </Text>
            {selectFileButton}
          </DZSubText>
        </>
      )}
    </FDZContainer>
  )
}

export interface FileDropzoneProps {
  Content?: FC<DropZoneContentProps>
  hasError?: boolean
  upload: (file: FileDropzoneFile) => void
  accept: string
  multiple?: boolean
  style?: ViewStyle
  isReadOnly?: boolean
  label?: string
}
