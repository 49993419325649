import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'

interface IconProps {
  color?: string
  size?: number
  style?: object | object[]
}

const PhoneIcon = React.memo(
  ({ color = '#000', size = 16, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <Path
          d="M5.404 1.467h-2.07a1.866 1.866 0 0 0-1.867 1.866V5.2a9.333 9.333 0 0 0 9.333 9.333h1.867c1.03 0 1.866-.835 1.866-1.866v-1.29c0-.354-.2-.677-.516-.835l-2.255-1.127a.933.933 0 0 0-1.302.54l-.278.833c-.18.54-.734.862-1.293.75A5.644 5.644 0 0 1 4.462 7.11a1.129 1.129 0 0 1 .75-1.292l1.035-.345a.933.933 0 0 0 .61-1.111L6.31 2.174a.933.933 0 0 0-.906-.707Z"
          stroke={color}
        />
      </Svg>
    </View>
  )
)
export default PhoneIcon
