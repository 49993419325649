import React from 'react'
import { LegendItemProps, LegendProps } from './types'
import { View } from 'react-native'
import Icon from '../../common/Icon'
import { Text } from '../../../../../components/common/Text'

const LegendItem = (props: LegendItemProps) => {
  const {
    color,
    title,
    index,
    setIndex,
    setIsClicked,
    setIsHover,
    handleClick,
    opacity
  } = props
  return (
    <View
      style={{
        height: 25,
        marginBottom: 5,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        opacity,
        cursor: 'pointer'
      }}
      onMouseEnter={() => {
        setIndex(index)
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIndex(undefined)
        setIsHover(false)
      }}
      onMouseDown={() => {
        setIsClicked(true)
        handleClick && handleClick(title)
      }}
      onMouseUp={() => {
        setIsClicked(false)
      }}
    >
      <Icon iconColor={color} />
      <Text styles={{ alignSelf: 'center' }}>{title}</Text>
    </View>
  )
}
const Legend = (props: LegendProps) => {
  const {
    values,
    setIndex,
    opacity,
    setIsClicked,
    setIsHover,
    handleClick,
    index: selectedIndex
  } = props
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center'
      }}
    >
      {values.map(({ title, color }, index) => (
        <LegendItem
          color={color}
          title={title}
          index={index}
          opacity={selectedIndex === index ? opacity : 1}
          setIndex={setIndex}
          setIsClicked={setIsClicked}
          setIsHover={setIsHover}
          handleClick={handleClick}
        />
      ))}
    </View>
  )
}

export default Legend
