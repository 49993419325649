import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { searchQueryByName } from '../../../recoil/searchQueryAtom'
import useCurrentQueryTable from '../../../hooks/useCurrentQueryTable'

interface CurrentSubmissionsQueryProps {
  queryDynamicName: string
}

interface UseCurrentSubmissionsQuery {
  submissions: any[]
}

const useCurrentSubmissionsQuery = (): UseCurrentSubmissionsQuery => {
  const { currentTable } = useCurrentQueryTable()

  const searchQueriesVariables = useRecoilValue(searchQueryByName(currentTable))

  const submissions = useMemo(
    () => searchQueriesVariables?.data?.searchInnovationEngine?.hits ?? [],
    [searchQueriesVariables]
  )

  return {
    submissions
  }
}

export default useCurrentSubmissionsQuery
