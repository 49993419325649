import { atom } from 'recoil'

export interface Route {
  screen: string | null
  params: any
}

export interface History {
  history: Route[]
}

const HistoryRoutesAtom = atom<History>({
  key: 'historyRoutes',
  default: {
    history: []
  }
})

export default HistoryRoutesAtom
