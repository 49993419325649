import { useMutation as RAuseMutation } from '@apollo/client'
import Sentry from '../utils/sentry'
import useTranslation from './useTranslation'
import useToast from './useToast'

export interface MutationProps {
  onCompleted?: (results: any) => void
  onError?: (error: any) => void
  errorMessage?: string
  [key: string]: any
}

const useMutation = (mutation, options: MutationProps = {}) => {
  const { t } = useTranslation()
  const { onError, errorMessage, skipErrorThrow, ...mutationOptions } = options
  const { setToastErrorMessage } = useToast()

  const result = RAuseMutation(mutation, {
    ...mutationOptions,
    onError: error => {
      if (onError && !skipErrorThrow?.(error)) {
        onError(error)
      }

      Sentry.captureException(error)
      setToastErrorMessage(errorMessage || t('error:defaultMutation'))
    }
  })

  return result
}

export default useMutation
