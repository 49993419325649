import React, { useContext } from 'react'
import { ButtonsContainer } from '../SharedStyledComponents'
import CsvButton from './CsvButton'
import GeneralButton from './GeneralButton'
import { TableContext } from '../../../hooks/TableProvider'

const Buttons = (): any => {
  const {
    config: { showCsvButton, showPDFButton, showProductPDFButton }
  } = useContext(TableContext)

  return (
    <ButtonsContainer>
      {showCsvButton ? <CsvButton /> : null}
      {showPDFButton ? <GeneralButton /> : null}
      {showProductPDFButton ? <GeneralButton isProduct /> : null}
    </ButtonsContainer>
  )
}

export default Buttons
