import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import Colors from '../../../../constants/Colors'

export const NewGradient = ({ children }: any) => {
  return (
    <LinearGradient
      style={{
        height: '100%',
        width: '100%'
      }}
      colors={[
        Colors.light.gradientLightBlue,
        Colors.light.gradientDarkBlue,
        Colors.light.gradientBlack
      ]}
      start={{ x: 0, y: 0 }}
      end={{ x: 0.5, y: 2 }}
      locations={[0.004, 0.055, 0.2]}
    >
      {children}
    </LinearGradient>
  )
}

export default NewGradient
