import React, { useContext } from 'react'
import { shouldMemoizeComponent } from '../../../../constants'
import {
  FilterComponentComponentsProps,
  FilterComponentProps
} from '../../types'
import FilterHook from './FilterHook'
import AutoCompleteDropdown from '../../../common/AutoCompleteDropdown'
import { textInputStyle } from '../../constants'
import { TableContext } from '../../../../hooks/TableProvider'
import { DropdownValue } from '../../../../../common/Dropdown'

const FilterComponentComponents = React.memo(
  (props: FilterComponentComponentsProps) => {
    const { filter, header, setQueryFilters, value, isDisabled } = props
    const { isHook, key: filterKey } = filter

    const handleOnSelectValue = (value: DropdownValue) => {
      setQueryFilters({ [filterKey]: value })
    }

    return filter ? (
      isHook ? (
        <FilterHook
          header={header}
          filter={filter}
          value={value}
          handleChangeFilters={handleOnSelectValue}
        />
      ) : (
        <AutoCompleteDropdown
          options={filter.options ?? []}
          placeholder={header}
          value={value}
          isDisabled={isDisabled}
          onSelectValue={handleOnSelectValue}
          styles={textInputStyle}
        />
      )
    ) : null
  },
  shouldMemoizeComponent
)

const FilterComponent = (props: FilterComponentProps): any => {
  const { setQueryFilters, queryFilters } = useContext(TableContext)
  const { filter, header, value, handleChangeFilters, isDisabled } = props

  return (
    <FilterComponentComponents
      filter={filter}
      header={header}
      setQueryFilters={handleChangeFilters ?? setQueryFilters}
      value={value ?? queryFilters[filter.key]}
      isDisabled={isDisabled}
    />
  )
}

export default FilterComponent
